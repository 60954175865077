<template>
    <v-select
        id="document_id"
        v-model="localStudentDocument"
        :error="!!errors['document_id']"
        :error-messages="errors['document_id'] ? errors['document_id'] : null"
        :items="studentDocuments"
        :label="this.label"
        :loading="loading"
        item-text="name"
        item-value="id"
    />
</template>

<script>
export default {
    name: "StudentDocumentSelector",
    props: {
        label: {
            default: "Document",
            type: String
        },
        value: {
            default: 0,
            type: Number,
            required: true,
        },
    },
    computed: {
        loading() {
            return this.$store.getters.studentDocumentDropdownLoading;
        },
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
        studentDocuments() {
            return this.$store.getters.studentDocumentDropdown;
        },
        localStudentDocument: {
            get() {
                return this.value
            },
            set(localStudentDocument) {
                this.$emit('input', localStudentDocument)
            }
        }
    },
    mounted() {
        this.$emit('input', this.localStudentDocument);
    },
};
</script>
