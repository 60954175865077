<template>
    <v-select
        id="application_member_id"
        v-model="localStudent"
        :error="!!errors['application_member_id']"
        :error-messages="errors['application_member_id'] ? errors['application_member_id'] : null"
        :items="students"
        :label="this.label"
        :loading="loading"
        item-text="name"
        item-value="id"
    >
    </v-select>
</template>

<script>
import { GET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN } from "../../store/actions.type";

export default {
    name: "StudentSelector",
    props: {
        label: {
            default: "Student",
            type: String
        },
        value: {
            default: null,
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
        students() {
            return this.$store.getters.currentStudents;
        },
        localStudent: {
            get() {
                return this.value
            },
            set(localStudent) {
                this.$emit('input', localStudent)
            }
        }
    },
    methods: {
        getStudents() {
            this.loading = true;
            const application_id = this.$route.params.application_id;
            const household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN, { application_id, household_id }).then(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.getStudents();
        this.$emit('input', this.localStudent);
    },
};
</script>
