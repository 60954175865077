<template>
    <div :class="disableCheckbox ? 'pb-5' : ''">
        <v-checkbox
            v-model="application.agree_to_terms"
            :disabled="disableCheckbox"
            :error="!!errors['agree_to_terms']"
            :error-messages="errors['agree_to_terms'] ? errors['agree_to_terms'] : null"
            color="secondary"
            @change="updateHouseholdApplicationCheckbox('agree_to_terms')"
        >
            <template v-slot:label>
                <div class="">
                    I promise that all information on this application is true and that all income is reported to the best
                    of my knowledge.
                </div>
            </template>
        </v-checkbox>
        <div
            v-if="disableCheckbox"
            class="box-with-background"
        >
            <div style="font-size: 1.125rem;">Please clear the following items prior to agreeing to this affidavit:</div>
            <ol>
                <li v-if="!application.agree_to_income_reporting_instructions">
                    Check the box in the Household Income section to confirm income reporting instructions
                </li>
                <li v-if="!$store.getters.currentApplicationMembersFiltered(2).length">
                    Add at least one parent or guardian
                </li>
                <li v-if="$store.getters.currentApplicationMembersFiltered(2).filter(member => !member.phone).length">
                    Add a phone number for each parent or guardian
                </li>
                <li v-if="!$store.getters.currentApplicationMembersFiltered(3).length">
                    Add at least one student
                </li>
                <li v-if="!application.household_income">
                    Add household income for at least one household member
                </li>
                <li
                    v-if="$store.getters.currentApplicationDocuments.filter(document => !document.document_submitted).length">
                    Upload documents required in the Documents section
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
import { PATCH_HOUSEHOLD_APPLICATION_CHECKBOX } from "../../../store/actions.type";

export default {
    name: "Terms",
    props: {
        application: {
            type: Object,
            required: true
        },
    },
    computed: {
        disableCheckbox() {
            let disable = !this.application.agree_to_income_reporting_instructions ||
                !this.application.household_income ||
                !this.application.members.filter(member => member.type === 2).length ||
                this.application.members.filter(member => member.type === 2).filter(member => !member.phone).length ||
                !this.application.members.filter(member => member.type === 3).length ||
                this.$store.getters.currentApplicationDocuments.filter(document => !document.document_submitted).length;

            if (!disable) {
                return false;
            }

            if (this.application.agree_to_terms) {
                this.updateHouseholdApplicationCheckbox("agree_to_terms")
            }

            return true;
        },
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
    },
    methods: {
        updateHouseholdApplicationCheckbox(field_name) {
            this.saving = true;
            let household_application_id = this.$route.params.application_id;
            let household_id = this.$route.params.household_id;
            this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_CHECKBOX,
                {
                    household_id,
                    household_application_id,
                    field_name,
                }
            )
                .then(() => {
                    this.saving = false;
                });
        },
    }
}
</script>

<style scoped>
ol li {
    margin-bottom: 20px;
}

ol li:first-of-type {
    margin-top: 20px;
}

ol li:last-of-type {
    margin-bottom: 0;
}

.box-with-background {
    font-family: "Mulish", sans-serif !important;
}
</style>
