<template>
    <div class="mr-5">
        <v-row
            align="center"
            class="ml-1"
        >
            <v-col md="6">
                <admin-header page-title="Users"/>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn
                    color="primary"
                    rounded
                    @click="dialogAddNewUser = true"
                >
                    <v-icon
                        class="mr-2"
                        small
                    >mdi-account-plus
                    </v-icon>
                    Add New User
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialogAddNewUser"
            :fullscreen="!!isMobile"
            :max-width="dialogMaxWidth"
        >
            <v-card class="dialog">
                <v-card-title>
                    Add New User
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        id="email"
                        v-model.trim="user.email"
                        :error="!!errors['email']"
                        :error-messages="errors['email'] ? errors['email'] : null"
                        autocomplete="email"
                        background-color="white"
                        class="mt-5"
                        filled
                        label="Email"
                        @blur="checkRegistered"
                        @focus="clearErrors()"
                    />
                    <v-row>
                        <v-col>
                            <v-text-field
                                id="first_name"
                                v-model.trim="user.first_name"
                                :error="!!errors['first_name']"
                                :error-messages="errors['first_name'] ? errors['first_name'] : null"
                                autocomplete="first_name"
                                background-color="white"
                                filled
                                label="First Name"
                                @focus="clearErrors()"
                            />
                            <v-text-field
                                id="last_name"
                                v-model.trim="user.last_name"
                                :error="!!errors['last_name']"
                                :error-messages="errors['last_name'] ? errors['last_name'] : null"
                                autocomplete="last_name"
                                background-color="white"
                                filled
                                label="Last Name"
                                @focus="clearErrors()"
                            />
                            <v-text-field
                                id="phone"
                                v-model.trim="user.phone"
                                :error="!!errors['phone']"
                                :error-messages="errors['phone'] ? errors['phone'] : null"
                                autocomplete="phone"
                                background-color="white"
                                filled
                                label="Phone"
                                @focus="clearErrors()"
                                @input="phoneNumber"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="mt-n5 pb-5">
                    <v-spacer/>
                    <v-btn
                        class="cancel-button"
                        rounded
                        @click="dialogAddNewUser = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        class="save-by-cancel-button"
                        color="primary"
                        rounded
                        @click="addNewUser"
                    >
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogNewUserAdded"
            :fullscreen="!!isMobile"
            :max-width="dialogMaxWidth"
        >
            <v-card>
                <v-card-title>
                    New User Added
                </v-card-title>
                <v-card-text style="color: unset; font-size: unset;">
                    {{ newUser.first_name }} {{ newUser.last_name }} has been added to the system. Please click below if
                    you
                    would
                    like
                    to impersonate {{ newUser.first_name }}.
                </v-card-text>
                <v-card-actions class="pb-5">
                    <v-btn
                        class="cancel-button"
                        rounded
                        @click="dialogNewUserAdded = false"
                    >
                        Cancel
                    </v-btn>
                    <a :href="'/impersonate/take/' + newUser.id">
                        <v-btn
                            class="ml-2"
                            color="primary"
                            rounded
                        >
                            Impersonate {{ newUser.first_name }}
                        </v-btn>
                    </a>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <snackbar
            :message="snackbarMessage"
            :snackbar="snackbar"
        />
        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
            :headers="headers"
            :items="users"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :page.sync="page"
            :search="search"
            :server-items-length="serverItemsLength"
            :single-select="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-2 mt-3"
            item-key="id"
            @pagination="getUsers"
            @update:sort-by="getUsers"
            @update:sort-desc="getUsers"
        >
            <template v-slot:top>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            v-model="search"
                            background-color="white"
                            class="mx-4"
                            label="Search on anything"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                        />
                    </v-col>
                </v-row>
            </template>
            <template #item.address="{ item }">
                <template v-if="item.address">
                    {{ item.address.city }}, {{ item.address.state }} {{ item.address.postal }}
                </template>
            </template>
            <template #item.donor_donations_count="{ item }">
                {{ item.donor_donations_count | formatNumber }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            :href="'/impersonate/take/' + item.id"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon color="primary">mdi-account-convert</v-icon>
                        </a>
                    </template>
                    <span>Impersonate</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            :href="'/dashboard/admin/create-donor-donation-by-check/' + item.id"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon color="secondary">mdi-currency-usd</v-icon>
                        </a>
                    </template>
                    <span>Donate By Check</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {
    ADMIN_CHECK_REGISTERED,
    ADMIN_EMAIL_DONOR_ANNUAL_RECEIPT,
    ADMIN_REGISTER_USER,
    GET_ADMIN_USERS
} from "../../../store/actions.type";
import Snackbar from "../../../components/shared/Snackbar";
import {CLEAR_ERRORS} from "../../../store/mutations.type";

export default {
    name: "AdminUsersList",
    components: {
        Snackbar,
        AdminHeader,
        Loading
    },
    data() {
        return {
            dialogAddNewUser: false,
            dialogNewUserAdded: false,
            headers: [
                {text: 'Name', align: 'start', value: 'name'},
                {text: 'Email', align: 'start', value: 'email'},
                {text: 'Address', align: 'start', value: 'address'},
                {text: 'User ID', align: 'start', value: 'id'},
                {text: 'Options', value: 'actions', sortable: false, align: 'end'},
            ],
            itemsPerPage: 30,
            loading: true,
            page: 1,
            pageCount: 0,
            search: "",
            serverItemsLength: 0,
            sortBy: 'name',
            sortDesc: false,
            snackbar: false,
            snackbarMessage: "",
            user: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            },
            newUser: {
                first_name: "",
                id: 0,
                last_name: "",
            }
        }
    },
    computed: {
        users() {
            return this.$store.getters.currentAdminUsers['users'];
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        addNewUser() {
            let user = this.user;
            this.$store.dispatch(ADMIN_REGISTER_USER, user)
                .then((data) => {
                    this.user.id = data.message_response.user_id;

                    this.newUser.first_name = this.user.first_name;
                    this.newUser.id = this.user.id;
                    this.newUser.last_name = this.user.last_name;

                    this.dialogAddNewUser = false;
                    this.dialogNewUserAdded = true;

                    // Initialize fields for next new user
                    this.user.first_name = "";
                    this.user.last_name = "";
                    this.user.email = "";
                    this.user.phone = "";

                });
        },
        checkRegistered() {
            let user = this.user;
            this.user.email = this.user.email.toLowerCase();
            this.$store.dispatch(ADMIN_CHECK_REGISTERED, user)
                .then(() => {
                });
        },
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        },
        getUsers() {
            this.loading = true;
            let page = this.page;
            let itemsPerPage = this.itemsPerPage;
            let sortBy = this.sortBy;
            let sortDesc = this.sortDesc;
            let search = this.search;
            this.$store.dispatch(GET_ADMIN_USERS, {page, itemsPerPage, sortBy, sortDesc, search})
                .then((data) => {
                    this.serverItemsLength = data.totalRecords;
                    this.loading = false;
                });
        },
        phoneNumber() {
            const x = this.user.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.user.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
    },
    watch: {
        search() {
            this.page = 1;
            this.getUsers();
        }
    }
}
</script>

<style scoped>::v-deep tbody td {
    height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}</style>
