<template>
  <div>
    <v-data-table
      v-if="currentApplicationMembersFiltered.length"
      :disable-sort="!sortingEnabled"
      :footer-props="{ itemsPerPageOptions: [10] }"
      :headers="headers"
      :hide-default-footer="true"
      :items="currentApplicationMembersFiltered"
      :items-per-page="10"
      class="elevation-2 mb-5"
      sort-by="first_name"
    >
      <template v-slot:top>
        <v-toolbar
          v-if="toolbarTitle"
          color="secondary"
          flat
        >
          {{ toolbarTitle }}
        </v-toolbar>
      </template>
      <!-- All -->
      <template #item.first_name="{ item }">
        {{ item.first_name }} {{ item.last_name }}
        <span v-if="item.nick_name">({{ item.nick_name }})</span>
      </template>

      <!-- Student -->
      <template #item.school_id="{ item }">
        {{ item.student.school_name }}
      </template>

      <!-- Student -->
      <template #item.birth_date="{ item }">
        {{ displayDate(item.birth_date) }}
      </template>

      <!-- Student -->
      <template #item.grade_id="{ item }">
        {{ item.student.grade_name }}
      </template>

      <!-- Actions -->
      <template v-slot:item.actions="{ item }">

        <v-tooltip
          v-if="type === 3 && !item.student.is_preregistration"
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <a
              :href="`/api/households/${household.id}/applications/${application.id}/members/${item.id}/download/scholarship-verification/pdf`"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="secondary">fas fa-file-pdf</v-icon>
            </a>
          </template>
          <span>Download Awards</span>
        </v-tooltip>

        <v-icon
          class="mr-2"
          color="secondary"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          color="red"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    No {{ tableNoData }} have been added. Click Add button.
                </span>
      </template>
    </v-data-table>

    <v-row>
      <v-col>
        <v-btn
          :block="!!isMobile"
          color="primary"
          dark
          rounded
          @click="createItem"
        >
          Add {{ tableTitle }}
        </v-btn>
        <div
          v-if="type === 2 && household && household.address && household.address.street1"
          class="box-with-background my-3"
        >
          We have this address for your household: {{ household.address.street1 }} |
          {{ household.address.city_line }}.
          <div class="mt-2">
            Please
            <span
              style="color: white; text-decoration: underline; cursor: pointer;"
              @click="updateAddress"
            >
                            update your address
                        </span>
            if you've moved.
          </div>
        </div>
        <v-spacer/>
        <v-dialog
          v-model="addEditDialog"
          :fullscreen="isMobile"
          :max-width="dialogMaxWidth"
        >
          <family-member
            :edited-item="temporaryItem"
            :member-type="type"
            :member-type-name="memberTypeName"
            @done="addEditDialog = false"
          />
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <v-card class="pb-5 dialog">
            <v-card-title class="pa-4">
              Confirm
            </v-card-title>
            <v-card-text class="mx-n2">
              <v-row class="mt-2">
                <v-col class="text-h6-mulish">
                  Delete family member?
                </v-col>
              </v-row>
            </v-card-text>
            <div
              v-if="isMobile"
              class="px-4"
            >
              <v-btn
                block
                class="cancel-button"
                rounded
                @click="dialogDelete = false"
              >
                Cancel
              </v-btn>
              <v-btn
                block
                class="mt-5"
                color="primary"
                rounded
                @click="deleteItemConfirm"
              >
                Yes, Delete Family Member
              </v-btn>
            </div>
            <v-card-actions v-else>
              <v-btn
                class="cancel-button"
                rounded
                @click="dialogDelete = false"
              >
                Cancel
              </v-btn>
              <v-btn
                class="ml-2"
                color="primary"
                rounded
                @click="deleteItemConfirm"
              >
                Yes, Delete Family Member
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>
        <v-dialog
          v-model="addressUpdateDialog"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <update-address @done="addressUpdateDialog = false"/>
        </v-dialog>
        <v-snackbar
          v-model="snackbar"
          centered
        >
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FamilyMember from "./FamilyMember";
import UpdateAddress from "./UpdateAddress";
import {
  DELETE_HOUSEHOLD_APPLICATION_MEMBER,
  GET_HOUSEHOLD_APPLICATION,
  GET_HOUSEHOLD_APPLICATION_DOCUMENTS,
  GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS,
} from "../../../store/actions.type";

export default {
  name: "FamilyMemberTable",
  components: {
    FamilyMember,
    UpdateAddress
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
    toolbarTitle: {
      type: String,
      required: false,
      default: null,
    },
    sortingEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    addEditDialog: false,
    addressUpdateDialog: false,
    dialogDelete: false,
    snackbar: false,
    snackbarMessage: "",

    temporaryItem: null,
  }),
  computed: {
    application() {
      return this.$store.getters.currentApplication;
    },
    headers() {
      switch (this.type) {
        case 1:
          return [
            {text: "Name", align: "start", sortable: false, value: "first_name",},
            {text: "Edit or Delete", value: "actions", sortable: false, align: "end"},
          ];
        case 2:
          return [
            {text: "Name", align: "start", sortable: false, value: "first_name",},
            {text: "Email", value: "email"},
            {text: "Phone", value: "phone"},
            {text: "Edit or Delete", value: "actions", sortable: false, align: "end"},
          ];
        case 3:
          return [
            {text: "Name", align: "start", sortable: false, value: "first_name",},
            {text: "Birth Date", value: "birth_date"},
            {text: "School", value: "school_id"},
            {text: "Grade", value: "grade_id"},
            {text: "Download, Edit or Delete", value: "actions", sortable: false, align: "end"},
          ];
      }
    },
    household() {
      return this.$store.getters.currentUserHousehold;
    },
    tableTitle() {
      switch (this.type) {
        case 1:
          return "Other Household Member";
        case 2:
          return "Parent / Guardian";
        case 3:
          return "Student";
      }
    },
    tableNoData() {
      switch (this.type) {
        case 1:
          return "other household members";
        case 2:
          return "parent(s) or guardians";
        case 3:
          return "students";
      }
    },
    defaultItem() {
      return {
        birth_date: "",
        email: "",
        first_name: "",
        income_earnings: "",
        income_none: "",
        income_other: "",
        income_pension: "",
        income_welfare: "",
        is_child: "",
        last_name: "",
        nick_name: "",
        phone: "",
        student: {
          active_military: "",
          comments: "",
          disabled: "",
          esa_enum: "",
          foster: "",
          foster_amount: "",
          grade_id: "",
          is_preregistration: "",
          kindergarten: "",
          previous_scholarship: "",
          previous_scholarship_award_1: "",
          previous_scholarship_award_2: "",
          previous_scholarship_award_3: "",
          previous_scholarship_award_4: "",
          previous_scholarship_award_1_sto: "",
          previous_scholarship_award_2_sto: "",
          previous_scholarship_award_3_sto: "",
          previous_scholarship_award_4_sto: "",
          school_id: "",
          transfer_from_charter_or_public_school: "",
          transfer_from_different_state: "",
          transfer_from_home_school: "",
          tuition_amount: "",
        },
        type: this.type,
      };
    },
    currentApplicationMembersFiltered() {
      return this.$store.getters.currentApplicationMembersFiltered(this.type);
    },
    memberTypeName() {
      switch (this.type) {
        case 1:
          return "Other Household Member";
        case 2:
          return "Parent / Guardian";
        case 3:
          return "Student";
      }
    },
    error() {
      return this.$store.getters.getMessage("application");
    },
    errors() {
      return this.$store.getters.getErrors("application");
    },
  },
  methods: {
    createItem() {
      this.temporaryItem = Object.assign({}, this.defaultItem);
      this.addEditDialog = true;
    },
    editItem(item) {
      this.temporaryItem = Object.assign({}, item);
      this.addEditDialog = true;
    },
    deleteItem(item) {
      this.temporaryItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let application_id = this.$route.params.application_id;
      let household_id = this.$route.params.household_id;
      let item_id = this.temporaryItem.id;
      this.$store.dispatch(DELETE_HOUSEHOLD_APPLICATION_MEMBER, {
        household_id,
        application_id,
        item_id,
      })
        .then(() => {
          this.$store.dispatch(GET_HOUSEHOLD_APPLICATION, {
            household_id,
            application_id,
          })
            .then(() => {
              this.updateDocuments();
              this.updateScholarships();
              this.dialogDelete = false;
            })
        })
        .catch((response) => {
          this.snackbarMessage = response.data.message_response;
          this.snackbar = true;
          this.dialogDelete = false;
        });
    },
    updateAddress() {
      this.addressUpdateDialog = true;
    },
    updateDocuments() {
      const application_id = this.$route.params.application_id;
      const household_id = this.$route.params.household_id;
      this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_DOCUMENTS, {
        household_id,
        application_id,
      });
    },
    updateScholarships() {
      const application_id = this.$route.params.application_id;
      const household_id = this.$route.params.household_id;
      this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS, {
        household_id,
        application_id,
      });
    },
  },
};
</script>

<style scoped>
::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}
</style>
