<template>
    <v-card
        v-if="!editMode"
        :class="content.class"
    >
        <v-row
            class="pa-10 mt-8"
            no-gutters
        >
            <v-col md="6">
                <div>
                    <h2 class="font-weight-bold secondary--text">{{ content.title }}</h2>
                </div>
                <div class="text-apsto-purple">
                    {{ dateDisplay }}
                </div>

                <div
                    class="mt-5"
                    v-html="content.body.description"
                />

                <div
                    v-if="showMore"
                    class="mt-5"
                    v-html="content.body.text"
                />
                <v-btn
                    v-if="!!content.body.text"
                    :block="!!isMobile"
                    class="mt-5 white--text"
                    color="primary"
                    rounded
                    style="min-width: 130px;"
                    @click="showMore = !showMore"
                >
                    {{ showMore ? 'Read Less' : 'Read More' }}
                </v-btn>
                <v-btn
                    :block="!!isMobile"
                    :class="isMobile ? '' : 'ml-1'"
                    class="mt-5"
                    color="primary"
                    rounded
                    style="min-width: 130px;"
                    @click="share(content)"
                >
                    Share
                    <v-icon right>mdi-link</v-icon>
                </v-btn>
                <v-snackbar
                    v-model="snackbar"
                    centered
                >
                    Link copied to clipboard!
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="white"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-card>

    <v-row
        v-else
        class="mt-10"
        justify="center"
        no-gutters
    >
        <v-col>
            <v-text-field
                v-model="localContent.title"
                label="Title"
                @blur="updateContent"
            />

            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localContent.body.date"
                        label="Release Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="localContent.body.date"
                    no-title
                    scrollable
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        class="cancel-button"
                        rounded
                        @click="menu = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="updateContent"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
            Short:
            <wysiwyg-editor
                v-model="localContent.body.description"
                @update="updateContent"
            />

            Read More:
            <wysiwyg-editor
                v-model="localContent.body.text"
                @update="updateContent"
            />
        </v-col>
        <v-col cols="auto">
            <v-icon
                color="error"
                icon
                @click="deleteContent(content.id)"
            >mdi-delete
            </v-icon>
        </v-col>
    </v-row>
</template>

<script>
import { PATCH_CONTENT, DELETE_CONTENT, GET_CONTENTS } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from "../../../../store/mutations.type";


import WysiwygEditor from "../WysiwygEditor";
import UploadImage from "../../../shared/UploadImage";

export default {
    name: "NewsArticle",
    props: {
        content: {
            type: Object,
        },
    },
    components: {
        WysiwygEditor,
        UploadImage,
    },
    data() {
        return {
            loading: false,
            localContent: null,
            menu: false,
            showMore: false,
            snackbar: false,
        };
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
        dateDisplay() {
            return moment(this.localContent.body.date).tz("US/Mountain").format("dddd LL")
        }
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let content = this.localContent;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    this.menu = false;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        },
        deleteContent(content_id) {
            this.$store.dispatch(DELETE_CONTENT, content_id).then(() => {
                this.$store.dispatch(GET_CONTENTS);
            });
        },
        share(content) {
            let url = window.location.href.split("?")[0] + "?news=" + content.id;
            let title = this.localContent.title;
            //sanitize the description
            let text = this.localContent.body.description.replace(/<[^>]+>/g, '');
            if (navigator.share) {
                navigator.share({
                    title: title,
                    text: text,
                    url: url,
                });
            } else {
                navigator.clipboard.writeText(url);
                this.snackbar = true;
            }
        },
    },
    created() {
        this.localContent = this.content;
    },
};
</script>
