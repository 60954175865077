<template>
    <vue-editor
        :editorOptions="editorOptions"
        :disabled="disabled"
        v-model="localValue"
        @focus="lockFab"
        @blur="update"
    ></vue-editor>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

import { PATCH_CONTENT } from "../../../store/actions.type";
import { SET_FAB_ENABLED } from "../../../store/mutations.type";

let Parchment = Quill.import('parchment');

let config = {
    scope: Parchment.Scope.INLINE,
};

let MClass = new Parchment.Attributor.Class('mark', 'dom-mark', config);
Quill.register(MClass, true)

var icons = Quill.import('ui/icons');
icons['number'] = '<i class="mdi mdi-numeric-1-circle" aria-hidden="true"></i>';


export default {
    name: "WysiwygEditor",
    components: {
        VueEditor,
    },
    props: {
        value: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editorFocused: null,
            editorOptions: {
                modules: {
                    toolbar: {
                        container: [
                            [
                                {
                                    header: [],
                                },
                            ],
                            [
                                {
                                    size: ["small", false, "large", "huge"],
                                },
                            ],
                            ["bold", "italic", "underline", "strike"],
                            [
                                {
                                    align: "",
                                },
                                {
                                    align: "center",
                                },
                                {
                                    align: "right",
                                },
                                {
                                    align: "justify",
                                },
                            ],
                            ["blockquote", "code-block", "number"],
                            [
                                {
                                    list: "ordered",
                                },
                                {
                                    list: "bullet",
                                },
                                {
                                    list: "check",
                                },
                            ],
                            [
                                {
                                    script: "sub",
                                },
                                {
                                    script: "super",
                                },
                            ],
                            [
                                {
                                    indent: "-1",
                                },
                                {
                                    indent: "+1",
                                },
                            ],
                            [
                                {
                                    color: [
                                        "#4C226B",
                                        "#31164A",
                                        "#55A9AD",
                                        "#DDEEEE",
                                        "#EEF6F7",
                                        "#FFFFFF",
                                        "#000000",
                                    ],
                                },
                                {
                                    background: [
                                        "#4C226B",
                                        "#31164A",
                                        "#55A9AD",
                                        "#DDEEEE",
                                        "#EEF6F7",
                                        "#FFFFFF",
                                        "#000000",
                                    ],
                                },
                            ],
                            [
                                {
                                    direction: "rtl",
                                },
                            ],
                            ["link", "image"],
                            ["clean"],
                        ],
                        handlers: {
                            number: this.myHandleNumber,
                        },
                    },
                },
            },
        };
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        update() {
            this.$nextTick(() => {
                this.$emit("update");
            });
        },
        lockFab(quill) {
            this.editorFocused = quill;
            this.$store.commit(SET_FAB_ENABLED, false);
        },
        myHandleNumber() {
            if (this.editorFocused) {
                let quill = this.editorFocused;
                quill.format('mark', 'purplecircle');

                // var index = quill.getSelection(true).index;
                // var number = prompt("Enter the number");
                // var data = { text: number };
                // quill.insertEmbed(index, "numberblock", data);
            }
        },
    },
};
</script>


