<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row>
      <v-col>
        <admin-header page-title="Review Household Application"/>
      </v-col>
    </v-row>
    <v-row v-if="household">
      <v-col
        class="mx-4"
        md="8"
      >
        <div class="mt-n5 box-with-background">
          {{ household.name }}
          <div v-if="household.address">
            {{ household.address.street1 }}
            <div v-if="household.address.street2">
              {{ household.address.street2 }}
            </div>
            <div>
              {{ household.address.city }},
              {{ household.address.state }}
              {{ household.address.postal }}
            </div>
          </div>
        </div>
        <div class="mt-4 box-with-background purple-background">
          Application for School Year {{ householdApplication.school_year_name }}
        </div>
        <revisions
          :application="householdApplication"
          application-type="household"
        />
        <div class="ml-n2">
          <v-card-title>Family Members</v-card-title>
          <v-card-text style="font-size: 1.05rem;">
            <family-member-income-table/>
          </v-card-text>

          <div v-if="!householdApplication.is_preregistration">
            <v-card-title>Financial Hardship</v-card-title>
            <v-card-text>
              <financial-hardship-section class="mt-4"/>
            </v-card-text>

            <v-card-title>Student Questionnaire(s)</v-card-title>
            <v-card-text class="mt-n4">
              <student-questionnaire
                :school-year-name="householdApplication.school_year_name"
                :students="householdApplicationStudents"
              />
            </v-card-text>

            <documents-approve-decline :canEdit="true"/>

            <v-row>
              <v-col cols="auto">
                <v-card-title class="">Scholarships</v-card-title>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="showScholarships"
                  color="secondary"
                >
                  <template v-slot:label>
                    <div> Show Scholarships Table</div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <v-card-text
              v-if="showScholarships"
              style="font-size: 1.05rem;"
            >
              <scholarships/>
            </v-card-text>

            <v-divider/>
            <v-card-title class="">Affidavit</v-card-title>
            <v-card-text style="font-size: 1.05rem;">
              <div
                v-if="householdApplication.agree_to_terms"
                class="mt-2"
              >
                {{ householdApplication.responsible_party_name }} agreed to the terms.
              </div>
              <div
                v-else
                class="missing-data mt-2"
              >
                Terms have not been agreed to.
              </div>
            </v-card-text>
            <v-divider/>
          </div>
          <div v-else class="mt-n5">
            <v-card-title class="">Acknowledgement</v-card-title>
            <v-card-text style="font-size: 1.05rem;">
              <div
                v-if="householdApplication.agree_to_terms"
                class="mt-2"
              >
                {{ householdApplication.responsible_party_name }} acknowledged that this is a
                pre-registration, not an
                application.
              </div>
              <div
                v-else
                class="missing-data mt-2"
              >
                Acknowledgement has not been agreed to.
              </div>
            </v-card-text>
            <v-divider/>
          </div>
          <v-card-title class="">Status</v-card-title>
          <v-card-text style="font-size: 1.05rem;">
            <div
              v-if="!statusComplete"
              class="missing-data mt-2"
            >
              Application Incomplete
            </div>
            <status-selector
              v-else
              v-model="householdApplication.status_id"
            />
          </v-card-text>

          <v-divider/>
          <v-card-actions class="pb-4 mt-4">
            <v-btn
              :to="{
                                name: 'admin-household-show',
                                params: { household_id: this.$route.params.household_id },
                            }"
              class="cancel-button"
              rounded
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="disableSaveButton"
              class="ml-2"
              color="primary"
              rounded
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>

        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  GET_HOUSEHOLD,
  GET_HOUSEHOLD_APPLICATION, GET_HOUSEHOLD_APPLICATION_AS_ADMIN,
  PATCH_HOUSEHOLD_APPLICATION_STATUS
} from "../../../../../store/actions.type";
import AdminHeader from "../../../main/AdminHeader";
import Loading from "../../../../../components/shared/Loading";
import StatusSelector from "../../../../../components/shared/StatusSelector";
import SummaryTable from "../../../../../components/household/application/SummaryTable";
import FinancialHardshipSection from "./FinancialHardshipSection";
import Scholarships from "../../../../../components/household/application/Scholarships";
import DocumentsApproveDecline from "./DocumentsApproveDecline";
import StudentQuestionnaire from "./StudentQuestionnaire";
import Revisions from "../../../../../components/shared/Revisions";
import FamilyMemberIncomeTable from "../../../../../components/household/application/FamilyMemberIncomeTable.vue";

export default {
  name: "AdminHouseholdApplicationReview",
  components: {
    FamilyMemberIncomeTable,
    DocumentsApproveDecline,
    Scholarships,
    FinancialHardshipSection,
    SummaryTable,
    StatusSelector,
    StudentQuestionnaire,
    AdminHeader,
    Loading,
    Revisions
  },
  data() {
    return {
      loading: true,
      showScholarships: false,
      snackbar: false,
      snackbarMessage: "",
    }
  },
  computed: {
    disableSaveButton() {
      return !this.isStatusComplete
    },
    household() {
      return this.$store.getters.currentHousehold;
    },
    householdApplication() {
      return this.$store.getters.currentApplication;
    },
    householdApplicationDocuments() {
      return this.$store.getters.currentApplicationDocuments;
    },
    householdApplicationStudents() {
      return this.$store.getters.currentApplicationMembersFiltered(3);
    },
    isStatusComplete() {

      let parentsCount = this.householdApplication.members.filter(member => member.type === 2).length;
      let studentsCount = this.householdApplication.members.filter(member => member.type === 3).length;
      let unsubmittedDocumentsCount = this.householdApplicationDocuments.filter(document => !document.document_submitted).length;

      return !(parentsCount === 0 ||
        studentsCount === 0 ||
        unsubmittedDocumentsCount > 0 ||
        !this.householdApplication.agree_to_terms)

    },
    statusComplete() {
      return this.isStatusComplete;
    },
  },
  methods: {
    getHouseholdApplication() {
      this.loading = true;
      let household_id = this.$route.params.household_id;
      let application_id = this.$route.params.application_id;
      this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_AS_ADMIN, {household_id, application_id})
        .then(() => {
          this.$store.dispatch(GET_HOUSEHOLD, household_id)
          this.loading = false;
        });
    },
    save() {
      let household_id = this.$route.params.household_id
      let household_application_id = this.$route.params.application_id;
      let status = this.householdApplication.status_id;
      this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_STATUS, {household_id, household_application_id, status})
        .then(() => {
          this.$router.push({
            name: 'admin-household-show',
            params: {
              household_id: this.$route.params.household_id
            }
          })
        })
        .catch((response) => {
          console.log("error: ", response);
        });
    }
  },
  mounted() {
    this.getHouseholdApplication();
  }
}
</script>

<style scoped>
.missing-data {
  color: #ff0000;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-left: 12px;
  padding-right: 0;
}
</style>
