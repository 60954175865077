<template>
    <div>
        <div class="text-h5"> Donation Information</div>
        <v-text-field
            id="federal_ein"
            v-model.trim="company.federal_ein"
            :error="!!errors.federal_ein"
            :error-messages="errors.federal_ein ? errors.federal_ein : null"
            background-color="white"
            class="mt-6"
            filled
            label="Federal EIN"
            @input="federalEIN"
        />
        <v-text-field
            id="naics_code"
            v-model.trim="company.naics_code"
            :error="!!errors.naics_code"
            :error-messages="errors.naics_code ? errors.naics_code : null"
            background-color="white"
            filled
            label="NAICS Code (Leave blank unless you are an insurance company and you plan to apply this donation against your premium tax liability)"
        />
        <v-select
            id="tax_credit_type"
            v-model="corporatePledge.tax_credit_type"
            :error="!!errors['corporatePledge.tax_credit_type']"
            :error-messages="errors['corporatePledge.tax_credit_type'] ? errors['corporatePledge.tax_credit_type'] : null"
            :items="taxCreditTypes"
            autocomplete="on"
            background-color="white"
            filled
            item-key="id"
            item-text="value"
            item-value="id"
            label="Tax Credit Type"
            persistent-hint
        />
        <v-currency-field
            id="amount"
            v-model.trim="corporatePledge.amount"
            :error="!!errors['corporatePledge.amount']"
            :error-messages="errors['corporatePledge.amount'] ? errors['corporatePledge.amount'] : null"
            autocomplete="amount"
            background-color="white"
            filled
            label="Requested Donation Amount"
        />

        <div v-if="company.organization_type_enum === 3">
            <v-select
                id="subs_subsidiary"
                v-model="corporatePledge.subs_subsidiary"
                :error="!!errors['corporatePledge.subs_subsidiary']"
                :error-messages="errors['corporatePledge.subs_subsidiary'] ? errors['corporatePledge.subs_subsidiary'] : null"
                :items="subsSubsidiaryOptions"
                background-color="white"
                filled
                item-key="id"
                item-text="value"
                item-value="id"
                label="Are You a Qualified Subchapter S Subsidiary"
                name="subs_subsidiary"
            />
            <v-text-field
                v-if="corporatePledge.subs_subsidiary === 1"
                id="subs_parent_info"
                v-model.trim="corporatePledge.subs_parent_info"
                :error="!!errors['corporatePledge.subs_parent_info']"
                :error-messages="errors['corporatePledge.subs_parent_info'] ? errors['corporatePledge.subs_parent_info'] : null"
                background-color="white"
                filled
                label="Parent Company Name and Federal EIN"
            />
        </div>
        <v-card class="pa-5">
            <new-company-corporate-pledge-recommendations
                v-model="corporatePledge.recommendations"
                class="mt-n6 pb-2"
            />
        </v-card>
    </div>
</template>

<script>
import { GET_TAX_CREDIT_INFO } from "../../../../store/actions.type";
import NewCompanyCorporatePledgeRecommendations from "./NewCompanyCorporatePledgeRecommendations";
import CorporatePledgeReviewPanel from "../CorporatePledgeReviewPanel.vue";

export default {
    name: "NewCompanyCorporatePledgeForm",
    components: { NewCompanyCorporatePledgeRecommendations },
    props: {
        company: {
            type: Object,
            required: true
        },
        corporatePledge: {
            type: Object,
            required: true,
        },
        create: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("companies");
        },
        errors() {
            return this.$store.getters.getErrors("companies");
        },
        subsSubsidiaryOptions() {
            return this.$store.getters.subsSubsidiaryOptions;
        },
        taxCreditInfo() {
            return this.$store.getters.taxCreditInfo;
        },
        taxCreditTypes() {
            return this.$store.getters.taxCreditTypes;
        },
    },
    methods: {
        federalEIN() {
            const x = this.company.federal_ein
                .replace(/\D/g, "")
                .match(/(\d{0,2})(\d{0,7})/);
            this.company.federal_ein = !x[2] ? x[1] : x[1] + (x[2] ? "-" + x[2] : "");
        },
        getTaxCreditInfo() {
            this.loading = true;
            this.$store.dispatch(GET_TAX_CREDIT_INFO)
                .then(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getTaxCreditInfo();
    }
}
</script>

