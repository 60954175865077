<template>
    <v-row>
        <v-col>
            <school-header :page-title="pageTitle" />
            <div class="box-with-background ml-4">
                <v-row>
                    <v-col md="8">
                        Hi {{ currentUser.first_name }},
                        <div class="mt-4">
                            You can exit and re-enter this agreement without losing any data. We save your entries as
                            you
                            make them.
                        </div>
                        <div class="mt-4">
                            You can click section headings to jump ahead or back. Each section includes a NEXT button
                            but
                            you don't need to use it.
                        </div>
                        <div class="mt-4">
                            When you're done, check the box in the last section at the bottom. That will submit your
                            agreement
                            to us for review. We don't look at your agreement until it's complete and the last section
                            will
                            let you know if anything's missing.
                        </div>
                        <!--        <div class="mt-4">-->
                        <!--          If you get stuck, click the HELP button at the bottom of the page to message us. We'll get back-->
                        <!--          to you within 24 hours.-->
                        <!--        </div>-->
                    </v-col>
                </v-row>
            </div>
            <v-stepper
                v-model="e6"
                class="mt-6 ml-4"
                non-linear
                vertical
            >

                <!--Questionnaire-->
                <v-stepper-step
                    :complete="e6 > 1"
                    editable
                    step="1"
                    id="step-1"
                >
                    Questionnaire
                </v-stepper-step>
                <v-stepper-content step="1">
                    <v-card
                        class="mb-5 pa-4"
                        color="primaryVeryLight"
                        min-height="200px"
                    >
                        <school-questionnaire />
                    </v-card>
                    <v-btn
                        :block="isMobile"
                        color="secondary"
                        rounded
                        @click="goToStep(2)"
                    >Next
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step
                    :complete="e6 > 2"
                    editable
                    step="2"
                    id="step-2"
                >
                    Tuition Schedule
                    <small
                        class="subtitle-2 font-weight-medium"
                        style="line-height: 1rem;"
                    >
                        <v-row class="pt-2">
                            <v-col md="6">
                                Enter tuition amounts for grades you offer. (Leave amount empty for grades you don't.)
                            </v-col>
                        </v-row>
                    </small>
                </v-stepper-step>
                <v-stepper-content step="2">
                    <v-card
                        class="mb-5 pa-4"
                        color="primaryVeryLight"
                        min-height="200px"
                    >
                        <school-tuition-table />
                        <v-row class="pt-2">
                            <v-col md="6">
                                <div class="text-h7-mulish">
                                    Tuition Schedule Comments (Optional)
                                </div>
                                <v-textarea
                                    v-model="schoolApplication.comments"
                                    :error="!!errors['comments']"
                                    :error-messages="errors['comments'] ? errors['comments'] : null"
                                    auto-grow
                                    background-color="secondaryLight"
                                    @change="updateSchoolApplicationTextarea()"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-btn
                        :block="isMobile"
                        color="secondary"
                        rounded
                        @click="goToStep(3)"
                    >Next
                    </v-btn>
                </v-stepper-content>

                <!--Terms-->
                <v-stepper-step
                    :complete="e6 > 3"
                    editable
                    step="3"
                    id="step-3"
                >
                    Terms
                </v-stepper-step>
                <v-stepper-content step="3">
                    <v-card
                        class="mb-5 pa-4"
                        color="primaryVeryLight"
                        min-height="200px"
                    >
                        <school-terms />
                    </v-card>
                    <v-btn
                        :block="isMobile"
                        :to="{ name: 'school-applications-list' }"
                        class="cancel-button"
                        rounded
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        :block="isMobile"
                        :disabled="!!schoolApplication.under_review"
                        :to="{ name: 'school-applications-list' }"
                        class="ml-2"
                        color="primary"
                        rounded
                    >Finished
                    </v-btn>
                </v-stepper-content>
            </v-stepper>
        </v-col>
    </v-row>
</template>

<script>
import { GET_SCHOOL_APPLICATION, PATCH_SCHOOL_APPLICATION_TEXTAREA } from "../../../../store/actions.type";
import SchoolTerms from "../../../../components/school/application/SchoolTerms";
import SchoolHeader from "../../../../components/school/SchoolHeader";
import SchoolQuestionnaire from "../../../../components/school/application/SchoolQuestionnaire";
import SchoolTuitionTable from "../../../../components/school/application/SchoolTuitionTable";


export default {
    name: "SchoolApplicationEdit",
    components: {
        SchoolTuitionTable,
        SchoolTerms,
        SchoolHeader,
        SchoolQuestionnaire,
    },
    data() {
        return {
            e6: 1,
            file: null,
            rules: [
                value => !value || value.size < 5000000 || 'File size should be less than 5 MB!',
            ],
            saving: false,
        }
    },
    computed: {
        schoolApplication() {
            return this.$store.getters.currentSchoolApplication;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        pageTitle() {
            return "Partner Agreement for School Year " + this.schoolApplication.school_year_name;
        },
        error() {
            return this.$store.getters.getMessage("schoolApplication");
        },
        errors() {
            return this.$store.getters.getErrors("schoolApplication");
        },
    },
    methods: {
        getSchoolApplication() {
            let school_application_id = this.$route.params.application_id;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(GET_SCHOOL_APPLICATION, { school_id, school_application_id })
                .then(() => {
                    this.loading = false;
                });
        },
        updateSchoolApplicationTextarea() {
            this.saving = true;
            let school_application_id = this.$route.params.application_id;
            let school_id = this.$route.params.school_id;
            let schoolApplication = this.schoolApplication;
            this.$store.dispatch(PATCH_SCHOOL_APPLICATION_TEXTAREA, {
                school_id,
                school_application_id,
                schoolApplication,
            })
                .then(() => {
                    this.saving = false;
                });
        },
        goToStep(step) {
            this.e6 = step;
            setTimeout(() => {
                console.log('scrolling to step-' + step);

                window.scrollTo({
                    behavior: 'smooth',
                    top: document.getElementById('step-' + step).getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top - 150
                });
            }, 800);
        },
    },
    mounted() {
        this.getSchoolApplication();
    }
}
</script>

<style scoped>
::v-deep .v-textarea textarea {
    padding: 10px;
}

::v-deep .v-stepper__wrapper {
    padding-bottom: 3px !important;
}
</style>



