<template>
  <div>
    <v-btn
      :block="isMobile"
      color="primary"
      rounded
      @click="showForm = true"
    >
      <v-icon
        left
        style="color: white;"
      > mdi-download
      </v-icon>
      Get Quarterly Reports
    </v-btn>
    <v-dialog
      v-model="showForm"
      width="350"
    >
      <div class="box-with-background">
        <div class="text-h6-mulish">Get Quarterly Reports</div>
      </div>
      <v-card
        class="pa-4"
        style="border-radius: 0; width: 350px;"
      >
        <v-row>
          <v-col>
            <v-select
              v-model="year"
              :error="!!errors['year']"
              :error-messages="errors['year'] ? errors['year'] : null"
              :items="years"
              filled
              item-text="name"
              item-value="id"
              label="Year"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="quarter"
              :error="!!errors['quarter']"
              :error-messages="errors['quarter'] ? errors['quarter'] : null"
              :items="quarters"
              filled
              item-text="name"
              item-value="id"
              label="Quarter"
            />
          </v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col cols="12">
            <v-btn
              :disabled="disabled"
              :href="`/api/companies/${$route.params.company_id}/employee-donations/download/${quarter}/${year}/pdf`"
              block
              color="secondary"
              target="_blank"
              @click="showForm = false"
            >
              <v-icon left> mdi-file-pdf-box</v-icon>
              Download PDF
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="disabled"
              :href="`/api/companies/${$route.params.company_id}/employee-donations/download/${quarter}/${year}`"
              block
              class="mt-n2"
              color="secondary"
              @click="showForm = false"
            >
              <v-icon left> mdi-table</v-icon>
              Download Spreadsheet
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "YearAndQuarterSelectors",
  data() {
    return {
      quarter: null,
      quarters: [
        {id: 1, name: "1"},
        {id: 2, name: "2"},
        {id: 3, name: "3"},
        {id: 4, name: "4"},
      ],
      showForm: false,
      year: null,
      years: []
    }
  },
  computed: {
    disabled() {
      return !this.year || !this.quarter;
    },
    error() {
      return this.$store.getters.getMessage("employeeDonations");
    },
    errors() {
      return this.$store.getters.getErrors("employeeDonations");
    },
  },
  mounted() {
    this.yearNow = new Date().getFullYear();
    this.years = [
      {id: this.yearNow, name: this.yearNow},
      {id: this.yearNow - 1, name: this.yearNow - 1},
    ]
  }

}
</script>

<style scoped>
.box-with-background {
  text-align: center;
}

::v-deep .v-dialog.v-dialog--active .v-btn {
  padding: 20px 12px !important;
}
</style>
