<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col class="px-3 py-0">
          <v-form>
            <v-radio-group
              v-model="form.action"
              :error="!!errors['action']"
              :error-messages="errors['action'] ? errors['action'] : null"
              class="bottom-border"
              column
            >
              <template v-slot:label>
                <div class="pb-2 award-caption">
                  Accept or decline this award offer:
                </div>
              </template>
              <v-radio
                color="secondary"
                label="Accept full amount"
                value="1"
              />
              <v-radio
                color="secondary"
                label="Accept partial amount"
                value="3"
              />
              <v-radio
                color="secondary"
                label="Decline"
                value="2"
              />
            </v-radio-group>
            <div v-if="form.action === '3'">
              <div class="award-caption">
                Amount You Are Accepting
              </div>
              <v-text-field
                id="amount"
                v-model.trim="form.amount"
                :error="!!errors['amount']"
                :error-messages="errors['amount'] ? errors['amount'] : null"
                class="mt-3"
                filled
              />
            </div>
            <div v-if="form.action === '2' || form.action === '3'">
              <div class="award-caption">
                Select reason {{ actionDescription }}
              </div>
              <school-award-declination-selector
                v-model="form.reason_enum"
                class="mt-3"
              />
              <div class="award-caption">
                Comments {{ commentsLabel }}
              </div>
              <v-textarea
                v-model="form.comments"
                :error="!!errors['comments']"
                :error-messages="errors['comments'] ? errors['comments'] : null"
                auto-grow
                background-color="secondaryLight"
              />
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="py-4">
      <v-spacer/>
      <v-btn v-if="displayCancelButton" class="cancel-button" rounded @click="$emit('done')"> Cancel</v-btn>
      <v-btn :disabled="disabled" class="ml-3" color="primary" @click="save"> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import SchoolAwardDeclinationSelector from "../../../../../components/shared/SchoolAwardDeclinationSelector";
import {POST_SCHOOL_STUDENT_AWARD_ACTION} from "../../../../../store/actions.type";

export default {
  name: "AwardActionAdd",
  components: {
    SchoolAwardDeclinationSelector
  },
  data: () => ({
    saving: false,
    form: {
      action: "0",
      amount_offered: 0,
      reason_enum: 0
    },
  }),
  props: {
    award: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actionDescription() {
      return this.form.action === "2" ? "declining this award" : "accepting less than the full amount"
    },
    applicationStudentAward() {
      return this.$store.getters.currentApplicationStudentAward;
    },
    schoolStudentAwardActions() {
      return this.applicationStudentAward.application_student_award_actions;
    },
    commentsLabel() {
      return this.form.reason_enum === 5 ? "(Required)" : (this.form.reason_enum === 0 ? "" : "(Optional)")
    },
    disabled() {
      return this.form.action === "0" ? true : false;
    },
    displayCancelButton() {
      return this.schoolStudentAwardActions.length > 0;
    },
    error() {
      return this.$store.getters.getMessage("schoolStudent");
    },
    errors() {
      return this.$store.getters.getErrors("schoolStudent");
    },
  },
  methods: {
    save() {
      this.saving = true;
      let school_id = this.$route.params.school_id
      let application_student_school_id = this.$route.params.application_student_school_id;
      let application_student_award_id = this.award.id;

      let awardAction = this.form;
      awardAction.amount_offered = this.award.amount;

      this.$store.dispatch(POST_SCHOOL_STUDENT_AWARD_ACTION,
        {
          school_id,
          application_student_school_id,
          application_student_award_id,
          awardAction
        }
      )
        .then(() => {
          this.saving = false;
          this.$emit("done");
        });
    },
  },
};
</script>

<style scoped>
.errors-message {
  color: red;
  padding-left: 5px;
}

.award-caption {
  color: black;
  font-size: 1.1rem;
}

::v-deep .v-textarea textarea {
  padding: 10px;
}

::v-deep .v-label.theme--light {
  color: black;
}
</style>
