<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row align="center" class="ml-1 mr-0 mt-5">
            <v-col class="pa-0" cols="auto">
                <admin-header :page-title="pageTitle"/>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn
                    :href="downloadHref"
                    color="primary"
                    rounded
                >
                    <v-icon left style="color: white;"> mdi-download</v-icon>
                    <span style="color: white;">Download This List</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="rebuilding" class="mt-6">
            <v-col class="text-center">
                <v-card class="pa-5">
                    <h3>Updating . . .</h3>
                    <v-progress-circular
                        :size="50"
                        :width="7"
                        class="mt-5"
                        color="#55A9AD"
                        indeterminate
                    />
                </v-card>
            </v-col>
        </v-row>
        <v-data-table
            v-if="!rebuilding"
            :footer-props="{ itemsPerPageOptions:[10,20,30,-1] }"
            :headers="headers"
            :item-class="rowClass"
            :items="fundSections"
            :items-per-page="-1"
            :single-select="true"
            class="elevation-2 mt-6"
            fixed-header
            group-by="year_month_group_by"
            group-desc
            height="100vh"
            item-key="id"
            @click:row="editFundSection"
        >
            <template v-slot:group.header="{items, isOpen, toggle}">
                <th colspan="8">
                    <v-icon @click="toggle">
                        {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ items[0].year_month }}
                </th>
            </template>
            <template #item.charitable={item,index}>
                <div v-if="item.charitable >= 0">
                    {{ item.charitable | formatNumber }}
                </div>
                <div v-else>
                    ({{ -item.charitable | formatNumber }})
                </div>
            </template>
            <template #item.corporate_disabled_displaced={item,index}>
                <div v-if="item.corporate_disabled_displaced >= 0">
                    {{ item.corporate_disabled_displaced | formatNumber }}
                </div>
                <div v-else>
                    ({{ -item.corporate_disabled_displaced | formatNumber }})
                </div>
            </template>
            <template #item.corporate_low_income={item,index}>
                <div v-if="item.corporate_low_income >= 0">
                    {{ item.corporate_low_income | formatNumber }}
                </div>
                <div v-else>
                    ({{ -item.corporate_low_income | formatNumber }})
                </div>
            </template>
            <template #item.original_tax_credit={item,index}>
                <div v-if="item.original_tax_credit >= 0">
                    {{ item.original_tax_credit | formatNumber }}
                </div>
                <div v-else>
                    ({{ -item.original_tax_credit | formatNumber }})
                </div>
            </template>
            <template #item.switcher={item,index}>
                <div v-if="item.switcher >= 0">
                    {{ item.switcher | formatNumber }}
                </div>
                <div v-else>
                    ({{ -item.switcher | formatNumber }})
                </div>
            </template>
            <template #item.total={item,index}>
                <div v-if="item.total >= 0">
                    {{ item.total | formatNumber }}
                </div>
                <div v-else>
                    ({{ -item.total | formatNumber }})
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {GET_FUND_SECTIONS} from "../../../store/actions.type";

export default {
    name: "AdminFundSectionList",
    components: {
        AdminHeader,
        Loading
    },
    data() {
        return {
            defaultItem: {
                name: '',
                approved: '',
            },
            dialog: false,
            headers: [
                {text: 'Type', value: 'type'},
                {text: 'Displaced', align: 'end', value: 'corporate_disabled_displaced'},
                {text: 'Low Income', align: 'end', value: 'corporate_low_income'},
                {text: 'Original', align: 'end', value: 'original_tax_credit'},
                {text: 'Switcher', align: 'end', value: 'switcher'},
                {text: 'Charitable', align: 'end', value: 'charitable'},
                {text: 'Total', align: 'end', value: 'total'},
            ],
            loading: true,
            rebuilding: false,
        }
    },
    computed: {
        downloadHref() {
            return "/api/admin/funds/download-fund-overview/";
        },
        fundSections() {
            return this.$store.getters.currentFundSections;
        },
        pageTitle() {
            let month = new Date().getMonth();
            let year = month < 7 ? new Date().getFullYear() : new Date().getFullYear() + 1;
            return "Funds Activity Overview For FYE June 30th, " + year;
        }
    },
    methods: {
        getFundSections() {
            this.loading = true;
            this.$store.dispatch(GET_FUND_SECTIONS)
                .then(() => {
                    this.loading = false;
                })
        },
        editFundSection(item) {
            console.log(item)
            item.year = item.year.substring(0, 4);
            this.$router.push({
                name: "admin-fund-section-donations-show",
                params: {year: item.year, month: item.month}
            });
        },
        rowClass(item) {
            if (item.order >= 5 && item.order <= 7) {
                const rowClass = 'font-weight-thin font-italic'
                return rowClass;
            }
            return "";
        }
    },
    mounted() {
        this.getFundSections();
    },
}
</script>

<style scoped>

::v-deep th:not([role=columnheader]) {
    background-color: var(--apsto-purple-very-light);
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
    cursor: default;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.font-italic) {
    background-color: var(--apsto-teal-very-light);
    cursor: pointer;
}
</style>
