import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_SCHOOL_ADMIN,
    GET_SCHOOL_ADMIN,
    GET_SCHOOL_ADMINS,
    GET_SCHOOL_INVITE,
    GET_SCHOOL_INVITES,
    PATCH_SCHOOL_ADMIN,
    TRANSFER_SCHOOL_ADMIN
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_SCHOOL_ADMIN,
    SET_SCHOOL_ADMINS,
    SET_SCHOOL_INVITE,
    SET_SCHOOL_INVITES
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        schoolAdmin: {},
        schoolAdmins: [],
        schoolInvite: {},
        schoolInvites: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentSchoolAdmins(state) {
        return state.schoolAdmins;
    },
    currentSchoolInvites(state) {
        return state.schoolInvites;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_SCHOOL_ADMINS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${id}/admins`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_ADMINS, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_ADMIN](context, {school_id, school_admin_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/admins/${school_admin_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_ADMIN, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolAdmins');
                    reject(error.response);
                });
        });
    },
    [TRANSFER_SCHOOL_ADMIN](context, {school_id, user_id}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/admins/${user_id}/transfer-primary`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_ADMIN, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolAdmins');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_ADMIN](context, {school_id, user_id, field_name, field_value}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/admins/${user_id}/${field_name}/${field_value}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_ADMIN, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolAdmins');
                    reject(error.response);
                });
        });
    },
    [DELETE_SCHOOL_ADMIN](context, {school_id, user_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/schools/${school_id}/admins/${user_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_INVITES](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${id}/invites`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_INVITES, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_INVITE](context, {school_id, school_invite_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/invites/${school_invite_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_INVITE, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolAdmins');
                    reject(error.response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_SCHOOL_ADMINS](state, data) {
        state.schoolAdmins = data;
    },
    [SET_SCHOOL_ADMIN](state, data) {
        state.schoolAdmin = data;
    },
    [SET_SCHOOL_INVITES](state, data) {
        state.schoolInvites = data;
    },
    [SET_SCHOOL_INVITE](state, data) {
        state.schoolInvite = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
