<template>
    <message-input
        v-on="$listeners"
        @click="submit"
        @input="(msg) => message.body = msg"
        @submit="submit"
        :disabled="disabled || loading"
        :loading="loading"
        ref="messageInput"
        :placeholder="placeholder"
        :preview="preview"
    />
</template>

<script>
import MessageInput from './MessageInput.vue'

import {
    POST_MESSAGE,
    UPDATE_MESSAGE,
    UPDATE_CHAT
} from "../../../store/actions.type";

export default {
    name: "SendMessage",
    components: {
        MessageInput
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        initialMessage: {
            type: Object,
            default: function () {
                return {
                    id: null,
                    body: "",
                };
            },
        },
        conversation: {
            type: Object,
            default: null,
        },
        preview: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            defaultMessage: {
                id: null,
                body: "",
            },
            message: {
                id: null,
                body: "",
            },
            loading: false,
        };
    },
    computed: {
        isEdit() {
            return this.message.id ? true : false;
        },
        placeholder() {
            return !this.disabled || this.preview ? 'Type here...' : 'Chat disabled'
        }
    },
    mounted() {
        this.message = Object.assign({}, this.initialMessage);
        this.$refs.messageInput.message = this.message.body
    },
    methods: {


        clearMessage() {
            this.$refs.messageInput.message = ''
            this.message = Object.assign({}, this.defaultMessage);
            //If not editing, then focus on the input again for subsequent messages
            if (!this.isEdit) {
                this.focusInput();
            }
        },
        focusInput() {
            this.$nextTick(() => {
                this.$refs.messageInput.$refs.input.focus()
            })
        },
        resetMessage() {
            this.message = Object.assign({}, this.initialMessage);
        },
        submit() {
            if (this.loading) {
                return;
            }
            let self = this;

            this.isShowingEmojiDialog = false;


            this.loading = true;

            const conversation = this.conversation;
            var message = this.message;

            message.body = message.body.trim();

            if (message.body.length == 0) {
                this.loading = false;
                return;
            }

            let regexLink =
                /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;



            if (this.isEdit) {
                if (this.conversation.type == 'meeting') {
                    var action = UPDATE_CHAT;
                } else {
                    var action = UPDATE_MESSAGE
                }
                this.$store
                    .dispatch(action, message)
                    .then(() => {
                        self.clearMessage();
                        this.loading = false;
                    })
                    .catch((e) => {
                        self.resetMessage();
                        this.loading = false;
                    });
            } else {
                this.$store
                    .dispatch(POST_MESSAGE, { conversation, message })
                    .then(() => {
                        self.clearMessage();
                        this.loading = false;
                        this.$emit("scroll");
                    })
                    .catch(() => {
                        self.resetMessage();
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
