<template>
  <loading v-if="loading"/>
  <div v-else>
    <admin-header :page-title="pageTitle"/>
    <v-row>
      <v-col>
        <v-card class="menu-card">
          <v-row>
            <v-col md="6">

              <v-row align="center" class="menu-item">
                <v-col class="menu-number"> 1</v-col>
                <div
                  class="menu-title"
                  @click="showAddress = !showAddress"
                >
                  Address and Status (Currently: {{ school.active ? 'Active' : 'Inactive' }})
                </div>
                <admin-school-address
                  v-if="showAddress"
                  class="box-around"
                />
              </v-row>

              <v-row align="center" class="menu-item">
                <v-col class="menu-number"> 2</v-col>
                <div
                  class="menu-title"
                  @click="showAdmins = !showAdmins"
                >
                  Admins
                </div>
                <admin-administrators
                  v-if="showAdmins"
                  class="box-around"
                  entity-type="school"
                />
              </v-row>

              <v-row align="center" class="menu-item">
                <v-col class="menu-number"> 3</v-col>
                <div
                  class="menu-title"
                  @click="showAwards = !showAwards"
                >
                  Awards
                </div>
                <admin-school-student-awards
                  v-if="showAwards"
                  class="box-around"
                />
              </v-row>
            </v-col>
            <v-col class="last-col pr-0" md="6">
              <v-row align="center" class="menu-item">
                <v-col class="menu-number"> 4</v-col>
                <div
                  class="menu-title"
                  @click="showPartnerAgreements = !showPartnerAgreements"
                >
                  Partner Agreements
                  {{ schoolApplicationsCount ? '(' + schoolApplicationsCount + ' Requiring Review)' : '' }}
                </div>
                <admin-partner-agreements
                  v-if="showPartnerAgreements"
                  class="box-around"
                />
              </v-row>
              <v-row align="center" class="menu-item">
                <v-col class="menu-number"> 5</v-col>
                <div
                  class="menu-title"
                  @click="showStudents = !showStudents"
                >
                  Student Recommendations {{ unlinkedStudentsCount ? '(' + unlinkedStudentsCount + ' unlinked)' : '' }}
                </div>
                <admin-students
                  v-if="showStudents"
                  class="box-around"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminAdministrators from "../../../main/AdminAdministrators";
import AdminHeader from "../../../main/AdminHeader";
import AdminPartnerAgreements from "./AdminPartnerAgreements";
import AdminSchoolAddress from "./AdminSchoolAddress";
import AdminSchoolStudentAwards from "../awards/AdminSchoolStudentAwards";
import AdminStudents from "./AdminStudents";
import ConfirmationDialog from "../../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../../components/shared/Loading";
import MenuItemShow from "../../../../../components/shared/MenuItemShow";
import {GET_ADMIN_SCHOOL} from "../../../../../store/actions.type";

export default {
  name: "AdminSchoolEdit",
  components: {
    AdminAdministrators,
    AdminHeader,
    AdminPartnerAgreements,
    AdminSchoolAddress,
    AdminSchoolStudentAwards,
    AdminStudents,
    ConfirmationDialog,
    Loading,
    MenuItemShow,
  },
  data() {
    return {
      loading: false,
      showAddress: false,
      showAdmins: false,
      showAwards: false,
      showPartnerAgreements: false,
      showStudents: false,
      showStudentsByYear: false,
    }
  },
  computed: {
    pageTitle() {
      return "Edit School - " + this.school.name
    },
    school() {
      return this.$store.getters.currentAdminSchool;
    },
    schoolApplicationsCount() {
      return this.school.application_in_review_count;
    },
    unlinkedStudentsCount() {
      return this.school.unlinked_donor_selection_students && this.school.unlinked_donor_selection_students.length;
    },
  },
  methods: {
    getSchool() {
      this.loading = true;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(GET_ADMIN_SCHOOL, school_id)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log("APPROVED SCHOOL ERROR:", e);
        });
    },
  },
  mounted() {
    this.getSchool();
  },
}
</script>

<style scoped>

a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
  height: 60px;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
  cursor: pointer;
}

::v-deep .v-icon {
  font-size: 24px !important;
}

.menu-card {
  padding: 30px 30px 20px 30px;
}

.menu-item {
  margin-bottom: 20px;
  margin-right: 10px;
}

.menu-item:last-of-type {
  margin-bottom: 0px;
}

.last-col .menu-item {
  margin-right: 0;
}

.menu-number {
  background-color: var(--apsto-teal);
  color: white;
  font-weight: 700;
  height: 50px;
  max-width: 10%;
  padding: 12px 0;
  text-align: center;
}

.menu-title {
  background-color: var(--apsto-teal-light) !important;
  color: black;
  font-weight: normal;
  height: 50px;
  width: 90%;
  padding: 12px 20px;
  text-transform: uppercase;
}

.menu-title:hover {
  background-color: var(--apsto-purple) !important;
  color: white;
  cursor: pointer;
}

.box-around {
  border: 1px solid var(--apsto-teal-light);
  margin-top: 20px;
  padding: 20px;
  width: 100%;
}
</style>
