<template>
  <loading v-if="loading"/>
  <div v-else>

    <v-row>
      <v-col>
        <household-header page-title="Invite Another Household Admin"/>
      </v-col>
    </v-row>
    <v-row class="mt-n3 mt-md-0">
      <v-col
        class="mx-2 mx-md-4"
        md="6"
      >
        <v-text-field
          id="email"
          v-model.trim="invite.email"
          :error="!!errors.email"
          :error-messages="errors.email ? errors.email : null"
          autocomplete="email"
          background-color="white"
          filled
          label="Email of The Person to Invite"
        />
        <div>
          <v-btn
            :block="isMobile"
            :to="{ name: 'household-admins-list', params: { household_id: $route.params.household_id } }"
            class="cancel-button"
            rounded
          >
            Cancel
          </v-btn>
          <v-btn
            :block="isMobile"
            :class="isMobile ? 'mt-5' : 'ml-2'"
            class="save-by-cancel-button"
            color="primary"
            rounded
            @click="submit"
          > Send
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import Loading from "../../../components/shared/Loading";
import {POST_INVITE} from "../../../store/actions.type";
import HouseholdHeader from "../../../components/household/HouseholdHeader.vue";

export default {
  name: "HouseholdAdminInvite",
  components: {
    HouseholdHeader,
    Loading
  },
  data() {
    return {
      loading: true,
      invite: {
        model_id: this.$route.params.household_id,
        model_type: 'Application',
        title: '123',
      },
      saving: false
    }
  },
  computed: {
    error() {
      return this.$store.getters.getMessage("invites");
    },
    errors() {
      return this.$store.getters.getErrors("invites");
    },
  },
  methods: {
    submit() {
      this.saving = true;
      let invitable_id = this.$route.params.household_id;
      let invitable_type = 'Household';
      let invite = this.invite;
      this.$store.dispatch(POST_INVITE, {invitable_id, invitable_type, invite})
        .then(() => {
          this.saving = false;
          this.$router.push({
            name: 'household-admins-list', params: {household_id: invitable_id}
          })
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.loading = false;
  }
}

</script>
