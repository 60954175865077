<template>
  <loading v-if="loading"/>
  <div v-else>
    <div class="mt-n5">
      {{ fiscalYearName }} {{ monthName }}
    </div>
    <v-autocomplete
      v-if="!hasStudent"
      v-model="studentAward.application_student_school_id"
      :error="!!errors.application_student_school_id"
      :error-messages="errors.application_student_school_id ? errors.application_student_school_id : null"
      :items="schoolStudents"
      class="mt-5"
      dense
      filled
      item-text="dropdown_display_info"
      item-value="id"
      label="Student"
    />
    <admin-school-student-award-balances
      v-if="fiscalYearId"
      class="mb-5"
    />
    <v-text-field
      v-if="studentAward.tax_credit_type"
      v-model.trim="studentAward.amount"
      :error="!!errors.amount"
      :error-messages="errors.amount ? errors.amount : null"
      :rules="[
                v => !!v || 'Amount is required',
                v => v >= 0 || 'Amount must be greater than or equal to 0',
                v => v <= currentFundBalance || 'Amount must be less than or equal to the current fund balance'
            ]"
      filled
      label="Amount"
    />
  </div>
</template>

<script>
import {
  GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD,
  GET_ADMIN_SCHOOL_STUDENTS,
  GET_FISCAL_YEARS
} from "../../../../../store/actions.type";
import AdminSchoolStudentAwardBalances from "./AdminSchoolStudentAwardBalances";
import Loading from "../../../../../components/shared/Loading";
import {SET_SCHOOL_STUDENT_AWARD} from "../../../../../store/mutations.type";

export default {
  name: "AdminSchoolStudentAwardForm",
  components: {AdminSchoolStudentAwardBalances, Loading},
  data() {
    return {
      loading: false,
      month: null,
      months: [
        {id: 1, name: "January"},
        {id: 2, name: "February"},
        {id: 3, name: "March"},
        {id: 4, name: "April"},
        {id: 5, name: "May"},
        {id: 6, name: "June"},
        {id: 7, name: "July"},
        {id: 8, name: "August"},
        {id: 9, name: "September"},
        {id: 10, name: "October"},
        {id: 11, name: "November"},
        {id: 12, name: "December"},
      ],
      taxCreditTypes: [
        {id: 1, name: "Disabled/Displaced Tax Credit Scholarship (Lexie's Law)"},
        {id: 2, name: "Low Income Corporate Tax Credit Scholarship"},
        {id: 3, name: "Original Tax Credit Scholarship"},
        {id: 4, name: "Switcher (Overflow/Plus) Tax Credit Scholarship"},
        {id: 5, name: "Charitable Contribution"},
      ],
    }
  },
  computed: {
    hasStudent() {
      return !!this.$route.params.permanent_id;
    },
    fiscalYearId() {
      return this.studentAward.fiscal_year_id;
    },
    fiscalYearName() {
      return this.searchArrayByKey(this.studentAward.fiscal_year_id, this.fiscalYears, "id", "object").name;
    },
    fiscalYears() {
      return this.$store.getters.currentFiscalYears
    },
    monthName() {
      return this.searchArrayByKey(this.studentAward.month, this.months, "id", "object").name;
    },
    schoolStudents() {
      return this.$store.getters.currentAdminSchoolStudents;
    },
    error() {
      return this.$store.getters.getMessage("adminSchools");
    },
    errors() {
      return this.$store.getters.getErrors("adminSchools");
    },
    studentAward: {
      get() {
        return this.$store.getters.currentApplicationStudentAward;
      },
      set(value) {
        this.$store.commit(SET_SCHOOL_STUDENT_AWARD, value);
      }
    },
    fundBalances() {
      return this.$store.getters.currentAdminSchoolStudentAwardBalances;
    },
    currentFundBalance() {
      if (this.studentAward.tax_credit_type)
        return this.searchArrayByKey(this.studentAward.tax_credit_type, this.fundBalances, "id", "object").balance_fund;
    }
  },
  methods: {
    getFiscalYears() {
      this.loading = true;
      this.$store.dispatch(GET_FISCAL_YEARS).then(() => {
        this.loading = false;
      })
        .catch(() => {
          this.loading = false;
        });
    },
    getSchoolStudents(fiscal_year_id) {
      if (this.applicationStudentSchoolId) {
        return;
      }
      this.loading = true;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(GET_ADMIN_SCHOOL_STUDENTS, {school_id, fiscal_year_id}).then(() => {
        this.loading = false;
      })
        .catch((response) => {
          console.log(response)
        });
    },
  },
  mounted() {
    this.getFiscalYears();
    this.getSchoolStudents(this.studentAward.fiscal_year_id)
  },
}
</script>

<style scoped>
::v-deep .theme--light.v-list-item .v-list-item__mask {
  color: white !important;
  background: var(--apsto-teal) !important;
  font-weight: bold !important;
}
</style>
