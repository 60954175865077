import { render, staticRenderFns } from "./SchoolEdit.vue?vue&type=template&id=7fe451f1&scoped=true&"
import script from "./SchoolEdit.vue?vue&type=script&lang=js&"
export * from "./SchoolEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fe451f1",
  null
  
)

export default component.exports