<template>
    <div class="mr-5">
        <v-row
            align="center"
            class="ml-1 py-5"
        >
            <v-col md="6">
                <admin-header page-title="Corporate Donation Requests" />
            </v-col>
        </v-row>
        <v-spacer />
        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
            :headers="headers"
            :items="corporatePledges"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :page.sync="page"
            :search="search"
            :server-items-length="serverItemsLength"
            :single-select="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-2 mt-3"
            fixed-header
            height="100vh"
            item-key="id"
            @pagination="getCorporatePledges"
            @click:row="editCorporatePledge"
            @update:sort-by="getCorporatePledges"
            @update:sort-desc="getCorporatePledges"
        >
            <template v-slot:top>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            v-model="search"
                            background-color="white"
                            class="mx-4"
                            label="Search on anything"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no corporate pledges on file.
                </span>
            </template>
            <template #item.closed="{ item }">
                <div v-if="item.closed === 0">Open</div>
                <div v-else>Closed</div>
            </template>
            <template #item.amount="{ item, index }">
                <div v-if="!index">{{ item.amount | formatCurrency }}</div>
                <div v-else>{{ item.amount | formatNumber }}</div>
            </template>
            <template #item.donation_amount="{ item, index }">
                <div v-if="!index">{{ item.donation_amount | formatCurrency }}</div>
                <div v-else>{{ item.donation_amount | formatNumber }}</div>
            </template>
            <template #item.fee_percentage="{ item }">
                {{ item.fee_percentage / 100 | formatPercent }}
            </template>
            <template #item.response_info="{ item }">
                <div>
                    {{ item.response_info.line1 }}
                </div>
                <div>
                    {{ item.response_info.line2 }}
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import { GET_CORPORATE_PLEDGES_ADMIN } from "../../../store/actions.type";

export default {
    name: "AdminCorporatePledgesList",
    components: {
        AdminHeader,
        Loading
    },
    data() {
        return {
            dialog: false,
            headers: [
                { text: 'Status', align: 'start', value: 'closed' },
                { text: 'Year', align: 'start', value: 'tax_year' },
                { text: 'Company Name', value: 'company_name' },
                { text: 'Credit Type', value: 'tax_credit_name' },
                { text: 'REQUESTED', value: 'amount', align: 'end' },
                { text: 'DONATED', value: 'donation_amount', align: 'end' },
                { text: 'Fee', value: 'fee_percentage', align: 'end' },
                { text: 'SUBMITTED', value: 'submitted_to_ador' },
                { text: 'ADOR', value: 'response_info' },
                { text: 'NOTIFIED', value: 'notified_by_apsto' },
                { text: 'Paid', value: 'paid' },
            ],
            loading: true,
            search: "",
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,
            sortBy: 'closed',
            sortDesc: false,
            serverItemsLength: 0,
        }
    },
    computed: {
        corporatePledges() {
            return this.$store.getters.currentCorporatePledges['collection'];
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        getCorporatePledges() {
            this.loading = true;
            let page = this.page;
            let itemsPerPage = this.itemsPerPage;
            let search = this.search;
            let sortBy = this.sortBy;
            let sortDesc = this.sortDesc;
            console.log("get Corporate Pledges: ", page, itemsPerPage, sortBy, sortDesc, search)
            this.$store.dispatch(GET_CORPORATE_PLEDGES_ADMIN, { page, itemsPerPage, sortBy, sortDesc, search })

                .then((data) => {
                    this.serverItemsLength = data.totalRecords;
                    this.loading = false;
                });
        },
        editCorporatePledge(item) {
            this.$router.push(`corporate-pledges/${item.id}/edit`);
        },
        initialize() {
            this.corporatePledges = [];
        },
    },
    watch: {
        search() {
            this.page = 1;
            this.getCorporatePledges();
        }
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
