<template>
    <v-row align="center">
        <v-col class="text-center">
            <v-img src="/images/website/hero-two.jpg">
                <v-row
                    class="
            fill-height
            ma-0
            white--text
            font-roboto-slab font-weight-bold
          "
                    align="center"
                    justify="center"
                >
                    <v-col cols="6">
                        <v-textarea
                            auto-grow
                            :loading="loading"
                            v-if="editMode"
                            dark
                            v-model="localContent.body.text"
                            @focus="lockFab"
                            @change="updateContent"
                        />

                        <div
                            v-resize-text="{ ratio: 1.4 }"
                            style="line-height:1;"
                            v-else
                        >
                            {{ text }}
                        </div>
                        <v-btn
                            :x-large="$vuetify.breakpoint.lgAndUp"
                            class="ma-xl-10 ma-5 text-h7-mulish font-weight-black"
                            color="secondary"
                            rounded
                            to="/donate-now"
                            v-if="$vuetify.breakpoint.mdAndUp"
                        >
                            Donate Now
                        </v-btn>
                        <v-btn
                            :x-large="$vuetify.breakpoint.lgAndUp"
                            class="ma-xl-10 ma-5 text-h7-mulish font-weight-black"
                            color="secondary"
                            dark
                            rounded
                            to="/scholarship-application"
                            v-if="!isMobile"
                        >
                            Apply Now
                        </v-btn>
                    </v-col>
                </v-row>
            </v-img>
            <v-row
                justify="space-around"
                class="mt-4 mx-2"
                v-if="isMobile"
            >
                <v-btn
                    :block="!!isMobile"
                    :class="$vuetify.breakpoint.xs ? 'mt-5' : 'ma-5'"
                    :x-large="$vuetify.breakpoint.sm"
                    class="font-weight-black text-h7-mulish"
                    color="secondary"
                    dark
                    rounded
                    to="/donate-now"
                >
                    Donate Now
                </v-btn>
                <v-btn
                    :block="!!isMobile"
                    :class="$vuetify.breakpoint.xs ? 'mt-2' : 'ma-5'"
                    :x-large="$vuetify.breakpoint.sm"
                    class="font-weight-black text-h7-mulish"
                    color="secondary"
                    dark
                    rounded
                    to="/scholarship-application"
                >
                    Apply Now
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { PATCH_CONTENT } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from "../../../../store/mutations.type";

export default {
    name: "Hero",
    props: {
        content: {
            type: Object,
        },
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
        text() {
            return this.content.body.text;
        },
    },
    data() {
        return {
            loading: false,
            localContent: null,
        };
    },
    created() {
        this.localContent = this.content;
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let self = this;
            let content = this.localContent;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    self.loading = false;
                })
                .catch((e) => {
                    self.loading = false;
                });
            return;
        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        },
    },
};
</script>

<style scoped>::v-deep textarea {
    text-align: center;
    line-height: 2em !important;
}

.v-input {
    font-size: 3em !important;
}</style>
