import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_HOUSEHOLD_ADMIN,
    GET_HOUSEHOLD_ADMIN,
    GET_HOUSEHOLD_ADMINS,
    GET_HOUSEHOLD_INVITE,
    GET_HOUSEHOLD_INVITES,
    PATCH_HOUSEHOLD_ADMIN,
    TRANSFER_HOUSEHOLD_ADMIN
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_HOUSEHOLD_ADMIN,
    SET_HOUSEHOLD_ADMINS,
    SET_HOUSEHOLD_INVITE,
    SET_HOUSEHOLD_INVITES
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        householdAdmin: {},
        householdAdmins: [],
        householdInvite: {},
        householdInvites: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentHouseholdAdmins(state) {
        return state.householdAdmins;
    },
    currentHouseholdInvites(state) {
        return state.householdInvites;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_HOUSEHOLD_ADMINS](context, household_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/admins`)
                .then(({data}) => {
                    console.log("GET_HOUSEHOLD_ADMINS: ", household_id, data)
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_ADMINS, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'householdAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_HOUSEHOLD_ADMIN](context, {household_id, household_admin_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/admins/${household_admin_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_ADMIN, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "householdAdmins",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [TRANSFER_HOUSEHOLD_ADMIN](context, {household_id, user_id}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/admins/${user_id}/transfer-primary`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_ADMIN, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'householdAdmins');
                    reject(error.response);
                });
        });
    },
    [PATCH_HOUSEHOLD_ADMIN](context, {household_id, household_admin_id, householdAdmin}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/admins/${household_admin_id}`, householdAdmin)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_ADMIN, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'householdAdmins');
                    reject(error.response);
                });
        });
    },
    [DELETE_HOUSEHOLD_ADMIN](context, {household_id, user_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/households/${household_id}/admins/${user_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'householdAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_HOUSEHOLD_INVITES](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${id}/invites`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_INVITES, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'householdAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_HOUSEHOLD_INVITE](context, {household_id, household_invite_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/invites/${household_invite_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_INVITE, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'householdAdmins');
                    reject(error.response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_HOUSEHOLD_ADMINS](state, data) {
        state.householdAdmins = data;
    },
    [SET_HOUSEHOLD_ADMIN](state, data) {
        state.householdAdmin = data;
    },
    [SET_HOUSEHOLD_INVITES](state, data) {
        state.householdInvites = data;
    },
    [SET_HOUSEHOLD_INVITE](state, data) {
        state.householdInvite = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
