import { render, staticRenderFns } from "./Video.vue?vue&type=template&id=71303e8f&scoped=true&"
import script from "./Video.vue?vue&type=script&lang=js&"
export * from "./Video.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71303e8f",
  null
  
)

export default component.exports