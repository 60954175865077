<template>
    <v-row style="min-height: 600px;">
        <v-col>
            <slot></slot>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Full",
}
</script>
