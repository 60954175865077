import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {GET_REGIONS, GET_STATE_ISO_FROM_REGION_ID} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_ERROR, SET_REGIONS, SET_STATE_ISO_FROM_REGION_ID} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        regions: [],
        stateIso: 'AZ',
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentRegions(state) {
        return state.regions;
    },
    currentRegionIso: (state) => (typeId) => {
        if (!state.regions.length) return;
        return state.regions.filter(type => type.id === typeId)[0].iso;
    },
    currentStateIso(state) {
        return state.stateIso;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_REGIONS](context) {
        //Prevent loading regions over and over
        if (context.state.regions.length) return context.state.regions;

        return new Promise((resolve, reject) => {
            ApiService.get("/api/regions/")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_REGIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "regions",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_STATE_ISO_FROM_REGION_ID](context, region_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/regions/${region_id}/get-state-iso-from-region-id/`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_STATE_ISO_FROM_REGION_ID, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_REGIONS](state, data) {
        state.regions = data;
    },
    [SET_STATE_ISO_FROM_REGION_ID](state, data) {
        state.stateIso = data;
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
