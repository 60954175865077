<template>
  <div>
    <v-list-item
      v-long-press="500"
      @click="openConversationOwner(conversation)"
      >
        <v-list-item-content class="d-inline-block">
          <v-row class="mx-0 d-flex justify-space-between">
            <v-col class="pa-0" cols="10">
              <v-list-item-title class="truncate">
                {{conversation.title}}
              </v-list-item-title>
              <v-row class="pa-0 ma-0">
                  <v-list-item-subtitle
                    :class="conversation.has_unread ? 'font-weight-bold' : ''"
                    class="truncate"
                  >
                    {{conversation.body}}
                  </v-list-item-subtitle>
              </v-row>
            </v-col>
            <v-col
              class="pa-0 d-flex"
              align="center"
              cols="1"
            >
              <v-icon
                v-if="conversation.has_unread"
                x-small
                color="red"
              >
                fas fa-circle
              </v-icon>
            </v-col>
          </v-row>
        </v-list-item-content>
    </v-list-item>
    <v-dialog
      v-model="conversationMenu"
      width="500"
      scrollable>
      <ModalMenu @done="menuClick" :list="menu" />
    </v-dialog>
  </div>
</template>
<script>
import { ACCEPT_CONVERSATION, IGNORE_CONVERSATION, DELETE_CONVERSATION, READ_CONVERSATION } from '../../../store/actions.type';
import { SET_CONVERSATION, CLEAR_MESSAGES, SHOW_MESSAGES } from "../../../store/mutations.type";
import LongPress from "vue-directive-long-press";
import ModalMenu from "./ModalMenu";
export default {
  name: "Conversation",
  components: {
    ModalMenu,
    LongPress
  },
  directives: {
    "long-press": LongPress,
  },
  props: {
    conversation: Object,
  },
  data() {
    return {
      conversationMenu: false,
      menu: [{
        text: "Delete",
        icon: "fas fa-trash-alt",
        method: "delete",
      }]
    }
  },
  methods: {
    openConversationOwner(conversation) {
        this.$store.commit(CLEAR_MESSAGES);
        if (conversation.has_unread) {
          this.$store.dispatch(READ_CONVERSATION, conversation)
        }
        this.$router.push({ path: conversation.conversation_link });
    },
    acceptInvite(conversation) {
      this.$store.dispatch(ACCEPT_CONVERSATION,conversation);
    },
    ignoreInvite(conversation) {
      this.$store.dispatch(IGNORE_CONVERSATION,conversation);
    },
    menuClick(method) {
      if (method === 'delete'){
        this.$store.dispatch(DELETE_CONVERSATION,this.conversation);
        this.conversationMenu = false;
      }
    },
  },
};
</script>
