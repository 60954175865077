<template>
    <loading v-if="loading" />
    <div v-else>

        <v-row v-if="isMobile">
            <v-col>
                <donor-header page-title="Donations" />
            </v-col>
        </v-row>

        <v-row
            v-if="!isMobile"
            align="center"
        >
            <v-col md="6">
                <donor-header page-title="Donations" />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn
                    :to="{ name: 'donor-donation-create' }"
                    color="primary"
                    rounded
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    Make A Donation
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :class="isMobile ? '' : 'ml-4 mt-5'"
            :disable-sort="isMobile"
            :headers="headers"
            :hide-default-footer="isMobile"
            :items="donations"
            class="elevation-2"
            group-by="tax_year"
            group-desc
        >
            <template #item.amount="{ item, index }">
                <div v-if="!index">
                    {{ item.amount | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.amount | formatNumberWithCents }}
                </div>
            </template>
            <template v-slot:group.header="{ items, isOpen, toggle }">
                <v-toolbar
                    v-if="isMobile"
                    color="secondary"
                    flat
                >
                    <v-row>
                        <v-col>
                            List Of Donations
                        </v-col>
                        <v-col cols="auto">
                            <v-icon
                                color="white"
                                rounded
                                @click="addItem"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-toolbar>

                <th v-if="isMobile">
                    <v-icon @click="toggle">
                        {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    Tax Year: {{ items[0].tax_year }}
                </th>
                <th v-if="isMobile" />
                <th v-if="isMobile" />
                <th v-if="isMobile" />
                <th v-if="isMobile">
                    <span
                        v-if="items[0].tax_year_total !== items[0].amount"
                        class="text-apsto-teal"
                        style="font-style: italic;"
                    >
                        Total: {{ items[0].tax_year_total | formatCurrencyWithCents }}
                    </span>
                </th>

                <th
                    v-if="!isMobile"
                    colspan="6"
                >
                    <v-icon @click="toggle">
                        {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    Tax Year: {{ items[0].tax_year }}
                    <span
                        v-if="items[0].tax_year_total !== items[0].amount"
                        class="text-apsto-teal"
                        style="font-style: italic;"
                    >
                        Total: {{ items[0].tax_year_total | formatCurrencyWithCents }}
                    </span>
                </th>

                <v-snackbar
                    v-model="snackbar"
                    centered
                >
                    {{ snackbarMessage }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </template>
            <template v-slot:item.actionsDonation="{ item }">
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-2"
                            color="secondary"
                            v-bind="attrs"
                            @click="sendEmail(item)"
                            v-on="on"
                        >
                            mdi-email
                        </v-icon>
                    </template>
                    <span>Email Me A Copy</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import { EMAIL_DONOR_DONATION, GET_DONOR_DONATIONS } from "../../../store/actions.type";
import DonorHeader from "../../../components/donors/DonorHeader.vue";

export default {
    name: "DonationList",
    components: {
        DonorHeader,
        Loading
    },
    data() {
        return {
            defaultItem: {
                date_submitted: '',
                name: '',
                status_name: '',
            },
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: 'Date', align: 'start', value: 'created_at' },
                { text: 'Tax Year', value: 'tax_year' },
                { text: 'Type', value: 'frequency' },
                { text: 'Amount', value: 'amount', align: 'end' },
                { text: 'Payment', value: 'payment' },
                { text: 'Filing As', value: 'tax_filing_status_label' },
                { text: 'Options', value: 'actionsDonation', sortable: false, align: 'center' },
            ],
            loading: true,
            sending: false,
            snackbar: false,
            snackbarMessage: "",

        }
    },
    computed: {
        donations() {
            return this.$store.getters.currentDonations;
        },
        donor() {
            return this.$store.getters.currentDonor;
        }
    },
    methods: {
        addItem() {
            this.$router.push({ name: 'donor-donation-create' });
        },
        getDonations() {
            this.$store.dispatch(GET_DONOR_DONATIONS)
        },
        closeDeleteDonation() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        initialize() {
            this.donations = [];
        },
        sendEmail(item) {
            this.editedIndex = this.donations.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.sending = true;
            let donation_id = this.editedItem.id;
            this.$store.dispatch(EMAIL_DONOR_DONATION, donation_id)
                .then(() => {
                    this.sending = false;
                    this.snackbarMessage = "Email sent";

                    this.snackbar = true;
                })
                .catch((response) => {
                    console.log(response);
                    this.sending = false;
                });
        },
        save() {
            this.snack = true
            this.snackText = 'Data saved'
        },
        close() {
            console.log('Dialog closed')
        },
    },
    mounted() {
        this.getDonations();
        this.loading = false;
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDeleteDonation()
        },
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep th:not([role=columnheader]) {
    background-color: var(--apsto-purple-very-light);
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}
</style>
