<template>
  <v-card>
    <v-list class="pa-0">
      <template v-for="item in list">
        <v-list-item class="pt-2 pl-6 pb-1" v-if="item.title" v-bind:key="item.title">
          <v-row 
            class="ma-0 d-flex align-self-end" 
            justify="space-between">
              <span class="font-weight-medium">
                {{item.title}}
              </span>
          </v-row>
        </v-list-item>

        <v-divider class="my-0 ml-0 mr-8 thicker"  v-else-if="item.divider" v-bind:key="item.divider"/>
        
        <v-list-item 
          v-bind:key="item.text"
          v-else 
          exact  
          :to="item.to"
          @click="$emit('done', item.method)"
        >
            <v-list-item-avatar>
              <v-icon color="silver">{{item.textIcon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "ModalMenu",
  props: {
    list: {
        type: Array,
    } 
  } 
}


</script>

<style scoped>
</style>