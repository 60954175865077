<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            align="center"
            class="ma-0 white--text font-roboto-slab font-weight-black purple-background"
            justify="center"
            style="height: 150px;"
        >
            <v-col class="text-center" cols="8">
                <div class="billboard-body">
                    School Details
                </div>
            </v-col>
        </v-row>

        <v-sheet>
            <v-row justify="center" no-gutters>
                <v-col class="mt-5 centered" cols="12" lg="6" md="8">
                    <h1>{{ school.name }}</h1>

                    <h2 class="mt-4">Address</h2>
                    <div class="mt-2">{{ school.physical_address.street1 }}</div>
                    <div v-if="school.physical_address.street2">
                        {{ school.physical_address.street2 }}
                    </div>
                    <div>
                        {{ school.physical_address.city }},
                        {{ school.physical_address.region }}
                        {{ school.physical_address.postal }}
                    </div>
                    <h2 class="mt-4">Mailing Address</h2>
                    <div class="mt-2">{{ school.address.street1 }}</div>
                    <div v-if="school.address.street2">{{ school.address.street2 }}</div>
                    <div>
                        {{ school.address.city }}, {{ school.address.region }}
                        {{ school.address.postal }}
                    </div>
                    <h2 class="mt-4">Contact Information</h2>
                    <div class="mt-2">
                        {{ school.primary_user_name }}, {{ school.primary_user_title }}
                        <br/>
                        Email:
                        <a
                            :href="'mailto:' + school.primary_user_email"
                            style="color: var(--apsto-teal)"
                            target="_blank"
                        >
                            {{ school.primary_user_email }}
                        </a>
                        <br v-if="school.website"/>
                        <span v-if="school.website">
                        Web:
                        <a

                            :href="school.website"
                            style="color: var(--apsto-teal)"
                            target="_blank"
                        >
                            Website
                        </a>
                        </span>
                        <br/>
                        Phone: {{ school.phone }}
                        <br v-if="school.fax && school.fax !== ''"/>
                        <span v-if="school.fax && school.fax !== ''">
                            Fax: {{ school.fax }}
                        </span>
                    </div>
                </v-col>
            </v-row>
            <web-footer class="mt-8"/>
        </v-sheet>
    </div>
</template>

<script>
import ApiService from "../../api/api.service";
import Loading from "../../components/shared/Loading";
import WebFooter from "../../components/website/components/WebFooter";

export default {
    name: "ApprovedSchool",
    components: {Loading, WebFooter},
    data() {
        return {
            loading: true,
            school: {
                address: {},
                physical_address: {},
            },
        };
    },
    mounted() {
        let school_id = this.$route.params.school_id;
        ApiService.get(`/api/website/data/approved-schools/${school_id}`)
            .then((response) => {
                this.school = response.data;
                this.loading = false;
            })
            .catch((e) => {
                console.log("APPROVED SCHOOL ERROR:", e);
            });
    },
};
</script>

<style scoped>
div {
    font-size: 1.15rem;
}

.centered {
    text-align: center;
}

.billboard-body {
    font-family: "Roboto Slab", serif;
    font-size: 44px;
    text-shadow: 1px 1px 1px #000;
    line-height: 1.1;
}
</style>
