<template>
  <v-row class="mt-n3">
    <v-col>
      <div class="section-header">
        School Year
      </div>
      <v-radio-group
        v-model="editedItem.student.is_preregistration"
        :error="!!errors['student.is_preregistration']"
        :error-messages="errors['student.is_preregistration'] ? errors['student.is_preregistration'] : null"
        class="mt-3"
        column
        @change="clearErrors"
      >
        <template v-slot:label>
          <div
            class="text--primary darken-4 pb-2"
            style="font-size: 1rem;"
          >
            Please select the first school year below unless you are pre-registering this student. In
            that case, select the second school year below.
          </div>
        </template>
        <v-radio
          :label="applicationSchoolYearName + ' school year'"
          :value="0"
          color="secondary"
        />
        <v-radio
          :label="nextSchoolYearName + ' school year (pre-registration)'"
          :value="1"
          color="secondary"
        />
      </v-radio-group>

      <school-selector
        v-model="editedItem.student.school_id"
        :error="!!errors['student.school_id']"
        :error-messages="errors['student.school_id'] ? errors['student.school_id'] : null"
        :label="schoolSelectorLabel"
      />
      <school-grade-selector
        v-model="editedItem.student.grade_id"
        :label="schoolGradeSelectorLabel"
      />
      <div
        v-if="editedItem.student.grade_id === 2"
        class="mb-4"
      >
        {{ kindergartenMessage }}
      </div>
      <!--      ESA-->
      <div v-if="!hideContentForPreregistration">
        <div class="section-header">
          Empowerment Scholarship Account (ESA)
        </div>
        <v-radio-group
          v-model="editedItem.student.esa_enum"
          :error="!!errors['student.esa_enum']"
          :error-messages="errors['student.esa_enum'] ? errors['student.esa_enum'] : null"
          class="text--primary darken-4"
        >
          <v-radio
            :value="1"
            color="secondary"
            style="align-items: flex-start;"
          >
            <template v-slot:label>
              <div class="text--primary darken-4">
                This student has NOT participated with ESA. I will notify APSTO immediately if I sign an ESA
                contract.
              </div>
            </template>
          </v-radio>
          <v-radio
            :value="2"
            class="mt-5"
            color="secondary"
            style="align-items: flex-start;"
          >
            <template v-slot:label>
              <div class="text--primary darken-4">
                This student has participated with ESA and I confirm that their ESA contract was CANCELED.
              </div>
            </template>
          </v-radio>
          <v-radio
            :value="3"
            class="mt-5"
            color="secondary"
            style="align-items: flex-start;"
          >
            <template v-slot:label>
              <div class="text--primary darken-4">
                This student is participating with ESA. Their contract will NOT be canceled for the
                {{ schoolYearName }} school year.
                <div class="mt-3">
                  I understand that APSTO may NOT release any tax credit awards while this student is
                  under the ESA
                  contract but, can instead, hold potential tuition awards for a future school year when
                  this student no
                  longer has an ESA contract.
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div v-if="!hideContentForPreregistration && !hideContentForESA">
        <div
          :class="editedItem.student.grade_id === 2 ? 'mt-3' : ''"
          class="section-header"
        >
          Student Narrative
        </div>
        <div class="py-2">
          <div
            class="font-mulish"
            style="color: var(--apsto-black); font-size: 16px; font-weight: 500;"
          >
            Please provide a brief narrative about your student below. This narrative is required,
            we use this as one of our criterion when making award decisions. This does not need to
            be an extensive narrative, we just want to know a bit more about your student.
          </div>
        </div>
        <v-textarea
          v-model="editedItem.student.comments"
          :error="!!errors['student.comments']"
          :error-messages="errors['student.comments'] ? errors['student.comments'] : null"
          auto-grow
          background-color="secondaryLight"
        />
        <div
          v-if="wordCount"
          class="text-right mb-4 mt-n4"
        >
          {{ wordCount }} words (500 word maximum)
        </div>
        <v-currency-field
          id="tuition_amount"
          v-model.number="editedItem.student.tuition_amount"
          :error="!!errors['student.tuition_amount']"
          :error-messages="errors['student.tuition_amount'] ? errors['student.tuition_amount'] : null"
          background-color="white"
          filled
          label="Tuition Amount for School Year"
          prefix="$"
          type="number"
        />

        <div class="section-header">
          Student Scholarship Eligibility
        </div>
        <div
          class="mt-1 font-mulish"
          style="color: var(--apsto-black); font-size: 16px; font-weight: 500;"
        >
          Please check below all statements that apply to this student.
        </div>
        <div class="eligibility-requirements">
          <v-checkbox
            v-model="editedItem.student.transfer_from_charter_or_public_school"
            :error="!!errors['student.transfer_from_charter_or_public_school']"
            :error-messages="errors['student.transfer_from_charter_or_public_school'] ? errors['student.transfer_from_charter_or_public_school'] : null"
            color="secondary"
          >
            <template v-slot:label>
              <div class="text--primary darken-4">
                This student is transferring from an Arizona charter or public school
                <div
                  class="mt-2"
                  style="color: var(--apsto-purple);"
                >
                  Note: To check this box, this student must have been enrolled in an Arizona
                  charter or public school for at least 90 days of the previous school year.
                </div>
              </div>
            </template>
          </v-checkbox>

          <v-checkbox
            v-model="editedItem.student.transfer_from_different_state"
            :error="!!errors['student.transfer_from_different_state']"
            :error-messages="errors['student.transfer_from_different_state'] ? errors['student.transfer_from_different_state'] : null"
            color="secondary"
          >
            <template v-slot:label>
              <div class="text--primary darken-4">
                This student moved to Arizona from another state or country and transferred directly to an
                Arizona private school
              </div>
            </template>
          </v-checkbox>

          <v-checkbox
            v-model="editedItem.student.transfer_from_home_school"
            :error="!!errors['student.transfer_from_home_school']"
            :error-messages="errors['student.transfer_from_home_school'] ? errors['student.transfer_from_home_school'] : null"
            column
          >
            <template v-slot:label>
              <div class="text--primary darken-4 pb-2">
                This student was home schooled immediately prior to transferring directly to an Arizona
                private school
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="editedItem.student.foster"
            :error="!!errors['student.foster']"
            :error-messages="errors['student.foster'] ? errors['student.foster'] : null"
          >
            <template v-slot:label>
              <div class="text--primary darken-4 pb-2">
                This student is or was a part of the Arizona Foster care system
              </div>
            </template>
          </v-checkbox>
          <div
            v-if="editedItem.student.foster"
            class="follow-up-section"
          >
            <div class="my-2 font-mulish">
              Please complete the field below if this is an application for a student who is the legal
              responsibility of a welfare agency or court and is currently living in your household.
            </div>
            <v-currency-field
              id="tuition_amount"
              v-model.number="editedItem.student.foster_amount"
              :error="!!errors['student.foster_amount']"
              :error-messages="errors['student.foster_amount'] ? errors['student.foster_amount'] : null"
              background-color="white"
              filled
              label="Student's Personal Use of Monthly Income"
              type="number"
            />
          </div>
          <v-checkbox
            v-model="editedItem.student.active_military"
            :error="!!errors['student.active_military']"
            :error-messages="errors['student.active_military'] ? errors['student.active_military'] : null"
            column
          >
            <template v-slot:label>
              <div class="text--primary darken-4 pb-2">
                This student is a dependent of a member of the United States Armed Forces stationed in
                Arizona pursuant to military orders
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="editedItem.student.disabled"
            :error="!!errors['student.disabled']"
            :error-messages="errors['student.disabled'] ? errors['student.disabled'] : null"
            column
          >
            <template v-slot:label>
              <div class="text--primary darken-4 pb-2">
                This student is currently enrolled in private school and has an Individualized Education
                Plan (IEP)
                from an Arizona public school or Multidisciplinary Evaluation Team (MET)
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="editedItem.student.previous_scholarship"
            :error="!!errors['student.previous_scholarship']"
            :error-messages="errors['student.previous_scholarship'] ? errors['student.previous_scholarship'] : null"
            column
            @change="clearScholarshipAwards(editedItem.student.previous_scholarship)"
          >
            <template v-slot:label>
              <div class="text--primary darken-4 pb-2">
                This student received a tax credit scholarship from APSTO or another Arizona State Tuition
                Organization in the previous school year and continues to attend private school in Arizona
              </div>
            </template>
          </v-checkbox>

        </div>
        <family-member-student-awards
          v-if="editedItem.student.previous_scholarship"
          :edited-item="editedItem"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FamilyMemberIncome from "./FamilyMemberIncome";
import FamilyMemberStudentAwards from "./FamilyMemberStudentAwards";
import SchoolGradeSelector from "../../shared/SchoolGradeSelector.vue";
import SchoolSelector from "../../shared/SchoolSelector.vue";
import {CLEAR_ERRORS} from "../../../store/mutations.type";

export default {
  name: "FamilyMemberStudent",
  components: {
    SchoolSelector, SchoolGradeSelector,
    FamilyMemberStudentAwards,
    FamilyMemberIncome
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    },
    schoolYearName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      column: null,
      row: null,
    }
  },
  computed: {
    applicableSchoolYearName() {
      return this.editedItem.student.is_preregistration
        ? this.nextSchoolYearName
        : this.applicationSchoolYearName;
    },
    applicationSchoolYearName() {
      return this.application.school_year_name;
    },
    nextSchoolYearName() {
      return this.application.next_school_year_name;
    },
    hideContentForESA() {
      return this.editedItem.student.esa_enum === '3';
    },
    hideContentForPreregistration() {
      return this.editedItem.student.is_preregistration;
    },
    kindergartenMessage() {
      if (this.editedItem.student.is_preregistration) {
        const schoolYear = parseInt(this.application.school_year_start_year.substring(0, 4)) - 4
        return "Kindergarten students must be born on or before 01/01/" + schoolYear + " to be eligible for tax credits next school year."
      }

      const schoolYear = parseInt(this.application.school_year_start_year.substring(0, 4)) - 5;
      return "Kindergarten students must be born on or before 01/01/" + schoolYear + " to be eligible for tax credits this school year."

    },
    schoolGradeSelectorLabel() {
      let labelVerbiage = this.editedItem.student.is_preregistration ? 'Will Be Enrolled In' : 'Enrolled In';
      return "Grade Student " + labelVerbiage + " During " + this.applicableSchoolYearName;
    },
    schoolSelectorLabel() {
      let labelVerbiage = this.editedItem.student.is_preregistration ? 'Plans To Attend' : 'Attending';
      return "School Student " + labelVerbiage + " During " + this.applicableSchoolYearName;
    },
    wordCount() {
      if (this.editedItem.student.comments) {
        return this.editedItem.student.comments.split(' ').length
      }
      return 0;
    },
    error() {
      return this.$store.getters.getMessage("application");
    },
    errors() {
      return this.$store.getters.getErrors("application");
    },
  },
  methods: {
    clearErrors() {
      this.$store.commit(CLEAR_ERRORS)
    },
    clearScholarshipAwards(status) {
      if (status === false) {
        this.editedItem.student.previous_scholarship_award_1 = false;
        this.editedItem.student.previous_scholarship_award_2 = false;
        this.editedItem.student.previous_scholarship_award_3 = false;
        this.editedItem.student.previous_scholarship_award_4 = false;
        this.editedItem.student.previous_scholarship_award_1_sto = null;
        this.editedItem.student.previous_scholarship_award_2_sto = null;
        this.editedItem.student.previous_scholarship_award_3_sto = null;
        this.editedItem.student.previous_scholarship_award_4_sto = null;
      }
    },
  }
}
</script>

<style scoped>
.follow-up-section {
  border-bottom: 1px dotted var(--apsto-teal);
  margin: 20px 10px 20px 30px;
}

.section-header {
  background-color: #6CB2EB;
  color: white;
  font-family: "Mulish", sans-serif;
  font-weight: bold;
  padding: 15px 20px;
}

::v-deep .v-input__slot {
  align-items: start !important;
}

::v-deep .eligibility-requirements .v-input--checkbox {
  border-bottom: 1px solid var(--apsto-border);
}

::v-deep .eligibility-requirements .v-input--checkbox:last-child {
  border-bottom: none;
}
</style>
