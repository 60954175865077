<template>
  <div>
    <div class="text-h6-mulish mb-6">Actual Students To Choose From</div>
    <v-data-table
      :footer-props="{ itemsPerPageOptions:[10,20,30] }"
      :headers="headers"
      :items-per-page.sync="itemsPerPage"
      :items="students"
      :loading="loading"
      :page.sync="page"
      :search="search"
      :server-items-length="serverItemsLength"
      :single-select="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @click:row="linkTo"
      @pagination="getStudents"
      @update:sort-by="getStudents"
      @update:sort-desc="getStudents"
      class="elevation-2"
      fixed-header
      height="100vh"
      item-key="id"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              class="mx-4"
              label="Search on anything"
              prepend-inner-icon="mdi-magnify"
              outlined
              background-color="white"
            />
          </v-col>
        </v-row>
        <v-dialog v-model="createDialog" :max-width="dialogMaxWidth" :fullscreen="!!isMobile">
          <admin-school-link-student-confirm
            :id-to-link-to="idToLinkTo"
            :student-name="studentName"
            :unlinked-student-name="unlinkedStudentName"
            :link-type="linkType"
            @done="createDialog = false"
          />
        </v-dialog>
      </template>

      <template #item.school_history="{item}">
        <table class="school-history">
          <tr v-for="(items,itemsIndex) in item.school_history" :key="itemsIndex">
            <td>
              <span class="text-apsto-teal">{{ items.school_name }}</span>
              <br>
              {{ items.grade_name }}
            </td>
          </tr>
        </table>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import AdminSchoolLinkStudentConfirm from "./AdminSchoolLinkStudentConfirm";
import {GET_ADMIN_STUDENTS} from "../../../../../../store/actions.type";

export default {
  name: "AdminStudentsTable",
  components: {AdminSchoolLinkStudentConfirm},
  props: {
    unlinkedStudentName: {
      required: true,
      type: String
    },
    linkType: {
      required: true,
      type: String
    }
  },
  computed: {
    students() {
      return this.$store.getters.currentAdminStudents['students'];
    },
  },
  data() {
    return {
      createDialog: false,
      donorSelectionStudentId: null,
      headers: [
        {text: 'Student', align: 'start', value: 'name'},
        {text: 'Household', align: 'start', value: 'household_name'},
        {text: 'School Year', align: 'start', value: 'school_year_name'},
        {text: 'School', align: 'start', value: 'school_history'},
      ],
      idToLinkTo: null,
      itemsPerPage: 20,
      loading: true,
      page: 1,
      pageCount: 0,
      search: "",
      serverItemsLength: 0,
      sortBy: 'name',
      sortDesc: false,
      studentName: null,
      temporaryItem: null
    }
  },
  methods: {
    getStudents() {
      this.loading = true;
      let page = this.page;
      let itemsPerPage = this.itemsPerPage;
      let search = this.search;
      let sortBy = this.sortBy;
      let sortDesc = this.sortDesc;
      this.$store.dispatch(GET_ADMIN_STUDENTS, {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
        search
      })
        .then((data) => {
          this.serverItemsLength = data.totalRecords;
          this.loading = false;
        })
    },
    linkTo(item) {
      this.createDialog = true;
      this.idToLinkTo = item.id;
      this.studentName = item.name;
    },
  },
  watch: {
    search() {
      this.page = 1;
      this.getStudents()
    }
  }
}
</script>

<style scoped>

a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep table tbody td {
  height: unset !important;
}

::v-deep tbody tr td {
  padding: 8px 16px !important;
}

table.school-history td {
  padding-top: 3px;
}

table.school-history {
  padding-bottom: 3px;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light) !important;
  cursor: pointer;
}

::v-deep .v-icon {
  font-size: 24px !important;
}

</style>

