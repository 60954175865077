import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_SCHOOL_APPLICATION_ADMIN,
    GET_ADMIN_LINKED_STUDENT,
    GET_ADMIN_LINKED_STUDENT_AWARDS,
    GET_ADMIN_LINKED_STUDENT_NAME,
    GET_ADMIN_SCHOOL,
    GET_ADMIN_SCHOOL_APPLICATIONS,
    GET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES,
    GET_ADMIN_SCHOOL_STUDENTS,
    GET_ADMIN_SCHOOLS,
    GET_ADMIN_STUDENTS,
    GET_ADMIN_UNLINKED_STUDENT,
    GET_ADMIN_UNLINKED_STUDENT_NAME,
    GET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID,
    LINK_DONOR_STUDENT_RECOMMENDATION_TO_ACTUAL_STUDENT,
    LINK_MULTIPLE_DONOR_STUDENT_RECOMMENDATIONS_TO_ACTUAL_STUDENT, LOCK_AWARD_OFFERS,
    PATCH_SCHOOL_APPLICATION_STATUS, RELEASE_AWARD_OFFERS,
    UNLINK_DONOR_STUDENT_RECOMMENDATION
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ADMIN_LINKED_STUDENT,
    SET_ADMIN_LINKED_STUDENT_AWARDS,
    SET_ADMIN_LINKED_STUDENT_NAME,
    SET_ADMIN_SCHOOL,
    SET_ADMIN_SCHOOL_APPLICATIONS,
    SET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES,
    SET_ADMIN_SCHOOL_STUDENTS,
    SET_ADMIN_SCHOOLS,
    SET_ADMIN_STUDENTS,
    SET_ADMIN_UNLINKED_STUDENT,
    SET_ADMIN_UNLINKED_STUDENTS_TO_LINK,
    SET_ADMIN_UNLINKED_STUDENT_NAME,
    SET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID,
    SET_ERROR,
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        adminSchools: [],
        adminSchool: {},
        adminSchoolApplications: [],
        adminSchoolApplication: {},
        adminSchoolStudents: [],
        adminSchoolStudent: {},
        adminUnlinkedStudent: {},
        adminUnlinkedStudentsToLink: [],
        adminUnlinkedStudentAwards: {},
        adminSchoolStudentAwardBalances: [],
        adminSchoolStudentWithAwards: {},
        adminLinkedStudentName: {},
        adminStudents: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentAdminStudents(state) {
        return state.adminStudents;
    },
    currentAdminSchoolStudents(state) {
        return state.adminSchoolStudents;
    },
    currentAdminSchools(state) {
        return state.adminSchools;
    },
    currentAdminSchool(state) {
        return state.adminSchool;
    },
    currentAdminSchoolApplications(state) {
        return state.adminSchoolApplications;
    },
    currentAdminSchoolStudentAwardBalances(state) {
        return state.adminSchoolStudentAwardBalances;
    },
    currentAdminSchoolStudentWithAwards(state) {
        return state.adminSchoolStudentWithAwards;
    },
    currentAdminLinkedStudent(state) {
        return state.adminLinkedStudent;
    },
    currentAdminLinkedStudentAwards(state) {
        return state.adminLinkedStudentAwards;
    },
    currentAdminLinkedStudentNameAndStudentPermanentName(state) {
        return state.adminLinkedStudentName;
    },
    currentAdminUnlinkedStudent(state) {
        return state.adminUnlinkedStudent;
    },
    currentAdminUnlinkedStudentsToLink(state) {
        return state.adminUnlinkedStudentsToLink;
    },
    currentAdminUnlinkedStudentName(state) {
        return state.adminUnlinkedStudentName;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_ADMIN_SCHOOLS](context, {page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/schools`, {page, itemsPerPage, sortBy, sortDesc, search})
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOLS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL](context, school_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/show`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL_APPLICATIONS](context, school_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/applications/${school_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL_APPLICATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_SCHOOL_APPLICATION_ADMIN](context, school_application_id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/schools/applications/${school_application_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_SCHOOL_APPLICATION_STATUS](context, {school_application_id, status}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/applications/${school_application_id}/status/${status}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_UNLINKED_STUDENT](context, {school_id, donor_selection_student_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/unlinked-students/${donor_selection_student_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_UNLINKED_STUDENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_UNLINKED_STUDENT_NAME](context, {school_id, donor_selection_student_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/unlinked-students/${donor_selection_student_id}/name`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_UNLINKED_STUDENT_NAME, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_STUDENTS](context, {page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/schools/actual-students`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_STUDENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [LINK_DONOR_STUDENT_RECOMMENDATION_TO_ACTUAL_STUDENT](context, {
        actual_student_id,
        donor_selection_student_id,
        school_id,
    }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/schools/${school_id}/unlinked-students/${donor_selection_student_id}/link-to-id/${actual_student_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [LINK_MULTIPLE_DONOR_STUDENT_RECOMMENDATIONS_TO_ACTUAL_STUDENT](context, {
        actual_student_id,
        donor_selection_students_ids,
        school_id,
    }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/schools/${school_id}/link-multiple-students/${actual_student_id}`, {
                "donor_selection_students_ids": donor_selection_students_ids
                })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_LINKED_STUDENT](context, {school_id, donor_selection_student_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/linked-students/${donor_selection_student_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_LINKED_STUDENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_LINKED_STUDENT_NAME](context, {school_id, donor_selection_student_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/linked-students/${donor_selection_student_id}/name`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_LINKED_STUDENT_NAME, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [UNLINK_DONOR_STUDENT_RECOMMENDATION](context, {
        donor_selection_student_id,
        school_id,
    }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/schools/${school_id}/linked-students/${donor_selection_student_id}/unlink`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_LINKED_STUDENT_AWARDS](context, {school_id, student_permanent_id, donor_selection_student_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/linked-student-awards/${student_permanent_id}/dss/${donor_selection_student_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_LINKED_STUDENT_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL_STUDENTS](context, {school_id, fiscal_year_id}) {
        return new Promise((resolve, reject) => {
            // AdminSchoolController::class, 'indexApplicationStudentSchoolsInSchoolYear'
            ApiService.get(`/api/admin/schools/${school_id}/students/year/${fiscal_year_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL_STUDENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES](context, {school_id, fiscal_year_id, application_student_school_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/fund-balances/${fiscal_year_id}/student/${application_student_school_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID](context, {school_id, permanent_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/students/${permanent_id}/edit`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [LOCK_AWARD_OFFERS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/lock-award-offers`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [RELEASE_AWARD_OFFERS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/release-award-offers`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {

    [SET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES](state, data) {
        state.adminSchoolStudentAwardBalances = data;
    },
    [SET_ADMIN_SCHOOLS](state, data) {
        state.adminSchools = data;
    },
    [SET_ADMIN_SCHOOL](state, data) {
        state.adminSchool = data;
    },
    [SET_ADMIN_SCHOOL_APPLICATIONS](state, data) {
        state.adminSchoolApplications = data;
    },
    [SET_ADMIN_SCHOOL_STUDENTS](state, data) {
        state.adminSchoolStudents = data;
    },
    [SET_ADMIN_LINKED_STUDENT](state, data) {
        state.adminLinkedStudent = data;
    },
    [SET_ADMIN_LINKED_STUDENT_AWARDS](state, data) {
        state.adminLinkedStudentAwards = data;
    },
    [SET_ADMIN_LINKED_STUDENT_NAME](state, data) {
        state.adminLinkedStudentName = data;
    },
    [SET_ADMIN_UNLINKED_STUDENT](state, data) {
        state.adminUnlinkedStudent = data;
    },
    [SET_ADMIN_UNLINKED_STUDENTS_TO_LINK](state, data) {
        state.adminUnlinkedStudentsToLink = data;
    },
    [SET_ADMIN_UNLINKED_STUDENT_NAME](state, data) {
        state.adminUnlinkedStudentName = data;
    },
    [SET_ADMIN_STUDENTS](state, data) {
        state.adminStudents = data;
    },
    [SET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID](state, data) {
        state.adminSchoolStudentWithAwards = data;
    }

};

export default {
    getters,
    actions,
    mutations,
    state,
};
