<template>
  <div>
    <v-radio-group
      v-model="localTaxFilingStatus"
      :error="!!errors['donation.tax_filing_status']"
      :error-messages="errors['donation.tax_filing_status'] ? errors['donation.tax_filing_status'] : null"
      column
    >
      <template v-slot:label>
        <div
          v-if="selectedTaxYear"
          class="pb-2 font-weight-black"
          style="font-size: 1.125rem"
        >
          Tax Filing Status for the
          <span class="text-apsto-teal">{{ selectedTaxYear }}</span>
          tax year
          <span class="red--text">*</span>
        </div>
        <div
          v-else
          class="pb-2 font-weight-black"
          style="font-size: 1.125rem"
        >
          Tax Filing Status
        </div>
      </template>
      <v-radio
        color="secondary"
        label="Married Filing Jointly"
        value="joint"
      />
      <v-radio
        color="secondary"
        label="Head of Household, Married Filing Separately, Single, or Qualifying Widow(er)"
        value="single"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "FilingStatus",
  props: {
    value: {
      type: String,
    },
    selectedTaxYear: {
      type: Number,
      required: false,
    },
  },
  computed: {
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
    localTaxFilingStatus: {
      get() {
        return this.value;
      },
      set(taxFilingStatus) {
        this.$emit("input", taxFilingStatus);
      },
    },
  },
};
</script>

