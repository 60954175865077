<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1"
    >
      <v-col
        class="pa-0"
        cols="auto"
      >
        <household-header page-title="Custom Resources"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :class="isMobile ? 'px-6' : 'px-8'"
        md="6"
      >
        <div class="text-h6-mulish font-weight-bold">
          Custom Tools to Support You
        </div>
        <div class="text-h7-mulish mt-5">
          Maximize this program for your family with custom donation-raising materials. These valuable resources
          are
          automatically generated along with your student(s) application profile to help you share and explain the
          tax
          credit program to family and friends so they can recommend your student for scholarships. Add in photos
          and
          custom messaging for personal touch or use our pre-formatted versions.
        </div>
        <v-img
          v-if="isMobile"
          class="mb-n6 mt-6 mx-6"
          src="/images/custom_resources_page_top.jpg"
        />
        <div class="text-h6-mulish font-weight-bold mt-5">
          Custom Brochure
        </div>
        <div class="text-h7-mulish mt-5">
          This tool allows you to create your own custom PDF brochure featuring your student(s) or family. You
          place a picture of your student(s) or family on the brochure cover, your student(s) and school names in the
          recommendations section of the donation form and then print or email your brochure to friends and
          family! Click the button below to get started and follow the easy instructions.
        </div>
        <div v-if="!promos.length">
          <v-btn
            :block="isMobile"
            class="mt-5"
            color="red"
            rounded
            style="color: white; min-width: 280px"
          >
            Please Add An Application To Access Brochure
          </v-btn>
        </div>
        <div
          v-for="(promo, promo_index) in promos"
          :key="promo_index"
        >
          <v-btn
            :block="isMobile"
            :to="{
                            name: 'application-brochure',
                            params: {
                                household_id: $route.params.household_id,
                                application_id: promo.id,
                            },
                        }"
            class="mt-5"
            color="primary"
            rounded
            style="min-width: 280px"
          >
            Select Brochure
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-if="!isMobile"
        md="2"
      ></v-col>
      <v-col md="4">
        <v-img
          v-if="!isMobile"
          class="mt-5"
          src="/images/custom_resources_page_top.jpg"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :class="isMobile ? 'px-6' : 'px-8'"
        md="6"
      >
        <v-img
          v-if="isMobile"
          class="mb-6 mt-n6 mx-6"
          src="/images/custom_resources_page_bottom.png"
        />
        <div class="text-h6-mulish font-weight-bold">
          Custom Donation Pages
        </div>
        <div class="text-h7-mulish mt-5">
          This tool allows you to create and customize a one-page donation website specifically designed for your
          individual students or family. Your custom donation page is automatically generated from your
          application. You
          can post, text or email your website link to family, friends and taxpayers to promote donations for your
          student’s tuition!
        </div>
        <div class="text-h7-mulish mt-5">
          You can choose between our student donation template or our family template. If you have more than one
          student
          we
          recommend the family donation page. To see what each template will look like simply click the “View”
          buttons
          for each page. Once you have decided which template you will use, click the appropriate button below to
          get
          started, and follow the easy instructions.
        </div>
        <div v-if="!promos.length">
          <v-btn
            :block="isMobile"
            class="mt-5"
            color="red"
            rounded
            style="color: white; min-width: 280px"
          >
            Please Add An Application
          </v-btn>
          <v-btn
            :block="isMobile"
            :class="isMobile ? 'mt-2' : 'ml-2 mt-5'"
            class="font-weight-black"
            color="secondary"
            href="/promos/1844/king"
            rounded
            style="min-width: 300px"
            target="_blank"
          >
            <v-icon
              color="black"
              left
            >
              mdi-human-male-female-child
            </v-icon>
            <span style="color: black">VIEW FAMILY PAGE EXAMPLE</span>
          </v-btn>
        </div>
        <div
          v-for="(promo, promo_index) in promos"
          :key="promo_index"
        >

          <template v-if="promo.promo">
            <v-btn
              :block="isMobile"
              :to="{
                                name: 'application-promo-edit',
                                params: {
                                    household_id: $route.params.household_id,
                                    application_id: promo.id,
                                    promo_id: promo.promo.id
                                },
                            }"
              class="mt-5"
              color="primary"
              rounded
              style="min-width: 280px"
            >
              <v-icon
                color="white"
                left
              >
                mdi-human-male-female-child
              </v-icon>
              <span style="color: white">EDIT FAMILY PAGE</span>
            </v-btn>
          </template>

          <template v-else>
            <v-btn
              :block="isMobile"
              :to="{
                                name: 'application-promo-create',
                                params: {
                                    household_id: $route.params.household_id,
                                    application_id: promo.id,
                                },
                            }"
              class="mt-5"
              color="primary"
              rounded
              style="min-width: 280px"
            >
              <v-icon
                color="white"
                left
              >
                mdi-human-male-female-child
              </v-icon>
              <span style="color: white">CREATE FAMILY PAGE</span>
            </v-btn>
          </template>
          <v-btn
            :block="isMobile"
            :class="isMobile ? 'mt-2' : 'ml-2 mt-5'"
            class="font-weight-black"
            color="secondary"
            href="/promos/1844/king"
            rounded
            style="min-width: 300px"
            target="_blank"
          >
            <v-icon
              color="black"
              left
            >
              mdi-human-male-female-child
            </v-icon>
            <span style="color: black">VIEW FAMILY PAGE EXAMPLE</span>
          </v-btn>
        </div>
        <v-btn
          :block="isMobile"
          :to="{
                        name: 'promos-list',
                        params: {
                            household_id: $route.params.household_id,
                        },
                    }"
          class="mt-5"
          color="primary"
          rounded
          style="min-width: 280px"
        >
          <v-icon
            color="white"
            left
          >
            mdi-account-school
          </v-icon>
          <span style="color: white">GO TO STUDENTS PAGE</span>
        </v-btn>
        <v-btn
          :block="isMobile"
          :class="isMobile ? 'mt-2' : 'ml-2 mt-5'"
          class="font-weight-black"
          color="secondary"
          href="/promos/1844/king/15732/nikkie-king"
          rounded
          style="min-width: 300px"
          target="_blank"
        >
          <v-icon
            color="black"
            left
          >
            mdi-account-school
          </v-icon>
          <span style="color: black">VIEW CHILD PAGE EXAMPLE</span>
        </v-btn>
      </v-col>
      <v-col
        v-if="!isMobile"
        md="2"
      >
      </v-col>
      <v-col md="4">
        <v-img
          v-if="!isMobile"
          class="pa-5"
          src="/images/custom_resources_page_bottom.png"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import Loading from "../../../../components/shared/Loading";
import {GET_APPLICATION_PROMOS} from "../../../../store/actions.type";

export default {
  name: "PromosCustomResourcesExplainer",
  components: {
    HouseholdHeader,
    Loading,
  },
  data() {
    return {
      headers: [
        {text: "School Year", align: "start", value: "school_year_name"},
        {text: "For", value: "type"},
        {text: "Actions", value: "actionsApplication", sortable: false},
        {text: "Date Last Updated", value: "updated"},
      ],
      memberHeaders: [
        {text: "For", align: "start", value: "name"},

        {text: "Actions", value: "actionsMember", sortable: false},
        {text: "Date Last Updated", value: "updated"},
      ],
      loading: true,
      sending: false,
    };
  },
  computed: {
    promos() {
      return this.$store.getters.currentPromos;
    },
    household() {
      return this.$store.getters.currentUserHousehold;
    },
    promoIds() {
      return Object.fromEntries(this.promos.map((promo) => [promo.id, true]));
    },
  },
  methods: {
    getPromos() {
      this.$store
        .dispatch(GET_APPLICATION_PROMOS, this.$route.params.household_id)
        .then(() => {
          this.$refs.promosTable.expansion = this.promoIds;
        });
    },
  },
  mounted() {
    this.getPromos();
    this.loading = false;
  },
};
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep [role="columnheader"] {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 10px 0;
}
</style>
