<template>
    <v-autocomplete
        id="slug"
        v-model="localSiteDocument"
        :error="!!errors['slug']"
        :error-messages="errors['slug'] ? errors['slug'] : null"
        :items="siteDocumentsForDropdown"
        :label="this.label"
        :loading="loading"
        item-text="name"
        item-value="slug"
    />
</template>

<script>
export default {
    name: "SiteDocumentSelector",
    props: {
        label: {
            default: "Document",
            type: String
        },
        value: {
            default: '',
            type: String,
            required: true,
        },
    },
    computed: {
        siteDocumentsForDropdown() {
            return this.$store.getters.currentSiteDocumentSlugs;
        },
        loading() {
            return false;
            return this.$store.getters.siteDocumentDropdownLoading;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
        localSiteDocument: {
            get() {
                return this.value
            },
            set(localSiteDocument) {
                this.$emit('input', localSiteDocument)
            }
        }
    },
    mounted() {
        this.$emit('input', this.localSiteDocument);
    },
};
</script>
