<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header :page-title="'Salesperson Assignments - ' + salesperson.name"/>
      </v-col>
      <v-spacer/>
      <v-col
        class="pa-0"
        cols="auto"
      >
        <v-btn
          :to="{ name: 'admin-salesperson-assignment-create', params: { salesperson_id: salesperson.id } }"
          color="primary"
          rounded
        >
          <v-icon left> mdi-plus-circle-outline</v-icon>
          Add New Assignment
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
      :headers="headers"
      :items="salespersonAssignments"
      :items-per-page="-1"
      :search="search"
      :single-select="true"
      class="elevation-2 mt-3"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <v-card>
            <v-card-title class="text-h5-mulish">
              Confirm
            </v-card-title>
            <v-card-text
              class="pa-4 pl-6 black--text"
              style="font-size: 1.1rem;"
            >
              Are you sure you want to delete this item?
            </v-card-text>
            <v-card-actions class="pt-3 pb-4">
              <v-spacer/>
              <v-btn
                class="cancel-button"
                rounded
                @click="closeDeleteAssignment"
              >
                Cancel
              </v-btn>
              <v-btn
                class="body-2 font-weight-bold"
                color="primary"
                @click="deleteAssignmentConfirm"
              >
                YES
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
          v-model="snackbar"
          centered
        >
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <template #item.commission_rate="{ item }">
        {{ item.commission_rate | formatNumber }}%
      </template>
      <template #item.type="{ item }">
        <v-icon
          class="mr-1"
          color="secondary"
        >
          {{ item.icon }}
        </v-icon>
        {{ item.type }}
      </template>
      <template v-slot:item.actionsSalespersonAssignments="{ item }">
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              @click="editSalespersonAssignment(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Assignment</span>
        </v-tooltip>
        <v-icon
          color="red"
          @click="deleteAssignment(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no assignments on file for {{ salesperson.name }}.
                </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../../main/AdminHeader";
import Loading from "../../../../components/shared/Loading";
import {
  DELETE_SALESPERSON_ASSIGNMENT,
  GET_SALESPERSON,
  GET_SALESPERSON_ASSIGNMENTS
} from "../../../../store/actions.type";

export default {
  name: "AdminSalespersonAssignmentsList",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {},
      dialogDelete: false,
      headers: [
        {
          text: 'DONOR Type',
          align: 'start',
          value: 'type',
        },
        {text: 'Name', value: 'name'},
        {text: 'Commission RATE', value: 'commission_rate', align: 'end'},
        {text: '', value: ''},
        {text: 'Options', value: 'actionsSalespersonAssignments', sortable: false},
      ],
      loading: true,
      search: '',
      snackbar: false,
      snackbarMessage: "",

    }
  },
  computed: {
    salespersonAssignments() {
      return this.$store.getters.currentSalespersonAssignments;
    },
    salesperson() {
      return this.$store.getters.currentSalesperson;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getSalespersonAssignments() {
      this.loading = true;
      let salesperson_id = this.$route.params.salesperson_id;
      this.$store.dispatch(GET_SALESPERSON_ASSIGNMENTS, salesperson_id)
        .then(() => {
          this.$store.dispatch(GET_SALESPERSON, salesperson_id)
          this.loading = false;
        });
    },
    editSalespersonAssignment(item) {
      let assignment_id = item.id;
      let assignment_type = item.type;
      this.$router.push(`assignments/${assignment_id}/${assignment_type}/edit`);
    },
    initialize() {
      this.salespersonAssignments = [];
    },
    closeDeleteAssignment() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteAssignment(item) {
      this.editedIndex = this.salespersonAssignments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteAssignmentConfirm() {
      this.destroyAssignment();
      this.salespersonAssignments.splice(this.editedIndex, 1)
      this.closeDeleteAssignment()
    },
    destroyAssignment() {
      this.saving = true;
      let assignment_id = this.editedItem.id;
      let assignment_type = this.editedItem.type;
      let salesperson_id = this.$route.params.salesperson_id;
      this.$store.dispatch(DELETE_SALESPERSON_ASSIGNMENT, {salesperson_id, assignment_id, assignment_type})
        .then((data) => {
          this.saving = false;
          this.snackbarMessage = data.message_response;
          this.snackbar = true;
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;

          this.snackbar = true;
        });
    },
  },
  mounted() {
    this.getSalespersonAssignments();
  },
}
</script>

<style scoped>::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}</style>
