//Views
import SchoolApplicationList from "../views/schools/school/applications/SchoolApplicationList";
import SchoolApplicationCreate from "../views/schools/school/applications/SchoolApplicationCreate";
import SchoolApplicationEdit from "../views/schools/school/applications/SchoolApplicationEdit";
import SchoolApplicationShow from "../views/schools/school/applications/SchoolApplicationShow";

export default [
    {
        path: "/dashboard/schools/:school_id/partner-agreements",
        name: "school-applications-list",
        component: SchoolApplicationList,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/partner-agreements/create",
        name: "school-application-create",
        component: SchoolApplicationCreate,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/partner-agreements/:application_id/edit",
        name: "school-application-edit",
        component: SchoolApplicationEdit,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/partner-agreements/:application_id/show",
        name: "school-application-show",
        component: SchoolApplicationShow,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'

        },
    },
];
