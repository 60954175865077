<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col cols="6">
        <admin-header page-title="Annual Report"/>
        <v-card class="mt-2">
          <v-form class="pa-4">
            <year-selector
              v-model="fiscal_year_id"
              :years="fiscalYears"
              label="Please select fiscal year for this annual report"
            />
            <div class="mt-2">
              <v-btn
                :to="{ name: 'admin-menu' }"
                class="cancel-button"
                rounded
              > Cancel
              </v-btn>
              <v-btn
                :disabled="disabled"
                :href="'/api/admin/download-annual-report/' + fiscal_year_id"
                class="ml-1"
                color="primary"
                rounded
              >
                <v-icon left> mdi-download</v-icon>
                Create Report
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import AdminHeader from "../main/AdminHeader";
import YearSelector from "../../../components/shared/YearSelector";
import {GET_FISCAL_YEARS} from "../../../store/actions.type";

export default {
  name: "AdminAnnualReport",
  components: {
    YearSelector,
    Loading,
    AdminHeader
  },
  data() {
    return {
      fiscal_year_id: null,
      loading: false,
      saving: false
    }
  },
  computed: {
    disabled() {
      return !this.fiscal_year_id;
    },
    fiscalYears() {
      return this.$store.getters.currentFiscalYears;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getFiscalYears() {
      this.$store.dispatch(GET_FISCAL_YEARS)
    }
  },
  mounted() {
    this.getFiscalYears();
  }

}
</script>

<style scoped></style>
