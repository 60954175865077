import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_SCHOOL_PENDING_STUDENT_AWARDS,
    GET_SCHOOL_STUDENT,
    GET_SCHOOL_STUDENT_AWARD, GET_SCHOOL_STUDENT_AWARD_REFUND,
    GET_SCHOOL_STUDENT_YEARS,
    GET_SCHOOL_STUDENTS, GET_SCHOOL_STUDENTS_NON_RENEWED,
    PATCH_STUDENT_AMOUNT_REQUESTED, PATCH_STUDENT_HIDE,
    POST_SCHOOL_STUDENT_AWARD_ACTION,
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_SCHOOL_PENDING_STUDENT_AWARDS,
    SET_SCHOOL_STUDENT,
    SET_SCHOOL_STUDENT_AWARD, SET_SCHOOL_STUDENT_AWARD_REFUND,
    SET_SCHOOL_STUDENT_YEARS,
    SET_SCHOOL_STUDENTS, SET_SCHOOL_STUDENTS_NON_RENEWED
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        applicationStudentAward: {},
        applicationStudentAwardRefund: {},
        applicationStudentAwardsPending: [],
        schoolStudentYears: [],
        schoolStudent: {},
        schoolStudents: [],
        schoolStudentsNonRenewed: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentApplicationStudentAward(state) {
        return state.applicationStudentAward;
    },
    currentApplicationStudentAwardRefund(state) {
        return state.applicationStudentAwardRefund;
    },
    currentApplicationStudentAwardsPending(state) {
        return state.applicationStudentAwardsPending;
    },
    currentSchoolStudents(state) {
        return state.schoolStudents;
    },
    currentSchoolStudentsNonRenewed(state) {
        return state.schoolStudentsNonRenewed;
    },
    currentSchoolStudent(state) {
        return state.schoolStudent;
    },
    currentSchoolStudentYears(state) {
        return state.schoolStudentYears;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_SCHOOL_STUDENTS](context, {page, itemsPerPage, sortBy, sortDesc, search, school_id, school_year_id}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/schools/${school_id}/students`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search,
                school_id,
                school_year_id
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_STUDENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL_STUDENTS_NON_RENEWED](context, {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
        search,
        school_id,
        school_year_id
    }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/schools/${school_id}/students/non-renewed`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search,
                school_id,
                school_year_id
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_STUDENTS_NON_RENEWED, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL_STUDENT](context, {school_id, application_student_school_id, fiscal_year_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/students/${application_student_school_id}/year/${fiscal_year_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_STUDENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL_STUDENT_YEARS](context, school_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/students/years`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_STUDENT_YEARS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_STUDENT_AMOUNT_REQUESTED](context, {
        school_id,
        application_student_school_id,
        form
    }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/students/${application_student_school_id}`, form)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_STUDENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_STUDENT_HIDE](context, {
        school_id,
        application_student_school_id,
        form
    }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/students/${application_student_school_id}/hide`, form)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL_STUDENT_AWARD](context, {school_id, application_student_school_id, application_student_award_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/students/${application_student_school_id}/awards/${application_student_award_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_STUDENT_AWARD, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SCHOOL_STUDENT_AWARD_ACTION](context, {
        school_id,
        application_student_school_id,
        application_student_award_id,
        awardAction
    }) {
        return new Promise((resolve, reject) => {
            console.log("POST_SCHOOL_STUDENT_AWARD_ACTION: ", school_id, application_student_school_id, application_student_award_id, awardAction)
            ApiService.post(`/api/schools/${school_id}/students/${application_student_school_id}/awards/${application_student_award_id}`, awardAction)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL_PENDING_STUDENT_AWARDS](context, school_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/pending-awards`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_PENDING_STUDENT_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL_STUDENT_AWARD_REFUND](context, {
        school_id,
        application_student_school_id,
        application_student_award_id
    }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/students/${application_student_school_id}/awards/${application_student_award_id}/refunds/`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_STUDENT_AWARD_REFUND, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolStudent",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_SCHOOL_STUDENT_YEARS](state, data) {
        state.schoolStudentYears = data;
    },
    [SET_SCHOOL_STUDENTS](state, data) {
        state.schoolStudents = data;
    },
    [SET_SCHOOL_STUDENTS_NON_RENEWED](state, data) {
        state.schoolStudentsNonRenewed = data;
    },
    [SET_SCHOOL_STUDENT](state, data) {
        state.schoolStudent = data;
    },
    [SET_SCHOOL_STUDENT_AWARD](state, data) {
        state.applicationStudentAward = data;
    },
    [SET_SCHOOL_STUDENT_AWARD_REFUND](state, data) {
        state.applicationStudentAwardRefund = data;
    },
    [SET_SCHOOL_PENDING_STUDENT_AWARDS](state, data) {
        state.applicationStudentAwardsPending = data;
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
