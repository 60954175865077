<template>
    <loading v-if="loading" />
    <div v-else>

        <v-row v-if="isMobile">
            <v-col>
                <company-header page-title="Company Admins" />
            </v-col>
        </v-row>

        <v-row
            v-if="!isMobile"
            align="center"
        >
            <v-col md="6">
                <company-header page-title="Company Admins" />
            </v-col>
            <v-spacer />
            <v-col
                v-if="company.user_is_primary_admin"
                cols="auto"
            >
                <v-btn
                    :to="{ name: 'company-admin-invite', params: { company_id: company.id } }"
                    color="primary"
                    rounded
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    Invite Another Company Admin
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :class="isMobile ? '' : 'ml-4 mt-5'"
            :disable-sort="isMobile"
            :headers="headers"
            :hide-default-footer="isMobile"
            :items="admins"
            class="elevation-2"
        >
            <template v-slot:top>
                <v-toolbar
                    v-if="isMobile"
                    color="secondary"
                    flat
                >
                    <v-row>
                        <v-col>
                            List of Company Admins
                        </v-col>
                        <v-col
                            v-if="company.user_is_primary_admin"
                            cols="auto"
                        >
                            <v-icon
                                color="white"
                                rounded
                                @click="addItem"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-toolbar>

                <v-dialog
                    v-model="dialogDeleteAdmin"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <v-card class="pb-5 dialog">
                        <v-card-title class="pa-4">
                            Confirm
                        </v-card-title>
                        <v-card-text class="mx-n2">
                            <v-row class="mt-2">
                                <v-col class="text-h6-mulish">
                                    Delete admin?
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <div
                            v-if="isMobile"
                            class="px-4"
                        >
                            <v-btn
                                block
                                class="cancel-button"
                                rounded
                                @click="closeDeleteAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="primary"
                                rounded
                                @click="deleteAdminConfirm"
                            >
                                Yes, Delete Admin
                            </v-btn>
                        </div>
                        <v-card-actions v-else>
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="closeDeleteAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="primary"
                                rounded
                                @click="deleteAdminConfirm"
                            >
                                Yes, Delete Admin
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogMakePrimaryAdmin"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >

                    <v-card class="pb-5 dialog">
                        <v-card-title class="pa-4">
                            Confirm
                        </v-card-title>
                        <v-card-text class="mx-n2">
                            <v-row class="mt-2">
                                <v-col class="text-h6-mulish">
                                    Are you sure you want to make this person the primary user for this company?
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <div
                            v-if="isMobile"
                            class="px-4"
                        >
                            <v-btn
                                block
                                class="cancel-button"
                                rounded
                                @click="closeMakePrimaryAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="primary"
                                @click="makePrimaryAdminConfirm"
                            >
                                Yes, Make Them Primary
                            </v-btn>
                        </div>
                        <v-card-actions v-else>
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="closeMakePrimaryAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="primary"
                                @click="makePrimaryAdminConfirm"
                            >
                                Yes, Make Them The Primary User
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
            </template>
            <template #item.is_primary="{ item }">
                {{ item.is_primary ? 'Yes' : 'No' }}
            </template>
            <v-snackbar
                v-model="snackbar"
                centered
            >
                {{ snackbarMessage }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
            <template v-slot:item.phone="props">
                <inline-edit-admin-phone
                    v-model.trim="props.item.phone"
                    class="text-apsto-teal"
                    label="Phone"
                    @input="save(props.item.id, 'phone', $event)"
                />
            </template>
            <template v-slot:item.title="props">
                <inline-edit-admin-title
                    v-model.trim="props.item.title"
                    class="text-apsto-teal"
                    label="Title"
                    @input="save(props.item.id, 'title', $event)"
                />
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :disabled="disableMakePrimaryAdminLink(item)"
                            class="mr-2"
                            color="secondary"
                            v-bind="attrs"
                            @click="makePrimaryAdmin(item)"
                            v-on="on"
                        >
                            mdi-account-convert
                        </v-icon>
                    </template>
                    <span>Assign role of primary admin to someone else</span>
                </v-tooltip>
                <v-icon
                    :disabled="disableDeleteAdminLink(item)"
                    color="red"
                    @click="deleteAdmin(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <company-invites-list class="mt-3" />
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {
    DELETE_COMPANY_ADMIN,
    GET_COMPANY_ADMINS,
    GET_USER_COMPANY,
    PATCH_COMPANY_ADMIN,
    TRANSFER_COMPANY_ADMIN
} from "../../../store/actions.type";
import CompanyHeader from "../../../components/company/CompanyHeader";
import CompanyInvitesList from "./CompanyInvitesList";
import InlineEditAdminPhone from "../../../components/shared/InlineEditAdminPhone";
import InlineEditAdminTitle from "../../../components/shared/InlineEditAdminTitle";
import HouseholdHeader from "../../../components/household/HouseholdHeader.vue";

export default {
    name: "CompanyAdminsList",
    components: {
        HouseholdHeader,
        InlineEditAdminPhone,
        InlineEditAdminTitle,
        CompanyInvitesList,
        CompanyHeader,
        Loading
    },
    data() {
        return {
            defaultItemAdmin: {
                name: '',
                email: '',
                phone: '',
                title: '',
                is_primary: 0,
            },
            dialog: false,
            dialogDeleteAdmin: false,
            dialogMakePrimaryAdmin: false,
            headers:
                [
                    {
                        text: 'Company Admin Name',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Email', value: 'email' },
                    { text: 'Phone', value: 'phone' },
                    { text: 'Title', value: 'title' },
                    { text: 'Primary Admin', value: 'is_primary' },
                    { text: 'Options', value: 'actions', sortable: false, align: 'end' },
                ],
            loading: true,
            search: '',
            selected: [],
            snackbar: false,
            snackbarMessage: "",

        }
    },
    computed: {
        admins() {
            return this.$store.getters.currentCompanyAdmins;
        },
        company() {
            return this.$store.getters.currentUserCompany;
        },
    },
    methods: {
        addItem() {
            this.$router.push({ name: 'company-admin-invite', params: { company_id: this.company.id } });
        },
        getAdmins() {
            this.$store.dispatch(GET_COMPANY_ADMINS, this.$route.params.company_id)
        },
        getCompany() {
            // To get updated value for company.user_is_primary_admin to check whether user can see button to invite new admins.
            this.$store.dispatch(GET_USER_COMPANY, this.$route.params.company_id)
        },
        disableDeleteAdminLink(item) {
            return item.show_delete_link ? false : true;
        },
        disableMakePrimaryAdminLink(item) {
            return item.show_make_primary_link ? false : true;
        },
        closeDeleteAdmin() {
            this.dialogDeleteAdmin = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItemAdmin)
                this.editedIndex = -1
            })
        },
        closeMakePrimaryAdmin() {
            this.dialogMakePrimaryAdmin = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItemAdmin)
                this.editedIndex = -1
            })
        },
        editAdmin(item) {
            this.editedIndex = this.admins.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteAdmin(item) {
            this.editedIndex = this.admins.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDeleteAdmin = true
        },
        deleteAdminConfirm() {
            this.destroyAdmin();
            this.admins.splice(this.editedIndex, 1)
            this.closeDeleteAdmin()
        },
        destroyAdmin() {
            this.saving = true;
            let company_id = this.company.id;
            let user_id = this.editedItem.id;
            this.$store.dispatch(DELETE_COMPANY_ADMIN, { company_id, user_id })
                .then(() => {
                    this.saving = false;
                })
                .catch(({ data }) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        initialize() {
            this.admins = [];
        },
        makePrimaryAdmin(item) {
            this.editedIndex = this.admins.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogMakePrimaryAdmin = true
        },
        makePrimaryAdminConfirm() {
            this.transferPrimaryAdmin();
            this.closeMakePrimaryAdmin()
        },
        transferPrimaryAdmin() {
            this.saving = true;
            let company_id = this.company.id;
            let user_id = this.editedItem.id;
            this.$store.dispatch(TRANSFER_COMPANY_ADMIN, { company_id, user_id })
                .then(() => {
                    this.getCompany(company_id);
                    this.getAdmins();
                    this.saving = false;
                })
                .catch(({ data }) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        // snackbar
        save(user_id, field_name, field_value) {
            let company_id = this.company.id;
            this.$store.dispatch(PATCH_COMPANY_ADMIN, { company_id, user_id, field_name, field_value })
                .then(() => {
                    this.snackbar = true

                    this.snackbarMessage = 'Data saved'
                })
                .catch(({ data }) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        close() {
            console.log('Dialog closed')
        },
    },
    mounted() {
        this.getAdmins();
        this.loading = false;
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDeleteAdmin(val) {
            val || this.closeDeleteAdmin()
        },
        dialogMakePrimaryAdmin(val) {
            val || this.closeMakePrimaryAdmin()
        },
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}</style>
