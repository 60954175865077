<template>
    <loading v-if="loading" />
    <div v-else>

        <v-row v-if="isMobile">
            <v-col>
                <company-header page-title="Donation Requests" />
            </v-col>
        </v-row>

        <v-row
            v-if="!isMobile"
            align="center"
        >
            <v-col md="6">
                <company-header page-title="Donation Requests" />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <!--Can create new donation request-->
                <v-btn
                    v-if="company.organization_type_enum !== 4"
                    :to="{ name: 'corporate-pledge-create', params: { company_id: company.id } }"
                    color="primary"
                    rounded
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    Add New Donation Request
                </v-btn>
                <!--Cannot create new donation request-->
                <v-btn
                    v-if="company.organization_type_enum === 4"
                    color="primary"
                    rounded
                    @click="showDialogCannotCreate = true"
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    Add New Donation Request
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :class="isMobile ? '' : 'mt-5'"
            :disable-sort="isMobile"
            :headers="headers"
            :hide-default-footer="isMobile"
            :items="corporatePledges"
            :single-select="true"
            class="elevation-2"
            item-key="id"
        >
            <template v-slot:top>
                <v-toolbar
                    v-if="isMobile"
                    color="secondary"
                    flat
                >
                    <v-row>
                        <v-col>
                            {{ toolbarTitle }}
                        </v-col>
                        <v-col cols="auto">
                            <v-icon
                                v-if="company.organization_type_enum !== 4"
                                class="ml-2"
                                color="white"
                                rounded
                                @click="addItem"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                            <v-icon
                                v-if="company.organization_type_enum === 4"
                                color="white"
                                rounded
                                @click="showDialogCannotCreate = true"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>

            <template v-slot:no-data>
                <span class="text-apsto-teal no-data">
                    No data added. Click {{ isMobile ? "Plus icon" : "Add button" }} at above right.
                </span>
            </template>
            <template #item.response_info="{ item }">
                <div>
                    {{ item.response_info.line1 }}
                </div>
                <div>
                    {{ item.response_info.line2 }}
                </div>
            </template>
            <template #item.amount="{ item, index }">
                <div v-if="!index">{{ item.amount | formatCurrency }}</div>
                <div v-else>{{ item.amount | formatNumber }}</div>
            </template>
            <template #item.donation_amount="{ item, index }">
                <div v-if="!index">{{ item.donation_amount | formatCurrency }}</div>
                <div v-else>{{ item.donation_amount | formatNumber }}</div>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <span v-on="on">
                            <v-btn
                                :disabled="isDisabled(item)"
                                icon
                            >
                                <v-icon
                                    class="mr-2"
                                    color="iconCyan"
                                    v-bind="attrs"
                                    @click="edit(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </span>
                    </template>
                    <span>
                        {{ isDisabled(item) ? "Donation Request locked by APSTO" : "Edit Donation Request" }}
                    </span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            :href="`/api/companies/${item.company_id}/corporate-pledges/${item.id}/download/pdf`"
                            class="mr-2"
                            target="_blank"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon color="secondary">fas fa-file-pdf</v-icon>
                        </a>
                    </template>
                    <span>Download Donation Request Form</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <span v-on="on">
                            <v-btn
                                :disabled="adorApprovalDisabled(item)"
                                :href="`/api/companies/${item.company_id}/corporate-pledges/${item.id}/download/ador-approval/pdf`"
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon
                                    class="mr-2"
                                    color="iconCyan"
                                >
                                    fas fa-file-pdf
                                </v-icon>
                            </v-btn>
                        </span>
                    </template>
                    <span>
                        {{
                            adorApprovalDisabled(item)
                            ? "Download DOR Approval After DOR Approval Received"
                            : "Download DOR Approval"
                        }}
                    </span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog
            v-model="showDialogCannotCreate"
            :fullscreen="isMobile"
            :max-width="dialogMaxWidth"
        >
            <v-card class="pb-5 dialog">
                <v-card-title class="pa-4">
                    Arizona Restriction
                </v-card-title>
                <v-card-text class="mx-n2">
                    <v-row class="mt-2">
                        <v-col class="text-h6-mulish">
                            Arizona law restricts corporate pledges to the following types of businesses:
                            <ul class="mt-4">
                                <li>
                                    C Corporation
                                </li>
                                <li>
                                    Insurance Company
                                </li>
                                <li>
                                    Sub S or LLC filing as a Sub S
                                </li>
                            </ul>
                            <div class="mt-4">
                                Your employees can participate, though, through our payroll withholding program. Please
                                click below
                                if you'd like us to contact you about payroll withholding.
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <div
                    v-if="isMobile"
                    class="px-4"
                >
                    <v-btn
                        block
                        class="cancel-button"
                        rounded
                        @click="showDialogCannotCreate = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        block
                        class="mt-5"
                        color="primary"
                        @click="contact"
                    >
                        <v-icon left> mdi-check</v-icon>
                        Contact Me
                    </v-btn>
                </div>
                <v-card-actions v-else>
                    <v-btn
                        class="cancel-button"
                        rounded
                        @click="showDialogCannotCreate = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        class="ml-2"
                        color="primary"
                        rounded
                        @click="contact"
                    >
                        <v-icon left> mdi-check</v-icon>
                        Contact Me
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            Thank you. We will contact you shortly.
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import { GET_CORPORATE_PLEDGES, POST_CONTACT_USER_FROM } from "../../../store/actions.type";
import CompanyHeader from "../../../components/company/CompanyHeader";

export default {
    name: "CorporatePledgesList",
    components: {
        CompanyHeader,
        Loading
    },
    data() {
        return {
            headers: [
                { text: 'Year', value: 'tax_year' },
                { text: 'Credit Type', value: 'tax_credit_name' },
                { text: 'Requested', value: 'amount', align: 'end' },
                { text: 'Submitted to ADOR', value: 'submitted_to_ador' },
                { text: 'ADOR Response', value: 'response_info' },
                { text: 'Donor Notified', value: 'notified_by_apsto' },
                { text: 'Date Paid', value: 'date_donation_paid', align: 'end' },
                { text: 'Amount Donated', value: 'donation_amount', align: 'end' },
                { text: "Edit / Downloads", value: "actions", sortable: false, align: "end" },
            ],
            loading: true,
            search: '',
            selected: [],
            showContactAcknowledgement: false,
            showDialogCannotCreate: false,
            showForm: false,
            snackbar: false,
            toolbarTitle: "Your Donation Requests",
            year: null,
            years: [],
        }
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        },
        corporatePledges() {
            return this.$store.getters.currentCorporatePledges;
        },
        user() {
            return this.$store.getters.currentUser;
        },
        error() {
            return this.$store.getters.getMessage("employeeDonations");
        },
        errors() {
            return this.$store.getters.getErrors("employeeDonations");
        },
    },
    methods: {
        addItem() {
            this.$router.push({ name: 'corporate-pledge-create', params: { company_id: this.company.id } });
        },
        contact() {
            this.showDialogCannotCreate = false;
            let contact_type_enum = 3; // Payroll Withholding
            let email = this.user.email;
            let name = this.user.name;
            let message = "I clicked the Contact Me button to contact me about payroll withholding. I'm with " + this.company.name + " and our phone number is " + this.company.phone + ".";
            let subject = "Payroll Withholding"
            this.$store.dispatch(POST_CONTACT_USER_FROM, { contact_type_enum, email, message, name, subject }).then(() => {
                this.snackbar = true;
            });
        },
        edit(item) {
            this.$router.push(`corporate-pledges/${item.id}/edit`);
        },
        isDisabled(item) {
            return !item.can_edit;
        },
        adorApprovalDisabled(item) {
            return !item.files.length;
        },
    },
    mounted() {
        let company_id = this.$route.params.company_id;
        this.$store.dispatch(GET_CORPORATE_PLEDGES, company_id)
            .then(() => {
                this.loading = false;
                this.yearNow = new Date().getFullYear();
                this.years = [
                    { id: this.yearNow, name: this.yearNow },
                    { id: this.yearNow - 1, name: this.yearNow - 1 },
                ]
            });
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}
</style>
