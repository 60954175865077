import { render, staticRenderFns } from "./AnnouncementBar.vue?vue&type=template&id=e7ff6870&scoped=true&"
import script from "./AnnouncementBar.vue?vue&type=script&lang=js&"
export * from "./AnnouncementBar.vue?vue&type=script&lang=js&"
import style0 from "./AnnouncementBar.vue?vue&type=style&index=0&id=e7ff6870&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7ff6870",
  null
  
)

export default component.exports