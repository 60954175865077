//Views
import AdminAnnualReport from "../views/admin/annual-report/AdminAnnualReport";
import AdminBankReportDates from "../views/admin/bank/AdminBankReportDates.vue";
import AdminBrochureRequestsList from "../views/admin/brochure-requests/AdminBrochureRequestsList";
import AdminCompaniesInEmployeeDonationsProgramList
    from "../views/admin/employee-donations/AdminCompaniesInEmployeeDonationsProgramList";
import AdminCompaniesList from "../views/admin/companies/AdminCompaniesList";
import AdminCompanyAnnualDonationsList from "../views/admin/employee-donations/AdminCompanyAnnualDonationsList";
import AdminCompanyEdit from "../views/admin/companies/AdminCompanyEdit";
import AdminCompanyEmployeesList from "../views/admin/employee-donations/AdminCompanyEmployeesList.vue";
import AdminCompanyPayrollBatchList from "../views/admin/employee-donations/AdminCompanyPayrollBatchList";
import AdminCompanyPayrollBatchShow from "../views/admin/employee-donations/AdminCompanyPayrollBatchShow";
import AdminContactsList from "../views/admin/contacts/AdminContactsList";
import AdminCorporatePledgeEdit from "../views/admin/corporate-pledges/AdminCorporatePledgeEdit";
import AdminCorporatePledgesActivityReport from "../views/admin/corporate-pledges/AdminCorporatePledgesActivityReport";
import AdminCorporatePledgesList from "../views/admin/corporate-pledges/AdminCorporatePledgesList";
import AdminCorporatePledgesReportDates from "../views/admin/corporate-pledges/AdminCorporatePledgesReportDates";
import AdminCreateDonorDonationByCheck from "../views/admin/donor-donations/AdminCreateDonorDonationByCheck";
import AdminCreateStudentAwardOffers from "../views/admin/student-awards/AdminCreateStudentAwardOffers.vue";
import AdminDeclarationOfDonors from "../views/admin/declaration-of-donors/AdminDeclarationOfDonors.vue";
import AdminDepositsList from "../views/admin/deposits/AdminDepositsList.vue";
import AdminDonorDonationsActivityReport from "../views/admin/donors/donations/AdminDonorDonationsActivityReport";
import AdminDonorDonationsReportDates from "../views/admin/donors/donations/AdminDonorDonationsReportDates";
import AdminDonorDonationsRevise from "../views/admin/donor-donations/AdminDonorDonationsRevise.vue";
import AdminEmployeeDonationsUpload from "../views/admin/employee-donations/AdminEmployeeDonationsUpload";
import AdminFundSectionDonationsShow from "../views/admin/student-awards/AdminFundSectionDonationsShow";
import AdminFundSectionList from "../views/admin/student-awards/AdminFundSectionList";
import AdminFunds from "../views/admin/funds/AdminFunds";
import AdminFundsActivityReport from "../views/admin/funds/AdminFundsActivityReport";
import AdminHouseholdApplicationReview
    from "../views/admin/households/household/applications/AdminHouseholdApplicationReview";
import AdminHouseholdApplicationsList
    from "../views/admin/households/household/applications/AdminHouseholdApplicationsList";
import AdminHouseholdShow from "../views/admin/households/household/AdminHouseholdShow";
import AdminHouseholdsList from "../views/admin/households/AdminHouseholdsList";
import AdminMenu from "../views/admin/main/AdminMenu";
import AdminMessaging from "../views/admin/main/AdminMessaging.vue";
import AdminPendingStudentAwardsList from "../views/admin/student-awards/AdminPendingStudentAwardsList.vue";
import AdminRecentStudentAwardsList from "../views/admin/student-awards/AdminRecentStudentAwardsList";
import AdminRecommendationsRevise from "../views/admin/student-awards/AdminRecommendationsRevise.vue";
import AdminSalespeopleList from "../views/admin/salespeople/AdminSalespeopleList";
import AdminSalespersonAssignmentCreate from "../views/admin/salespeople/assignments/AdminSalespersonAssignmentCreate";
import AdminSalespersonAssignmentEdit from "../views/admin/salespeople/assignments/AdminSalespersonAssignmentEdit";
import AdminSalespersonAssignmentsList from "../views/admin/salespeople/assignments/AdminSalespersonAssignmentsList";
import AdminSalespersonCreate from "../views/admin/salespeople/AdminSalespersonCreate";
import AdminSalespersonEdit from "../views/admin/salespeople/AdminSalespersonEdit";
import AdminSiteDocumentsList from "../views/admin/site-documents/AdminSiteDocumentsList";
import AdminSiteWallpapersList from "../views/admin/site-wallpapers/AdminSiteWallpapersList";
import AdminStudentAwardsRevise from "../views/admin/student-awards/AdminStudentAwardsRevise.vue";
import AdminStudentsList from "../views/admin/students/AdminStudentsList.vue";
import AdminUsersList from "../views/admin/users/AdminUsersList";
import AdminDonorDonationRevise from "../views/admin/donor-donations/AdminDonorDonationRevise.vue";

export default [
    {
        path: "/dashboard/admin",
        name: "admin-menu",
        component: AdminMenu,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/annual-report",
        name: "admin-annual-report",
        component: AdminAnnualReport,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/banks",
        name: "admin-bank-report-dates",
        component: AdminBankReportDates,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/declaration-of-donors",
        name: "admin-declaration-of-donors",
        component: AdminDeclarationOfDonors,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/donor-donation/:id/revise",
        name: "admin-donor-donation-revise",
        component: AdminDonorDonationRevise,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/donor-donations/revise",
        name: "admin-donor-donations-revise",
        component: AdminDonorDonationsRevise,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/student-awards/pending",
        name: "admin-student-awards-pending",
        component: AdminPendingStudentAwardsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/student-awards/recent",
        name: "admin-student-awards-recent",
        component: AdminRecentStudentAwardsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/student-awards/revise",
        name: "admin-student-awards-revise",
        component: AdminStudentAwardsRevise,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies/:company_id/edit",
        name: "admin-company-edit",
        component: AdminCompanyEdit,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies",
        name: "admin-companies-list",
        component: AdminCompaniesList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies/in-employee-donations-program",
        name: "admin-companies-in-employee-donations-program-list",
        component: AdminCompaniesInEmployeeDonationsProgramList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies/:company_id/employee-donations",
        name: "admin-company-annual-donations-list",
        component: AdminCompanyAnnualDonationsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies/:company_id/employee-donations/:company_annual_donation_id",
        name: "admin-company-daily-donations-list",
        component: AdminCompanyPayrollBatchList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies/:company_id/employee-list/:company_annual_donation_id",
        name: "admin-company-employees-list",
        component: AdminCompanyEmployeesList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies/:company_id/employee-donations/:company_annual_donation_id/batch/:company_payroll_batch_id",
        name: "admin-company-payroll-batch-show",
        component: AdminCompanyPayrollBatchShow,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/companies/:company_id/employee-donations/uploads/upload",
        name: "admin-employee-donations-upload",
        component: AdminEmployeeDonationsUpload,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        }
    },
    {
        path: "/dashboard/admin/brochure-requests",
        name: "admin-brochure-requests-list",
        component: AdminBrochureRequestsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/contacts",
        name: "admin-contacts-list",
        component: AdminContactsList,
        meta: {
            requiresAdmin: true, requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/corporate-pledges",
        name: "admin-corporate-pledges-list",
        component: AdminCorporatePledgesList,
        meta: {
            requiresAdmin: true, requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/corporate-pledges/:corporate_pledge_id/edit",
        name: "admin-corporate-pledge-edit",
        component: AdminCorporatePledgeEdit,
        meta: {
            requiresAdmin: true, requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/messaging",
        name: "admin-messaging",
        component: AdminMessaging,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/deposits",
        name: "admin-deposits-list",
        component: AdminDepositsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/recommendations",
        name: "admin-recommendations-revise",
        component: AdminRecommendationsRevise,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/users",
        name: "admin-users-list",
        component: AdminUsersList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/create-donor-donation-by-check/:user_id",
        name: "admin-create-donor-donation-by-check",
        component: AdminCreateDonorDonationByCheck,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/corporate-pledges/report-dates",
        name: "admin-corporate-pledges-report-dates",
        component: AdminCorporatePledgesReportDates,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/corporate-pledges/activity-report/from/:year_from/thru/:year_thru",
        name: "admin-corporate-pledges-activity-report",
        component: AdminCorporatePledgesActivityReport,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/donor-donations",
        name: "admin-donor-donations-report-dates",
        component: AdminDonorDonationsReportDates,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true
        },
    },
    {
        path: "/dashboard/admin/donor-donations/activity-report/from/:date_from/thru/:date_thru",
        name: "admin-donor-donations-activity-report",
        component: AdminDonorDonationsActivityReport,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/funds",
        name: "admin-funds-report-dates",
        component: AdminFunds,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/funds/activity-report/from/:date_from/thru/:date_thru",
        name: "admin-funds-activity-report",
        component: AdminFundsActivityReport,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/households/:household_id/applications/:application_id/review",
        name: "admin-household-application-review",
        component: AdminHouseholdApplicationReview,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/households/applications",
        name: "admin-household-applications-list",
        component: AdminHouseholdApplicationsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/households/:household_id",
        name: "admin-household-show",
        component: AdminHouseholdShow,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/households",
        name: "admin-households-list",
        component: AdminHouseholdsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/salespeople",
        name: "admin-salespeople-list",
        component: AdminSalespeopleList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/salesperson/create",
        name: "admin-salesperson-create",
        component: AdminSalespersonCreate,
        meta: {
            requiresAdmin: true, requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/salespeople/:salesperson_id/edit",
        name: "admin-salesperson-edit",
        component: AdminSalespersonEdit,
        meta: {
            requiresAdmin: true, requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/salespeople/:salesperson_id/assignments",
        name: "admin-salesperson-assignments-list",
        component: AdminSalespersonAssignmentsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/salespeople/:salesperson_id/assignments/create",
        name: "admin-salesperson-assignment-create",
        component: AdminSalespersonAssignmentCreate,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/salespeople/:salesperson_id/assignments/:assignment_id/:assignment_type/edit",
        name: "admin-salesperson-assignment-edit",
        component: AdminSalespersonAssignmentEdit,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/site-documents",
        name: "admin-site-documents-list",
        component: AdminSiteDocumentsList,
        meta: {
            requiresAdmin: true, requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/fund-sections",
        name: "admin-fund-sections",
        component: AdminFundSectionList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/fund-section-donations/:year/:month/show",
        name: "admin-fund-section-donations-show",
        component: AdminFundSectionDonationsShow,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/site-wallpapers",
        name: "admin-site-wallpapers-list",
        component: AdminSiteWallpapersList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'

        },
    },
    {
        path: "/dashboard/admin/students",
        name: "admin-student-permanents-list",
        component: AdminStudentsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/create-student-award-offers",
        name: "admin-create-student-award-offers",
        component: AdminCreateStudentAwardOffers,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
];
