<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Edit Salesperson"/>
        <v-form :disabled="saving">
          <salesperson-form :salesperson="salesperson"/>
          <v-btn
            :to="{ name: 'admin-salespeople-list' }"
            class="cancel-button"
            rounded
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            rounded
            @click="submit"
          > Save
          </v-btn>
          <v-btn
            color="error"
            rounded
            @click="delRecord"
          > Delete
          </v-btn>
          <confirmation-dialog ref="confirm"></confirmation-dialog>
          <v-snackbar
            v-model="snackbar"
            centered
          >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import ConfirmationDialog from "../../../components/shared/ConfirmationDialog";
import Loading from "../../../components/shared/Loading";
import SalespersonForm from "../../../components/admin/salespeople/salesperson/SalespersonForm";
import {DELETE_SALESPERSON, GET_SALESPERSON, PATCH_SALESPERSON} from "../../../store/actions.type";

export default {
  name: "AdminSalespersonEdit",
  components: {
    AdminHeader,
    ConfirmationDialog,
    Loading,
    SalespersonForm
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarMessage: "",

    }
  },
  computed: {
    salesperson() {
      return this.$store.getters.currentSalesperson;
    },
  },
  methods: {
    async delRecord() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this salesperson?"
        )
      ) {
        this.destroy();
      }
    },
    destroy() {
      this.saving = true;
      let salesperson = this.salesperson;
      this.$store.dispatch(DELETE_SALESPERSON, salesperson)
        .then(() => {
          this.saving = false;
          this.$router.push({name: 'admin-salespeople-list'})
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;
          this.snackbar = true;
        });

    },
    edit(item) {
      this.$router.push(`students/${item.id}/edit`);
    },
    getSalesperson() {
      this.loading = true;
      let salesperson_id = this.$route.params.salesperson_id;
      this.$store.dispatch(GET_SALESPERSON, salesperson_id)
        .then(() => {
          this.loading = false;
        });
    },
    initialize() {
      this.salespeople = [];
    },
    submit() {
      this.saving = true;
      let salesperson = this.salesperson;
      this.$store.dispatch(PATCH_SALESPERSON, salesperson)
        .then(() => {
          this.saving = false;
          this.$router.push({name: 'admin-salespeople-list'})
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.getSalesperson();
    this.loading = false;
  },
}
</script>

<style scoped></style>
