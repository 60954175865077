<template>
  <loading v-if="loading"/>
  <div v-else>
    <admin-company-header :page-title="pageTitle"/>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-row align="center">
            <v-col cols="auto">
              <v-card-title>Payroll Withholding</v-card-title>
              <v-card-subtitle
                class="text-apsto-teal font-weight-black"
                style="text-transform: uppercase;"
              >
                Withheld: {{ withheld }}
              </v-card-subtitle>
            </v-col>
          </v-row>
          <v-row class="mt-n5 px-4">
            <v-col cols="auto">
              <v-btn
                :block="isMobile"
                color="primary"
                rounded
                @click="delRecord"
              >
                <v-icon>mdi-delete</v-icon>
                Delete This Upload
              </v-btn>
            </v-col>
            <v-col class="mr-5">
              <v-btn
                :block="isMobile"
                :href="'/api/companies/' + company.id + '/employee-donations/download/batch/' + companyPayrollBatch.id"
                color="primary"
                rounded
              >
                <v-icon>mdi-arrow-down</v-icon>
                Download This List
              </v-btn>
            </v-col>
          </v-row>
          <v-simple-table
            fixed-header
            min-height="200px"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left pr-15 first-column">Name</th>
                <th class="text-right second-column">Amount</th>
                <th class="text-left pl-15">Paid</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(donorDonation, index) in donorDonationsInBatch"
                :key="index"
              >
                <td class="text-left pr-15 first-column">{{ donorDonation.name }}</td>
                <td
                  v-if="index === 0"
                  class="text-right second-column"
                >
                  {{
                  donorDonation.amount | formatCurrencyWithCents
                  }}
                </td>
                <td
                  v-else
                  class="text-right second-column"
                >
                  {{ donorDonation.amount | formatNumberWithCents }}
                </td>
                <td class="text-left pl-15">{{ paid }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-left pr-15 first-column">Total</td>
                <td class="text-right second-column financial-total">
                  {{ companyPayrollBatch.amount | formatCurrencyWithCents }}
                </td>
                <td class="text-left pr-15">&nbsp;</td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <confirmation-dialog ref="confirm"/>
          <v-snackbar
            v-model="snackbar"
            centered
          >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-col>
      <v-col
        class="mt-n3"
        md="6"
      >
        <admin-company-payroll-batch-payments-table/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import AdminCompanyHeader from "../main/AdminCompanyHeader";
import {DELETE_COMPANY_DAILY_DONATION, GET_EMPLOYEE_DONATION} from "../../../store/actions.type";
import AdminCompanyPayrollBatchPaymentsTable from "./AdminCompanyPayrollBatchPaymentsTable";
import moment from "moment-timezone";
import Spacer from "../../../components/website/components/Faq/Spacer";
import ConfirmationDialog from "../../../components/shared/ConfirmationDialog.vue";

export default {
  name: "AdminCompanyPayrollBatchShow",
  components: {
    ConfirmationDialog,
    Spacer,
    AdminCompanyPayrollBatchPaymentsTable,
    AdminCompanyHeader,
    Loading
  },
  data() {
    return {
      addEditDialog: false,
      dialogDelete: false,
      loading: true,
      snackbar: false,
      snackbarMessage: "",

      temporaryItem: null,
    }
  },
  computed: {
    amountDue() {
      return parseFloat(this.companyPayrollBatch.amount);
    },
    batchName() {
      return ", Batch #" + this.$route.params.company_payroll_batch_id;
    },
    company() {
      return this.$store.getters.currentUserCompany;
    },
    companyPayrollBatch() {
      return this.$store.getters.currentCompanyPayrollBatch;
    },
    dailyDonationDate() {
      return this.companyPayrollBatch.created_at;
    },
    donorDonationsInBatch() {
      return this.$store.getters.currentDonorDonationsInBatch;
    },
    pageTitle() {
      return "Payroll Withholding Uploaded On " + moment(this.dailyDonationDate).format("MM/DD/YYYY") + this.batchName;
    },
    paid() {

      if (!this.paymentsSum) {
        return "No";
      }

      let paymentObligation = parseFloat(this.companyPayrollBatch.amount).toFixed(2);
      if (this.paymentsSum === paymentObligation) {
        return "Yes";
      }

      return "Partially";

    },
    payments() {
      return this.$store.getters.currentPayments;
    },
    paymentsSum() {
      return parseFloat(this.payments.reduce((total, {amount}) => total + amount, 0)).toFixed(2);
    },
    withheld() {
      if (this.companyPayrollBatch.month === undefined) {
        return 0;
      }
      return this.companyPayrollBatch.calendar_year + " / " + this.companyPayrollBatch.month.toString().padStart(2, "0");
    }
  },
  methods: {
    async delRecord() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this list?"
        )
      ) {
        this.deleteDailyDonation();
      }
    },
    getDailyDonation() {
      let batch = this.$route.params.batch;
      let company_payroll_batch_id = this.$route.params.company_payroll_batch_id;
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_EMPLOYEE_DONATION, {company_id, company_payroll_batch_id, batch})
      this.loading = false;
    },
    deleteDailyDonation() {
      let company_payroll_batch_id = this.$route.params.company_payroll_batch_id;
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(DELETE_COMPANY_DAILY_DONATION, {company_id, company_payroll_batch_id})
        .then(() => {
          this.$router.push("/dashboard/admin/companies/" + company_id + "/employee-donations")
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;
          this.snackbar = true;
        });
    },
  },
  mounted() {
    this.getDailyDonation();
  }
}
</script>

<style scoped>
.first-column {
  width: 50%;
}

.second-column {
  width: 20%;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}
</style>
