<template>
    <v-select
        id="status"
        v-model="localStatus"
        :error="!!errors['status']"
        :error-messages="errors['status'] ? errors['status'] : null"
        :items="options"
        :label="label"
        item-text="name"
        item-value="id"
    >
    </v-select>
</template>

<script>

export default {
    name: "StatusSelector",
    data() {
        return {
            options: [
                {
                    id: 1,
                    name: "Incomplete",
                },
                {
                    id: 2,
                    name: "In Review",
                },
                {
                    id: 3,
                    name: "Approved",
                },
            ]
        }
    },
    props: {
        label: {
            type: String,
            default: "Please assign a status",
            required: false
        },
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
        localStatus: {
            get() {
                return this.value
            },
            set(localStatus) {
                this.$emit('input', localStatus)
            }
        }
    },
    mounted() {
        this.$emit('input', this.localStatus);
    },
};
</script>
