<template>
    <div>
        <div class="table-header" style="padding-bottom: 20px;">Recommendations {{ period }}</div>
        <v-data-table
            id="recommendations"
            :footer-props="{ itemsPerPageOptions:[10,20,30] }"
            :headers="recommendationsHeaders"
            :items="recommendations"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :page.sync="page"
            :search="search"
            :server-items-length="serverItemsLength"
            :single-select="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-2 mt-3 student_recommendations"
            fixed-header
            height="100vh"
            item-key="id"
            @pagination="getRecommendations"
            @update:sort-by="getRecommendations"
            @update:sort-desc="getRecommendations"
        >
            <template v-slot:top>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            v-model="search"
                            background-color="white"
                            class="mx-4"
                            label="Search on anything"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
            <span class="text-apsto-teal">
              There are no recommendations on file for this time period.
            </span>
            </template>
            <template v-slot:body.prepend>
                <tr class="totals">
                    <th colspan="3">Totals</th>
                    <th class="text-right">{{ totalRecommendationsAmount | formatCurrencyWithCents }}</th>
                    <th class="text-right">{{ totalRecommendationsFeeAmount | formatCurrencyWithCents }}</th>
                    <th class="text-right">{{ totalRecommendationsNetAmount | formatCurrencyWithCents }}</th>
                    <th>&nbsp;</th>
                </tr>
            </template>
            <template #item.amount="{item, index}">
                <div v-if="index === 0">
                    {{ item.amount | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.amount | formatNumberWithCents }}
                </div>
            </template>
            <template #item.fee_amount="{item, index}">
                <div v-if="index === 0">
                    {{ item.fee_amount | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.fee_amount | formatNumberWithCents }}
                </div>
            </template>
            <template #item.net_amount="{item, index}">
                <div v-if="index === 0">
                    {{ item.amount - item.fee_amount | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.amount - item.fee_amount | formatNumberWithCents }}
                </div>
            </template>
            <template #item.other_recommendations="{item}">

                <!--Display recommended school or comments if either exists-->
                <div v-if="item.other_recommendations.length">
                    <div v-if="item.other_recommendations[0].comments">
                        {{ item.other_recommendations[0].comments }}
                    </div>
                    <div v-else>{{ item.other_recommendations[0].school_name }}</div>
                </div>

                <!--Display corporate pledge school recommendation if exists-->
                <div v-if="item.corporate_recommendations">
                    <div v-if="item.corporate_recommendations.recommendation_type === 3">
                        {{ item.corporate_recommendations.comments }}
                    </div>
                    <div
                        v-if="item.corporate_recommendations.recommendation_type === 1 || item.corporate_recommendations.recommendation_type === 2">
                        <table class="student_recommendations">
                            <thead class="pb-2 text-apsto-purple">
                            <th class="left-align school-name">School</th>
                            <th class="right-align">NET</th>
                            </thead>
                            <tbody>
                            <tr v-for="(school, index) in item.corporate_recommendations.schools" :key="index">
                                <td class="school-name">
                                    {{ school.name }}
                                </td>
                                <td v-if="index === 0" style="text-align: right;">
                                    {{
                                        (item.amount - item.fee_amount) * school.allocation_percentage / 100 | formatCurrencyWithCents
                                    }}
                                </td>
                                <td v-else style="text-align: right;">
                                    {{
                                        (item.amount - item.fee_amount) * school.allocation_percentage / 100 | formatNumberWithCents
                                    }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!--Display recommended students if list exists-->
                <div v-if="item.student_recommendations.length">
                    <table class="student_recommendations">
                        <thead class="pb-2 text-apsto-purple">
                        <th class="left-align student-name">Student</th>
                        <th class="left-align school-name">School</th>
                        <th class="right-align">NET</th>
                        </thead>
                        <tbody>
                        <tr v-for="(student,index) in item.student_recommendations" :key="index">
                            <td class="student-name">
                                <a v-if="student.student_permanent_id" @click="linkedStudent(student)">
                                    {{ student.name }}
                                </a>
                                <a v-else @click="unlinkedStudent(student)">
                                    {{ student.name }} <span class="text-apsto-purple">(UL)</span>
                                </a>
                            </td>
                            <td class="school-name">
                                {{ student.school_name }}
                            </td>
                            <td v-if="index === 0" style="text-align: right;">
                                {{ student.amount | formatCurrencyWithCents }}
                            </td>
                            <td v-else style="text-align: right;">
                                {{ student.amount | formatNumberWithCents }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {GET_FUND_SECTION_RECOMMENDATIONS} from "../../../store/actions.type";

export default {
    name: "DonorRecommendationsTable",
    data() {
        return {
            loading: true,
            search: "",
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,
            sortBy: 'donor_name',
            sortDesc: true,
            serverItemsLength: 0,
            recommendationsHeaders: [
                {text: 'Donor', align: 'start', value: 'donor_name'},
                {text: 'Type', align: 'start', value: 'recommendationable_type'},
                {text: 'Tax Credit Type', align: 'start', value: 'tax_credit_type_name'},
                {text: 'Amount', align: 'end', value: 'amount'},
                {text: 'Fee', align: 'end', value: 'fee_amount'},
                {text: 'Net', align: 'end', value: 'net_amount'},
                {text: 'Other', align: 'start', value: 'other_recommendations'},
            ],
        }
    },
    computed: {
        period() {
            return "Made During " + this.$store.getters.currentFundSectionDonations['period'];
        },
        recommendations() {
            return this.$store.getters.currentFundSectionRecommendations['recommendations'];
        },
        totalRecommendationsAmount() {
            return this.$store.getters.currentFundSectionRecommendations['totalAmount'];
        },
        totalRecommendationsFeeAmount() {
            return this.$store.getters.currentFundSectionRecommendations['totalFeeAmount'];
        },
        totalRecommendationsNetAmount() {
            return this.totalRecommendationsAmount - this.totalRecommendationsFeeAmount;
        },
    },
    methods: {
        linkedStudent(student) {
            this.$router.push(`/dashboard/admin/schools/${student.school_id}/linked-student-awards/${student.student_permanent_id}/dss/${student.id}`);
        },
        unlinkedStudent(student) {
            this.$router.push(`/dashboard/admin/schools/${student.school_id}/unlinked-students/${student.id}`);
        },
        getRecommendations() {
            this.loading = true;
            let year = this.$route.params.year;
            let month = this.$route.params.month;
            let page = this.page;
            let itemsPerPage = this.itemsPerPage;
            let search = this.search;
            let sortBy = this.sortBy;
            let sortDesc = this.sortDesc;
            this.$store.dispatch(GET_FUND_SECTION_RECOMMENDATIONS, {
                year,
                month,
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then((data) => {
                    this.serverItemsLength = data.totalRecords;
                    this.loading = false;
                })
        }
    },
    watch: {
        search() {
            this.page = 1;
            this.getRecommendations();
        }
    }
}
</script>

<style scoped>
a {
    color: var(--apsto-teal) !important;
}

.table-header {
    font-size: 1.5rem;
    font-weight: bold;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

table.student_recommendations {
    width: 100%;
}

table.student_recommendations th {
    line-height: 18px;
    padding-bottom: 3px;
}

table.student_recommendations td {
    padding-bottom: 3px;
    padding-top: 3px;
}

table.student_recommendations .school-name {
    width: 50%;
}

table.student_recommendations .student-name {
    padding-right: 8px;
    width: 30%;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.totals) {
    background-color: transparent;
}

::v-deep table.student_recommendations > tbody > tr:hover {
    background-color: var(--apsto-purple-very-light);
}

::v-deep tr.totals {
    background-color: var(--apsto-teal) !important;
}

::v-deep tr.totals > th {
    color: white;
    font-size: .90rem !important;
}

::v-deep .text-end,
::v-deep .text-start {
    padding-top: 8px !important;
    vertical-align: top;
}
</style>
