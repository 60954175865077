<template>
    <v-card class="pb-5 dialog">
        <v-card-title class="pa-4">
            Update Address
        </v-card-title>
        <v-row class="mt-4 mx-0 mx-md-2">
            <v-col class="text-h6-mulish">
                <vuetify-google-autocomplete
                    id="map"
                    append-icon="mdi-magnify"
                    background-color="white"
                    country="us"
                    filled
                    placeholder="Quick Address Entry: start typing address"
                    v-on:placechanged="getAddressData"
                />
                <v-text-field
                    id="street1"
                    v-model.trim="household.address.street1"
                    :error="!!errors['address.street1']"
                    :error-messages="errors['address.street1'] ? errors['address.street1'] : null"
                    autocomplete="address-line1"
                    background-color="white"
                    filled
                    label="Street1"
                />
                <v-text-field
                    id="street2"
                    v-model.trim="household.address.street2"
                    :error="!!errors['address.street2']"
                    :error-messages="errors['address.street2'] ? errors['address.street2'] : null"
                    autocomplete="address-line2"
                    background-color="white"
                    filled
                    label="Street2"
                />
                <v-text-field
                    id="postal"
                    v-model.trim="household.address.postal"
                    :error="!!errors['address.postal']"
                    :error-messages="errors['address.postal'] ? errors['address.postal'] : null"
                    autocomplete="zip"
                    background-color="white"
                    filled
                    label="Postal / Zip"
                />
                <v-text-field
                    id="city"
                    v-model.trim="household.address.city"
                    :error="!!errors['address.city']"
                    :error-messages="errors['address.city'] ? errors['address.city'] : null"
                    autocomplete="address-level2"
                    background-color="white"
                    filled
                    label="City"
                />
                <region-selector v-model="household.address.region_id" />
            </v-col>
        </v-row>
        <div
            v-if="isMobile"
            class="mx-2"
        >
            <div v-if="error !== null">
                <span class="errors-message">Please scroll up to see errors</span>
            </div>
            <v-btn
                block
                class="cancel-button"
                rounded
                @click="$emit('done')"
            > No Changes
            </v-btn>
            <v-btn
                block
                class="mt-5"
                color="primary"
                rounded
                @click="save"
            > Save Changes
            </v-btn>
        </div>
        <v-card-actions
            v-if="!isMobile"
            class="mt-n6 px-5"
        >
            <div v-if="error !== null">
                <span class="errors-message">Please scroll up to see errors</span>
            </div>
            <v-btn
                class="cancel-button"
                rounded
                @click="$emit('done')"
            > No Changes
            </v-btn>
            <v-btn
                color="primary"
                rounded
                @click="save"
            > Save Changes
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import RegionSelector from "../../shared/RegionSelector.vue";
import { PATCH_HOUSEHOLD } from "../../../store/actions.type";

export default {
    name: "UpdateAddress",
    components: { RegionSelector },
    data() {
        return {
            form: {
                address: "",
                city: "",
                state: "",
                zip: "",
                country: "",
            },
        };
    },
    computed: {
        household() {
            return this.$store.getters.currentUserHousehold;
        },
        regions() {
            return this.$store.getters.currentRegions;
        },
        error() {
            return this.$store.getters.getMessage("households");
        },
        errors() {
            return this.$store.getters.getErrors("households");
        },
    },
    methods: {
        getAddressData: function (addressData, placeResultData, id) {
            this.household.address.street1 = addressData.name;
            this.household.address.city = addressData.locality;
            this.household.address.postal = addressData.postal_code;
            this.household.address.lat = addressData.latitude;
            this.household.address.lon = addressData.longitude;

            let iso_state = addressData.administrative_area_level_1;
            this.household.address.region_id = searchArrayByKey(iso_state, this.regions, 'iso', 'object').id;
        },
        save() {
            this.saving = true;
            let household = this.household;
            this.$store.dispatch(PATCH_HOUSEHOLD, household)
                .then(() => {
                    this.saving = false;
                    this.$emit("done");
                })
                .catch(() => {
                    this.saving = false;
                });
        },
    }
}
</script>

<style scoped>
.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    margin: 0 0 10px 20px;
    padding: 20px 20px 0 20px;
}
</style>
