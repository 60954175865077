<template>
    <v-card
        :to="{ name: buttonName, params: { buttonParam: buttonParamValue } }"
        class="pa-4 fill-height"
        elevation="2"
    >
        <v-row align="center">
            <v-col cols="auto">
                <v-avatar
                    color="primary"
                    rounded
                >
                    <v-icon color="white"> {{ icon }}</v-icon>
                </v-avatar>
            </v-col>
            <v-col>
                <div class="text-h5-mulish">
                    {{ title }}
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-n2">
            <v-col>
                <div class="container-middle">
                    {{ description }}
                </div>
            </v-col>
        </v-row>
        <v-card-actions class="text-h6-mulish text-apsto-teal">
            <v-btn
                :block="!!isMobile"
                :to="{ name: buttonName, params: { buttonParam: buttonParamValue } }"
                class="font-weight-bold"
                color="secondary"
                rounded
            >
                {{ buttonText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "BlockPanel",
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        buttonName: {
            type: String,
            required: true
        },
        buttonText: {
            type: String,
            required: true
        },
        buttonParam: {
            type: String,
            required: false,
            default: ""
        },
        buttonParamValue: {
            type: Number,
            required: false,
            default: 0
        }
    },
}
</script>


<style scoped>
.container-middle {
    min-height: 115px;
    padding-bottom: 10px;
}

.v-card__actions {
    padding-left: 0 !important;
}
</style>
