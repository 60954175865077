<template>
    <loading v-if="loading"/>
    <div v-else>
        <div v-if="invites.length">
            <v-row
                v-if="!isMobile"
                class="ml-2 my-3"
            >
                <v-col class="text-h5-mulish"> Invitations (Invitees are on this list until they join your
                    household)
                </v-col>
            </v-row>
            <v-data-table
                :class="isMobile ? '' : 'ml-4'"
                :disable-sort="isMobile"
                :headers="headersInvite"
                :hide-default-footer="isMobile"
                :items="invites"
                class="elevation-2"
            >
                <template v-slot:top>

                    <v-toolbar
                        v-if="isMobile"
                        color="secondary"
                        flat
                    >
                        <v-row>
                            <v-col>
                                List of Invitations
                            </v-col>
                        </v-row>
                    </v-toolbar>

                    <v-dialog
                        v-model="dialogDeleteInvite"
                        :fullscreen="!!isMobile"
                        :max-width="dialogMaxWidth"
                    >
                        <v-card class="pb-5 dialog">
                            <v-card-title class="pa-4">
                                Confirm
                            </v-card-title>
                            <v-card-text class="mx-n2">
                                <v-row class="mt-2">
                                    <v-col class="text-h6-mulish">
                                        Delete invitation?
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <div
                                v-if="isMobile"
                                class="mx-4"
                            >
                                <v-btn
                                    block
                                    class="cancel-button"
                                    rounded
                                    @click="closeDeleteInvite"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    block
                                    class="mt-5"
                                    color="primary"
                                    rounded
                                    @click="deleteInviteConfirm"
                                >
                                    Yes, Delete Invitation
                                </v-btn>
                            </div>
                            <v-card-actions v-else>
                                <v-btn
                                    class="cancel-button"
                                    rounded
                                    @click="closeDeleteInvite"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    class="ml-2"
                                    color="primary"
                                    rounded
                                    @click="deleteInviteConfirm"
                                >
                                    Yes, Delete Invitation
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        v-model="dialogSendEmail"
                        :fullscreen="!!isMobile"
                        :max-width="dialogMaxWidth"
                    >
                        <v-card class="pb-5 dialog">
                            <v-card-title class="pa-4">
                                Confirm
                            </v-card-title>
                            <v-card-text class="mx-n2">
                                <v-row class="mt-2">
                                    <v-col class="text-h6-mulish">
                                        Resend earlier invitation?
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <div
                                v-if="isMobile"
                                class="mx-4"
                            >
                                <v-btn
                                    block
                                    class="cancel-button"
                                    rounded
                                    @click="closeSendEmail"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    block
                                    class="mt-5"
                                    color="primary"
                                    rounded
                                    @click="sendEmailConfirm"
                                >
                                    YES
                                </v-btn>
                            </div>
                            <v-card-actions v-else>
                                <v-btn
                                    class="cancel-button"
                                    rounded
                                    @click="closeSendEmail"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    class="ml-2"
                                    color="primary"
                                    rounded
                                    @click="sendEmailConfirm"
                                >
                                    YES
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-snackbar
                        v-model="snackbar"
                        centered
                    >
                        {{ snackbarMessage }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </template>
                <template #item.user_id="{ item }">
                    {{ item.user_id ? 'Yes' : 'No' }}
                </template>
                <template v-slot:item.actionsInvite="{ item }">
                    <v-tooltip
                        bottom
                        color="secondary"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="mr-2"
                                color="secondary"
                                v-bind="attrs"
                                @click="sendEmail(item)"
                                v-on="on"
                            >
                                mdi-email
                            </v-icon>
                        </template>
                        <span>Resend Invitation</span>
                    </v-tooltip>
                    <v-icon
                        color="red"
                        @click="deleteInvite(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {DELETE_INVITE, GET_HOUSEHOLD_INVITES, PATCH_INVITE} from "../../../store/actions.type";

export default {
    name: "HouseholdInvitesList",
    components: {
        Loading
    },
    data() {
        return {
            defaultItemInvite: {
                invitable_type: 'Application',
                invitable_id: 0,
                email: '',
                token: '',
                user_id: 0
            },
            dialog: false,
            dialogDeleteInvite: false,
            dialogSendEmail: false,
            headersInvite: [
                {
                    text: 'Invitee Name',
                    align: 'start',
                    value: 'name',
                },
                {text: 'Email', value: 'email'},
                {text: 'Options', value: 'actionsInvite', sortable: false, align: 'end'},
            ],
            loading: true,
            search: '',
            selected: [],
            snackbar: false,
            snackbarMessage: '',
        }
    },
    computed: {
        invites() {
            return this.$store.getters.currentHouseholdInvites;
        },
    },
    methods: {
        getInvites() {
            this.loading = true;
            this.$store.dispatch(GET_HOUSEHOLD_INVITES, this.$route.params.household_id).then(() => {
                this.loading = false;
            });
        },
        closeDeleteInvite() {
            this.dialogDeleteInvite = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItemInvite)
                this.editedIndex = -1
            })
        },
        closeSendEmail() {
            this.dialogSendEmail = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItemInvite)
                this.editedIndex = -1
            })
        },
        initialize() {
            this.invites = [];
        },
        deleteInvite(item) {
            this.editedIndex = this.invites.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDeleteInvite = true
        },
        deleteInviteConfirm() {
            this.destroyInvite();
            this.invites.splice(this.editedIndex, 1)
            this.closeDeleteInvite()
        },
        editInvite(item) {
            this.editedIndex = this.invites.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        sendEmail(item) {
            this.editedIndex = this.invites.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogSendEmail = true
        },
        sendEmailConfirm() {
            this.sendEmailToRecipient();
            this.closeSendEmail()
        },
        destroyInvite() {
            this.saving = true;
            let invite_id = this.editedItem.id;
            this.$store.dispatch(DELETE_INVITE, invite_id)
                .then(() => {
                    this.saving = false;
                })
                .catch(({data}) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        sendEmailToRecipient() {
            let invite_id = this.editedItem.id;
            this.$store.dispatch(PATCH_INVITE, invite_id)
                .then(() => {
                    this.saving = false;
                    this.snackbarMessage = "Email sent";

                    this.snackbar = true;
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
        // snackbar
        save() {
            console.log(this);
            this.snackbar = true

            this.snackbarMessage = 'Data saved'
        },
        close() {
            console.log('Dialog closed')
        },
    },
    mounted() {
        this.getInvites();
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDeleteInvite(val) {
            val || this.closeDeleteInvite()
        },
        dialogSendEmail(val) {
            val || this.closeSendEmail()
        },
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}
</style>
