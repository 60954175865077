<template>
    <v-card class="pa-3">
        <v-card-title class="justify-space-between font-weight-black">
            CREATE ACCOUNT
        </v-card-title>
        <v-card-subtitle>
            <div class="text-h6-mulish">
                Welcome to APSTO! You've been invited as an admin by {{ this.entityName }}.
            </div>
            <p class="text-h6-mulish">
                Please complete the following information to register with us:
            </p>
        </v-card-subtitle>
        <v-card-subtitle
            v-if="error"
            :class="error ? 'red--text text-center' : 'text-center'"
        >
            {{ error }}
        </v-card-subtitle>

        <v-card-text>
            <v-form
                ref="registerform"
                v-model="isFormValid"
            >
                <v-text-field
                    ref="first_name"
                    v-model="first_name"
                    :rules="rules.name"
                    :success="!!first_name"
                    label="First Name"
                    name="first_name"
                    type="text"
                    validate-on-blur
                    @keyup.enter="$refs.last_name.focus()"
                />

                <v-text-field
                    ref="last_name"
                    v-model="last_name"
                    :rules="rules.name"
                    :success="!!last_name"
                    label="Last Name"
                    name="last_name"
                    type="text"
                    validate-on-blur
                    @keyup.enter="$refs.password.focus()"
                />

                <v-text-field
                    ref="email"
                    v-model="this.email"
                    disabled
                    label="Email"
                    name="email"
                    type="email"
                />


                <v-text-field
                    id="password"
                    ref="password"
                    v-model="password"
                    :error="!!errors && !!errors.password"
                    :error-messages="!!errors && errors.password ? errors.password : null"
                    :hint="passwordStrength.strength >= 100
                        ? 'Requirements met.'
                        : 'Please enter 8 or more characters. We recommend at least uppercase, lowercase, number, and special character.'
                        "
                    :rules="[
                        passwordStrength.strength >= 100 ||
                        'Please enter 8 or more characters. We recommend at least uppercase, lowercase, number, and special character.',
                    ]"
                    :success="passwordStrength.strength >= 100"
                    label="Password"
                    name="password"
                    persistent-hint
                    type="password"
                    @focus="clearErrors()"
                    @keyup.enter="$refs.password_confirmation.focus()"
                >
                </v-text-field>

                <v-text-field
                    id="password_confirmation"
                    ref="password_confirmation"
                    v-model="password_confirmation"
                    :error="!doPasswordsMatch"
                    :rules="[doPasswordsMatch || 'Must match first password.']"
                    :success="!!password.length && !!doPasswordsMatch"
                    label="Confirm Password"
                    name="password_confirmation"
                    type="password"
                    @focus="clearErrors()"
                    @keyup.enter="signUp()"
                />
            </v-form>
        </v-card-text>
        <v-card-actions class="align-end">
            <v-spacer/>
            <v-btn
                :disabled="!isFormValid"
                :loading="loading"
                color="secondary"
                large
                rounded
                @click.native="signUp"
            >
                REGISTER
            </v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
import {POST_AFTER_REGISTRATION_FROM_INVITE, REGISTER} from "../../store/actions.type";
import {CLEAR_ERRORS} from "../../store/mutations.type";

export default {
    name: "RegisterFromInvitationForm",
    props: {
        email: {
            type: String,
            required: true,
            default: ""
        },
        entityName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            first_name: "",
            last_name: "",
            password: "",
            password_confirmation: "",
            remember: true,
            loading: false,
            passwordToolTip: false,
            isFormValid: false,

            // Validation Rules
            rules: {
                name: [(v) => !!v || "Name is required"],
                email: [
                    (v) => !!v || "E-mail is required",
                    (v) =>
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            v
                        ) || "Must be a valid email address",
                    (v) => true || "Email already associated with another account.",
                ],
                password: (v) =>
                    !!v ||
                    "Please enter 8 or more characters. We recommend at least uppercase, lowercase, number, and special character.",
            },
        };
    },
    watch: {
        first_name(val) {
            this.$refs.registerform.validate();
        },
        last_name(val) {
            this.$refs.registerform.validate();
        },
        password(val) {
            this.$refs.registerform.validate();
        },
        password_confirmation(val) {
            this.$refs.registerform.validate();
        },
        passwordStrength(val) {
            if (val.strength >= 100) {
                this.passwordToolTip = false;
            } else {
                this.passwordToolTip = true;
            }
        },
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("register");
        },
        errors() {
            return this.$store.getters.getErrors("register");
        },
        // Field specific errors
        emailErrors() {
            return this.errors.email && this.errors.email.length > 0;
        },
        passwordErrors() {
            return this.errors.password && this.errors.password.length > 0;
        },
        passwordStrength() {
            return this.checkPasswordStrength(this.password);
        },
        passwordStrengthColor() {
            const {strength} = this.passwordStrength;
            if (strength >= 100) {
                return "seaGreen";
            } else if (strength > 50) {
                return "info";
            } else if (strength > 25) {
                return "gold";
            } else return "red";
        },
        doPasswordsMatch() {
            return this.password === this.password_confirmation;
        },
    },
    methods: {
        signUp() {
            this.loading = true;
            let first_name = this.first_name;
            let last_name = this.last_name;
            let email = this.email;
            let password = this.password;
            let password_confirmation = this.password_confirmation;
            let remember = this.remember;
            this.$store.dispatch(REGISTER, {
                first_name,
                last_name,
                email,
                password,
                password_confirmation,
                remember,
            })
                .then((data) => {
                    let user_id = data.user.id;
                    let token = this.$route.params.token;
                    this.$store.dispatch(POST_AFTER_REGISTRATION_FROM_INVITE, {token, user_id})
                    this.loading = false;
                    this.$router.push({name: "dashboard"});
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        }
    },
};
</script>

