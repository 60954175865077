<template>
    <v-data-table
        :headers="headers"
        :items="donorPriorDonations"
        class="elevation-2"
        sort-by="first_name"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-spacer />
                <v-btn
                    color="primary"
                    dark
                    rounded
                    @click="createItem"
                >
                    Add Prior Donation
                </v-btn>
                <v-dialog
                    v-model="addEditDialog"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <prior-donation
                        :admin-creating-donor-donation="adminCreatingDonorDonation"
                        :edited-item="temporaryItem"
                        :email="email"
                        :selected-tax-year="selectedTaxYear"
                        @done="addEditDialog = false"
                    />
                </v-dialog>
                <v-dialog
                    v-model="dialogDelete"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <v-card>
                        <v-card-title class="text-h5"> Confirm</v-card-title>
                        <v-card-text
                            class="pa-4 pl-6 black--text"
                            style="font-size: 1.1rem"
                        >
                            Are you sure you want to delete this item?
                        </v-card-text>
                        <v-card-actions class="pt-3 pb-4">
                            <v-spacer />
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="dialogDelete = false"
                            >Cancel
                            </v-btn>
                            <v-btn
                                class="save-by-cancel-button"
                                color="primary"
                                rounded
                                @click="deleteItemConfirm"
                            >YES
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>

        <template #item.amount="{ item }">
            {{ item.amount | formatCurrency }}
        </template>

        <template #item.sto_name="{ item }">
            <div v-if="item.sto_name">
                {{ item.sto_name }}
            </div>
            <div v-else>Not provided</div>
        </template>

        <template #item.created_at="{ item }">
            <div v-if="item.created_at">{{ displayDate(item.created_at) }}</div>
        </template>
        <!-- Actions -->
        <template v-slot:item.actions="{ item }">
            <v-tooltip
                bottom
                color="secondary"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        :disabled="disableButtons(item)"
                        class="mr-2"
                        v-bind="attrs"
                        @click="editItem(item)"
                        v-on="on"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Edit prior donation</span>
            </v-tooltip>
            <v-icon
                :disabled="disableButtons(item)"
                color="red"
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <span class="text-apsto-teal"> No prior donations have been added. </span>
        </template>
    </v-data-table>
</template>

<script>
import PriorDonation from "./PriorDonation";
import { DELETE_DONOR_PRIOR_DONATION } from "../../store/actions.type";

export default {
    name: "PriorDonationsTable",
    components: { PriorDonation },
    props: {
        adminCreatingDonorDonation: {
            default: false,
            required: false,
        },
        email: {
            type: String,
            required: true
        },
        selectedTaxYear: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        addEditDialog: false,
        dialogDelete: false,
        headers: [
            { text: "DATE", align: "start", value: "created_at", sortable: false },
            { text: "STO Name (OPTIONAL)", value: "sto_name", sortable: false },
            { text: "Amount", value: "amount", sortable: false },
            { text: "ACTIONS", value: "actions", sortable: false },
        ],
        temporaryItem: null,
        snackbar: false,
        snackbarMessage: "",
    }),
    computed: {
        defaultItem() {
            return {
                amount: 0,
                created_at: new Date().toISOString(),
                email: this.email,
                sto_name: "",
            };
        },
        donorPriorDonations() {
            return this.$store.getters.currentDonorPriorDonations(this.selectedTaxYear);
        },
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
    },
    methods: {
        createItem() {
            this.temporaryItem = Object.assign({}, this.defaultItem);
            this.addEditDialog = true;
        },
        editItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.addEditDialog = true;
        },
        deleteItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            let item_id = this.temporaryItem.id;
            this.$store.dispatch(DELETE_DONOR_PRIOR_DONATION, item_id).then(() => {
                this.dialogDelete = false;
            });
        },
        disableButtons(item) {
            return !item.can_delete;
        },
    },
};
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}
</style>
