<template>
    <loading v-if="loading" />
    <div v-else>
        <table
            v-for="(row, row_index) in awards"
            id="awards"
            :key="row.id"
            class="row-main"
        >
            <tr>
                <td class="label">Award Month</td>
                <td class="data">{{ row.period_name }}</td>
            </tr>
            <tr>
                <td class="label">Type</td>
                <td class="data">{{ row.tax_credit_type_label }}</td>
            </tr>
            <tr>
                <td class="label">Accepted</td>
                <td class="data">{{ row.amount | formatCurrencyWithCents }}</td>
            </tr>
            <tr v-if="row.refunds_sum">
                <td class="label">Prior Refunds</td>
                <td class="data">{{ row.refunds_sum | formatCurrencyWithCents }}</td>
            </tr>

            <tr>
                <td class="label">Available</td>
                <td class="data">{{ row.remaining_balance | formatCurrencyWithCents }}</td>
            </tr>
            <tr>
                <td colspan="3">
                    <v-row
                        class="mt-4"
                        no-gutters
                    >
                        <v-col>
                            <v-text-field
                                :key="row.id"
                                v-model.trim="row.apply_amount"
                                :error="!!errors.apply_amount"
                                :error-messages="errors.apply_amount ? errors.apply_amount : null"
                                :rules="[rules(row, row.apply_amount)]"
                                background-color="white"
                                filled
                                hide-details="auto"
                                label="Refund Amount To Apply"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                :key="row.id"
                                :disabled="row.remaining_balance === 0"
                                class="mt-2 ml-2"
                                color="primaryLight white--text"
                                @click="applyMax(row)"
                            >
                                MAX
                            </v-btn>
                        </v-col>
                    </v-row>
                </td>
            </tr>

            <tr v-if="row.refunds_sum">
                <th class="text-left">Refund Date</th>
                <th class="text-left">Reason</th>
                <th class="text-right">Amount</th>
            </tr>
            <tr
                v-for="(refund, refundIndex) in row.refunds"
                :key="refund.id"
            >
                <td> {{ refund.date_deposited }}</td>
                <td> {{ refund.reason }}</td>
                <td
                    v-if="!refundIndex"
                    class="text-right"
                > {{ refund.amount | formatCurrencyWithCents }}
                </td>
                <td
                    v-else
                    class="text-right"
                > {{ refund.amount | formatNumberWithCents }}
                </td>
            </tr>

        </table>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import { GET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS } from "../../../store/actions.type";

export default {
    name: "StudentAvailableAwards",
    components: {
        Loading,
    },
    props: {
        applicationStudentSchoolId: {
            type: Number,
            required: true,
        },
        fiscalYearId: {
            type: Number,
            required: true,
        },
        schoolId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        deposit() {
            return this.$store.getters.currentAdminDeposit;
        },
        awards() {
            return this.$store.getters.currentAdminSchoolStudentAvailableAwards;
        },
        awardRefunds() {
            return this.$store.getters.currentAdminDepositAwardRefunds;
        },
        awardRefundsTotal() {
            return this.awardRefunds.reduce((total, refund) => {
                return parseFloat(total) + parseFloat(refund.amount);
            }, 0);
        },
        pendingRefundsTotal() {
            return this.awards.reduce((total, award) => {
                return parseFloat(total) + parseFloat(award.apply_amount);
            }, 0);
        },
        pendingRefundsToDepositRemaining() {
            return parseFloat(this.deposit.amount) - (parseFloat(this.awardRefundsTotal) + parseFloat(this.pendingRefundsTotal));
        },
        refundsToDepositRemaining() {
            return parseFloat(this.deposit.amount) - parseFloat(this.awardRefundsTotal);
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        rules(award, v) {
            if (this.pendingRefundsToDepositRemaining < 0) {
                return "Amount exceeds remaining pending refund amount by: " + Math.abs(parseFloat(this.pendingRefundsToDepositRemaining));
            }
            if (v > this.refundsToDepositRemaining) {
                return "Amount exceeds remaining refund amount: " + (parseFloat(this.refundsToDepositRemaining) - parseFloat(v));
            }
            if (v > award.remaining_balance) {
                return "Amount exceeds available amount";
            }
            return true;
        },
        getStudentAvailableAwards() {
            this.loading = true;
            let application_student_school_id = this.applicationStudentSchoolId;
            let fiscal_year_id = this.fiscalYearId;
            let school_id = this.schoolId;
            this.$store.dispatch(GET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS, {
                application_student_school_id,
                fiscal_year_id,
                school_id
            }).then(() => {
                this.loading = false;
            });
        },
        applyMax(row) {
            if (this.pendingRefundsToDepositRemaining === 0) {
                return;
            }
            if (parseFloat(row.remaining_balance) >= parseFloat(this.pendingRefundsToDepositRemaining) + row.apply_amount) {
                row.apply_amount = parseFloat(this.pendingRefundsToDepositRemaining) + parseFloat(row.apply_amount);
            } else {
                row.apply_amount = parseFloat(row.remaining_balance);
            }
        }
    },
    mounted() {
        this.getStudentAvailableAwards();
    },
}
</script>

<style scoped>
::v-deep table#awards tr td {
    height: unset !important;
}

.row-main {
    background-color: white;
    border: 1px solid var(--apsto-teal);
    margin: 20px 0;
    padding: 20px;
    width: 100%;

    .label {
        font-weight: bold;
        width: 25%;
    }

}</style>
