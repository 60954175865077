<template>
    <div>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "Snackbar",
    props: {
        message: {
            type: String,
            default: "",
        },
        snackbar: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped></style>
