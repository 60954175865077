import { render, staticRenderFns } from "./BarDrawer.vue?vue&type=template&id=cf644822&scoped=true&"
import script from "./BarDrawer.vue?vue&type=script&lang=js&"
export * from "./BarDrawer.vue?vue&type=script&lang=js&"
import style0 from "./BarDrawer.vue?vue&type=style&index=0&id=cf644822&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf644822",
  null
  
)

export default component.exports