<template>
    <v-row>
        <v-col>
            <div class="instructions-box">
                <div class="font-weight-bold"> Household Income Instructions</div>
                <ul class="mt-5">
                    <li style="font-size: 1rem;">
                        The table below must include every person living in your household (adults and
                        children). Use the previous sections to make any changes to the list of your household members.
                    </li>
                    <li style="font-size: 1rem;">
                        Every person living in your household must be listed whether or not they receive income.
                    </li>
                    <li style="font-size: 1rem;">
                        Click a person in the table below to add or change their income.
                        Each person's financial information shown must match your household's
                        {{ priorCalendarYear }}
                        tax return(s) exactly.
                    </li>
                </ul>
                <div class="mt-5">
                    <v-checkbox
                        v-model="application.agree_to_income_reporting_instructions"
                        :error="!!errors['agree_to_income_reporting_instructions']"
                        :error-messages="errors['agree_to_income_reporting_instructions'] ? errors['agree_to_income_reporting_instructions'] : null"
                        color="secondary"
                        @change="updateHouseholdApplicationCheckbox('agree_to_income_reporting_instructions')"
                    >
                        <template v-slot:label>
                            <div class="text-apsto-teal font-weight-black">
                                Please check here to confirm that you have read and understand these
                                instructions.
                            </div>
                        </template>
                    </v-checkbox>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { PATCH_HOUSEHOLD_APPLICATION_CHECKBOX } from "../../../store/actions.type";

export default {
    name: "IncomeInstructions",

    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        priorCalendarYear() {
            return parseInt(this.schoolYearStartYear && this.schoolYearStartYear.substring(0, 4)) - 1;
        },
        schoolYearStartYear() {
            return this.application.school_year_start_year;
        },

        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
    },
    methods: {
        updateHouseholdApplicationCheckbox(field_name) {
            console.log("field_name", field_name)
            this.saving = true;
            let household_application_id = this.$route.params.application_id;
            let household_id = this.$route.params.household_id;
            this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_CHECKBOX,
                {
                    household_id,
                    household_application_id,
                    field_name,
                }
            )
                .then(() => {
                    this.saving = false;
                });
        },
    }
}
</script>

<style scoped>
ol li {
    margin-bottom: 20px;
}

ol li:first-of-type {
    margin-top: 20px;
}

.instructions-box {
    background-color: white;
    font-family: "Mulish", sans-serif;
    padding: 20px 20px 0;
}
</style>
