<template>
    <loading v-if="loading" />
    <your-page-content
        v-else
        :promo="promo"
    />
</template>

<script>
import { GET_CHILD_PROMO_DISPLAY, GET_FAMILY_PROMO_DISPLAY } from '../../../../store/actions.type';

import Loading from '../../../../components/shared/Loading';
import YourPageContent from './YourPageContent';

export default {
    name: "DisplayPromo",
    components: {
        YourPageContent,
        Loading
    },
    data() {
        return {
            loading: true,
        }
    },
    computed: {
        promo() {
            return this.$store.getters.currentPromo;
        }
    },
    methods: {
        loadPromo() {
            let household_id = this.$route.params.household_id;
            if (this.$route.params.child_id) {
                let child_id = this.$route.params.child_id;
                console.log("household_id: ", household_id, " child_id: ", child_id);
                this.$store.dispatch(GET_CHILD_PROMO_DISPLAY, { household_id, child_id }).then(() => {
                    this.loading = false;
                }).catch((e) => {
                    console.log(e.getMessage());
                    this.loading = false;
                });
            } else {
                this.$store.dispatch(GET_FAMILY_PROMO_DISPLAY, { household_id }).then(() => {
                    console.log(household_id);
                    this.loading = false;
                }).catch((e) => {
                    console.log(e.getMessage());
                    this.loading = false;
                });
            }

        }
    },
    mounted() {
        this.loadPromo();
    }
}
</script>
