<template>
    <v-card class="dialog">
        <v-card-title class="pa-4">
            {{ editedItem.id ? "Edit" : "Add" }} {{ memberTypeName }}
        </v-card-title>
        <v-row
            class="mt-5"
            no-gutters
        >
            <v-col class="mx-2 mx-md-4">
                <v-text-field
                    id="first_name"
                    v-model.trim="editedItem.first_name"
                    :error="!!errors['first_name']"
                    :error-messages="errors['first_name'] ? errors['first_name'] : null
                        "
                    background-color="white"
                    filled
                    label="First Name"
                />
                <v-text-field
                    id="last_name"
                    v-model.trim="editedItem.last_name"
                    :error="!!errors['last_name']"
                    :error-messages="errors['last_name'] ? errors['last_name'] : null"
                    background-color="white"
                    filled
                    label="Last Name"
                />
                <v-radio-group
                    v-if="editedItem.type === 1"
                    v-model="editedItem.is_child"
                    :error="!!errors['is_child']"
                    :error-messages="errors['is_child'] ? errors['is_child'] : null"
                    class="mt-n2"
                    column
                >
                    <template v-slot:label>
                        <div
                            class="text--primary darken-4 pb-2"
                            style="font-size: 1rem;"
                        >
                            Is this household member under the age of 18 and not a student in a private school?
                        </div>
                    </template>
                    <v-radio
                        :value="1"
                        color="secondary"
                        label="Yes"
                    />
                    <v-radio
                        :value="0"
                        color="secondary"
                        label="No"
                    />
                </v-radio-group>
                <v-text-field
                    v-if="editedItem.type === 3"
                    id="nick_name"
                    v-model.trim="editedItem.nick_name"
                    :error="!!errors['nick_name']"
                    :error-messages="errors['nick_name'] ? errors['nick_name'] : null"
                    background-color="white"
                    filled
                    label="Nickname (How You and Teachers Refer to This Student)"
                />
                <v-text-field
                    v-if="editedItem.type === 2"
                    id="email"
                    v-model.trim="editedItem.email"
                    :error="!!errors['email']"
                    :error-messages="errors['email'] ? errors['email'] : null"
                    background-color="white"
                    filled
                    label="Email"
                />
                <v-text-field
                    v-if="editedItem.type === 2"
                    id="phone"
                    v-model.trim="editedItem.phone"
                    :error="!!errors['phone']"
                    :error-messages="errors['phone'] ? errors['phone'] : null"
                    background-color="white"
                    filled
                    label="Phone"
                    @input="phoneNumber"
                />
                <div v-if="editedItem.type === 3">
                    <v-text-field
                        v-model="editedItem.birth_date"
                        :error="!!errors['birth_date']"
                        :error-messages="errors['birth_date'] ? errors['birth_date'] : null"
                        background-color="white"
                        filled
                        label="Birth Date"
                        type="date"
                    />
                    <family-member-student
                        :application="application"
                        :edited-item="editedItem"
                        :school-year-name="application.school_year_name"
                        class="mt-2"
                    />
                </div>
            </v-col>
        </v-row>

        <v-row v-if="error">
            <v-col class="errors-message">
                Please scroll up to see errors
            </v-col>
        </v-row>

        <div
            v-if="isMobile"
            class="pb-5 mx-2"
        >
            <v-btn
                block
                class="cancel-button"
                rounded
                @click="$emit('done')"
            >
                Cancel
            </v-btn>
            <v-btn
                :disabled="disabledButton"
                block
                class="mt-5 save-by-cancel-button"
                color="primary"
                @click="save"
                rounded
            >
                Save
            </v-btn>
        </div>

        <v-card-actions
            v-if="!isMobile"
            class="mt-n3 pb-6"
        >
            <v-btn
                class="cancel-button"
                rounded
                @click="$emit('done')"
            >
                Cancel
            </v-btn>
            <v-btn
                :disabled="disabledButton"
                color="primary"
                rounded
                @click="save"
                class="save-by-cancel-button"
            >
                Save
            </v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
import { mask } from "vue-the-mask";

import FamilyMemberIncome from "./FamilyMemberIncome";
import FamilyMemberStudent from "./FamilyMemberStudent";
import SchoolSelector from "../../shared/SchoolSelector";
import SchoolGradeSelector from "../../shared/SchoolGradeSelector";

import {
    GET_HOUSEHOLD_APPLICATION,
    GET_HOUSEHOLD_APPLICATION_DOCUMENTS,
    GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS,
    PATCH_HOUSEHOLD_APPLICATION_MEMBER,
    POST_HOUSEHOLD_APPLICATION_MEMBER,
} from "../../../store/actions.type";
import { CLEAR_ERRORS } from "../../../store/mutations.type";

export default {
    name: "FamilyMember",
    directives: {
        mask
    },
    components: {
        FamilyMemberIncome,
        FamilyMemberStudent,
        SchoolGradeSelector,
        SchoolSelector,
    },
    data: () => ({
        saving: false,
        possibleFields: [
            "first_name",
            "last_name",
            "nick_name",
            "email",
            "phone",
            "birth_date",
            "student.school_id",
            "student.grade_id",
        ]
    }),
    props: {
        editedItem: {
            type: Object,
            required: true,
        },
        memberType: {
            type: Number,
            required: true,
        },
        memberTypeName: {
            type: String,
            required: true,
        },
    },
    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        disabledButton() {
            return this.saving;
        },
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
    },
    methods: {
        clearErrors() {
            this.$store.commit(CLEAR_ERRORS)
        },
        phoneNumber() {
            const x = this.editedItem.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.editedItem.phone = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        save() {
            this.saving = true;
            let application_id = this.$route.params.application_id;
            let household_id = this.$route.params.household_id;
            let applicationMember = this.editedItem;

            applicationMember.application_id = this.application.id;

            if (applicationMember.type === 3 && applicationMember.student.is_preregistration) {
                applicationMember.student.active_military = null;
                applicationMember.student.comments = null;
                applicationMember.student.disabled = null;
                applicationMember.student.esa_enum = applicationMember.student.esa_enum ? applicationMember.student.esa_enum : 1;
                applicationMember.student.foster = null;
                applicationMember.student.foster_amount = null;
                applicationMember.student.previous_scholarship = null;
                applicationMember.student.previous_scholarship_award_1 = null;
                applicationMember.student.previous_scholarship_award_2 = null;
                applicationMember.student.previous_scholarship_award_3 = null;
                applicationMember.student.previous_scholarship_award_4 = null;
                applicationMember.student.previous_scholarship_award_1_sto = null;
                applicationMember.student.previous_scholarship_award_2_sto = null;
                applicationMember.student.previous_scholarship_award_3_sto = null;
                applicationMember.student.previous_scholarship_award_4_sto = null;
                applicationMember.student.transfer_from_charter_or_public_school = null;
                applicationMember.student.transfer_from_different_state = null;
                applicationMember.student.transfer_from_home_school = null;
                applicationMember.student.tuition_amount = null;
            }

            this.$store.dispatch(
                this.editedItem.id
                    ? PATCH_HOUSEHOLD_APPLICATION_MEMBER
                    : POST_HOUSEHOLD_APPLICATION_MEMBER,
                {
                    household_id,
                    application_id,
                    applicationMember,
                }
            )
                .then(() => {
                    this.$store.dispatch(GET_HOUSEHOLD_APPLICATION, {
                        household_id,
                        application_id,
                    })
                        .then(() => {
                            this.updateDocuments();
                            this.updateScholarships();
                            this.saving = false;
                            this.$emit("done");
                        })
                })
                .catch(() => {
                    //Scroll to error
                    this.possibleFields.every((field) => {
                        if (this.errors[field]) {
                            field = field.split('.').pop();
                            const el = document.getElementById(field);
                            if (el) {
                                el.scrollIntoView(false);
                                return false;
                            }
                        }
                        return true;
                    });
                    this.saving = false;
                });

        },
        updateDocuments() {
            const application_id = this.$route.params.application_id;
            const household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_DOCUMENTS, {
                household_id,
                application_id,
            });
        },
        updateScholarships() {
            const application_id = this.$route.params.application_id;
            const household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS, {
                household_id,
                application_id,
            });
        },
    },
};
</script>

<style scoped>
.errors-message {
    background-color: red;
    color: white;
    margin: 0 20px 30px;
    padding: 10px;
    text-align: center;
}

::v-deep .v-textarea textarea {
    padding: 10px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter,
.v-leave-to {
    opacity: 0;
}
</style>
