<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row class="mt-2">
            <v-col class="mt-n4" md="6">
                <v-form :disabled="saving">
                    <household-form :household="household"/>
                    <v-row>
                        <v-col>
                            <v-btn :to="{ name: 'households' }" class="cancel-button" rounded> Cancel</v-btn>
                            <v-btn class="ml-2 save-by-cancel-button" color="primary" rounded @click="submit"> Save
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" style="text-align: right;">
                            <v-btn color="error" rounded @click="delRecord"> Delete</v-btn>
                        </v-col>
                    </v-row>
                    <confirmation-dialog ref="confirm"/>
                </v-form>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" centered>
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import HouseholdForm from "../../../../components/household/HouseholdForm";
import ConfirmationDialog from "../../../../components/shared/ConfirmationDialog";

import {DELETE_HOUSEHOLD, GET_USER_HOUSEHOLD, PATCH_HOUSEHOLD,} from "../../../../store/actions.type";

export default {
    name: "HouseholdEdit",
    components: {
        Loading,
        HouseholdForm,
        ConfirmationDialog,
    },
    data() {
        return {
            loading: true,
            saving: false,
            snackbar: false,
            snackbarMessage: "",
            possibleFields: [
                "city",
                "name",
                "postal",
                "street1",
                "street2",
            ],
        };
    },
    computed: {
        household() {
            return this.$store.getters.currentUserHousehold;
        },
        error() {
            return this.$store.getters.getMessage("households");
        },
        errors() {
            return this.$store.getters.getErrors("households");
        },
    },
    methods: {
        async delRecord() {
            if (
                await this.$refs.confirm.open(
                    "Confirm",
                    "Are you sure you want to delete this household?"
                )
            ) {
                this.destroy();
            }
        },
        destroy() {
            this.saving = true;
            this.$store.dispatch(DELETE_HOUSEHOLD, this.household)
                .then(() => {
                    this.saving = false;
                    this.$router.push({
                        name: "households",
                    });
                })
                .catch(({data}) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        getHousehold() {
            this.loading = true;
            this.$store
                .dispatch(GET_USER_HOUSEHOLD, this.$route.params.household_id)
                .then(() => {
                    this.loading = false;
                });
        },
        submit() {
            this.saving = true;
            this.$store
                .dispatch(PATCH_HOUSEHOLD, this.household)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: "households"});
                })
                .catch(() => {
                    this.saving = false;
                    //Scroll to error
                    console.log("errors: ", this.errors);
                    this.possibleFields.every((field) => {
                        if (this.errors[field]) {
                            field = field.split('.').pop();
                            const el = document.getElementById(field);
                            if (el) {
                                el.scrollIntoView({block: "center"});
                                return false;
                            }
                        }
                        return true;
                    });

                });
        },
    },
    mounted() {
        this.getHousehold();
    },
};
</script>

<style scoped>
</style>
