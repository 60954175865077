<template>
    <div v-else class="mt-3">
        <v-row align="center" class="ml-1">
            <v-col class="pa-0" cols="auto">
                <school-header :page-title="pageTitle"/>
            </v-col>
            <v-spacer/>
            <v-btn
                v-if="!isMobile"
                :href="'/api/schools/' + $route.params.school_id + '/students/year/' + $route.params.school_year_id + '/download'"
                class="mr-3"
                color="primary"
                rounded
            >
                <v-icon left style="color: white;"> mdi-download</v-icon>
                Download This List
            </v-btn>
        </v-row>
        <v-row v-if="isMobile" class="ml-0 mt-n1">
            <v-col>
                <v-btn
                    :href="'/api/schools/' + $route.params.school_id + '/students/year/' + $route.params.school_year_id + '/download'"
                    class="mr-3"
                    color="primary"
                    rounded
                >
                    <v-icon left style="color: white;"> mdi-download</v-icon>
                    Download This List
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :disable-sort="isMobile"
            :footer-props="{ itemsPerPageOptions:[50,100,150,200] }"
            :headers="headers"
            :items="schoolStudentsNonRenewed"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :page.sync="page"
            :search="search"
            :server-items-length="serverItemsLength"
            :single-select="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-2 mt-5"
            item-key="id"
            @pagination="getSchoolStudentsNonRenewed"
            @click:row="edit"
            @update:sort-by="getSchoolStudents"
            @update:sort-desc="getSchoolStudents"
        >
            <template v-slot:top>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            v-model="search"
                            background-color="white"
                            class="mx-4"
                            label="Search on anything"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-n8 ml-2">
                    <v-col md="6">
                        <div>
                            <strong>School Administrator</strong>: Please note we have just moved to a new software
                            platform. Some of the applicant information, especially eligibility and application status
                            may not be accurate. Each applicant must review their current 2023-24 application to confirm
                            accuracy of the data transfer. This can take time and we appreciate your patience while
                            going through this process with your student families.
                            <div class="mt-5">
                                This will not interrupt our award cycles. We do still have access to the previous
                                platform. Should you have any questions regarding your student families eligibility or
                                application status, feel free to contact Karen Blandini at extension 1.
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <span class="text-apsto-teal">
                  No students found
                </span>
            </template>
            <template #item.amount_requested="{ item , index }">
                <div v-if="index === 0">
                    {{ item.amount_requested | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    <div v-if="item.amount_requested">
                        {{ item.amount_requested | formatNumberWithCents }}
                    </div>
                </div>
            </template>
            <template #item.tuition_amount_student="{ item , index }">
                <div v-if="index === 0">
                    {{ item.tuition_amount_student | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    <div v-if="item.tuition_amount_student">
                        {{ item.tuition_amount_student | formatNumberWithCents }}
                    </div>
                </div>
            </template>
            <template #item.application_status_name="{ item }">
                <div :style="item.application_status_name === 'Incomplete' ? 'color: red' : ''">
                    {{ item.application_status_name }}
                </div>
            </template>
            <template #item.birth_date="{ item }">
                <div>{{ displayDate(item.birth_date) }}</div>
            </template>
            <template #item.last_offer="{ item }">
                <div v-if="item.last_offer['amount']">
                    {{ item.last_offer['amount'] | formatCurrency }}
                    on {{ displayDate(item.last_offer['created_at']) }}
                    <div>
                        {{ item.last_offer['tax_credit_abbreviation'] }}
                    </div>
                </div>
            </template>
            <template #item.offer_status="{ item }">
                <div v-if="item.last_offer['amount']">
                    <div v-if="item.last_offer['status'] === 1">
                        Accepted
                    </div>
                    <div v-else-if="item.last_offer['status'] === 2">
                        Declined
                    </div>
                    <div v-else-if="item.last_offer['status'] === 3">
                        Accepted Partial Amount
                    </div>
                    <div v-else>
                        Pending
                    </div>
                </div>
            </template>
            <template v-slot:group.header="{items, isOpen, toggle}">
                <th colspan="10">
                    <v-icon @click="toggle">
                        {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    School Year: {{ items[0].school_year_name }}
                    <span class="text-apsto-teal" style="font-style: italic;">
            Students: {{ serverItemsLength | formatNumber }}
          </span>
                </th>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import SchoolHeader from "../../../../components/school/SchoolHeader";
import Loading from "../../../../components/shared/Loading";
import {
    GET_SCHOOL_STUDENTS,
    GET_SCHOOL_STUDENTS_NON_RENEWED,
    GET_SCHOOL_YEAR_NAME
} from "../../../../store/actions.type";

export default {
    name: "SchoolStudentsInYearNonRenewed",
    components: {
        SchoolHeader,
        Loading
    },
    data() {
        return {
            defaultItem: {
                date_submitted: '',
                loading: false,
                name: '',
                status_name: '',
            },
            headers: [
                {text: 'Name', value: 'student_name'},
                {text: 'Grade', value: 'grade_name'},
                {text: 'Birthdate', value: 'birth_date'},
                {text: 'Eligibility', value: 'qualifies_list'},
                {text: 'Application', value: 'application_status_name'},
                {text: 'Family', value: 'household_name'},
                {text: 'Parent/Guardian', value: 'parent_name'},
                // {text: 'Last Offer', value: 'last_offer'},
                // {text: 'Offer Status', value: 'offer_status'},
            ],
            itemsPerPage: 20,
            loading: true,
            page: 1,
            pageCount: 0,
            search: "",
            serverItemsLength: 0,
            sortBy: 'student_name',
            sortDesc: false,
        }
    },
    computed: {
        pageTitle() {
            return "Students Who Haven't Renewed For School Year " + this.schoolYearName;
        },
        school() {
            return this.$store.getters.currentUserSchool;
        },
        schoolStudentsNonRenewed() {
            return this.$store.getters.currentSchoolStudentsNonRenewed.collection;
        },
        schoolYearName() {
            return this.$store.getters.currentSchoolYearName;
        },
    },
    methods: {
        edit(item) {
            this.$router.push({
                name: 'school-student-edit',
                params: {
                    school_id: this.$route.params.school_id,
                    fiscal_year_id: this.$route.params.school_year_id,
                    application_student_school_id: item.id
                }
            });
        },
        getSchoolStudentsNonRenewed() {
            this.loading = true;
            let page = this.page;
            let itemsPerPage = this.itemsPerPage;
            let sortBy = this.sortBy;
            let sortDesc = this.sortDesc;
            let search = this.search;
            let school_id = this.$route.params.school_id;
            let school_year_id = this.$route.params.school_year_id;
            this.$store.dispatch(GET_SCHOOL_STUDENTS_NON_RENEWED, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search,
                school_id,
                school_year_id
            })
                .then((data) => {
                    this.serverItemsLength = data.totalRecords;
                    this.loading = false;
                });
        },
        getSchoolYearName() {
            let school_year_id = this.$route.params.school_year_id;
            this.$store.dispatch(GET_SCHOOL_YEAR_NAME, school_year_id)
                .then(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getSchoolYearName();
    },
    watch: {
        search() {
            this.page = 1;
            this.getSchoolStudentsNonRenewed();
        }
    }
}
</script>

<style scoped>

::v-deep tbody td {
    height: unset !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

::v-deep th:not([role=columnheader]) {
    background-color: var(--apsto-purple-very-light);
}

::v-deep tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    vertical-align: top;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
