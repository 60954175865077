<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row v-if="household">
            <v-col>
                <household-header :page-title="pageTitle"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="isMobile ? 12 : 6">
                <v-card :class="isMobile ? '' : 'ml-4'">
                    <div class="text-h6-mulish font-weight-black box-with-background">
                        Thank you, you've unsubscribed from reminders to update your application for school year
                        {{ schoolYearName }}.
                    </div>
                    <!--          <v-card-text>-->
                    <!--            <div class="text-h6-mulish" style="color: var(&#45;&#45;apsto-black);">-->
                    <!--              We'd appreciate it if you could answer two quick questions to help our administration efforts:-->
                    <!--            </div>-->
                    <!--            <v-radio-group-->
                    <!--              v-model="form.students"-->
                    <!--              :error="!!errors['students']"-->
                    <!--              :error-messages="errors['students'] ? errors['students'] : null"-->
                    <!--              class="bottom-border font-weight-black"-->
                    <!--            >-->
                    <!--              <template v-slot:label>-->
                    <!--                <div class="pb-2 caption">-->
                    <!--                  Do you plan_to_enroll to enroll one or more students in a private school for school year-->
                    <!--                  {{ schoolYearName }}?-->
                    <!--                </div>-->
                    <!--              </template>-->
                    <!--              <v-radio-->
                    <!--                color="secondary"-->
                    <!--                label="Yes"-->
                    <!--                value="1"-->
                    <!--              />-->
                    <!--              <v-radio-->
                    <!--                color="secondary"-->
                    <!--                label="No"-->
                    <!--                value="0"-->
                    <!--              />-->
                    <!--            </v-radio-group>-->
                    <!--            <v-radio-group-->
                    <!--              v-model="form.plan_to_enroll"-->
                    <!--              :error="!!errors['plan_to_enroll']"-->
                    <!--              :error-messages="errors['plan_to_enroll'] ? errors['plan_to_enroll'] : null"-->
                    <!--              class="bottom-border font-weight-black"-->
                    <!--            >-->
                    <!--              <template v-slot:label>-->
                    <!--                <div class="pb-2 caption">-->
                    <!--                  If you answered Yes, do you plan_to_enroll to apply with any STO for scholarships for school year-->
                    <!--                  {{ schoolYearName }}?-->
                    <!--                </div>-->
                    <!--              </template>-->
                    <!--              <v-radio-->
                    <!--                color="secondary"-->
                    <!--                label="Yes"-->
                    <!--                value="1"-->
                    <!--              />-->
                    <!--              <v-radio-->
                    <!--                color="secondary"-->
                    <!--                label="No"-->
                    <!--                value="0"-->
                    <!--              />-->
                    <!--            </v-radio-group>-->

                    <!--            <div class="mt-5 text-h7-mulish">-->
                    <!--              If you would like to re-subscribe to reminders to update your application for school year-->
                    <!--              {{ schoolYearName }}, please go to the application and click the Resubscribe icon.-->
                    <!--            </div>-->
                    <!--          </v-card-text>-->
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {GET_HOUSEHOLD_APPLICATION, GET_USER_HOUSEHOLD} from "../../../../store/actions.type";
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import Loading from "../../../../components/shared/Loading";
import StatusSelector from "../../../../components/shared/StatusSelector";
import StudentQuestionnaire from "../../../admin/households/household/applications/StudentQuestionnaire";
import SummaryTable from "../../../../components/household/application/SummaryTable";
import FinancialHardshipSection from "../../../admin/households/household/applications/FinancialHardshipSection";
import Scholarships from "../../../../components/household/application/Scholarships";
import DocumentsApproveDecline from "../../../admin/households/household/applications/DocumentsApproveDecline";
import FamilyMemberIncomeTable from "../../../../components/household/application/FamilyMemberIncomeTable.vue";

export default {
    name: "ApplicationUnsubscribeReminderUpdate",
    components: {
        FamilyMemberIncomeTable,
        DocumentsApproveDecline,
        FinancialHardshipSection,
        HouseholdHeader,
        Scholarships,
        StatusSelector,
        StudentQuestionnaire,
        SummaryTable,
        Loading
    },
    data() {
        return {
            form: {
                plan_to_enroll: null,
                students: null
            },
            loading: true,
            snackbar: false,
            snackbarMessage: "",
        }
    },
    computed: {
        household() {
            return this.$store.getters.currentUserHousehold;
        },
        householdApplication() {
            return this.$store.getters.currentApplication;
        },
        pageTitle() {
            return "Unsubscribe From Reminder Email";
        },
        schoolYearName() {
            return this.householdApplication.school_year_name.toString();
        },
        error() {
            return this.$store.getters.getMessage("schoolStudent");
        },
        errors() {
            return this.$store.getters.getErrors("schoolStudent");
        },
    },
    methods: {
        getHouseholdApplication() {
            this.loading = true;
            let household_id = this.$route.params.household_id;
            let application_id = this.$route.params.application_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION, {household_id, application_id})
                .then(() => {
                    this.$store.dispatch(GET_USER_HOUSEHOLD, household_id)
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getHouseholdApplication();
    }
}
</script>

<style scoped>

.bottom-border {
    border-bottom: 1px dotted var(--apsto-border);
}

.caption {
    color: var(--apsto-black) !important;
    font-family: "Mulish", sans-serif !important;
    font-size: 1.1rem !important;
}

</style>
