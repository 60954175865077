//Views
import PromosList from "../views/households/household/promos/PromosList";
import PromosAddEdit from "../views/households/household/promos/PromosAddEdit";
import DisplayPromo from "../views/households/household/promos/DisplayPromo";
import BrochurePreview from "../views/households/household/promos/BrochurePreview";
import PromosCustomResourcesExplainer from "../views/households/household/promos/PromosCustomResourcesExplainer.vue";

export default [{
    path: "/promos/:household_id/:household_slug",
    name: "family-promo-show",
    component: DisplayPromo,
    meta: {
        requiresAuth: false,
        hideForAuth: false,
        hasDashboardMenu: false,
    },
},
    {
        path: "/promos/:household_id/:household_slug/:child_id/:child_slug",
        name: "child-promo-show",
        component: DisplayPromo,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false
        },
    },
    {
        path: "/dashboard/households/:household_id/promos",
        name: "promos-list",
        component: PromosList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/custom-resources-explainer",
        name: "custom-resources-explainer",
        component: PromosCustomResourcesExplainer,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/promos/application/:application_id/create",
        name: "application-promo-create",
        component: PromosAddEdit,
        meta: {
            mode: "create",
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/application/:application_id/promos/:promo_id/create",
        name: "application-promo-edit",
        component: PromosAddEdit,
        meta: {
            mode: "edit",
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/application/:application_id/brochure",
        name: "application-brochure",
        component: BrochurePreview,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/promos/application/:application_id/members/:application_member_id/create",
        name: "application-member-promo-create",
        component: PromosAddEdit,
        meta: {
            mode: "create",
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/promos/:promo_id/application/:application_id/members/:application_member_id/edit",
        name: "application-member-promo-edit",
        component: PromosAddEdit,
        meta: {
            mode: "edit",
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },

];
