<template>
  <v-row>
    <v-col md="6">
      <company-header page-title="Donation Request"/>
      <v-form :disabled="saving">
        <corporate-pledge-form
          :company="company"
          :corporate-pledge="corporatePledge"
          :create="true"
        />
        <v-row>
          <v-col :class="isMobile ? 'mt-5' : 'ml-2'">
            <v-btn
              :block="!!isMobile"
              :to="{ name: 'corporate-pledges-list', params: { company_id: this.$route.params.company_id } }"
              class="cancel-button"
              rounded
            >
              Cancel
            </v-btn>
            <v-btn
              :block="!!isMobile"
              :class="isMobile ? 'mt-5' : 'ml-2'"
              :disabled="isDisabled"
              color="primary"
              rounded
              @click="submit"
            >
              Submit to APSTO
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import CompanyHeader from "../../../components/company/CompanyHeader";
import CorporatePledgeForm from "../../../components/company/corporate-pledges/CorporatePledgeForm";
import {POST_CORPORATE_PLEDGE} from "../../../store/actions.type";

export default {
  name: "CorporatePledgeCreate",
  components: {
    CorporatePledgeForm,
    CompanyHeader,
  },
  data() {
    return {
      corporatePledge: {
        amount: null,
        company_id: this.$route.params.company_id,
        recommendations: {
          recommendation_type: 1,
          comments: null,
          disclose: 0,
          schools: []
        },
        secondary_contact_name: null,
        secondary_contact_email: null,
        secondary_contact_title: null,
        subs_parent_info: null,
        subs_subsidiary: 0,
        tax_credit_type: 0
      },
      possibleFields: [
        "amount",
        "comments",
        "disclose",
        "federal_ein",
        "recommendations",
        "subs_parent_info",
        "subs_subsidiary",
        "tax_credit_type",
      ],
      saving: false
    };
  },
  computed: {
    company() {
      return this.$store.getters.currentUserCompany;
    },
    isDisabled() {
      return this.saving;
    },
    error() {
      return this.$store.getters.getMessage("corporatePledges");
    },
    errors() {
      return this.$store.getters.getErrors("corporatePledges");
    },
  },
  methods: {
    submit() {
      this.saving = true;

      if (this.company.organization_type_enum !== 4) {
        this.corporatePledge.subs_subsidiary = 0;
        this.corporatePledge.subs_parent_info = '';
      }

      let company_id = this.company.id;
      let corporatePledge = this.corporatePledge;
      corporatePledge.fee_percentage = 10;
      corporatePledge.federal_ein = this.company.federal_ein;
      corporatePledge.organization_type_enum = this.company.organization_type_enum;
      this.$store.dispatch(POST_CORPORATE_PLEDGE, {company_id, corporatePledge})
        .then(() => {
          this.saving = false;
          this.$router.push({name: 'corporate-pledges-list', params: {company_id: this.company.id}})
        })
        .catch(() => {
          //Scroll to error
          console.log("errors: ", this.errors);
          this.possibleFields.every((field) => {
            if (this.errors[field]) {
              field = field.split('.').pop();
              const el = document.getElementById(field);
              if (el) {
                el.scrollIntoView({block: "center"});
                return false;
              }
            }
            return true;
          });
          this.saving = false;
        });
    },
  },
}
</script>

