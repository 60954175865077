<template>
    <loading v-if="loading" />
    <div v-else>
        <admin-header
            page-title="Unlinked Student Recommendation:"
            :href="href"
            :href-text="hrefText"
            icon="fa-link"
        />
        <div class="note-box mt-5">
            The information shown on this page is everything we know about this donation. Click the link icon above when
            you're ready to link this donor student recommendation to an actual student.
        </div>
        <v-row center>
            <v-col md="6">
                <v-card
                    class="mt-4 pa-5"
                    style="min-height: 196px;"
                >
                    <div class="text-h6-mulish">
                        Net Donation of {{ netDonationAmount | formatCurrencyWithCents }} on
                        {{ unlinkedStudent.donorDonation.created_at }}
                    </div>
                    <v-row class="mt-0">
                        <v-col>
                            <table class="donation-box">
                                <tbody>
                                    <tr>
                                        <td class="tax-credit-type-name-column">
                                            Original Tax Credit Scholarship:
                                        </td>
                                        <td>
                                            {{ unlinkedStudent.mainDonationAllocation.original | formatCurrencyWithCents }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="tax-credit-type-name-column">
                                            Switcher Tax Credit Scholarship:
                                        </td>
                                        <td>
                                            {{ unlinkedStudent.mainDonationAllocation.switcher | formatCurrencyWithCents }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="tax-credit-type-name-column">
                                            Charitable Contribution:
                                        </td>
                                        <td>
                                            {{ unlinkedStudent.mainDonationAllocation.charitable | formatCurrencyWithCents
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mt-2">
                                {{ unlinkedStudent.donor.name }} ({{ unlinkedStudent.donor.email }}) |
                                {{ unlinkedStudent.donor.address.street1 }}, {{ unlinkedStudent.donor.address.city }}
                                <span v-if="unlinkedStudent.donor.phone">
                                    | {{ unlinkedStudent.donor.phone }}
                                </span>
                                <br>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col md="6">
                <v-card
                    class="mt-4 pa-5"
                    style="min-height: 196px;"
                >
                    <div class="text-h6-mulish">{{ school.name }}</div>
                    <v-row
                        class="mt-0"
                        align="center"
                    >
                        <v-col
                            cols="auto"
                            class="text-h6-mulish"
                        >Primary Contact</v-col>
                        <v-col>
                            <a
                                :href="'/impersonate/take/' + school.primary_user_id"
                                class="text-apsto-teal font-weight-bold"
                            >
                                Impersonate
                            </a>
                        </v-col>
                    </v-row>
                    <div class="mt-2">
                        {{ school.primary_user_name }}, {{ school.primary_user_title }}
                        <br>
                        Email:
                        <a
                            :href="'mailto:' + school.primary_user_email"
                            target="_blank"
                            color="primary"
                        >
                            {{ school.primary_user_email }}
                        </a>
                        <br>
                        Phone: {{ school.contact_phone }}
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="mt-2 pa-5">
                    <AdminSchoolStudentsLinksRecommendations :student-recommendations="studentRecommendations" />
                </v-card>
                <v-card class="mt-7 pa-5">
                    <AdminSchoolStudentsLinksOtherDonations
                        :other-donations="otherDonations"
                        :donor="unlinkedStudent.donor"
                    />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AdminHeader from "../../../../main/AdminHeader";
import ConfirmationDialog from "../../../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../../../components/shared/Loading";
import { GET_ADMIN_SCHOOL, GET_ADMIN_UNLINKED_STUDENT } from "../../../../../../store/actions.type";
import AdminSchoolStudentsLinksRecommendations from "../AdminSchoolStudentsLinksRecommendations";
import AdminSchoolStudentsLinksOtherDonations from "../AdminSchoolStudentsLinksOtherDonations";

export default {
    name: "AdminSchoolUnlinkedStudent",
    components: {
        AdminSchoolStudentsLinksOtherDonations,
        AdminSchoolStudentsLinksRecommendations,
        AdminHeader,
        ConfirmationDialog,
        Loading,
    },
    data() {
        return {
            loading: true,
            saving: false,
        }
    },
    computed: {
        href() {
            let school_id = this.$route.params.school_id;
            let donor_selection_student_id = this.$route.params.donor_selection_student_id;
            return "/dashboard/admin/schools/" + school_id + "/link-student/" + donor_selection_student_id;
        },
        hrefText() {
            return this.unlinkedStudent.donorSelectionStudent.name.toString();
        },
        netDonationAmount() {
            return this.unlinkedStudent.mainDonationAllocation.charitable + this.unlinkedStudent.mainDonationAllocation.original + this.unlinkedStudent.mainDonationAllocation.switcher
        },
        school() {
            return this.$store.getters.currentAdminSchool;
        },
        otherDonations() {
            return this.unlinkedStudent.otherDonations;
        },
        studentRecommendations() {
            return this.unlinkedStudent.students;
        },
        unlinkedStudent() {
            return this.$store.getters.currentAdminUnlinkedStudent;
        },
    },
    methods: {
        getUnlinkedStudent() {
            this.loading = true;
            let school_id = this.$route.params.school_id;
            let donor_selection_student_id = this.$route.params.donor_selection_student_id;
            this.$store.dispatch(GET_ADMIN_SCHOOL, school_id)
                .then(() => {
                    this.$store.dispatch(GET_ADMIN_UNLINKED_STUDENT, { school_id, donor_selection_student_id }).then(() => {
                        this.loading = false;
                    });
                })
                .catch(() => {
                });
        },
    },
    mounted() {
        this.getUnlinkedStudent();
    },
}
</script>

<style scoped>
a {
    color: var(--apsto-teal) !important;
}

.note-box {
    background-color: var(--apsto-teal-light);
    padding: 20px;
}

::v-deep .v-data-table-header {
    background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
    height: 60px !important;
}

::v-deep table.donation-box tbody td {
    height: unset !important;
}

::v-deep tbody td.student-permanent {
    height: unset !important;
    padding-bottom: 4px;
    padding-top: 4px;
}

::v-deep table.donation-box tbody td.tax-credit-type-name-column {
    padding-right: 20px;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}

::v-deep .theme--light.v-data-table.unlinked-students>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
    cursor: pointer;
}

::v-deep .v-icon {
    font-size: 24px !important;
}</style>
