//Views
import ApplicationCreate from "../views/households/household/applications/ApplicationCreate";
import ApplicationEdit from "../views/households/household/applications/ApplicationEdit";
import ApplicationReview from "../views/households/household/applications/ApplicationReview.vue";
import ApplicationList from "../views/households/household/applications/ApplicationList.vue";
import ApplicationUnsubscribeReminderUpdate
    from "../views/households/household/applications/ApplicationUnsubscribeReminderUpdate.vue";

export default [
    {
        path: "/dashboard/households/:household_id/applications/application/create",
        name: "household-application-create",
        component: ApplicationCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/applications/:application_id/edit",
        name: "household-application-edit",
        component: ApplicationEdit,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: false,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/applications/:application_id/show",
        name: "household-application-show",
        component: ApplicationReview,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/applications/:application_id/unsubscribe/:token/reminder/update",
        name: "household-application-unsubscribe-reminder-update",
        component: ApplicationUnsubscribeReminderUpdate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: false,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/applications",
        name: "household-applications-list",
        component: ApplicationList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
];
