<template>
    <div v-if="students.length">
        <v-card
            v-for="(student, index) in studentsNew"
            :key="index"
            class="mt-5"
        >

            <v-card-title>{{ student.name }}
                <span
                    v-if="isIncomplete(student.student)"
                    class="missing-data ml-2"
                >
                    (Incomplete)
                </span>
            </v-card-title>

            <div
                class="text-h7-mulish text-apsto-teal ml-4 mt-n4 pb-4"
                style="cursor: pointer;"
                @click="student.show = !student.show"
            >
                Show student questionnaire
            </div>

            <div v-if="student.show">

                <v-card-text
                    class="mt-n4"
                    style="color: rgba(0, 0, 0, 0.87); font-size: 1.05rem;"
                >

                    <div class="qa-block">
                        School: {{ student.student.school_name }}
                        <div class="my-2">
                            Grade: {{ student.student.grade_name }}
                        </div>
                        Tuition: {{ student.student.tuition_amount | formatCurrency }}
                    </div>

                    <div class="qa-block">
                        (Q) This student has NOT participated with ESA. I will notify APSTO immediately if I sign an ESA
                        contract.
                        <div class="mt-2">
                            <div v-if="student.student.esa_enum === 1">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.esa_enum === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) This student has participated with ESA and I confirm that their ESA contract was CANCELED.
                        <div class="mt-2">
                            <div v-if="student.student.esa_enum === 2">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.esa_enum === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) This student is participating with ESA. Their contract will NOT be canceled for the
                        {{ schoolYearName }} school year.
                        <div class="mt-2">
                            <div v-if="student.student.esa_enum === 3">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.esa_enum === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) Is this student transferring from an Arizona charter or public school?
                        <div class="mt-2">
                            <div v-if="student.student.transfer_from_charter_or_public_school">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.transfer_from_charter_or_public_school === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) Did this student move to Arizona from another state or country and transfer directly to an
                        Arizona
                        private school?
                        <div class="mt-2">
                            <div v-if="student.student.transfer_from_different_state">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.transfer_from_different_state === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) Was this student home schooled immediately prior to transferring directly to an Arizona private
                        school?
                        <div class="mt-2">
                            <div v-if="student.student.transfer_from_home_school">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.transfer_from_home_school === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) Is this student currently or has this student previously been a part of the Arizona Foster care
                        system?
                        <div class="mt-2">
                            <div v-if="student.student.foster">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.foster === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                        <div
                            v-if="student.student.foster"
                            class="mt-4"
                        >
                            (Q) If this is an application for a student who is the legal responsibility of a welfare agency
                            or court
                            and
                            is currently living in your household, enter student's personal use of monthly income.
                            <div class="mt-2">
                                <div v-if="student.student.foster_amount">
                                    (A) {{ student.student.foster_amount | formatCurrency }}
                                </div>
                                <div
                                    v-else
                                    class="missing-data"
                                >Question left unanswered.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) Is this student a dependent of a student of the United States Armed Forces stationed in Arizona
                        pursuant
                        to
                        military orders?
                        <div class="mt-2">
                            <div v-if="student.student.active_military">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.active_military === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) Is this student currently enrolled in private school and do they have a current Individualized
                        Education
                        Plan (IEP) from an Arizona public school or Multidisciplinary Evaluation Team (MET)?
                        <div class="mt-2">
                            <div v-if="student.student.disabled">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.disabled === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>
                    </div>


                    <div class="qa-block">

                        (Q) Has this student previously received a tax credit scholarship from APSTO or another Arizona
                        STO in a previous school year and continued to attend private school in Arizona?
                        <div class="mt-2">
                            <div v-if="student.student.previous_scholarship">
                                (A) Yes
                            </div>
                            <div v-else-if="student.student.previous_scholarship === null">
                                <span class="missing-data">Question left unanswered.</span>
                            </div>
                            <div v-else>
                                (A) No
                            </div>
                        </div>

                        <div
                            v-if="student.student.previous_scholarship"
                            class="mt-4"
                        >
                            Previous Scholarship Awards:
                            <div
                                v-if="student.student.previous_scholarship_award_1"
                                class="mt-2"
                            >
                                * Disabled/Displaced Tax Credit Scholarship
                            </div>
                            <div
                                v-if="student.student.previous_scholarship_award_2"
                                class="mt-2"
                            >
                                * Low-Income Corporate Tax Credit Scholarship
                            </div>
                            <div
                                v-if="student.student.previous_scholarship_award_3"
                                class="mt-2"
                            >
                                * Original Tax Credit Scholarship
                            </div>
                            <div
                                v-if="student.student.previous_scholarship_award_4"
                                class="mt-2"
                            >
                                * Switcher (Overflow/Plus) Tax Credit Scholarship
                            </div>
                            <div class="mt-4">
                                (Q) Which, if any, of these awards were issued by an STO other than APSTO?
                                <div class="mt-2">
                                    <div v-if="student.student.previous_scholarship_award_1_sto">
                                        Displaced / Disabled Tax Credit Scholarship
                                    </div>
                                    <div v-if="student.student.previous_scholarship_award_2_sto">
                                        Low-Income Corporate Tax Credit Scholarship
                                    </div>
                                    <div v-if="student.student.previous_scholarship_award_3_sto">
                                        Original Tax Credit Scholarship
                                    </div>
                                    <div v-if="student.student.previous_scholarship_award_4_sto">
                                        Switcher (Overflow/Plus) Tax Credit Scholarship
                                    </div>
                                    <div v-if="!student.student.previous_scholarship_award_1_sto &&
                                        !student.student.previous_scholarship_award_2_sto &&
                                        !student.student.previous_scholarship_award_3_sto &&
                                        !student.student.previous_scholarship_award_4_sto">
                                        <span>None</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="notIndicatedPreviousScholarships(student.student)"
                            class="missing-data mt-2"
                        >
                            Student has not indicated their previous scholarships.
                        </div>
                    </div>

                    <div class="qa-block">
                        (Q) Student Narrative
                        <div class="mt-2">
                            (A) {{ student.student.comments }}
                        </div>
                    </div>
                </v-card-text>

            </div>

        </v-card>
    </div>
    <div v-else>
        <v-card>
            <v-card-text>
                <span class="missing-data">
                    Please add at least one student.
                </span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "StudentQuestionnaire",
    data() {
        return {
            studentsNew: []
        };
    },
    props: {
        schoolYearName: {
            type: String,
            required: true
        },
        students: {
            type: Array,
            required: true
        }
    },
    methods: {
        isIncomplete(student) {
            return student.previous_scholarship && this.notIndicatedPreviousScholarships(student);
        },
        notIndicatedPreviousScholarships(student) {
            if (!student.previous_scholarship) return;
            return !student.previous_scholarship_award_1 &&
                !student.previous_scholarship_award_2 &&
                !student.previous_scholarship_award_3 &&
                !student.previous_scholarship_award_4;
        }
    },
    mounted() {
        this.studentsNew = this.students.map(obj => ({ ...obj, show: false }))
    }
}
</script>

<style scoped>
.qa-block {
    border-bottom: 1px dotted var(--apsto-teal);
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.qa-block:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.missing-data {
    color: #ff0000;
    font-size: 1.05rem;
}
</style>
