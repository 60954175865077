<template>
    <v-row
        class="flex-column flex-nowrap fill-height"
        no-gutters
    >
        <v-list
            id="messagesContainer"
            ref="messagesContainer"
            class="overflow-y-auto overflow-x-hidden flex-grow-1"
            subheader
        >
            <infinite-loading
                :identifier="messagesIdentifier"
                direction="top"
                @infinite="getMessages"
            >
                <div
                    slot="spinner"
                    class="pt-2"
                >
                    <v-progress-circular indeterminate />
                </div>
                <div
                    slot="no-more"
                    class="px-2 pt-2"
                >
                    <strong v-if="messages.every(m => m.deleted)">No messages have been exchanged. Be the first!</strong>
                    <span v-else>Conversation started</span>
                </div>
                <div
                    slot="no-results"
                    class="px-2 pt-2"
                >
                    <strong>No messages have been exchanged. Be the first!</strong>
                </div>
            </infinite-loading>
            <template v-for="(message, message_index) in messages">
                <message
                    v-if="!message.deleted"
                    v-bind:key="message.id"
                    :conversation="conversation"
                    :isEventChat="false"
                    :linkify="true"
                    :message="message"
                    :showName="false"
                    :showTime="showTime(message_index)"
                    class="my-1 my-sm-2"
                />
            </template>
        </v-list>
        <v-footer
            ref="footer"
            color="white"
        >
            <send-message
                :conversation="conversation"
                class="pb-1"
                @input="handleInputHeight"
                @scroll="handleInputHeight"
            />
        </v-footer>
    </v-row>
</template>

<script>
import { GET_MESSAGES } from "../../../store/actions.type";
import SendMessage from './SendMessage'
import Message from "./Message";

export default {
    name: "Messages",
    components: {
        Message,
        SendMessage,
    },
    props: ['conversation'],
    data() {
        return {
            footerHeight: null
        }
    },
    computed: {
        messages() {
            return this.$store.getters.messages;
        },
        messagesIdentifier() {
            return this.$store.getters.getMessagesIdentifier;
        },
        avatarClass() {
            return null;
        },
    },
    mounted() {
        this.footerHeight = this.$refs.footer.$el.clientHeight;
        this.getMessages();
        setTimeout(() => {
            this.scrollToEnd(true)
        }, 1000);
    },
    methods: {
        handleInputHeight() {
            setTimeout(() => {
                let footer = this.$refs.footer.$el
                let messagesContainer = this.$refs.messagesContainer.$el;
                if (this.footerHeight !== footer.clientHeight) {
                    const diff = footer.clientHeight - this.footerHeight
                    if (messagesContainer.scrollHeight - messagesContainer.offsetHeight >= messagesContainer.scrollTop) {
                        messagesContainer.scrollTop = messagesContainer.scrollTop + diff
                    }

                    this.footerHeight = footer.clientHeight
                }
            }, 100)
        },
        scrollToEnd(force = false) {
            console.log("messagesContainer scrollToEnd()");
            const content = document.getElementById("messagesContainer")
            if (content !== null) {
                console.log("scrollToEnd content is not null");
                console.log("content.scrollTop: " + content.scrollTop);
                console.log("content.scrollHeight: " + content.scrollHeight);
                if (force || content.scrollHeight - content.offsetHeight - 200 <= content.scrollTop) {
                    content.scrollTop = content.scrollHeight;
                }
                console.log("new content.scrollTop: " + content.scrollTop);
            }
        },
        getMessages($state) {
            console.log("getMessages");
            console.log(this.conversation);
            this.$store.dispatch(GET_MESSAGES, this.conversation)
                .then((data) => {
                    if (data.length) {
                        console.log("State Loaded: " + data.length);
                        $state.loaded();
                    } else {
                        console.log("State Complete: " + data.length);
                        $state.complete();
                    }
                    //this.$emit('scroll',true);
                });
        },
        showTime(message_index) {
            if (this.messages.length === 1) {
                return true;
            }
            if (
                message_index === this.messages.length - 1 &&
                (this.messages[message_index - 1].uuid !== this.messages[message_index].uuid ||
                    this.messages[message_index - 1].created_at.substring(0, 16) !==
                    this.messages[message_index].created_at.substring(0, 16))
            ) {
                return true;
            }
            return message_index < this.messages.length - 1 &&
                (this.messages[message_index + 1].uuid !== this.messages[message_index].uuid ||
                    this.messages[message_index + 1].created_at.substring(0, 16) !==
                    this.messages[message_index].created_at.substring(0, 16));

        },
    },
};
</script>

<style scoped>.v-toolbar {
    z-index: 1;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    box-shadow: 0 0 1px;
    -webkit-box-shadow: 0 0 1px;
}</style>
