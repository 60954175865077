<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Salespeople"/>
      </v-col>
      <v-spacer/>
      <v-col
        class="pa-0"
        cols="auto"
      >
        <v-btn
          :to="{ name: 'admin-salesperson-create' }"
          color="primary"
          rounded
        >
          <v-icon left> mdi-plus-circle-outline</v-icon>
          Add New Salesperson
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
      :headers="headers"
      :items="salespeople"
      :items-per-page="-1"
      class="elevation-2 mt-3"
    >
      <template v-slot:item.actionsSalespeople="{ item }">
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              @click="editSalesperson(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Salesperson</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              @click="viewSalespersonAssignments(item)"
              v-on="on"
            >
              mdi-format-list-bulleted
            </v-icon>
          </template>
          <span>Add / Edit Salesperson Assignments</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no salespeople on file.
                </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {GET_SALESPEOPLE} from "../../../store/actions.type";

export default {
  name: "AdminSalespeopleList",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        name: '',
        commission_rate: '',
        created_at: ''
      },
      dialog: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {text: 'SINCE', value: 'created_at'},
        {text: 'Commission Rate', value: 'commission_rate', align: 'end'},
        {text: 'Options', value: 'actionsSalespeople', sortable: false},
      ],
      loading: true,
    }
  },
  computed: {
    salespeople() {
      return this.$store.getters.currentSalespeople;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getSalespeople() {
      this.loading = true;
      this.$store.dispatch(GET_SALESPEOPLE)
        .then(() => {
          this.loading = false;
        });
    },
    editSalesperson(item) {
      this.$router.push(`salespeople/${item.id}/edit`);
    },
    initialize() {
      this.salespeople = [];
    },
    viewSalespersonAssignments(item) {
      this.$router.push(`salespeople/${item.id}/assignments`);
    }
  },
  mounted() {
    this.getSalespeople();
  },
}
</script>

<style scoped>::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}</style>
