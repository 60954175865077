<template>
    <v-app-bar-nav-icon
        class="p-2 mt-1"
        @click="toggleDialog"
    >
        <v-menu
            v-model="isShowingConversationDialog"
            bottom
        >
            <template v-slot:activator="{ on }">
                <v-badge
                    :value="hasUnread"
                    color="red"
                    dot
                    overlap
                >
                    <v-avatar>
                        <v-icon
                            color="secondary"
                            large
                        >mdi-bell-circle</v-icon>
                    </v-avatar>
                </v-badge>
            </template>
            <conversation-container />
        </v-menu>
    </v-app-bar-nav-icon>
</template>

<script>
import {
    TOGGLE_CONVERSATION_DIALOG,
} from "../../../store/mutations.type";

import {
    GET_CONVERSATIONS,
    READ_CONVERSATION
} from "../../../store/actions.type";
import ConversationContainer from "./ConversationContainer";

export default {
    name: "ToolbarIcon",
    data() {
        return {
            loading: false,
        };
    },
    components: {
        ConversationContainer,
    },
    computed: {
        hasUnread() {
            return this.$store.getters.getHasUnreadConversations;
        },
        isShowingConversationDialog: {
            get: function () {
                return this.$store.getters.isShowingConversationDialog;
            },
            set: function (newValue) {
                console.log('Convo Dialog being set');
                console.log(newValue);
            },
        },
        isShowingMessages() {
            return this.$store.getters.isShowingMessages
        },
        currentConversation() {
            return this.$store.getters.getCurrentConversation
        },
        conversations() {
            return this.$store.getters.conversations
        },
    },
    methods: {
        toggleDialog() {
            this.$emit("toggleChat");
            this.$store.commit(TOGGLE_CONVERSATION_DIALOG);
            if (this.isShowingMessages) {
                const conversation = this.conversations.find(c => c.id === this.currentConversation.id)
                if (conversation.has_unread) {
                    bus.$emit('conversationScrollToEnd')
                    this.$store.dispatch(READ_CONVERSATION, conversation)
                }
            }
        },
        getConversations() {
            if (this.loading) {
                console.log('Already loading');
                return;
            }
            console.log('Getting Conversations');
            this.loading = true;
            this.$store.dispatch(GET_CONVERSATIONS).then((data) => {
                if (data.length) {
                    this.loading = false;
                    $state.loaded();
                } else {
                    this.loading = false;
                    $state.complete();
                }
            });
        },
    },
    mounted() {
        setTimeout(() => {
            console.log('Getting Conversations');
            this.getConversations();
        }, 1000);
    },
};
</script>
