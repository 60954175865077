<template>
  <div class="mr-5">
    <v-row
      align="center"
      class="ml-"
    >
      <v-col md="6">
        <admin-header
          :second-line="secondLine"
          page-title="Corporate Pledges Report"
        />
      </v-col>
      <v-spacer/>
      <v-col
        class="pa-0"
        cols="auto"
      >
        <v-btn
          :href="'/api/admin/corporate-pledges/download-transactions/' + $route.params.year_from + '/' + $route.params.year_thru"
          class="mr-3"
          color="primary"
          rounded
        >
          <v-icon left> mdi-download</v-icon>
          Download This List
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
      :headers="headers"
      :items="adminCorporatePledges"
      :loading="loading"
      :search="search"
      class="elevation-2 mt-3"
      fixed-header
      height="100vh"
      item-key="id"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no corporate pledges on file.
                </span>
      </template>
      <template #item.amount="{ item }">
        {{ item.amount | formatCurrency }}
      </template>
      <template #item.donation_amount="{ item }">
        {{ item.donation_amount | formatCurrency }}
      </template>
      <template #item.fee_percentage="{ item }">
        {{ item.fee_percentage / 100 | formatPercent }}
      </template>
      <template #item.tax_credit_name="{ item }">
        <div>
          {{ item.tax_credit_name }}
        </div>
        <div>
          {{ item.fiscal_year }}
        </div>
      </template>
      <template #item.response_info="{ item }">
        <div>
          {{ item.response_info.line1 }}
        </div>
        <div>
          {{ item.response_info.line2 }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {GET_ADMIN_CORPORATE_PLEDGES} from "../../../store/actions.type";

export default {
  name: "AdminCorporatePledgesActivityReport",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        name: '',
        commission_rate: '',
        created_at: ''
      },
      dialog: false,
      headers: [
        {text: 'Year', align: 'start', value: 'tax_year'},
        {text: 'Company Name', value: 'company_name'},
        {text: 'Credit Type', value: 'tax_credit_name'},
        {text: 'REQUESTED', value: 'amount', align: 'end'},
        {text: 'DONATED', value: 'donation_amount', align: 'end'},
        {text: 'Fee', value: 'fee_percentage'},
        {text: 'SUBMITTED', value: 'submitted_to_ador'},
        {text: 'ADOR', value: 'response_info'},
        {text: 'NOTIFIED', value: 'notified_by_apsto'},
        {text: 'Paid', value: 'paid'},
      ],
      loading: true,
      search: ''
    }
  },
  computed: {
    adminCorporatePledges() {
      return this.$store.getters.currentAdminCorporatePledges;
    },
    secondLine() {
      let fromYear = this.$route.params.year_from;
      let thruYear = this.$route.params.year_thru;
      if (fromYear !== thruYear) return 'For Calendar Years ' + fromYear + ' Through ' + thruYear;
      else
        return 'For Calendar Year ' + fromYear;
    }
  },
  methods: {
    getCorporatePledges() {
      let year_from = this.$route.params.year_from;
      let year_thru = this.$route.params.year_thru;
      this.$store.dispatch(GET_ADMIN_CORPORATE_PLEDGES, {year_from, year_thru}).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getCorporatePledges();
  }
}
</script>


<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}</style>
