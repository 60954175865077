//Views
import HouseholdList from "../views/households/household/main/HouseholdList";
import Household from "../views/households/household/main/Household";
import HouseholdCreate from "../views/households/household/main/HouseholdCreate";
import HouseholdEdit from "../views/households/household/main/HouseholdEdit";

export default [
    {
        path: "/dashboard/households",
        name: "households",
        component: HouseholdList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id",
        name: "household",
        component: Household,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/household/create",
        name: "household-create",
        component: HouseholdCreate,
        meta: {
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/edit",
        name: "household-edit",
        component: HouseholdEdit,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
];
