<template>
  <div class="mr-5">
    <v-row class="ml-1 pt-5">
      <v-col>
        <admin-header page-title="Student Awards"/>
        <v-row>
          <v-col cols="auto">
            <v-btn
              :disabled="disableDonationsButton"
              class="mr-1"
              color="primary"
              rounded
              @click="displayDonations"
            >
              Show Donations
            </v-btn>
            <v-btn
              :disabled="disableRecommendationsButton"
              class="mr-1"
              color="primary"
              rounded
              @click="displayRecommendations"
            >
              Show Recommendations
            </v-btn>
            <v-btn
              :disabled="disableStudentAwardsButton"
              color="primary"
              rounded
              @click="displayStudentAwards"
            >
              Show Student Awards
            </v-btn>
          </v-col>
          <v-spacer/>
          <v-col v-if="showDonations || showRecommendations || showStudentAwards" cols="auto">
            <v-btn
              :href="downloadHref"
              color="primary"
              rounded
            >
              <v-icon left style="color: white;"> mdi-download</v-icon>
              <span style="color: white;">Download This List</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ml-1">
      <v-col v-if="showDonations">
        <donations-table/>
      </v-col>
      <v-col v-if="showRecommendations">
        <donor-recommendations-table/>
      </v-col>
      <v-col v-if="showStudentAwards">
        <student-awards-table/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import ConfirmationDialog from "../../../components/shared/ConfirmationDialog";
import DonationsTable from "./DonationsTable";
import DonorRecommendationsTable from "./DonorRecommendationsTable";
import Loading from "../../../components/shared/Loading";
import StudentAwardsTable from "./StudentAwardsTable";

export default {
  name: "AdminFundSectionDonationsShow",
  components: {
    AdminHeader,
    ConfirmationDialog,
    DonationsTable,
    DonorRecommendationsTable,
    Loading,
    StudentAwardsTable,
  },
  data() {
    return {
      disableDonationsButton: true,
      disableRecommendationsButton: false,
      disableStudentAwardsButton: false,
      saving: false,
      showDonations: true,
      showRecommendations: false,
      showStudentAwards: false,
      snackbar: false,
      snackbarMessage: "",

    }
  },
  computed: {
    downloadHref() {
      let href;
      if (this.showDonations) {
        href = '/api/admin/student-awards/' + this.$route.params.year + '/' + this.$route.params.month + '/donations/download';
      } else if (this.showRecommendations) {
        href = '/api/admin/student-awards/' + this.$route.params.year + '/' + this.$route.params.month + '/recommendations/download';
      } else if (this.showStudentAwards) {
        href = '/api/admin/student-awards/' + this.$route.params.year + '/' + this.$route.params.month + '/awards/download';
      }
      return href;
    },
  },
  methods: {
    displayDonations() {
      this.showDonations = true;
      this.showRecommendations = false;
      this.showStudentAwards = false;
      this.disableDonationsButton = true;
      this.disableRecommendationsButton = false;
      this.disableStudentAwardsButton = false;
    },
    displayRecommendations() {
      this.showDonations = false;
      this.showRecommendations = true;
      this.showStudentAwards = false;
      this.disableDonationsButton = false;
      this.disableRecommendationsButton = true;
      this.disableStudentAwardsButton = false;
    },
    displayStudentAwards() {
      this.showDonations = false;
      this.showRecommendations = false;
      this.showStudentAwards = true;
      this.disableDonationsButton = false;
      this.disableRecommendationsButton = false;
      this.disableStudentAwardsButton = true;
    },

    total(item) {
      return parseFloat(item.corporate_disabled_displaced) +
        parseFloat(item.corporate_low_income) +
        parseFloat(item.charitable) +
        parseFloat(item.original_tax_credit) +
        parseFloat(item.switcher)
    }
  },
}
</script>
