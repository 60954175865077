<template>
    <v-select
        id="frequency"
        v-model="localPeriod"
        :background-color="backgroundColor"
        :error="!!errors['frequency']"
        :error-messages="errors['frequency'] ? errors['frequency'] : null"
        :items="frequencies"
        :label="this.label"
        filled
        item-text="name"
        item-value="id"
    >
    </v-select>
</template>

<script>
export default {
    name: "PeriodSelector",
    props: {
        backgroundColor: {
            default: "white",
            type: String,
            required: false
        },
        frequencies: {
            type: Array,
            required: true
        },
        label: {
            default: "Select frequency",
            type: String
        },
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
        localPeriod: {
            get() {
                return this.value
            },
            set(localPeriod) {
                this.$emit('input', localPeriod)
            }
        }
    },
    mounted() {
        this.$emit('input', this.localPeriod);
    },
};
</script>
