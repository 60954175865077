<template>
    <v-row
        justify="center"
        class="mt-10 background-apsto-teal"
    >
        <v-col class="text-center white--text">
            <v-textarea
                :value="text"
                auto-grow
                v-if="editMode"
                dark
                class="text-h4"
            />
            <div
                class="font-weight-black py-5"
                v-resize-text="{ ratio: 2.5, maxFontSize: '35px' }"
                v-else
            >
                {{ text }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Banner",
    props: {
        content: {
            type: Object,
        },
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
        text() {
            return this.content.body.text;
        },
    },
};
</script>

<style scoped>::v-deep textarea {
    font-weight: bold;
    padding-top: 20px;
    text-align: center;
    font-family: var(--font-TITLE) !important;
}

.background-apsto-teal {
    background-color: var(--apsto-teal);
}</style>
