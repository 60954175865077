<template>
    <v-data-table
        :disable-sort="isMobile"
        :headers="headers"
        :hide-default-footer="isMobile"
        :items="localSchools"
        class="mb-3"
        sort-by="name"
        style="border: 1px dotted var(--apsto-teal);"
    >
        <template v-slot:top>

            <v-toolbar
                v-if="isMobile"
                color="secondary"
                flat
            >
                Recommended Schools
            </v-toolbar>

            <div>
                <v-btn
                    class="my-5 mx-4"
                    color="primary"
                    dark
                    rounded
                    @click="createItem"
                >
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Add A School
                </v-btn>
                <v-dialog
                    v-model="addEditDialog"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <corporate-pledge-recommendation-school
                        v-model="temporaryItem"
                        @done="addEditDialog = false"
                        @save="saveItem"
                    />
                </v-dialog>
                <!-- Delete -->
                <v-dialog
                    v-model="deleteDialog"
                    :fullscreen="isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <v-card class="dialog">
                        <v-card-title class="pa-4">
                            <div class="text-h5">Delete School</div>
                        </v-card-title>
                        <v-card-text class="mx-n2">
                            <v-row class="mt-2">
                                <v-col class="text-h6-mulish">
                                    Are you sure you want to delete this school from your list of recommended schools?
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <div
                            v-if="isMobile"
                            class="px-4"
                        >
                            <v-btn
                                block
                                class="cancel-button"
                                rounded
                                @click="deleteDialog = false"
                            >Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5 "
                                color="primary"
                                rounded
                                @click="deleteItem"
                            >Yes, Delete School
                            </v-btn>
                        </div>
                        <v-card-actions
                            v-if="!isMobile"
                            class="mt-n2 pb-6"
                        >
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="deleteDialog = false"
                            >Cancel
                            </v-btn>
                            <v-btn
                                color="primary"
                                rounded
                                @click="deleteItem"
                            >Yes, Delete School
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>
        <!-- Actions -->
        <template v-slot:item.actions="{ item, index }">
            <!-- Edit -->
            <v-tooltip
                bottom
                color="secondary"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        class="mr-2"
                        small
                        @click="editItem(item, index)"
                        v-on="on"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Edit school</span>
            </v-tooltip>

            <!-- Delete -->
            <v-icon
                color="red"
                @click="deleteItemConfirm(index)"
            > mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <span class="text-apsto-teal"> No schools have been added. Click button above to add one or more schools.</span>
        </template>
        <template #item.id="{ item }">
            <div> {{ schoolName(item.id) }}</div>
        </template>
        <template #item.allocation_percentage="{ item }">
            <div> {{ item.allocation_percentage }}%</div>
        </template>
        <template v-slot:body.append>
            <tr>
                <td> Total Percentage</td>
                <td class="text-right"> {{ totalAllocationPercentage() / 100 | formatPercentThreeDecimals }}</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import { GET_SCHOOL_DROPDOWN } from "../../../store/actions.type";
import CorporatePledgeRecommendationSchool from "./CorporatePledgeRecommendationSchool";

export default {
    name: "CorporatePledgeRecommendationSchoolsTable",
    components: {
        CorporatePledgeRecommendationSchool
    },
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        loading: true,
        addEditDialog: false,
        deleteDialog: false,
        headers: [
            { text: "School Name", align: "start", value: "id", },
            { text: "Percentage", value: "allocation_percentage", align: "end" },
            { text: "Edit / Delete", value: "actions", sortable: false, align: "end" }],
        defaultItem: {
            id: null,
            allocation_percentage: null,
        },
        temporaryItem: {
            id: null,
            allocation_percentage: null,
        },
        deleteIndex: {
            type: Number,
            default: null
        },
    }),
    computed: {
        localSchools: {
            get() {
                this.value.map(school => {
                    school.name = this.schoolName(school.id);
                    return school.name
                })
                return this.value;
            },
            set(localSchools) {
                this.$emit('input', localSchools);
            }
        },
        schoolDropdown() {
            return this.$store.getters.currentSchoolDropdown;
        }
    },
    methods: {
        schoolName(id) {
            let school = this.schoolDropdown.find(school => school.id === id);
            return school ? school.name : null;
        },
        createItem() {
            this.temporaryItem = Object.assign({}, this.defaultItem);
            this.addEditDialog = true;
        },
        editItem(item, index) {
            item.name = this.schoolName(item.id);
            this.temporaryItem = Object.assign({}, item);
            this.temporaryItem.index = index;
            this.addEditDialog = true;
        },
        deleteItemConfirm(index) {
            this.deleteIndex = index;
            this.deleteDialog = true;
        },
        deleteItem() {
            this.localSchools.splice(this.deleteIndex, 1);
            this.deleteIndex = null;
            this.deleteDialog = false;
        },
        saveItem() {

            let tempLocalSchools = this.localSchools;

            if (this.temporaryItem.index > -1) {
                tempLocalSchools.splice(this.temporaryItem.index, 1, this.temporaryItem);
            } else {

                tempLocalSchools.push(this.temporaryItem);
            }

            this.localSchools = tempLocalSchools;

            this.temporaryItem = Object.assign({}, this.defaultItem);

            this.addEditDialog = false;
        },
        totalAllocationPercentage() {
            if (!this.localSchools.length) return 0;
            // sum data in give key (property)
            return parseFloat(this.localSchools.reduce((a, b) => parseFloat(a) + (parseFloat(b["allocation_percentage"]) || 0), 0));
        }
    },
    mounted() {
        this.$store.dispatch(GET_SCHOOL_DROPDOWN).then(() => {
            this.loading = false;
        });
    }
};
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}
</style>


