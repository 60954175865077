<template>
  <div>
    <v-app-bar
      :absolute="$vuetify.breakpoint.smAndDown"
      :fixed="$vuetify.breakpoint.mdAndUp"
      :style="announcementsClass"
      app
      class="pt-6 px-6 white"
      clipped-right
      extended
    >
      <v-toolbar-title class="mr-2">
        <router-link to="/">
          <v-img :width="$vuetify.breakpoint.mdAndUp ? 275 : null" src="/images/logo.png"/>
        </router-link>
      </v-toolbar-title>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-spacer/>
        <template v-for="(item, item_index) in items">
          <v-spacer
            v-if="item === 'spacer'"
            :key="item"
          />
          <div
            v-if="item.pages"
            :key="item.title"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :x-large="$vuetify.breakpoint.lgAndUp"
                  active-class="active-website-button"
                  class="text-none"
                  exact
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.title }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(page, index) in item.pages"
                  :key="index"
                  :to="page.to"
                >
                  <v-list-item-title>{{ page.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="editMode"
                  class="success"
                  @click="openAddPageEditDialog(item.id)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold white--text">
                      <v-icon class="white--text">mdi-plus</v-icon>
                      Add New Page
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

          </div>
          <v-btn
            v-if="!!item.to"
            :key="item.title"
            :color="item.color ? item.color : ''"
            :dark="!!item.color"
            :text="!item.color"
            :to="item.to"
            active-class="active-website-button"
            class="text-none font-weight-black"
            exact
            rounded
          >
            {{ item.title }}
          </v-btn>
          <div
            v-if="!!item.method"
            :key="item.title"
          >
            <v-btn
              :color="item.color ? item.color : ''"
              :dark="!!item.color"
              :text="!item.color"
              block
              class="text-none ml-3 font-weight-black"
              exact
              rounded
              @click="handle_function_call(item.method)"
            >
              {{ item.title }}
            </v-btn>
          </div>
          <div
            v-if="!!item.href"
            :key="item.title"
          >
            <v-btn
              :color="item.color ? item.color : ''"
              :dark="!!item.color"
              :href="item.href"
              :text="!item.color"
              block
              class="text-none"
              exact
              x-large
            >
              {{ item.title }}
            </v-btn>
          </div>
        </template>
        <v-menu
          v-if="isAuthenticated"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              class="ml-5 mt-1"
              v-bind="attrs"
              v-on="on"
            >

              <v-img
                v-if="isAuthenticated && user && user.profile_hash"
                :src="imageUrl(user.profile_hash)"
                contain
                rounded
              ></v-img>
              <v-icon
                v-else
                color="secondary"
                large
              >mdi-account-circle
              </v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in profileItems"
              :key="index"
              @click="handle_function_call(item.method)"
            >
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <toolbar-icon v-if="isAuthenticated"/>
      </template>
      <template v-else>
        <v-spacer/>
        <v-app-bar-nav-icon
          x-large
          @click="toggleDrawer"
        />
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="isDrawerOpen"
      app
      clipped
      right
      temporary
    >
      <v-list
        dense
        nav
      >
        <v-list-item-group
          v-if="contents.length > 1"
          class="text-center"
        >
          <template v-for="item in items">
            <v-menu
              v-if="item.pages"
              :key="item.title"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :to="item.to"
                  active-class="active-website-button"
                  block
                  class="text-none"
                  exact
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.title }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(page, index) in item.pages"
                  :key="index"
                  :to="page.to"
                >
                  <v-list-item-title>{{ page.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="!!item.to"
              :key="item.title"
              :color="item.color ? item.color : ''"
              :dark="!!item.color"
              :text="!item.color"
              :to="item.to"
              active-class="active-website-button"
              block
              class="text-none"
              exact
            >
              {{ item.title }}
            </v-btn>
            <div
              v-if="!!item.method"
              :key="item.title"
            >
              <v-btn
                :color="item.color ? item.color : ''"
                :dark="!!item.color"
                :text="!item.color"
                block
                class="text-none mt-2"
                exact
                @click="handle_function_call(item.method)"
              >
                {{ item.title }}
              </v-btn>
            </div>
            <div
              v-if="!!item.href"
              :key="item.title"
            >
              <v-btn
                :color="item.color ? item.color : ''"
                :dark="!!item.color"
                :href="item.href"
                :text="!item.color"
                block
                class="text-none"
                exact
              >
                {{ item.title }}
              </v-btn>
            </div>
          </template>
          <v-btn
            v-if="isAuthenticated"
            block
            class="text-none"
            text
            @click="logout"
          >
            Logout
          </v-btn>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {LOGOUT} from "../../store/actions.type";

import {SET_ADD_PAGE_DIALOG_STATUS, SET_ADD_PAGE_TO_GROUP_ID, SET_EDIT_MODE,} from "../../store/mutations.type";

import ToolbarIcon from "./chat/ToolbarIcon";

export default {
  name: "BarDrawer",
  components: {
    ToolbarIcon,
  },
  data() {
    return {
      isDrawerOpen: false,
      profileItems: [
        {
          title: "Settings",
          method: "profile",
        },
        {
          title: "Log Out",
          method: "logout",
        },
      ],

      guestItems: [
        {
          color: "primary",
          hideOn: '/promos/',
          title: "Donate Now",
          to: "donate-now",
        },
        {
          color: "secondary",
          method: "login",
          title: "Log In",
        },
      ],
      authItems: [],
      adminItems: [],
      customerItems: [],
      afterItems: [],
    };
  },
  computed: {
    contents() {
      return this.$store.getters.currentContents;
    },
    beforeItems() {
      if (!this.contents) return;
      let self = this;
      let items = [];
      // let title = "";
      // let pages = [];

      //Loaded Web Routes
      this.contents.forEach((item) => {
        console.log("Adding Item");
        //Skip if not supposed to be shown in the bar
        if (!item.show_in_bar) return;

        let itemId = item.id;
        let itemTitle = item.name;
        let pages = [];

        item.pages.forEach((page) => {
          let pageTitle = page.name;
          let to = "/" + (page.override_url ? page.override_url : self.convertToSlug(page.name));
          pages.push({
            title: pageTitle,
            to,
          });
        });

        items.push({
          id: itemId,
          title: itemTitle,
          pages,
        });
      });

      items.push("spacer");

      if (this.isAuthenticated && this.user && this.isAdmin) {
        items.push({
          color: "primary",
          title: "ADMIN",
          to: "/dashboard/admin",
        });
      }

      return items;
    },
    items() {
      let self = this;
      let roles = this.$store.getters.currentRoles;
      let tempItems;
      tempItems = [];

      Array.prototype.push.apply(tempItems, this.beforeItems);
      //Role routes first
      roles.forEach((role) => {
        Array.prototype.push.apply(tempItems, this[role + "Items"]);
      });

      //Then Auth or Guest Routes
      if (this.isAuthenticated) {
        Array.prototype.push.apply(tempItems, this.authItems);
      } else {
        this.guestItems.forEach(item => {
          if (item.hideOn) {
            if (!self.$route.matched.some(r => r.path.includes(item.hideOn))) {
              tempItems.push(item);
            }
          } else {
            tempItems.push(item);
          }
        })
      }
      //The After Items
      Array.prototype.push.apply(tempItems, this.afterItems);
      return tempItems;
    },
    user() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    editMode() {
      return this.$store.getters.currentEditMode;
    },
    hasAnnouncements() {
      return this.$store.getters.hasAnnouncements;
    },
    announcementsClass() {
      if (this.hasAnnouncements && this.$route.meta.showAnnouncements) {
        if (this.$vuetify.breakpoint.width < 550) {
          return "margin-top: 94px;";
        } else {
          return "margin-top: 46px;";
        }
      }

      return '';
    }
  },
  methods: {
    login() {
      this.$router.push("/login-register");
    },
    register() {
      this.$router.push("/login-register");
    },
    logout() {
      this.$store.commit(SET_EDIT_MODE, false);
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({name: "home"}));
    },
    profile() {
      this.$router.push("/user-profile-menu");
    },
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    openAddPageEditDialog(group_id) {
      console.log(group_id);
      this.$store.commit(SET_ADD_PAGE_TO_GROUP_ID, group_id);
      this.$store.commit(SET_ADD_PAGE_DIALOG_STATUS, true);
    },
  },
};
</script>

<style scoped>
.v-list {
  border-top: 5px solid var(--apsto-purple);
}

.v-list-item {
  border-bottom: 5px solid transparent;
}

.v-list-item:hover {
  color: var(--apsto-purple);
  cursor: pointer;
  border-bottom: 5px solid var(--apsto-teal);
}

a:hover ::v-deep .v-list-item__title {
  color: var(--apsto-purple);
}
</style>
