<template>
    <div>
        <v-text-field
            id="name"
            v-model.trim="salesperson.name"
            :error="!!errors.name"
            :error-messages="errors.name ? errors.name : null"
            autocomplete="organization"
            background-color="white"
            filled
            label="Salesperson Name"
        />
        <v-text-field
            id="commission_rate"
            v-model.trim="salesperson.commission_rate"
            :error="!!errors.commission_rate"
            :error-messages="errors.commission_rate ? errors.commission_rate : null"
            autocomplete="commission_rate"
            background-color="white"
            filled
            label="Commission Rate (can be overridden for specific companies or donors)"
        />
    </div>
</template>

<script>

export default {
    name: "SalespersonForm",
    data() {
        return {}
    },
    props: {
        salesperson: {
            type: Object,
            required: true,
        }
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {}
}
</script>
