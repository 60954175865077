import { render, staticRenderFns } from "./AdminCorporatePledgeEdit.vue?vue&type=template&id=64cd1082&scoped=true&"
import script from "./AdminCorporatePledgeEdit.vue?vue&type=script&lang=js&"
export * from "./AdminCorporatePledgeEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64cd1082",
  null
  
)

export default component.exports