import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_SCHOOL_APPLICATION,
    EMAIL_SCHOOL_APPLICATION,
    GET_SCHOOL_APPLICATION,
    GET_SCHOOL_APPLICATION_GRADES,
    GET_SCHOOL_APPLICATION_SCHOOL_YEAR,
    GET_SCHOOL_APPLICATIONS,
    PATCH_SCHOOL_APPLICATION,
    PATCH_SCHOOL_APPLICATION_CHECKBOX,
    PATCH_SCHOOL_APPLICATION_GRADE,
    PATCH_SCHOOL_APPLICATION_RADIO,
    PATCH_SCHOOL_APPLICATION_TEXT_FIELD,
    PATCH_SCHOOL_APPLICATION_TEXTAREA,
    POST_SCHOOL_APPLICATION,
    POST_SCHOOL_APPLICATION_DOCUMENT_UPLOAD
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_SCHOOL_APPLICATION,
    SET_SCHOOL_APPLICATION_GRADE,
    SET_SCHOOL_APPLICATION_GRADES,
    SET_SCHOOL_APPLICATION_SCHOOL_YEAR,
    SET_SCHOOL_APPLICATIONS
} from "./mutations.type";
import school from "../views/schools/school/main/School.vue";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        schoolApplications: [],
        schoolApplication: {},
        schoolStatus: [
            {id: 1, value: 'Being assembled by school'},
            {id: 2, value: 'In review by APSTO'},
            {id: 3, value: 'Approved by APSTO'},
        ],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentSchoolApplications(state) {
        return state.schoolApplications;
    },
    currentSchoolApplication(state) {
        return state.schoolApplication;
    },
    currentSchoolApplicationSchoolYear(state) {
        return state.schoolApplicationSchoolYear;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [EMAIL_SCHOOL_APPLICATION](context, {school_id, school_application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/schools/${school_id}/applications/${school_application_id}/email-pdf`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_APPLICATIONS](context, school_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/applications`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATIONS, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_APPLICATION](context, {school_id, school_application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/applications/${school_application_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_APPLICATION_GRADES](context, {school_id, school_application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/applications/${school_application_id}/grades`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION_GRADES, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_APPLICATION_SCHOOL_YEAR](context, school_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${school_id}/applications/application/create`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION_SCHOOL_YEAR, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolApplication",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SCHOOL_APPLICATION](context, schoolApplication) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/schools/${schoolApplication.school_id}/applications`, schoolApplication)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_APPLICATION](context, schoolApplication) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/applications/${schoolApplication.id}`, schoolApplication)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_APPLICATION_GRADE](context, {
        school_id,
        school_application_id,
        grade_id,
        field_name,
        value
    }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/applications/${school_application_id}/grade/${field_name}`, {
                grade_id,
                value
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION_GRADE, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [DELETE_SCHOOL_APPLICATION](context, {school_id, school_application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/schools/${school_id}/applications/${school_application_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [POST_SCHOOL_APPLICATION_DOCUMENT_UPLOAD](context, {
        school_id,
        school_application_id,
        file
    }) {
        const formData = new FormData()
        formData.append('file', file);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/schools/${school_id}/applications/${school_application_id}/files`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_APPLICATION_CHECKBOX](context, {school_id, school_application_id, field_name}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/applications/${school_application_id}/checkbox/${field_name}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_APPLICATION_RADIO](context, {school_id, school_application_id, field_name, field_value}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/applications/${school_application_id}/radio/${field_name}/${field_value}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_APPLICATION_TEXTAREA](context, {
        school_id,
        school_application_id,
        schoolApplication
    }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/applications/${school_application_id}/textarea`, schoolApplication)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_APPLICATION_TEXT_FIELD](context, {
        school_id,
        school_application_id,
        schoolApplication
    }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/applications/${school_application_id}/text-field`, schoolApplication)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schoolApplication');
                    reject(error.response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_SCHOOL_APPLICATIONS](state, data) {
        state.schoolApplications = data;
    },
    [SET_SCHOOL_APPLICATION](state, data) {
        state.schoolApplication = data;
    },
    [SET_SCHOOL_APPLICATION_GRADE](state, data) {
        state.schoolApplication.grade = data;
    },
    [SET_SCHOOL_APPLICATION_GRADES](state, data) {
        state.schoolApplication.grades = data;
    },
    [SET_SCHOOL_APPLICATION_SCHOOL_YEAR](state, data) {
        state.schoolApplicationSchoolYear = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
