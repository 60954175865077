<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            v-if="showCheckPortal"
            align="center"
            class="py-12 portal-check"
            justify="center"
        >
            <v-col md="6 text-center">
                <v-card class="mt-n10 px-0 px-sm-6 py-12">
                    <div class="message-salutation">
                        Hi {{ user.first_name }}
                    </div>
                    <div class="message-welcome">
                        Welcome to your Applicant Portal
                    </div>
                    <div class="message-instructions px-4 px-sm-16 py-6">
                        You can use this portal to create donations on behalf of a company or to turn on payroll
                        withholding for your employees.
                        <div class="mt-5 message-continue">
                            Would you like to continue?
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn
                            block
                            color="secondary"
                            href="/scholarship-program"
                            rounded
                        >
                            No, I didn't mean to select this portal
                        </v-btn>
                        <v-btn
                            block
                            class="mt-5"
                            color="secondary"
                            rounded
                            @click="proceedWithPortal"
                        >
                            Yes, please continue
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <template v-if="!showCheckPortal">
            <v-card
                class="pa-5"
                elevation="2"
            >
                <v-card-title class="main-header">
                    <v-row align="center">
                        <v-col cols="auto">
                            {{ household.name }}
                        </v-col>
                        <v-col v-if="user.has_multiple_households">
                            <div class="text-h7-mulish text--secondary pl-0">
                                (Click Applicants in purple menu bar to see all your households)
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-btn
                    :block="!!isMobile"
                    :to="{ name: 'household-edit', params: { household_id: household.id } }"
                    class="font-weight-bold"
                    color="secondary"
                    rounded
                >
                    <v-icon left> mdi-pencil</v-icon>
                    Applicant Contact Info
                </v-btn>
                <!--            <v-btn-->
                <!--                :block="!!isMobile"-->
                <!--                :class="'font-weight-bold' + (isMobile ? ' mt-5' : ' ml-2')"-->
                <!--                :to="{ name: 'household-create' }"-->
                <!--                color="secondary"-->
                <!--                rounded-->
                <!--            >-->
                <!--                <v-icon left> mdi-plus-circle-outline</v-icon>-->
                <!--                Add {{ isMobile ? 'A' : 'Another' }} Household-->
                <!--            </v-btn>-->
            </v-card>
            <v-row class="mt-2">
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="household.id"
                        :description="scholarshipApplicationDescription"
                        button-name="household-applications-list"
                        button-param="household_id"
                        button-text="Go"
                        icon="mdi-application"
                        title="Scholarship Application"
                    />
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="household.id"
                        button-name="custom-resources-explainer"
                        button-param="household_id"
                        button-text="Go"
                        description="Maximize this program for your child using our customizable donation tools."
                        icon="mdi-school-outline"
                        title="Custom Resources"
                    />
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="household.id"
                        button-name="household-admins-list"
                        button-param="household_id"
                        button-text="Go"
                        description="Invite family members to help manage your application and custom resources."
                        icon="mdi-account-multiple"
                        title="Household Admins"
                    />
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>
import Loading from "../../../../components/shared/Loading";
import {GET_USER_HOUSEHOLD} from "../../../../store/actions.type";
import BlockPanel from "../../../../components/shared/BlockPanel.vue";

export default {
    name: "Household",
    components: {
        BlockPanel,
        Loading,
    },
    data() {
        return {
            calendarYear: null,
            hideCheckPortal: false,
            loading: true,
            showHideIcon: "eye",
            showHideText: "Show",
            showInfo: false,
            showPreregisterExplainer: false,
        };
    },
    computed: {
        household() {
            return this.$store.getters.currentUserHousehold;
        },
        scholarshipApplicationDescription() {
            return "View your student’s current application status or start a new one. Each student in your household with an application on file with APSTO will be listed here. " + this.scholarshipApplicationDescriptionNotification;
        },
        scholarshipApplicationDescriptionNotification() {
            let today = new Date();
            let fiscalYearName = today.getFullYear() + "/" + (today.getFullYear() + 1);
            if (today.getMonth() + 1 < 5 || (today.getMonth() + 1 === 5 && today.getDate() < 16)) {
                return "APSTO'S " + fiscalYearName + " application will be available on May 16th.";
            }
            return "";
        },
        showCheckPortal() {
            return this.user.has_created_content && !this.user.applicant && !this.hideCheckPortal;
        },
        user() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        proceedWithPortal() {
            this.hideCheckPortal = true;
        }
    },
    mounted() {
        this.$store.dispatch(GET_USER_HOUSEHOLD, this.$route.params.household_id)
            .then(() => {
                this.loading = false;
                this.calendarYear = new Date().getFullYear();
            });
    },
};
</script>

<style scoped>
.main-header {
    color: var(--apsto-purple);
    font-size: 24px;
    font-weight: bold;
    padding-left: 0;
    padding-top: 0;
}
</style>
