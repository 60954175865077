<template>
    <loading v-if="loading"/>
    <div v-else>
        <div class="my-n8 mx-n6">
            <v-img src="/images/website/headers/corporate-donor-portal.jpeg">
                <v-row
                    align="center"
                    class="fill-height ma-0 white--text font-roboto-slab font-weight-black"
                    justify="center"
                >
                    <v-col class="text-center">
                        <div
                            v-resize-text="{ ratio: 1.8 }"
                            class="billboard-body"
                        >
                            Donation Request Form
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </div>
        <v-row justify="center">
            <v-col md="6">
                <v-form :disabled="saving">
                    <new-company-with-corporate-pledge-form
                        :company="company"
                        :corporate-pledge="company.corporatePledge"
                        class="mt-9"
                    />
                    <div class="mt-6">
                        <v-btn
                            :to="{ name: 'companies' }"
                            class="cancel-button"
                            rounded
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            class="ml-2 save-by-cancel-button"
                            color="primary"
                            rounded
                            @click="submit"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import {GET_USER_COMPANIES, POST_COMPANY_WITH_CORPORATE_PLEDGE} from "../../../store/actions.type";
import Loading from "../../../components/shared/Loading.vue";
import CompanyForm from "../../../components/company/CompanyForm.vue";
import NewCompanyWithCorporatePledgeForm
    from "../../../components/company/corporate-pledges/new-company-with-pledge/NewCompanyWithCorporatePledgeForm.vue";

export default {
    name: "CorporatePledgeOnlineForm",
    components: {
        NewCompanyWithCorporatePledgeForm,
        CompanyForm,
        Loading
    },
    computed: {
        errors() {
            return this.$store.getters.getErrors("companies");
        },
    },
    data() {
        return {
            company: {
                address: {
                    street1: "",
                    street2: "",
                    city: "",
                    lat: "",
                    lon: "",
                    postal: "",
                    region_id: 3
                },
                corporatePledge: {
                    amount: null,
                    recommendations: {
                        recommendation_type: 1,
                        comments: null,
                        disclose: 0,
                        schools: []
                    },
                    secondary_contact_name: null,
                    secondary_contact_email: null,
                    secondary_contact_title: null,
                    tax_credit_type: 0,
                },
                employee_donations_frequency: 5,
                organization_type_enum: 0,
            },
            possibleFields: [
                "name",
                "phone",
                "organization_type_enum",
                "title",
                "contact_phone",
                "address.city",
                "address.postal",
                "address.street1",
                "corporatePledge.amount",
                "corporatePledge.tax_credit_type",
                "federal_ein",
            ],
            loading: false,
            saving: false,
        };
    },
    methods: {
        submit() {
            this.saving = true;

            if (this.company.organization_type_enum !== 4) {
                this.company.corporatePledge.subs_subsidiary = 0;
                this.company.corporatePledge.subs_parent_info = '';
            }

            let corporatePledge = this.company.corporatePledge;
            corporatePledge.fee_percentage = 10;
            corporatePledge.organization_type_enum = this.company.organization_type_enum;

            this.$store.dispatch(POST_COMPANY_WITH_CORPORATE_PLEDGE, this.company)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: 'companies'});
                })
                .catch(() => {
                    //Scroll to error
                    console.log("errors: ", this.errors);
                    this.possibleFields.every((field) => {
                        if (this.errors[field]) {
                            field = field.split('.').pop();
                            const el = document.getElementById(field);
                            if (el) {
                                el.scrollIntoView({block: "center"});
                                return false;
                            }
                        }
                        return true;
                    });
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch(GET_USER_COMPANIES).then((data) => {
            this.loading = false;
            if (data.length === 1) {
                let company_id = data[0].id;
                this.$router.push({name: 'corporate-pledges-list', params: {company_id: company_id}});
            }
        });
    },
};
</script>
