<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center" class="ml-1">
      <v-col class="pa-0" cols="auto">
        <school-header page-title="Pending Awards"/>
      </v-col>
      <v-spacer/>
      <v-btn
        :href="'/api/schools/' + $route.params.school_id + '/pending-awards/download'"
        class="mr-3"
        color="primary"
        rounded
      >
        <v-icon left style="color: white;"> mdi-download</v-icon>
        Download This List
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="pendingStudentAwards"
      :search="search"
      :single-select="true"
      class="elevation-2 mt-5"
      item-key="id"
      @click:row="edit"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <span class="text-apsto-teal">
          No pending awards found
        </span>
      </template>
      <template #item.amount="{ item , index }">
        {{ item.amount | formatCurrencyWithCents }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loading from "../../../../../components/shared/Loading";
import SchoolHeader from "../../../../../components/school/SchoolHeader";
import {GET_SCHOOL_PENDING_STUDENT_AWARDS} from "../../../../../store/actions.type";

export default {
  name: "SchoolPendingStudentAwards",
  components: {
    Loading,
    SchoolHeader,
  },
  data() {
    return {
      loading: false,
      headers: [
        {text: 'Offer Date', value: 'date'},
        {text: 'Student', value: 'student_name'},
        {text: 'Tax Credit Type', value: 'tax_credit_type'},
        {text: 'Amount', value: 'amount'},
        {text: 'Household', value: 'household_name'},
        {text: '', width: '20%'},
      ],
      search: '',
    }
  },
  computed: {
    pendingStudentAwards() {
      return this.$store.getters.currentApplicationStudentAwardsPending;
    },
  },
  methods: {
    edit(item) {
      this.$router.push({
        name: 'school-student-edit',
        params: {
          school_id: this.$route.params.school_id,
          school_year_id: item.fiscal_year_id,
          application_student_school_id: item.application_student_school_id,
          sender: "school-pending-student-awards",
        },
      });
    },
    getSchoolPendingStudentAwards() {
      this.loading = true;
      const schoolId = this.$route.params.school_id;
      this.$store.dispatch(GET_SCHOOL_PENDING_STUDENT_AWARDS, schoolId)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSchoolPendingStudentAwards();
  },
}
</script>

<style scoped>
.accepted-declined {
  font-weight: 700;
}

.awards-header {
  font-family: Mulish;
  font-size: 21px;
}

.awards-header.hint {
  font-size: 15px;
  margin-bottom: 15px;
}

.sub-header {
  background-color: var(--apsto-purple-very-light);
  font-size: 1.15rem;
  font-weight: bold;
  padding: 30px 20px;
}

::v-deep tbody td {
  height: unset !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

::v-deep th:not([role=columnheader]) {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}
</style>