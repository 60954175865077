<template>
  <v-card class="pb-5 dialog">
    <v-card-title class="pa-4">
      {{ title }}
    </v-card-title>
    <v-card-text class="mx-n2">
      <v-row class="mt-2">
        <v-col class="text-h6-mulish">
          {{ textQuestion }}
        </v-col>
      </v-row>
    </v-card-text>
    <div
      v-if="isMobile"
      class="px-4"
    >
      <v-btn
        block
        class="cancel-button"
        rounded
        @click="$emit('done')"
      >
        Cancel
      </v-btn>
      <v-btn
        block
        class="mt-5"
        color="primary"
        @click="action"
      >
        {{ buttonQuestion }}
      </v-btn>
    </div>
    <v-card-actions v-else>
      <v-btn
        class="cancel-button"
        rounded
        @click="$emit('done')"
      >
        Cancel
      </v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        @click="action"
      >
        {{ buttonQuestion }}
      </v-btn>
    </v-card-actions>
    <div
      v-if="messageResponse"
      class="message-response"
    >
      {{ messageResponse }}
    </div>
  </v-card>
</template>

<script>

export default {
  name: "DialogConfirmation",

  props: {
    action: {
      type: Function,
      required: true,
    },
    buttonQuestion: {
      type: String,
      required: true,
      default: null,
    },
    messageResponse: {
      type: String,
      required: false,
      default: null,
    },
    textQuestion: {
      type: String,
      required: true,
      default: null,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
  },
};
</script>


<style scoped>
.message-response {
  background-color: mediumvioletred;
  color: white;
  padding: 10px;
  margin: 20px 0 -20px 0;
  text-align: center;
}
</style>
