<template>
  <v-card>
    <v-card-title>
            <span class="text-h5-mulish">
                Add New Wallpaper
            </span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          class="mt-2"
          cols="12"
        >
          <v-form>
            <v-card class="pa-4">
              <v-text-field
                id="name"
                v-model.trim="name"
                :error="!!errors.name"
                :error-messages="errors.name ? errors.name : null"
                label="Wallpaper Name"
              />
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="py-4">
      <v-spacer/>
      <v-btn
        class="cancel-button"
        rounded
        @click="cancel"
      > Cancel
      </v-btn>
      <v-btn
        :disabled="saveDisabled"
        color="primary"
        @click="save"
      > Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {POST_SITE_WALLPAPER} from "../../../store/actions.type";
import SiteWallpaperSelector from "../../../components/shared/SiteWallpaperSelector";

export default {
  name: "AdminSiteWallpaperFileUpload",
  components: {SiteWallpaperSelector},
  data: () => ({
    name: null,
    saving: false,
  }),
  computed: {
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
    saveDisabled() {
      return !this.name;
    }
  },
  methods: {
    cancel() {
      this.$emit("done");
    },
    save() {
      this.saving = true;
      let name = this.name;
      this.$store.dispatch(POST_SITE_WALLPAPER, name)
        .then(() => {
          this.saving = false;
          this.name = null;
          this.$emit("done");
        })
    },
  },
};
</script>

<style scoped>.v-application .v-messages__message {
  padding: 12px 24px;
}</style>
