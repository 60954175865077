<template>
    <div>
        <v-data-table
            :disable-sort="isMobile"
            :expanded.sync="expanded"
            :footer-props="{ itemsPerPageOptions: [10] }"
            :headers="headers"
            :hide-default-footer="true"
            :items-per-page="10"
            :items="scholarships"
            :single-expand="singleExpand"
            class="elevation-2"
            group-by="Student"
            item-key="unique_key"
            show-expand
            sort-by="Student"
        >
            <template v-slot:group.header="{ items, isOpen, toggle }">
                <th colspan="4">
                    <v-icon @click="toggle">
                        {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ items[0].Student }}
                </th>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <scholarship-disabled-displaced
                        v-if="item.scholarship_value === 1"
                        :item="item"
                    />
                    <scholarship-low-income
                        v-if="item.scholarship_value === 2"
                        :item="item"
                    />
                    <scholarship-original
                        v-if="item.scholarship_value === 3"
                        :item="item"
                    />
                    <scholarship-switcher
                        v-if="item.scholarship_value === 4"
                        :item="item"
                    />
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ScholarshipDisabledDisplaced from "./scholarships/ScholarshipDisabledDisplaced";
import ScholarshipLowIncome from "./scholarships/ScholarshipLowIncome";
import ScholarshipOriginal from "./scholarships/ScholarshipOriginal";
import ScholarshipSwitcher from "./scholarships/ScholarshipSwitcher";
import { GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS } from "../../../store/actions.type";

export default {
    name: "Scholarships",
    components: {
        ScholarshipDisabledDisplaced,
        ScholarshipLowIncome,
        ScholarshipOriginal,
        ScholarshipSwitcher,
    },
    data() {
        return {
            expanded: [],
            loading: true,
            singleExpand: false,
            headers: [
                { text: "Scholarship", value: "scholarship_label", align: "left" },
                { text: "Qualifies", value: "qualifies", align: "left" },
                { text: "Subject To", value: "subject_to", align: "left" },
                { text: "", value: "data-table-expand" },
            ],
        };
    },
    computed: {
        scholarships() {
            return this.$store.getters.currentApplicationScholarships;
        },
    },
    methods: {
        getScholarships() {
            const application_id = this.$route.params.application_id;
            const household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS, {
                household_id,
                application_id,
            });
        },
    },
    mounted() {
        this.getScholarships();
        this.loading = false;
    },
};
</script>

<style scoped>
::v-deep .theme--light.v-icon {
    color: var(--apsto-teal);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    font-size: 0.9rem;
}

.v-data-table>.v-data-table__wrapper tbody tr.v-data-table__expanded__content>td {
    background-color: var(--apsto-teal-very-light);
    padding: 20px;
}
</style>
