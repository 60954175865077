<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    persistent
  >
    <v-card class="dialog">
      <v-card-title class="pa-4">
        <div class="text-h5">
          {{ title }}
        </div>
      </v-card-title>

      <v-card-text
        v-show="!!message"
        class="mx-n2"
      >
        <v-row class="mt-2">
          <v-col class="text-h6-mulish">
            {{ message }}
          </v-col>
        </v-row>
      </v-card-text>

      <div
        v-if="isMobile"
        class="px-4"
      >
        <v-btn
          v-if="!options.noconfirm"
          block
          class="cancel-button"
          rounded
          @click.native="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          block
          class="mt-5"
          color="primary"
          @click.native="agree"
        >
          OK
        </v-btn>
      </div>
      <v-card-actions
        v-else
        class="pb-6"
      >
        <v-spacer/>
        <v-btn
          v-if="!options.noconfirm"
          class="cancel-button"
          rounded
          text
          @click.native="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          class="body-2 font-weight-bold"
          color="primary"
          @click.native="agree"
        >
          OK
        </v-btn>
      </v-card-actions>
      <div
        v-if="messageResponse"
        class="message-response"
      >
        {{ messageResponse }}
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      message_response: null,
      title: null,
      options: {
        color: "grey lighten-3",
        width: 600,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  computed: {
    messageResponse() {
      return this.message_response !== 200 ? this.message_response : null
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>.message-response {
  color: red;
  padding-bottom: 20px;
  text-align: center;
}</style>
