<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row class="ml-1 mr-0 mt-5">
      <v-col class="pa-0">
        <admin-header page-title="Create Student Award Offers"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-title class="text-h6-mulish">
            Step 1: Select Period for Student Award Offers
          </v-card-title>
          <v-select
            v-model="fiscal_year_id"
            :error="!!errors.fiscal_year_id"
            :error-messages="errors.fiscal_year_id ? errors.fiscal_year_id : null"
            :items="fiscalYears"
            class="px-4"
            filled
            item-text="name"
            item-value="id"
            label="Fiscal Year"
          />
          <v-select
            v-model="month"
            :error="!!errors.month"
            :error-messages="errors.month ? errors.month : null"
            :items="months"
            class="px-4"
            filled
            item-text="name"
            item-value="id"
            label="Award Month"
          />
          <v-alert
            v-if="failureMessage"
            class="mx-4"
            type="error"
          >
            {{ failureMessage }}
          </v-alert>
          <v-card-actions class="px-4 pb-4 mt-n2">
            <v-btn
              :disabled="!fiscal_year_id || !month"
              color="primary"
              rounded
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
          <div
            v-if="successMessage"
            class="text-h7-mulish pa-4"
          >
            Use the <a
            href="/dashboard/admin/schools"
            style="color: var(--apsto-teal);"
          >Schools</a> page to create new
            award offers then come back here
            when you're ready to release offers to schools.
          </div>
        </v-card>
        <v-card class="mt-5">
          <v-card-title class="text-h6-mulish">
            Step 2: Release Student Award Offers
          </v-card-title>
          <admin-schools-release-award-offers/>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-2">
        <div>
          <v-btn
            class="cancel-button"
            rounded
            to="/dashboard/admin"
          >
            Cancel
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader.vue";
import AdminSchoolsReleaseAwardOffers from "./AdminSchoolsReleaseAwardOffers.vue";
import {
  GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD,
  GET_ADMIN_SCHOOL_STUDENTS,
  GET_FISCAL_YEARS, GET_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD,
  POST_SCHOOL_STUDENT_AWARDS_PERIOD
} from "../../../store/actions.type";
import Loading from "../../../components/shared/Loading.vue";

export default {
  name: "AdminCreateStudentAwardOffers",
  components: {
    Loading,
    AdminHeader,
    AdminSchoolsReleaseAwardOffers,
  },
  data() {
    return {
      fiscal_year_id: null,
      loading: false,
      month: null,
      months: [
        {id: 1, name: 'January'},
        {id: 2, name: 'February'},
        {id: 3, name: 'March'},
        {id: 4, name: 'April'},
        {id: 5, name: 'May'},
        {id: 6, name: 'June'},
        {id: 7, name: 'July'},
        {id: 8, name: 'August'},
        {id: 9, name: 'September'},
        {id: 10, name: 'October'},
        {id: 11, name: 'November'},
        {id: 12, name: 'December'},
      ],
      failureMessage: null,
      successMessage: false,
    };
  },
  computed: {
    fiscalYears() {
      return this.$store.getters.currentFiscalYears
    },
    error() {
      return this.$store.getters.getMessage("adminSchools");
    },
    errors() {
      return this.$store.getters.getErrors("adminSchools");
    },
  },
  methods: {
    getFiscalYears() {
      this.loading = true;
      this.$store.dispatch(GET_FISCAL_YEARS).then(() => {
        this.loading = false;
      })
        .catch(() => {
          this.loading = false;
        });
    },
    getSchoolStudentAwardsLatestPeriod() {
      this.loading = true;
      this.$store.dispatch(GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD)
        .then((data) => {
          this.loading = false;
          this.fiscal_year_id = data.fiscal_year_id;
          this.month = data.month;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    save() {
      this.$store.dispatch(POST_SCHOOL_STUDENT_AWARDS_PERIOD, {
        fiscal_year_id: this.fiscal_year_id,
        month: this.month,
      }).then(() => {
        this.successMessage = true;
      })
        .catch((response) => {
          this.failureMessage = response.data.message;
        });
    }
  },
  mounted() {
    this.getFiscalYears();
    this.getSchoolStudentAwardsLatestPeriod();
  },
}
</script>

<style scoped></style>
