<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col
                lg="6"
                md="8"
            >
                <company-header page-title="Donation Request"/>

                <div v-if="!corporatePledge.can_edit">
                    <v-alert
                        :value="true"
                        class="font-weight-black"
                        type="error"
                    >
                        This Donation Request is locked while being reviewed by APSTO. We'll contact you shortly with
                        next
                        steps.
                    </v-alert>
                    <corporate-pledge-review-panel
                        :company="company"
                        :corporate-pledge="corporatePledge"
                        :disabled="true"
                        called-from=""
                        class="my-5"
                    />
                </div>

                <v-form
                    v-if="corporatePledge.can_edit"
                    :disabled="saving"
                >
                    <corporate-pledge-form
                        :company="company"
                        :corporate-pledge="corporatePledge"
                    />
                    <v-row v-if="isMobile">
                        <v-col class="mx-2">
                            <v-btn
                                :to="{ name: 'corporate-pledges-list', params: { company_id: this.$route.params.company_id } }"
                                block
                                class="cancel-button"
                                rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="save-by-cancel-button mt-5"
                                color="primary"
                                @click="submit"
                            >
                                Submit to APSTO
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="error"
                                @click="delRecord"
                            >
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col>
                            <v-btn
                                :to="{ name: 'corporate-pledges-list', params: { company_id: this.$route.params.company_id } }"
                                class="cancel-button"
                                rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                :disabled="isDisabled"
                                class="save-by-cancel-button ml-2"
                                color="primary"
                                rounded
                                @click="submit"
                            >
                                Submit to APSTO
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="auto"
                            style="text-align: right;"
                        >
                            <v-btn
                                color="error"
                                rounded
                                @click="delRecord"
                            >
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                    <confirmation-dialog ref="confirm"/>
                </v-form>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import CompanyHeader from "../../../components/company/CompanyHeader";
import CorporatePledgeForm from "../../../components/company/corporate-pledges/CorporatePledgeForm";
import ConfirmationDialog from "../../../components/shared/ConfirmationDialog";

import {DELETE_CORPORATE_PLEDGE, GET_CORPORATE_PLEDGE, PATCH_CORPORATE_PLEDGE} from "../../../store/actions.type";
import CorporatePledgeReviewPanel from "../../../components/company/corporate-pledges/CorporatePledgeReviewPanel.vue";

export default {
    name: "CorporatePledgeEdit",
    components: {
        CorporatePledgeReviewPanel,
        Loading,
        CompanyHeader,
        ConfirmationDialog,
        CorporatePledgeForm,
    },
    data() {
        return {
            loading: true,
            saving: false,
            snackbar: false,
            snackbarMessage: "",
            possibleFields: [
                "amount",
                "comments",
                "federal_ein",
                "recommendations",
                "subs_parent_info",
                "subs_subsidiary",
                "tax_credit_type",
            ],
        };
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        },
        corporatePledge() {
            return this.$store.getters.currentCorporatePledge;
        },
        isDisabled() {
            return this.saving;
        },
        error() {
            return this.$store.getters.getMessage("corporatePledges");
        },
        errors() {
            return this.$store.getters.getErrors("corporatePledges");
        },
    },
    methods: {
        async delRecord() {
            if (
                await this.$refs.confirm.open(
                    "Delete Donation Request",
                    "Are you sure you want to delete this donation request?"
                )
            ) {
                this.destroy();
            }
        },
        destroy() {
            this.saving = true;
            let company_id = this.$route.params.company_id;
            let corporate_pledge_id = this.$route.params.corporate_pledge_id;
            this.$store.dispatch(DELETE_CORPORATE_PLEDGE, {company_id, corporate_pledge_id})
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: 'corporate-pledges-list', params: {company_id: this.company.id}})
                })
                .catch(({data}) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });

        },
        getCorporatePledge() {
            this.loading = true;
            let company_id = this.$route.params.company_id;
            let corporate_pledge_id = this.$route.params.corporate_pledge_id;
            this.$store.dispatch(GET_CORPORATE_PLEDGE, {company_id, corporate_pledge_id})
                .then(() => {
                    this.loading = false;
                });
        },
        submit() {
            this.saving = true;

            /**
             * Reset fields if required but prevent reset if ADOR has approved donation
             */

            if (this.corporatePledge.response_from_ador !== 1) {

                if (this.company.organization_type_enum !== 2 && this.company.organization_type_enum !== 3) {
                    this.corporatePledge.subs_subsidiary = 0;
                    this.corporatePledge.subs_parent_info = '';
                }

            }

            /**
             * Dispatch patch event
             * @type {string}
             */

            let company_id = this.$route.params.company_id;
            let corporate_pledge_id = this.$route.params.corporate_pledge_id;
            let corporatePledge = this.corporatePledge;
            corporatePledge.federal_ein = this.company.federal_ein;
            corporatePledge.organization_type_enum = this.company.organization_type_enum;
            this.$store.dispatch(PATCH_CORPORATE_PLEDGE, {company_id, corporate_pledge_id, corporatePledge})
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: 'corporate-pledges-list', params: {company_id}})
                })
                .catch(() => {
                    //Scroll to error
                    this.possibleFields.every((field) => {
                        if (this.errors[field]) {
                            field = field.split('.').pop();
                            const el = document.getElementById(field);
                            if (el) {
                                el.scrollIntoView({block: "center"});
                                return false;
                            }
                        }
                        return true;
                    });
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.loading = false;
        this.getCorporatePledge();
    },

}
</script>

<style scoped></style>
