<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col class="mx-2 text-h5-mulish"> Edit Company</v-col>
        </v-row>
        <v-row class="mt-n3 mt-sm-0">
            <v-col
                class="mx-2"
                md="6"
            >
                <v-form :disabled="saving">
                    <company-form :company="company"/>
                    <v-row
                        v-if="isMobile"
                        class="mt-n6"
                    >
                        <v-col>
                            <v-btn
                                :to="{ name: 'companies' }"
                                block
                                class="cancel-button"
                                rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="save-by-cancel-button mt-5"
                                color="primary"
                                rounded
                                @click="submit"
                            >
                                Save
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="error"
                                rounded
                                @click="delRecord"
                            >
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row
                        v-else
                        class="mt-n6"
                    >
                        <v-col>
                            <v-btn
                                :to="{ name: 'companies' }"
                                class="cancel-button"
                                rounded
                            > Cancel
                            </v-btn>
                            <v-btn
                                class="save-by-cancel-button ml-2"
                                color="primary"
                                rounded
                                @click="submit"
                            >
                                Save
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="auto"
                            style="text-align: right;"
                        >
                            <v-btn
                                color="error"
                                rounded
                                @click="delRecord"
                            >
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                    <confirmation-dialog ref="confirm"/>
                </v-form>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import CompanyForm from "../../../components/company/CompanyForm";
import ConfirmationDialog from "../../../components/shared/ConfirmationDialog";

import {DELETE_COMPANY, GET_USER_COMPANY, PATCH_COMPANY,} from "../../../store/actions.type";

export default {
    name: "CompanyEdit",
    components: {
        Loading,
        CompanyForm,
        ConfirmationDialog,
    },
    data() {
        return {
            loading: true,
            saving: false,
            snackbar: false,
            snackbarMessage: "",
        };
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        },
    },
    methods: {
        async delRecord() {
            if (
                await this.$refs.confirm.open(
                    "Confirm",
                    "Are you sure you want to delete this company?"
                )
            ) {
                this.destroy();
            }
        },
        destroy() {
            this.saving = true;
            this.$store.dispatch(DELETE_COMPANY, this.company)
                .then(() => {
                    this.saving = false;
                    this.$router.push({
                        name: "companies",
                    });
                })
                .catch(({data}) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        getCompany() {
            this.loading = true;
            this.$store
                .dispatch(GET_USER_COMPANY, this.$route.params.company_id)
                .then(() => {
                    this.loading = false;
                });
        },
        submit() {
            this.saving = true;
            this.$store.dispatch(PATCH_COMPANY, this.company)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: "companies"});
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.getCompany();
    },
};
</script>

<style scoped></style>
