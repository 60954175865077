<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            align="center"
            class="ml-1"
        >
            <v-col class="pa-0">
                <school-header page-title="Students"/>
            </v-col>
        </v-row>
        <v-data-table
            :disable-sort="isMobile"
            :footer-props="{ itemsPerPageOptions:[10,20,30] }"
            :headers="headers"
            :hide-default-footer="isMobile"
            :items="schoolStudentYears"
            :loading="loading"
            :single-select="true"
            class="elevation-2 mx-4 mt-3"
            item-key="id"
            @click:row="edit"
        >
            <template v-slot:no-data>
      <span class="text-apsto-teal">
        No students found
      </span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {GET_SCHOOL_STUDENT_YEARS} from "../../../../store/actions.type";
import SchoolHeader from "../../../../components/school/SchoolHeader.vue";
import Loading from "../../../../components/shared/Loading.vue";

export default {
    name: "SchoolStudentYearsTable",
    components: {Loading, SchoolHeader},
    data() {
        return {
            loading: false,
            headers: [
                {text: 'School Year', value: 'school_year_name'},
                {text: 'Students In Year', value: 'students_in_year', align: 'center'},
                {text: '', width: '70%'}
            ]
        }
    },
    computed: {
        schoolStudentYears() {
            return this.$store.getters.currentSchoolStudentYears;
        },
    },
    methods: {
        edit(item) {
            this.$router.push({
                name: 'school-year-students',
                params: {school_id: this.$route.params.school_id, school_year_id: item.id}
            });
        },
        getSchoolStudentYears() {
            this.loading = true;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(GET_SCHOOL_STUDENT_YEARS, school_id)
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getSchoolStudentYears();
    },
}
</script>

<style scoped>

::v-deep tbody td {
    height: unset !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

::v-deep th:not([role=columnheader]) {
    background-color: var(--apsto-purple-very-light);
}

::v-deep tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    vertical-align: top;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover {
    background-color: var(--apsto-teal-very-light) !important;
}
</style>
