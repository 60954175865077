<script>

export default {
    name: "FormRequiresWideScreen"
}
</script>

<template>
    <div class="box-with-background">
        This form will be available in a cellphone version by July, 2023. It works now on a tablet, desktop, or laptop.
    </div>
</template>

<style scoped></style>
