<template>
    <div class="pl-5">
        <v-row>
            <v-col
                class="ml-3 mt-4"
                lg="6"
                md="8"
            >
                <menu-item
                    class="menu-item"
                    enumerator="1"
                    title="Name and Address"
                    to="user-profile-name"
                />
                <menu-item
                    class="menu-item"
                    enumerator="2"
                    title="Change Login Email"
                    to="user-profile-email"
                />
                <!--                <menu-item-->
                <!--                    class="menu-item"-->
                <!--                    enumerator="2"-->
                <!--                    title="Upload Picture"-->
                <!--                    to="user-profile-picture"-->
                <!--                />-->
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MenuItem from "../../components/shared/MenuItem";

export default {
    name: "UserProfileMenu",
    components: { MenuItem },
    data() {
        return {
            localUser: null
        };
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },
}
</script>

<style scoped>
.menu-item {
    margin-bottom: 20px;
}
</style>
