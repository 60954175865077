<template>
  <div>
    <div class="text-h6-mulish">Partner Agreements</div>
    <v-data-table
      :headers="headers"
      :items="schoolApplications"
      :single-select="true"
      class="mt-3"
      item-key="id"
    >
      <template v-slot:no-data>
          <span class="text-apsto-teal">
            There are no applications on file for this school.
          </span>
      </template>
      <template v-slot:item.actionsApplication="{ item }">
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="secondary"
              v-bind="attrs"
              @click="reviewApplication(item)"
              v-on="on"
            >
              mdi-format-list-bulleted
            </v-icon>
          </template>
          <span>Review Application</span>
        </v-tooltip>
        <v-icon
          :disabled="disableDeleteLink(item)"
          color="red"
          @click="deleteApplication(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" :fullscreen="!!isMobile" :max-width="dialogMaxWidth">
      <v-card>
        <v-card-title class="text-h5-mulish"> Confirm</v-card-title>
        <v-card-text class="pa-4 pl-6 black--text" style="font-size: 1.1rem">
          Are you sure you want to delete this item?
        </v-card-text>
        <v-card-actions class="pt-3 pb-4">
          <v-spacer/>
          <v-btn
            class="cancel-button" rounded
            @click="closeDeleteApplication"
          >
            Cancel
          </v-btn>
          <v-btn
            class="body-2 font-weight-bold"
            color="primary"
            @click="deleteApplicationConfirm"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {DELETE_SCHOOL_APPLICATION_ADMIN, GET_ADMIN_SCHOOL_APPLICATIONS} from "../../../../../store/actions.type";

export default {
  name: "AdminPartnerAgreements",
  data() {
    return {
      dialogDelete: false,
      headers: [
        {text: 'Year', value: 'school_year_name'},
        {text: 'Status', value: 'status_name'},
        {text: 'Options', align: 'center', value: 'actionsApplication', sortable: false},
      ],
      loading: true,
      snackbar: false,
      snackbarMessage: "",

    }
  },
  computed: {
    schoolApplications() {
      return this.$store.getters.currentAdminSchoolApplications;
    },
  },
  methods: {
    closeDeleteApplication() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    disableDeleteLink(item) {
      return item.show_delete_link ? false : true;
    },
    deleteApplication(item) {
      this.editedIndex = this.schoolApplications.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteApplicationConfirm() {
      this.destroyApplication();
      this.schoolApplications.splice(this.editedIndex, 1);
      this.closeDeleteApplication();
    },
    destroyApplication() {
      this.saving = true;
      let school_application_id = this.editedItem.id;
      this.$store
        .dispatch(DELETE_SCHOOL_APPLICATION_ADMIN, school_application_id)
        .then(() => {
          this.saving = false;
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;

          this.snackbar = true;
        });
    },
    getSchoolApplications() {
      this.loading = true;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(GET_ADMIN_SCHOOL_APPLICATIONS, school_id)
        .then(() => {
          this.loading = false;
        });
    },
    reviewApplication(item) {
      this.$router.push(`applications/${item.id}/review`);
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDeleteApplication();
    },
  },
  mounted() {
    this.getSchoolApplications();
  }
}
</script>

<style scoped>

</style>