<template>
    <v-row>
        <v-col>
            <v-row align="center">
                <v-col>
                    <v-divider class="quote-divider" />
                </v-col>
                <v-col cols="auto">
                    <v-icon
                        color="primary"
                        class="super-xl"
                    >mdi-format-quote-open</v-icon>
                </v-col>
                <v-col>
                    <v-divider class="quote-divider" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="main text-center">
                        Children are the world's most valuable resource and its best hope
                        for the future
                    </div>
                    <div class="small text-center"><i>~ JFK</i></div>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-divider class="quote-divider" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "JFKQuote",
    props: {
        content: {
            type: Object,
        },
    },
};
</script>

<style scoped>
.main {
    color: var(--apsto-purple);
    font-size: 36px;
    font-weight: 900;
}

.quote-divider {
    border-color: var(--apsto-purple);
    border-width: 2px;
}

.small {
    color: var(--apsto-purple);
    font-size: 21px;
    font-weight: 500;
}

.super-xl {
    font-size: 100px
}</style>
