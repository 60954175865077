import { render, staticRenderFns } from "./AdminSiteWallpapersList.vue?vue&type=template&id=65c55100&scoped=true&"
import script from "./AdminSiteWallpapersList.vue?vue&type=script&lang=js&"
export * from "./AdminSiteWallpapersList.vue?vue&type=script&lang=js&"
import style0 from "./AdminSiteWallpapersList.vue?vue&type=style&index=0&id=65c55100&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c55100",
  null
  
)

export default component.exports