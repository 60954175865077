<template>
    <div>
        <v-row>
            <v-col
                class="mx-4"
                md="6"
            >
                You're currently logged in as <span class="text-apsto-teal"> {{ localUser.email }}</span>.
                <v-form
                    :disabled="saving"
                    class="mt-4"
                >
                    <v-text-field
                        id="email"
                        v-model.trim="email"
                        :error="!!errors['email']"
                        :error-messages="errors['email'] ? errors['email'] : null"
                        autocomplete="email"
                        background-color="white"
                        filled
                        label="New Login Email"
                    />
                    <v-btn
                        :to="{ name: 'user-profile-menu' }"
                        class="cancel-button"
                        rounded
                    > Cancel
                    </v-btn>
                    <v-btn
                        class="ml-2"
                        color="primary"
                        rounded
                        @click="submit"
                    > Send Message To New Email
                    </v-btn>
                </v-form>
            </v-col>
            <v-snackbar
                v-model="snackbar"
                :timeout="snackbarTimeout"
                bottom
            >
                {{ snackbarMessage }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-row>
    </div>
</template>

<script>

import { SEND_CHANGE_USERNAME_EMAIL } from "../../store/actions.type";

export default {
    name: "UserProfileEmail",
    data() {
        return {
            saving: false,
            snackbar: false,
            snackbarMessage: "",
            snackbarTimeout: 0,
            multiLine: true,
            email: null
        };
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        error() {
            return this.$store.getters.getMessage("users");
        },
        errors() {
            return this.$store.getters.getErrors("users");
        },
    },
    methods: {
        submit() {
            let person = {
                email: this.email
            }
            this.saving = true;
            this.$store.dispatch(SEND_CHANGE_USERNAME_EMAIL, person)
                .then(() => {
                    this.saving = false;
                    this.snackbar = true;
                    this.snackbarMessage = "Please click the button in the verification email we just sent to " + this.email + ".";
                })
                .catch((response) => {
                    this.saving = false;
                    if (response['status'] !== 422) {
                        this.snackbar = true;
                        this.snackbarMessage = response.data.message;
                    }
                });
        },
    },
    created() {
        this.localUser = { ...this.currentUser };
    },
}
</script>

<style scoped>
::v-deep .v-snack__wrapper.theme--dark {
    background-color: red;
    color: white;
    font-weight: 900 !important;
}</style>
