<template>
    <v-card v-if="loading">
        <loading />
    </v-card>
    <v-card v-else>
        <v-card-title>Add Award Refund to Deposit</v-card-title>
        <v-card-text>
            <v-select
                v-model="award.fiscal_year_id"
                :error="!!errors.fiscal_year_id"
                :error-messages="errors.fiscal_year_id ? errors.fiscal_year_id : null"
                :items="fiscalYears"
                background-color="white"
                class="mt-5"
                filled
                item-text="name"
                item-value="id"
                label="Fiscal Year Award Made"
            />

            <data-table-student-available-awards
                v-if="award.fiscal_year_id"
                :award="award"
            />

        </v-card-text>
        <v-card-actions>
            <v-btn
                :disabled="addRefundDisabled"
                class="my-5"
                color="success"
                @click="postRefunds"
            >
                <v-icon
                    color="white"
                    left
                    small
                >
                    mdi-plus
                </v-icon>
                <span style="color: white;">Save</span>
            </v-btn>
            <v-spacer />
            <v-btn
                class="my-5"
                color="error"
                @click="$emit('done')"
            >
                <v-icon
                    color="white"
                    left
                    small
                >
                    mdi-close-thick
                </v-icon>
                <span style="color: white;">Cancel</span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import DataTableStudentAvailableAwards from "./DataTableStudentAvailableAwards";
import {
    GET_AWARDS_BY_SCHOOL_AND_PERIOD,
    GET_FISCAL_YEARS,
    POST_AWARD_REFUNDS_TO_DEPOSIT,
    GET_ADMIN_DEPOSIT_AWARD_REFUNDS
} from "../../../store/actions.type";


export default {
    name: "AdminDepositFormAwardRefund",
    components: {
        Loading,
        DataTableStudentAvailableAwards,
    },
    data() {
        return {
            loading: false,
            award: {
                fiscal_year_id: null,
            },
            addRefundDialog: false,
        }
    },
    computed: {
        deposit: {
            get() {
                return this.$store.getters.currentAdminDeposit;
            },
            set(newValue) {
                this.$store.commit(SET_ADMIN_DEPOSIT, newValue);
            },
        },
        awardRefunds() {
            return this.$store.getters.currentAdminDepositAwardRefunds;
        },
        awardRefundsTotal() {
            return this.awardRefunds.reduce((total, refund) => {
                return parseFloat(total) + parseFloat(refund.amount);
            }, 0);
        },
        awards() {
            return this.$store.getters.currentAdminSchoolStudentAvailableAwards;
        },
        awardRefundsToPost() {
            return this.awards.filter(award => award.apply_amount > 0);
        },
        pendingRefundsTotal() {
            return this.awards.reduce((total, award) => {
                return parseFloat(total) + parseFloat(award.apply_amount);
            }, 0);
        },
        pendingRefundsToDepositRemaining() {
            return parseFloat(this.deposit.amount) - (parseFloat(this.awardRefundsTotal) + parseFloat(this.pendingRefundsTotal));
        },
        addRefundDisabled() {
            return this.awardRefundsToPost.length == 0 || this.pendingRefundsToDepositRemaining < 0
        },
        fiscalYearId() {
            return this.award.fiscal_year_id;
        },
        fiscalYears() {
            return this.$store.getters.currentFiscalYears
        },

        schoolId() {
            return this.deposit.school_id ? this.deposit.school_id : 0;
        },
        taxCreditTypes() {
            return this.$store.getters.currentTaxCreditTypes;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        postRefunds() {
            let deposit_id = this.deposit.id;
            let awardRefundsToPost = this.awardRefundsToPost;
            this.$store.dispatch(POST_AWARD_REFUNDS_TO_DEPOSIT, { deposit_id, awardRefundsToPost })
                .then(() => {
                    let fiscal_year_id = this.award.fiscal_year_id;
                    this.getSchoolStudentAwards(fiscal_year_id);
                    this.$emit("done");
                })
                .catch((e) => {
                    console.log(e);
                    console.log("POST_AWARD_REFUNDS_TO_DEPOSIT error")
                });
        },
        getFiscalYears() {
            this.loading = true;
            this.$store.dispatch(GET_FISCAL_YEARS).then(() => {
                this.loading = false;
            })
                .catch(() => {
                    this.loading = false;
                });
        },
        getSchoolStudentAwards(fiscal_year_id) {
            if (!this.deposit.school_id) return;

            this.loading = true;
            let school_id = this.deposit.school_id;
            this.$store.dispatch(GET_AWARDS_BY_SCHOOL_AND_PERIOD, { fiscal_year_id, school_id }).then(() => {
                this.loading = false;
            })
                .catch((response) => {
                    console.log(response)
                });
        },
    },
    mounted() {
        this.getFiscalYears();
    },
    watch: {
        fiscalYearId() {
            this.getSchoolStudentAwards(this.award.fiscal_year_id)
        },
        schoolId() {
            this.getSchoolStudentAwards(this.award.fiscal_year_id)
        }
    },
}
</script>

<style scoped>
::v-deep .theme--light.v-icon {
    color: var(--apsto-teal);
}
</style>
