var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('loading'):_c('div',[_c('v-row',{staticClass:"ml-1 pb-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('household-header',{attrs:{"page-title":"Custom Resources"}})],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-simple-table',{ref:"promosTable",staticClass:"elevation-2 mt-3"},[_c('tr',[_c('th',{attrs:{"role":"columnheader"}},[_vm._v("School Year")]),_vm._v(" "),_c('th',{attrs:{"role":"columnheader"}},[_vm._v("For")]),_vm._v(" "),_c('th',{attrs:{"role":"columnheader"}},[_vm._v("Actions")]),_vm._v(" "),_c('th',{attrs:{"role":"columnheader"}},[_vm._v("Date Last Updated")])]),_vm._v(" "),_c('tbody',[(_vm.household.application_count === 0)?_c('tr',[_c('td',{staticClass:"text-center text-apsto-teal py-4",attrs:{"colspan":"4"}},[_vm._v("\n        Please add an application to see student pages.\n      ")])]):_vm._e()]),_vm._v(" "),_vm._l((_vm.promos),function(promo,promo_index){return _c('tbody',{key:promo_index},_vm._l((promo.members),function(member,member_index){return _c('tr',{key:member_index},[_c('td',{staticClass:"text-center py-4"},[_vm._v("\n        "+_vm._s(promo.school_year_name)+"\n      ")]),_vm._v(" "),_c('td',{staticClass:"text-center py-4"},[_vm._v("\n        "+_vm._s(member.name)+"\n      ")]),_vm._v(" "),_c('td',{staticClass:"text-center py-4"},[(member.promo)?[_c('v-btn',{staticStyle:{"min-width":"240px"},attrs:{"to":{
                                  name: 'application-member-promo-edit',
                                  params: {
                                      household_id: _vm.$route.params.household_id,
                                      application_id: member.application_id,
                                      application_member_id: member.id,
                                      promo_id: member.promo.id
                                  },
                              },"color":"secondary","outlined":""}},[_c('v-icon',{attrs:{"color":"black","left":""}},[_vm._v("mdi-account-school\n            ")]),_vm._v(" "),_c('span',{staticStyle:{"color":"black"}},[_vm._v("EDIT STUDENT PAGE")])],1)]:[_c('v-btn',{staticStyle:{"min-width":"240px"},attrs:{"to":{
                                  name: 'application-member-promo-create',
                                  params: {
                                      household_id: _vm.$route.params.household_id,
                                      application_id: member.application_id,
                                      application_member_id: member.id,
                                  },
                              },"color":"secondary","outlined":""}},[_c('v-icon',{attrs:{"color":"black","left":""}},[_vm._v("mdi-account-school\n            ")]),_vm._v(" "),_c('span',{staticStyle:{"color":"black"}},[_vm._v("CREATE STUDENT PAGE")])],1)]],2),_vm._v(" "),_c('td',{staticClass:"text-center py-4"},[_vm._v("\n        "+_vm._s(member.promo ? _vm.displayDate(member.promo.updated_at) : "-")+"\n      ")])])}),0)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }