<template>
    <div class="mx-2">
        <v-row>
            <v-col class="text-h5-mulish"> {{ message }}</v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="box-with-background">
                    Quick Address Entry
                    <vuetify-google-autocomplete
                        id="map"
                        append-icon="mdi-magnify"
                        background-color="white"
                        class="mt-4"
                        country="us"
                        filled
                        placeholder="Start typing address"
                        v-on:placechanged="getAddressData"
                    />
                </div>
            </v-col>
        </v-row>
        <v-text-field
            id="street1"
            v-model.trim="donor.address.street1"
            :error="!!errors['address.street1']"
            :error-messages="errors['address.street1'] ? errors['address.street1'] : null"
            autocomplete="address-line1"
            background-color="white"
            filled
            label="Street1"
        />
        <v-text-field
            id="street2"
            v-model.trim="donor.address.street2"
            :error="!!errors['address.street2']"
            :error-messages="errors['address.street2'] ? errors['address.street2'] : null"
            autocomplete="address-line2"
            background-color="white"
            filled
            label="Street2"
        />
        <v-text-field
            id="postal"
            v-model.trim="donor.address.postal"
            :error="!!errors['address.postal']"
            :error-messages="errors['address.postal'] ? errors['address.postal'] : null"
            autocomplete="zip"
            background-color="white"
            filled
            label="Postal / Zip"
        />
        <v-text-field
            id="city"
            v-model.trim="donor.address.city"
            :error="!!errors['address.city']"
            :error-messages="errors['address.city'] ? errors['address.city'] : null"
            autocomplete="address-level2"
            background-color="white"
            filled
            label="City"
        />
        <region-selector v-model="donor.address.region_id" />

        <v-row>
            <v-col
                class="text-h5"
                md="6"
            > Contact Phone
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text--secondary mb-5 mt-n4">
                In case we need to clarify anything about your donation.
            </v-col>
        </v-row>
        <v-text-field
            id="phone"
            v-model.trim="donor.phone"
            :error="!!errors['phone']"
            :error-messages="errors['phone'] ? errors['phone'] : null"
            autocomplete="phone"
            background-color="white"
            filled
            label="Phone"
            @input="phoneNumber"
        />
    </div>
</template>

<script>
import RegionSelector from "../shared/RegionSelector";

export default {
    name: "DonorForm",
    components: {
        RegionSelector
    },
    props: {
        donor: {
            type: Object,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
    },
    computed: {
        regions() {
            return this.$store.getters.currentRegions;
        },
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
    },
    methods: {
        phoneNumber() {
            const x = this.donor.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.donor.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.donor.address.street1 = addressData.name;
            this.donor.address.city = addressData.locality;
            this.donor.address.postal = addressData.postal_code;
            this.donor.address.lat = addressData.latitude;
            this.donor.address.lon = addressData.longitude;

            let iso_state = addressData.administrative_area_level_1;
            this.donor.address.region_id = searchArrayByKey(iso_state, this.regions, 'iso', 'object').id;
        }
    }
}
</script>

<style scoped>
.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
}
</style>
