<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col class="mx-2 text-h5-mulish"> Create New Company Profile</v-col>
        </v-row>
        <v-row class="mt-n3 mt-sm-0">
            <v-col
                class="mx-2"
                md="6"
            >
                <v-form :disabled="saving">
                    <company-form :company="company"/>
                    <v-btn
                        :block="!!isMobile"
                        :to="{ name: 'companies' }"
                        class="cancel-button" rounded
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        :block="!!isMobile"
                        :class="isMobile ? 'mt-5' : 'ml-2'"
                        class="save-by-cancel-button"
                        color="primary"
                        rounded
                        @click="submit"
                    >
                        Save
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import CompanyForm from "../../../components/company/CompanyForm";
import {POST_COMPANY} from "../../../store/actions.type";

export default {
    name: "CompanyCreate",
    components: {
        Loading,
        CompanyForm
    },
    data() {
        return {
            company: {
                address: {
                    street1: "",
                    street2: "",
                    city: "",
                    lat: "",
                    lon: "",
                    postal: "",
                    region_id: 3
                },
                employee_donations_frequency: 5,
                organization_type_enum: 0
            },
            loading: true,
            saving: false
        };
    },
    methods: {
        submit() {
            this.saving = true;
            this.$store.dispatch(POST_COMPANY, this.company)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: 'companies'})
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.loading = false;
    }
}
</script>

