<template>
    <div>
        <div class="text-h6-mulish">Admins</div>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="admins"
            class="mt-3 elevation-1"
            style="width: 100%;"
            :hide-default-footer="true"
        >
            <template #item.is_primary="{ item }">
                {{ item.is_primary ? 'Yes' : '' }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            :href="'/impersonate/take/' + item.id"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon style="color: var(--apsto-teal);">mdi-account-convert</v-icon>
                        </a>
                    </template>
                    <span>Impersonate</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { GET_COMPANY_ADMINS, GET_HOUSEHOLD_ADMINS, GET_SCHOOL_ADMINS } from "../../../store/actions.type";

export default {
    name: "AdminAdministrators",
    data() {
        return {
            headers:
                [
                    {
                        text: 'Admin Name',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Email', value: 'email' },
                    { text: 'Primary Admin', value: 'is_primary' },
                    { text: 'Options', value: 'actions', sortable: false, align: 'end' },
                ],
            loading: true,
        }
    },
    computed: {
        admins() {
            switch (this.entityType) {
                case "company":
                    return this.$store.getters.currentCompanyAdmins;
                case "household":
                    return this.$store.getters.currentHouseholdAdmins;
                case "school":
                    return this.$store.getters.currentSchoolAdmins;
            }
        },
    },
    props: {
        entityType: {
            type: String,
            required: true
        }
    },
    methods: {
        getAdmins() {
            switch (this.entityType) {
                case "company":
                    let company_id = this.$route.params.company_id;
                    this.$store.dispatch(GET_COMPANY_ADMINS, company_id).then(() => {
                        this.loading = false;
                    });
                    break;
                case "household":
                    let household_id = this.$route.params.household_id;
                    this.$store.dispatch(GET_HOUSEHOLD_ADMINS, household_id).then(() => {
                        this.loading = false;
                    });
                    break;
                case "school":
                    let school_id = this.$route.params.school_id;
                    this.$store.dispatch(GET_SCHOOL_ADMINS, school_id).then(() => {
                        this.loading = false;
                    });
                    break;
            }
        },
    },
    mounted() {
        this.getAdmins();
    },
}
</script>
<style scoped>
::v-deep .v-data-table-header {
    background-color: var(--apsto-purple-very-light);
}
</style>
