<template>
    <v-card class="mb-5 pa-5">
        <div v-if="!salespersonAssignment.id">
            <v-radio-group
                v-model="salespersonAssignment.type"
                column
                @change="clearId"
            >
                <template v-slot:label>
                    <div class="text--primary darken-4 pb-2">
                        Do you want to assign {{ salesperson.name }} to a corporate or individual donor?
                    </div>
                </template>
                <v-radio
                    color="secondary"
                    label="Corporate"
                    value="Corporate"
                />
                <v-radio
                    color="secondary"
                    label="Individual"
                    value="Individual"
                />
            </v-radio-group>
            <div v-if="salespersonAssignment.type === 'Corporate'">
                <company-selector
                    v-model="salespersonAssignment.company_id"
                    background-color=""
                />
            </div>
            <div v-else>
                <user-selector
                    v-model="salespersonAssignment.user_id"
                    background-color=""
                    label="Individual Name"
                />
            </div>
        </div>
        <div
            class="text--primary darken-4 pb-2"
            style="font-size: 1.125rem;"
        >
            Which commission rate would you like to use?
        </div>
        <v-text-field
            v-model="salespersonAssignment.commission_rate"
            :error="!!errors.commission_rate"
            :error-messages="errors.commission_rate ? errors.commission_rate : null"
            filled
        />

        <v-radio-group
            v-model="salespersonAssignment.backdate"
            column
        >
            <template v-slot:label>
                <div class="text--primary darken-4 pb-2">
                    Do you want to give {{ salesperson.name }} credit for all transactions for this donor during the current
                    school year?
                </div>
            </template>
            <v-radio
                :value="true"
                color="secondary"
                label="Yes"
            />
            <v-radio
                :value="false"
                color="secondary"
                label="No"
            />
        </v-radio-group>
    </v-card>
</template>

<script>

import CompanySelector from "../../../shared/CompanySelector";
import UserSelector from "../../../shared/UserSelector";

export default {
    name: "SalespersonAssignmentForm",
    components: { CompanySelector, UserSelector },
    props: {
        salesperson: {
            type: Object,
            required: true,
        },
        salespersonAssignment: {
            type: Object,
            required: true,
        }
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        clearId() {
            if (this.salespersonAssignment.type === "Corporate") {
                this.salespersonAssignment.user_id = 0;
            } else {
                this.salespersonAssignment.company_id = 0;
            }
        },
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .v-input--radio-group legend.v-label {
    font-size: 18px;
}
</style>
