<template>
    <loading v-if="loading" />
    <div v-else>
        <v-img
            contain
            src="/images/website/headers/charitable-donations.jpeg"
        >
            <v-row
                align="center"
                class="fill-height ma-0 white--text font-roboto-slab font-weight-black"
                justify="center"
            >
                <v-col
                    class="text-center"
                    cols="8"
                >
                    <div
                        v-resize-text="{ ratio: 1.4 }"
                        class="billboard-body"
                    >
                        Charitable Donation
                    </div>
                </v-col>
            </v-row>
        </v-img>
        <v-sheet>
            <v-form :disabled="saving">
                <!--Your Information-->
                <v-row
                    class="pl-4 pt-8"
                    justify="center"
                >
                    <v-col
                        class="text-h5-mulish"
                        md="6"
                    > Your Information
                    </v-col>
                </v-row>
                <v-row
                    class="px-5"
                    justify="center"
                >
                    <v-col md="6">
                        <v-text-field
                            id="email"
                            v-model.trim="donor.email"
                            :error="!!errors['donor.email']"
                            :error-messages="errors['donor.email'] ? errors['donor.email'] : null"
                            autocomplete="email"
                            filled
                            label="Email"
                            @blur="checkRegistered"
                        />
                        <div
                            v-if="displaySignInMessage"
                            class="box-with-background pa-5"
                        >
                            <v-row>
                                <v-col>
                                    Welcome back,
                                    <div class="mt-3">
                                        Would you like us to log you in so we can fill in data we
                                        already have on file for you? You won't need a password.
                                    </div>
                                    <div class="my-5">
                                        <v-btn
                                            :block="isMobile"
                                            class="guest-mimic-logged-in-btn"
                                            rounded
                                            style="min-width: 40%;"
                                            @click="continueWithoutSigningIn"
                                        >
                                            No, Continue As A Guest<br> Without Logging In
                                        </v-btn>
                                        <div class="mt-5">
                                            <v-btn
                                                :block="isMobile"
                                                :disabled="disableLoginByEmail"
                                                class="guest-mimic-logged-in-btn"
                                                color="primary"
                                                rounded
                                                style="min-width: 40%;"
                                                @click="sendNoSignInEmail"
                                            >
                                                Yes, Log Me In <br>Without a Password
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div
                                        v-if="displayEmailMessage"
                                        class="mt-3 box-with-background purple"
                                    >
                                        We just sent a login link to {{ donor.email }}. Please check your spam folder if you
                                        don't see it.
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="!hideEverything">
                            <v-text-field
                                id="first_name"
                                v-model.trim="donor.first_name"
                                :error="!!errors['donor.first_name']"
                                :error-messages="errors['donor.first_name'] ? errors['donor.first_name'] : null"
                                autocomplete="name"
                                filled
                                label="First Name"
                            />
                            <v-text-field
                                id="last_name"
                                v-model.trim="donor.last_name"
                                :error="!!errors['donor.last_name']"
                                :error-messages="errors['donor.last_name'] ? errors['donor.last_name'] : null"
                                autocomplete="name"
                                filled
                                label="Last Name"
                            />
                            <v-text-field
                                id="phone"
                                v-model.trim="donor.phone"
                                :error="!!errors['donor.phone']"
                                :error-messages="errors['donor.phone'] ? errors['donor.phone'] : null"
                                autocomplete="phone"
                                filled
                                label="Phone"
                                @input="phoneNumber"
                            />
                        </div>
                    </v-col>
                </v-row>

                <div v-if="!hideEverything">
                    <!--Mailing Address-->
                    <v-row
                        class="pl-4"
                        justify="center"
                    >
                        <v-col
                            class="text-h5-mulish mt-n5"
                            md="6"
                        >
                            Mailing Address
                        </v-col>
                    </v-row>
                    <v-row
                        class="px-5"
                        justify="center"
                    >
                        <v-col md="6">
                            <div class="box-with-background">
                                Quick Address Entry
                                <vuetify-google-autocomplete
                                    id="map"
                                    append-icon="mdi-magnify"
                                    background-color="white"
                                    class="mt-4"
                                    country="us"
                                    filled
                                    placeholder="Start typing address"
                                    v-on:placechanged="getAddressData"
                                />
                            </div>

                            <v-text-field
                                id="street1"
                                v-model.trim="donor.address.street1"
                                :error="!!errors['donor.address.street1']"
                                :error-messages="errors['donor.address.street1'] ? errors['donor.address.street1'] : null"
                                autocomplete="address-line1"
                                filled
                                label="Street1"
                            />
                            <v-text-field
                                id="street2"
                                v-model.trim="donor.address.street2"
                                :error="!!errors['donor.address.street2']"
                                :error-messages="errors['donor.address.street2'] ? errors['donor.address.street2'] : null"
                                autocomplete="address-line2"
                                filled
                                label="Street2"
                            />
                            <v-text-field
                                id="postal"
                                v-model.trim="donor.address.postal"
                                :error="!!errors['donor.address.postal']"
                                :error-messages="errors['donor.address.postal'] ? errors['donor.address.postal'] : null"
                                autocomplete="zip"
                                filled
                                label="Postal / Zip"
                            />
                            <v-text-field
                                id="city"
                                v-model.trim="donor.address.city"
                                :error="!!errors['donor.address.city']"
                                :error-messages="errors['donor.address.city'] ? errors['donor.address.city'] : null"
                                autocomplete="address-level2"
                                filled
                                label="City"
                            />
                            <region-selector
                                v-model="donor.address.region_id"
                                background-color=""
                            />
                        </v-col>
                    </v-row>
                </div>

                <div v-if="donor.email && !hideEverything">

                    <v-row
                        class="pl-4"
                        justify="center"
                    >
                        <v-col
                            class="text-h5-mulish"
                            md="6"
                        > Donation Amount <span class="red--text">*</span>
                        </v-col>
                    </v-row>

                    <!--Donation Amount-->
                    <v-row
                        class="px-5"
                        justify="center"
                    >
                        <v-col md="6">

                            <v-currency-field
                                id="amount"
                                v-model.number="donation.amount"
                                :error="!!errors['amount']"
                                :error-messages="errors['amount'] ? errors['amount'] : null"
                                filled
                                label="Donation Amount"
                                type="number"
                            />

                        </v-col>
                    </v-row>

                    <!--Recommendations-->
                    <v-row
                        class="px-5 mt-n9"
                        justify="center"
                    >
                        <v-col md="6">

                            <recommendations-charitable
                                v-model="donation.recommendations.charitable"
                                class="bottom-border"
                                tax-credit-type="charitable"
                            />

                        </v-col>
                    </v-row>

                    <!--Payment-->
                    <v-row
                        class="px-5 mt-n2"
                        justify="center"
                    >
                        <v-col md="6">

                            <h2 class="mb-4 text-apsto-teal">Payment</h2>
                            <div class="text-h7-mulish font-weight-black">Amount: {{ donation.amount | formatCurrency }}
                            </div>

                            <!-- Payment-->
                            <donation-payment-by-card
                                :called-from-charitable-donation-page="true"
                                :donation="donation"
                                :donor="donor"
                                :email="donor.email"
                            />

                        </v-col>
                    </v-row>

                </div>

            </v-form>
            <web-footer class="mt-8" />
        </v-sheet>
    </div>
</template>

<script>

import DonationPaymentByCard from "../../components/donations/DonationPaymentByCard";
import FilingStatus from "../../components/donations/FilingStatus";
import Loading from "../../components/shared/Loading";
import RecommendationsCharitable from "../../components/donations/RecommendationsCharitable";
import RegionSelector from "../../components/shared/RegionSelector";
import SchoolSelector from "../../components/shared/SchoolSelector";
import WebFooter from "../../components/website/components/WebFooter";
import {
    CHECK_REGISTERED,
    SEND_SIGN_IN_EMAIL_FROM_CHARITABLE_DONATION,
} from "../../store/actions.type";
import { SET_DONATION } from "../../store/mutations.type";

export default {
    name: "OnlineCharitableDonationCreate",
    components: {
        FilingStatus,
        Loading,
        DonationPaymentByCard,
        RecommendationsCharitable,
        SchoolSelector,
        RegionSelector,
        WebFooter
    },
    data() {
        return {
            loading: true,
            disableLoginByEmail: false,
            displayEmailMessage: false,
            displaySignInMessage: false,
            donor: {
                address: {
                    street1: "",
                    street2: "",
                    city: "",
                    lat: "",
                    lon: "",
                    postal: "",
                    region_id: 3
                },
            },
            hideEverything: false,
            saving: false,
        };
    },
    computed: {
        donation: {
            get() {
                return this.$store.getters.currentDonation;
            },
            set(donation) {
                this.$store.commit(SET_DONATION, donation);
            },
        },
        regions() {
            return this.$store.getters.currentRegions;
        },
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
    },
    methods: {
        checkRegistered() {
            let email = this.donor.email
            this.$store.dispatch(CHECK_REGISTERED, email)
                .then((data) => {
                    if (data.message_response.status === "is_registered" && data.message_response.offer_to_login === true) {
                        this.displaySignInMessage = true;
                    }
                })
                .catch((response) => {
                    console.log(response);
                })
        },
        continueWithoutSigningIn() {
            this.displaySignInMessage = false;
            this.hideEverything = false;
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.donor.address.street1 = addressData.name;
            this.donor.address.city = addressData.locality;
            this.donor.address.postal = addressData.postal_code;
            this.donor.address.lat = addressData.latitude;
            this.donor.address.lon = addressData.longitude;

            let iso_state = addressData.administrative_area_level_1;
            this.donor.address.region_id = searchArrayByKey(iso_state, this.regions, 'iso', 'object').id;
        },
        login() {
            this.$router.push("/login-register");
        },
        phoneNumber() {
            const x = this.donor.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.donor.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        sendNoSignInEmail() {
            let email = this.donor.email;
            this.$store.dispatch(SEND_SIGN_IN_EMAIL_FROM_CHARITABLE_DONATION, email)
            this.displayEmailMessage = true;
            this.disableLoginByEmail = true;
            this.hideEverything = true;
        }
    },
    mounted() {
        this.loading = true;

        if (this.isAuthenticated) {
            this.$router.push({ name: "charitable-donation-create" });
            return;
        }

        //Set donation to a blank version
        this.$nextTick(() => {
            this.$store.commit(SET_DONATION, {
                amount: 0,
                agree_to_terms: true,
                recommendations: {
                    charitable: {
                        comments: null,
                        school_id: null,
                        students: [],
                    },
                },
                tax_filing_status: "single",
            });
            this.loading = false;
        });
    },
};
</script>

<style scoped>
div {
    font-size: 1.125rem;
}

.billboard-body {
    font-family: "Roboto Slab", serif;
    text-shadow: 1px 1px 1px #000;
    line-height: 1.1;
}

.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
}

.box-with-background.purple {
    background-color: var(--apsto-purple) !important;
    margin-bottom: 0;
    padding-bottom: 20px;
}

.bottom-border {
    border-bottom: 1px dotted;
}

::v-deep .v-toolbar__content {
    background-color: var(--apsto-purple-very-light);
}

::v-deep .v-label {
    color: black;
}
</style>
