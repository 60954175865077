<template>
    <div>
        <v-row
            align="center"
            class="py-12"
            justify="center"
        >
            <v-col md="6 text-center">

                <v-avatar class="avatar">
                    <v-img
                        v-if="user.profile_hash"
                        :src="imageUrl(user.profile_hash)"
                        contain
                        rounded
                    />
                    <v-icon
                        v-else
                        color="secondary"
                        size="80"
                    >
                        mdi-account-circle
                    </v-icon>
                </v-avatar>
                <v-card class="message-background mt-n10 px-0 px-sm-6 py-12">
                    <div class="message-welcome">
                        {{ textWelcome }}
                    </div>
                    <div class="message-instructions px-4 px-sm-16 py-6">
                        {{ textInstructions }}
                    </div>
                    <div class="message-close">
                        Thank you for choosing to partner with APSTO!
                    </div>
                    <div class="mt-8">
                        <v-btn
                            v-if="user.corporate"
                            class="mr-2"
                            color="secondary"
                            rounded
                            @click="$router.push({ name: 'companies' })"
                        >
                            Corporate Donors
                        </v-btn>
                        <v-btn
                            v-if="user.donor_individual || user.donor_subscription || user.donor_withholding"
                            class="mr-2"
                            color="secondary"
                            rounded
                            @click="$router.push({ name: 'donor' })"
                        >
                            Individual Donors
                        </v-btn>
                        <v-btn
                            v-if="user.applicant"
                            class="mr-2"
                            color="secondary"
                            rounded
                            @click="$router.push({ name: 'households' })"
                        >
                            Applicant
                        </v-btn>
                        <v-btn
                            v-if="user.school"
                            color="secondary"
                            rounded
                            @click="$router.push({ name: 'schools' })"
                        >
                            Schools
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import config from "../../common/config";

export default {
    name: "Dashboard",
    methods: {
        config() {
            return config
        }
    },
    computed: {
        textInstructions() {
            return this.user.is_new_user ? 'You can manage and access different portal profiles you have created from this page. To start, please click the profile you\'d like to access below.' : 'Please click the profile you\'d like to access below.';
        },
        textWelcome() {
            return this.user.is_new_user ? 'Hi ' + this.user.first_name + ', welcome!' : 'Hi ' + this.user.first_name + ', welcome back!';
        },
        user() {
            return this.$store.getters.currentUser;
        },
    },
    mounted() {
        if (this.user.redirect_to) {
            this.$router.push({name: this.user.redirect_to});
        }
    },
}
</script>

<style scoped>
.avatar {
    height: 80px !important;
    width: 80px !important;
    z-index: 2;
}

.message-background {
    background-color: var(--apsto-purple-very-light);
}

.message-welcome {
    color: var(--apsto-purple);
    font-family: 'Mulish', sans-serif !important;
    font-size: 2.125rem !important;
    font-weight: 900;
    letter-spacing: 0.0073529412em !important;
    line-height: 2.5rem;
}

.message-instructions {
    color: var(--apsto-black-light);
    font-family: 'Mulish', sans-serif !important;
    font-size: 1.25rem !important;
    font-weight: 400;
    letter-spacing: 0.009375em !important;
    line-height: 1.6rem;
}

.message-close {
    color: var(--apsto-teal);
    font-family: 'Roboto Slab', serif;
    font-size: 1.625rem;
}
</style>
