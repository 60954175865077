<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row
            class="ml-1 mt-5"
            align="center"
        >
            <v-col md="6">
                <admin-header page-title="Companies" />
            </v-col>
        </v-row>
        <v-spacer />
        <v-data-table
            :headers="headers"
            :items="companies"
            :search="search"
            :single-select="true"
            item-key="id"
            @click:row="editCompany"
            class="elevation-2 mt-3"
        >
            <template v-slot:top>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            v-model="search"
                            class="mx-4"
                            label="Search on anything"
                            prepend-inner-icon="mdi-magnify"
                            outlined
                            background-color="white"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no companies on file.
                </span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import { GET_COMPANIES } from "../../../store/actions.type";

export default {
    name: "AdminCompaniesList",
    components: {
        AdminHeader,
        Loading
    },
    data() {
        return {
            defaultItem: {
                name: '',
                approved: '',
            },
            dialog: false,
            headers: [
                { text: 'Name', align: 'start', value: 'name' },
                { text: 'Type', value: 'organization_type_enum' },
            ],
            loading: true,
            search: "",
        }
    },
    computed: {
        companies() {
            return this.$store.getters.currentCompanies;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        getCompanies() {
            this.loading = true;
            this.$store.dispatch(GET_COMPANIES)
                .then(() => {
                    this.loading = false;
                });
        },
        editCompany(item) {
            this.$router.push(`companies/${item.id}/edit`);
        },
        initialize() {
            this.companies = [];
        },
    },
    mounted() {
        this.getCompanies();
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}</style>
