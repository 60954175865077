<template>
    <v-card max-width="520">
        <v-card-title>
            <span class="text-h5">
                {{ title }}
            </span>
        </v-card-title>
        <v-card-subtitle
            v-if="subtitle"
            class="f-size-14 mt-3 pl-7 pb-0"
        >
            {{ subtitle }}
        </v-card-subtitle>
        <v-card-text class="mt-5">
            <v-row>
                <v-col>
                    <div class="f-size-14 black--text">
                        File Types: PNG, JPEG or JPG.
                        <br />
                        Maximum File Size: 10 MB
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-file-input
                        ref="fileupload"
                        v-model="selectedFile"
                        accept="image/*"
                        label="File"
                        placeholder="Select a file"
                        :show-size="1024"
                        @change="setupCropper"
                        :error="error"
                        :error-messages="errorMessage"
                        @click:clear="$emit('clear')"
                    />


                    <v-row v-if="objectUrl">
                        <v-col class="text-center">
                            <div class="overline">Original</div>
                            <div class="image-container elevation-4">
                                <img
                                    class="image-preview"
                                    ref="source"
                                    :src="objectUrl"
                                />
                            </div>
                            <div class="d-flex justify-center">
                                <v-btn
                                    icon="icon"
                                    small="small"
                                    @click="resetCropper"
                                >
                                    <v-icon small="small">mdi-aspect-ratio</v-icon>
                                </v-btn>
                                <div class="mx-2"></div>
                                <v-btn
                                    icon="icon"
                                    small="small"
                                    @click="rotateLeft"
                                >
                                    <v-icon small="small">mdi-rotate-left</v-icon>
                                </v-btn>
                                <v-btn
                                    icon="icon"
                                    small="small"
                                    @click="rotateRight"
                                >
                                    <v-icon small="small">mdi-rotate-right</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="objectUrl">
                        <v-col class="text-center">
                            <div class="overline">Preview</div>
                            <div class="image-container elevation-4">
                                <img
                                    class="image-preview"
                                    :src="previewCropped"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <div v-if="objectUrl">
                        <div>
                            <v-progress-linear
                                v-model="progress"
                                color="#55A9AD"
                                height="35"
                                reactive
                            >
                                <strong>{{ progress }} %</strong>
                            </v-progress-linear>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn
                v-if="picture"
                @click="removePicture"
            > Remove</v-btn>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                :disabled="!objectUrl"
                @click.prevent="upload"
            >
                <span>Submit</span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Cropper from "cropperjs";
import ApiService from "../../api/api.service";

export default {
    props: {
        aspectRatio: {
            default: 1,
            type: Number,
            default: null,
        },
        picture: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: "Avatar cropper",
        },
        subtitle: {
            type: String,
        },

        loading: {
            type: Boolean,
            default: false
        },
        fillColor: {
            type: String,
            default: '#fff'
        }
    },
    data() {
        return {
            objectUrl: null,
            progress: 0,
            cropper: null,
            previewCropped: null,
            selectedFile: null,
            error: false,
            errorMessage: "",
            debouncedUpdatePreview: this.updatePreview,
        };
    },
    mounted() {
        if (this.picture) {
            this.objectUrl = this.picture;
            this.$nextTick(this.setupCropperInstance);
        }
    },
    methods: {
        resetCropper() {
            this.cropper.reset();
        },
        rotateLeft() {
            this.cropper.rotate(-90);
        },
        rotateRight() {
            this.cropper.rotate(90);
        },
        setupCropper(selectedFile) {
            if (this.cropper) {
                this.cropper.destroy();
            }

            if (this.objectUrl) {
                window.URL.revokeObjectURL(this.objectUrl);
            }

            if (!selectedFile) {
                if (this.picture) {
                    this.objectUrl = this.picture;
                    this.$nextTick(this.setupCropperInstance);
                } else {
                    this.cropper = null;
                    this.objectUrl = null;
                    this.previewCropped = null;
                }
                return;
            }

            this.objectUrl = window.URL.createObjectURL(selectedFile);
            this.$nextTick(this.setupCropperInstance);
        },
        setupCropperInstance() {
            let self = this;
            this.cropper = new Cropper(this.$refs.source, {
                crop: this.debouncedUpdatePreview,
                aspectRatio:self.aspectRatio,
                background: false
            })
        },
        updatePreview(event) {
            const canvas = this.cropper.getCroppedCanvas({
                fillColor: this.fillColor
            });
            this.previewCropped = canvas.toDataURL("image/png");
        },
        upload() {
            let self = this;
            const canvas = this.cropper.getCroppedCanvas({
                fillColor: this.fillColor
            });
            canvas.toBlob(
                (blob) => {
                    ApiService.uploadImage(blob, (event) => {
                        self.progress = Math.round((100 * event.loaded) / event.total);
                    })
                        .then((response) => {

                            let hash = response.data;
                            //Clear error message
                            self.errorMessage = "";
                            self.objectUrl = undefined;
                            self.previewCropped = undefined;
                            //Reset File Upload
                            self.$refs.fileupload.reset();
                            self.$emit("done", hash);
                        })
                        .catch((err) => {
                            self.progress = 0;
                            self.message = "Could not upload the image! " + err;
                            self.objectUrl = undefined;
                        });
                },
                "image/jpeg",
                0.75
            );
        },
        removePicture() {
            this.cropper = null;
            this.objectUrl = null;
            this.previewCropped = null;
            this.$emit("remove");
        },
    },
};
</script>

<style scoped>.image-container {
    display: inline-block;
}

.image-preview {
    display: block;
    max-height: 229px;
    max-width: 100%;
}</style>
