<template>
  <v-row align="center" class="ml-n1 outlined">
    <v-col cols="6">
      <v-btn
        v-if="!editMode"
        :href="`/siteDocuments/slug/${content.file.slug}`"
        class="site-document-link"
        target="_blank"
        text
      >
        <v-icon class="mr-2 ml-n2" size="24">$pdf</v-icon>
        <span style="color: var(--apsto-purple);">
          {{ content.file.name }}
        </span>
      </v-btn>
      <v-autocomplete
        v-else
        v-model="content.body.file"
        :items="siteDocuments"
        :loading="loading"
        eager
        item-text="name"
        item-value="slug"
        label="Document"
        @change="updateContent"
      />
    </v-col>
  </v-row>
</template>

<script>

import {GET_CONTENTS, PATCH_CONTENT} from '../../../../store/actions.type';
import {SET_FAB_ENABLED} from '../../../../store/mutations.type';

export default {
  name: "SiteDocument",
  props: {
    content: {
      type: Object,
    },
  },
  computed: {
    editMode() {
      return this.$store.getters.currentEditMode;
    },
    siteDocuments() {
      return this.$store.getters.currentSiteDocumentSlugs;
    }
  },
  data() {
    return {
      loading: false,
      search: null,
    };
  },
  methods: {
    updateContent() {
      let content = this.content;
      this.loading = true;
      this.$store.commit(SET_FAB_ENABLED, false);
      let self = this;
      this.$store
        .dispatch(PATCH_CONTENT, content)
        .then(() => {
          this.$store.dispatch(GET_CONTENTS);
          this.$store.commit(SET_FAB_ENABLED, true);
          self.loading = false;
        })
        .catch((e) => {
          self.loading = false;
        });

    },

  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  font-weight: 600;
  padding: 0;
}

.v-btn:not(.v-btn--round).v-size--default:hover {
  font-weight: 600;
  padding: 0;
}

.v-btn:before {
  background-color: transparent;
}

::v-deep .v-btn__content {
  font-size: 18px;
  text-transform: capitalize;
}
</style>
