<template>
  <div>
    <div class="box-with-background">
      <span class="text-apsto-purple">School Year:&nbsp;</span>
      {{ schoolYearName }}
    </div>
    <div class="mt-2 box-with-background">
      {{ applicationMember.name }} | Family:
      {{ applicationMember.application.household.name }}
      <div>
        Enrolled in {{ schoolStudent.grade_name }} during school year {{ schoolYearName }}
      </div>
      <div>
        Currently {{ applicationMember.age | formatNumber }} years old |
        Birthdate:
        {{ displayDate(applicationMember.birth_date) }}
      </div>
    </div>
    <div
      v-if="qualifiesForAScholarship"
      class="mt-2 box-with-background"
    >
      <div class="text-apsto-purple">Eligibility This School Year
        ({{ schoolYearName }})
      </div>
      <div class="mt-2">
        <div v-if="applicationStudent.qualifies_for_disabled_displaced">
          Disabled/Displaced Tax Credit Scholarship (Lexie's Law)
        </div>
        <div v-if="applicationStudent.qualifies_for_low_income_corporate">
          Low Income Corporate Tax Credit Scholarship
        </div>
        <div v-if="applicationStudent.qualifies_for_original">
          Original Tax Credit Scholarship
        </div>
        <div v-if="applicationStudent.qualifies_for_switcher">
          Switcher Tax Credit Scholarship
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentShow",
  props: {
    applicationMember: {
      type: Object,
      required: true
    },
    applicationStudent: {
      type: Object,
      required: true
    },
    schoolStudent: {
      type: Object,
      required: true
    },
    schoolYearName: {
      type: String,
      required: true
    }
  },
  computed: {
    qualifiesForAScholarship() {
      return this.applicationStudent.qualifies_for_disabled_displaced ||
        this.applicationStudent.qualifies_for_low_income_corporate ||
        this.applicationStudent.qualifies_for_original ||
        this.applicationStudent.qualifies_for_switcher;
    }
  },
}
</script>

<style scoped>
.box-with-background {
  background-color: var(--apsto-teal);
  color: #ffffff;
  font-weight: bold;
  padding: 20px;
}
</style>
