<template>
  <loading v-if="loading"/>
  <div v-else>

    <v-row>
      <v-col>
        <school-header page-title="Invite Another School Admin"/>
      </v-col>
    </v-row>

    <v-row class="mt-n3 mt-md-0">
      <v-col
        class="mx-2 mx-md-4"
        md="6"
      >
        <v-text-field
          id="email"
          v-model.trim="invite.email"
          :error="!!errors.email"
          :error-messages="errors.email ? errors.email : null"
          background-color="white"
          filled
          label="Email of The Person to Invite"
        />
        <v-text-field
          id="phone"
          v-model.trim="invite.phone"
          :error="!!errors.phone"
          :error-messages="errors.phone ? errors.phone : null"
          background-color="white"
          filled
          label="Their Contact Phone Number"
          @input="phoneNumber"
        />
        <v-text-field
          id="title"
          v-model.trim="invite.title"
          :error="!!errors.title"
          :error-messages="errors.title ? errors.title : null"
          background-color="white"
          filled
          label="Their Title at Your School"
        />
        <div>
          <v-btn
            :block="isMobile"
            :to="{ name: 'school-admins-list', params: { school_id: $route.params.school_id } }"
            class="cancel-button"
            rounded
          >
            Cancel
          </v-btn>
          <v-btn
            :block="isMobile"
            :class="isMobile ? 'mt-5' : 'ml-2'"
            class="save-by-cancel-button"
            color="primary"
            rounded
            @click="submit"
          > Send
          </v-btn>
        </div>

      </v-col>
    </v-row>
  </div>
</template>

<script>

import Loading from "../../../components/shared/Loading";
import SchoolHeader from "../../../components/school/SchoolHeader";
import {POST_INVITE} from "../../../store/actions.type";
import HouseholdHeader from "../../../components/household/HouseholdHeader.vue";

export default {
  name: "SchoolAdminInvite",
  components: {
    HouseholdHeader,
    SchoolHeader,
    Loading
  },
  data() {
    return {
      loading: true,
      invite: {
        model_id: this.$route.params.school_id,
        model_type: 'School'
      },
    }
  },
  computed: {
    error() {
      return this.$store.getters.getMessage("invites");
    },
    errors() {
      return this.$store.getters.getErrors("invites");
    },
  },
  methods: {
    phoneNumber() {
      var x = this.invite.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.invite.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    submit() {
      this.saving = true;
      let invitable_id = this.$route.params.school_id;
      let invitable_type = 'School';
      let invite = this.invite;
      this.$store.dispatch(POST_INVITE, {invitable_id, invitable_type, invite})
        .then(() => {
          this.saving = false;
          this.$router.push({
            name: 'school-admins-list', params: {school_id: invitable_id}
          })
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.loading = false;
  }
}

</script>
