import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    ADMIN_CHECK_REGISTERED,
    ADMIN_REGISTER_USER,
    DELETE_ADMIN_DEPOSIT,
    DELETE_CORPORATE_PLEDGE_PAYMENT,
    DELETE_DEPOSIT_AWARD_REFUND,
    DELETE_HOUSEHOLD_APPLICATION_ADMIN,
    DELETE_SALESPERSON,
    DELETE_SALESPERSON_ASSIGNMENT,
    DELETE_SCHOOL_STUDENT_AWARD,
    DELETE_SITE_DOCUMENT,
    DELETE_SITE_WALLPAPER,
    DOWNLOAD_FUND_TRANSACTIONS,
    GET_ADMIN_CORPORATE_PLEDGES,
    GET_ADMIN_DEPOSIT,
    GET_ADMIN_DEPOSIT_AWARD_REFUNDS,
    GET_ADMIN_DEPOSITS,
    GET_ADMIN_DONOR_DONATIONS,
    GET_ADMIN_DONOR_DONATIONS_RECENT,
    GET_ADMIN_HOUSEHOLD,
    GET_ADMIN_HOUSEHOLD_APPLICATIONS,
    GET_ADMIN_HOUSEHOLD_MEMBERS,
    GET_ADMIN_HOUSEHOLDS,
    GET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS,
    GET_ADMIN_SCHOOL_STUDENT_AWARD,
    GET_ADMIN_SCHOOL_STUDENT_AWARDS, GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD,
    GET_ADMIN_USERS,
    GET_APPLICATION_BY_APPLICATION_MEMBER_ID,
    GET_AWARDS_BY_SCHOOL_AND_PERIOD,
    GET_COMPANIES_IN_EMPLOYEE_DONATIONS_PROGRAM,
    GET_CORPORATE_PLEDGE_ADMIN,
    GET_CORPORATE_PLEDGE_PAYMENTS,
    GET_CORPORATE_PLEDGES_ADMIN,
    GET_FUND_SECTION_DONATIONS,
    GET_FUND_SECTION_RECOMMENDATIONS,
    GET_FUND_SECTION_STUDENT_AWARDS,
    GET_FUND_SECTIONS,
    GET_FUND_TRANSACTIONS,
    GET_HOUSEHOLD_APPLICATION_AS_ADMIN,
    GET_PENDING_STUDENT_AWARDS,
    GET_PENDING_STUDENT_AWARDS_COUNT,
    GET_RECENT_STUDENT_AWARDS,
    GET_REVISIONS,
    GET_SALESPEOPLE,
    GET_SALESPERSON,
    GET_SALESPERSON_ASSIGNMENT,
    GET_SALESPERSON_ASSIGNMENTS, GET_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD,
    GET_SITE_DOCUMENT,
    GET_SITE_DOCUMENTS,
    GET_SITE_DOCUMENTS_BY_SLUGS,
    GET_SITE_WALLPAPER,
    GET_SITE_WALLPAPERS,
    GET_SITE_WALLPAPERS_BY_SLUGS,
    GET_STUDENT_AWARDS_BY_DATE_RANGE,
    GET_STUDENT_PERMANENTS,
    GET_UNLOCKED_STUDENT_AWARDS_COUNT,
    GET_USER_LIST,
    MARK_REVISIONS_VIEWED,
    PATCH_ADMIN_DEPOSIT,
    PATCH_COMPANY_EMPLOYEE_DONATIONS_FREQUENCY,
    PATCH_COMPANY_EMPLOYEE_DONATIONS_PARTICIPATION,
    PATCH_CORPORATE_PLEDGE_ADMIN,
    PATCH_CORPORATE_PLEDGE_PAYMENT,
    PATCH_HOUSEHOLD_APPLICATION_STATUS,
    PATCH_SALESPERSON,
    PATCH_SALESPERSON_ASSIGNMENT,
    PATCH_SCHOOL_STUDENT_AWARD,
    PATCH_SITE_DOCUMENT,
    PATCH_SITE_WALLPAPER_STATUS,
    PATCH_STUDENT_DOCUMENT_ADMIN,
    POST_ADMIN_BANK_REPORT_DATES,
    POST_ADMIN_CORPORATE_PLEDGES_REPORT_DATES,
    POST_ADMIN_DEPOSIT,
    POST_ADMIN_DONOR_DONATIONS_REPORT_DATES,
    POST_ADMIN_STUDENT_AWARDS_REPORT_DATES, POST_ADOR_APPROVAL_DOCUMENT_UPLOAD,
    POST_AWARD_REFUNDS_TO_DEPOSIT,
    POST_CORPORATE_PLEDGE_CARD_PAYMENT,
    POST_CORPORATE_PLEDGE_PAYMENT,
    POST_DONOR_DONATION_PAYMENT_BY_CHECK,
    POST_FUND_TRANSACTION_REPORT_DATES,
    POST_SALESPERSON,
    POST_SALESPERSON_ASSIGNMENT, POST_SCHOOL_APPLICATION_DOCUMENT_UPLOAD,
    POST_SCHOOL_STUDENT_AWARD, POST_SCHOOL_STUDENT_AWARDS_PERIOD,
    POST_SITE_DOCUMENT,
    POST_SITE_WALLPAPER,
    UPDATE_SITE_DOCUMENTS,
    UPLOAD_SITE_WALLPAPER
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    APPEND_CORPORATE_PLEDGE_PAYMENT,
    CLEAR_ADMIN_DEPOSIT_AWARD_REFUNDS,
    CLEAR_ADMIN_HOUSEHOLD_MEMBERS,
    CLEAR_AWARDS_BY_SCHOOL_AND_PERIOD,
    CLEAR_ERRORS,
    CLEAR_PENDING_STUDENT_AWARDS,
    CLEAR_RECENT_STUDENT_AWARDS,
    REMOVE_CORPORATE_PLEDGE_PAYMENT,
    SET_ADMIN_BANK_REPORT_DATES,
    SET_ADMIN_CORPORATE_PLEDGES,
    SET_ADMIN_CORPORATE_PLEDGES_REPORT_DATES,
    SET_ADMIN_DEPOSIT,
    SET_ADMIN_DEPOSIT_AWARD_REFUNDS,
    SET_ADMIN_DEPOSITS,
    SET_ADMIN_DONOR_DONATIONS,
    SET_ADMIN_DONOR_DONATIONS_REPORT_DATES,
    SET_ADMIN_HOUSEHOLD_APPLICATIONS,
    SET_ADMIN_HOUSEHOLD_MEMBERS,
    SET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS,
    SET_ADMIN_SCHOOL_STUDENT_AWARD,
    SET_ADMIN_SCHOOL_STUDENT_AWARDS, SET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD,
    SET_ADMIN_USERS,
    SET_APPLICATION_BY_APPLICATION_MEMBER_ID,
    SET_AWARDS_BY_SCHOOL_AND_PERIOD,
    SET_COMPANIES,
    SET_CORPORATE_PLEDGE,
    SET_CORPORATE_PLEDGE_PAYMENTS,
    SET_CORPORATE_PLEDGES,
    SET_ADOR_APPROVAL_DOCUMENT_UPLOAD,
    SET_ERROR,
    SET_FUND_SECTION_DONATIONS,
    SET_FUND_SECTION_RECOMMENDATIONS,
    SET_FUND_SECTION_STUDENT_AWARDS,
    SET_FUND_SECTIONS,
    SET_FUND_TRANSACTION_REPORT_DATES,
    SET_FUND_TRANSACTIONS,
    SET_HOUSEHOLD,
    SET_HOUSEHOLD_APPLICATION,
    SET_HOUSEHOLD_APPLICATION_DOCUMENT,
    SET_HOUSEHOLDS,
    SET_PENDING_STUDENT_AWARDS,
    SET_PENDING_STUDENT_AWARDS_COUNT,
    SET_RECENT_STUDENT_AWARDS,
    SET_REVISIONS,
    SET_SALESPEOPLE,
    SET_SALESPERSON,
    SET_SALESPERSON_ASSIGNMENT,
    SET_SALESPERSON_ASSIGNMENTS,
    SET_SITE_DOCUMENT,
    SET_SITE_DOCUMENT_SLUGS,
    SET_SITE_DOCUMENTS,
    SET_SITE_WALLPAPER,
    SET_SITE_WALLPAPER_SLUGS,
    SET_SITE_WALLPAPERS,
    SET_STUDENT_PERMANENTS,
    SET_UNLOCKED_STUDENT_AWARDS_COUNT,
    SET_USER_LIST,
    UPDATE_CORPORATE_PLEDGE_PAYMENT,
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        adminBankReportDates: {},
        adminCorporatePledges: [],
        adminHouseholdApplication: {},
        adminHouseholdApplications: [],
        adminHouseholdMembers: [],
        adminDeposits: [],
        adminDeposit: {},
        adminDepositAwardRefunds: [],
        adminSchoolStudentAvailableAwards: [],
        adminSchoolStudentAwardsLatestPeriod: {},
        adminSchoolStudentAwards: [],
        adminSchoolStudentAward: {},
        adminUsers: [],
        applicationData: {},
        awardsBySchoolAndPeriod: [],
        corporatePledge: {},
        corporatePledgePayments: [],
        corporatePledgesReportDates: {},
        corporatePledges: [],
        donorDonations: [],
        donorDonationsReportDates: {},
        fundSectionDonations: [],
        fundSectionRecommendations: [],
        fundSectionStudentAwards: [],
        fundSections: [],
        fundTransaction: {},
        fundTransactionReportDates: {},
        fundTransactions: [],
        householdApplicationDocument: {},
        pendingAwards: [],
        pendingAwardsCount: 0,
        depositSources: [
            {id: 1, value: "Amazon Smile"},
            {id: 5, value: "Benevity"},
            {id: 2, value: "Blackbaud"},
            {id: 4, value: "Cybergrants"},
            {id: 3, value: "Double The Donation"},
        ],
        depositTypes: [
            {id: 1, value: "Award Refund"},
            {id: 2, value: "Other"},
        ],
        recentAwards: [],
        revisions: [],
        salespeople: [],
        salesperson: {},
        salespersonAssignment: {},
        salespersonAssignments: [],
        siteDocument: {},
        siteDocumentSlugs: [],
        siteDocuments: [],
        siteWallpaper: {},
        siteWallpaperSlugs: [],
        siteWallpapers: [],
        studentPermanents: [],
        taxCreditTypes: [
            {id: 1, name: "Disabled/Displaced Tax Credit Scholarship (Lexie's Law)"},
            {id: 2, name: "Low Income Corporate Tax Credit Scholarship"},
            {id: 3, name: "Original Tax Credit Scholarship"},
            {id: 4, name: "Switcher (Overflow/Plus) Tax Credit Scholarship"},
            {id: 5, name: "Charitable Contribution"},
        ],
        unlockedAwardsCount: 0,
        userList: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentAdminHouseholdApplications(state) {
        return state.adminHouseholdApplications;
    },
    currentAdminHouseholdMembers(state) {
        return state.adminHouseholdMembers;
    },
    currentAdminCorporatePledges(state) {
        return state.adminCorporatePledges;
    },
    currentAdminDeposits(state) {
        return state.adminDeposits;
    },
    currentAdminDeposit(state) {
        return state.adminDeposit;
    },
    currentAdminDepositAwardRefunds(state) {
        return state.adminDepositAwardRefunds;
    },
    currentApplicationData(state) {
        return state.applicationData;
    },
    currentAwardsBySchoolAndPeriod(state) {
        return state.awardsBySchoolAndPeriod;
    },
    currentCorporatePledgePayments(state) {
        return state.corporatePledgePayments;
    },
    currentAdminDonorDonations(state) {
        return state.donorDonations;
    },
    currentAdminUsers(state) {
        return state.adminUsers;
    },
    currentAdminPendingStudentAwards(state) {
        return state.pendingAwards;
    },
    currentAdminPendingStudentAwardsCount(state) {
        return state.pendingAwardsCount;
    },
    currentAdminRecentStudentAwards(state) {
        return state.recentAwards;
    },
    currentAdminSchoolStudentAvailableAwards(state) {
        return state.adminSchoolStudentAvailableAwards;
    },
    currentAdminSchoolStudentAwards(state) {
        return state.adminSchoolStudentAwards;
    },
    currentAdminUnlockedStudentAwardsCount(state) {
        return state.unlockedAwardsCount;
    },
    currentFundSectionDonations(state) {
        return state.fundSectionDonations;
    },
    currentFundSectionRecommendations(state) {
        return state.fundSectionRecommendations;
    },
    currentFundSectionStudentAwards(state) {
        return state.fundSectionStudentAwards;
    },
    currentFundSections(state) {
        return state.fundSections;
    },
    currentFundTransactions(state) {
        return state.fundTransactions;
    },
    currentReceiptSources(state) {
        return state.depositSources;
    },
    currentReceiptTypes(state) {
        return state.depositTypes;
    },
    currentRevisionsFiltered: (state) => (viewedStatus) => {

        viewedStatus = viewedStatus === false ? 0 : 1;

        return viewedStatus ? state.revisions : state.revisions.filter(revision => revision.viewed === viewedStatus);

    },
    currentSalespeople(state) {
        return state.salespeople;
    },
    currentSalesperson(state) {
        return state.salesperson;
    },
    currentSalespersonAssignments(state) {
        return state.salespersonAssignments;
    },
    currentSalespersonAssignment(state) {
        return state.salespersonAssignment;
    },
    currentSiteDocuments(state) {
        return state.siteDocuments;
    },
    currentSiteDocumentSlugs(state) {
        return state.siteDocumentSlugs;
    },
    currentSiteWallpapers(state) {
        return state.siteWallpapers;
    },
    currentSiteWallpaperSlugs(state) {
        return state.siteWallpaperSlugs;
    },
    currentStudentPermanents(state) {
        return state.studentPermanents;
    },
    currentTaxCreditTypes(state) {
        return state.taxCreditTypes;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [ADMIN_CHECK_REGISTERED](context, user) {
        console.log("ADMIN CHECK_REGISTERED");
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/users/check-registered`, user)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [ADMIN_REGISTER_USER](context, user) {
        console.log("ADMIN REGISTER USER");
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/users/register`, user)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_USERS](context, {page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/users`, {page, itemsPerPage, sortBy, sortDesc, search})
                .then(({data}) => {
                    console.log("GET_ADMIN_USERS: ", data)
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_USERS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_DONOR_DONATIONS_RECENT](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/donor-donations/recent`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DONOR_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_DONOR_DONATIONS](context, {date_from, date_thru}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/donor-donations/report-transactions/${date_from}/${date_thru}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DONOR_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_ADMIN_CORPORATE_PLEDGES_REPORT_DATES](context, dates) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/corporate-pledges/report-dates`, dates)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_CORPORATE_PLEDGES_REPORT_DATES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_CORPORATE_PLEDGES](context, {year_from, year_thru}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/corporate-pledges/report-transactions/${year_from}/${year_thru}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_CORPORATE_PLEDGES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_ADMIN_BANK_REPORT_DATES](context, dates) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/banks/report-dates`, dates)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_BANK_REPORT_DATES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_ADMIN_DONOR_DONATIONS_REPORT_DATES](context, dates) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/donor-donations/report-dates`, dates)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DONOR_DONATIONS_REPORT_DATES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_ADMIN_STUDENT_AWARDS_REPORT_DATES](context, dates) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/student-awards/report-dates`, dates)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DONOR_DONATIONS_REPORT_DATES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_HOUSEHOLDS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/households`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_HOUSEHOLD](context, household_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/households/${household_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATION_AS_ADMIN](context, {household_id, application_id}) {
        console.log("GET_HOUSEHOLD_APPLICATION_AS_ADMIN", household_id, application_id);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/households/${household_id}/applications/${application_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [GET_ADMIN_HOUSEHOLD_MEMBERS](context, household_id) {
        console.log("GET_ADMIN_HOUSEHOLD_MEMBERS");
        context.commit(CLEAR_ADMIN_HOUSEHOLD_MEMBERS);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/households/${household_id}/members`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_HOUSEHOLD_MEMBERS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_COMPANIES_IN_EMPLOYEE_DONATIONS_PROGRAM](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/admin/companies/unpaid-employee-donations")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANIES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_CORPORATE_PLEDGES_ADMIN](context, {page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/corporate-pledges`, {page, itemsPerPage, sortBy, sortDesc, search})
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CORPORATE_PLEDGES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_CORPORATE_PLEDGE_ADMIN](context, corporate_pledge_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/corporate-pledges/${corporate_pledge_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CORPORATE_PLEDGE, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_CORPORATE_PLEDGE_ADMIN](context) {
        let corporatePledge = context.state.corporatePledge;

        console.log("corporatePledge: ", corporatePledge);

        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/corporate-pledges/${corporatePledge.id}`, corporatePledge)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_CORPORATE_PLEDGE_PAYMENTS](context, corporate_pledge_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/corporate-pledges/${corporate_pledge_id}/payments`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CORPORATE_PLEDGE_PAYMENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_FUND_TRANSACTION_REPORT_DATES](context, dates) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/funds/report-dates`, dates)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FUND_TRANSACTION_REPORT_DATES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_FUND_SECTIONS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/funds/sections`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FUND_SECTIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_FUND_TRANSACTIONS](context, {date_from, date_thru}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/funds/report-transactions/${date_from}/${date_thru}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FUND_TRANSACTIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DOWNLOAD_FUND_TRANSACTIONS](context, {date_from, date_thru}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/funds/download-transactions/${date_from}/${date_thru}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SALESPEOPLE](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/salespeople`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SALESPEOPLE, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SALESPERSON](context, salesperson_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/salespeople/${salesperson_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SALESPERSON, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_HOUSEHOLD_APPLICATIONS](context, {page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/households/applications`, {page, itemsPerPage, sortBy, sortDesc, search})
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_HOUSEHOLD_APPLICATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_HOUSEHOLD_APPLICATION_ADMIN](context, {household_id, household_application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/households/${household_id}/applications/${household_application_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "householdApplication",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_HOUSEHOLD_APPLICATION_STATUS](context, {household_id, household_application_id, status}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/households/${household_id}/applications/${household_application_id}/status/${status}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SALESPERSON_ASSIGNMENTS](context, salesperson_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/salespeople/${salesperson_id}/assignments`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SALESPERSON_ASSIGNMENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SALESPERSON_ASSIGNMENT](context, {salesperson_id, assignment_id, assignment_type}) {
        console.log('GET SALESPERSON_ASSIGNMENT', salesperson_id, assignment_id, assignment_type)
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/salespeople/${salesperson_id}/assignments/${assignment_id}/${assignment_type}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SALESPERSON_ASSIGNMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SALESPERSON](context, salesperson) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/admin/salespeople", salesperson)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_SALESPERSON](context, salesperson) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/salespeople/${salesperson.id}`, salesperson)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_SALESPERSON](context, salesperson) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/salespeople/${salesperson.id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SALESPERSON_ASSIGNMENT](context, {salesperson_id, salespersonAssignment}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/salespeople/${salesperson_id}/assignments`, salespersonAssignment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_SALESPERSON_ASSIGNMENT](context, {salesperson_id, salespersonAssignment}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/salespeople/${salesperson_id}/assignments/${salespersonAssignment.id}/${salespersonAssignment.type}`, salespersonAssignment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_SALESPERSON_ASSIGNMENT](context, {salesperson_id, assignment_id, assignment_type}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/salespeople/${salesperson_id}/assignments/${assignment_id}/${assignment_type}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SITE_DOCUMENTS](context) {
        console.log(GET_SITE_DOCUMENTS);
        return new Promise((resolve, reject) => {
            ApiService.get("/api/admin/siteDocuments")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SITE_DOCUMENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SITE_DOCUMENTS_BY_SLUGS](context) {
        console.log(GET_SITE_DOCUMENTS_BY_SLUGS);
        if (context.state.siteDocumentSlugs.length) return context.state.siteDocumentSlugs;
        return new Promise((resolve, reject) => {
            ApiService.get("/api/admin/siteDocumentsBySlugs")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SITE_DOCUMENT_SLUGS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SITE_DOCUMENT](context, id) {
        console.log(GET_SITE_DOCUMENT);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/siteDocuments/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SITE_DOCUMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SITE_DOCUMENT](context, {file, site_document_slug, site_document_year}) {

        const formData = new FormData()
        formData.append('file', file);
        formData.append('site_document_slug', site_document_slug);
        formData.append('site_document_year', site_document_year);

        console.log("POST_SITE_DOCUMENT", file, site_document_slug, site_document_year)

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/siteDocuments/`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    //context.commit(SET_SITE_DOCUMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_SITE_DOCUMENT](context, company) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/siteDocuments/${company.id}`, company)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    //context.commit(SET_SITE_DOCUMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_SITE_DOCUMENT](context, company) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/siteDocuments/${company.id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SITE_WALLPAPERS](context, called_from) {
        console.log(GET_SITE_WALLPAPERS);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/siteWallpapers/${called_from}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SITE_WALLPAPERS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SITE_WALLPAPERS_BY_SLUGS](context) {
        console.log(GET_SITE_WALLPAPERS_BY_SLUGS);
        if (context.state.siteWallpaperSlugs.length) return context.state.siteWallpaperSlugs;
        return new Promise((resolve, reject) => {
            ApiService.get("/api/siteWallpapersBySlugs")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SITE_WALLPAPER_SLUGS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SITE_WALLPAPER](context, id) {
        console.log(GET_SITE_WALLPAPER);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/siteWallpapers/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SITE_WALLPAPER, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SITE_WALLPAPER](context, name) {

        const formData = new FormData()
        formData.append('name', name);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/siteWallpapers/`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [UPLOAD_SITE_WALLPAPER](context, {file, site_wallpaper_slug}) {

        const formData = new FormData()
        formData.append('file', file);
        formData.append('site_wallpaper_slug', site_wallpaper_slug);

        console.log("UPLOAD_SITE_WALLPAPER", file, site_wallpaper_slug)

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/siteWallpapers/image/`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_SITE_WALLPAPER_STATUS](context, site_wallpaper_id) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/siteWallpapers/${site_wallpaper_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_SITE_WALLPAPER](context, site_wallpaper_id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/siteWallpapers/${site_wallpaper_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_USER_LIST](context) {
        return new Promise((resolve, reject) => {
            console.log("GET_USER_LIST")
            ApiService.get(`/api/admin/impersonate`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_LIST, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_STUDENT_DOCUMENT_ADMIN](context, {application_document_id, application_student_id, action}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/households/applications/student/${application_student_id}/documents/${application_document_id}/action/${action}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_REVISIONS](context, {application_id, application_type}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/revisions/${application_id}/type/${application_type}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_REVISIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [MARK_REVISIONS_VIEWED](context, {application_id, application_type}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/revisions/${application_id}/type/${application_type}/viewed`)
                .then(({data}) => {
                    console.log("MARK_REVISIONS_VIEWED: ", data)
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_REVISIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_COMPANY_EMPLOYEE_DONATIONS_FREQUENCY](context, company) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/companies/${company.id}/employee-donations-frequency`, company)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_COMPANY_EMPLOYEE_DONATIONS_PARTICIPATION](context, company) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/companies/${company.id}/employee-donations-participation`, company)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_CORPORATE_PLEDGE_PAYMENT](context, {corporate_pledge_id, payment}) {
        console.log('POST_CORPORATE_PLEDGE_PAYMENT');
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/corporate-pledges/${corporate_pledge_id}/payment/`, payment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_CORPORATE_PLEDGE_PAYMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_CORPORATE_PLEDGE_CARD_PAYMENT](context, {corporate_pledge_id, payment}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/corporate-pledges/${corporate_pledge_id}/payment/card/`, payment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_CORPORATE_PLEDGE_PAYMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_CORPORATE_PLEDGE_PAYMENT](context, {corporate_pledge_id, payment}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/corporate-pledges/${corporate_pledge_id}/payment/${payment.id}`, payment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(UPDATE_CORPORATE_PLEDGE_PAYMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_CORPORATE_PLEDGE_PAYMENT](context, {corporate_pledge_id, payment_id}) {
        console.log("DELETE_CORPORATE_PLEDGE_PAYMENT");
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/corporate-pledges/${corporate_pledge_id}/payment/${payment_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_CORPORATE_PLEDGE_PAYMENT, payment_id);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_FUND_SECTION_DONATIONS](context, {year, month, page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/funds/section-donations/${year}/${month}`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FUND_SECTION_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_FUND_SECTION_RECOMMENDATIONS](context, {year, month, page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/funds/section-recommendations/${year}/${month}`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FUND_SECTION_RECOMMENDATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_FUND_SECTION_STUDENT_AWARDS](context, {year, month, page, itemsPerPage, sortBy, sortDesc, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/funds/section-student-awards/${year}/${month}`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FUND_SECTION_STUDENT_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL_STUDENT_AWARDS](context, {page, itemsPerPage, sortBy, sortDesc, search, school_id}) {
        return new Promise((resolve, reject) => {
            // AdminApplicationStudentAwardController::class, 'getStudentAwards'
            ApiService.post(`/api/admin/schools/${school_id}/students/awards`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search,
                school_id
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL_STUDENT_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL_STUDENT_AWARD](context, {school_id, application_student_award_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/students/awards/${application_student_award_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL_STUDENT_AWARD, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_PENDING_STUDENT_AWARDS](context, {page, itemsPerPage, sortBy, sortDesc, search}) {
        context.commit(CLEAR_PENDING_STUDENT_AWARDS);
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/student-awards/pending`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_PENDING_STUDENT_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_PENDING_STUDENT_AWARDS_COUNT](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/student-awards/pending/count`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_PENDING_STUDENT_AWARDS_COUNT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_RECENT_STUDENT_AWARDS](context, {page, itemsPerPage, sortBy, sortDesc, search}) {
        context.commit(CLEAR_RECENT_STUDENT_AWARDS);
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/student-awards/recent`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_RECENT_STUDENT_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_STUDENT_AWARDS_BY_DATE_RANGE](context, {page, itemsPerPage, sortBy, sortDesc, dateFrom, dateThru, search}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/student-awards`, {
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                dateFrom,
                dateThru,
                search,
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_RECENT_STUDENT_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_DONOR_DONATION_PAYMENT_BY_CHECK](context, donation) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/donations/payment-by-check`, donation)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_SCHOOL_STUDENT_AWARD](context, form) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/schools/${form.school_id}/student-award`, form)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SCHOOL_STUDENT_AWARD](context, form) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/schools/${form.school_id}/student-award`, form)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_SCHOOL_STUDENT_AWARD](context, {school_id, application_student_award_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/schools/${school_id}/students/awards/${application_student_award_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [UPDATE_SITE_DOCUMENTS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/update-site-documents`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_UNLOCKED_STUDENT_AWARDS_COUNT](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/student-awards/unlocked/count`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_UNLOCKED_STUDENT_AWARDS_COUNT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_STUDENT_PERMANENTS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/student-permanents`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_STUDENT_PERMANENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_APPLICATION_BY_APPLICATION_MEMBER_ID](context, application_member_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/get-application-by-application-member-id/${application_member_id}}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_BY_APPLICATION_MEMBER_ID, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_DEPOSITS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/deposits`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DEPOSITS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_DEPOSIT](context, deposit_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/deposit/${deposit_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DEPOSIT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_ADMIN_DEPOSIT](context, deposit) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/deposit`, deposit)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DEPOSIT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_ADMIN_DEPOSIT](context, deposit) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/deposit/${deposit.id}`, deposit)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DEPOSIT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_DEPOSIT_AWARD_REFUNDS](context, deposit_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/deposit/${deposit_id}/awardrefunds`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DEPOSIT_AWARD_REFUNDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_AWARD_REFUNDS_TO_DEPOSIT](context, {deposit_id, awardRefundsToPost}) {
        console.log("POST_AWARD_REFUNDS_TO_DEPOSIT", deposit_id, awardRefundsToPost);
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/deposit/${deposit_id}/awardrefunds`, {
                refunds: awardRefundsToPost
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DEPOSIT_AWARD_REFUNDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_ADMIN_DEPOSIT](context, deposit) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/deposit/${deposit.id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_DEPOSIT_AWARD_REFUND](context, {deposit_id, award_refund_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/admin/deposit/${deposit_id}/awardrefunds/${award_refund_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_DEPOSIT_AWARD_REFUNDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_AWARDS_BY_SCHOOL_AND_PERIOD](context, {fiscal_year_id, school_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/get-awards-by-school-and-period/${fiscal_year_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_AWARDS_BY_SCHOOL_AND_PERIOD, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS](context, {application_student_school_id, fiscal_year_id, school_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/get-school-student-available-awards/${application_student_school_id}/${fiscal_year_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SCHOOL_STUDENT_AWARDS_PERIOD](context, form) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/student-awards/period`, form)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "adminSchools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/student-awards/latest-period`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_ADOR_APPROVAL_DOCUMENT_UPLOAD](context, {
        corporate_pledge_id,
        file
    }) {
        const formData = new FormData()
        formData.append('file', file);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/admin/corporate-pledges/${corporate_pledge_id}/files`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    let file = data.file;
                    context.commit(SET_ADOR_APPROVAL_DOCUMENT_UPLOAD, file);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'corporatePledges');
                    reject(error.response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_ADMIN_BANK_REPORT_DATES](state, data) {
        state.adminBankReportDates = data;
    },
    [SET_ADMIN_CORPORATE_PLEDGES](state, data) {
        state.adminCorporatePledges = data;
    },
    [SET_ADMIN_CORPORATE_PLEDGES_REPORT_DATES](state, data) {
        state.corporatePledgesReportDates = data;
    },
    [SET_ADMIN_DONOR_DONATIONS](state, data) {
        state.donorDonations = data;
    },
    [SET_ADMIN_DONOR_DONATIONS_REPORT_DATES](state, data) {
        state.donorDonationsReportDates = data;
    },
    [SET_ADMIN_HOUSEHOLD_APPLICATIONS](state, data) {
        state.adminHouseholdApplications = data;
    },
    [CLEAR_ADMIN_HOUSEHOLD_MEMBERS](state) {
        state.adminHouseholdMembers = [];
    },
    [SET_ADMIN_HOUSEHOLD_MEMBERS](state, data) {
        state.adminHouseholdMembers = data;
    },
    [SET_ADMIN_DEPOSITS](state, data) {
        console.log("SET_ADMIN_DEPOSITS: ", data);
        state.adminDeposits = data;
    },
    [SET_ADMIN_DEPOSIT](state, data) {
        console.log("SET_ADMIN_DEPOSIT: ", data);
        state.adminDeposit = data;
    },
    [SET_ADMIN_DEPOSIT_AWARD_REFUNDS](state, data) {
        state.adminDepositAwardRefunds = data;
    },
    [CLEAR_ADMIN_DEPOSIT_AWARD_REFUNDS](state) {
        state.adminDepositAwardRefunds = [];
    },
    [SET_ADMIN_SCHOOL_STUDENT_AWARDS](state, data) {
        state.adminSchoolStudentAwards = data;
    },
    [SET_ADMIN_SCHOOL_STUDENT_AWARD](state, data) {
        state.adminSchoolStudentAward = data;
    },
    [SET_ADMIN_USERS](state, data) {
        console.log("currentAdminUsers: ", data)
        state.adminUsers = data;
    },
    [SET_CORPORATE_PLEDGES](state, data) {
        state.corporatePledges = data;
    },
    [SET_CORPORATE_PLEDGE](state, data) {
        state.corporatePledge = data;
    },
    [SET_ADOR_APPROVAL_DOCUMENT_UPLOAD](state, data) {
        state.corporatePledge.files[0] = data;
    },
    [SET_CORPORATE_PLEDGE_PAYMENTS](state, data) {
        state.corporatePledgePayments = data;
    },
    [APPEND_CORPORATE_PLEDGE_PAYMENT](state, data) {
        state.corporatePledgePayments.push(data);
    },
    [UPDATE_CORPORATE_PLEDGE_PAYMENT](state, data) {
        let payments = state.corporatePledgePayments;
        for (let i = 0; i < payments.length; i++) {
            if (payments[i].id === data.id) {
                payments.splice(i, 1, data);
                return;
            }
        }
    },
    [REMOVE_CORPORATE_PLEDGE_PAYMENT](state, id) {
        let payments = state.corporatePledgePayments;
        for (let i = 0; i < payments.length; i++) {
            if (payments[i].id === id) {
                payments.splice(i, 1);
                return;
            }
        }
    },
    [SET_FUND_SECTION_DONATIONS](state, data) {
        state.fundSectionDonations = data;
    },
    [SET_FUND_SECTION_RECOMMENDATIONS](state, data) {
        state.fundSectionRecommendations = data;
    },
    [SET_FUND_SECTION_STUDENT_AWARDS](state, data) {
        state.fundSectionStudentAwards = data;
    },
    [SET_FUND_SECTIONS](state, data) {
        state.fundSections = data;
    },
    [SET_FUND_TRANSACTIONS](state, data) {
        state.fundTransactions = data;
    },
    [SET_FUND_TRANSACTION_REPORT_DATES](state, data) {
        state.fundTransactionReportDates = data;
    },
    [SET_HOUSEHOLD_APPLICATION_DOCUMENT](state, data) {
        state.householdApplicationDocument = data;
    },
    [CLEAR_PENDING_STUDENT_AWARDS](state) {
        state.pendingAwards = [];
    },
    [SET_PENDING_STUDENT_AWARDS](state, data) {
        state.pendingAwards = data;
    },
    [CLEAR_RECENT_STUDENT_AWARDS](state) {
        state.recentAwards = [];
    },
    [SET_RECENT_STUDENT_AWARDS](state, data) {
        state.recentAwards = data;
    },
    [SET_REVISIONS](state, data) {
        state.revisions = data;
    },
    [SET_SALESPEOPLE](state, data) {
        state.salespeople = data;
    },
    [SET_SALESPERSON](state, data) {
        state.salesperson = data;
    },
    [SET_SALESPERSON_ASSIGNMENTS](state, data) {
        state.salespersonAssignments = data;
    },
    [SET_SALESPERSON_ASSIGNMENT](state, data) {
        state.salespersonAssignment = data;
    },
    [SET_SITE_DOCUMENTS](state, data) {
        state.siteDocuments = data;
    },
    [SET_SITE_DOCUMENT_SLUGS](state, data) {
        state.siteDocumentSlugs = data;
    },
    [SET_SITE_DOCUMENT](state, data) {
        state.siteDocument = data;
    },
    [SET_SITE_WALLPAPERS](state, data) {
        state.siteWallpapers = data;
    },
    [SET_SITE_WALLPAPER_SLUGS](state, data) {
        state.siteWallpaperSlugs = data;
    },
    [SET_SITE_WALLPAPER](state, data) {
        state.siteWallpaper = data;
    },
    [SET_USER_LIST](state, data) {
        state.userList = data;
    },
    [SET_PENDING_STUDENT_AWARDS_COUNT](state, data) {
        state.pendingAwardsCount = data;
    },
    [SET_UNLOCKED_STUDENT_AWARDS_COUNT](state, data) {
        state.unlockedAwardsCount = data;
    },
    [SET_STUDENT_PERMANENTS](state, data) {
        state.studentPermanents = data;
    },
    [SET_APPLICATION_BY_APPLICATION_MEMBER_ID](state, data) {
        state.applicationData = data;
    },
    [SET_AWARDS_BY_SCHOOL_AND_PERIOD](state, data) {
        state.awardsBySchoolAndPeriod = data;
    },
    [CLEAR_AWARDS_BY_SCHOOL_AND_PERIOD](state) {
        state.awardsBySchoolAndPeriod = [];
    },
    [SET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS](state, data) {
        //Add the attribute apply_amount to the array of objects, if its not already set
        data.forEach(function (item) {
            if (!item.hasOwnProperty("apply_amount")) {
                item.apply_amount = 0;
            }
        });
        state.adminSchoolStudentAvailableAwards = data;
    },
    [SET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD](state, data) {
        state.adminSchoolStudentAwardsLatestPeriod = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
