<template>
    <loading v-if="loading" />
    <div v-else>
        <v-card>
            <v-row>
                <v-col>
                    <v-card-title class="mt-n2">Payments</v-card-title>
                    <v-card-subtitle>
                        <table>
                            <tr v-if="paymentsSum">
                                <td style="width: 115px;">Amount Paid:</td>
                                <td>{{ paymentsSum | formatCurrencyWithCents }}</td>
                            </tr>
                            <tr>
                                <td style="width: 115px;">Unpaid Balance:</td>
                                <td>{{ unpaidBalance | formatCurrencyWithCents }}</td>
                            </tr>
                        </table>
                    </v-card-subtitle>
                </v-col>
                <v-col>
                    <v-card-actions class="justify-end mr-5">
                        <v-btn
                            v-if="unpaidBalance"
                            class="ml-2 mt-5"
                            color="primary"
                            rounded
                            @click="createItem"
                        >
                            <v-icon left> mdi-plus-circle-outline</v-icon>
                            Record Payment
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="payments"
                sort-by="name"
            >
                <template v-slot:item.actions="{ item }">
                    <v-tooltip
                        bottom
                        color="secondary"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                :disabled="item.method === 'Card'"
                                class="mr-2"
                                v-bind="attrs"
                                @click="editItem(item)"
                                v-on="on"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Edit Payment</span>
                    </v-tooltip>
                    <v-icon
                        :disabled="item.method === 'Card'"
                        color="red"
                        @click="deleteItem(item)"
                    > mdi-delete
                    </v-icon>
                </template>
                <template #item.amount="{ item }">
                    <div>
                        {{ item.amount | formatCurrencyWithCents }}
                    </div>
                </template>
                <template #item.created_at="{ item }">
                    <div>
                        {{ displayDate(item.created_at) }}
                    </div>
                </template>
                <template v-slot:no-data>
                    <span class="text-apsto-teal"> No payments have been recorded. </span>
                </template>
            </v-data-table>
            <v-dialog
                v-model="addEditDialog"
                :fullscreen="!!isMobile"
                :max-width="dialogMaxWidth"
            >
                <payment
                    :edited-item="temporaryItem"
                    :unpaid-balance="unpaidBalance"
                    called-from="CorporatePledge"
                    @done="addEditDialog = false"
                />
            </v-dialog>
            <v-dialog
                v-model="dialogDelete"
                :fullscreen="!!isMobile"
                :max-width="dialogMaxWidth"
            >
                <v-card>
                    <v-card-title class="text-h5-mulish"> Confirm</v-card-title>
                    <v-card-text
                        class="pa-4 pl-6 black--text"
                        style="font-size: 1.1rem"
                    >
                        Are you sure you want to delete this payment?
                    </v-card-text>
                    <v-card-actions class="pt-3 pb-4">
                        <v-spacer />
                        <v-btn
                            class="cancel-button"
                            rounded
                            @click="dialogDelete = false"
                        >Cancel
                        </v-btn>
                        <v-btn
                            class="save-by-cancel-button"
                            color="primary"
                            rounded
                            @click="deleteItemConfirm"
                        >OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>

    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import Payment from "../../../components/shared/Payment";
import { DELETE_CORPORATE_PLEDGE_PAYMENT, GET_CORPORATE_PLEDGE_PAYMENTS } from "../../../store/actions.type";

export default {
    name: "AdminCorporatePledgePaymentsTable",
    components: {
        Loading,
        Payment
    },
    data() {
        return {
            addEditDialog: false,
            dialogDelete: false,
            loading: true,
            temporaryItem: null,
        }
    },
    computed: {
        amountDue() {
            return parseFloat(this.corporatePledge.donation_amount);
        },
        corporatePledge() {
            return this.$store.getters.currentCorporatePledge;
        },
        defaultItem() {
            return {
                amount: null,
                check_date: null,
                transaction_id: null,
            };
        },
        headers() {
            return [
                { text: "Date Deposited", value: "check_date" },
                { text: "Method", value: "method" },
                { text: "Transaction #", value: "transaction_id" },
                { text: "Amount", value: "amount", align: 'right' },
                { text: "Actions", value: "actions", sortable: false },
            ]
        },
        paid() {
            if (this.paymentsSum === 0) {
                return "No";
            }
            if (this.paymentsSum === this.amountDue && this.amountDue > 0) {
                return "Yes";
            }
            return "Partially";
        },
        payments() {
            return this.$store.getters.currentCorporatePledgePayments;
        },
        paymentsSum() {
            return parseFloat(this.payments.reduce((total, obj) => parseFloat(obj.amount) + parseFloat(total), 0));
        },
        unpaidBalance() {
            return this.amountDue - this.paymentsSum;
        }
    },
    methods: {
        cancel() {
            this.$router.go(-1)
        },
        createItem() {
            this.temporaryItem = Object.assign({}, this.defaultItem);
            this.addEditDialog = true;
        },
        editItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.addEditDialog = true;
        },
        deleteItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            let corporate_pledge_id = this.$route.params.corporate_pledge_id;
            let payment_id = this.temporaryItem.id;
            this.$store.dispatch(DELETE_CORPORATE_PLEDGE_PAYMENT, { corporate_pledge_id, payment_id })
                .then(() => {
                    this.dialogDelete = false;
                });
        },
        getPayments() {
            let corporate_pledge_id = this.$route.params.corporate_pledge_id;
            this.$store.dispatch(GET_CORPORATE_PLEDGE_PAYMENTS, corporate_pledge_id)
                .then(() => {
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.getPayments();
    }
}
</script>

<style
  scoped>
  ::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background-color: transparent;
  }
</style>
