<template>
  <v-card>
    <v-card-title class="text-h5-mulish pt-3 pb-5">
      Upload Wallpaper
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="mt-2" cols="12">
          <v-form enctype="multipart/form-data">
            <v-card class="pa-4">
              <v-card-title class="pb-2 pl-0 pt-0">
                <site-wallpaper-selector v-model="slug"/>
              </v-card-title>
              <v-file-input
                v-model="file"
                :rules="rules"
                accept=".jpg,.jpeg,.pdf,.png"
                show-size
                truncate-length="15"
              />
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="py-4">
      <v-spacer/>
      <v-btn class="cancel-button" rounded @click="cancel"> Cancel</v-btn>
      <v-btn :disabled="saveDisabled" color="primary" @click="save"> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {GET_SITE_WALLPAPERS_BY_SLUGS, UPLOAD_SITE_WALLPAPER,} from "../../../store/actions.type";
import SiteWallpaperSelector from "../../../components/shared/SiteWallpaperSelector";

export default {
  name: "AdminSiteWallpaperFileUpload",
  components: {SiteWallpaperSelector},
  data: () => ({
    saving: false,
    file: null,
    rules: [
      value => !value || value.size < 20000000 || 'File size should be less than 20 MB!',
    ],
    showExplanation: false,
    slug: null,
  }),
  computed: {
    saveDisabled() {
      return !this.slug || !this.file;
    }
  },
  methods: {
    getSiteWallpapers() {
      this.$store.dispatch(GET_SITE_WALLPAPERS_BY_SLUGS);
    },
    cancel() {
      this.$emit("done");
    },
    save() {
      this.saving = true;
      let file = this.file;
      let site_wallpaper_slug = this.slug;
      this.$store
        .dispatch(UPLOAD_SITE_WALLPAPER, {
          file,
          site_wallpaper_slug,
        })
        .then(() => {
          this.saving = false;
          this.file = null;
          this.slug = null;
          this.$emit("done");
        })
    },
  },
  mounted() {
    this.getSiteWallpapers();
  }
};
</script>

<style scoped>

</style>
