<template>
    <v-select
        id="declinationReason"
        v-model="localDeclinationReason"
        :error="!!errors['reason_enum']"
        :error-messages="errors['reason_enum'] ? errors['reason_enum'] : null"
        :items="options"
        :label="label"
        filled
        item-text="name"
        item-value="id"
    >
    </v-select>
</template>

<script>

export default {
    name: "SchoolAwardDeclinationSelector",
    data() {
        return {
            options: [
                {
                    id: 5,
                    name: "Other",
                },
                {
                    id: 1,
                    name: "Student changed to a different private school",
                },
                {
                    id: 4,
                    name: "Student is ineligible",
                },
                {
                    id: 6,
                    name: "Student no longer attending any private school",
                },
                {
                    id: 2,
                    name: "Student now in ESA program",
                },
                {
                    id: 3,
                    name: "Student's tuition fully funded",
                },
            ]
        }
    },
    props: {
        label: {
            type: String,
            default: "",
            required: false
        },
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("schoolStudent");
        },
        errors() {
            return this.$store.getters.getErrors("schoolStudent");
        },
        localDeclinationReason: {
            get() {
                return this.value
            },
            set(localDeclinationReason) {
                this.$emit('input', localDeclinationReason)
            }
        }
    },
    mounted() {
        this.$emit('input', this.localDeclinationReason);
    },
};
</script>
