<template>
  <VEmojiPicker @select="select" />
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";

export default {
  name: "EmojiPicker",
  components: {
    VEmojiPicker,
  },
  methods: {
    select(emoji) {
      this.$emit('select',emoji.data);
    },
  },
};
</script>