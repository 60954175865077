<template>
  <div>
    <div class="table-header">Donations {{ period }}</div>
    <div class="sub-header">Note: Amounts shown are net of admin fees.</div>
    <v-data-table
      :footer-props="{ itemsPerPageOptions:[10,20,30] }"
      :headers="donationsHeaders"
      :items-per-page.sync="itemsPerPage"
      :items="donations"
      :loading="loading"
      :page.sync="page"
      :search="search"
      :server-items-length="serverItemsLength"
      :single-select="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @pagination="getDonations"
      @update:sort-by="getDonations"
      @update:sort-desc="getDonations"
      class="elevation-2 mt-3"
      fixed-header
      height="100vh"
      item-key="id"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              class="mx-4"
              label="Search on anything"
              prepend-inner-icon="mdi-magnify"
              outlined
              background-color="white"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
            <span class="text-apsto-teal">
              There are no fund sections on file for this time period.
            </span>
      </template>
      <template v-slot:body.prepend>
        <tr class="totals">
          <th colspan="2">Totals</th>
          <th class="text-right">{{ totalDonationCorporateDisabledDisplaced | formatCurrencyWithCents }}</th>
          <th class="text-right">{{ totalDonationCorporateLowIncome | formatCurrencyWithCents }}</th>
          <th class="text-right">{{ totalDonationOriginalTaxCredit | formatCurrencyWithCents }}</th>
          <th class="text-right">{{ totalDonationSwitcher | formatCurrencyWithCents }}</th>
          <th class="text-right">{{ totalDonationCharitable | formatCurrencyWithCents }}</th>
          <th class="text-right">{{ totalDonations | formatCurrencyWithCents }}</th>
        </tr>
      </template>
      <template #item.transaction_type="{item, index}">
        <div v-if="item.transaction_type === 1">
          Awards
        </div>
        <div v-else>
          Donations
        </div>
      </template>
      <template #item.corporate_disabled_displaced="{item, index}">
        <div v-if="index === 0">
          {{ item.corporate_disabled_displaced | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.corporate_disabled_displaced | formatNumberWithCents }}
        </div>
      </template>
      <template #item.corporate_low_income="{item, index}">
        <div v-if="index === 0">
          {{ item.corporate_low_income | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.corporate_low_income | formatNumberWithCents }}
        </div>
      </template>
      <template #item.original_tax_credit="{item, index}">
        <div v-if="index === 0">
          {{ item.original_tax_credit | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.original_tax_credit | formatNumberWithCents }}
        </div>
      </template>
      <template #item.switcher="{item, index}">
        <div v-if="index === 0">
          {{ item.switcher | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.switcher | formatNumberWithCents }}
        </div>
      </template>
      <template #item.charitable="{item, index}">
        <div v-if="index === 0">
          {{ item.charitable | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.charitable | formatNumberWithCents }}
        </div>
      </template>
      <template #item.total="{item, index}">
        <div v-if="index === 0">
          {{ total(item) | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ total(item) | formatNumberWithCents }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {GET_FUND_SECTION_DONATIONS} from "../../../store/actions.type";

export default {
  name: "DonationsTable",
  data() {
    return {
      loading: true,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 30,
      sortBy: 'donor_name',
      sortDesc: false,
      serverItemsLength: 0,
      donationsHeaders: [
        {text: 'Donor', align: 'start', value: 'donor_name'},
        {text: 'Type', value: 'donation_type'},
        {text: 'Displaced', align: 'end', value: 'corporate_disabled_displaced'},
        {text: 'Low Income', align: 'end', value: 'corporate_low_income'},
        {text: 'Original', align: 'end', value: 'original_tax_credit'},
        {text: 'Switcher', align: 'end', value: 'switcher'},
        {text: 'Charitable', align: 'end', value: 'charitable'},
        {text: 'Total', align: 'end', value: 'total'},
      ],
    }
  },
  computed: {
    donations() {
      return this.$store.getters.currentFundSectionDonations['donations'];
    },
    period() {
      return "Made During " + this.$store.getters.currentFundSectionDonations['period'];
    },
    totalDonationCorporateDisabledDisplaced() {
      return this.$store.getters.currentFundSectionDonations['totalCorporateDisabledDisplaced'];
    },
    totalDonationCorporateLowIncome() {
      return this.$store.getters.currentFundSectionDonations['totalCorporateLowIncome'];
    },
    totalDonationCharitable() {
      return this.$store.getters.currentFundSectionDonations['totalCharitable'];
    },
    totalDonationOriginalTaxCredit() {
      return this.$store.getters.currentFundSectionDonations['totalOriginalTaxCredit'];
    },
    totalDonationSwitcher() {
      return this.$store.getters.currentFundSectionDonations['totalSwitcher'];
    },
    totalDonations() {
      return this.totalDonationCorporateDisabledDisplaced + this.totalDonationCorporateLowIncome + this.totalDonationCharitable + this.totalDonationOriginalTaxCredit + this.totalDonationSwitcher;
    },
  },
  methods: {
    total(item) {
      return parseFloat(item.corporate_disabled_displaced) +
        parseFloat(item.corporate_low_income) +
        parseFloat(item.charitable) +
        parseFloat(item.original_tax_credit) +
        parseFloat(item.switcher)
    },
    getDonations() {
      this.loading = true;
      let year = this.$route.params.year;
      let month = this.$route.params.month;
      let page = this.page;
      let itemsPerPage = this.itemsPerPage;
      let search = this.search;
      let sortBy = this.sortBy;
      let sortDesc = this.sortDesc;
      this.$store.dispatch(GET_FUND_SECTION_DONATIONS, {year, month, page, itemsPerPage, sortBy, sortDesc, search})
        .then((data) => {
          this.serverItemsLength = data.totalRecords;
          this.loading = false;
        })
    },
  },
  watch: {
    search() {
      this.page = 1;
      this.getDonations();
    }
  }
}
</script>

<style scoped>
.sub-header {
  color: var(--apsto-teal);
  font-size: 0.975rem;
  font-weight: bold;
  padding-bottom: 20px;
}

.table-header {
  font-size: 1.5rem;
  font-weight: bold;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.totals) {
  background-color: transparent;
}

::v-deep tr.totals {
  background-color: var(--apsto-teal) !important;
}

::v-deep tr.totals > th {
  color: white;
  font-size: .90rem !important;
}
</style>