<template>
  <v-card class="pb-5 dialog">
    <v-card-title class="pa-4">
      Create Link
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col>
          <div class="about">
            You are about to link a student named in a donor recommendation to a student found in a household
            application.
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col>
          <div class="text-h6-mulish">
            Student Name Provided By Donor
            <div class="font-weight-bold">{{ unlinkedStudentName }}</div>
          </div>
          <div class="mt-4">
            <div class="text-h6-mulish">
              Actual Student You Are About To Link To
              <div class="font-weight-bold">{{ studentName }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="py-6">
      <v-spacer/>
      <v-btn @click="cancel" class="cancel-button"> Stop</v-btn>
      <v-btn color="primary" @click="createLink" class="ml-2"> Create Link</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>


import {
  LINK_DONOR_STUDENT_RECOMMENDATION_TO_ACTUAL_STUDENT,
  LINK_MULTIPLE_DONOR_STUDENT_RECOMMENDATIONS_TO_ACTUAL_STUDENT
} from "../../../../../../store/actions.type";

export default {
  name: "AdminSchoolLinkStudentConfirm",
  props: {
    idToLinkTo: {
      required: true,
      type: Number
    },
    studentName: {
      required: true,
      type: String
    },
    unlinkedStudentName: {
      required: true,
      type: String
    },
    linkType: {
      required: true,
      type: String
    },
  },
  data: () => ({
    saving: false,
  }),
  computed: {
    unlinkedStudents() {
      return this.$store.getters.currentAdminUnlinkedStudentsToLink;
    },
    unlinkedStudentsIds() {
      //Return an array of IDs of the unlinked students
      return this.unlinkedStudents.map((student) => {
        return student.id;
      });
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },

  },
  methods: {
    cancel() {
      this.$emit("done");
    },
    createLink() {
      this.saving = true;
      if (this.linkType === "single") {
        this.createSingleLink();
      } else {
        this.createMultipleLink();
      }
    },
    createSingleLink() {
      let actual_student_id = this.idToLinkTo;
      let donor_selection_student_id = this.$route.params.donor_selection_student_id;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(LINK_DONOR_STUDENT_RECOMMENDATION_TO_ACTUAL_STUDENT, {
        actual_student_id,
        donor_selection_student_id,
        school_id
      })
        .then(() => {
          this.saving = false;
          this.$emit("done");
          this.$router.push(`/dashboard/admin/schools/${school_id}/edit`);
        })
        .catch((response) => {
          console.log("Error: ", response)
        });
    },
    createMultipleLink() {
      let actual_student_id = this.idToLinkTo;
      let donor_selection_students_ids = this.unlinkedStudentsIds;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(LINK_MULTIPLE_DONOR_STUDENT_RECOMMENDATIONS_TO_ACTUAL_STUDENT, {
        actual_student_id,
        donor_selection_students_ids,
        school_id
      })
        .then(() => {
          this.saving = false;
          this.$emit("done");
          this.$router.push(`/dashboard/admin/schools/${school_id}/edit`);
        })
        .catch((response) => {
          console.log("Error: ", response)
        });
    }
  },

};
</script>

<style scoped>
.errors-message {
  color: red;
  padding-left: 5px;
}

.about {
  background-color: var(--apsto-teal-light);
  font-size: 1rem;
  padding: 20px;
}
</style>
