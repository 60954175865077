<template>
  <v-card>
    <div
      v-if="!disabled"
      class="box-with-background mt-5"
    >
      <span class="text-h6-mulish font-weight-black">Please review your Donation Request below</span>
      <div class="box-in-box text-apsto-black">
        Click the Submit button when the data shown matches what you'd like us to submit to the
        Arizona Department of Revenue.
      </div>
    </div>
    <div class="pa-5">
      <v-row>
        <v-col
          class="text-h6-mulish font-weight-black"
          cols="auto"
        >
          Company Info
        </v-col>
        <v-col v-if="!disabled">
          <a
            v-if="calledFrom === 'company'"
            :href="'/dashboard/companies/' + company.id + '/edit'"
          >
            <v-icon color="secondary">mdi-circle-edit-outline</v-icon>
          </a>
          <a
            v-else
            @click="scrollUp"
          >
            <v-icon color="secondary">mdi-circle-edit-outline</v-icon>
          </a>
        </v-col>
      </v-row>
      <div class="text-h7-mulish mt-3">
        <span class="caption-col">Company Name:</span>
        <span class="data-col">{{ company.name }}</span>
      </div>
      <div class="text-h7-mulish mt-3">
        <span class="caption-col">Company Address:</span>
        <div v-if="company && company.address && company.address.street1">
          <div class="data-col mt-3">{{ company.address.street1 }}</div>
          <div class="data-col">{{ company.address.city }}, {{ stateIso }}
            {{ company.address.postal }}
          </div>
        </div>
      </div>
      <div class="text-h7-mulish mt-3">
        <span class="caption-col">Company Type:</span>
        <span class="data-col">{{ companyOrganizationTypeName }}</span>
      </div>
      <div class="text-h7-mulish mt-3">
        <span class="caption-col">Parent S Corporation:</span>
        <span class="data-col">
                    {{ corporatePledge.subs_parent_info ? corporatePledge.subs_parent_info : 'N/A' }}
                </span>
      </div>
      <v-divider class="my-5"/>
      <v-row class="">
        <v-col
          class="text-h6-mulish font-weight-black"
          cols="auto"
        >
          Donation
        </v-col>
        <v-col v-if="!disabled">
          <a @click="scrollUp">
            <v-icon color="secondary">mdi-circle-edit-outline</v-icon>
          </a>
        </v-col>
      </v-row>
      <div class="text-h7-mulish mt-3">
        <span class="caption-col">Tax Credit Type:</span>
        <span class="data-col">{{ taxCreditTypeName }}</span>
      </div>
      <div class="text-h7-mulish mt-3">
        <span class="caption-col">Donation Amount:</span>
        <span class="data-col">{{ corporatePledge.amount | formatCurrency }}</span>

        <div class="text-h7-mulish mt-3">
          <span class="caption-col">Corporate EIN:</span>
          <span class="data-col">{{ company.federal_ein }}</span>
        </div>
        <div class="text-h7-mulish mt-3">
          <span class="caption-col">NAICS Code for Insurance Companies:</span>
          <span class="data-col">{{ company.naics_code ? company.naics_code : 'N/A' }}</span>
        </div>
        <div class="mt-3">
          <span class="caption-col">School Recommendations (optional): </span>
          <table
            v-if="schoolsList.length"
            class="data-col mt-3"
          >
            <tr
              v-for="(school) in schoolsList"
              v-bind:key="school.id"
              class="mt-2"
            >

              <td class="text-right">
                {{ school.allocation_percentage }}%
              </td>
              <td class="pl-2">
                {{ school.name }}
              </td>
            </tr>
          </table>
          <div
            v-else
            class="mt-3 data-col"
          >
            None Chosen
          </div>
        </div>
        <div class="mt-3">
          <span class="caption-col">Comments (optional): </span>
          <span class="data-col">
                        {{
              corporatePledge.recommendations.comments ? corporatePledge.recommendations.comments : 'N/A'
            }}
                    </span>
        </div>
        <div
          v-if="corporatePledge.recommendations.recommendation_type === 2"
          class="mt-3"
        >
                    <span class="caption-col">
                        May we share your donation with your recommended schools?
                    </span>
          <span class="data-col">
                        {{ corporatePledge.recommendations.disclose === true ? 'Yes' : 'No' }}
                    </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>

export default {
  name: "CorporatePledgeReviewPanel",
  props: {
    calledFrom: {
      type: String,
      required: true,
    },
    company: {
      type: Object,
      required: true
    },
    corporatePledge: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    regions() {
      return this.$store.getters.currentRegions;
    },
    schoolsList() {
      let schools = this.corporatePledge.recommendations.schools;
      if (!schools) return [];

      // alphabetize schools list by name
      return schools.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });


      // return schools.map(school => {
      //   return {
      //     ...school,
      //     name: this.$store.getters.schoolName(school.id),
      //   }
      // });
    },
    stateIso() {
      console.log('company for state iso', this.company)
      if (!this.company) return;
      return this.$store.getters.currentRegionIso(this.company.address.region_id);
    },
    companyOrganizationTypeName() {
      console.log('company for org type name', this.company)
      if (!this.company) return;
      if (this.company.organization_type_name) {
        return this.company.organization_type_name;
      }

      if (!this.company.organization_type_enum) return;
      return this.$store.getters.organizationTypeName(this.company.organization_type_enum);
    },
    taxCreditInfo() {
      return this.$store.getters.taxCreditInfo;
    },
    taxCreditTypeName() {
      if (!this.corporatePledge.tax_credit_type) return;
      return this.corporatePledge.tax_credit_type === 1
        ? this.taxCreditInfo.disabilityTaxCreditName
        : this.taxCreditInfo.lowIncomeTaxCreditName;
    },
  },
  methods: {
    scrollUp() {
      window.scrollTo(0, 0);
    }
  },
  watch: {
    company() {
      //this.stateIso()
      //this.companyOrganizationTypeName()
    },
  },
}
</script>

<style scoped>
.box-in-box {
  background-color: var(--apsto-teal-very-light);
  padding: 10px;
  margin-top: 20px;
}

.caption-col {
  font-weight: normal;
}

.data-col {
  font-weight: bold;
}</style>
