<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row class="mt-2">
            <v-col class="mr-5 mt-n4" md="6">
                <v-form :disabled="saving">
                    <household-form :household="household"/>
                    <v-btn :to="{ name: 'households' }" class="cancel-button" rounded>
                        Cancel
                    </v-btn>
                    <v-btn class="ml-2 save-by-cancel-button" color="primary" rounded @click="submit">
                        Save
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import HouseholdForm from "../../../../components/household/HouseholdForm";

import {POST_HOUSEHOLD} from "../../../../store/actions.type";

export default {
    name: "HouseholdCreate",
    components: {
        Loading,
        HouseholdForm
    },
    data() {
        return {
            household: {
                address: {
                    street1: "",
                    street2: "",
                    city: "",
                    lat: "",
                    lon: "",
                    postal: "",
                    region_id: 3
                },
                name: "",
            },
            possibleFields: [
                "city",
                "name",
                "postal",
                "street1",
                "street2",
            ],
            loading: true,
            saving: false
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("households");
        },
        errors() {
            return this.$store.getters.getErrors("households");
        },
    },
    methods: {
        submit() {
            this.saving = true;
            this.$store.dispatch(POST_HOUSEHOLD, this.household)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: 'households'})
                })
                .catch(() => {
                    this.saving = false;
                    //Scroll to error
                    console.log("errors: ", this.errors);
                    this.possibleFields.every((field) => {
                        if (this.errors[field]) {
                            field = field.split('.').pop();
                            const el = document.getElementById(field);
                            if (el) {
                                el.scrollIntoView({block: "center"});
                                return false;
                            }
                        }
                        return true;
                    });
                });
        },
    },
    mounted() {
        this.loading = false;
    }
}
</script>

