<template>
    <loading v-if="loading"/>
    <div
        v-else
        class="mr-5"
    >
        <v-row
            align="center"
            class="ml-1 mt-5"
        >
            <v-col cols="auto">
                <admin-header page-title="Revise Donor Donation"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader.vue";
import AdminDeposit from "../../../components/admin/deposits/AdminDeposit.vue";
import Loading from "../../../components/shared/Loading.vue";
import {GET_ADMIN_DONOR_DONATIONS_IN_FISCAL_YEAR} from "../../../store/actions.type";

export default {
    name: "AdminDonorDonationRevise",
    components: {Loading, AdminDeposit, AdminHeader},
    data() {
        return {
            headers: [
                {text: 'Name', value: 'donor_name'},
                {text: 'Email', value: 'donor_email'},
                {text: 'Date', align: 'start', value: 'created_at'},
                {text: 'Type', align: 'start', value: 'type'},
                {text: 'Amount', align: 'end', value: 'amount'},
            ],
            loading: false,
            search: "",
        }
    },
    computed: {
        adminDonorDonations() {
            return this.$store.getters.currentAdminDonorDonations['donorDonations'];
        },
        currentYearStartDate() {
            return new Date(this.$store.getters.currentAdminDonorDonations['currentYearStartDate'].substring(0, 10));
        },
        priorYearIsClosed() {
            return this.$store.getters.currentAdminDonorDonations['priorYearIsClosed'];
        },
    },
    methods: {
        getDonorDonation() {
            // this.loading = true;
            // this.$store.dispatch(GET_ADMIN_DONOR_DONATION).then(() => {
            //     this.loading = false;
            // })
            //     .catch(error => {
            //         console.log(error);
            //         this.loading = false;
            //     });
        },
    },
    mounted() {
        this.getDonorDonation();
    }
}
</script>


<style scoped>

</style>
