<template>
    <v-card class="px-4 py-2">
        <v-row>
            <v-col md="6">
                <v-radio-group
                    v-model="schoolApplication.comports_to_law"
                    :error="!!errors['comports_to_law']"
                    :error-messages="errors['comports_to_law'] ? errors['comports_to_law'] : null
                        "
                    column
                    @change="
                        updateSchoolApplicationRadio(
                            'comports_to_law',
                            schoolApplication.comports_to_law
                        )
                        "
                >
                    <template v-slot:label>
                        <div class="text--primary darken-4 pb-2">
                            Is this a qualified school, a nongovernmental primary or secondary
                            school that does not discriminate on the basis of race, color,
                            disability, familial status or national origin and that satisfies
                            the requirements prescribed by law for private schools in Arizona?
                        </div>
                    </template>
                    <v-radio
                        :value="1"
                        color="secondary"
                        label="Yes"
                    />
                    <v-radio
                        :value="0"
                        color="secondary"
                        label="No"
                    />
                </v-radio-group>
                <v-radio-group
                    v-model="schoolApplication.fingerprints"
                    :error="!!errors['fingerprints']"
                    :error-messages="errors['fingerprints'] ? errors['fingerprints'] : null
                        "
                    column
                    @change="
                        updateSchoolApplicationRadio(
                            'fingerprints',
                            schoolApplication.fingerprints
                        )
                        "
                >
                    <template v-slot:label>
                        <div class="text--primary darken-4 pb-2">
                            Does this qualified school require all teaching staff and
                            personnel that have unsupervised contact with students to be
                            fingerprinted?
                        </div>
                    </template>
                    <v-radio
                        :value="1"
                        color="secondary"
                        label="Yes"
                    />
                    <v-radio
                        :value="0"
                        color="secondary"
                        label="No"
                    />
                    <transition
                        :duration="500"
                        name="slide-fade"
                    >
                        <div
                            v-if="schoolApplication.fingerprints"
                            class="mb-n8 mt-2"
                        >
                            <form>
                                <v-text-field
                                    id="fingerprints_provider"
                                    v-model.trim="schoolApplication.fingerprints_provider"
                                    :error="!!errors['fingerprints_provider']"
                                    :error-messages="errors['fingerprints_provider']
                                        ? errors['fingerprints_provider']
                                        : null
                                        "
                                    background-color="white"
                                    filled
                                    label="Name of the company / organization you use"
                                    @change="updateSchoolApplicationTextField()"
                                />
                            </form>
                        </div>
                    </transition>
                </v-radio-group>

                <v-radio-group
                    v-model="schoolApplication.publish_test_scores"
                    :error="!!errors['publish_test_scores']"
                    :error-messages="errors['publish_test_scores'] ? errors['publish_test_scores'] : null
                        "
                    column
                    @change="
                        updateSchoolApplicationRadio(
                            'publish_test_scores',
                            schoolApplication.publish_test_scores
                        )
                        "
                >
                    <template v-slot:label>
                        <div class="text--primary darken-4 pb-2">
                            Does this qualified school annually administer and make available
                            to the public the aggregate test scores of its students on a
                            nationally standardized norm-referenced achievement test?
                        </div>
                    </template>
                    <v-radio
                        :value="1"
                        color="secondary"
                        label="Yes"
                    ></v-radio>
                    <v-radio
                        :value="0"
                        color="secondary"
                        label="No"
                    ></v-radio>
                    <transition
                        :duration="500"
                        name="slide-fade"
                    >
                        <div
                            v-if="schoolApplication.publish_test_scores"
                            class="mb-n8 mt-2"
                        >
                            <form>
                                <v-text-field
                                    id="test_name"
                                    v-model.trim="schoolApplication.test_name"
                                    :error="!!errors['test_name']"
                                    :error-messages="errors['test_name'] ? errors['test_name'] : null
                                        "
                                    background-color="white"
                                    filled
                                    label="Name of the tests you use"
                                    @change="updateSchoolApplicationTextField()"
                                />
                                <v-text-field
                                    id="how_scores_published"
                                    v-model.trim="schoolApplication.how_scores_published"
                                    :error="!!errors['how_scores_published']"
                                    :error-messages="errors['how_scores_published']
                                        ? errors['how_scores_published']
                                        : null
                                        "
                                    background-color="white"
                                    class="mt-n2"
                                    filled
                                    label="How are these scores published?"
                                    @change="updateSchoolApplicationTextField()"
                                />
                            </form>
                        </div>
                    </transition>
                </v-radio-group>
                <v-radio-group
                    v-model="schoolApplication.charge_for_all_students"
                    :error="!!errors['charge_for_all_students']"
                    :error-messages="errors['charge_for_all_students']
                        ? errors['charge_for_all_students']
                        : null
                        "
                    column
                    @change="
                        updateSchoolApplicationRadio(
                            'charge_for_all_students',
                            schoolApplication.charge_for_all_students
                        )
                        "
                >
                    <template v-slot:label>
                        <div class="text--primary darken-4 pb-2">
                            Does this qualified school charge tuition for all students
                            (notwithstanding any types of scholarships or discounts it may
                            offer)?
                        </div>
                    </template>
                    <v-radio
                        :value="1"
                        color="secondary"
                        label="Yes"
                    ></v-radio>
                    <v-radio
                        :value="0"
                        color="secondary"
                        label="No"
                    ></v-radio>
                </v-radio-group>

                <div class="text--primary my-4 pt-2">
                    We would like to be eligible to receive scholarships in the following
                    tax credit programs:
                </div>
                <v-checkbox
                    v-model="schoolApplication.disabled_displaced"
                    :error="!!errors['disabled_displaced']"
                    :error-messages="errors['disabled_displaced'] ? errors['disabled_displaced'] : null
                        "
                    color="secondary"
                    @change="updateSchoolApplicationCheckbox('disabled_displaced')"
                >
                    <template v-slot:label>
                        <div>Disabled/Displaced Tax Credit Scholarship</div>
                    </template>
                </v-checkbox>
                <v-checkbox
                    v-model="schoolApplication.low_income_corporate"
                    :error="!!errors['low_income_corporate']"
                    :error-messages="errors['low_income_corporate']
                        ? errors['low_income_corporate']
                        : null
                        "
                    color="secondary"
                    @change="updateSchoolApplicationCheckbox('low_income_corporate')"
                >
                    <template v-slot:label>
                        <div>Low-Income Corporate Tax Credit Scholarship</div>
                    </template>
                </v-checkbox>
                <v-checkbox
                    v-model="schoolApplication.original"
                    :error="!!errors['original']"
                    :error-messages="errors['original'] ? errors['original'] : null"
                    color="secondary"
                    @change="updateSchoolApplicationCheckbox('original')"
                >
                    <template v-slot:label>
                        <div>Original Tax Credit Scholarship</div>
                    </template>
                </v-checkbox>
                <v-checkbox
                    v-model="schoolApplication.switcher"
                    :error="!!errors['switcher']"
                    :error-messages="errors['switcher'] ? errors['switcher'] : null"
                    color="secondary"
                    @change="updateSchoolApplicationCheckbox('switcher')"
                >
                    <template v-slot:label>
                        <div>Switcher (Overflow/Plus) Tax Credit Scholarship</div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import SchoolSelector from "../../shared/SchoolSelector";
import {
    PATCH_SCHOOL_APPLICATION_CHECKBOX,
    PATCH_SCHOOL_APPLICATION_RADIO,
    PATCH_SCHOOL_APPLICATION_TEXT_FIELD,
} from "../../../store/actions.type";

export default {
    name: "SchoolQuestionnaire",
    components: {
        SchoolSelector,
    },
    data() {
        return {
            column: null,
            row: null,
        };
    },
    computed: {
        schoolApplication() {
            return this.$store.getters.currentSchoolApplication;
        },
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
    },
    methods: {
        updateSchoolApplicationCheckbox(field_name) {
            this.saving = true;
            let school_application_id = this.$route.params.application_id;
            let school_id = this.$route.params.school_id;
            this.$store
                .dispatch(PATCH_SCHOOL_APPLICATION_CHECKBOX, {
                    school_id,
                    school_application_id,
                    field_name,
                })
                .then(() => {
                    this.saving = false;
                });
        },
        updateSchoolApplicationRadio(field_name, field_value) {
            this.saving = true;
            let school_application_id = this.$route.params.application_id;
            let school_id = this.$route.params.school_id;
            this.$store
                .dispatch(PATCH_SCHOOL_APPLICATION_RADIO, {
                    school_id,
                    school_application_id,
                    field_name,
                    field_value,
                })
                .then(() => {
                    this.saving = false;
                });
        },
        updateSchoolApplicationTextField() {
            this.saving = true;
            let school_application_id = this.$route.params.application_id;
            let school_id = this.$route.params.school_id;
            let schoolApplication = this.schoolApplication;
            this.$store
                .dispatch(PATCH_SCHOOL_APPLICATION_TEXT_FIELD, {
                    school_id,
                    school_application_id,
                    schoolApplication,
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>

<style scoped>
.v-input--radio-group {
    border-bottom: 1px dotted var(--apsto-teal);
    font-size: 16px;
}

.v-input--checkbox {
    margin-top: 0;
    padding-top: 0;
}

.text--primary {
    font-size: 16px;
}
</style>
