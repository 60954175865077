<template>
  <loading v-if="loading"/>
  <div v-else>

    <v-row v-if="isMobile">
      <v-col>
        <company-header page-title="Payroll Withholding - By Calendar Year"/>
      </v-col>
    </v-row>

    <v-row
      v-if="!isMobile"
      align="center"
    >
      <v-col md="6">
        <company-header page-title="Payroll Withholding - By Calendar Year"/>
      </v-col>
      <v-spacer/>
      <v-col cols="auto">
        <v-btn
          :to="{ name: 'employee-donations-upload', params: { company_id: company.id } }"
          color="primary"
          rounded
        >
          <v-icon left>
            mdi-plus-circle-outline
          </v-icon>
          Upload Payroll Withholding
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :class="isMobile ? '' : 'ml-4 mt-5'"
      :disable-sort="isMobile"
      :headers="headers"
      :hide-default-footer="isMobile"
      :items="annualEmployeeDonations"
      :single-select="true"
      class="elevation-2"
      item-key="id"
    >

      <template v-slot:top>
        <v-toolbar
          v-if="isMobile"
          color="secondary"
          flat
        >
          <v-row>
            <v-col>
              Payroll Withholding - By Calendar Year
            </v-col>
            <v-col cols="auto">
              <v-icon
                color="white"
                rounded
                @click="addItem"
              >
                mdi-plus-circle-outline
              </v-icon>
            </v-col>
          </v-row>

        </v-toolbar>
      </template>

      <template #item.amount="{ item, index }">
        <div v-if="index === 0">
          {{ item.amount | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.amount | formatNumberWithCents }}
        </div>
      </template>
      <template #item.created_at="{ item }">
        {{ displayDate(item.created_at) }}
      </template>
      <template #item.fully_paid="{ item }">
        <div v-if="item.fully_paid"> Yes</div>
        <div v-else>No</div>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              v-bind="attrs"
              @click="edit(item)"
              v-on="on"
            >
              mdi-view-list
            </v-icon>
          </template>
          <span>View Uploads </span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-3"
              color="blue"
              v-bind="attrs"
              @click="showEmployees(item)"
              v-on="on"
            >
              mdi-account-group
            </v-icon>
          </template>
          <span>View Employees</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    No data added. Click button above.
                </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import {GET_ANNUAL_EMPLOYEE_DONATIONS} from "../../../../store/actions.type";
import CompanyHeader from "../../../../components/company/CompanyHeader";

export default {
  name: "CompanyAnnualDonationsList",
  components: {
    CompanyHeader,
    Loading
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    company() {
      return this.$store.getters.currentUserCompany;
    },
    annualEmployeeDonations() {
      return this.$store.getters.currentAnnualEmployeeDonations;
    },
    headers() {
      return [
        {text: 'C Year', align: 'start', value: 'calendar_year',},
        {text: 'Amount', value: 'amount'},
        {text: 'Last Upload', value: 'created_at'},
        {text: 'Fully Paid', value: 'fully_paid'},
        {text: 'Options', value: 'actions', align: 'center', sortable: false}
      ]
    },
  },
  methods: {
    addItem() {
      this.$router.push({name: 'employee-donations-upload', params: {company_id: this.company.id}});
    },
    edit(item) {
      this.$router.push({
        name: 'company-daily-donations-list',
        params: {company_id: this.company.id, company_annual_donation_id: item.id}
      })
    },
    getAnnualEmployeeDonations() {
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_ANNUAL_EMPLOYEE_DONATIONS, company_id)
        .then((data) => {
          this.loading = false;
          if (data.length === 1) {
            let company_annual_donation_id = data[0].id;
            this.$router.push(
              {
                name: 'company-daily-donations-list',
                params: {
                  company_id: company_id,
                  company_annual_donation_id: company_annual_donation_id
                }
              }
            );
          }
        });
    },
    showEmployees(item) {
      this.$router.push({
        name: 'company-employees-list',
        params: {company_id: this.company.id, company_annual_donation_id: item.id}
      })
    },
  },
  mounted() {
    this.getAnnualEmployeeDonations();
  },
}
</script>

<style scoped>::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent !important;
}</style>
