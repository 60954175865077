<template>
    <div>
        <div class="section-header">
            Previous Scholarship Awards
        </div>
        <div
            class="mt-1 font-mulish"
            style="color: var(--apsto-black); font-size: 16px; font-weight: 500;"
        >
            Please check below each of the following awards you have received.
        </div>
        <v-checkbox
            v-model="editedItem.student.previous_scholarship_award_1"
            :error="!!errors['student.previous_scholarship_award_1']"
            :error-messages="errors['student.previous_scholarship_award_1'] ? errors['student.previous_scholarship_award_1'] : null"
            color="secondary"
            @change="editedItem.student.previous_scholarship_award_1_sto = null"
        >
            <template v-slot:label>
                <div class="font-mulish"> Disabled/Displaced Tax Credit Scholarship</div>
            </template>
        </v-checkbox>
        <v-radio-group
            v-if="editedItem.student.previous_scholarship_award_1"
            v-model="editedItem.student.previous_scholarship_award_1_sto"
            :error="!!errors['student.previous_scholarship_award_1_sto']"
            :error-messages="errors['student.previous_scholarship_award_1_sto'] ? errors['student.previous_scholarship_award_1_sto'] : null"
            class="mt-n2 radio-border-bottom"
            column
        >
            <template v-slot:label>
                <div class="text--primary darken-4 pb-2">
                    Was this award issued by APSTO?
                </div>
            </template>
            <v-radio
                :value="1"
                color="secondary"
                label="Yes"
            />
            <v-radio
                :value="0"
                color="secondary"
                label="No, by another Arizona STO"
            />
        </v-radio-group>
        <v-checkbox
            v-model="editedItem.student.previous_scholarship_award_2"
            :error="!!errors['student.previous_scholarship_award_2']"
            :error-messages="errors['student.previous_scholarship_award_2'] ? errors['student.previous_scholarship_award_2'] : null"
            class="mt-n4"
            color="secondary"
            @change="editedItem.student.previous_scholarship_award_2_sto = null"
        >
            <template v-slot:label>
                <div class="font-mulish"> Low-Income Corporate Tax Credit Scholarship</div>
            </template>
        </v-checkbox>
        <v-radio-group
            v-if="editedItem.student.previous_scholarship_award_2"
            v-model="editedItem.student.previous_scholarship_award_2_sto"
            :error="!!errors['student.previous_scholarship_award_2_sto']"
            :error-messages="errors['student.previous_scholarship_award_2_sto'] ? errors['student.previous_scholarship_award_2_sto'] : null"
            class="mt-n2 radio-border-bottom"
            column
        >
            <template v-slot:label>
                <div class="text--primary darken-4 pb-2">
                    Was this award issued by APSTO?
                </div>
            </template>
            <v-radio
                :value="1"
                color="secondary"
                label="Yes"
            />
            <v-radio
                :value="0"
                color="secondary"
                label="No, by another Arizona STO"
            />
        </v-radio-group>
        <v-checkbox
            v-model="editedItem.student.previous_scholarship_award_3"
            :error="!!errors['student.previous_scholarship_award_3']"
            :error-messages="errors['student.previous_scholarship_award_3'] ? errors['student.previous_scholarship_award_3'] : null"
            class="mt-n4"
            color="secondary"
            @change="editedItem.student.previous_scholarship_award_3_sto = null"
        >
            <template v-slot:label>
                <div class="font-mulish"> Original Tax Credit Scholarship</div>
            </template>
        </v-checkbox>
        <v-radio-group
            v-if="editedItem.student.previous_scholarship_award_3"
            v-model="editedItem.student.previous_scholarship_award_3_sto"
            :error="!!errors['student.previous_scholarship_award_3_sto']"
            :error-messages="errors['student.previous_scholarship_award_3_sto'] ? errors['student.previous_scholarship_award_3_sto'] : null"
            class="mt-n2 radio-border-bottom"
            column
        >
            <template v-slot:label>
                <div class="text--primary darken-4 pb-2">
                    Was this award issued by APSTO?
                </div>
            </template>
            <v-radio
                :value="1"
                color="secondary"
                label="Yes"
            />
            <v-radio
                :value="0"
                color="secondary"
                label="No, by another Arizona STO"
            />
        </v-radio-group>
        <v-checkbox
            v-model="editedItem.student.previous_scholarship_award_4"
            :error="!!errors['student.previous_scholarship_award_4']"
            :error-messages="errors['student.previous_scholarship_award_4'] ? errors['student.previous_scholarship_award_4'] : null"
            class="mt-n4"
            color="secondary"
            @change="editedItem.student.previous_scholarship_award_4_sto = null"
        >
            <template v-slot:label>
                <div class="font-mulish"> Switcher (Overflow/Plus Tax Credit Scholarship)</div>
            </template>
        </v-checkbox>
        <v-radio-group
            v-if="editedItem.student.previous_scholarship_award_4"
            v-model="editedItem.student.previous_scholarship_award_4_sto"
            :error="!!errors['student.previous_scholarship_award_4_sto']"
            :error-messages="errors['student.previous_scholarship_award_4_sto'] ? errors['student.previous_scholarship_award_4_sto'] : null"
            class="mt-n2"
            column
        >
            <template v-slot:label>
                <div class="text--primary darken-4 pb-2">
                    Was this award issued by APSTO?
                </div>
            </template>
            <v-radio
                :value="1"
                color="secondary"
                label="Yes"
            />
            <v-radio
                :value="0"
                color="secondary"
                label="No, by another Arizona STO"
            />
        </v-radio-group>
    </div>
</template>

<script>
export default {
    name: "FamilyMemberStudentAwards",
    props: {
        editedItem: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            column: null,
            row: null,
        }
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
    },
}
</script>

<style scoped>
label.v-label div {
    color: black;
    font-size: 14px;
    font-weight: 400;
}

.section-header {
    background-color: #6CB2EB;
    color: white;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    padding: 15px 20px;
}

.radio-border-bottom {
    border-bottom: 1px solid var(--apsto-border);
    margin-bottom: 35px;
    padding-bottom: 0;
}
</style>
