<template>
  <div>
    <v-row
      class="dark-purple-background mt-3 pa-2"
      justify="center"
      style="text-align: center;"
    >
      <v-col
        class="white--text font-weight-black"
        cols="auto"
        style="font-size: 1.25rem;"
      >
        Questions? Call <a
        href="tel:4802561001"
        style="color: var(--apsto-teal);"
      >(480) 256-1001</a> or email&nbsp;<a
        href="mailto:INFO@APSTO.ORG"
        style="color: var(--apsto-teal);"
      >info@APSTO.org</a>
      </v-col>
    </v-row>
    <v-row
      class="purple-background mt-3 pa-10"
      justify="center"
    >
      <v-col
        class="white--text"
        cols="12"
        lg="4"
        sm="6"
      >
        Arizona Private School Tuition Organization
        <br/>
        6424 East Greenway Parkway, Suite 100
        <br/>
        Scottsdale, AZ 85254
        <br/><br/>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          class="my-1"
          color="secondary"
          dark
          href="tel:(480) 256-1001"
          rounded
          small
          style="min-width: 200px;"
        >
          <v-icon small>mdi-phone</v-icon>
          (480) 256-1001
        </v-btn>
        <br/>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          class="my-1"
          color="secondary"
          dark
          href="tel:480-256-1002"
          rounded
          small
          style="min-width: 200px;"
        >
          <v-icon small>mdi-fax</v-icon>
          480-256-1002
        </v-btn>
        <br/>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          class="my-1"
          color="secondary"
          dark
          href="mailto:Info@APSTO.org"
          rounded
          small
          style="min-width: 200px;"
        >
          <v-icon small>mdi-email</v-icon>
          Info@APSTO.org
        </v-btn>
      </v-col>
      <v-col
        class="white--text"
        cols="12"
        lg="4"
        sm="6"
      >
        <p>
          A school tuition organization cannot award, restrict or reserve
          scholarships solely on the basis of a donor’s recommendation. A taxpayer
          may not claim a tax credit if the taxpayer agrees to swap donations with
          another taxpayer to benefit either taxpayer’s own dependent. A.R.S.
          43-1603
        </p>
      </v-col>
    </v-row>

    <v-row
      class="dark-purple-background mt-3 pa-5"
      justify="center"
      style="text-align: center;"
    >
      <v-col
        class="white--text"
        cols="auto"
      >
        &copy;{{ new Date().getFullYear() }} Arizona Private School Tuition Organization, All rights reserved.
        <div>
          <a
            class="white--text"
            href="/arizona-certifications"
          >
            Arizona Certifications
          </a>
          |
          <a
            class="white--text"
            href="/contact"
          >
            Contact
          </a>
          |
          <a
            class="white--text"
            href="/copyright"
          >
            Copyright
          </a>
          |
          <a
            class="white--text"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
        </div>
        <div>
          501(c)3 nonprofit organization. | EIN 47-3027793
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "WebFooter",
};
</script>
