<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            v-if="showCheckPortal"
            align="center"
            class="py-12 portal-check"
            justify="center"
        >
            <v-col md="6 text-center">
                <v-card class="mt-n10 px-0 px-sm-6 py-12">
                    <div class="message-salutation">
                        Hi {{ user.first_name }}
                    </div>
                    <div class="message-welcome">
                        Welcome to your School Portal.
                    </div>
                    <div class="message-instructions px-4 px-sm-16 py-6">
                        You can use this portal to create a school and manage student awards.
                        <div class="mt-5 message-continue">
                            Would you like to continue?
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn
                            block
                            color="secondary"
                            href="/school-program"
                            rounded
                        >
                            No, I didn't mean to select this portal
                        </v-btn>
                        <v-btn
                            block
                            class="mt-5"
                            color="secondary"
                            rounded
                            @click="proceedWithPortal"
                        >
                            Yes, please continue
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <template v-if="!showCheckPortal">
            <v-card
                class="pa-5 mt-6"
                elevation="2"
            >
                <v-card-title class="main-header">
                    <v-row align="center">
                        <v-col cols="auto">
                            {{ school.name }}
                        </v-col>
                        <v-col v-if="user.has_multiple_schools">
                            <div class="text-h7-mulish text--secondary pl-0">
                                (Click Schools in purple menu bar to see all your schools)
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-btn
                    :block="!!isMobile"
                    class="font-weight-bold"
                    color="secondary"
                    rounded
                    @click="toggleInfo"
                >
                    <v-icon left> mdi-{{ showHideIcon }}</v-icon>
                    {{ showHideText }} School {{ isMobile ? '' : 'Profile' }}
                </v-btn>
                <v-btn
                    :block="!!isMobile"
                    :class="'font-weight-bold' + (isMobile ? ' mt-5' : ' ml-2')"
                    :to="{ name: 'school-edit', params: { school_id: school.id } }"
                    color="secondary"
                    rounded
                >
                    <v-icon left> mdi-pencil</v-icon>
                    Edit School {{ isMobile ? '' : 'Profile' }}
                </v-btn>
                <v-btn
                    :block="!!isMobile"
                    :class="'font-weight-bold' + (isMobile ? ' mt-5' : ' ml-2')"
                    :to="{ name: 'school-create' }"
                    color="secondary"
                    rounded
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    Create {{ isMobile ? 'A' : 'Another' }} School
                </v-btn>
            </v-card>
            <v-row
                v-show="showInfo"
                class="mt-2"
            >
                <v-col :cols="isMobile ? 12 : ''">
                    <v-card
                        class="pa-4 fill-height"
                        elevation="2"
                    >
                        <v-simple-table>
                            <tr>
                                <td class="text-h5-mulish pb-3">
                                    Info
                                </td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Website</td>
                                <td class="pl-5">{{ school.website }}</td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Phone</td>
                                <td class="pl-5">{{ school.phone }}</td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Fax</td>
                                <td class="pl-5">{{ school.fax }}</td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Status</td>
                                <td class="pl-5 teal-text">
                                    {{ school.approved ? 'Approved by APSTO' : 'In Review by APSTO' }}
                                </td>
                            </tr>
                        </v-simple-table>
                    </v-card>
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <v-card
                        class="pa-4 fill-height"
                        elevation="2"
                    >
                        <div class="text-h5-mulish pb-3">
                            Physical Location
                        </div>
                        <div>{{ school.physical_address.street1 }}</div>
                        <div v-if="school.physical_address.street2">{{ school.physical_address.street2 }}</div>
                        <div>{{ school.physical_address.city_line }}</div>
                    </v-card>
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <v-card
                        class="pa-4 fill-height"
                        elevation="2"
                    >
                        <div class="text-h5-mulish pb-3">
                            Mailing Address
                        </div>
                        <div>{{ school.address.street1 }}</div>
                        <div v-if="school.address.street2">{{ school.address.street2 }}</div>
                        <div>{{ school.address.city_line }}</div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-2">
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="school.id"
                        button-name="school-applications-list"
                        button-param="school_id"
                        button-text="Go"
                        description="Create or edit your school's partner agreement."
                        icon="mdi-application"
                        title="Partner Agreements"
                    />
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="school.id"
                        button-name="school-student-years-table"
                        button-param="school_id"
                        button-text="Go"
                        description="View student roster and manage student awards."
                        icon="mdi-school-outline"
                        title="Students"
                    />
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="school.id"
                        button-name="school-admins-list"
                        button-param="school_id"
                        button-text="Go"
                        description="Invite personnel to help manage student awards."
                        icon="mdi-account-multiple"
                        title="School Admins"
                    />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import {GET_USER_SCHOOL} from "../../../../store/actions.type";
import BlockPanel from "../../../../components/shared/BlockPanel.vue";

export default {
    name: "School",
    components: {
        BlockPanel,
        Loading,
    },
    data() {
        return {
            calendarYear: new Date().getFullYear(),
            hideCheckPortal: false,
            loading: true,
            showHideIcon: "eye",
            showHideText: "Show",
            showInfo: false,
        };
    },
    computed: {
        school() {
            return this.$store.getters.currentUserSchool;
        },
        showCheckPortal() {
            return this.user.has_created_content && !this.user.school && !this.hideCheckPortal;
        },
        user() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        getSchool() {
            this.loading = true;
            this.$store.dispatch(GET_USER_SCHOOL, this.$route.params.school_id).then(() => {
                this.loading = false;
            });
        },
        proceedWithPortal() {
            this.hideCheckPortal = true;
        },
        toggleInfo() {
            this.showInfo = !this.showInfo;
            this.showInfo ? (this.showHideText = "Hide") : (this.showHideText = "Show");
            this.showInfo ? (this.showHideIcon = "eye-off") : (this.showHideIcon = "eye");
        }
    },
    mounted() {
        this.getSchool();
    },
};
</script>

<style scoped>
.heading-smaller {
    font-size: 1rem;
}

.main-header {
    color: var(--apsto-purple);
    font-size: 24px;
    font-weight: bold;
    padding-left: 0;
    padding-top: 0;
}
</style>
