<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 mt-2"
    >
      <admin-header page-title="Edit Corporate Donation Request"/>
    </v-row>
    <v-row>
      <v-col md="6">
        <div class="box-with-background">
          {{ corporatePledge && corporatePledge.company_name }}
          <br>
          {{ corporatePledge && corporatePledge.tax_credit_name }}
          <table class="mt-2">
            <tr>
              <td style="width: 60px;">Pledged:</td>
              <td>{{ corporatePledge && corporatePledge.amount | formatCurrency }}</td>
            </tr>
            <tr>
              <td style="width: 85px;">Donated:</td>
              <td>{{ corporatePledge && corporatePledge.donation_amount | formatCurrency }}</td>
            </tr>
            <tr>
              <td style="width: 85px;">Tax Year:</td>
              <td>{{ corporatePledge && corporatePledge.tax_year }} (Calendar year in which donation
                is requested or paid)
              </td>
            </tr>
          </table>
        </div>
        <div class="box-with-background orange mt-5">
          <a
            :href="`/api/companies/${corporatePledge.company_id}/corporate-pledges/${corporatePledge.id}/download/pdf`"
            class="mt-5"
            style="color: white; font-size: 1.075rem;"
          >
            <span>Download Donation Request</span>
          </a>
          <br>
          <a
            :href="`/api/companies/${corporatePledge.company_id}/corporate-pledges/${corporatePledge.id}/download/ador-application/pdf`"
            style="color: white; font-size: 1.075rem;"
          >
            <span>Download ADOR Application Form</span>
          </a>
          <br>
          <a
            :href="`/api/companies/${corporatePledge.company_id}/corporate-pledges/${corporatePledge.id}/download/ador-approval/pdf`"
            style="color: white; font-size: 1.075rem;"
          >
            <span>Download ADOR Approval</span>
          </a>
          <br>
          <a
            :href="`/api/companies/${corporatePledge.company_id}/corporate-pledges/download-annual-tax-receipt/year/${corporatePledge.tax_year}/pdf`"
            style="color: white; font-size: 1.075rem;"
          >
            <span>Download Company Contributions Summary for {{ corporatePledge.tax_year }}</span>
          </a>
        </div>
        <v-card class="mt-5">
          <v-card-title>Options and Activity</v-card-title>
          <v-card-subtitle>

          </v-card-subtitle>

          <v-form :disabled="saving">
            <div class="px-3 py-2">

              <!--Fee Percentage For This Corporate Pledge-->
              <v-text-field
                id="fee_percentage"
                v-model.trim="corporatePledge.fee_percentage"
                :error="!!errors.fee_percentage"
                :error-messages="errors.fee_percentage ? errors.fee_percentage : null"
                filled
                hint="Change fee percentage here if you agreed to a different fee for this corporate pledge. Do not enter a percentage sign."
                label="Fee Percentage"
                @focus="clearErrors()"
              />


              <!--Submitted to ADOR Date-->
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="submittedDateFormatted"
                    filled
                    label="Date Submitted to ADOR"
                    @blur="corporatePledge.submitted_to_ador = parseDateOnly(submittedDateFormatted)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="corporatePledge.submitted_to_ador"
                  :max="maxDate"
                  :min="minDate"
                  no-title
                />
              </v-menu>

              <!--Response from ADOR Date-->
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="responseDateFormatted"
                    :error="!!errors.response_date"
                    :error-messages="errors.response_date ? errors.response_date : null"
                    filled
                    label="Date of ADOR Response"
                    @blur="corporatePledge.response_date = parseDateOnly(responseDateFormatted)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="corporatePledge.response_date"
                  :max="maxDate"
                  :min="minDate"
                  no-title
                />
              </v-menu>
              <!--Response from ADOR-->
              <v-select
                v-model="corporatePledge.response_from_ador"
                :error="!!errors.response_from_ador"
                :error-messages="errors.response_from_ador ? errors.response_from_ador : null"
                :items="responseOptions"
                background-color="white"
                filled
                item-text="name"
                item-value="id"
                label="ADOR Response"
              />

              <v-row>
                <v-col
                  v-if="corporatePledge.response_from_ador === 1"
                  class="mt-n1"
                >
                  <ador-approval-file-upload/>
                </v-col>
              </v-row>

              <!--Notified Client Date-->
              <v-menu
                v-if="corporatePledge.response_from_ador === 1 && corporatePledge.notified_by_apsto"
                :close-on-content-click="false"
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="notifiedDateFormatted"
                    class="mt-5"
                    filled
                    label="Date Notified Client Pledge Approved By ADOR"
                    @blur="corporatePledge.notified_by_apsto = parseDateOnly(notifiedDateFormatted)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="corporatePledge.notified_by_apsto"
                  :max="maxDate"
                  :min="minDate"
                  no-title
                />
              </v-menu>


              <!--Final Amount-->
              <v-text-field
                id="donation_amount"
                v-model.trim="corporatePledge.donation_amount"
                :disabled="disabledForPayments"
                :error="!!errors.donation_amount"
                :error-messages="errors.donation_amount ? errors.donation_amount : null"
                class="mt-5"
                filled
                hint="Enter the lesser of the amount authorized by the state or the amount paid or to be paid by the company."
                label="Donation Amount"
                @blur="submit"
                @focus="clearErrors()"
              />


              <div
                v-if="disabledForPayments"
                class="text-apsto-teal font-weight-black mt-n4 mb-4"
              >
                Donation amount cannot be edited. If you need to change the donation amount, first delete
                all payments.
              </div>


              <v-radio-group
                v-model="corporatePledge.can_edit"
                :error="!!errors['can_edit']"
                :error-messages="errors['can_edit'] ? errors['can_edit'] : null"
                class="mt-n2"
                @change="submit"
              >
                <template v-slot:label>
                  <div class="radio-label mb-2">
                    Can company edit this pledge?
                  </div>
                </template>
                <v-radio
                  :value="true"
                  color="secondary"
                  label="Yes"
                />
                <v-radio
                  :value="false"
                  color="secondary"
                  label="No"
                />
              </v-radio-group>

              <!--Close Pledge-->
              <v-checkbox
                v-model="corporatePledge.closed"
                :error="!!errors.closed"
                :error-messages="errors.closed ? errors.closed : null"
                class="mt-n2"
                color="secondary"
              >
                <template v-slot:label>
                  <div class="text-apsto-teal font-weight-black">
                    Close pledge (No further action expected)
                  </div>
                </template>
              </v-checkbox>
              <v-card-actions class="ml-n2">
                <v-btn
                  :to="{ name: 'admin-corporate-pledges-list' }"
                  class="cancel-button"
                  rounded
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="ml-2"
                  color="primary"
                  rounded
                  @click="submit"
                >
                  Save
                  <v-icon
                    v-if="saving"
                    right
                    small
                  >fas fa-spinner fa-spin
                  </v-icon>
                  <v-icon
                    v-if="showCheckmark"
                    right
                    small
                  >fas fa-check
                  </v-icon>
                </v-btn>
                <confirmation-dialog ref="confirm"/>
                <v-snackbar
                  v-model="snackbar"
                  centered
                >
                  {{ snackbarMessage }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card-actions>
            </div>
          </v-form>
        </v-card>

      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="mt-2"
        md="6"
      >
        <admin-corporate-pledge-payments-table/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminCorporatePledgePaymentsTable from "./AdminCorporatePledgePaymentsTable";
import AdminHeader from "../main/AdminHeader";
import AdorApprovalFileUpload from "../../../components/admin/corporate-pledges/AdorApprovalFileUpload.vue";
import ConfirmationDialog from "../../../components/shared/ConfirmationDialog";
import Loading from "../../../components/shared/Loading";
import {CLEAR_ERRORS, SET_CORPORATE_PLEDGE} from "../../../store/mutations.type";
import {GET_CORPORATE_PLEDGE_ADMIN, PATCH_CORPORATE_PLEDGE_ADMIN} from "../../../store/actions.type";

export default {
  name: "AdminCorporatePledgeEdit",
  components: {
    AdminCorporatePledgePaymentsTable,
    AdminHeader,
    AdorApprovalFileUpload,
    ConfirmationDialog,
    Loading,
  },
  data() {
    return {
      loading: true,
      maxDate: "2099-12-31",
      minDate: "2022-01-01",
      notifiedDateFormatted: null,
      responseDateFormatted: null,
      responseOptions: [
        {id: 0, name: "Select"},
        {id: 1, name: "Approved"},
        {id: 2, name: "Added to wait list"},
        {id: 3, name: "Cap reached"},
      ],
      saving: false,
      showCheckmark: false,
      snackbar: false,
      snackbarMessage: "",
      submittedDateFormatted: null,
    }
  },
  computed: {
    corporatePledge: {
      get: function () {
        return this.$store.getters.currentCorporatePledge;
      },
      set: function (value) {
        this.$store.commit(SET_CORPORATE_PLEDGE, value);
      },
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
    payments() {
      return this.$store.getters.currentCorporatePledgePayments;
    },
    disabledForPayments() {
      return !!(this.payments && this.payments.length);
    },
  },
  methods: {
    clearErrors: function () {
      this.$store.commit(CLEAR_ERRORS);
    },
    getCorporatePledge() {
      this.loading = true;
      let corporate_pledge_id = this.$route.params.corporate_pledge_id;
      this.$store.dispatch(GET_CORPORATE_PLEDGE_ADMIN, corporate_pledge_id)
        .then(() => {
          this.corporatePledge.donation_amount = this.corporatePledge.donation_amount === 0 ? null : this.corporatePledge.donation_amount;
          this.loading = false;
        });
    },
    initialize() {
      this.corporatePledge = [];
    },
    submit() {
      this.saving = true;
      this.$store.dispatch(PATCH_CORPORATE_PLEDGE_ADMIN).then(() => {

        const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));

        const showSpinner = () => {
          return wait(500).then(() => {
            console.log('showSpinner');
            this.saving = false;
          });
        };

        const showCheckmark = () => {
          this.showCheckmark = true;
          return wait(1500).then(() => {
            console.log('showCheckmark');
            this.showCheckmark = false;
          });
        };

        async function showSaveActions() {
          await showSpinner();
          await showCheckmark();
        }

        showSaveActions();
      })
        .catch(() => {
          this.saving = false;
          this.showCheckmark = false;
        });
    },
  },
  watch: {
    corporatePledge: {
      handler() {
        this.submittedDateFormatted = this.formatDateOnly(this.corporatePledge.submitted_to_ador);
        this.notifiedDateFormatted = this.formatDateOnly(this.corporatePledge.notified_by_apsto);
        this.responseDateFormatted = this.formatDateOnly(this.corporatePledge.response_date);
      },
      deep: true
    }
  },
  mounted() {
    this.getCorporatePledge();
    this.loading = false;
  },
}
</script>

<style scoped></style>
