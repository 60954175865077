<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            v-if="showCheckPortal"
            align="center"
            class="py-12 portal-check"
            justify="center"
        >
            <v-col md="6 text-center">
                <v-card class="mt-n10 px-0 px-sm-6 py-12">
                    <div class="message-salutation">
                        Hi {{ user.first_name }}
                    </div>
                    <div class="message-welcome">
                        Welcome to your Corporate Portal
                    </div>
                    <div class="message-instructions px-4 px-sm-16 py-6">
                        You can use this portal to create donations on behalf of a company or to turn on payroll
                        withholding for your employees.
                        <div class="mt-5 message-continue">
                            Would you like to continue?
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn
                            block
                            color="secondary"
                            href="/corporate-program"
                            rounded
                        >
                            No, I didn't mean to select this portal
                        </v-btn>
                        <v-btn
                            block
                            class="mt-5"
                            color="secondary"
                            rounded
                            @click="proceedWithPortal"
                        >
                            Yes, please continue
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <template v-if="!showCheckPortal">
            <v-card
                class="pa-5"
                elevation="2"
            >
                <v-card-title class="main-header">
                    <v-row align="center">
                        <v-col cols="auto">
                            {{ company.name }}
                        </v-col>
                        <v-col v-if="user.has_multiple_companies">
                            <div class="text-h7-mulish text--secondary pl-0">
                                (Click Corporate Donors in purple menu bar to see all your companies)
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-btn
                    :block="!!isMobile"
                    class="font-weight-bold"
                    color="secondary"
                    rounded
                    @click="toggleInfo"
                >
                    <v-icon left> mdi-{{ showHideIcon }}</v-icon>
                    {{ showHideText }} Company {{ isMobile ? '' : 'Profile' }}
                </v-btn>
                <v-btn
                    :block="!!isMobile"
                    :class="'font-weight-bold' + (isMobile ? ' mt-5' : ' ml-2')"
                    :to="{ name: 'company-edit', params: { company_id: company.id } }"
                    color="secondary"
                    rounded
                >
                    <v-icon left> mdi-pencil</v-icon>
                    Edit Company {{ isMobile ? '' : 'Profile' }}
                </v-btn>
                <v-btn
                    :block="!!isMobile"
                    :class="'font-weight-bold' + (isMobile ? ' mt-5' : ' ml-2')"
                    :to="{ name: 'company-create' }"
                    color="secondary"
                    rounded
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    Create {{ isMobile ? 'A' : 'Another' }} Company Profile
                </v-btn>
            </v-card>
            <v-row
                v-show="showInfo"
                class="mt-2"
            >
                <v-col :cols="isMobile ? 12 : ''">
                    <v-card
                        class="pa-4 fill-height"
                        elevation="2"
                    >
                        <v-simple-table>
                            <tr>
                                <td class="text-h5-mulish pb-3">
                                    Info
                                </td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Website</td>
                                <td class="pl-5">{{ company.website }}</td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Phone</td>
                                <td class="pl-5">{{ company.phone }}</td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Entity Type</td>
                                <td class="pl-5 ellipsis">{{ company.organization_type_name }}</td>
                            </tr>
                            <tr>
                                <td class="text--secondary">NAICS Code</td>
                                <td class="pl-5">{{ company.naics_code }}</td>
                            </tr>
                        </v-simple-table>
                    </v-card>
                </v-col>
                <v-col
                    v-if="companyWantsPayroll"
                    :cols="isMobile ? 12 : ''"
                >
                    <v-card
                        class="pa-4 fill-height"
                        elevation="2"
                        style="display: grid;"
                    >
                        <v-simple-table>
                            <tr>
                                <td class="text-h5-mulish">
                                    Payroll Withholding Info
                                </td>
                            </tr>
                            <tr>
                                <td class="text--secondary pt-3">Employer Withholding Code</td>
                                <td class="pl-5">
                                    {{ company.withholding_code.substring(0, 3) }}-{{
                                        company.withholding_code.substring(3)
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text--secondary">Payroll Withholding Frequency</td>
                                <td class="pl-5">
                                    {{ company.employee_donations_frequency_name }}
                                </td>
                            </tr>
                        </v-simple-table>
                        <div class="text-h6-mulish text-apsto-teal">
                            <v-btn
                                :href="'/api/companies/' + company.id + '/download/employee-instructions-letter'"
                                block
                                class="mt-5"
                                color="secondary"
                                rounded
                            >
                                <span class="font-weight-bold">Get Employee Instructions Letter</span>
                            </v-btn>
                            <br>
                            <v-btn
                                :to="{ name: 'company-get-form-a4c-list', params: { company_id: company.id } }"
                                block
                                class="mt-n2"
                                color="secondary"
                                rounded
                            >
                                <span class="font-weight-bold">Get Employees Form A-4C</span>
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <v-card
                        class="pa-4 fill-height"
                        elevation="2"
                    >
                        <div class="text-h5-mulish pb-3">
                            Mailing Address
                        </div>
                        <div>{{ company.address.street1 }}</div>
                        <div v-if="company.address.street2">{{ company.address.street2 }}</div>
                        <div>{{ company.address.city_line }}</div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-2">
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="company.id"
                        button-name="corporate-pledges-list"
                        button-param="company_id"
                        button-text="Go"
                        description="Create donation requests and download documents and receipts."
                        icon="mdi-heart"
                        title="Donation Requests"
                    />
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        v-if="companyWantsPayroll"
                        :button-param-value="company.id"
                        button-name="company-annual-donations-list"
                        button-param="company_id"
                        button-text="Go"
                        description="Upload payroll withholding donations and access reporting."
                        icon="mdi-account-check"
                        title="Payroll Withholding"
                    />
                    <v-card
                        v-if="!companyWantsPayroll"
                        class="pa-4 fill-height"
                        elevation="2"
                    >
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-avatar
                                    color="primary"
                                    rounded
                                >
                                    <v-icon color="white">mdi-account-check</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col>
                                <div class="text-h5-mulish">
                                    Payroll Withholding
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n2">
                            <v-col>
                                <div class="container-middle">
                                    <a href="/withholdings-program">
                                    <span
                                        class="text-apsto-teal"
                                        style="font-size: 1.25rem;"
                                    >Learn More</span>
                                    </a>
                                </div>
                            </v-col>
                        </v-row>
                        <v-card-actions class="text-h6-mulish text-apsto-teal">
                            <v-btn
                                class="font-weight-bold"
                                color="secondary"
                                rounded
                                @click="contact"
                            >
                                <span class="font-weight-bold">Contact Me About Payroll</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-param-value="company.id"
                        button-name="company-admins-list"
                        button-param="company_id"
                        button-text="Go"
                        description="Invite personnel to help manage donation requests and payroll."
                        icon="mdi-account-multiple"
                        title="Company Admins"
                    />
                </v-col>
            </v-row>
            <v-snackbar
                v-model="snackbar"
                centered
            >
                Thank you. We will contact you shortly.
                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {GET_USER_COMPANY, POST_CONTACT_USER_FROM} from "../../../store/actions.type";
import BlockPanel from "../../../components/shared/BlockPanel.vue";

export default {
    name: "Company",
    components: {
        BlockPanel,
        Loading,
    },
    data() {
        return {
            hideCheckPortal: false,
            loading: true,
            calendarYear: new Date().getFullYear(),
            showInfo: false,
            showHideIcon: "eye",
            showHideText: "Show",
            snackbar: false,
        };
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        },
        companyWantsPayroll() {
            return this.company.employee_donations;
        },
        showCheckPortal() {
            return this.user.has_created_content && !this.user.corporate && !this.hideCheckPortal;
        },
        user() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        contact() {
            this.showDialogCannotCreate = false;
            let contact_type_enum = 3; // Payroll Withholding
            let email = this.user.email;
            let name = this.user.name;
            let message = "I clicked the Contact Me button to contact me about payroll withholding. I'm with " + this.company.name + " and our phone number is " + this.company.phone + ".";
            let subject = "Payroll Withholding"
            this.$store.dispatch(POST_CONTACT_USER_FROM, {
                contact_type_enum,
                email,
                message,
                name,
                subject
            }).then(() => {
                this.snackbar = true;
            });
        },
        proceedWithPortal() {
            this.hideCheckPortal = true;
        },
        toggleInfo() {
            this.showInfo = !this.showInfo;
            this.showInfo ? (this.showHideText = "Hide") : (this.showHideText = "Show");
            this.showInfo ? (this.showHideIcon = "eye-off") : (this.showHideIcon = "eye");
        }
    },
    mounted() {
        this.$store
            .dispatch(GET_USER_COMPANY, this.$route.params.company_id)
            .then((data) => {
                this.loading = false;
            });
    },
};
</script>

<style scoped>
.container-middle {
    min-height: 100px;
    padding-bottom: 10px;
}

.main-header {
    color: var(--apsto-purple);
    font-size: 24px;
    font-weight: bold;
    padding-left: 0;
    padding-top: 0;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
