<template>
  <loading v-if="loading"/>
  <div
    v-else
    class="mr-5"
  >
    <v-row
      align="center"
      class="ml-4 pt-2"
    >
      <v-col
        class="pa-0"
        cols="auto"
      >
        <admin-header page-title="Edit Student Awards"/>
      </v-col>
    </v-row>
    <v-row class="ml-0">
      <v-col
        lg="6"
        md="8"
      >
        <student-show
          :application-member="applicationMember"
          :application-student="applicationStudent"
          :school-student="schoolStudent"
          :school-year-name="schoolYearName"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      class="ml-0 mt-4"
    >
      <v-col cols="auto">
        <div class="awards-header">Awards</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog
          v-model="createDialog"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <admin-school-student-award-create
            :student-name="applicationMember.name"
            @done="createDialog = false"
          />
        </v-dialog>
        <v-dialog
          v-model="editDialog"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <admin-school-student-award-edit
            :application-student-award-id="awardId"
            :school="school"
            :student-name="applicationMember.name"
            @done="editDialog = false"
          />
        </v-dialog>
        <v-data-table
          :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
          :headers="headers"
          :items="schoolStudentAwards"
          :items-per-page="30"
          :single-select="true"
          class="elevation-2 ml-3 mr-0"
          item-key="id"
        >
          <template v-slot:no-data>
                        <span class="text-apsto-teal">
                            No students awards found
                        </span>
          </template>
          <template #item.application_student_award_actions="{ item }">
            <div
              v-for="(action, index) in item.application_student_award_actions"
              :key="index"
            >
              <div v-if="action.action === 1 && !acceptedPartialAmount(action, item)">
                <div
                  :style="index >= 1 ? 'padding-top: 8px;' : ''"
                  class="accepted-declined"
                >
                  Accepted on {{ displayDate(action.created_at) }} at {{ displayTime(action.created_at) }}
                  by
                  {{ action.user_name }}
                </div>
                <div>
                  Amount: {{ action.amount | formatCurrencyWithCents }}
                </div>
              </div>
              <div v-if="acceptedPartialAmount(action, item)">
                <div
                  :style="index >= 1 ? 'padding-top: 8px;' : ''"
                  class="accepted-declined"
                >
                  Accepted partial amount on {{ displayDate(action.created_at) }} at {{
                  displayTime(action.created_at)
                  }}
                  by {{ action.user_name }}
                </div>
                <div>
                  Amount Accepted: {{ action.amount | formatCurrencyWithCents }}
                </div>
                <div> Reason: {{ action.award_declination_label }}</div>
                <div v-if="action.comments">Comments: {{ action.comments }}</div>
              </div>
              <div v-if="action.action === 2">
                <div
                  :style="index >= 1 ? 'padding-top: 8px;' : ''"
                  class="accepted-declined"
                >
                  Declined on {{ displayDate(action.created_at) }} at {{ displayTime(action.created_at) }}
                  by
                  {{ action.user_name }}
                </div>
                <div> Reason: {{ action.award_declination_label }}</div>
                <div v-if="action.comments">Comments: {{ action.comments }}</div>
              </div>
            </div>
          </template>
          <template #item.amount="{ item, index }">
            <div v-if="index === 0">
              {{ item.amount | formatCurrencyWithCents }}
              <div v-if="item.refunds.length">
                <div
                  v-for="(refund, index) in item.refunds"
                  :key="index"
                >
                  <div> {{ refund.amount * -1 | formatCurrencyWithCents }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              {{ item.amount | formatNumberWithCents }}
              <div v-if="item.refunds.length">
                <div
                  v-for="(refund, index) in item.refunds"
                  :key="index"
                >
                  <div> {{ refund.amount * -1 | formatNumberWithCents }}</div>
                </div>
              </div>
            </div>

          </template>
          <template #item.created_at="{ item }">
            {{ displayDate(item.created_at) }}
            <div v-if="item.refunds.length">
              <div
                v-for="(refund, index) in item.refunds"
                :key="index"
              >
                <div>
                  {{ displayDate(refund.date_deposited) }}
                </div>
              </div>
            </div>
          </template>
          <template #item.tax_credit_name="{ item }">
            <div>{{ item.tax_credit_name }}</div>
            <div v-if="item.refunds.length">
              <div
                v-for="(refund, index) in item.refunds"
                :key="index"
              >
                <div>[Refund] Reason: {{ refund.reason }}</div>
              </div>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip
              bottom
              color="secondary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="disableLink(item)"
                  class="mr-2"
                  v-bind="attrs"
                  @click="editSchoolStudentAward(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit Award</span>
            </v-tooltip>

            <v-icon
              :disabled="disableLink(item)"
              color="red"
              @click="deleteAward(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <v-card>
            <v-card-title class="text-h5-mulish"> Confirm</v-card-title>
            <v-card-text
              class="pa-4 pl-6 black--text"
              style="font-size: 1.1rem"
            >
              Are you sure you want to delete this item?
            </v-card-text>
            <v-card-actions class="pt-3 pb-4">
              <v-spacer/>
              <v-btn
                class="cancel-button"
                rounded
                @click="closeDeleteAward"
              >
                Cancel
              </v-btn>
              <v-btn
                class="body-2 font-weight-bold"
                color="primary"
                @click="destroyAward"
              >
                YES
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="ml-0">
      <v-col>
        <v-card-actions class="pb-4 ml-n2 mt-n2">
          <v-btn
            :to="{ name: 'admin-school-edit', params: { school_id: this.$route.params.school_id } }"
            class="cancel-button"
            rounded
          >
            Back
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  DELETE_SCHOOL_STUDENT_AWARD,
  GET_ADMIN_SCHOOL,
  GET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID,
  GET_SCHOOL_STUDENT_AWARD,
  GET_UNLOCKED_STUDENT_AWARDS_COUNT,
  POST_SCHOOL_STUDENT_AWARD
} from "../../../../../store/actions.type";
import AdminHeader from "../../../main/AdminHeader";
import AdminSchoolStudentAwardEdit from "./AdminSchoolStudentAwardEdit";
import AdminSchoolStudentAwardForm from "./AdminSchoolStudentAwardForm";
import Loading from "../../../../../components/shared/Loading";
import StudentShow from "../../../../../components/school/StudentShow";
import {SET_SCHOOL_STUDENT_AWARD} from "../../../../../store/mutations.type";

export default {
  name: "AdminSchoolStudentAwardsEdit",
  components: {
    AdminHeader,
    AdminSchoolStudentAwardEdit,
    AdminSchoolStudentAwardForm,
    Loading,
    StudentShow
  },
  data() {
    return {
      amount_requested: null,
      awardId: null,
      createDialog: false,
      dialogDelete: false,
      editDialog: false,
      loading: true,
      form: {
        amount: null,
        application_student_school_id: null,
        month: null,
        tax_credit_type: null,
        school_year_id: null,
      },
      headers: [
        {text: 'School Year', value: 'fiscal_year_name'},
        {text: 'Date', value: 'created_at'},
        {text: 'Amount', align: 'end', value: 'amount',},
        {text: 'Tax Credit Type', value: 'tax_credit_name', width: '30%'},
        {text: 'Activity', value: 'application_student_award_actions'},
        {text: 'Options', align: 'end', value: 'actions', sortable: false},
      ],
      saving: false,
    }
  },
  computed: {
    applicationMember() {
      return this.applicationStudent.application_member;
    },
    applicationStudent() {
      return this.schoolStudent.application_student;
    },
    applicationStudentSchoolId() {
      return this.schoolStudent.id;
    },
    school() {
      return this.$store.getters.currentAdminSchool;
    },
    schoolStudent() {
      return this.$store.getters.currentAdminSchoolStudentWithAwards['schoolStudent'];
    },
    schoolStudentAwards() {
      return this.$store.getters.currentAdminSchoolStudentWithAwards['awards']
    },
    schoolYearName() {
      return this.applicationStudent.school_year_name.toString();
    },
    today() {
      return new Date().toLocaleDateString();
    },
  },
  methods: {
    acceptedPartialAmount(action, item) {
      return action.action === 1 && parseFloat(action.amount) > 0 && parseFloat(action.amount) < parseFloat(item.amount);
    },
    closeDeleteAward() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    createStudentAward() {
      let applicationStudentSchoolId = this.applicationStudentSchoolId;
      this.$store.commit(SET_SCHOOL_STUDENT_AWARD, {
        amount: null,
        application_student_school_id: applicationStudentSchoolId,
        fiscal_year_id: null,
        month: null,
        tax_credit_type: null,
      });
      this.createDialog = true;
      this.editDialog = false;
    },
    disableLink(item) {
      return !!(item.application_student_award_actions && item.application_student_award_actions.length);
    },
    deleteAward(item) {
      this.editedIndex = this.schoolStudentAwards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    destroyAward() {
      this.saving = true;
      let application_student_award_id = this.editedItem.id;
      let school_id = this.$route.params.school_id;
      this.$store
        .dispatch(DELETE_SCHOOL_STUDENT_AWARD, {school_id, application_student_award_id})
        .then(() => {
          this.saving = false;
          this.schoolStudentAwards.splice(this.editedIndex, 1);
          this.closeDeleteAward();
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;
          this.snackbar = true;
          this.dialogDelete = false;
        });
    },
    doneAwardActions() {
      this.getSchoolStudent();
    },
    editSchoolStudentAward(item) {
      this.loading = true;
      let school_id = this.$route.params.school_id;
      let application_student_school_id = item.application_student_school_id;
      let application_student_award_id = item.id;
      this.awardId = item.id;
      this.$store.dispatch(GET_SCHOOL_STUDENT_AWARD, {
        school_id,
        application_student_school_id,
        application_student_award_id
      })
        .then(() => {
          this.loading = false;
          this.createDialog = false;
          this.editDialog = true;
        })
        .catch(() => {
          this.loading = false;
        });

    },
    getSchool() {
      console.log("GET SCHOOL");
      this.loading = true;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(GET_ADMIN_SCHOOL, school_id)
        .then(() => {
          this.getSchoolStudent();
        })
        .catch((e) => {
          console.log("APPROVED SCHOOL ERROR:", e);
          this.loading = false;
        });
    },
    getSchoolStudent() {
      console.log("GET SCHOOL STUDENT");
      this.loading = true;
      let permanent_id = this.$route.params.permanent_id;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(GET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID, {school_id, permanent_id})
        .then(() => {
          this.getUnlockedStudentsCount();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getUnlockedStudentsCount() {
      console.log("GET UNLOCKED STUDENTS COUNT");
      this.loading = true;
      this.$store.dispatch(GET_UNLOCKED_STUDENT_AWARDS_COUNT).then(() => {
        this.loading = false;
      });
    },
    save() {
      this.saving = true;
      this.form.school_id = this.$route.params.school_id;
      let form = this.form;
      this.$store.dispatch(POST_SCHOOL_STUDENT_AWARD, form).then(() => {
        this.saving = false;
        this.$emit("done");
      })
        .catch((response) => {
          console.log(response)
          this.saving = false;
        });
    }
  },
  mounted() {
    this.getSchool();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDeleteAward();
    },
  },
}
</script>

<style scoped>
.accepted-declined {
  font-weight: 700;
}

.awards-header {
  font-family: Mulish, sans-serif;
  font-size: 21px;
}

.awards-header.hint {
  font-size: 15px;
  margin-bottom: 15px;
}

.sub-header {
  background-color: var(--apsto-purple-very-light);
  font-size: 1.15rem;
  font-weight: bold;
  padding: 30px 20px;
}

::v-deep tbody td {
  height: unset !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

::v-deep th:not([role=columnheader]) {
  background-color: var(--apsto-purple-very-light);
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}
</style>
