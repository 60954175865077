<template>
    <v-app>
        <announcement-bar v-if="hasAnnouncements && $route.meta.showAnnouncements" />

        <bar-drawer />
        <v-main
            :id="$route.meta.hasDashboardMenu ? 'dashboard' : 'front-end'"
            :class="$route.meta.backgroundColorGrey ? 'grey-background' : ''"
            :style="announcementStyle"
        >
            <v-row
                v-if="impersonating"
                class="mb-n6"
            >
                <v-col
                    class="text-center"
                    cols="12"
                    style="background-color: var(--apsto-teal);"
                >
                    <div
                        class="my-4 px-0 font-weight-black"
                        style="color: white; font-size: 1.25rem; height: 40px;"
                    >
                        You're signed in as {{ currentUser.name }} ({{ currentUser.email }}).
                        <a
                            class="ml-2"
                            href="/impersonate/leave"
                            style="color: white; text-decoration: underline;"
                        >
                            Log Out
                        </a>
                    </div>
                </v-col>
            </v-row>
            <loading v-if="loading" />
            <component
                :is="layout"
                v-else
            >
                <router-view />
            </component>
            <web-footer />
        </v-main>

        <v-dialog
            v-model="currentAddPageDialogStatus"
            max-width="600"
        >
            <add-edit-page />
        </v-dialog>
    </v-app>
</template>

<script>
//Store
import { GET_ANNOUNCEMENTS, GET_CONTENTS } from "../store/actions.type";

import { SET_ADD_PAGE_DIALOG_STATUS } from "../store/mutations.type";

//Loading
import Loading from "../components/shared/Loading";

//Layouts
import Full from "./Full";
import TopMenu from "./TopMenu";

//Nav
import BarDrawer from "../components/shared/BarDrawer";

//Dialogs
import LoginForm from "../components/shared/LoginForm";
import RegisterForm from "../components/shared/RegisterForm";
import AddEditPage from "../components/website/dialogs/AddEditPage";
import AnnouncementBar from "../components/website/components/AnnouncementBar";

import WebFooter from "../components/website/components/WebFooter";

export default {
    name: "App",
    components: {
        Full,
        TopMenu,
        LoginForm,
        RegisterForm,
        BarDrawer,
        Loading,
        AddEditPage,
        AnnouncementBar,
        WebFooter,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        impersonating() {
            return this.$store.getters.isImpersonating;
        },
        layout() {
            return this.$route.meta.hasDashboardMenu ? "TopMenu" : "Full";
        },
        currentAddPageDialogStatus: {
            get: function () {
                return this.$store.getters.currentAddPageDialogStatus;
            },
            set: function (value) {
                this.$store.commit(SET_ADD_PAGE_DIALOG_STATUS, value);
            },
        },
        hasAnnouncements() {
            return this.$store.getters.hasAnnouncements;
        },
        announcementStyle() {
            if (this.hasAnnouncements && this.$route.meta.showAnnouncements) {
                if (this.$vuetify.breakpoint.width < 550) {
                    return "margin-top: 86px !important;";
                } else {
                    return "margin-top: 46px !important;";
                }
            }

            return '';
        }
    },
    async beforeMount() {
        this.loading = true;
        await this.$store
            .dispatch(GET_CONTENTS)
            .then(() => {
                this.$store.dispatch(GET_ANNOUNCEMENTS);
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    },
};
</script>
