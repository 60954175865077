<template>
    <div>
        <v-menu
            v-model="isEditDialogOpen"
            :close-on-click="true"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <span
                    v-bind="attrs"
                    v-on="on"
                >
                    <span class="text-apsto-teal font-weight-black">
                        {{ value }}
                    </span>
                </span>
            </template>
            <v-sheet>
                <v-row
                    align="center"
                    class="px-5"
                    no-gutters
                >
                    <v-col>
                        <v-text-field
                            v-mask="'(###) ###-####'"
                            v-model="localValue"
                            :label="label"
                            reverse
                            @keydown.enter="save"
                            @keydown.esc="cancel"
                            :rules="[max20chars]"
                        />
                        <div
                            style="font-size: 12px; font-style: italic; font-weight: 300; padding-bottom: 10px; text-align: right;">
                            To save changes, place cursor at far right of your entry then press Enter key
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-menu>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    name: "InlineEditAdminPhone",
    directives: {
        mask
    },
    props: {
        label: {
            default: "Value",
            type: String,
        },
        value: {
            default: null,
        },
    },
    data() {
        return {
            isEditDialogOpen: false,
            loading: true,
            snackbar: false,

            snackbarMessage: "",
            max20chars: v => v.length <= 20 || 'Input too long!',
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("companyAdmin");
        },
        errors() {
            return this.$store.getters.getErrors("companyAdmin");
        },
        localValue: {
            get() {
                return this.value;
            },
            set(localValue) {
                if (!localValue) this.value = "";
            },
        },
    },
    methods: {
        save(event) {
            event.preventDefault();
            if (!event.target.value || event.target.value.length < 10) {
                this.snackbar = true;
                this.snackbarMessage = "Please enter at least 10 characters for phone number.";
                return;
            }

            this.$emit("input", event.target.value);
            this.isEditDialogOpen = false;
        },
        cancel(event) {
            event.preventDefault();
            this.isEditDialogOpen = false;
        },

    },
};
</script>
