<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Brochure Requests"/>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="brochureRequests"
      class="elevation-2 mt-3"
      item-key="id"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <v-card>
            <v-card-title class="text-h5-mulish">
              Confirm
            </v-card-title>
            <v-card-text
              class="pa-4 pl-6 black--text"
              style="font-size: 1.1rem;"
            >
              Are you sure you want to delete this item?
            </v-card-text>
            <v-card-actions class="pt-3 pb-4">
              <v-spacer/>
              <v-btn
                class="cancel-button"
                rounded
                @click="closeDeleteBrochureRequest"
              >
                Cancel
              </v-btn>
              <v-btn
                class="body-2 font-weight-bold"
                color="primary"
                @click="deleteBrochureRequestConfirm"
              >
                YES
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #item.name="{ item }">
        <div class="py-5">
          Sent: {{ item.created_at }}
          <br>
          From: {{ item.name }}
          <br>
          Email: {{ item.email }}
          <br>
          Phone: {{ item.phone }}
          <br><br>
          {{ item.street1 }}
          <div v-if="item.street2">
            {{ item.street2 }}
          </div>
          <br>
          {{ item.city }}, {{ item.state }} {{ item.postal }}
          <br><br>
          Donor Brochures Quantity: {{ item.quantity_db | formatNumber }}
          <br>
          Donor Brochures Comments: {{ item.comments_db ? item.comments_db : 'None' }}
          <br><br>
          Corporate Tax Credit Brochure Quantity: {{ item.quantity_ctcb | formatNumber }}
          <br>
          Corporate Tax Credit Brochure Comments: {{ item.comments_ctcb ? item.comments_ctcb : 'None' }}
        </div>
      </template>
      <template v-slot:item.actionsBrochureRequests="{ item }">
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="red"
              v-bind="attrs"
              @click="deleteBrochureRequest(item)"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Message</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    No brochure requests at this time.
                </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {DELETE_BROCHURE_REQUEST, GET_BROCHURE_REQUESTS} from "../../../store/actions.type";

export default {
  name: "AdminBrochureRequestsList",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        name: '',
        created_at: ''
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'MESSAGE', value: 'name'},
        {text: 'Options', value: 'actionsBrochureRequests', sortable: false},
      ],
      loading: true,
    }
  },
  computed: {
    brochureRequests() {
      return this.$store.getters.currentBrochureRequests;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getBrochureRequests() {
      this.loading = true;
      this.$store.dispatch(GET_BROCHURE_REQUESTS)
        .then(() => {
          this.loading = false;
        });
    },
    closeDeleteBrochureRequest() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteBrochureRequest(item) {
      this.editedIndex = this.brochureRequests.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteBrochureRequestConfirm() {
      this.destroyBrochureRequest();
      this.brochureRequests.splice(this.editedIndex, 1)
      this.closeDeleteBrochureRequest()
    },
    destroyBrochureRequest() {
      this.saving = true;
      let brochureRequest_id = this.editedItem.id;
      this.$store.dispatch(DELETE_BROCHURE_REQUEST, brochureRequest_id)
        .then(() => {
          this.saving = false;
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;

          this.snackbar = true;
        });
    },
    initialize() {
      this.brochureRequests = [];
    },
  },
  mounted() {
    this.getBrochureRequests();
  },
}
</script>

<style scoped>::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}</style>
