<template>
  <v-card class="pb-5 dialog">
    <v-card-title class="pa-4">
      Upload Document
    </v-card-title>
    <div class="instructions-box pa-4">
      Please select student and document then click on the paperclip line.
    </div>
    <div
      v-if="error"
      class="errors-message pa-4"
    >
      <span>{{ error }}</span>
      <span v-for="error in errors">{{ error }}</span>
    </div>
    <v-card-text>
      <v-row>
        <v-col class="mt-2">
          <v-form enctype="multipart/form-data">
            <student-selector
              v-model="application_member_id"
              @input="getStudentDocuments"
            />
            <student-document-selector v-model="document_id"/>
            <div class="pa-0">
              <a @click="showExplanation = !showExplanation">
                I don't see the document I want to upload
              </a>
              <div
                v-if="showExplanation"
                class="instructions-box mt-5 pa-4 smaller"
              >
                Documents you've uploaded no longer show in the Documents dropdown. To change a
                document you've already uploaded:
                <ol class="mt-5">
                  <li class="smaller">Click Cancel to close this window</li>
                  <li class="smaller">Locate the document you want to update in the documents
                    list
                  </li>
                  <li class="smaller">Delete the existing document by clicking the trash can</li>
                  <li class="smaller">Click the Upload Document button again</li>
                </ol>
              </div>
            </div>
            <v-file-input
              v-model="file"
              :rules="rules"
              accept=".jpg,.jpeg,.pdf,.png"
              show-size
              truncate-length="15"
            />
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      v-if="isMobile"
      class="px-4"
    >
      <v-btn
        block
        class="cancel-button"
        rounded
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="saveDisabled"
        block
        class="mt-5"
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
    </div>
    <v-card-actions
      v-if="!isMobile"
      class="pb-4 pt-0"
    >
      <v-btn
        class="cancel-button"
        rounded
        @click="cancel"
      > Cancel
      </v-btn>
      <v-btn
        :disabled="saveDisabled"
        color="primary"
        rounded
        @click="save"
      > Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  GET_HOUSEHOLD_APPLICATION_DOCUMENTS,
  GET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN,
  POST_HOUSEHOLD_APPLICATION_MEMBER_DOCUMENT_UPLOAD,
} from "../../../store/actions.type";
import {CLEAR_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN} from '../../../store/mutations.type';
import StudentSelector from "../../shared/StudentSelector";
import StudentDocumentSelector from "./StudentDocumentSelector";

export default {
  name: "ApplicationFileUpload",
  components: {StudentDocumentSelector, StudentSelector},
  data: () => ({
    saving: false,
    application_member_id: null,
    document_id: null,
    file: null,
    rules: [
      value => !value || value.size < 5000000 || 'File size should be less than 5 MB!',
    ],
    showExplanation: false,
  }),

  computed: {
    error() {
      return this.$store.getters.getMessage("application");
    },
    errors() {
      return this.$store.getters.getErrors("application");
    },
    saveDisabled() {
      return !this.application_member_id || !this.document_id || !this.file;
    }
  },
  methods: {
    getStudentDocuments() {
      if (!this.application_member_id) return;
      const application_id = this.$route.params.application_id;
      const application_member_id = this.application_member_id;
      const household_id = this.$route.params.household_id;
      this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN, {
        application_id,
        household_id,
        application_member_id
      });
    },
    cancel() {
      this.application_member_id = null;
      this.file = null;
      this.$store.commit(CLEAR_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN);
      this.$emit("done");
    },

    save() {
      this.saving = true;
      let application_id = this.$route.params.application_id;
      let application_member_id = this.application_member_id;
      let household_id = this.$route.params.household_id;
      let document_id = this.document_id;
      let file = this.file;
      this.$store
        .dispatch(POST_HOUSEHOLD_APPLICATION_MEMBER_DOCUMENT_UPLOAD,
          {
            household_id,
            application_id,
            application_member_id,
            document_id,
            file
          }
        )
        .then(() => {
          this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_DOCUMENTS, {household_id, application_id})
            .then(() => {
              this.saving = false;
              this.application_member_id = null;
              this.file = null;
              this.$store.commit(CLEAR_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN);
              this.$emit("done");
            })
        })
        .catch(() => {
          this.saving = false;
        })
    },
  },
};
</script>

<style scoped>.errors-message {
  color: red;
  padding-left: 5px;
}

.instructions-box {
  background-color: var(--apsto-teal-very-light);
  color: var(--apsto-black);
  font-family: "Mulish", sans-serif;
}

.smaller {
  font-size: 1.0rem;
}</style>
