<template>
    <div>
        <v-text-field
            id="name"
            v-model.trim="household.name"
            :error="!!errors.name"
            :error-messages="errors.name ? errors.name : null"
            autocomplete="organization"
            background-color="white"
            class="mt-2"
            filled
            hint="Please enter your family's last name."
            label="Household Name (Ex: Smith)"
        />
        <v-row>
            <v-col
                class="text-h5 mt-n2"
                md="6"
            > Household Mailing Address
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="box-with-background">
                    Quick Address Entry
                    <vuetify-google-autocomplete
                        id="map"
                        append-icon="mdi-magnify"
                        autofocus
                        background-color="white"
                        class="mt-4"
                        country="us"
                        filled
                        placeholder="Start typing address"
                        v-on:placechanged="getAddressData"
                    />
                </div>
            </v-col>
        </v-row>
        <v-text-field
            id="street1"
            v-model.trim="household.address.street1"
            :error="!!errors['address.street1']"
            :error-messages="errors['address.street1'] ? errors['address.street1'] : null"
            autocomplete="address-line1"
            background-color="white"
            filled
            label="Street1"
        />
        <v-text-field
            id="street2"
            v-model.trim="household.address.street2"
            :error="!!errors['address.street2']"
            :error-messages="errors['address.street2'] ? errors['address.street2'] : null"
            autocomplete="address-line2"
            background-color="white"
            filled
            label="Street2"
        />
        <v-text-field
            id="postal"
            v-model.trim="household.address.postal"
            :error="!!errors['address.postal']"
            :error-messages="errors['address.postal'] ? errors['address.postal'] : null"
            autocomplete="zip"
            background-color="white"
            filled
            label="Postal / Zip"
        />
        <v-text-field
            id="city"
            v-model.trim="household.address.city"
            :error="!!errors['address.city']"
            :error-messages="errors['address.city'] ? errors['address.city'] : null"
            autocomplete="address-level2"
            background-color="white"
            filled
            label="City"
        />
        <region-selector v-model="household.address.region_id" />
    </div>
</template>

<script>
import RegionSelector from "../shared/RegionSelector";

export default {
    name: "HouseholdForm",
    components: {
        RegionSelector
    },
    props: {
        household: {
            type: Object,
            required: true,
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        regions() {
            return this.$store.getters.currentRegions;
        },
        error() {
            return this.$store.getters.getMessage("households");
        },
        errors() {
            return this.$store.getters.getErrors("households");
        },
    },
    methods: {
        getAddressData: function (addressData, placeResultData, id) {
            this.household.address.street1 = addressData.name;
            this.household.address.city = addressData.locality;
            this.household.address.postal = addressData.postal_code;
            this.household.address.lat = addressData.latitude;
            this.household.address.lon = addressData.longitude;

            let iso_state = addressData.administrative_area_level_1;
            this.household.address.region_id = searchArrayByKey(iso_state, this.regions, 'iso', 'object').id;
        }
    },
    mounted() {
        this.household.name = this.household.name ? this.household.name : this.currentUser.last_name;
    }
}
</script>

<style scoped>
.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
}
</style>
