import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {DELETE_BROCHURE_REQUEST, GET_BROCHURE_REQUESTS, POST_BROCHURE_REQUEST_FORM} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_BROCHURE_REQUESTS, SET_ERROR} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        brochureRequest: {},
        brochureRequests: []
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentBrochureRequests(state) {
        return state.brochureRequests;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_BROCHURE_REQUESTS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/brochure_requests`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_BROCHURE_REQUESTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "brochureRequest",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_BROCHURE_REQUEST_FORM](context, brochureRequestForm) {
        console.log("POST_BROCHURE_REQUEST_FORM ", brochureRequestForm)
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/brochure_requests`, brochureRequestForm)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "brochureRequest", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_BROCHURE_REQUEST](context, brochureRequest_id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/brochure_requests/${brochureRequest_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "brochureRequest", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },

};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_BROCHURE_REQUESTS](state, data) {
        state.brochureRequests = data;
    },
};

export default {
    getters, actions, mutations, state,
};
