<template>
  <div class="content">
        <span class="font-weight-bold">There are two ways for a student to qualify for the {{
            item.scholarship_label
          }}:</span>
    <div class="mt-2">
      (1) the student is or will be enrolled in Kindergarten through grade 12, OR
      <br>
      (2) the student is or will be enrolled in Preschool and is disabled and attends a school that
      provides services for disabilities.
    </div>
    <div
      v-if="item.qualifies === 'Yes'"
      class="mt-2 font-weight-bold"
    >
      <div v-if="isDisabledPreschooler(item)">
        You've indicated that {{ item.model.first_name }} is disabled and is or will be enrolled in a preschool that
        offers services for the disabled.
      </div>
      <div v-else>
        You've indicated that {{ item.model.first_name }} meets the enrolled grade prerequisite during this
        application school year.
      </div>
    </div>
    <div
      v-else
      class="mt-2 font-weight-bold"
    >
      <div v-if="item.model.student.esa_enum === 3">
        However {{ item.model.first_name }} is enrolled in the ESA program and cannot qualify for any tax credit
        scholarship while in that program.
      </div>
      <div v-else>
        You've indicated that {{ item.model.first_name }} did not meet either prerequisite during this
        application school year.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScholarshipOriginal",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isDisabledPreschooler(item) {
      return item.model.student.grade_id === 1 &&
        item.model.student.disabled &&
        item.model.student.school_has_disabled;
    }
  }
}

</script>

<style scoped>.content {
  font-family: "Mulish", sans-serif;
  font-size: .9rem;
  line-height: 1.40rem;
}</style>
