<template>
    <loading v-if="loading"/>
    <div
        v-else
        id="paymentForm"
    >
        <v-row v-if="showAmount">
            <v-col>
                <v-text-field
                    ref="amount"
                    v-model.trim="localPaymentInfo.amount"
                    :error="!!errors['amount']"
                    :error-messages="errors['amount'] ? errors['amount'] : null"
                    label="Charge Amount"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="mt-n6 text-h6-mulish font-weight-black">
                    Card Information
                </div>
                <v-text-field
                    ref="cardNumber"
                    v-model="localPaymentInfo.cardNumber"
                    :error="!!errors['cardNumber']"
                    :error-messages="errors['cardNumber'] ? errors['cardNumber'] : null"
                    class="mt-6"
                    filled
                    label="Card Number"
                    name="cardNumber"
                />
            </v-col>
        </v-row>
        <v-row class="mt-n5">
            <v-col>
                <v-text-field
                    ref="expiryMonth"
                    v-model="localPaymentInfo.expiryMonth"
                    :error="!!errors['expiryMonth']"
                    :error-messages="errors['expiryMonth'] ? errors['expiryMonth'] : null"
                    filled
                    label="Exp Month (2 digits)"
                    name="expiryMonth"
                />
                <div
                    v-if="localPaymentInfo.expiryMonth"
                    class="red--text mt-n6"
                >
                    <div v-if="localPaymentInfo.expiryMonth.length > 2">
                        Please enter 2 digits for month.
                    </div>
                </div>
            </v-col>
            <v-col>
                <v-text-field
                    ref="expiryYear"
                    v-model="localPaymentInfo.expiryYear"
                    :error="!!errors['expiryYear']"
                    :error-messages="errors['expiryYear'] ? errors['expiryYear'] : null"
                    filled
                    label="Exp Year (2 digits)"
                    name="expiryYear"
                />
                <div
                    v-if="localPaymentInfo.expiryYear && localPaymentInfo.expiryYear.length > 2"
                    class="red--text mt-n6"
                >
                    Please enter 2 digits for year.
                </div>
            </v-col>
            <v-col>
                <v-text-field
                    ref="cardCode"
                    v-model="localPaymentInfo.cardCode"
                    :error="!!errors['cardCode']"
                    :error-messages="errors['cardCode'] ? errors['cardCode'] : null"
                    filled
                    label="Card Code"
                    name="cardCode"
                />
                <div
                    v-if="localPaymentInfo.cardCode && localPaymentInfo.cardCode.length > 3"
                    class="red--text mt-n6"
                >
                    Please enter three digit card security code.
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="mt-n6 text-h6-mulish font-weight-black">
                    Name on Card and Billing Address
                </div>
                <!--                <v-checkbox-->
                <!--                    v-if="offerToCopyInfo"-->
                <!--                    v-model="populateCheckbox"-->
                <!--                    class="mt-0"-->
                <!--                    color="secondary"-->
                <!--                    @change="populateNameAndAddress"-->
                <!--                >-->
                <!--                    <template v-slot:label>-->
                <!--                        <div>-->
                <!--                            Copy name and billing address from your entries above-->
                <!--                        </div>-->
                <!--                    </template>-->
                <!--                </v-checkbox>-->
            </v-col>
        </v-row>
        <v-row class="mt-n6">
            <v-col>
                <v-text-field
                    ref="firstName"
                    v-model="localPaymentInfo.firstName"
                    :error="!!errors['firstName']"
                    :error-messages="errors['firstName'] ? errors['firstName'] : null"
                    filled
                    label="First Name"
                    name="firstName"
                />
            </v-col>
            <v-col>
                <v-text-field
                    ref="lastName"
                    v-model="localPaymentInfo.lastName"
                    :error="!!errors['lastName']"
                    :error-messages="errors['lastName'] ? errors['lastName'] : null"
                    filled
                    label="Last Name"
                    name="lastName"
                />
            </v-col>
        </v-row>
        <v-row class="mt-n6">
            <v-col>
                <v-text-field
                    ref="billingAddress1"
                    v-model="localPaymentInfo.billingAddress1"
                    :error="!!errors['billingAddress1']"
                    :error-messages="errors['billingAddress1'] ? errors['billingAddress1'] : null"
                    filled
                    label="Street"
                    name="billingAddress1"
                />
            </v-col>
        </v-row>
        <v-row class="mt-n6">
            <v-col>
                <v-text-field
                    ref="billingCity"
                    v-model="localPaymentInfo.billingCity"
                    :error="!!errors['billingCity']"
                    :error-messages="errors['billingCity'] ? errors['billingCity'] : null"
                    filled
                    label="City"
                    name="billingCity"
                />
            </v-col>
            <v-col>
                <v-text-field
                    ref="billingState"
                    v-model="localPaymentInfo.billingState"
                    :error="!!errors['billingState']"
                    :error-messages="errors['billingState'] ? errors['billingState'] : null"
                    filled
                    label="State"
                    name="billingState"
                />
            </v-col>
            <v-col>
                <v-text-field
                    ref="billingPostcode"
                    v-model="localPaymentInfo.billingPostcode"
                    :error="!!errors['billingPostcode']"
                    :error-messages="errors['billingPostcode'] ? errors['billingPostcode'] : null"
                    filled
                    label="Zip"
                    name="billingPostalcode"
                />
            </v-col>
        </v-row>

    </div>
</template>

<script>
import {CLEAR_ERRORS} from "../../store/mutations.type";
import {GET_STATE_ISO_FROM_REGION_ID, GET_USER_ADDRESS} from "../../store/actions.type";
import Loading from "./Loading.vue";

export default {
    name: "PaymentForm",
    components: {Loading},
    data: () => ({
        loading: false,
        populateCheckbox: false,
    }),
    props: {
        donor: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        value: {
            type: Object,
            required: true,
        },
        offerToCopyInfo: {
            type: Boolean,
            required: false,
            default: true,
        },
        showAmount: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        localPaymentInfo: {
            get() {
                return this.value;
            },
            set(localPaymentInfo) {
                this.$emit("input", localPaymentInfo);
            }
        },
        stateIso() {
            return this.$store.getters.currentStateIso;
        },
        userAddress() {
            return this.$store.getters.currentUserAddress;
        },

    },
    methods: {
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        },
        getGuestPaymentInfo() {
            this.localPaymentInfo.firstName = this.donor.first_name;
            this.localPaymentInfo.lastName = this.donor.last_name;
            this.localPaymentInfo.billingAddress1 = this.donor.address.street1;
            this.localPaymentInfo.billingCity = this.donor.address.city;
            this.localPaymentInfo.billingPostcode = this.donor.address.postal;
            this.getStateIso(this.donor.address.region_id);
        },
        // Most of the time, region_id will be 3 (AZ) and we don't need to make an API call
        // to get the state ISO.  However, if the donor is from a different state, we need to
        // make an API call to get the state ISO.
        getStateIso(regionId) {
            if (!regionId) {
                return '';
            }

            if (regionId === 3) {
                this.localPaymentInfo.billingState = this.stateIso;
            }

            this.$store.dispatch(GET_STATE_ISO_FROM_REGION_ID, regionId)
                .then(() => {
                    this.localPaymentInfo.billingState = this.stateIso;
                });
        },
        getUserPaymentInfo() {

            if (!this.currentUser) {
                return;
            }

            this.loading = true;

            this.$store.dispatch(GET_USER_ADDRESS).then(() => {
                this.loading = false;
                this.localPaymentInfo.firstName = this.currentUser.first_name;
                this.localPaymentInfo.lastName = this.currentUser.last_name;
                this.localPaymentInfo.billingAddress1 = this.userAddress.street1;
                this.localPaymentInfo.billingCity = this.userAddress.city;
                this.localPaymentInfo.billingPostcode = this.userAddress.postal;
                this.localPaymentInfo.billingState = this.userAddress.state;
            });

        },
        populateNameAndAddress() {
            if (this.populateCheckbox) {
                this.localPaymentInfo.firstName = this.donor.first_name;
                this.localPaymentInfo.lastName = this.donor.last_name;
                this.localPaymentInfo.billingAddress1 = this.donor.address.street1;
                this.localPaymentInfo.billingCity = this.donor.address.city;
                this.localPaymentInfo.billingPostcode = this.donor.address.postal;
                this.localPaymentInfo.billingState = this.donor.address.region_id;
            }
        },
    },
    mounted() {
        this.getUserPaymentInfo();
    },
    watch: {
        donor: {
            handler: function (newVal, oldVal) {
                this.getGuestPaymentInfo();
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.v-tab {
    border: 1px solid var(--apsto-teal);
    margin-right: 10px;
}
</style>

