<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            v-if="showCheckPortal"
            align="center"
            class="py-12 portal-check"
            justify="center"
        >
            <v-col md="6 text-center">
                <v-card class="mt-n10 px-0 px-sm-6 py-12">
                    <div class="message-salutation">
                        Hi {{ user.first_name }}
                    </div>
                    <div class="message-welcome">
                        Welcome to your Donor Portal.
                    </div>
                    <div class="message-instructions px-4 px-sm-16 py-6">
                        You can use this portal to:
                        <ul>
                            <li>
                                Donate while logged in so that you don't need to repeat your name and address
                                information each year
                            </li>
                            <li>
                                To enroll in monthly donations
                            </li>
                            <li>
                                To enroll in payroll donations if your employer has elected to participate in our
                                payroll
                                withholding program.
                            </li>
                        </ul>
                        <div class="mt-5 message-continue">
                            Would you like to continue?
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn
                            block
                            color="secondary"
                            href="/donor-program"
                            rounded
                        >
                            No, I didn't mean to select this portal
                        </v-btn>
                        <v-btn
                            block
                            class="mt-5"
                            color="secondary"
                            rounded
                            @click="proceedWithPortal"
                        >
                            Yes, please continue
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <template v-if="!showCheckPortal">
            <v-card
                class="pa-5 mt-6"
                elevation="2"
            >
                <v-card-title class="main-header">{{ donor.name }}</v-card-title>
                <v-btn
                    :block="!!isMobile"
                    :to="{ name: 'donor-edit', params: { user_id: donor.id } }"
                    class="font-weight-bold"
                    color="secondary"
                    rounded
                >
                    <v-icon left> mdi-pencil</v-icon>
                    Edit {{ isMobile ? '' : 'Mailing' }} Address
                </v-btn>
                <v-btn
                    :block="!!isMobile"
                    :class="'font-weight-bold' + (isMobile ? ' mt-5' : ' ml-2')"
                    :to="{ name: 'donor-annual-donations-receipt' }"
                    color="secondary"
                    rounded
                >
                    <v-icon
                        color="white"
                        left
                    >
                        mdi-receipt-text-send-outline
                    </v-icon>
                    GET TAX RECEIPT
                </v-btn>
            </v-card>
            <v-row class="mt-2">
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        button-name="donor-donations-list"
                        button-text="Make A Donation Today"
                        description="Turn your Arizona tax liability into tuition scholarships."
                        icon="mdi-heart"
                        title="Donations"
                    />
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-name="user.donor_subscription ? 'donor-donations-subscription-list' : 'donor-subscription-explainer'"
                        :button-text="user.donor_subscription ? 'Start or Stop Monthly Contributions' : 'Learn More'"
                        description="Create tuition scholarships through monthly contributions."
                        icon="mdi-calendar-today"
                        title="Monthly Donations"
                    />
                </v-col>
                <v-col :cols="isMobile ? 12 : ''">
                    <block-panel
                        :button-name="user.donor_withholding ? 'donor-donations-withholding-list' : 'donor-withholding-explainer'"
                        :button-text="user.donor_withholding ? 'Start or Stop Payroll Withholding' : 'Learn More'"
                        description="Create scholarships without affecting your take-home pay."
                        icon="mdi-account-check"
                        title="Payroll Withholding"
                    />
                </v-col>

            </v-row>
        </template>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {GET_CURRENT_DONOR} from "../../../store/actions.type";
import DonationList from "./DonationList";
import BlockPanel from "../../../components/shared/BlockPanel.vue";

export default {
    name: "Donor",
    components: {
        BlockPanel,
        Loading,
        DonationList
    },
    data() {
        return {
            hideCheckPortal: false,
            loading: true,
            calendarYear: null,
            showInfo: false,
        };
    },
    computed: {
        donor() {
            return this.$store.getters.currentDonor;
        },
        showCheckPortal() {
            return this.user.has_created_content &&
                !(this.user.donor_individual || this.user.donor_subscription || this.user.donor_withholding) &&
                !this.hideCheckPortal;
        },
        user() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        proceedWithPortal() {
            this.hideCheckPortal = true;
        }
    },
    mounted() {
        this.$store.dispatch(GET_CURRENT_DONOR)
            .then((data) => {
                this.loading = false;
                this.calendarYear = new Date().getFullYear();
                if (!data.address) {
                    this.$router.push({name: 'donor-edit'});
                }
            });
    },
};
</script>

<style scoped>
.main-header {
    color: var(--apsto-purple);
    font-size: 24px;
    font-weight: bold;
    padding-left: 0;
    padding-top: 0;
}

.container-middle {
    min-height: 100px;
    padding-bottom: 10px;
}

.v-card__actions {
    padding-left: 0 !important;
}

ul li {
    margin-top: 20px;
    text-align: left;
}
</style>
