import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {GET_COMPANY_DROPDOWN} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_ERROR, SET_COMPANY_DROPDOWN, SET_COMPANY_DROPDOWN_IS_LOADING} from "./mutations.type";
/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        isLoading: false,
        companyDropdown: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentCompanyDropdown(state) {
        return state.companyDropdown;
    },
    currentCompanyDropdownIsLoading(state) {
        return state.isLoading;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_COMPANY_DROPDOWN](context) {
        console.log("GET_COMPANY_DROPDOWN");
        //Prevent loading company dropdown over and over
        if (context.state.companyDropdown.length) return context.state.companyDropdown;

        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/dropdown`)
                .then(({data}) => {
                    console.log(data)
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_DROPDOWN, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companyDropdown",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_COMPANY_DROPDOWN](state, data) {
        console.log("SET_COMPANY_DROPDOWN");
        state.companyDropdown = data;
    },
    [SET_COMPANY_DROPDOWN_IS_LOADING](state, value) {
        console.log("SET_COMPANY_DROPDOWN_IS_LOADING", value);
        state.isLoading = value;
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
