<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            v-if="showCheckPortal"
            align="center"
            class="py-12 portal-check"
            justify="center"
        >
            <v-col md="6 text-center">
                <v-card class="mt-n10 px-0 px-sm-6 py-12">
                    <div class="message-salutation">
                        Hi {{ user.first_name }}
                    </div>
                    <div class="message-welcome">
                        Welcome to your Donor Portal.
                    </div>
                    <div class="message-instructions px-4 px-sm-16 py-6">
                        You can use this portal to:
                        <ul>
                            <li>
                                Donate while logged in so that you don't need to repeat your name and address
                                information each year
                            </li>
                            <li>
                                To enroll in monthly donations
                            </li>
                            <li>
                                To enroll in payroll donations if your employer has elected to participate in our
                                payroll
                                withholding program.
                            </li>
                        </ul>
                        <div class="mt-5 message-continue">
                            Would you like to continue?
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn
                            block
                            color="secondary"
                            href="/donor-program"
                            rounded
                        >
                            No, I didn't mean to select this portal
                        </v-btn>
                        <v-btn
                            block
                            class="mt-5"
                            color="secondary"
                            rounded
                            @click="proceedWithPortal"
                        >
                            Yes, please continue
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!showCheckPortal" class="mt-n3 mt-sm-0">
            <v-col md="6">
                <v-form :disabled="saving">
                    <donor-form :donor="donor" :message="message"/>
                    <div class="mx-2">
                        <v-btn
                            v-if="!donor.address.city"
                            :block="isMobile"
                            :to="{ name: 'home' }" class="cancel-button"
                            rounded
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            v-else
                            :block="isMobile"
                            :to="{ name: 'donor', params: { user_id: $route.params.user_id } }"
                            class="cancel-button"
                            rounded
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            :block="isMobile"
                            :class="isMobile ? 'mt-5' : 'ml-2'"
                            class="save-by-cancel-button"
                            color="primary"
                            rounded
                            @click="submit"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-form>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import DonorForm from "../../../components/donors/DonorForm";

import {GET_CURRENT_DONOR, PATCH_CURRENT_DONOR,} from "../../../store/actions.type";

export default {
    name: "DonorEdit",
    components: {
        Loading,
        DonorForm,
    },
    data() {
        return {
            hideCheckPortal: false,
            loading: true,
            saving: false,
            snackbar: false,
            snackbarMessage: "",
        };
    },
    computed: {
        donor() {
            let donor = this.$store.getters.currentDonor;
            if (!donor.address) {
                donor.address = {
                    city: '',
                    postal: '',
                    region_id: 3,
                    street1: '',
                    street2: '',
                };
            }
            return donor;
        },
        message() {
            return !this.donor.address.street1 ? "Please provide your mailing address." : "Mailing Address";
        },
        showCheckPortal() {
            return this.user.has_created_content &&
                !(this.user.donor_individual || this.user.donor_subscription || this.user.donor_withholding) &&
                !this.hideCheckPortal;
        },
        user() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        getDonor() {
            this.loading = true;
            this.$store.dispatch(GET_CURRENT_DONOR)
                .then(() => {
                    this.loading = false;

                });
        },
        proceedWithPortal() {
            this.hideCheckPortal = true;
            console.log("this.hideCheckPortal: ", this.hideCheckPortal);
        },
        submit() {
            this.saving = true;
            this.$store.dispatch(PATCH_CURRENT_DONOR, this.donor)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: 'donor'});
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.getDonor();
    },
};
</script>

<style scoped>
ul li {
    margin-top: 20px;
    text-align: left;
}
</style>
