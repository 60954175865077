import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_CONTACT_US_MESSAGE,
    GET_CONTACT_US_MESSAGES, PATCH_CONTACT_READ_STATUS, PATCH_SITE_WALLPAPER_STATUS,
    POST_CONTACT_US_FORM,
    POST_CONTACT_USER_FROM
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_CONTACTS, SET_ERROR} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        contact: {},
        contacts: []
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentContacts(state) {
        return state.contacts;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_CONTACT_US_MESSAGES](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/contacts`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CONTACTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "contact",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_CONTACT_US_FORM](context, contactUsForm) {
        console.log("POST_CONTACT_US_FORM ", contactUsForm)
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/contacts`, contactUsForm)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "contact", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_CONTACT_USER_FROM](context, {contact_type_enum, email, message, name, subject}) {
        const formData = new FormData();
        formData.append("contact_type_enum", contact_type_enum);
        formData.append("email", email);
        formData.append("message", message);
        formData.append("name", name);
        formData.append("subject", subject);
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/contacts`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "contact", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_CONTACT_US_MESSAGE](context, contact_id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/contacts/${contact_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "contact", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_CONTACT_READ_STATUS](context, contact_id) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/admin/contacts/${contact_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_CONTACTS](state, data) {
        state.contacts = data;
    },
};

export default {
    getters, actions, mutations, state,
};
