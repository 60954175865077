<template>
  <v-row>
    <v-col
      class="mt-2 purple-background white--text"
      cols="12"
    >
      <div class="ml-10 mx-auto text-h4-mulish py-3">
        {{ formatTitleCase($route.meta.topBarTitle ? $route.meta.topBarTitle : $route.name) }}
      </div>
    </v-col>
    <v-col
      v-if="false"
      cols="12"
    >
      <v-tabs
        :show-arrows="$vuetify.breakpoint.smAndDown"
        centered
        dark
        icons-and-text
      >
        <v-tab
          v-for="(item, i) in items"
          :key="i"
          :exact="item.exact"
          :to="item.to"
        >
          {{ item.name }}
          <v-icon>{{ item.icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="12">
      <div
        class="my-5 ma-sm-5"
        style="min-height: 600px;"
      >
        <slot></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TopMenu",
  data() {
    return {
      default: [
        {
          icon: "mdi-home-outline",
          name: "Home",
          to: "/dashboard",
          exact: true
        },
        {
          icon: "mdi-domain",
          name: "Corporate Donors",
          to: "/dashboard/companies",
          exact: false
        },
        {
          icon: "mdi-cards-heart",
          name: "Individual Donors",
          to: "/dashboard/donors",
          exact: false
        },
        {
          icon: "mdi-school-outline",
          name: "Applicants",
          to: "/dashboard/households",
          exact: false
        },
        {
          icon: "mdi-town-hall",
          name: "Schools",
          to: "/dashboard/schools",
          exact: false
        },
      ],

      "super-admin": [
        {
          icon: "mdi-wrench",
          name: "Admin",
          to: "/dashboard/admin",
        },
      ],

      "site-admin": [
        {
          icon: "mdi-wrench",
          name: "Admin",
          to: "/dashboard/admin",
        },
      ],
      "site-admin-1": [
        {
          icon: "mdi-wrench",
          name: "Admin",
          to: "/dashboard/admin",
        },
      ]
    };
  },
  computed: {
    items: function () {
      let roles = this.$store.getters.currentRoles;
      let tempItems;
      tempItems = [];

      Array.prototype.push.apply(tempItems, this.default);

      //Role routes first
      roles.forEach((role) => {
        Array.prototype.push.apply(tempItems, this[role]);
      });

      //Then Auth or Guest Routes
      /*
      if (this.isAuthenticated) {
        Array.prototype.push.apply(tempItems, this.authItems);
      } else {
        Array.prototype.push.apply(tempItems, this.guestItems);
      }
      */
      //The After Items
      //Array.prototype.push.apply(tempItems, this.afterItems);
      return tempItems;
    },
  },
};
</script>

<style scoped>
::v-deep .v-slide-group__wrapper {
  background-color: var(--apsto-purple);
}

::v-deep .v-tabs-bar {
  background-color: var(--apsto-purple) !important;
}

.text-h4-mulish {
  font-family: "Mulish", sans-serif !important;
  font-size: 1.75rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
  line-height: 1.75rem;
}</style>
