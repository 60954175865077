<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center">
      <v-col cols="auto">
        <company-header :page-title="'Payroll Withholding - ' + employeeDonationsYear"/>
      </v-col>
      <v-spacer/>
      <v-col class="mx-2 mx-sm-0" cols="auto">
        <v-row>
          <v-col>
            <year-and-quarter-selectors/>
          </v-col>
          <v-col>
            <v-btn
              :block="isMobile"
              :to="{ name: 'employee-donations-upload', params: { company_id: company.id } }"
              color="primary"
              rounded
            >
              <v-icon left> mdi-plus-circle-outline</v-icon>
              Upload Payroll Withholding
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="companyPayrollBatches"
      :single-select="true"
      class="elevation-2 mt-5"
      item-key="id"
      @click:row="edit"
    >
      <template v-slot:no-data>
        <span class="text-apsto-teal">
          No data available. Click Add button above.
        </span>
      </template>
      <template #item.amount="{ item , index }">
        {{ item.amount | formatCurrencyWithCents }}
      </template>
      <template #item.batch="{item}">
        {{ item.batch | formatNumber }}
      </template>
      <template #item.created_at="{item}">
        {{ displayDate(item.created_at) }}
      </template>
      <template #item.paid="{item}">
        <div v-if="item.paid === item.amount"> Yes</div>
        <div v-else-if="item.paid > item.amount" class="red--text font-weight-black"> Partially</div>
        <div v-else class="red--text font-weight-black">No</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import {GET_EMPLOYEE_DONATIONS} from "../../../../store/actions.type";
import CompanyHeader from "../../../../components/company/CompanyHeader";
import YearAndQuarterSelectors from "../../../../components/shared/YearAndQuarterSelectors";

export default {
  name: "CompanyPayrollBatchsList",
  components: {
    CompanyHeader,
    Loading,
    YearAndQuarterSelectors
  },
  data() {
    return {
      loading: true,
      search: '',
      selected: [],
    }
  },
  computed: {
    company() {
      return this.$store.getters.currentUserCompany;
    },
    companyPayrollBatches() {
      return this.employeeDonations['companyPayrollBatch'];
    },
    employeeDonations() {
      return this.$store.getters.currentEmployeeDonations;
    },
    employeeDonationsYear() {
      return this.employeeDonations['year']
    },
    headers() {
      return [
        {text: 'Amount', value: 'amount'},
        {text: 'Year Withheld', value: 'calendar_year'},
        {text: 'Month Withheld', value: 'month'},
        {text: 'Uploaded', value: 'created_at'},
        {text: 'Batch #', value: 'id', align: 'center'},
        {text: 'Paid', value: 'paid'}
      ]
    },
  },
  methods: {
    edit(item) {
      let company_annual_donation_id = this.$route.params.company_annual_donation_id;
      let company_id = this.$route.params.company_id;
      this.$router.push(
        {
          name: "company-payroll-batch-show",
          params: {
            company_id: company_id,
            company_annual_donation_id: company_annual_donation_id,
            company_payroll_batch_id: item.id
          }
        }
      );
    },
    getEmployeeDonations() {
      let company_annual_donation_id = this.$route.params.company_annual_donation_id
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_EMPLOYEE_DONATIONS, {company_id, company_annual_donation_id})
        .then(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getEmployeeDonations();
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}
</style>
