<template>
    <v-container
        v-if="section !== 'login-button'"
        class="pa-0"
    >
        <div
            id="pal"
            class="first-heading"
        >I Don't Have An Account
        </div>
        <!--        Or, not sure if you may have an account?-->
        <!--        <a-->
        <!--            class="text-apsto-teal"-->
        <!--            href="#"-->
        <!--            style="cursor: pointer;"-->
        <!--            @click="showCheckDataByEmailForm"-->
        <!--        >-->
        <!--            Click here.-->
        <!--        </a>-->
        <div v-if="showReasons">
            <v-btn
                class="mt-5"
                color="primary"
                rounded
                @click="showReasons = false"
            >
                Create An Account Now
            </v-btn>

            <div class="second-heading mt-5 font-weight-bold">
                {{ secondHeading }}
            </div>
            <div>
                <div class="list-item-title">{{ listItemTitle1 }}</div>
                <div class="list-item-content"> {{ listItemContent1 }}</div>
                <div class="list-item-title">{{ listItemTitle2 }}</div>
                <div class="list-item-content"> {{ listItemContent2 }}</div>
                <div class="list-item-title">{{ listItemTitle3 }}</div>
                <div class="list-item-content"> {{ listItemContent3 }}</div>
                <div
                    v-if="listItemTitle4"
                    class="list-item-title"
                >{{ listItemTitle4 }}
                </div>
                <div
                    v-if="listItemContent4"
                    class="list-item-content"
                > {{ listItemContent4 }}
                </div>
            </div>

        </div>
        <div
            v-if="!showReasons"
            class="mt-5"
        >
            <div class="second-heading">Create Account</div>
            <div
                v-if="error"
                :class="error ? 'red--text text-center' : 'text-center'"
            >
                {{ error }}
            </div>

            <v-form
                ref="registerform"
                v-model="isFormValid"
                class="mt-5 section"
            >
                <v-text-field
                    ref="first_name"
                    v-model="first_name"
                    :rules="rules.name"
                    :success="!!first_name"
                    label="First Name"
                    name="first_name"
                    type="text"
                    validate-on-blur
                    @keyup.enter="$refs.last_name.focus()"
                />

                <v-text-field
                    ref="last_name"
                    v-model="last_name"
                    :rules="rules.name"
                    :success="!!last_name"
                    label="Last Name"
                    name="last_name"
                    type="text"
                    validate-on-blur
                    @keyup.enter="$refs.email.focus()"
                />

                <v-text-field
                    ref="email"
                    v-model="email"
                    :error="!!errors && !!errors.email"
                    :error-messages="errors && errors.email ? errors.email : null"
                    :rules="rules.email"
                    label="Email"
                    name="email"
                    type="email"
                    @focus="clearErrors()"
                    @keyup.enter="$refs.password.focus()"
                />

                <v-text-field
                    id="password"
                    ref="password"
                    v-model="password"
                    :append-icon="passwordVisibility ? 'mdi-eye-lock-open' : 'mdi-eye-lock'"
                    :error="!!errors && !!errors.password"
                    :error-messages="!!errors && errors.password ? errors.password : null"
                    :hint="passwordStrength.strength >= 100
                        ? 'Requirements met.'
                        : 'Minimum 8 characters.'
                        "
                    :rules="[
                        passwordStrength.strength >= 100 ||
                        'Minimum 8 characters.',
                    ]"
                    :success="passwordStrength.strength >= 100"
                    :type="passwordVisibility ? 'text' : 'password'"
                    label="Password"
                    name="password"
                    persistent-hint
                    @focus="clearErrors()"
                    @keyup.enter="$refs.password_confirmation.focus()"
                    @click:append="togglePasswordVisibility"
                >
                </v-text-field>

                <v-text-field
                    id="password_confirmation"
                    ref="password_confirmation"
                    v-model="password_confirmation"
                    :append-icon="passwordVisibility ? 'mdi-eye-lock-open' : 'mdi-eye-lock'"
                    :error="!doPasswordsMatch"
                    :rules="[doPasswordsMatch || 'Must match first password.']"
                    :success="!!password.length && !!doPasswordsMatch"
                    :type="passwordVisibility ? 'text' : 'password'"
                    label="Confirm Password"
                    name="password_confirmation"
                    @focus="clearErrors()"
                    @keyup.enter="signUp()"
                    @click:append="togglePasswordVisibility"
                />
            </v-form>
            <div class="align-end">
                <v-btn
                    :disabled="!isFormValid"
                    :loading="loading"
                    block
                    class="mt-3"
                    color="primary"
                    rounded
                    @click.native="signUp"
                >
                    REGISTER
                </v-btn>
            </div>
        </div>

    </v-container>
</template>

<script>
import {REGISTER} from "../../store/actions.type";
import {CLEAR_ERRORS, SET_CHECK_DATA_BY_EMAIL_FORM} from "../../store/mutations.type";

export default {
    name: "RegisterForm",
    props: {
        section: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            email: "",
            first_name: "",
            isFormValid: false,
            last_name: "",
            loading: false,
            password: "",
            passwordToolTip: false,
            password_confirmation: "",
            passwordVisibility: false,
            remember: true,
            showReasons: false,
            // Validation Rules
            rules: {
                name: [(v) => !!v || "Name is required"],
                email: [
                    (v) => !!v || "E-mail is required",
                    (v) =>
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            v
                        ) || "Must be a valid email address",
                    (v) => true || "Email already associated with another account.",
                ],
                password: (v) =>
                    !!v ||
                    "Minimum 8 characters.",
            },
        };
    },
    watch: {
        passwordStrength(val) {
            this.passwordToolTip = val.strength < 100;
        },
        email() {
            this.$refs.registerform.validate();
        }
    },
    computed: {
        secondHeading() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "Benefits of creating an account today";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
            }
        },
        listItemTitle1() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "Print Tax Receipts";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
            }
        },
        listItemTitle2() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "Schedule Monthly Contributions";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
            }
        },
        listItemTitle3() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "Enroll In Withholdings Program";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
            }
        },
        listItemTitle4() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
            }
        },
        listItemContent1() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
            }
        },
        listItemContent2() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
            }
        },
        listItemContent3() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
                default:
                    return null;
            }
        },
        listItemContent4() {
            switch (this.section) {
                case "companies":
                    return "";
                case "donor":
                    return "";
                case "households":
                    return "";
                case "login-button":
                    return "";
                case "schools":
                    return "";
                default:
                    return null;
            }
        },
        error() {
            return this.$store.getters.getMessage("register");
        },
        errors() {
            return this.$store.getters.getErrors("register");
        },
        emailErrors() {
            return this.errors.email && this.errors.email.length > 0;
        },
        passwordErrors() {
            return this.errors.password && this.errors.password.length > 0;
        },
        passwordStrength() {
            return this.checkPasswordStrength(this.password);
        },
        passwordStrengthColor() {
            const {strength} = this.passwordStrength;
            if (strength >= 100) {
                return "seaGreen";
            } else if (strength > 50) {
                return "info";
            } else if (strength > 25) {
                return "gold";
            } else return "red";
        },
        doPasswordsMatch() {
            return this.password === this.password_confirmation;
        },
    },
    methods: {
        signUp() {
            this.loading = true;
            let first_name = this.first_name;
            let last_name = this.last_name;
            let email = this.email;
            let password = this.password;
            let password_confirmation = this.password_confirmation;
            let remember = this.remember;
            this.$store
                .dispatch(REGISTER, {
                    first_name,
                    last_name,
                    email,
                    password,
                    password_confirmation,
                    remember,
                })
                .then(() => {
                    this.loading = false;
                    let currentRoute = this.$router.currentRoute.path;
                    let destinationRoute = "dashboard";

                    switch (currentRoute) {
                        case '/applicant-portal':
                        case '/scholarship-application':
                        case '/renew-application':
                            destinationRoute = "households";
                            break;
                        case '/corporate-donation-request-online-form':
                            destinationRoute = "corporate-donation-request-online-form";
                            break;
                        case '/corporate-portal':
                            destinationRoute = "companies";
                            break;
                        case '/donor-portal':
                            destinationRoute = "donor";
                            break;
                        case '/school-portal':
                        case '/partner-with-us':
                        case '/renew-partnership':
                            destinationRoute = "schools";
                            break;
                    }

                    this.$router.push({name: destinationRoute});
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        },
        togglePasswordVisibility() {
            this.passwordVisibility = !this.passwordVisibility;
        },
        showCheckDataByEmailForm() {
            this.$store.commit(SET_CHECK_DATA_BY_EMAIL_FORM, true);
        },
    },
};
</script>

<style scoped>
.first-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 1.2rem;
    font-weight: bold;
}

.second-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 1rem;
}

.list-item-title {
    font-family: 'Roboto Slab', serif;
    font-size: .9rem;
    font-weight: bold;
    margin-top: 10px;
}

.list-item-content {
    font-family: 'Roboto Slab', serif;
    font-size: 0.8rem;
}

.section {
    background-color: var(--apsto-teal-very-light);
    border: 1px solid var(--apsto-teal);
    padding: 20px;
}
</style>
