<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row
            v-if="!isMobile"
            align="center"
        >
            <v-col md="6">
                <household-header page-title="Applications" />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn
                    :to="{
                        name: 'household-application-create',
                        params: { household_id: $route.params.household_id },
                    }"
                    class="mt-4"
                    color="primary"
                    rounded
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    {{ createUpdateApplication }}
                </v-btn>
            </v-col>
        </v-row>

        <v-spacer />
        <v-data-table
            :class="isMobile ? '' : 'mt-5'"
            :disable-sort="isMobile"
            :headers="headers"
            :items="applications"
            class="elevation-2"
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar
                    v-if="isMobile"
                    color="secondary"
                    flat
                >
                    <v-row>
                        <v-col>
                            List of Applications
                        </v-col>
                        <v-col cols="auto">
                            <v-icon
                                color="white"
                                rounded
                                @click="addItem"
                            >
                                mdi-plus-circle-outline
                            </v-icon>

                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template #item.status_name="{ item }">
                <div v-if="item.status === 2">
                    <v-icon
                        class="mr-1"
                        color="secondary"
                    >fa-lock
                    </v-icon>
                    Locked while in review by APSTO
                </div>
                <div v-else>{{ item.status_name }}</div>
            </template>
            <template v-slot:item.actionsApplication="{ item }">
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :disabled="disableLink(item)"
                            class="mr-2"
                            color="iconCyan"
                            v-bind="attrs"
                            @click="editApplication(item)"
                            v-on="on"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Edit {{ item.is_preregistration ? 'Pre-Registration' : 'Application' }}</span>
                </v-tooltip>

                <v-tooltip
                    v-if="!item.is_preregistration"
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-2"
                            color="primary"
                            v-bind="attrs"
                            @click="viewApplication(item)"
                            v-on="on"
                        >
                            mdi-format-list-bulleted
                        </v-icon>
                    </template>
                    <span>Review & Edit Application }}</span>
                </v-tooltip>

                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-2"
                            color="secondary"
                            v-bind="attrs"
                            @click="sendEmail(item)"
                            v-on="on"
                        >
                            mdi-email
                        </v-icon>
                    </template>
                    <span>Email Me A Copy</span>
                </v-tooltip>
                <v-icon
                    :disabled="disableLink(item)"
                    color="red"
                    @click="deleteApplication(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialogDelete"
            :fullscreen="!!isMobile"
            :max-width="dialogMaxWidth"
        >
            <v-card class="pb-5 dialog">
                <v-card-title class="pa-4">
                    Confirm
                </v-card-title>
                <v-card-text class="mx-n2">
                    <v-row class="mt-2">
                        <v-col class="text-h6-mulish">
                            Delete application?
                        </v-col>
                    </v-row>
                </v-card-text>
                <div
                    v-if="isMobile"
                    class="px-4"
                >
                    <v-btn
                        block
                        class="cancel-button"
                        rounded
                        @click="closeDeleteApplication"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        block
                        class="mt-5"
                        color="primary"
                        rounded
                        @click="destroyApplication"
                    >
                        Yes, Delete Application
                    </v-btn>
                </div>
                <v-card-actions v-else>
                    <v-btn
                        class="cancel-button"
                        rounded
                        @click="closeDeleteApplication"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        class="ml-2"
                        color="primary"
                        rounded
                        @click="destroyApplication"
                    >
                        Yes, Delete Application
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import Loading from "../../../../components/shared/Loading";
import {
    DELETE_HOUSEHOLD_APPLICATION,
    EMAIL_HOUSEHOLD_APPLICATION,
    GET_HOUSEHOLD_APPLICATIONS,
} from "../../../../store/actions.type";
import CompanyHeader from "../../../../components/company/CompanyHeader.vue";

export default {
    name: "ApplicationList",
    components: {
        CompanyHeader,
        HouseholdHeader,
        Loading,
    },
    data() {
        return {
            defaultItem: {
                date_submitted: "",
                name: "",
                status_name: "",
            },
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "School Year", align: "start", value: "name", },
                { text: "Status", value: "status_name" },
                { text: "Date Submitted to APSTO", value: "date_submitted" },
                { text: "Actions", value: "actionsApplication", sortable: false, align: "end" },
            ],
            loading: false,
            sending: false,
            snackbar: false,
            snackbarMessage: "",

            tab: null
        };
    },
    computed: {
        applications() {
            return this.$store.getters.currentApplications;
        },
        createUpdateApplication() {
            return this.applications.length ? "Update Application" : "Create Application";
        },
    },
    methods: {
        addItem() {
            let household_id = this.$route.params.household_id;
            this.$router.push({ name: 'household-application-create', params: { household_id: household_id } });
        },
        getApplications() {
            this.loading = true;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATIONS, this.$route.params.household_id).then(() => {
                this.loading = false;
            });
        },
        closeDeleteApplication() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        disableLink(item) {
            return !item.show_link;
        },
        deleteApplication(item) {
            this.editedIndex = this.applications.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        destroyApplication() {
            this.saving = true;
            let application_id = this.editedItem.id;
            let household_id = this.$route.params.household_id;
            this.$store
                .dispatch(DELETE_HOUSEHOLD_APPLICATION, {
                    household_id,
                    application_id,
                })
                .then(() => {
                    this.saving = false;
                    this.applications.splice(this.editedIndex, 1);
                    this.closeDeleteApplication();
                })
                .catch(({ data }) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                    this.dialogDelete = false;
                });
        },
        editApplication(item) {
            let household_id = this.$route.params.household_id;
            this.$router.push(`/dashboard/households/${household_id}/applications/${item.id}/edit`);
        },
        viewApplication(item) {
            let household_id = this.$route.params.household_id;
            this.$router.push(`/dashboard/households/${household_id}/applications/${item.id}/show`);
        },
        initialize() {
            this.applications = [];
        },
        sendEmail(item) {
            this.editedIndex = this.applications.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.sending = true;
            let application_id = this.editedItem.id;
            let household_id = this.$route.params.household_id;
            this.$store
                .dispatch(EMAIL_HOUSEHOLD_APPLICATION, { application_id, household_id })
                .then((response) => {
                    this.sending = false;
                    this.snackbarMessage = response.message;
                    this.snackbar = true;
                })
                .catch((response) => {
                    console.log(response);
                    this.sending = false;
                });
        },
    },
    mounted() {
        this.getApplications();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDeleteApplication();
        },
    },
};
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}
</style>
