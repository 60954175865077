<template>
  <v-card>
    <v-card-title>
      <span class="text-h5-mulish">
          Break Link
      </span>
      <div class="px-0 pt-2 pb-4 text-h6-mulish" style="line-height: 1.25;">
        <span style="color: red;">You are about to unlink {{ linkedStudentName }}</span>. They will no longer be associated with {{ studentName }}.
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="mt-2" cols="12">
          <div class="text-h6-mulish">
            Student Name Provided By Donor
            <div class="font-weight-bold">{{ linkedStudentName }}</div>
          </div>
          <div class="mt-4">
            <div class="text-h6-mulish">
              Actual Student You Are About To Unlink From
              <div class="font-weight-bold">{{ studentName }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="py-6">
      <v-spacer/>
      <v-btn @click="cancel"> Stop, Don't Break Link</v-btn>
      <v-btn color="primary" @click="breakLink"> Break Link</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>


import {UNLINK_DONOR_STUDENT_RECOMMENDATION} from "../../../../../../store/actions.type";

export default {
  name: "AdminSchoolUnlinkStudentConfirm",
  props: {
    studentName: {
      required: true,
      type: String
    },
    linkedStudentName: {
      required: true,
      type: String
    },
  },
  data: () => ({
    saving: false,
  }),
  computed: {
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },

  },
  methods: {
    cancel() {
      this.$emit("done");
    },
    breakLink() {
      this.saving = true;
      let donor_selection_student_id = this.$route.params.donor_selection_student_id;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(UNLINK_DONOR_STUDENT_RECOMMENDATION, {
        donor_selection_student_id,
        school_id
      })
        .then(() => {
          this.saving = false;
          this.$emit("done");
          this.$router.push(`/dashboard/admin/schools/${school_id}/edit`);
        })
        .catch((response) => {
          console.log("Error: ", response)
        });
    },
  },

};
</script>

<style scoped>
.errors-message {
  color: red;
  padding-left: 5px;
}
</style>
