import { render, staticRenderFns } from "./UserProfileMenu.vue?vue&type=template&id=67b6f3fd&scoped=true&"
import script from "./UserProfileMenu.vue?vue&type=script&lang=js&"
export * from "./UserProfileMenu.vue?vue&type=script&lang=js&"
import style0 from "./UserProfileMenu.vue?vue&type=style&index=0&id=67b6f3fd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b6f3fd",
  null
  
)

export default component.exports