<template>
  <v-card class="dialog">
    <v-card-title class="pa-4">
      Annual Income
    </v-card-title>
    <div
      v-if="!application.agree_to_income_reporting_instructions"
      class="pb-5 px-5"
    >
      <div style="border-top: 1px solid var(--apsto-border); padding-top: 20px;">
        Please close this window then check the box in the Household Income Instructions section to confirm that you
        have read and understand the instructions concerning household income.
      </div>
    </div>

    <v-row
      v-if="application.agree_to_income_reporting_instructions"
      no-gutters
    >
      <v-col class="mx-2 mx-md-4">
        <div class="instructions-box mx-n2 mx-md-n4">
          The following financial information for {{ editedItem.full_name }} must match your
          {{ priorCalendarYear }}
          taxes exactly. List the type of income received on a yearly basis.
        </div>
        <div v-if="!incomeReported">
          <v-checkbox
            v-model="editedItem.income_none"
            :disabled="!application.agree_to_income_reporting_instructions"
            :error="!!errors['income_none']"
            :error-messages="errors['income_none'] ? errors['income_none'] : null"
          >
            <template v-slot:label>
              <div>
                {{ editedItem.full_name }} has no income.
              </div>
            </template>
          </v-checkbox>
        </div>
        <div v-if="!editedItem.income_none">
          <div
            :class="incomeReported ? 'mt-4' : ''"
            class="income_type_box pa-4 mb-4"
          >
            <h4>
              Annual Work Earnings
            </h4>
            <div class="my-2">
              List gross earnings from work (wages, salaries, tips, commissions). This is
              not the same as take home pay; it is the amount earned before taxes and
              deductions. Gross earnings should be listed on your pay stub. If self
              employed, you may report income after expenses (your own business, freelance
              work, farm, or rental property).
            </div>
            <v-currency-field
              id="income_earnings"
              v-model.number="editedItem.income_earnings"
              :disabled="!application.agree_to_income_reporting_instructions"
              :error="!!errors['income_earnings']"
              :error-messages="errors['income_earnings'] ? errors['income_earnings'] : null"
              label="Enter Work Earnings"
              type="number"
            />
          </div>
          <div class="income_type_box pa-4 mb-4">
            <h4>
              Annual Welfare, Child Support, Spousal Maintenance
            </h4>
            <div class="my-2">
              Include TANF, General Assistance, General Relief, etc. NOTE: Food Stamps and
              FDPIR benefits are not included as income.
            </div>
            <v-currency-field
              id="income_welfare"
              v-model.number="editedItem.income_welfare"
              :disabled="!application.agree_to_income_reporting_instructions"
              :error="!!errors['income_welfare']"
              :error-messages="errors['income_welfare'] ? errors['income_welfare'] : null"
              label="Enter Welfare, Child Support, Spousal Maintenance"
              type="number"
            />
          </div>
          <div class="income_type_box pa-4 mb-4">
            <h4>
              Annual Pensions, Retirement and Social Security
            </h4>
            <div class="my-2">
              Include Supplemental Security Income (SSI), Veteran’s (VA) benefits, and
              disability benefits.
            </div>
            <v-currency-field
              id="income_pension"
              v-model.number="editedItem.income_pension"
              :disabled="!application.agree_to_income_reporting_instructions"
              :error="!!errors['income_pension']"
              :error-messages="errors['income_pension'] ? errors['income_pension'] : null"
              label="Enter Pensions, Retirement and Social Security"
              type="number"
            />
          </div>
          <div class="income_type_box pa-4 mb-4">
            <h4>
              All Other Annual Income
            </h4>
            <div class="my-2">
              Include Worker’s Compensations, unemployment, strike benefits, net rental
              income, annuities, net royalties, interest, dividend income, cash withdrawn
              from savings, income from estates, trust and / or investments, regular
              contributions from people who do not live in your household, and ANY OTHER
              INCOME.
            </div>
            <v-currency-field
              id="income_other"
              v-model.number="editedItem.income_other"
              :disabled="!application.agree_to_income_reporting_instructions"
              :error="!!errors['income_other']"
              :error-messages="errors['income_other'] ? errors['income_other'] : null"
              label="Enter All Other Income"
              type="number"
            />
          </div>
        </div>
        <div
          v-if="editedItem.income_none && editedItem.type === 2"
          class="instructions-box mb-5"
        >
          <v-row>
            <v-col cols="auto">
              <v-icon color="secondary">fa-exclamation-circle</v-icon>
            </v-col>
            <v-col>
              <div class="ml-n3">
                Note: At least one member of a household must report income. We can't
                process applications that report no household income.
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-card-actions
      :class="isMobile ? 'flex-wrap' : ''"
      class="mx-2 mx-sm-0 pb-5"
    >
      <div v-if="error !== null">
        <span class="errors-message">Please scroll up to see errors</span>
      </div>
      <v-btn
        :block="isMobile"
        class="cancel-button mx-0 mb-2 mr-sm-2"
        rounded
        @click="$emit('done')"
      >
        Cancel
      </v-btn>

      <v-btn
        v-if="application.agree_to_income_reporting_instructions"
        :block="isMobile"
        class="mx-0 mb-2"
        color="primary"
        rounded
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>


  </v-card>
</template>

<script>
import {
  GET_HOUSEHOLD_APPLICATION,
  GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS,
  PATCH_HOUSEHOLD_APPLICATION_MEMBER_INCOME
} from "../../../store/actions.type";

export default {
  name: "FamilyMemberIncome",
  props: {
    editedItem: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      checkbox: false,
    }
  },
  computed: {
    application() {
      return this.$store.getters.currentApplication;
    },
    incomeReported() {
      if (
        this.editedItem.income_earnings ||
        this.editedItem.income_other ||
        this.editedItem.income_pension ||
        this.editedItem.income_welfare
      ) {
        return true;
      }
      return false;
    },
    priorCalendarYear() {
      return parseInt(this.application.school_year_start_year.substring(0, 4)) - 1;
    },
    error() {
      return this.$store.getters.getMessage("application");
    },
    errors() {
      return this.$store.getters.getErrors("application");
    },
  },
  methods: {
    save() {
      this.saving = true;
      let application_id = this.$route.params.application_id;
      let household_id = this.$route.params.household_id;
      let applicationMember = this.editedItem;

      applicationMember.application_id = this.application.id;

      console.log('applicationMember', applicationMember);

      this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_MEMBER_INCOME,
        {
          household_id,
          application_id,
          applicationMember,
        }
      )
        .then(() => {
          this.$store.dispatch(GET_HOUSEHOLD_APPLICATION, {
            household_id,
            application_id,
          })
            .then(() => {
              this.updateScholarships();
              this.saving = false;
              this.$emit("done");
            });
        });
    },
    updateScholarships() {
      const application_id = this.$route.params.application_id;
      const household_id = this.$route.params.household_id;
      this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS, {
        household_id,
        application_id,
      });
    },

  },
}
</script>

<style scoped>
::v-deep .v-messages__message {
  line-height: 15px;
}

.income_type_box {
  border: 1px dotted var(--apsto-teal);
}

.instructions-box {
  font-family: "Mulish", sans-serif;
  background-color: var(--apsto-teal-very-light);
  padding: 15px;
}

.insert {
  background-color: var(--apsto-teal-light);
  padding-left: 22px;
  padding-right: 22px;
}
</style>
