import NotFound from "../views/NotFound";
import OldCustomResourceNotFound from "../views/OldCustomResourceNotFound";
export default [
    {
        path: '/family-lp/*/*',
        component: OldCustomResourceNotFound,
    },
    {
        path: '/child-lp/*/*',
        component: OldCustomResourceNotFound,
    },
    {
        path: '*',
        component: NotFound,
    }
]
