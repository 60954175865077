<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row
            justify="center"
            no-gutters
        >
            <v-col
                cols="12"
                lg="6"
                md="8"
                sm="10"
            >
                <household-header page-title="Custom Resources" />

                <v-card class="my-2">
                    <v-card-title class="pt-5 px-4 font-weight-black">
                        Instructions to Create a Donation Page for {{ pageIdentifier }}
                    </v-card-title>
                    <v-card-text style="color: black; font-size: 1rem">
                        You will be building a one-page donation website designed to help
                        you share and explain the tax credit program to family and friends
                        so they can recommend your student(s) for scholarships.
                        <div class="mt-4">
                            Please click on the steps below to make your page look the way you'd
                            like it to.
                        </div>
                    </v-card-text>
                </v-card>

                <h2 class="my-4">Steps:</h2>

                <!--Choose Caption and Colors for Top Banner & Buttons-->
                <v-expansion-panels
                    v-model="openPanels"
                    multiple
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header class="expansion-header">
                            <v-row>
                                <v-col
                                    class="section-number"
                                    cols="auto"
                                >
                                    1
                                </v-col>
                                <v-col class="section-title">
                                    Choose Caption and Colors for Top Banner & Buttons & Wallpaper
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                            <v-row no-gutters>
                                <v-col>
                                    <v-row no-gutters>
                                        <v-col
                                            class="mr-10"
                                            cols="12"
                                            sm="5"
                                        >
                                            <div class="caption-text">Banner Background Color</div>
                                            <v-color-picker
                                                v-model="promo.banner_background_color"
                                                class="no-alpha"
                                                dot-size="50"
                                                label="Banner Background Color"
                                                mode="hexa"
                                                show-swatches
                                                swatches-max-height="200"
                                            />
                                            <v-btn
                                                class="mt-6"
                                                color="secondary"
                                                style="width: 300px;"
                                                @click="resetBackgroundColor"
                                            >
                                                <span style="color: white; font-weight: 900">
                                                    Reset Background Color
                                                </span>
                                            </v-btn>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="5"
                                        >
                                            <div class="caption-text">Banner Text Color</div>
                                            <v-color-picker
                                                v-model="promo.banner_text_color"
                                                class="no-alpha"
                                                dot-size="50"
                                                label="Banner Text Color"
                                                mode="hexa"
                                                show-swatches
                                                swatches-max-height="200"
                                            />
                                            <v-btn
                                                class="mt-6"
                                                color="secondary"
                                                style="width: 300px;"
                                                @click="resetTextColor"
                                            >
                                                <span style="color: white; font-weight: 900">
                                                    Reset Text Color
                                                </span>
                                            </v-btn>
                                        </v-col>
                                        <v-col
                                            class="mr-10"
                                            cols="12"
                                            sm="5"
                                        >
                                            <div class="caption-text">Secondary Background Color</div>
                                            <v-color-picker
                                                v-model="promo.secondary_background_color"
                                                class="no-alpha"
                                                dot-size="50"
                                                label="Secondary Background Color"
                                                mode="hexa"
                                                show-swatches
                                                swatches-max-height="200"
                                            />
                                            <v-btn
                                                class="mt-6"
                                                color="secondary"
                                                style="width: 300px;"
                                                @click="resetSecondaryBackground"
                                            >
                                                <span style="color: white; font-weight: 900">
                                                    Reset Secondary Back Color
                                                </span>
                                            </v-btn>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="5"
                                        >
                                            <div class="caption-text">Secondary Text Color</div>
                                            <v-color-picker
                                                v-model="promo.secondary_text_color"
                                                class="no-alpha"
                                                dot-size="50"
                                                label="Secondary Text Color"
                                                mode="hexa"
                                                show-swatches
                                                swatches-max-height="200"
                                            />
                                            <v-btn
                                                class="mt-6"
                                                color="secondary"
                                                style="width: 300px;"
                                                @click="resetSecondaryText"
                                            >
                                                <span style="color: white; font-weight: 900">
                                                    Reset Secondary Text Color
                                                </span>
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col
                                    class="mt-5"
                                    cols="12"
                                >
                                    <div class="caption-text">Banner Caption</div>
                                    <v-text-field
                                        v-model="promo.banner_caption"
                                        solo
                                    />
                                    <div class="caption-text pb-3 pt-0">Sample Banner and Button Using the Colors You've
                                        Selected
                                    </div>
                                    <div
                                        :style="`color: ${promo.banner_text_color}; background-color:${promo.banner_background_color}`"
                                        class="pa-4 mt-2 font-weight-bold text-center"
                                        v-html="promo.banner_caption"
                                    />
                                    <v-btn
                                        :style="`color: ${promo.banner_text_color}; background-color:${promo.banner_background_color}`"
                                        block
                                        class="px-4 py-6 mt-5 font-weight-bold text-center your-promo-link"
                                        rounded
                                    >
                                        Donate Now
                                    </v-btn>
                                    <v-btn
                                        :style="`color: ${promo.secondary_text_color}; background-color:${promo.secondary_background_color}`"
                                        block
                                        class="px-4 py-6 mt-5 font-weight-bold text-center your-promo-link"
                                        rounded
                                    >
                                        Secondary Colors
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row
                                no-gutters
                                style="text-align: center"
                            >
                                <v-col cols="12">
                                    <v-dialog
                                        v-model="wallpaperDialog"
                                        width="1000px"
                                    >
                                        <wallpaper-selector
                                            @cancel="wallpaperDialog = false"
                                            @done="(hash) => {
                                                promo.site_wallpaper_id = hash;
                                                wallpaperDialog = false;
                                            }
                                                "
                                        />
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="mt-5"
                                                color="primary"
                                                v-bind="attrs"
                                                x-large
                                                v-on="on"
                                            >
                                                Click Me to Choose a Wallpaper
                                            </v-btn>
                                        </template>
                                    </v-dialog>
                                    <v-img
                                        v-if="promo.site_wallpaper_id"
                                        :src="`/website/siteWallpapers/${promo.site_wallpaper_id}/thumb/`"
                                        class="mt-5"
                                        contain
                                    />

                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!--Add Photos-->
                    <v-expansion-panel>
                        <v-expansion-panel-header class="expansion-header">
                            <v-row>
                                <v-col
                                    class="section-number"
                                    cols="auto"
                                >2
                                </v-col>
                                <v-col class="section-title"> Add Photos</v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                            <v-row no-gutters>
                                <v-col
                                    class="text-center"
                                    cols="12"
                                    sm="6"
                                >
                                    <v-dialog
                                        v-model="primaryImageDialog"
                                        width="520"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="my-5"
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Upload Primary Image
                                            </v-btn>
                                        </template>

                                        <image-cropper
                                            :aspect-ratio="0.75"
                                            title="Upload Primary Image"
                                            @done="(hash) => imageUploaded('primary_image', hash)"
                                        />
                                    </v-dialog>
                                    <v-img
                                        :src="`/website/uploads/show/${promo.primary_image}`"
                                        contain
                                        label="Primary Image"
                                        min-height="200"
                                        min-width="200"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                align="center"
                                                class="fill-height ma-0"
                                                justify="center"
                                            >
                                                <v-icon size="150">mdi-camera</v-icon>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-col>
                                <v-col
                                    class="text-center"
                                    cols="12"
                                    sm="6"
                                >
                                    <v-dialog
                                        v-model="secondaryImageDialog"
                                        width="520"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="my-5"
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Upload Secondary Image
                                            </v-btn>
                                        </template>

                                        <image-cropper
                                            :aspect-ratio="0.75"
                                            title="Upload Secondary Image"
                                            @done="(hash) => imageUploaded('secondary_image', hash)"
                                        />
                                    </v-dialog>

                                    <v-img
                                        :src="`/website/uploads/show/${promo.secondary_image}`"
                                        contain
                                        label="Secondary Image"
                                        min-height="200"
                                        min-width="200"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                align="center"
                                                class="fill-height ma-0"
                                                justify="center"
                                            >
                                                <v-icon size="150">mdi-camera</v-icon>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!--Add a Message-->
                    <v-expansion-panel>
                        <v-expansion-panel-header class="expansion-header">
                            <v-row>
                                <v-col
                                    class="section-number"
                                    cols="auto"
                                >3
                                </v-col>
                                <v-col class="section-title"> Add a Message</v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                            <v-row no-gutters>
                                <v-col>
                                    <div class="caption-text">Introductory Text</div>
                                    <vue-editor
                                        v-model="promo.introductory_text"
                                        class="white"
                                    />
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="kid"
                                no-gutters
                            >
                                <v-col>
                                    <div class="caption-text">Student Narrative (Optional)</div>
                                    <div class="mb-6">
                                        We've copied what you wrote about this student in your application but you can add
                                        anything here or remove everything by highlighting then clicking backspace or
                                        delete.
                                        Most families write one or two short paragraphs.
                                    </div>
                                    <vue-editor
                                        v-model="promo.child_narrative"
                                        class="white"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <div class="caption-text">Footer Text</div>
                                    <vue-editor
                                        v-model="promo.footer_text"
                                        class="white"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <div class="caption-text">Your Signature</div>
                                    <vue-editor
                                        v-model="promo.signature"
                                        class="white"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <div class="caption-text">"Thank You" Text (Shows after the big Thank You heading at the
                                        very
                                        bottom)
                                    </div>
                                    <vue-editor
                                        v-model="promo.thank_you"
                                        class="white"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!--Select Children to Recommend-->
                    <v-expansion-panel :disabled="children.length === 1">
                        <v-expansion-panel-header class="expansion-header">
                            <v-row>
                                <v-col
                                    class="section-number"
                                    cols="auto"
                                >4
                                </v-col>
                                <v-col class="section-title">
                                    Select Students to Highlight For Donor Recommendations
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                            <div class="mt-5">
                                Uncheck any student whose tuition has already been met. We'll
                                exclude them when showing donors a list of students you would
                                like them to recommend.
                            </div>
                            <div class="mt-5 recommendations-list">
                                <div
                                    v-for="child in children"
                                    :key="child.id"
                                >
                                    <v-checkbox
                                        v-model="promo.recommendations"
                                        :value="child.id"
                                        color="primary"
                                    >
                                        <template v-slot:label>
                                            <div style="color: black">{{ child.name }}</div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!--Share this Donation Page-->
                    <v-expansion-panel>
                        <v-expansion-panel-header class="expansion-header">
                            <v-row>
                                <v-col
                                    class="section-number"
                                    cols="auto"
                                >
                                    5
                                </v-col>
                                <v-col class="section-title">
                                    Save and Share Your Donation Page
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                            <v-row>
                                <v-col class="mt-3 text-center font-weight-bold red--text">
                                    <div v-if="errorMessage">
                                        {{ errorMessage }}
                                    </div>
                                    <div
                                        v-for="(error, error_index) in errors"
                                        :key="error_index"
                                    >
                                        {{ error[0] }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        :disabled="saveButtonDisabled"
                                        block
                                        color="primary"
                                        @click="savePromo"
                                    >
                                        SAVE YOUR DONATION PAGE
                                    </v-btn>
                                    <div v-if="mode === 'edit' || saved">
                                        <v-divider class="mt-5" />
                                        <div class="mt-5">
                                            This is a link to your donation page's address. You can paste it into an
                                            email to friends and family and to social media.
                                            <v-row align="center">
                                                <v-col cols="auto">
                                                    <v-card-text class="pl-0">
                                                        <v-chip
                                                            class="font-weight-bold pa-6"
                                                            color="white"
                                                            label
                                                            text-color="black"
                                                        >
                                                            <div style="font-size: 1rem">
                                                                {{ shareUrl }}
                                                            </div>
                                                        </v-chip>

                                                    </v-card-text>
                                                </v-col>
                                                <v-col>
                                                    <v-btn
                                                        color="secondary"
                                                        style="cursor: pointer"
                                                        @click="copyCode"
                                                        @mouseout="reset"
                                                    >
                                                        {{ copyText }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                        </div>
                                    </div>
                                    <v-divider class="mt-2" />
                                    <div class="mt-5">
                                        Check this box for your page to be visible on the web. If this box is not checked,
                                        people coming to your page will see a message that the page has been withdrawn.
                                        <v-checkbox
                                            v-model="promo.active"
                                            color="primary"
                                        >
                                            <template v-slot:label>
                                                <div style="color: black">
                                                    Display my page on the web
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn
                    v-if="saved"
                    block
                    class="mt-5 success"
                    color="primary"
                    @click="toggleYourPagePreview"
                >
                    <v-icon
                        class="mr-2"
                        color="primaryButton"
                    >{{ yourPagePreview ? 'fas fa-eye-slash' : 'fas fa-eye' }}
                    </v-icon>
                    {{ yourPagePreview ? 'Hide' : 'Show' }} Your Page Preview
                </v-btn>
            </v-col>
        </v-row>

        <v-row
            v-if="yourPagePreview"
            class="mt-8"
            justify="center"
            no-gutters
        >
            <v-col class="text-center">
                <h2>Your Page</h2>
                <v-divider class="mt-2" />
                <your-page-content :promo="promo" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import Loading from "../../../../components/shared/Loading";
import { VueEditor } from "vue2-editor";
import ImageCropper from "../../../../components/shared/ImageCropper";
import YourPageContent from "./YourPageContent";
import WallpaperSelector from "../../../../components/shared/WallpaperSelector";

import {
    CHECK_APPLICATION_PROMO,
    GET_APPLICATION_PROMO,
    GET_HOUSEHOLD_APPLICATION,
    PATCH_APPLICATION_PROMO,
    POST_APPLICATION_PROMO,
} from "../../../../store/actions.type";

import { SET_ERROR } from "../../../../store/mutations.type";

export default {
    name: "PromosAddEdit",
    components: {
        HouseholdHeader,
        Loading,
        VueEditor,
        YourPageContent,
        WallpaperSelector,
        ImageCropper,
    },
    data() {
        return {
            loading: true,
            saved: true,
            promo: null,
            slug: null,
            primaryImageDialog: false,
            secondaryImageDialog: false,
            yourPagePreview: false,
            wallpaperDialog: false,
            copyText: "Copy",
            defaultApplicationPromo: {
                banner_caption: "Creating Scholarships for the %FAMILY% Family!",
                banner_background_color: "#4D226A",
                banner_text_color: "#FFFFFF",
                secondary_background_color: "#55A9AD",
                secondary_text_color: "#FFFFFF",
                site_wallpaper_id: 11,
                primary_image: null,
                secondary_image: null,
                recommendations: [],
                introductory_text: `<p>I'm excited to have our children attending private school.</p><p>I am equally excited to share that the state of Arizona offers a Tax Credit that allows an Arizona taxpayer the ability to take the money they would normally pay in state taxes, and use them instead to help families with children in private schools. In other words, Arizona will allow you to redirect your state taxes and turn them into scholarships for children.\n</p>`,
                child_narrative: "",
                footer_text: `<p>I would be so grateful if you’d consider participating in this program and recommending our children to receive these funds.</p>`,
                signature: null,
                thank_you:
                    "<p>If you would like to make a dollar-for-dollar tax credit donation, please consider recommending our children when completing your donation.</p>",
            },
            defaultMemberPromo: {
                banner_caption: "Creating Scholarships for %KID%!",
                banner_background_color: "#4D226A",
                banner_text_color: "#FFFFFF",
                secondary_background_color: "#55A9AD",
                secondary_text_color: "#FFFFFF",
                site_wallpaper_id: 11,
                primary_image: null,
                secondary_image: null,
                recommendations: [],
                introductory_text: `<p>I'm excited to have %KID% attending %SCHOOL% this year!</p><p>I am equally excited to share that the state of Arizona offers a Tax Credit that allows an Arizona taxpayer the ability to take the money they would normally pay in state taxes, and use them instead to help families with children in private schools. In other words, Arizona will allow you to redirect your state taxes and turn them into scholarships for children.</p>`,
                child_narrative: "",
                footer_text:
                    "<p>I would be so grateful if you'd consider participating in this program and recommending %KID% at %SCHOOL% to receive these funds.</p>",
                signature: null,
                thank_you:
                    "<p>If you would like to make a dollar-for-dollar tax credit donation, please consider recommending %KID% at %SCHOOL% when completing your donation.</p>",
            },
            openPanels: [],
        };
    },
    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        applicationMember() {
            return this.$store.getters.currentApplicationMemberById(
                this.applicationMemberId
            );
        },
        applicationMemberId() {
            return this.$route.params.application_member_id;
        },
        children() {
            return this.$store.getters.currentApplicationMembersFiltered(3);
        },
        kid() {
            return this.pageType === "child" ? this.applicationMember.name : null;
        },
        mode() {
            return this.$route.meta.mode;
        },
        pageIdentifier() {
            if (this.pageType === "child") {
                return "Student: " + this.kid;
            }
            return "the " + this.application.household_name + " Family";
        },
        pageType() {
            return this.$route.params.application_member_id ? "child" : "family";
        },
        saveButtonDisabled() {
            if (!this.promo) {
                return true;
            }
            return !this.promo.primary_image || !!this.saved;
        },
        school() {
            return this.pageType === "child"
                ? this.applicationMember.student.school_name
                : null;
        },
        shareUrl() {
            let household_id = this.$route.params.household_id;

            let url = this.appUrl + "/promos/" + household_id + "/" + this.application.household_slug;

            if (this.pageType === "child") {
                let child_id = this.$route.params.application_member_id;
                url = url + "/" + child_id + "/" + this.applicationMember.slug;
            }

            return url;
        },
        signature() {
            return `<p>${this.user.name}<br/>${this.user.phone}</p>`;
        },
        user() {
            return this.$store.getters.currentUser;
        },
        errors() {
            return this.$store.getters.getErrors("promos");
        },
        errorMessage() {
            return this.$store.getters.getMessage("promos");
        }
    },
    methods: {
        toggleYourPagePreview() {
            this.yourPagePreview = !this.yourPagePreview;
        },
        copyCode() {
            navigator.clipboard.writeText(this.shareUrl);
            this.copyText = "Copied";
        },
        imageUploaded(target, hash) {
            this.promo[target] = hash;
            this.primaryImageDialog = false;
            this.secondaryImageDialog = false;
        },
        reset() {
            this.copyText = "Copy";
        },
        resetBackgroundColor() {
            this.promo.banner_background_color = "#4D226A";
        },
        resetTextColor() {
            this.promo.banner_text_color = "#FFFFFF";
        },
        resetSecondaryBackground() {
            this.promo.secondary_background_color = "#55A9AD";
        },
        resetSecondaryText() {
            this.promo.secondary_text_color = "#FFFFFF";
        },
        getPromo() {
            let household_id = this.$route.params.household_id;
            let application_id = this.$route.params.application_id;

            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION, {
                household_id,
                application_id,
            })
                .then(() => {
                    this.setDefaultPromo();
                    if (this.mode === "edit") {
                        console.log(this.$route.params.promo_id);
                        let promo_id = this.$route.params.promo_id;
                        this.$store.dispatch(GET_APPLICATION_PROMO, promo_id).then(() => {
                            this.promo = this.$store.getters.currentPromo;
                            this.loading = false;

                        }).then(() => {
                            this.saved = true;
                            console.log("Saved true");
                        });
                    } else {
                        this.openPanels = [2];
                        this.loading = false;
                        this.saved = true;
                        console.log("Saved true");
                    }
                });
        },
        async savePromo() {
            let self = this;
            let application_id = this.$route.params.application_id;
            let household_id = this.$route.params.household_id;
            let promo = this.promo;
            let shouldSave = false;

            if (this.pageType === "child") {
                promo.application_promoable_id =
                    this.$route.params.application_member_id;
                promo.application_promoable_type = "ApplicationMember";
            } else {
                promo.application_promoable_id = application_id;
                promo.application_promoable_type = "Application";
            }

            if (this.mode === 'create') {
                let application_promoable_type = promo.application_promoable_type;
                let application_promoable_id = promo.application_promoable_id;

                //Check if already exists
                await this.$store.dispatch(CHECK_APPLICATION_PROMO, {
                    application_promoable_type,
                    application_promoable_id
                })
                    .then((data) => {
                        if (data.exists) {
                            this.$store.commit(SET_ERROR, {
                                target: "promos",
                                message: "Promo already exists for this application"
                            });
                            this.loading = false;

                        } else {
                            shouldSave = true;
                        }
                    })
                    .catch((e) => {
                        this.$store.commit(SET_ERROR, {
                            target: "promos",
                            message: "Error checking if promo already exists. Please try again."
                        });
                        this.loading = false;

                    });

                if (!shouldSave) {
                    return;
                }

                this.$store.dispatch(POST_APPLICATION_PROMO, { household_id, application_id, promo })
                    .then(() => {
                        this.saved = true;
                        console.log("Saved true");
                        this.promo = this.$store.getters.currentPromo;

                        if (application_promoable_type === "ApplicationMember") {
                            this.$router.push({
                                name: "application-member-promo-edit",
                                params: {
                                    household_id,
                                    application_id,
                                    application_member_id: application_promoable_id,
                                    promo_id: this.promo.id,
                                },
                            });
                        } else {
                            this.$router.push({
                                name: "application-promo-edit",
                                params: {
                                    household_id,
                                    application_id,
                                    promo_id: this.promo.id,
                                },
                            });
                        }

                    })
                    .catch((e) => {
                        this.loading = false;
                    });
            } else {
                let promo_id = this.promo.id;
                this.$store.dispatch(PATCH_APPLICATION_PROMO, { household_id, application_id, promo_id, promo })
                    .then(() => {
                        this.promo = this.$store.getters.currentPromo;
                        this.loading = false;
                        self.$nextTick(() => {
                            self.saved = true;
                            console.log("Saved true");
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                        this.loading = false;
                    });
            }

        },
        setDefaultPromo() {
            let school = this.school;
            let signature = this.signature;
            let varObj;

            if (this.pageType === "child") {
                this.promo = this.defaultMemberPromo;
                this.promo.active = 1;
                this.promo.recommendations = [this.applicationMemberId];
                this.promo.child_narrative =
                    "<p>" + this.applicationMember.student.comments + "</p>";

                let kid = this.kid;
                varObj = {
                    "%KID%": kid,
                    "%SCHOOL%": school,
                };
            } else {
                this.promo = this.defaultApplicationPromo;
                this.promo.active = 1;
                this.promo.recommendations = this.children.map(child => {
                    return child.id
                })
                let familyName = this.application.household_name;

                varObj = {
                    "%FAMILY%": familyName,
                    "%SCHOOL%": school,
                };
            }

            let regexp = new RegExp(Object.keys(varObj).join("|"), "gi");

            Object.keys(this.promo).forEach((key) => {
                if (key === "signature") {
                    this.promo[key] = signature;
                } else {
                    if (
                        (typeof this.promo[key] === "string" ||
                            this.promo[key] instanceof String) &&
                        this.promo[key].length
                    ) {
                        this.promo[key] = this.promo[key].replace(regexp, (matched) => {
                            return varObj[matched];
                        });
                    }
                }
            });
        },
    },
    mounted() {
        this.getPromo();
    },
    watch: {
        promo: {
            handler(newValue, oldValue) {
                console.log("Promo Modified");
                this.saved = false;
            },
            deep: true
        },
        saveButtonDisabled(newValue, oldValue) {
            if (!newValue) {
                if (!this.openPanels.includes(5)) {
                    this.openPanels.push(5);
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.no-alpha {
    .v-color-picker__controls {
        .v-color-picker__preview {
            .v-color-picker__sliders {
                .v-color-picker__alpha {
                    display: none;
                }
            }
        }

        .v-color-picker__edit {
            .v-color-picker__input:last-child {
                display: none;
            }
        }
    }
}

.expansion-header {
    min-height: unset !important;
    padding: 11px 24px !important;
}

.v-expansion-panel-content {
    padding-bottom: 20px;
}

.caption-text {
    font-size: 1.125rem;
    font-weight: 900;
    padding: 20px 0;
}

.recommendations-list .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

.separator-box {
    background-color: var(--apsto-teal);
    color: white;
    font-weight: 900;
    padding: 20px;
}

.section-number {
    background-color: var(--apsto-teal);
    font-weight: 700;
    margin-left: -12px;
    padding: 20px;
    color: white;
}

.section-title {
    padding: 20px;
}

::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    opacity: 0;
}

::v-deep .theme--light.v-icon {
    color: var(--apsto-purple);
}

::v-deep button.success {
    background-color: var(--apsto-teal) !important;
    border-color: var(--apsto-teal) !important;
}

::v-deep .ql-container {
    font-family: Mulish, sans-serif;
}</style>
