import ApiService from "../api/api.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    REGET_CONVERSATIONS,
    GET_CONVERSATIONS,
    START_CONVERSATION,
    DELETE_CONVERSATION,
    ACCEPT_CONVERSATION,
    IGNORE_CONVERSATION,
    READ_CONVERSATION,
    GET_MESSAGES,
    GET_CHATS,
    UPDATE_CHAT,
    DELETE_CHAT,
    POST_MESSAGE,
    ADD_MESSAGE,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
} from "../store/actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_CONVERSATION,
    CLEAR_CONVERSATIONS,
    UPSERT_CONVERSATION,
    APPEND_CONVERSATIONS,
    UPDATE_CONVERSATION,
    REMOVE_CONVERSATION,
    SHOW_CONVERSATIONS,
    MARK_CONVERSATION_READ,
    CLEAR_MESSAGES,
    CLEAR_CHATS,
    RESET_CONVERSATIONS,
    UPREPLACE_MESSAGE,
    APPEND_MESSAGES,
    APPEND_MESSAGE,
    REMOVE_MESSAGE,
    EDIT_MESSAGE,
    SET_MESSAGE,
    SORT_CONVERSATIONS,
    APPEND_CHATS,
    APPEND_CHAT,
    UPREPLACE_CHAT,
    REMOVE_CHAT,
    SET_CHAT,
    SHOW_MESSAGES,
    OPEN_CONVERSATION_DIALOG,
    TOGGLE_CONVERSATION_DIALOG,
    CLOSE_CONVERSATION_DIALOG,
    OPEN_MESSAGES_DIALOG,
    TOGGLE_MESSAGES_DIALOG,
    CLOSE_MESSAGES_DIALOG,
    CLEAR_EDITING_MESSAGE,
    SHOW_CHAT_BADGE,
    CLEAR_CHAT_BADGE,
} from "../store/mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        conversations: [],
        conversation: null,
        chats: [],
        conversationsIdentifier: new Date(),
        messagesIdentifier: new Date(),
        chatsIdentifier: new Date(),
        messages: [],
        showing_conversation_dialog: false,
        showing_messages_dialog: false,
        loading_messages: false,
        editing_message: null,
        hasChatBadge: false,
    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    conversations(state) {
        return state.conversations;
    },
    getCurrentConversation(state) {
        return state.conversation;
    },
    messages(state) {
        return state.messages;
    },
    editingMessage(state) {
        return state.editing_message;
    },
    chats(state) {
        return state.chats;
    },
    isShowingConversationDialog(state) {
        return state.showing_conversation_dialog;
    },
    isShowingMessagesDialog(state) {
        return state.showing_messages_dialog;
    },
    getConversationsIdentifier(state) {
        return state.conversationsIdentifier;
    },
    getMessagesIdentifier(state) {
        return state.messagesIdentifier;
    },
    getChatsIdentifier(state) {
        return state.chatsIdentifier;
    },
    getHasUnreadMessages(state) {
        if (!state.messages) {
            return false;
        }
        return state.messages.some(function(message) {
            return message.unread == true;
        });
    },
    getHasUnreadConversations(state) {
        if (!state.conversations) {
            return false;
        }
        return state.conversations.some(function(conversation) {
            return conversation.has_unread;
        });
    },
    getHasChatBadge(state) {
        return state.hasChatBadge;
    }
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [RESET_CONVERSATIONS](state) {
        console.log('RESET_CONVERSATIONS');
        state.showing_conversation_dialog = false;
        state.showing_messages = false;
        state.conversations = [];
        state.conversationsIdentifier = new Date;
        state.messages = [];
        state.messagesIdentifier = new Date;
        state.chats = [];
        state.chatsIdentifier = new Date;
    },
    [CLEAR_CONVERSATIONS](state) {
        console.log('CLEAR_CONVERSATIONS');
        state.conversations = [];
        state.conversationsIdentifier = new Date;
    },
    [UPSERT_CONVERSATION](state,data) {
        console.log('UPSERT_CONVERSATION');
        upsert(state.conversations,data);
    },
    [APPEND_CONVERSATIONS](state,data) {
        console.log('APPEND_CONVERSATIONS');
        data.forEach((conversation) => {
            state.conversations.push(conversation);
        });
    },
    [SET_CONVERSATION](state, data) {
        console.log('SET_CONVERSATION');
        state.conversation = data;
        state.messages = [];
        state.messagesIdentifier = new Date;
    },
    [UPDATE_CONVERSATION](state, data) {
        console.log('UPDATE_CONVERSATION');
        for (var i=0; i < state.conversations.length; i++) {
            if (state.conversations[i].id == data.id) {
                state.conversations.splice(i,1,data);
            }
        }
    },
    [SORT_CONVERSATIONS](state) {
        console.log('SORT_CONVERSATIONS');
        state.conversations.sort((a, b) => moment(b.updated_at).unix() - moment(a.updated_at).unix());
    },
    [MARK_CONVERSATION_READ](state, id) {
        console.log('MARK_CONVERSATION_READ');
        for (var i=0; i < state.conversations.length; i++) {
            if (state.conversations[i].id == id) {
                state.conversations[i].unread_count = 0;
            }
        }
    },
    [REMOVE_CONVERSATION](state, id) {
        console.log('REMOVE_CONVERSATION');
        for (var i=0; i < state.conversations.length; i++) {
            if (state.conversations[i].id == id) {
                state.conversations.splice(i,1);
            }
        }
    },
    [CLEAR_MESSAGES](state) {
        console.log('CLEAR_MESSAGES');
        state.messages = [];
        state.messagesIdentifier = new Date;
    },
    [CLEAR_CHATS](state) {
        console.log('CLEAR_CHATS');
        state.chats = [];
        state.chatsIdentifier = new Date;
    },
    [APPEND_MESSAGES](state, data) {
        console.log('APPEND_MESSAGES');
        data.forEach((message) => {
            state.messages.unshift(message);
        });
    },
    [APPEND_MESSAGE](state, data) {
        console.log('APPEND_MESSAGE');

        if (data.conversation_id == state.conversation.id) {
            state.messages.push(data);
        }

        var unread_count = 1;
        if (
            data.conversation_id                === state.conversation.id &&
            state.showing_conversation_dialog   === true &&
            state.showing_messages              === true
        ) {
            unread_count = 0;
        }

        for (var i=0; i < state.conversations.length; i++) {
            if (state.conversations[i].id == data.conversation_id) {
                const conversation = state.conversations[i];
                if (data.mine) {
                    conversation.body = 'You: ' + data.body;
                    conversation.unread_count = unread_count;
                    conversation.updated_at = data.updated_at;
                } else {
                    conversation.title = data.username;
                    conversation.picture = data.picture;
                    conversation.body = data.body;
                    conversation.unread_count = unread_count;
                    conversation.updated_at = data.updated_at;
                }
            }
        }
    },
    [UPREPLACE_MESSAGE](state,data) {
        console.log('UPREPLACE_MESSAGE');
        upreplace(state.messages,data);
    },
    [APPEND_CHATS](state, data) {
        console.log('APPEND_CHATS');
        data.forEach((chat) => {
            state.chats.unshift(chat);
        });
    },
    [APPEND_CHAT](state, data) {
        console.log('APPEND_CHAT');
        if (!searchArrayByKey(data.id,state.chats)) {
            state.chats.push(data);
        } else {
            console.log("%cDuplicate Chat Append Detected, please trace.","color:red;font-weight:bold;font-size:1.2em;");
        }
    },
    [UPREPLACE_CHAT](state,data) {
        console.log('UPREPLACE_CHAT');
        upreplace(state.chats,data);
    },
    [REMOVE_CHAT](state,data) {
        console.log('REMOVE_CHAT');
        for (var i=0; i < state.chats.length; i++) {
            if (state.chats[i].id == data.id) {
                state.chats.splice(i,1);
            }
        }
    },
    [CLEAR_CHAT_BADGE](state) {
        console.log('CLEAR_CHAT_BADGE');
        state.hasChatBadge = false;
    },
    [SHOW_CHAT_BADGE](state) {
        console.log('SHOW_CHAT_BADGE');
        state.hasChatBadge = true;
    },
    [SHOW_MESSAGES](state) {
        console.log('SHOW_MESSAGES');
        state.showing_messages = true;
    },
    [EDIT_MESSAGE](state,data) {
        console.log('EDIT_MESSAGE');
        state.editing_message = data;
    },
    [REMOVE_MESSAGE](state, data) {
        console.log('REMOVE_MESSAGE');
        for (var i=0; i < state.messages.length; i++) {
            if (state.messages[i].id == data.id) {
                state.messages.splice(i,1);
            }
        }
    },
    [CLEAR_EDITING_MESSAGE](state) {
        console.log('CLEAR_EDITING_MESSAGE');
        state.editing_message = null;
    },
    [OPEN_CONVERSATION_DIALOG](state) {
        console.log('OPEN_CONVERSATION_DIALOG');
        state.showing_conversation_dialog = true;
    },
    [TOGGLE_CONVERSATION_DIALOG](state) {
        console.log('TOGGLE_CONVERSATION_DIALOG');
        state.showing_conversation_dialog = !state.showing_conversation_dialog;
    },
    [CLOSE_CONVERSATION_DIALOG](state) {
        console.log('CLOSE_CONVERSATION_DIALOG');
        state.showing_conversation_dialog = false;
    },
    [OPEN_MESSAGES_DIALOG](state) {
        console.log('OPEN_MESSAGES_DIALOG');
        state.showing_messages_dialog = true;
    },
    [TOGGLE_MESSAGES_DIALOG](state) {
        console.log('TOGGLE_MESSAGES_DIALOG');
        state.showing_messages_dialog = !state.showing_messages_dialog;
    },
    [CLOSE_MESSAGES_DIALOG](state) {
        console.log('CLOSE_MESSAGES_DIALOG');
        state.showing_messages_dialog = false;
    },
    [SET_MESSAGE](state, data) {
        console.log('SET_MESSAGE');
        let list = state.messages;
        for (var i=0; i < list.length; i++) {
            if (list[i].id == data.id) {
                list.splice(i,1,data);
                return;
            }
        }
    },
    [SET_CHAT](state, data) {
        console.log('SET_CHAT');
        let list = state.chats;
        for (var i=0; i < list.length; i++) {
            if (list[i].id == data.id) {
                console.log(list[i].id)
                console.log(data.id)
                list.splice(i,1,data);
                return;
            }
        }
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [REGET_CONVERSATIONS](context) {
        console.log('REGET_CONVERSATIONS');
        return new Promise((resolve, reject) => {
            let url = 'api/conversations?';
            url += 'offset=0';
            ApiService.get(url).then(({
                data
            }) => {
                context.commit(CLEAR_ERRORS);
                context.commit(CLEAR_CONVERSATIONS);
                if (data.length > 0 ) {
                    context.commit(
                        APPEND_CONVERSATIONS, data
                    );
                }
                resolve(data);
            })
            .catch(({
                response
            }) => {
                context.commit(
                    SET_ERROR, {
                        target: 'conversation',
                        message: response.data.error
                    }
                );
                reject(response);
            });
        });
    },
    [GET_CONVERSATIONS](context) {
        console.log('GET_CONVERSATIONS');
        return new Promise((resolve, reject) => {
            let url = 'api/conversations?';
            url += 'offset=' + context.state.conversations.length;
            ApiService.get(url).then(({
                data
            }) => {
                context.commit(CLEAR_ERRORS);
                if (data.length > 0 ) {
                    context.commit(
                        APPEND_CONVERSATIONS, data
                    );
                }
                resolve(data);
            })
            .catch(({
                response
            }) => {
                context.commit(
                    SET_ERROR, {
                        target: 'conversation',
                        message: response.data.error
                    }
                );
                reject(response);
            });
        });
    },
    [START_CONVERSATION](context, uuid) {
        console.log('START_CONVERSATION');
        return new Promise((resolve, reject) => {
            ApiService.post("api/conversations", {
                    uuid: uuid
                })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(UPSERT_CONVERSATION, data);
                    context.commit(SET_CONVERSATION, data);
                    context.commit(SHOW_MESSAGES);
                    context.commit(OPEN_CONVERSATION_DIALOG);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [ACCEPT_CONVERSATION](context, conversation) {
        console.log('ACCEPT_CONVERSATION');
        return new Promise((resolve, reject) => {
            ApiService.get("api/conversations/" + conversation.id + "/accept")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(UPDATE_CONVERSATION, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [IGNORE_CONVERSATION](context, conversation) {
        console.log('IGNORE_CONVERSATION');
        return new Promise((resolve, reject) => {
            ApiService.get("api/conversations/" + conversation.id + "/ignore")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_CONVERSATION, conversation.id);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [READ_CONVERSATION](context, conversation) {
        console.log('READ_CONVERSATION');
        return new Promise((resolve, reject) => {
            ApiService.get("api/conversations/" + conversation.id + "/read")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(MARK_CONVERSATION_READ,conversation.id);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [DELETE_CONVERSATION](context, conversation) {
        console.log('DELETE_CONVERSATION');
        return new Promise((resolve, reject) => {
            ApiService.post("api/conversations/" + conversation.id + "/remove")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_CONVERSATION, conversation.id);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_MESSAGES](context, conversation) {
        console.log('GET_MESSAGES');
        return new Promise((resolve, reject) => {
            let url = 'api/conversations/' + conversation.id + '/messages?';
            url += 'offset=' + context.state.messages.length;
            ApiService.get(url).then(({
                data
            }) => {
                context.commit(CLEAR_ERRORS);
                if (data.length > 0 ) {
                    context.commit(
                        APPEND_MESSAGES, data
                    );
                }
                resolve(data);
            })
            .catch(({
                response
            }) => {
                context.commit(
                    SET_ERROR, {
                        target: 'conversation',
                        message: response.data.error
                    }
                );
                reject(response);
            });
        });
    },
    [GET_CHATS](context, conversation) {
        console.log('GET_CHATS');
        return new Promise((resolve, reject) => {
            let url = 'api/conversations/' + conversation.id + '/messages?';
            url += 'offset=' + context.state.chats.length;
            ApiService.get(url).then(({
                data
            }) => {
                context.commit(CLEAR_ERRORS);
                if (data.length > 0 ) {
                    context.commit(
                        APPEND_CHATS, data
                    );
                }
                resolve(data);
            })
            .catch(({
                response
            }) => {
                context.commit(
                    SET_ERROR, {
                        target: 'conversation',
                        message: response.data.error
                    }
                );
                reject(response);
            });
        });
    },
    [UPDATE_CHAT](context, message) {
        console.log('UPDATE_CHAT');
        return new Promise((resolve, reject) => {
            ApiService.patch("api/messages/" + message.id, message)
                .then(({
                    data
                }) => {
                    console.log("Done Editing");
                    context.commit(CLEAR_ERRORS);
                    context.commit(CLEAR_EDITING_MESSAGE);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log("FAILED Editing");
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [DELETE_CHAT](context, id) {
        console.log('DELETE_CHAT');
        return new Promise((resolve, reject) => {
            ApiService.delete("api/messages/" + id)
                .then(() => {
                    context.commit(CLEAR_ERRORS);
                    resolve();
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [POST_MESSAGE](context, {conversation, message}) {
        console.log('POST_MESSAGE');
        return new Promise((resolve, reject) => {
            ApiService.post("api/conversations/" + conversation.id + "/messages", {body: message.body})
                .then(({
                    data
                }) => {
                    state.messages.push(data);
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [UPDATE_MESSAGE](context, message) {
        console.log('UPDATE_MESSAGE');
        return new Promise((resolve, reject) => {
            ApiService.patch("api/messages/" + message.id, message)
                .then(({
                    data
                }) => {
                    console.log("Done Editing");
                    context.commit(CLEAR_ERRORS);
                    context.commit(CLEAR_EDITING_MESSAGE);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log("FAILED Editing");
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [DELETE_MESSAGE](context, id) {
        console.log('DELETE_MESSAGE');
        return new Promise((resolve, reject) => {
            ApiService.delete("api/messages/" + id)
                .then(() => {
                    context.commit(CLEAR_ERRORS);
                    resolve();
                })
                .catch((
                    response
                ) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'conversation',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [ADD_MESSAGE](context, message) {
        console.log("ADD_MESSAGE");
        return new Promise((resolve, reject) => {
            try {
                context.commit(APPEND_MESSAGE,message);
                context.commit(SORT_CONVERSATIONS);
                resolve();
            }
            catch(error) {
                reject(error);
            }
        });
    },
};

export default {
    getters,
    actions,
    mutations,
    state
}
