<template>
    <div>
        <payment-form
            v-model="paymentInfo"
            :donor="donor"
            class="mt-6"
        />
        <v-alert
            v-if="disableSubmit && !saving"
            dismissible
            style="font-size: 1.125rem;"
            type="info"
        >
            Some items haven't been completed. Please answer them to continue.
            <ul class="mt-4">
                <li v-if="!donation.tax_filing_status">Tax Filing Status</li>
                <li v-if="!donation.amount">Donation Amount</li>
                <li v-if="!paymentInfo.billingAddress1">Billing Address</li>
                <li v-if="!paymentInfo.billingCity">Billing City</li>
                <li v-if="!paymentInfo.billingPostcode">Billing Zip</li>
                <li v-if="!paymentInfo.billingState">Billing State</li>
                <li v-if="!paymentInfo.cardCode">Card Code</li>
                <li v-if="!paymentInfo.cardNumber">Card Number</li>
                <li v-if="!paymentInfo.expiryMonth">Expiry Month</li>
                <li v-if="paymentInfo.expiryMonth && paymentInfo.expiryMonth.length !== 2">Expiry Month</li>
                <li v-if="!paymentInfo.expiryYear">Expiry Year</li>
                <li v-if="paymentInfo.expiryYear && paymentInfo.expiryYear.length !== 2">Expiry Year</li>
                <li v-if="!paymentInfo.firstName">First Name</li>
                <li v-if="!paymentInfo.lastName">Last Name</li>
            </ul>
        </v-alert>
        <div>
            <v-btn
                :to="{ name: 'donor-donations-list' }"
                class="cancel-button"
                rounded
            >
                Cancel
            </v-btn>
            <v-btn
                :disabled="disableSubmit"
                class="ml-2"
                color="primary"
                rounded
                @click="submit"
            >
                Submit
            </v-btn>
        </div>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { POST_DONOR_DONATION } from "../../store/actions.type";
import PaymentForm from "../shared/PaymentForm.vue";

export default {
    name: "DonationPaymentByCard",
    components: { PaymentForm },
    data() {
        return {
            paymentInfo: {
                amount: 0,
                billingAddress1: null,
                billingCity: null,
                billingPostcode: null,
                billingState: null,
                cardCode: null,
                cardNumber: null,
                expiryMonth: null,
                expiryYear: null,
                firstName: null,
                lastName: null,
            },
            possibleFields: [
                "name",
                "phone",
                "city",
                "postal",
                "street1",
            ],
            saving: false,
            snackbar: false,
            snackbarMessage: "",
        };
    },
    props: {
        calledFromCharitableDonationPage: {
            type: Boolean,
            default: false,
            required: false
        },
        calledFromDonationPage: {
            type: Boolean,
            default: false,
            required: false
        },
        donation: {
            type: Object,
            required: true,
        },
        donor: {
            type: Object,
            required: false
        },
        email: {
            type: String,
            default: "email@example.com",
            required: true
        }
    },
    computed: {
        disableSubmit() {
            return this.saving ||
                !this.paymentInfo.billingAddress1 ||
                !this.paymentInfo.billingCity ||
                !this.paymentInfo.billingPostcode ||
                !this.paymentInfo.billingState ||
                !this.paymentInfo.cardCode ||
                !this.paymentInfo.cardNumber ||
                !this.donation.amount ||
                !this.donation.tax_filing_status ||
                !this.paymentInfo.expiryMonth ||
                (this.paymentInfo.expiryMonth && this.paymentInfo.expiryMonth.length !== 2) ||
                !this.paymentInfo.expiryYear ||
                (this.paymentInfo.expiryYear && this.paymentInfo.expiryYear.length !== 2) ||
                !this.paymentInfo.firstName ||
                !this.paymentInfo.lastName ||
                this.paymentInfo.cardNumber.toString().length !== 16 ||
                this.paymentInfo.expiryYear.toString().length !== 2;
        },
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
    },
    methods: {
        submit() {
            this.saving = true;
            let donation = this.donation;

            donation.calledFromDonationPage = this.calledFromDonationPage;
            donation.calledFromCharitableDonationPage = this.calledFromCharitableDonationPage;
            donation.tax_year = donation.calledFromCharitableDonationPage ? 'current' : donation.tax_year;

            donation.paymentInfo = {
                amount: this.donation.amount,
                billingAddress1: this.paymentInfo.billingAddress1,
                billingCity: this.paymentInfo.billingCity,
                billingPostcode: this.paymentInfo.billingPostcode,
                billingState: this.paymentInfo.billingState,
                cardNumber: this.paymentInfo.cardNumber,
                cvv: this.paymentInfo.cardCode,
                email: this.paymentInfo.email,
                expiryMonth: this.paymentInfo.expiryMonth,
                expiryYear: this.paymentInfo.expiryYear,
                firstName: this.paymentInfo.firstName,
                lastName: this.paymentInfo.lastName,
            };

            if (this.donor) {
                donation.donor = this.donor;
            }

            this.$store.dispatch(POST_DONOR_DONATION, donation)
                .then(() => {
                    this.saving = false;
                    if (this.isAuthenticated) {
                        this.$router.push({ name: "donor-donations-list" });
                    } else {
                        this.$router.push({ name: "guest-donation-acknowledgement", params: { email: this.email } });
                    }
                })
                .catch((response) => {
                    this.saving = false;
                    console.log('error response: ', response);
                    if (response['status'] !== 422) {
                        this.snackbarMessage = response['data'];

                        this.snackbar = true;
                    }
                    window.scrollTo(0, 0);
                });
        },
    },
};
</script>

