<template>
    <div>
        <v-text-field
            id="name"
            v-model.trim="company.name"
            :error="!!errors.name"
            :error-messages="errors.name ? errors.name : null"
            autocomplete="organization"
            background-color="white"
            filled
            label="Company Name"
        />
        <v-text-field
            id="website"
            v-model.trim="company.website"
            :error="!!errors.website"
            :error-messages="errors.website ? errors.website : null"
            autocomplete="website"
            background-color="white"
            filled
            hint="An easy way to enter your website is to go to your site then copy the address and paste it here."
            label="Website (Optional)"
        />
        <v-text-field
            id="phone"
            v-model.trim="company.phone"
            :error="!!errors.phone"
            :error-messages="errors.phone ? errors.phone : null"
            autocomplete="phone"
            background-color="white"
            filled
            label="Company Phone"
            @input="phoneNumber"
        />
        <v-text-field
            id="fax"
            v-model.trim="company.fax"
            :error="!!errors.fax"
            :error-messages="errors.fax ? errors.fax : null"
            autocomplete="phone"
            background-color="white"
            filled
            label="Company Fax (Optional)"
            @input="faxNumber"
        />
        <v-select
            id="organization_type_enum"
            v-model="company.organization_type_enum"
            :error="!!errors.organization_type_enum"
            :error-messages="errors.organization_type_enum ? errors.organization_type_enum : null"
            :items="organizationTypes"
            autocomplete="on"
            background-color="white"
            filled
            item-text="value"
            item-value="id"
            label="Company Type"
            persistent-hint
        />
        <v-text-field
            id="title"
            v-model.trim="company.title"
            :error="!!errors.title"
            :error-messages="errors.title ? errors.title : null"
            autocomplete="organization-title"
            background-color="white"
            filled
            label="Your Title At Company"
        />
        <v-text-field
            id="contact_phone"
            v-model.trim="company.contact_phone"
            :error="!!errors.contact_phone"
            :error-messages="errors.contact_phone ? errors.contact_phone : null"
            autocomplete="phone"
            background-color="white"
            filled
            label="Your Contact Phone"
            @input="contactPhoneNumber"
        />
        <table
            class="box-with-background mt-2"
            style="padding: 20px; width:100%;"
        >
            <tr>
                <td>Primary Contact Name</td>
                <td>{{ user.name }}</td>
            </tr>
            <tr>
                <td>Primary Contact Title</td>
                <td>{{ company.title }}</td>
            </tr>
            <tr>
                <td>Primary Contact Email</td>
                <td>{{ user.email }}</td>
            </tr>
        </table>
        <v-text-field
            v-if="withPledge"
            id="secondary_contact_name"
            v-model.trim="company.corporatePledge.secondary_contact_name"
            :error="!!errors['corporatePledge.secondary_contact_name']"
            :error-messages="errors['corporatePledge.secondary_contact_name'] ? errors['corporatePledge.secondary_contact_name'] : null"
            background-color="white"
            filled
            label="Secondary Contact Name"
        />
        <v-text-field
            v-if="withPledge"
            id="secondary_contact_title"
            v-model.trim="company.corporatePledge.secondary_contact_title"
            :error="!!errors['corporatePledge.secondary_contact_title']"
            :error-messages="errors['corporatePledge.secondary_contact_title'] ? errors['corporatePledge.secondary_contact_title'] : null"
            background-color="white"
            filled
            label="Secondary Contact Title"
        />
        <v-text-field
            v-if="withPledge"
            id="secondary_contact_email"
            v-model.trim="company.corporatePledge.secondary_contact_email"
            :error="!!errors['corporatePledge.secondary_contact_email']"
            :error-messages="errors['corporatePledge.secondary_contact_email'] ? errors['corporatePledge.secondary_contact_email'] : null"
            background-color="white"
            filled
            label="Secondary Contact Email"
        />
        <v-radio-group
            v-model="company.disclose"
            :error="!!errors['disclose']"
            :error-messages="errors['disclose'] ? errors['disclose'] : null"
            class="mt-n2"
            inline
        >
            <template v-slot:label>
                <div class="radio-label">
                    May we share your company's name and logo on our website?
                </div>
            </template>
            <v-radio
                :value="true"
                color="secondary"
                label="Yes"
            />
            <v-radio
                :value="false"
                color="secondary"
                label="No"
            />
        </v-radio-group>
        <v-row class="mt-n5">
            <v-col class="text-h5"> Mailing Address</v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="mt-n3 box-with-background">
                    Quick Address Entry
                    <vuetify-google-autocomplete
                        id="map"
                        append-icon="mdi-magnify"
                        background-color="white"
                        class="mt-4"
                        country="us"
                        filled
                        hint="The address you select will fill in the fields below."
                        placeholder="Start typing address"
                        v-on:placechanged="getAddressData"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-n6">
            <v-col>
                <v-text-field
                    id="street1"
                    v-model.trim="company.address.street1"
                    :error="!!errors['address.street1']"
                    :error-messages="errors['address.street1'] ? errors['address.street1'] : null"
                    autocomplete="address-line1"
                    background-color="white"
                    filled
                    label="Street1"
                />
                <v-text-field
                    id="street2"
                    v-model.trim="company.address.street2"
                    :error="!!errors['address.street2']"
                    :error-messages="errors['address.street2'] ? errors['address.street2'] : null
                        "
                    autocomplete="address-line2"
                    background-color="white"
                    filled
                    label="Street2"
                />
                <v-text-field
                    id="postal"
                    v-model.trim="company.address.postal"
                    :error="!!errors['address.postal']"
                    :error-messages="errors['address.postal'] ? errors['address.postal'] : null
                        "
                    autocomplete="zip"
                    background-color="white"
                    filled
                    label="Postal / Zip"
                />
                <v-text-field
                    id="city"
                    v-model.trim="company.address.city"
                    :error="!!errors['address.city']"
                    :error-messages="errors['address.city'] ? errors['address.city'] : null"
                    autocomplete="address-level2"
                    background-color="white"
                    filled
                    label="City"
                />
                <region-selector v-model="company.address.region_id"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RegionSelector from "../shared/RegionSelector";

export default {
    name: "CompanyForm",
    components: {
        RegionSelector,
    },
    props: {
        company: {
            type: Object,
            required: true,
        },
        withPledge: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        organizationTypes() {
            return this.withPledge ? this.$store.getters.organizationTypesExcept(4) : this.$store.getters.organizationTypes;
        },
        regions() {
            return this.$store.getters.currentRegions;
        },
        user() {
            return this.$store.getters.currentUser;
        },
        error() {
            return this.$store.getters.getMessage("companies");
        },
        errors() {
            return this.$store.getters.getErrors("companies");
        },
    },
    methods: {
        contactPhoneNumber() {
            var x = this.company.contact_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.contact_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        phoneNumber() {
            var x = this.company.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        faxNumber() {
            var x = this.company.fax.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.fax = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.company.address.street1 = addressData.name;
            this.company.address.city = addressData.locality;
            this.company.address.postal = addressData.postal_code;
            this.company.address.lat = addressData.latitude;
            this.company.address.lon = addressData.longitude;

            let iso_state = addressData.administrative_area_level_1;
            this.company.address.region_id = searchArrayByKey(iso_state, this.regions, 'iso', 'object').id;
        }
    },
};
</script>

<style scoped>
.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 10px 10px 0 10px;
}

::v-deep .v-input--is-focused .v-messages__message {
    color: black;
    font-size: 1rem;
    line-height: 1.35rem;
}

::v-deep .v-messages__message:not(.message-transition-enter-to) {
    background-color: var(--apsto-teal-very-light);
    margin: 12px -12px;
    padding: 12px 24px;
    line-height: 1.5;
}
</style>
