<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            v-if="showCheckPortal"
            align="center"
            class="py-12 portal-check"
            justify="center"
        >
            <v-col md="6 text-center">
                <v-card class="mt-n10 px-0 px-sm-6 py-12">
                    <div class="message-salutation">
                        Hi {{ user.first_name }}
                    </div>
                    <div class="message-welcome">
                        Welcome to your Applicant Portal
                    </div>
                    <div class="message-instructions px-4 px-sm-16 py-6">
                        You can use this portal to create and renew applications.
                        <div class="mt-5 message-continue">
                            Would you like to continue?
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn
                            block
                            color="secondary"
                            href="/scholarship-program"
                            rounded
                        >
                            No, I didn't mean to select this portal
                        </v-btn>
                        <v-btn
                            block
                            class="mt-5"
                            color="secondary"
                            rounded
                            @click="proceedWithPortal"
                        >
                            Yes, please continue
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!showCheckPortal">
            <v-col md="8">
                <v-row :align="isMobile ? 'top' : 'center'">
                    <v-col class="mx-2 text-h5-mulish"> Families</v-col>
                    <v-col :cols="isMobile ? '' : 'auto'">
                        <v-btn
                            :block="isMobile"
                            :to="{ name: 'household-create' }"
                            class="font-weight-bold"
                            color="secondary"
                            elevation="0"
                            rounded
                        >
                            <v-icon left> mdi-plus-circle-outline</v-icon>
                            Create A Family Profile
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            :disable-filter="true"
                            :disable-pagination="true"
                            :disable-sort="true"
                            :headers="headers"
                            :hide-default-footer="true"
                            :items="households"
                            :items-per-page="5"
                            :mobile-breakpoint="0"
                            :single-select="true"
                            class="mt-4"
                            item-key="id"
                            @click:row="goTo"
                        >
                            <template v-slot:no-data>
                <span class="text-apsto-teal">
                  Please click the button above to add your family. You'll be able to create an application right after you add your family.
                </span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {GET_USER_HOUSEHOLDS} from "../../../../store/actions.type";
import Loading from "../../../../components/shared/Loading";

export default {
    name: "HouseholdList",
    components: {
        Loading,
    },
    data() {
        return {
            hideCheckPortal: false,
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Address', value: 'address.city_line'},
            ],
            loading: true,
        };
    },
    computed: {
        households() {
            return this.$store.getters.currentUserHouseholds;
        },
        showCheckPortal() {
            return this.user.has_created_content && !this.user.applicant && !this.hideCheckPortal;
        },
        user() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        goTo(item) {
            this.$router.push({name: 'household', params: {household_id: item.id}});
        },
        proceedWithPortal() {
            this.hideCheckPortal = true;
            this.$router.push({name: 'households'})
        }
    },
    mounted() {
        this.$store.dispatch(GET_USER_HOUSEHOLDS).then((data) => {
            this.loading = false;
            if (data.length === 1) {
                let household_id = data[0].id;
                this.$router.push({name: 'household', params: {household_id: household_id}});
            }
        });
    },
};
</script>

<style scoped>
::v-deep tbody td {
    height: 50px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
