<template>
    <div v-if="loading">
        <loading/>
    </div>
    <div
        v-else
        style="font-size: 1.125rem;"
    >
        <v-row class="ml-0">
            <v-col
                class="px-0"
                cols="12"
            >
                <div>
                    Low-Income Corporate Tax Credit Scholarships are funded by the Corporate Low-Income Tax Credit Law,
                    A.R.S. 43-1183. To be eligible for this tax credit, your student must meet both criteria A and B
                    below:
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="auto">
                <v-btn
                    color="secondary"
                    fab
                    style="border-radius: 0;"
                >A
                </v-btn>
            </v-col>
            <v-col>
                <span class="font-weight-black">Your household must be considered low income.</span>
                A family is considered low income if total household income is 185% or less of the income level set by
                the USDA’s Federal Reduced Lunch guidelines. Please refer to the chart below to see if you fall into
                this category.
                <div class="mt-2">
                    Your family will meet this criterion if your household income is less than the amount shown in the
                    far right column for your household size.
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                class="px-0"
                cols="12"
            >
                <v-simple-table>
                    <thead>
                    <tr>
                        <th
                            class="grey-black"
                            colspan="2"
                        >
                            Federal Reduced Price Lunch Guidelines {{ fromYear }} - {{ thruYear }} School Year
                            <v-row v-if="shouldShowToggle">
                                <v-col>
                                    <v-btn
                                        class="mt-5"
                                        color="secondary"
                                        @click="changeYear"
                                    >
                                        <span style="font-size: 14px;">Show {{ otherTaxYear }} Maximums Instead</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </th>
                        <th class="purple-white">Income Maximums for AZ Low-Income Corporate Tax Credit Scholarships
                        </th>
                    </tr>
                    <tr>
                        <th style="width: 33%">Household Size</th>
                        <th style="width: 33%">
                            Annual Gross Income
                            <br>
                            ({{ income_levels.cutoff_threshold_1 * 100 | formatNumber }}% of poverty level)
                        </th>
                        <th style="color: var(--apsto-purple); width: 33%">
                            Annual Gross Income
                            <br>
                            ({{ income_levels.cutoff_threshold_1 * 100 | formatNumber }}% of amount in column 2)
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ 1 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_1 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_1 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 2 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_2 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_2 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 3 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_3 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_3 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 4 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_4 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_4 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 5 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_5 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_5 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 6 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_6 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_6 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 7 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_7 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_7 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 8 | formatNumber }}</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.member_8 * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.member_8 * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>Each additional member</td>
                        <td>
                            {{
                                income_levels.federal_poverty_level.additional_member * income_levels.cutoff_threshold_1 |
                                    formatCurrency
                            }}
                        </td>
                        <td style="color: var(--apsto-purple);">
                            {{
                                income_levels.federal_poverty_level.additional_member * income_levels.cutoff_threshold_2 |
                                    formatCurrency
                            }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="auto">
                <v-btn
                    color="secondary"
                    fab
                    style="border-radius: 0;"
                >B
                </v-btn>
            </v-col>
            <v-col>
                <span class="font-weight-black">Your student then needs to fall under one of the following four
                    categories:</span>
                <ol class="mt-4">
                    <li>
                        <span class="font-weight-black">Switcher:</span>
                        your student qualifies as Switcher if they meet one of the following prerequisites:
                    </li>
                    <ul data-checked>
                        <li>
                            Your student attended an Arizona public or charter school as a full-time student for at
                            least 90
                            days, or
                            a full semester, in the prior school year, and transferred to a private school.
                        </li>
                        <li>
                            Your student was homeschooled and transferred directly to an Arizona private school.
                        </li>
                        <li>
                            Your student moved to Arizona from another state or country and transferred directly to an
                            Arizona private
                            school.
                        </li>
                        <li>
                            Your student has directly exited the ESA (Empowerment Scholarship Account) to participate in
                            the
                            tuition
                            tax credit program.
                        </li>
                        <li>
                            Your student is enrolling or currently enrolled in kindergarten.
                        </li>
                        <li>
                            Your student is a dependent of a member of the U.S. Armed Forces who is stationed on Arizona
                            under
                            military orders.
                        </li>
                        <li>
                            Your student previously received a Switcher or Corporate scholarship and has attended
                            private
                            School
                            continuously since.
                        </li>
                    </ul>
                    <li class="mt-6">
                        <span class="font-weight-black">Kindergarteners (or Preschoolers with disabilities):</span>
                    </li>
                    <li>
                        <span class="font-weight-black">Military:</span> your student is the dependents of a member of
                        the
                        U.S Armed
                        Forces, stationed in Arizona pursuant to military orders.
                    </li>
                    <li>
                        <span class="font-weight-black">Previous Scholarships:</span> your student has previously
                        received a
                        scholarship (in the prior school year) from any of the tax Credit scholarship programs
                        (Original,
                        Switcher,
                        or Corporate (Low-Income or Disabled/Displaced) and have continued in private school since
                        receiving
                        the
                        award.
                    </li>
                </ol>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ApiService from '../../../../api/api.service';
import Loading from '../../../shared/Loading';

export default {
    name: "LowIncomeCorporateTaxCredit",
    components: {
        Loading,
    },
    props: {
        content: {
            type: Object,
        },
    },
    data() {
        return {
            income_levels: null,
            loading: true,
            startingTaxYear: null,
            currentTaxYear: null,
        };
    },
    computed: {
        fromYear() {
            return this.currentTaxYear;

        },
        thruYear() {
            return this.fromYear + 1;
        },
        taxYear() {
            return this.applicableTaxYear();
        },
        shouldShowToggle() {
            const today = new Date();
            const month = today.getMonth();
            const day = today.getDate();
            //If today is after the tax day but before the end of the year, hide the toggle
            return !(month + 1 > 4 || (month + 1 === 4 && day > 16));

        },
        isStartingTaxYear() {
            return this.currentTaxYear === this.startingTaxYear;

        },
        otherTaxYear() {
            if (this.isStartingTaxYear) {
                return this.currentTaxYear + 1;
            } else {
                return this.currentTaxYear - 1;
            }
        },
    },
    methods: {
        changeYear() {
            if (this.isStartingTaxYear) {
                this.currentTaxYear = this.currentTaxYear + 1;
            } else {
                this.currentTaxYear = this.currentTaxYear - 1;
            }
            this.getHouseholdIncomeChart(this.currentTaxYear);
        },
        getHouseholdIncomeChart(year) {
            this.loading = true;
            ApiService.get('/api/website/data/household-income/' + year)
                .then((response) => {
                    this.income_levels = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log("LOW INCOME CORPORATE TAX CREDIT ERROR");
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.startingTaxYear = this.applicableTaxYear();
        this.currentTaxYear = this.applicableTaxYear();
        this.getHouseholdIncomeChart(this.currentTaxYear);
    },
};
</script>

<style scoped>
th,
td {
    font-size: 16px !important;
    padding: 20px;
    text-align: center !important;
}

tr > td,
th {
    border: 1px solid var(--apsto-purple-very-light);
}

::v-deep .v-btn__content {
    color: white;
    font-size: 16px;
}

.grey-black {
    background-color: var(--apsto-purple-very-light);
    border-top-left-radius: 10px;
    color: black !important;
    padding: 20px !important;
}

.purple-white {
    background-color: var(--apsto-purple);
    border-top-right-radius: 10px;
    color: white !important;
    padding: 20px !important;
}
</style>
