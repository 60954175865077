import { render, staticRenderFns } from "./RecommendationsPage.vue?vue&type=template&id=3dd1b880&scoped=true&"
import script from "./RecommendationsPage.vue?vue&type=script&lang=js&"
export * from "./RecommendationsPage.vue?vue&type=script&lang=js&"
import style0 from "./RecommendationsPage.vue?vue&type=style&index=0&id=3dd1b880&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd1b880",
  null
  
)

export default component.exports