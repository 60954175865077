<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center" class="ml-1 py-5">
      <v-col class="pa-0" cols="auto">
        <school-header page-title="Students"/>
      </v-col>
      <v-spacer/>
      <v-col class="pa-0" cols="auto">
        <v-btn :href="'/api/schools/' + $route.params.school_id + '/students/download'"
               color="primary"
               rounded
        >
          <v-icon left> mdi-download</v-icon>
          Download This List
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="schoolStudents.length > 0"
      :footer-props="{ itemsPerPageOptions:[10,20,30] }"
      :headers="headers"
      :items="schoolStudents"
      :items-per-page="-1"
      :search="search"
      :single-select="true"
      class="elevation-2 mt-4 mr-0"
      group-by="school_year_name"
      group-desc
      item-key="id"
      @click:row="edit"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template #item.tuition_amount_student="{ item }">
        <div class="align-end">{{ item.tuition_amount_student | formatCurrency }}</div>
      </template>
      <template #item.tuition_amount_school="{ item }">
        <div class="align-end">{{ item.tuition_amount_school | formatCurrency }}</div>
      </template>
      <template v-slot:group.header="{items, isOpen, toggle}">
        <th colspan="10">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          School Year: {{ items[0].school_year_name }}
          <span class="text-apsto-teal" style="font-style: italic;">
            Students: {{ items[0].quantity | formatNumber }}
          </span>
        </th>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SchoolHeader from "../../../../../components/school/SchoolHeader";
import Loading from "../../../../../components/shared/Loading";
import {GET_SCHOOL_STUDENTS} from "../../../../../store/actions.type";

export default {
  name: "AdminSchoolStudentsList",
  components: {
    SchoolHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        date_submitted: '',
        name: '',
        status_name: '',
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'School Year',
          align: 'start',
          value: 'school_year_name',
        },
        {text: 'Family', value: 'household_name'},
        {text: 'Parent / GUARDIAN', value: 'parent_name'},
        {text: 'Name', value: 'student_name'},
        {text: 'Birth Date', value: 'birth_date'},
        {text: 'Enrollment', value: 'grade_name'},
        {text: 'Eligibility', value: 'qualifies_list'},
        {text: 'Application Status', value: 'application_status_name'},
        {text: 'Tuition Student', align: 'end', value: 'tuition_amount_student'},
        {text: 'Tuition School', align: 'end', value: 'tuition_amount_school'},
      ],
      loading: true,
      search: '',
    }
  },
  computed: {
    schoolStudents() {
      return this.$store.getters.currentSchoolStudents;
    },
    school() {
      return this.$store.getters.currentSchool;
    }
  },
  methods: {
    edit(item) {
      this.$router.push(`students/${item.id}/edit`);
    },
    getSchoolStudents() {
      this.$store.dispatch(GET_SCHOOL_STUDENTS, this.$route.params.school_id)
    },
    initialize() {
      this.schoolStudents = [];
    },
  },
  mounted() {
    this.getSchoolStudents();
    this.loading = false;
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep th:not([role=columnheader]) {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}
</style>
