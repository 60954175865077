//Views
import SchoolList from "../views/schools/school/main/SchoolList";
import School from "../views/schools/school/main/School";
import SchoolCreate from "../views/schools/school/main/SchoolCreate";
import SchoolEdit from "../views/schools/school/main/SchoolEdit";

export default [
    {
        path: "/dashboard/schools",
        name: "schools",
        component: SchoolList,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: false,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id",
        name: "school",
        component: School,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/school/create",
        name: "school-create",
        component: SchoolCreate,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: false,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/edit",
        name: "school-edit",
        component: SchoolEdit,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
];
