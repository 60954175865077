<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-card class="pa-4">
      <v-row>
        <v-col>
          <v-card-title class="pa-0">
            Payroll Withholding Period
          </v-card-title>
          <div class="mt-3">
            Please indicate the year and month upload amounts were
            <span @click="dialogExplainWithheld = !dialogExplainWithheld">
                            <span
                              class="text-apsto-teal"
                              style="cursor:pointer;"
                            >withheld</span>
                        </span>
            from employees.
          </div>
          <form class="mt-5">
            <v-select
              v-model="form.calendar_year"
              :error="!!errors['calendar_year']"
              :error-messages="errors['calendar_year'] ? errors['calendar_year'] : null"
              :items="years"
              filled
              item-text="name"
              item-value="id"
              label="Year Funds Withheld"
              @change="getCompanyPayrollBatches"
            />
            <v-select
              v-model="form.month"
              :error="!!errors['month']"
              :error-messages="errors['month'] ? errors['month'] : null"
              :items="periods"
              filled
              item-text="name"
              item-value="id"
              label="Month Funds Withheld"
            />
            <v-btn
              :disabled="disableContinueButton"
              class="mt-n1"
              color="primary"
              style="min-width: 140px;"
              @click="save"
            >
              CONTINUE
            </v-btn>
          </form>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogExplainWithheld"
      :fullscreen="!!isMobile"
      :max-width="dialogMaxWidth"
    >
      <v-card class="pb-5 dialog">
        <v-card-title class="pa-4">
          Withholdings
        </v-card-title>
        <v-card-text class="mx-n2">
          <v-row class="mt-2">
            <v-col class="text-h6-mulish">
              Withholdings are based on employee payroll check date.
              <div class="mt-5">
                For example, if your withholding information pertains to payroll
                <span style="text-decoration: underline;">
                                    withheld from employee paychecks during the month of June
                                </span>
                , you would select <span style="text-decoration: underline;">June</span> for the
                <span class="font-weight-black">
                                    Month Funds Withheld
                                </span>
                dropdown.
              </div>
              <div class="mt-5">
                Quarterly Filers: Please select the last month of the quarter that amounts were withheld
                from employees.
              </div>
              <div class="mt-5">
                Note, please don't use the date you send your payment for the Year or Month dropdowns.
              </div>
              <div class="mt-5">
                If you make a mistake, you can easily delete your information after you finish uploading
                your
                withholdings
                and start over. Or, <a href="/contact"><span class="text-apsto-teal">contact us</span></a>
                and we'll
                walk you through it.
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <div
          v-if="isMobile"
          class="px-4"
        >
          <v-btn
            block
            class="cancel-button"
            rounded
            @click="dialogExplainWithheld = false"
          >
            OK
          </v-btn>
        </div>
        <v-card-actions v-else>
          <v-btn
            class="cancel-button"
            rounded
            @click="dialogExplainWithheld = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      centered
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import {SET_PAYROLL_WITHHOLDING_UPLOAD_SELECTIONS} from "../../../store/mutations.type";
import {GET_COMPANY_PAYROLL_BATCHES_BY_YEAR, GET_NEXT_COMPANY_PAYROLL_BATCH} from "../../../store/actions.type";
import Loading from "../../shared/Loading.vue";

export default {
  name: "EmployeeDonationsWithholdingPeriod",
  components: {Loading},
  data() {
    return {
      countStored: 0,
      dialogExplainWithheld: false,
      form: {
        calendar_year: null,
        month: null,
      },
      loading: false,
      months: [
        {id: 1, name: "January"},
        {id: 2, name: "February"},
        {id: 3, name: "March"},
        {id: 4, name: "April"},
        {id: 5, name: "May"},
        {id: 6, name: "June"},
        {id: 7, name: "July"},
        {id: 8, name: "August"},
        {id: 9, name: "September"},
        {id: 10, name: "October"},
        {id: 11, name: "November"},
        {id: 12, name: "December"},
      ],
      showForm: false,
      snackbar: false,
      snackbarMessage: "",
      years: []
    }
  },
  computed: {
    company() {
      return this.$store.getters.currentUserCompany;
    },
    companyPayrollBatches() {
      return this.$store.getters.currentCompanyPayrollBatches;
    },
    disableContinueButton() {
      return !this.form.calendar_year || !this.form.month;
    },
    nextCompanyPayrollBatch() {
      return this.$store.getters.currentNextCompanyPayrollBatch;
    },
    periodPreviouslyRun() {
      return !!this.companyPayrollBatches.filter(month => month === this.form.month).length;
    },
    periods() {
      return this.company.employee_donations_frequency === 4 ? this.months.filter(month => month.id % 3 === 0) : this.months;
    },
    error() {
      return this.$store.getters.getMessage("employeeDonations");
    },
    errors() {
      return this.$store.getters.getErrors("employeeDonations");
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1)
    },
    getCompanyPayrollBatches() {

      if (!this.form.calendar_year) {
        return [];
      }

      let calendar_year = this.form.calendar_year;
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_COMPANY_PAYROLL_BATCHES_BY_YEAR, {
        calendar_year,
        company_id,
      }).then(() => {

      });
    },
    getNextCompanyPayrollBatch() {
      let loading = true;
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_NEXT_COMPANY_PAYROLL_BATCH, company_id).then(() => {
        loading = false;
        this.form.calendar_year = this.nextCompanyPayrollBatch.calendar_year;
        this.form.month = this.nextCompanyPayrollBatch.month;

        const comparisonMonth = this.company.employee_donations_frequency === 3 ? 1 : 3;
        if (this.form.month > comparisonMonth) {
          // Remove the first year
          // this.years.pop();
        }

        this.getCompanyPayrollBatches();
      });
    },
    save() {

      if (this.periodPreviouslyRun) {
        this.snackbar = true;
        this.snackbarMessage = "You have already uploaded withholding information for this period.";
        this.$emit("failed");
        return;
      }

      console.log("periodPreviouslyRun", this.periodPreviouslyRun);

      const data = {
        calendar_year: this.form.calendar_year,
        month: this.form.month,
      };
      this.$store.commit(SET_PAYROLL_WITHHOLDING_UPLOAD_SELECTIONS, data);
      this.$emit("done");
    },
  },
  mounted() {
    this.yearNow = new Date().getFullYear();
    this.years = [
      {id: this.yearNow, name: this.yearNow},
      {id: this.yearNow - 1, name: this.yearNow - 1},
    ]
    this.getNextCompanyPayrollBatch();


  },
}

</script>

<style scoped></style>
