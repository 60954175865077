<template>
    <v-autocomplete
        id="user_id"
        v-model="localUser"
        :background-color="backgroundColor"
        :error="!!errors['user_id']"
        :error-messages="errors['user_id'] ? errors['user_id'] : null"
        :items="userList"
        :label="label"
        :loading="loading"
        filled
        item-text="name"
        item-value="id"
    />
</template>

<script>
import { GET_USER_DROPDOWN } from "../../store/actions.type";
import { SET_USER_DROPDOWN_IS_LOADING } from "../../store/mutations.type";

export default {
    name: "UserSelector",
    props: {
        backgroundColor: {
            default: "white",
            type: String,
        },
        label: {
            default: "User Name",
            type: String,
        },
        value: {
            type: Number,
            required: false,
        },
    },
    computed: {
        loading: {
            get() {
                return this.$store.getters.currentUserDropdownIsLoading;
            },
            set(value) {
                this.$store.commit(SET_USER_DROPDOWN_IS_LOADING, value);
            },
        },
        userList() {
            return this.$store.getters.currentUserDropdown;
        },
        error() {
            return this.$store.getters.getMessage("users");
        },
        errors() {
            return this.$store.getters.getErrors("users");
        },
        localUser: {
            get() {
                return this.value;
            },
            set(localUser) {
                this.$emit("input", localUser);
            },
        },
    },
    methods: {
        getUserDropdown() {
            if (!this.loading) {
                this.loading = true;
                this.$store.dispatch(GET_USER_DROPDOWN).then(() => {
                    this.loading = false;
                });
            }
        },
    },
    mounted() {
        this.getUserDropdown();
        this.$emit("input", this.localUser);
    },
};
</script>
