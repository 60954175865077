// Views
import CompanyAnnualDonationsList from "../views/companies/employee-donations/dashboard/CompanyAnnualDonationsList";
import CompanyPayrollBatchList from "../views/companies/employee-donations/dashboard/CompanyPayrollBatchList";
import EmployeeDonationsUpload from "../views/companies/employee-donations/EmployeeDonationsUpload";
import CompanyPayrollBatchShow from "../views/companies/employee-donations/dashboard/CompanyPayrollBatchShow";
import CompanyEmployeesList from "../views/companies/employee-donations/dashboard/CompanyEmployeesList.vue";

export default [
    {
        path: "/dashboard/companies/:company_id/employee-donations",
        name: "company-annual-donations-list",
        component: CompanyAnnualDonationsList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
    {
        path: "/dashboard/companies/:company_id/employee-donations/:company_annual_donation_id",
        name: "company-daily-donations-list",
        component: CompanyPayrollBatchList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
    {
        path: "/dashboard/companies/:company_id/employee-donations/:company_annual_donation_id/batch/:company_payroll_batch_id",
        name: "company-payroll-batch-show",
        component: CompanyPayrollBatchShow,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
    {
        path: "/dashboard/companies/:company_id/employee-donations/uploads/upload",
        name: "employee-donations-upload",
        component: EmployeeDonationsUpload,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        }
    },
    {
        path: "/dashboard/companies/:company_id/employee-list/:company_annual_donation_id",
        name: "company-employees-list",
        component: CompanyEmployeesList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
]

