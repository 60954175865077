// Views
import SchoolAdminInvite from "../views/schools/school-admins/SchoolAdminInvite";
import SchoolAdminsList from "../views/schools/school-admins/SchoolAdminsList";

export default [
    {
        path: "/dashboard/schools/:school_id/admins",
        name: "school-admins-list",
        component: SchoolAdminsList,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/admins/invite",
        name: "school-admin-invite",
        component: SchoolAdminInvite,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'School Portal'
        },
    },
]

