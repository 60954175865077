import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {GET_SCHOOL_DROPDOWN} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_ERROR, SET_SCHOOL_DROPDOWN, SET_SCHOOL_DROPDOWN_IS_LOADING} from "./mutations.type";
/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        isLoading: false,
        schoolDropdown: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentSchoolDropdown(state) {
        return state.schoolDropdown;
    },
    currentSchoolDropdownIsLoading(state) {
        return state.isLoading;
    },
    currentSchoolsFiltered: (state) => (schoolId) => {
        return state.schoolDropdown.filter(school => school.id === schoolId);
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_SCHOOL_DROPDOWN](context) {
        console.log("GET_SCHOOL_DROPDOWN");
        //Prevent loading school dropdown over and over
        if (context.state.schoolDropdown.length) return context.state.schoolDropdown;

        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/dropdown`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_DROPDOWN, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolDropdown",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_SCHOOL_DROPDOWN](state, data) {
        console.log("SET_SCHOOL_DROPDOWN");
        state.schoolDropdown = data;
    },
    [SET_SCHOOL_DROPDOWN_IS_LOADING](state, value) {
        console.log("SET_SCHOOL_DROPDOWN_IS_LOADING", value);
        state.isLoading = value;
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
