<template>
    <div>
        <v-radio-group
            v-model="localCorporatePledge.recommendation_type"
            column
            @change="clearUnusedFields"
        >
            <template v-slot:label>
                <div
                    class="pb-2 font-weight-black"
                    style="font-size: 1.125rem; line-height: 1.5"
                >
                    Do you have any recommendations for your donation?
                </div>
            </template>
            <v-radio
                :value="1"
                color="secondary"
                label="No Preference"
            />
            <v-radio
                :value="2"
                color="secondary"
                label="One or More School(s)"
            />
            <v-radio
                :value="3"
                color="secondary"
                label="Other"
            />
        </v-radio-group>
        <!--    User chooses schools-->
        <div v-if="localCorporatePledge.recommendation_type === 2">
            <corporate-pledge-recommendation-schools-table v-model="localCorporatePledge.schools" />
        </div>
        <!--    User chooses to leave a comment-->
        <div
            v-if="localCorporatePledge.recommendation_type === 3"
            class="mt-1"
        >
            <div class="text-subtitle-2">
                Please describe how you would recommend this donation be distributed.
            </div>
            <v-textarea
                v-model="localCorporatePledge.comments"
                :error="!!errors['comments']"
                :error-messages="errors['comments'] ? errors['comments'] : null"
                auto-grow
                background-color="secondaryLight"
            />
            <div class="text-subtitle-2 mb-4">
                Please note that a School Tuition Organization cannot award, restrict or
                reserve scholarships solely on the basis of a donor's recommendation.
            </div>
        </div>

        <div
            v-if="errors && errors['recommendations']"
            class="px-3"
        >
            <div class="v-messages__message">
                {{ errors['recommendations'][0] }}
            </div>
        </div>

    </div>
</template>

<script>
import SchoolSelector from "../../../../components/shared/SchoolSelector";
import CorporatePledgeRecommendationSchoolsTable from "./../CorporatePledgeRecommendationSchoolsTable";

export default {
    name: "NewCompanyCorporatePledgeRecommendations",
    components: {
        CorporatePledgeRecommendationSchoolsTable,
        SchoolSelector,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        saving: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("companies");
        },
        errors() {
            return this.$store.getters.getErrors("companies");
        },
        localCorporatePledge: {
            get() {
                return this.value;
            },
            set(localCorporatePledge) {
                this.$emit("input", localCorporatePledge);
            }
        },
    },
    methods: {
        clearUnusedFields() {
            switch (this.localCorporatePledge.recommendation_type) {
                case 1:
                    this.localCorporatePledge.comments = null;
                    this.localCorporatePledge.disclose = null ? 0 : this.localCorporatePledge.disclose;
                    this.localCorporatePledge.schools = [];
                    break;
                case 2:
                    this.localCorporatePledge.comments = null;
                    break;
                case 3:
                    this.localCorporatePledge.disclose = null ? 0 : this.localCorporatePledge.disclose;
                    this.localCorporatePledge.schools = [];
                    break;
            }
        }
    }
};
</script>

<style scoped>::v-deep .v-textarea textarea {
    padding: 10px;
}</style>
