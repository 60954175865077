//Views
import InviteAccept from "../views/invitation/InviteAccept";

export default [
    {
        path: "/dashboard/invites/accept/:token",
        name: "invite-accept",
        component: InviteAccept,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            backgroundColorGrey: true,
            showAnnouncements: true
        },
    },
];
