<template>
    <loading v-if="loading" />
    <v-card
        v-else
        class="pa-5"
    >
        <v-card-title>
            Edit Award
        </v-card-title>
        <v-card-subtitle class="mt-0">
            <div class="text-apsto-teal">
                {{ school.name }}
                <div v-if="studentName">
                    {{ studentName }}
                </div>
            </div>
        </v-card-subtitle>
        <v-card-text>
            <v-form :disabled="saving">
                <admin-school-student-award-form class="mt-0" />
            </v-form>
        </v-card-text>
        <v-card-actions class="mt-n6">
            <v-spacer />
            <v-btn
                class="cancel-button"
                rounded
                @click="$emit('done')"
            >Cancel
            </v-btn>
            <v-btn
                :disabled="disabled"
                class="save-by-cancel-button"
                color="primary"
                rounded
                @click="save"
            >Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import AdminSchoolStudentAwardForm from "./AdminSchoolStudentAwardForm";
import Loading from "../../../../../components/shared/Loading.vue";
import { PATCH_SCHOOL_STUDENT_AWARD } from "../../../../../store/actions.type";

export default {
    name: "AdminSchoolStudentAwardEdit",
    components: {
        AdminSchoolStudentAwardForm,
        Loading
    },
    data() {
        return {
            loading: false,
            saving: false,
        }
    },
    props: {
        applicationStudentAwardId: {
            type: Number,
            required: true,
        },
        studentName: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        disabled() {
            return this.saving || !this.studentAward.amount || !this.studentAward.application_student_school_id || !this.studentAward.fiscal_year_id || !this.studentAward.month || !this.studentAward.tax_credit_type;
        },
        school() {
            return this.$store.getters.currentAdminSchool;
        },
        studentAward() {
            return this.$store.getters.currentApplicationStudentAward;
        }
    },
    methods: {
        save() {
            this.saving = true;
            this.studentAward.school_id = this.$route.params.school_id;
            let form = this.studentAward;
            this.$store.dispatch(PATCH_SCHOOL_STUDENT_AWARD, form).then(() => {
                this.saving = false;
                this.$emit("done");
            })
                .catch(() => {
                    this.saving = false;
                });
        }
    },
}
</script>

<style scoped></style>
