<template>
    <v-card>
        <v-card-title class="justify-space-between font-weight-black">
            Forgot Password
            <v-btn
                icon
                @click="close"
            >
                <v-icon>fas fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
            <v-text-field
                v-model="inputData"
                :rules="emailRules"
                label="Email"
            />
        </v-card-text>
        <v-card-actions class="mx-5 my-4 pa-0 d-flex justify-center">
            <v-btn
                v-if="responseData !== 'passwords.sent'"
                :class="buttonColor"
                :disabled="!inputData || !buttonColor"
                :loading="loading"
                depressed
                rounded
                @click="validEmailCheck"
            >
                Send Password Reset Link
            </v-btn>
        </v-card-actions>
        <v-card-text
            v-if="foundEmailFlag && clickedSubmit"
            class="font-weight-black"
            style="text-align: center;"
        >
            Email successfully sent!
        </v-card-text>
        <v-card-text
            v-else-if="!foundEmailFlag && clickedSubmit"
            class="error--text font-weight-black"
            style="text-align: center;"
        >
            We don't have a record of that email.
        </v-card-text>
        <v-card-text
            v-else
            class="py-5"
        />

    </v-card>
</template>


<script>
import {EMAIL_PASSWORD_RESET} from '../../store/actions.type';

export default {
    name: "ForgotPasswordModal",
    data() {
        return {
            loading: false,
            inputData: "",
            responseData: null,
            clickedSubmit: false,
            foundEmailFlag: false,
            emailRules: [
                (v) => !!v || "ex: someone@example.com",
                (v) =>
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || "ex: someone@example.com",
            ]
        }
    },
    computed: {
        buttonColor() {
            return this.inputData && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                this.inputData
            ) ? 'px-2 gold' : 'px-2'
        }
    },
    methods: {
        close() {
            this.clickedSubmit = false;
            this.$emit('close');

        },
        validEmailCheck() {
            //send input data into database to check if email exists.
            if (this.inputData && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                this.inputData
            )) {
                this.loading = true

                this.$store.dispatch(EMAIL_PASSWORD_RESET, this.inputData)
                    .then((data) => {
                        this.clickedSubmit = true;
                        this.foundEmailFlag = true;
                        this.responseData = data;
                    })
                    .catch(err => {
                        console.log(err);
                        this.clickedSubmit = true;
                        this.foundEmailFlag = false;
                    })
                    .finally(() => this.loading = false)
            }
        }
    },
}
</script>


<style></style>
