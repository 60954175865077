<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row class="ml-1 mt-5" align="center">
      <v-col md="6">
        <admin-header page-title="Students"/>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-data-table
      :headers="headers"
      :items="students"
      :search="search"
      :single-select="true"
      item-key="id"
      @click:row="editStudent"
      class="elevation-2 mt-3"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              class="mx-4"
              label="Search on anything"
              prepend-inner-icon="mdi-magnify"
              outlined
              background-color="white"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <span class="text-apsto-teal">
          There are no students on file.
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {GET_APPLICATION_BY_APPLICATION_MEMBER_ID, GET_STUDENT_PERMANENTS} from "../../../store/actions.type";

export default {
  name: "AdminStudentsList",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        name: '',
        approved: '',
      },
      dialog: false,
      headers: [
        {text: 'Name', align: 'start', value: 'name'},
        {text: 'Household Name', value: 'household_name'},
        {text: 'School Year', value: 'school_year_name'},
      ],
      loading: true,
      search: "",
    }
  },
  computed: {
    applicationData() {
      return this.$store.getters.currentApplicationData;
    },
    students() {
      return this.$store.getters.currentStudentPermanents;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getStudents() {
      this.loading = true;
      this.$store.dispatch(GET_STUDENT_PERMANENTS)
        .then(() => {
          this.loading = false;
        });
    },
    editStudent(item) {
      this.$store.dispatch(GET_APPLICATION_BY_APPLICATION_MEMBER_ID, item.application_member_id).then((data) => {
        let application_id = data.application_id;
        let household_id = data.household_id;
        this.$router.push(`/dashboard/admin/households/${household_id}/applications/${application_id}/review`);
      });
    },
    initialize() {
      this.students = [];
    },
  },
  mounted() {
    this.getStudents();
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}
</style>