<template>
    <loading v-if="loading" />
    <v-data-table
        v-else
        :headers="headers"
        :items="awardRefunds"
        class="elevation-1"
        item-key="id"
    >
        <template v-slot:item.actions="{ item, index }">
            <!-- Delete -->
            <v-icon
                color="red"
                @click="deleteAwardRefund(item)"
            >
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:item.amount="{ item, index }">
            <div>{{ item.amount | formatNumberWithCents }}</div>
        </template>
    </v-data-table>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import { DELETE_DEPOSIT_AWARD_REFUND } from "../../../store/actions.type";

export default {
    name: "DataTableDepositRefunds",
    components: {
        Loading,
    },
    data() {
        return {
            headers: [
                { text: "Student Name", value: "student_name", },
                { text: "Credit Type", value: "application_student_award.tax_credit_abbreviation", },
                { text: "Amount", value: "amount", align: "end" },
                { text: "Actions", value: "actions", sortable: false, align: "end" },
            ],
            loading: false,
        }
    },
    computed: {
        deposit() {
            return this.$store.getters.currentAdminDeposit;
        },
        awardRefunds() {
            return this.$store.getters.currentAdminDepositAwardRefunds;
        },
    },
    methods: {
        deleteAwardRefund(item) {
            this.loading = true;
            let deposit_id = this.deposit.id;
            let award_refund_id = item.id;
            this.$store.dispatch(DELETE_DEPOSIT_AWARD_REFUND, { deposit_id, award_refund_id })
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        },
    },
}
</script>
