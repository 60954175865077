<template>
    <v-row class="ml-0 mt-n10">
        <v-col class="pa-0">
            <div style="font-size: 1.125rem;">
                We're here to help you every step of the way. There are two ways to apply:
            </div>
            <ol class="mt-5">
                <li>
                    Apply <a
                        href="/scholarship-application"
                        target="_blank"
                    >online</a>. Once you create a Parent Portal account, you can:
                    <ul
                        class="mt-2"
                        style="padding-left: 40px;"
                    >
                        <li>
                            Edit or update your application
                        </li>
                        <li>
                            Be notified about award decisions
                        </li>
                        <li>
                            View award history
                        </li>
                        <li>
                            Re-apply each year
                        </li>
                        <li>
                            Access APSTO's valuable custom resources
                        </li>
                    </ul>
                </li>
                <li>
                    Pre-Register <a
                        href="/scholarship-application"
                        target="_blank"
                    >online</a>
                    if your child will start attending private school as a new student in the {{ schoolYear }}
                    school year. This is the perfect time to plan ahead, please click this helpful link for
                    <a
                        href="/new-student-information"
                        target="_blank"
                    >
                        New Student Information
                    </a>
                    to learn more.
                </li>
            </ol>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "HowToApply",
    computed: {
        schoolYear() {
            return (new Date().getFullYear() + 1) + "-" + (new Date().getFullYear() + 2);
        },
    }
};
</script>

<style scoped></style>
