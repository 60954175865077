<template>
  <dashboard/>
</template>

<script>
import Dashboard from "../components/dashboard/Dashboard.vue";

export default {
  name: "Home",
  components: {
    Dashboard
  }
}
</script>
