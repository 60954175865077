import Vue from 'vue';
import VueRouter from 'vue-router';

import store from "../store";

import {CHECK_AUTH} from "../store/actions.type";

/**
 * Route Components
 */
import errorRoutes from "../routes/errors";
import adminRoutes from "../routes/admins";
import adminSchools from "../routes/admin-schools";
import companyAdminRoutes from "../routes/company-admins";
import companyRoutes from "../routes/companies";
import corporatePledgeRoutes from "../routes/corporate-pledges";
import donorRoutes from "../routes/donors";
import employeeDonationRoutes from "../routes/employee-donations";
import householdAdminRoutes from "../routes/household-admins";
import householdApplicationRoutes from "../routes/household-applications";
import householdRoutes from "../routes/households";
import invites from "../routes/invites";
import profileRoutes from "../routes/profile";
import promoRoutes from "../routes/promos";
import schoolAdminRoutes from "../routes/school-admins";
import schoolApplicationRoutes from "../routes/school-applications";
import schoolRoutes from "../routes/schools";
import schoolStudentRoutes from "../routes/school-students";
import websiteRoutes from "../routes/website";

let routes = errorRoutes;
routes = routes.concat(adminRoutes);
routes = routes.concat(adminSchools);
routes = routes.concat(companyRoutes);
routes = routes.concat(companyAdminRoutes);
routes = routes.concat(corporatePledgeRoutes);
routes = routes.concat(donorRoutes);
routes = routes.concat(employeeDonationRoutes);
routes = routes.concat(householdRoutes);
routes = routes.concat(promoRoutes);
routes = routes.concat(householdAdminRoutes);
routes = routes.concat(householdApplicationRoutes);
routes = routes.concat(invites);
routes = routes.concat(schoolRoutes);
routes = routes.concat(schoolAdminRoutes);
routes = routes.concat(schoolApplicationRoutes);
routes = routes.concat(schoolStudentRoutes);
routes = routes.concat(profileRoutes);
routes = routes.concat(websiteRoutes);

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    saveScrollPosition: true,
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0
        }
    },
    routes,
});

router.beforeEach((to, from, next) => {
    store.dispatch(CHECK_AUTH).then(() => {
        if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
            next('/login-register');
        }
        else if (to.meta.requiresAdmin && !(store.getters.hasRole("site-admin") || store.getters.hasRole("site-admin-1") || store.getters.hasRole("super-admin"))) {
            next('/login-register');
        }
        else if (to.meta.requiresSchoolAdmin && !store.getters.hasRole("school-admin")) {
            next('/login-register');
        }
        else {
            next();
        }
    }).catch(() => {
        next('/');
    });
});

export default router;
