<template>
    <loading v-if="loading" />
    <v-row v-else>
        <v-col class="pa-0">
            <v-card-title class="mx-2 mx-sm-0">
                <router-link :to="{ name: 'admin-menu' }">
                    <span class="text-apsto-teal">Admin /&nbsp;</span>
                </router-link>
                <div class="text-apsto-purple mr-2">
                    {{ pageTitle }}
                </div>
                <div v-if="hrefText">
                    <a :href="href">
                        <span :class="hrefColor">{{ hrefText }}</span>
                    </a>
                </div>
                <div
                    v-if="icon"
                    class="ml-2"
                >
                    <a
                        v-if="href"
                        :href="href"
                    >
                        <v-icon color="secondary">fas {{ icon }}</v-icon>
                    </a>
                    <v-icon
                        v-else
                        color="secondary"
                        style="cursor: pointer;"
                    >fas {{ icon }}</v-icon>
                </div>
            </v-card-title>
            <v-card-title
                v-if="secondLine"
                class="mt-n8 mx-2 mx-sm-0"
            >
                <div class="text-apsto-purple">
                    {{ secondLine }}
                </div>
            </v-card-title>
        </v-col>
    </v-row>
</template>

<script>

import Loading from "../../../components/shared/Loading.vue";

export default {
    name: "AdminHeader",
    components: { Loading },
    data() {
        return {
            loading: false,
        };
    },
    props: {
        href: {
            required: false,
            type: String
        },
        hrefColor: {
            default: 'text-apsto-teal',
            required: false,
            type: String
        },
        hrefText: {
            required: false,
            type: String
        },
        icon: {
            required: false,
            type: String
        },
        pageTitle: {
            required: true,
            type: String
        },
        secondLine: {
            required: false,
            type: String
        }
    },
};
</script>

<style scoped></style>
