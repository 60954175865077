<template>
    <v-row class="ml-2 mb-0 mt-10">
        <v-col class="pa-0">
            <ol>
                <li>
                    Decide how much you want to donate for {{ currentYear }}. State maximums are shown below.
                </li>
                <li>
                    Schedule your contributions through December 31, {{ currentYear }}. <a
                        href="/donor-portal"
                        target="_blank"
                    >Create a {{ currentYear }}
                        Contribution Schedule</a>.
                </li>
                <li>
                    You will be prompted to login to your donor portal account where you can select to start monthly
                    donations.
                </li>
                <li>
                    Specify the amount you would like to contribute each month and select the day you would like to make
                    your
                    monthly contributions.
                </li>
                <li>
                    Decide if you would like to recommend a student or school to benefit from your recommendation, there
                    will be a
                    section on the form for you to do this.
                </li>
            </ol>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "MonthlyContributions",
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    }
};
</script>

<style scoped></style>
