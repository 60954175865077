<template>
    <div>
        <v-card-title>
            Documents
            <span
                class="ml-2"
                style="font-size: 1.015rem"
                v-if="documents.length"
            >
                Click green chevron (<v-icon color="secondary">mdi-chevron-down</v-icon>) to see an uploaded document
            </span>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="documents"
            class="elevation-2 mx-4"
            sort-by="student_name"
            show-expand
            :expanded.sync="expanded"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td
                    :colspan="headers.length"
                    class="pa-4 secondaryLight"
                >
                    <div v-if="item.file_id">

                        <iframe
                            :src="`/customer-documents/${item.file_id}`"
                            style="width:100%;height:600px"
                        ></iframe>

                        <div v-if="canEdit">
                            <v-btn
                                color="secondary"
                                @click="determination(item, 'approve')"
                                :disabled="disableApproveButton(item)"
                            >
                                Approve File
                            </v-btn>

                            <v-btn
                                color="error"
                                @click="determination(item, 'decline')"
                                :href="mailHref(item)"
                                class="ml-2"
                                target="_blank"
                            >
                                Decline File
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-if="!item.file_id"
                        class="missing-data text-h6-mulish"
                    >
                        The applicant still needs to upload this document.
                    </div>
                </td>
            </template>
            <template #item.document_submitted="{ item }">
                <div v-if="item.document_submitted">
                    {{ displayDate(item.document_submitted) }}
                </div>
                <div
                    v-else
                    class="missing-data"
                >
                    Not Submitted
                </div>
            </template>
            <template v-slot:no-data>
                <div class="text-apsto-teal text-h7-mulish">
                    No documents required based on answers to student questionnaire(s).
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {
    GET_HOUSEHOLD_APPLICATION_DOCUMENTS,
    GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS,
    PATCH_STUDENT_DOCUMENT_ADMIN,
} from "../../../../../store/actions.type";

export default {
    name: "DocumentsApproveDecline",
    props: {
        canEdit: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data: () => ({
        createDialog: false,
        dialogDelete: false,
        expanded: [],
        temporaryItem: null,
    }),
    computed: {
        headers() {
            return [
                {
                    text: "Student",
                    align: "start",
                    sortable: false,
                    value: "student_name",
                },
                { text: "Qualifier", value: "qualification_name" },
                { text: "Document", value: "document_name" },
                { text: "Uploaded", value: "document_submitted" },
                { text: "Approved", value: "document_approved" },
                { text: "Actions", value: "actions", sortable: false },
                { text: '', value: 'data-table-expand' },
            ];
        },
        application() {
            return this.$store.getters.currentApplication;
        },
        defaultItem() {
            return {
                student: {},
            };
        },
        documents() {
            return this.$store.getters.currentApplicationDocuments;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        determination(item, action) {
            let application_document_id = item.document_id;
            let application_student_id = item.application_student_id;
            this.$store.dispatch(PATCH_STUDENT_DOCUMENT_ADMIN, { application_document_id, application_student_id, action })
                .then(() => {
                    this.getDocuments();
                })
                .then(() => {
                    this.updateScholarships();
                })

            if (action === "decline") {
                this.mailHref(item);
            }

        },
        disableApproveButton(item) {
            return !!item.document_approved;
        },
        getDocuments() {
            const application_id = this.$route.params.application_id;
            const household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_DOCUMENTS, {
                household_id,
                application_id,
            });
        },
        mailHref(item) {
            let studentFirstName = item.student_first_name;
            let documentName = item.document_name;
            let recipientEmail = this.application.household.primary_user_info.email;
            let recipientFirstName = this.application.household.primary_user_info.first_name;
            let schoolYearName = this.application.school_year_name + " School Year";

            return "mailto:" + recipientEmail + "?subject=Your Application For " + studentFirstName + " For The " + schoolYearName + "&body=Hi " + recipientFirstName +
                ", Please re-upload the " + documentName + " document for " + studentFirstName + ". The one you uploaded EXPLAIN_PROBLEM";
        },
        updateScholarships() {
            const application_id = this.$route.params.application_id;
            const household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS, {
                household_id,
                application_id,
            });
        },
    },
    mounted() {
        this.getDocuments();
        this.loading = false;
    },
};
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-icon {
    color: var(--apsto-teal);
}

.missing-data {
    color: #ff0000;
}
</style>
