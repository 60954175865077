import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_CORPORATE_PLEDGE,
    GET_CORPORATE_PLEDGE,
    GET_CORPORATE_PLEDGES,
    GET_TAX_CREDIT_INFO,
    PATCH_CORPORATE_PLEDGE,
    POST_CORPORATE_PLEDGE
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    APPEND_DONOR_RECOMMENDATION,
    APPEND_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION,
    CLEAR_ERRORS,
    INCREMENT_RECOMMENDATION_STUDENT_ID,
    REMOVE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION,
    REMOVE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATIONS,
    SET_CORPORATE_PLEDGE,
    SET_CORPORATE_PLEDGES,
    SET_ERROR,
    SET_TAX_CREDIT_INFO,
    UPDATE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION, INCREMENT_RECOMMENDATION_SCHOOL_ID,
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        corporatePledge: {},
        corporatePledges: [],
        subs_subsidiary_options: [
            {id: 0, value: 'No'},
            {id: 1, value: 'Yes'}
        ],
        tax_credit_types: [
            {id: 0, value: 'Please Select'},
            {id: 2, value: 'Low Income Corporate Tax Credit Scholarship A.R.S. 43-1183'},
            {id: 1, value: 'Disabled/Displaced Tax Credit Scholarship A.R.S. 43-1184'},
        ],
        taxCreditInfo: {}
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentCorporatePledges(state) {
        return state.corporatePledges;
    },
    currentCorporatePledge(state) {
        return state.corporatePledge;
    },
    subsSubsidiaryOptions(state) {
        return state.subs_subsidiary_options;
    },
    taxCreditInfo(state) {
        return state.taxCreditInfo;
    },
    taxCreditTypes(state) {
        return state.tax_credit_types;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_TAX_CREDIT_INFO](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/corporate-pledges/tax-credit-info`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_TAX_CREDIT_INFO, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "corporatePledges",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_CORPORATE_PLEDGES](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${id}/corporate-pledges`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CORPORATE_PLEDGES, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'corporatePledges');
                    reject(error.response);
                });
        });
    },
    [GET_CORPORATE_PLEDGE](context, {company_id, corporate_pledge_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/corporate-pledges/${corporate_pledge_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CORPORATE_PLEDGE, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'corporatePledges');
                    reject(error.response);
                });
        });
    },
    [POST_CORPORATE_PLEDGE](context, {company_id, corporatePledge}) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/companies/" + company_id + "/corporate-pledges", corporatePledge)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'corporatePledges');
                    reject(error.response);
                });
        });
    },
    [PATCH_CORPORATE_PLEDGE](context, {company_id, corporate_pledge_id, corporatePledge}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/companies/${company_id}/corporate-pledges/${corporate_pledge_id}`, corporatePledge)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CORPORATE_PLEDGE, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'corporatePledges');
                    reject(error.response);
                });
        });
    },
    [DELETE_CORPORATE_PLEDGE](context, {company_id, corporate_pledge_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/companies/${company_id}/corporate-pledges/${corporate_pledge_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'corporatePledges');
                    reject(error.response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_CORPORATE_PLEDGES](state, data) {
        state.corporatePledges = data;
    },
    [SET_CORPORATE_PLEDGE](state, data) {
        state.corporatePledge = data;
    },
    [SET_TAX_CREDIT_INFO](state, data) {
        state.taxCreditInfo = data;
    },
    [APPEND_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION](state, {school}) {
        state.corporatePledge.recommendations.schools.push(school);
    },
    [INCREMENT_RECOMMENDATION_SCHOOL_ID](state) {
        state.recommendationsCount++;
    },
    [UPDATE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION](state, {school}) {
        let list = state.corporatePledge.recommendations.schools;
        for (let item = 0; item < list.length; item++) {
            if (list[item].id === school.id) {
                list.splice(item, 1, school);
            }
        }
    },
    [REMOVE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATIONS](state) {
        state.corporatePledge.recommendations.schools = [];
    },
    [REMOVE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION](state, {id}) {
        let list = state.corporatePledge.recommendations.schools;
        for (let item = 0; item < list.length; item++) {
            if (list[item].id === id) {
                list.splice(item, 1);
            }
        }
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
