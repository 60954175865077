<template>
  <v-card>
    <div class="text-center"
         fill-height
         style="height: calc(100vh - 58px);"
    >
      <v-row align="center" justify="center">
        <v-col rows="12" md="8" lg="6">
          <div class="text-h4 mt-10">
            <div v-if="loading">Logging in, please wait.</div>
            <div v-if="error">{{ errorResponse }}</div>
          </div>
          <v-btn
            color="primary"
            to="/"
            class="mt-9"
          >
            Go to Home Page
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import {SIGN_IN_FROM_LINK, GET_CONTENTS} from "../../store/actions.type";

export default {
  name: "SignInFromLink",
  data() {
    return {
      loading: true,
      error: false,
      errorResponse: null
    };
  },
  computed: {
    calledFrom() {
      return this.$route.query.calledFrom;
    },
    email() {
      return this.$route.query.email;
    },
    expires() {
      return this.$route.query.expires;
    },
    user() {
      return this.$route.query.user;
    },
    signature() {
      return this.$route.query.signature;
    },
    
  },
  methods: {
    login() {
      let calledFrom = this.calledFrom;
      let email = this.email;
      let expires = this.expires;
      let user = this.user;
      let signature = this.signature;
      let pushTo = this.pushTo();

      this.$store.dispatch(SIGN_IN_FROM_LINK, {calledFrom, email, expires, user, signature})
        .then(() => {
          this.loading = false;
          this.$store.dispatch(GET_CONTENTS).then(() => {
            this.$router.push({name: pushTo});
          });
        })
        .catch((response) => {
          console.log(response);
          switch(response.data) {
            case "Link Expired":
              this.errorResponse = "The link in this email has expired."
              break;
            default:
              this.errorResponse = "Error: " + response.data;
          }
          this.loading = false;
          this.error = true;
        });
    },
    pushTo() {
      switch (this.calledFrom) {
        case "charitableDonation":
          return "charitable-donation-create";
        case "donation":
          return "donor-donation-create";
        case "donationPage":
          return "donor-donation-page-create";
        case "loginPage":
          return "home";
      }
    }
  },
  mounted() {
    this.login();
  },
};
</script>

