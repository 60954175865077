import { render, staticRenderFns } from "./AdminSiteDocumentFileUpload.vue?vue&type=template&id=0b6ba792&scoped=true&"
import script from "./AdminSiteDocumentFileUpload.vue?vue&type=script&lang=js&"
export * from "./AdminSiteDocumentFileUpload.vue?vue&type=script&lang=js&"
import style0 from "./AdminSiteDocumentFileUpload.vue?vue&type=style&index=0&id=0b6ba792&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6ba792",
  null
  
)

export default component.exports