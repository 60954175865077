<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col class="ml-4 pt-4">
                <admin-header page-title="Review School Application"/>
            </v-col>
        </v-row>
        <v-row v-if="school">
            <v-col md="6">
                <div class="mt-0 box-with-background">
                    {{ school.name }}
                    <div v-if="school.physical_address">
                        {{ school.physical_address.city }},
                        {{ school.physical_address.state }}
                        {{ school.physical_address.postal }}
                    </div>
                </div>
                <div class="mt-4 box-with-background apsto-purple">
                    Partner Agreement for School Year {{ schoolApplication.school_year_name }}
                </div>
            </v-col>
        </v-row>
        <v-row v-if="school">
            <v-col md="6">
                <v-card-title class="ml-n4">Questionnaire</v-card-title>
                <v-card class="">
                    <v-card-text style="color: rgba(0, 0, 0, 0.87); font-size: 1.05rem;">
                        <div class="qa-block">
                            (Q) Is this a qualified school, a nongovernmental primary or secondary school that does not
                            discriminate
                            on the basis of race, color, disability, familial status or national origin and that
                            satisfies
                            the
                            requirements prescribed by law for private schools in Arizona?
                            <div class="mt-2">
                                <div v-if="schoolApplication.comports_to_law">
                                    (A) Yes
                                </div>
                                <div v-else-if="schoolApplication.comports_to_law === null">
                                    <span class="missing-data">Question left unanswered.</span>
                                </div>
                                <div v-else>
                                    (A) No
                                </div>
                            </div>
                        </div>
                        <div class="qa-block">
                            (Q) Does this qualified school require all teaching staff and
                            personnel that have unsupervised contact with students to be
                            fingerprinted?
                            <div class="mt-2">
                                <div v-if="schoolApplication.fingerprints">
                                    (A) Yes
                                </div>
                                <div v-else-if="schoolApplication.fingerprints === null">
                                    <span class="missing-data">Question left unanswered.</span>
                                </div>
                                <div v-else>
                                    (A) No
                                </div>
                            </div>
                            <div
                                v-if="schoolApplication.fingerprints"
                                class="mt-4"
                            >
                                (Q) Name of the company / organization you use
                                <div class="mt-2">
                                    <div v-if="schoolApplication.fingerprints_provider">
                                        (A) {{ schoolApplication.fingerprints_provider }}
                                    </div>
                                    <div
                                        v-else
                                        class="missing-data"
                                    >Question left unanswered.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="qa-block">
                            (Q) Does this qualified school annually administer and make available
                            to the public the aggregate test scores of its students on a
                            nationally standardized norm-referenced achievement test?
                            <div class="mt-2">
                                <div v-if="schoolApplication.publish_test_scores">
                                    (A) Yes
                                </div>
                                <div v-else-if="schoolApplication.publish_test_scores === null">
                                    <span class="missing-data">Question left unanswered.</span>
                                </div>
                                <div v-else>
                                    (A) No
                                </div>
                            </div>
                            <div
                                v-if="schoolApplication.publish_test_scores"
                                class="mt-4"
                            >
                                (Q) Name of the tests you use
                                <div class="mt-2">
                                    <div v-if="schoolApplication.test_name">
                                        (A) {{ schoolApplication.test_name }}
                                    </div>
                                    <div
                                        v-else
                                        class="missing-data"
                                    >Question left unanswered.
                                    </div>
                                </div>
                                <div class="mt-4">
                                    (Q) How are these scores published?
                                    <div class="mt-2">
                                        <div v-if="schoolApplication.how_scores_published">
                                            (A) {{ schoolApplication.how_scores_published }}
                                        </div>
                                        <div
                                            v-else
                                            class="missing-data"
                                        >Question left unanswered.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="qa-block">
                            (Q) Does this qualified school charge tuition for all students
                            (notwithstanding any types of scholarships or discounts it may
                            offer)?
                            <div class="mt-2">
                                <div v-if="schoolApplication.charge_for_all_students">
                                    (A) Yes
                                </div>
                                <div v-else-if="schoolApplication.charge_for_all_students === null">
                                    <span class="missing-data">Question left unanswered.</span>
                                </div>
                                <div v-else>
                                    (A) No
                                </div>
                            </div>
                        </div>

                        <div class="qa-block">
                            We would like to be eligible to receive scholarships in the following tax credit programs:
                            <div v-if="scholarshipsRequested">
                                <div
                                    v-if="schoolApplication.disabled_displaced"
                                    class="mt-2"
                                >
                                    * Disabled/Displaced Tax Credit Scholarship
                                </div>
                                <div
                                    v-if="schoolApplication.low_income_corporate"
                                    class="mt-2"
                                >
                                    * Low-Income Corporate Tax Credit Scholarship
                                </div>
                                <div
                                    v-if="schoolApplication.original"
                                    class="mt-2"
                                >
                                    * Original Tax Credit Scholarship
                                </div>
                                <div
                                    v-if="schoolApplication.switcher"
                                    class="mt-2"
                                >
                                    * Switcher (Overflow/Plus) Tax Credit Scholarship
                                </div>
                            </div>
                            <div
                                v-else
                                class="missing-data mt-2"
                            >School has not selected any scholarships.
                            </div>
                        </div>

                        <!--Tuition Schedule-->
                        <div class="qa-block">
                            Tuition Schedule
                            <v-data-table
                                :footer-props="{ 'items-per-page-options': [-1] }"
                                :headers="tuitionScheduleHeaders"
                                :hide-default-footer="true"
                                :items="schoolApplication.school_levels"
                                class="mt-5"
                                sort-by="grade_id"
                            >
                                <template #item.tuition_amount="{ item }">
                                    <div> {{ formatCurrency(item.tuition_amount) }}</div>
                                </template>
                                <template v-slot:no-data>
                    <span class="text-apsto-teal">
                        No grades have been added.
                    </span>
                                </template>
                            </v-data-table>
                        </div>

                        <!--Tuition Schedule Comments-->
                        <div class="qa-block">
                            Tuition Schedule Comments
                            <div
                                v-if="schoolApplication.comments"
                                class="mt-2"
                            >
                                {{ schoolApplication.comments }}
                            </div>
                            <div
                                v-else
                                class="mt-2"
                            >
                                No comments have been added.
                            </div>
                        </div>

                        <div class="qa-block">
                            Terms
                            <div
                                v-if="schoolApplication.agree_to_terms"
                                class="mt-2"
                            >
                                {{ schoolApplication.responsible_party_name }} agreed to the terms.
                            </div>
                            <div
                                v-else
                                class="missing-data mt-2"
                            >
                                Terms have not been agreed to.
                            </div>
                        </div>

                        <div class="qa-block">
                            Status:
                            <div
                                v-if="!statusComplete"
                                class="missing-data mt-2"
                            >
                                Application Incomplete
                            </div>
                            <status-selector
                                v-else
                                v-model="schoolApplication.status_id"
                                label=""
                            />
                        </div>

                    </v-card-text>
                    <v-divider/>
                    <v-card-actions class="pb-4 mt-4">
                        <v-btn
                            :to="{
                                name: 'admin-school-edit',
                                params: { school_id: this.$route.params.school_id },
                            }"
                            class="cancel-button"
                            rounded
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            :disabled="disableSaveButton"
                            class="ml-2 save-by-cancel-button"
                            color="primary"
                            rounded
                            @click="save"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
            <v-col md="6">
                <revisions
                    :application="schoolApplication"
                    application-type="school"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {GET_SCHOOL, GET_SCHOOL_APPLICATION, PATCH_SCHOOL_APPLICATION_STATUS} from "../../../../../store/actions.type";
import AdminHeader from "../../../main/AdminHeader";
import Loading from "../../../../../components/shared/Loading";
import StatusSelector from "../../../../../components/shared/StatusSelector";
import Revisions from "../../../../../components/shared/Revisions";
import InlineEditTuition from "../../../../../components/shared/InlineEditTuition.vue";

export default {
    name: "AdminSchoolApplicationReview",
    components: {
        InlineEditTuition,
        StatusSelector, AdminHeader, Loading, Revisions
    },
    data() {
        return {

            loading: true,
        }
    },
    computed: {
        disableSaveButton() {
            return !this.isStatusComplete
        },
        school() {
            return this.$store.getters.currentSchool;
        },
        schoolApplication() {
            return this.$store.getters.currentSchoolApplication;
        },
        scholarshipsRequested() {
            return !!(this.schoolApplication.disabled_displaced ||
                this.schoolApplication.low_income_corporate ||
                this.schoolApplication.original ||
                this.schoolApplication.switcher)
        },
        isStatusComplete() {
            return !(this.schoolApplication.comports_to_law === null ||
                this.schoolApplication.fingerprints === null ||
                this.schoolApplication.publish_test_scores === null ||
                this.schoolApplication.charge_for_all_students === null ||
                // this.schoolApplication.fingerprints && !this.schoolApplication.fingerprints_provider ||
                // this.schoolApplication.publish_test_scores && !this.schoolApplication.test_name ||
                // this.schoolApplication.publish_test_scores && !this.schoolApplication.how_scores_published ||
                !(this.schoolApplication.school_levels && this.schoolApplication.school_levels.filter(grade => grade.tuition_amount).length > 0) ||
                !this.schoolApplication.agree_to_terms)
        },
        statusComplete() {
            let complete = this.isStatusComplete;

            if (!complete && this.schoolApplication.status !== 1) {
                this.resetApplicationStatus();
            }

            return complete
        },
        tuitionScheduleHeaders() {
            return [
                {text: "LEVEL", align: "start", sortable: false, value: "name",},
                {text: "AMOUNT", align: 'end', value: "tuition_amount"},
            ]
        },
    },
    methods: {
        getSchoolApplication() {
            this.loading = true;
            let school_id = this.$route.params.school_id;
            let school_application_id = this.$route.params.school_application_id;
            this.$store.dispatch(GET_SCHOOL_APPLICATION, {school_id, school_application_id})
                .then(() => {
                    this.$store.dispatch(GET_SCHOOL, school_id)
                    this.loading = false;
                });
        },
        resetApplicationStatus() {
            let school_application_id = this.$route.params.school_application_id;
            this.schoolApplication.status = 1;
            let status = this.schoolApplication.status;
            this.$store.dispatch(PATCH_SCHOOL_APPLICATION_STATUS, {school_application_id, status})
        },
        save() {
            let school_application_id = this.$route.params.school_application_id;
            let status = this.schoolApplication.status_id;
            this.$store.dispatch(PATCH_SCHOOL_APPLICATION_STATUS, {school_application_id, status})
                .then(() => {
                    this.$router.push({
                        name: 'admin-school-edit',
                        params: {
                            school_id: this.$route.params.school_id
                        }
                    })
                })
                .catch((response) => {
                    console.log(response);
                });
        }
    },
    mounted() {
        this.getSchoolApplication();
    }
}
</script>

<style scoped>
.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    padding: 20px;
}

.box-with-background.apsto-purple {
    background-color: var(--apsto-purple);
}

.qa-block {
    border-bottom: 1px dotted var(--apsto-teal);
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.qa-block:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.missing-data {
    color: #ff0000;

}
</style>
