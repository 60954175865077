<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center">
      <v-col cols="auto">
        <div class="text-h6-mulish">Students With Awards</div>
      </v-col>
      <v-col style="flex-grow: 0;">
        <v-icon
          color="primary"
          rounded
          @click="createStudentAward"
        >
          mdi-plus
        </v-icon>
      </v-col>
      <v-col>
        <a :href="downloadHref">
          <v-icon color="primary">
            mdi-download
          </v-icon>
        </a>
      </v-col>
    </v-row>
    <v-dialog
      v-model="createDialog"
      :fullscreen="!!isMobile"
      :max-width="dialogMaxWidth"
    >
      <admin-school-student-award-create @done="createDialog = false"/>
    </v-dialog>
    <admin-school-student-awards-table/>
  </div>
</template>

<script>
import AdminHeader from "../../../main/AdminHeader";
import AdminSchoolStudentAwardCreate from "./AdminSchoolStudentAwardCreate";
import AdminSchoolStudentAwardsTable from "./AdminSchoolStudentAwardsTable";
import Loading from "../../../../../components/shared/Loading";
import {SET_SCHOOL_STUDENT_AWARD} from "../../../../../store/mutations.type";
import {
  GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD,
  GET_UNLOCKED_STUDENT_AWARDS_COUNT
} from "../../../../../store/actions.type";

export default {
  name: "AdminSchoolStudentAwards",
  components: {
    AdminHeader,
    AdminSchoolStudentAwardCreate,
    AdminSchoolStudentAwardsTable,
    Loading
  },
  data() {
    return {
      createDialog: false,
      fiscal_year_id: null,
      loading: false,
      month: null,
      saving: false,
    }
  },
  computed: {
    downloadHref() {
      let school_id = this.$route.params.school_id;
      return "/api/admin/schools/" + school_id + "/pending-awards/download";
    },
    school() {
      return this.$store.getters.currentUserSchool;
    },
    today() {
      return new Date().toLocaleDateString();
    },
    error() {
      return this.$store.getters.getMessage("adminSchools");
    },
    errors() {
      return this.$store.getters.getErrors("adminSchools");
    },
  },
  methods: {
    createStudentAward() {
      this.$store.commit(SET_SCHOOL_STUDENT_AWARD, {
        amount: null,
        application_student_school_id: null,
        fiscal_year_id: this.fiscal_year_id,
        month: this.month,
        tax_credit_type: null,
      });
      this.createDialog = true;
    },
    getSchoolStudentAwardsLatestPeriod() {
      this.loading = true;
      this.$store.dispatch(GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD)
        .then((data) => {
          this.loading = false;
          this.fiscal_year_id = data.fiscal_year_id;
          this.month = data.month;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch(GET_UNLOCKED_STUDENT_AWARDS_COUNT).then(() => {
      this.loading = false;
    });
    this.getSchoolStudentAwardsLatestPeriod();
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: unset !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

::v-deep th:not([role=columnheader]) {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}
</style>
