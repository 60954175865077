<template>
    <div>
        <v-menu
            v-model="isEditDialogOpen"
            :close-on-click="true"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <span
                    v-bind="attrs"
                    v-on="on"
                >
                    <span
                        v-if="value"
                        class="text-apsto-teal font-weight-black"
                    >
                        {{ value | formatNumber }}
                    </span>
                    <span v-else>000</span>
                </span>
            </template>
            <v-sheet>
                <v-row
                    align="center"
                    class="px-5"
                    no-gutters
                >
                    <v-col>
                        <v-text-field
                            v-model="localValue"
                            :label="label"
                            reverse
                            @keydown="changeValue"
                            @keydown.enter="save"
                            @keydown.esc="cancel"
                        />
                        <div
                            style="font-size: 12px; font-style: italic; font-weight: 300; padding-bottom: 10px; text-align: right;">
                            To save changes, place cursor at far right of your entry then press Enter key
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-menu>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "InlineEditTuition",
    props: {
        label: {
            default: "Value",
            type: String,
        },
        value: {
            default: 0,
        },
    },
    data() {
        return {
            isEditDialogOpen: false,
            loading: true,
            snackbar: false,

            snackbarMessage: "",
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("schoolGrades");
        },
        errors() {
            return this.$store.getters.getErrors("schoolGrades");
        },
        localValue: {
            get() {
                return this.value;
            },
            set(localValue) {
                if (localValue < 0) this.value = 0;
            },
        },
    },
    methods: {
        save(event) {
            event.preventDefault();
            if (event.target.value < 0) {
                this.snackbar = true;
                this.snackbarMessage = "Please enter zero or greater.";
                return;
            }
            this.$emit("input", event.target.value);
            this.isEditDialogOpen = false;
        },
        cancel(event) {
            event.preventDefault();
            this.isEditDialogOpen = false;
        },
        changeValue() {
            const val = Math.round(Number(this.localValue));
            let tempVal = val;
            if (val < 0) {
                tempVal = 0;
            }
            this.localValue = tempVal;
        },
    },
};
</script>
