import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_HOUSEHOLD,
    GET_HOUSEHOLD,
    GET_HOUSEHOLDS,
    GET_USER_HOUSEHOLD,
    GET_USER_HOUSEHOLDS,
    PATCH_HOUSEHOLD,
    POST_HOUSEHOLD
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_HOUSEHOLD,
    SET_HOUSEHOLDS,
    SET_USER_HOUSEHOLD,
    SET_USER_HOUSEHOLDS,
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        households: [],
        household: {},
        userHouseholds: [],
        userHousehold: {},
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentHouseholds(state) {
        return state.households;
    },
    currentHousehold(state) {
        return state.household;
    },
    currentUserHouseholds(state) {
        return state.userHouseholds;
    },
    currentUserHousehold(state) {
        return state.userHousehold;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_HOUSEHOLDS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/admin/households")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "households",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_HOUSEHOLD](context, household_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/households/${household_id}`)
                .then(({data}) => {
                    console.log("GET_HOUSEHOLD: ", household_id, data)
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "households",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_USER_HOUSEHOLDS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/households")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_HOUSEHOLDS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "households",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_USER_HOUSEHOLD](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_HOUSEHOLD, data);
                    resolve(data);
                })
                .catch((error) => {
                    console.log("GET_USER_HOUSEHOLD: ", error.response)
                    handleError(context, error, 'households');
                    reject(error.response);
                });
        });
    },
    [POST_HOUSEHOLD](context, household) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/households", household)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "households",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_HOUSEHOLD](context, household) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household.id}`, household)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'households');
                    reject(error.response);
                });
        });
    },
    [DELETE_HOUSEHOLD](context, household) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/households/${household.id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'households');
                    reject(error.response);
                });
        });
    }

};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_HOUSEHOLDS](state, data) {
        state.households = data;
    },
    [SET_HOUSEHOLD](state, data) {
        state.household = data;
    },
    [SET_USER_HOUSEHOLDS](state, data) {
        state.userHouseholds = data;
    },
    [SET_USER_HOUSEHOLD](state, data) {
        state.userHousehold = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
