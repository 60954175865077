<template>
    <v-row no-gutters class="flex-column flex-nowrap fill-height">
        <v-toolbar class="flex-grow-0" dense flat color="primaryTheme">
            <v-toolbar-title class="primaryButton--text">Help Conversations</v-toolbar-title>
            <v-spacer />
            <!-- <v-btn icon @click="toggleSound">
        <v-icon color="primaryButton">{{ profile.conversation_sound ? 'mdi-volume-high' : 'mdi-volume-off' }}</v-icon>
      </v-btn> -->
            <v-btn icon @click="toggleDialog">
                <v-icon color="primaryButton">fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>

        <v-list ref="conversations" class="overflow-y-auto overflow-x-hidden fill-height" subheader>
            <conversation v-for="conversation in conversations" :key="conversation.id" :conversation="conversation" />
            <infinite-loading @infinite="getConversations" direction="bottom" :identifier="conversationsIdentifier">
                <div slot="spinner"></div>
                <div class="pa-2" slot="no-more">End of conversations.</div>
                <div class="pa-2" slot="no-results">
                    <div v-if="!conversations.length">
                        <strong>You currently have no active conversations.</strong>
                    </div>
                    <div v-else>
                        End of conversations.
                    </div>
                </div>
            </infinite-loading>
        </v-list>
    </v-row>
</template>

<script>
import { GET_CONVERSATIONS, } from "../../../store/actions.type";
import { TOGGLE_CONVERSATION_DIALOG } from "../../../store/mutations.type";

import Conversation from "./Conversation";

export default {
    name: "Conversations",
    components: {
        Conversation,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        isShowingConversationDialog() {
            return this.$store.getters.isShowingConversationDialog;
        },
        conversations() {
            return this.$store.getters.conversations;
        },
        profile() {
            return this.$store.getters.currentProfile;
        },
        conversationsIdentifier() {
            return this.$store.getters.getConversationsIdentifier;
        },
    },
    methods: {
        toggleSound() {
            //this.$store.dispatch(TOGGLE_CONVERSATION_SOUND);
        },
        toggleDialog() {
            this.$store.commit(TOGGLE_CONVERSATION_DIALOG);
        },
        getConversations($state) {
            if (this.loading) {
                return;
            }
            if (!$state) {
                return;
            }
            this.loading = true;
            this.$store.dispatch(GET_CONVERSATIONS).then((data) => {
                if (data.length) {
                    this.loading = false;
                    $state.loaded();
                } else {
                    this.loading = false;
                    $state.complete();
                }
            });
        },
    },
};
</script>
<style scoped>
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
}
</style>
