<template>
  <v-card>
    <div class="text-center"
         fill-height
         style="height: calc(100vh - 58px);"
    >
      <v-row align="center" justify="center">
        <v-col rows="12" md="8" lg="6">
          <div class="text-h4 mt-10">
            <div v-if="loading">Signing in, please wait.</div>
            <div v-else>
              <div v-if="error">{{ errorResponse }}</div>
              <div v-else>
                Your login email has been changed!
              </div>
            </div>

          </div>
          <v-btn
            color="primary"
            to="/"
            class="mt-9"
          >
            Go to Home Page
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import {CONFIRM_CHANGE_USERNAME} from "../../store/actions.type";

export default {
  name: "changeUsername",
  data() {
    return {
      loading: true,
      error: false,
      errorResponse: null
    };
  },
  computed: {
    calledFrom() {
      return this.$route.query.calledFrom;
    },
    email() {
      return this.$route.query.email;
    },
    expires() {
      return this.$route.query.expires;
    },
    user() {
      return this.$route.query.user;
    },
    signature() {
      return this.$route.query.signature;
    },

  },
  methods: {
    confirmChangeUsername() {
      let email = this.email;
      let expires = this.expires;
      let user = this.user;
      let signature = this.signature;

      this.$store.dispatch(CONFIRM_CHANGE_USERNAME, {email, expires, user, signature})
        .then(() => {
          this.loading = false;
        })
        .catch((response) => {
            console.log("error response: ", response);
            if (response.data === "Link Expired") {
              this.errorResponse = "The link in this email has expired.";
            } else if (response.data.message === "validation.unique") {
              this.errorResponse = "Your login email was already changed to " + this.email + ".";
            } else {
              this.errorResponse = "An error occurred. Please try again in 5 minutes.";
            }
            this.loading = false;
            this.error = true;
          }
        )
      ;
    },
  },
  mounted() {
    this.confirmChangeUsername();
  }
  ,
}
;
</script>

