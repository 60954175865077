<template>
    <v-autocomplete
        id="school_id"
        v-model="localSchool"
        :background-color="backgroundColor"
        :error="localError"
        :error-messages="localErrors"
        :items="schoolList"
        :label="label"
        :loading="loading"
        filled
        item-text="name"
        item-value="id"
    >
        <template #item="{ item }">
            <v-list-item-content>
                <v-list-item-title :class="item.id == 9999 ? 'font-weight-bold' : ''">
                    {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import { GET_SCHOOL_DROPDOWN } from "../../store/actions.type";
import { SET_SCHOOL_DROPDOWN_IS_LOADING } from "../../store/mutations.type";

export default {
    name: "SchoolSelector",
    props: {
        backgroundColor: {
            default: "white",
            type: String,
        },
        label: {
            default: "School Recommendation",
            type: String,
        },
        generalFund: {
            default: "push",
            type: String,
            required: false,
        },
        value: {
            default: 0,
            type: Number,
            required: false,
        },
        error: {
            default: false,
            type: Boolean,
            required: false,
        },
        errorMessages: {
            default: () => [],
            type: Array,
            required: false,
        },
    },
    computed: {
        loading: {
            get() {
                return this.$store.getters.currentSchoolDropdownIsLoading;
            },
            set(value) {
                this.$store.commit(SET_SCHOOL_DROPDOWN_IS_LOADING, value);
            },
        },
        schoolList() {
            let schoolList = structuredClone(this.$store.getters.currentSchoolDropdown);

            if (this.generalFund === 'prepend') {
                schoolList = [
                    {
                        id: 9999,
                        name: "General Fund",
                    },
                    ...schoolList,
                ];
            } else {
                schoolList = [
                    ...schoolList,
                    {
                        id: 9999,
                        name: "General Fund",
                    },
                ];
            }

            return schoolList;
        },
        localError() {
            if (this.error) {
                return this.error;
            }
            return this.$store.getters.getMessage("schools");
        },
        localErrors() {
            if (this.errorMessages && this.errorMessages.length > 0) {
                return "Please choose a school";
            }
            return this.$store.getters.getErrors("schools");
        },
        localSchool: {
            get() {
                return this.value;
            },
            set(localSchool) {
                this.$emit("input", localSchool);
            },
        },
    },
    methods: {
        getSchoolDropdown() {
            if (!this.loading) {
                this.loading = true;
                let generalFund = this.generalFund;
                this.$store.dispatch(GET_SCHOOL_DROPDOWN).then(() => {
                    this.loading = false;
                });
            }
        },
    },
    mounted() {
        this.getSchoolDropdown();
        this.$emit("input", this.localSchool);
    },
};
</script>

<style scoped></style>

