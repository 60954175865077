<template>
    <loading v-if="loading" />
    <div
        v-else
        class="mr-5"
    >
        <v-row
            class="ml-1 mt-5"
            align="center"
        >
            <v-col
                lg="6"
                md="8"
            >
                <admin-header page-title="Households" />
            </v-col>
        </v-row>
        <v-spacer />
        <v-data-table
            :headers="headers"
            :items="households"
            :search="search"
            :single-select="true"
            item-key="id"
            @click:row="editHousehold"
            class="elevation-2 mt-3"
        >
            <template v-slot:top>
                <v-row>
                    <v-col
                        lg="6"
                        md="8"
                    >
                        <v-text-field
                            v-model="search"
                            class="mx-4"
                            label="Search on anything"
                            prepend-inner-icon="mdi-magnify"
                            outlined
                            background-color="white"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no households on file.
                </span>
            </template>
            <template v-slot:item.members="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="secondary"
                            @mouseover="getHouseholdMembers(item.id)"
                        >
                            mdi-account-group
                        </v-icon>
                    </template>
                    <v-card>
                        <v-card-title class="px-4">{{ item.name }} Household Members</v-card-title>
                        <v-card-text>
                            <loading
                                v-if="tooltipLoading"
                                indeterminate
                                color="primary"
                            />
                            <v-simple-table v-else>
                                <tr v-for="householdMember in householdMembers">
                                    <td>{{ householdMember.school_year_name }}</td>
                                    <td>{{ householdMember.type }}</td>
                                    <td>{{ householdMember.name }}</td>
                                </tr>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import { GET_ADMIN_HOUSEHOLDS, GET_ADMIN_HOUSEHOLD_MEMBERS } from "../../../store/actions.type";

export default {
    name: "AdminHouseholdsList",
    components: {
        AdminHeader,
        Loading
    },
    data() {
        return {
            defaultItem: {
                name: '',
                approved: '',
            },
            dialog: false,
            headers: [
                { text: 'Household Name', align: 'start', value: 'name' },
                { text: 'Primary Admin Name', align: 'start', value: 'primary_user_name' },
                { text: 'Primary Admin Email', align: 'start', value: 'primary_user_email' },
                { text: 'Members', value: 'members', align: 'center', sortable: false }
            ],
            loading: true,
            search: "",
            tooltipLoading: true,
        }
    },
    computed: {
        householdMembers() {
            return this.$store.getters.currentAdminHouseholdMembers;
        },
        households() {
            return this.$store.getters.currentHouseholds;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        editHousehold(item) {
            this.$router.push(`households/${item.id}`);
        },
        getHouseholds() {
            this.loading = true;
            this.$store.dispatch(GET_ADMIN_HOUSEHOLDS)
                .then(() => {
                    this.loading = false;
                });
        },
        getHouseholdMembers(id) {
            this.tooltipLoading = true;
            this.$store.dispatch(GET_ADMIN_HOUSEHOLD_MEMBERS, id)
                .then(() => {
                    this.tooltipLoading = false;
                });
        },

    },
    mounted() {
        this.getHouseholds();
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
