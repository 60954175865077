import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_HOUSEHOLD_APPLICATION,
    DELETE_HOUSEHOLD_APPLICATION_DOCUMENT,
    DELETE_HOUSEHOLD_APPLICATION_MEMBER,
    EMAIL_HOUSEHOLD_APPLICATION,
    GET_HOUSEHOLD_APPLICATION,
    GET_HOUSEHOLD_APPLICATION_DOCUMENTS,
    GET_HOUSEHOLD_APPLICATION_MEMBER,
    GET_HOUSEHOLD_APPLICATION_MEMBERS,
    GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS,
    GET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR,
    GET_HOUSEHOLD_APPLICATIONS,
    PATCH_HOUSEHOLD_APPLICATION,
    PATCH_HOUSEHOLD_APPLICATION_BROCHURE,
    PATCH_HOUSEHOLD_APPLICATION_CHECKBOX,
    PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_CHECKBOX,
    PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_COMMENTS,
    PATCH_HOUSEHOLD_APPLICATION_MEMBER,
    PATCH_HOUSEHOLD_APPLICATION_MEMBER_INCOME,
    POST_HOUSEHOLD_APPLICATION,
    POST_HOUSEHOLD_APPLICATION_MEMBER,
    POST_HOUSEHOLD_APPLICATION_MEMBER_DOCUMENT_UPLOAD,
    POST_HOUSEHOLD_APPLICATION_TAX_RETURN_UPLOAD
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    APPEND_HOUSEHOLD_APPLICATION_MEMBER,
    CLEAR_ERRORS,
    REMOVE_HOUSEHOLD_APPLICATION_DOCUMENT,
    REMOVE_HOUSEHOLD_APPLICATION_MEMBER,
    SET_ERROR,
    SET_HOUSEHOLD_APPLICATION,
    SET_HOUSEHOLD_APPLICATION_DOCUMENTS,
    SET_HOUSEHOLD_APPLICATION_HARDSHIP,
    SET_HOUSEHOLD_APPLICATION_MEMBER,
    SET_HOUSEHOLD_APPLICATION_MEMBERS,
    SET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS,
    SET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR,
    SET_HOUSEHOLD_APPLICATIONS
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        applications: [],
        application: {
            hardship: {},
            members: [],
            school_year_id: 0,
        },
        documents: [],
        householdApplicationSchoolYear: [],
        member_types: [
            {id: 1, value: 'Other'},
            {id: 2, value: 'Parent'},
            {id: 3, value: 'Student'},
        ],
        previous_award_types: [
            {id: 1, value: 'Disabled/Displaced Tax Credit Scholarship)'},
            {id: 2, value: 'Low-Income Corporate Tax Credit Scholarship'},
            {id: 3, value: 'Original Tax Credit Scholarship'},
            {id: 4, value: 'Switcher (Overflow/Plus Tax Credit Scholarship)'},
        ],
        status: [
            {id: 1, value: 'Being assembled by family'},
            {id: 2, value: 'In review by APSTO'},
            {id: 3, value: 'Approved by APSTO'},
        ],
        scholarships: []
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentApplications(state) {
        return state.applications;
    },
    currentApplication(state) {
        return state.application;
    },
    currentApplicationMemberById: (state) => (id) => {
        return state.application.members ? state.application.members
            .filter(member => member.id === id)[0] : null;
    },
    currentApplicationMembers(state) {
        return state.application.members ? state.application.members
            .sort((a, b) => (a.type > b.type) ? 1 : (a.type === b.type) ? ((a.first_name > b.first_name) ? 1 : -1) : -1) : [];
    },
    currentApplicationMembersFiltered: (state) => (type) => {
        return state.application.members ? state.application.members
            .filter(member => member.type === type)
            .sort((a, b) => (a.type > b.type) ? 1 : (a.type === b.type) ? ((a.first_name > b.first_name) ? 1 : -1) : -1) : [];
    },
    currentApplicationScholarships(state) {
        return state.scholarships;
    },
    currentApplicationDocuments(state) {
        return state.documents;
    },
    currentApplicationSchoolYear(state) {
        return state.householdApplicationSchoolYear;
    },
    status(state) {
        return state.status;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [EMAIL_HOUSEHOLD_APPLICATION](context, {household_id, application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/households/${household_id}/applications/${application_id}/email-pdf`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATIONS](context, household_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATIONS, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATION](context, {household_id, application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications/${application_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATION_DOCUMENTS](context, {household_id, application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications/${application_id}/documents`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_DOCUMENTS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATION_MEMBERS](context, {household_id, application_id}) {
        return new Promise((resolve, reject) => {
            // ApplicationMemberController::class, 'index']
            ApiService.get(`/api/households/${household_id}/applications/${application_id}/members`)
                .then(({data}) => {
                    console.log(data)
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_MEMBERS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS](context, {household_id, application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications/${application_id}/scholarships`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR](context, household_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications/application/create`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_HOUSEHOLD_APPLICATION](context, application) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/households/${application.household_id}/applications`, application)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [PATCH_HOUSEHOLD_APPLICATION_BROCHURE](context, {household_id, application_id, application}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/applications/${application_id}/brochure`, application)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_CHECKBOX](context, {household_id, application_id, field_name}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/applications/${application_id}/hardship/checkbox/${field_name}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_HARDSHIP, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_COMMENTS](context, {household_id, application}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/applications/${application.id}/hardship/comments`, application)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_HARDSHIP, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [DELETE_HOUSEHOLD_APPLICATION](context, {household_id, application_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/households/${household_id}/applications/${application_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [GET_HOUSEHOLD_APPLICATION_MEMBER](context, {household_id, application_id, application_member_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications/${application_id}/members/${application_member_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_MEMBER, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_HOUSEHOLD_APPLICATION_MEMBER](context, {household_id, application_id, applicationMember}) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/households/${household_id}/applications/${application_id}/members`, applicationMember)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_HOUSEHOLD_APPLICATION_MEMBER, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_HOUSEHOLD_APPLICATION_MEMBER](context, {household_id, application_id, applicationMember}) {
        let member_id = applicationMember.id;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/applications/${application_id}/members/${member_id}`, applicationMember)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_MEMBER, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },

    [PATCH_HOUSEHOLD_APPLICATION_MEMBER_INCOME](context, {household_id, application_id, applicationMember}) {
        let member_id = applicationMember.id;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/applications/${application_id}/members/${member_id}/income`, applicationMember)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_MEMBER, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },

    [DELETE_HOUSEHOLD_APPLICATION_DOCUMENT](context, {household_id, application_id, document_id, id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/households/${household_id}/applications/${application_id}/documents/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_HOUSEHOLD_APPLICATION_DOCUMENT, document_id);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_HOUSEHOLD_APPLICATION_MEMBER](context, {household_id, application_id, item_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/households/${household_id}/applications/${application_id}/members/${item_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_HOUSEHOLD_APPLICATION_MEMBER, item_id);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_HOUSEHOLD_APPLICATION_MEMBER_DOCUMENT_UPLOAD](context, {
        household_id,
        application_id,
        application_member_id,
        document_id,
        file
    }) {
        const formData = new FormData()
        formData.append('application_member_id', application_member_id);
        formData.append('document_id', document_id);
        formData.append('file', file);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/households/${household_id}/applications/${application_id}/members/${application_member_id}/files`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "application",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_HOUSEHOLD_APPLICATION_CHECKBOX](context, {household_id, household_application_id, field_name}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/applications/${household_application_id}/checkbox/${field_name}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
    [POST_HOUSEHOLD_APPLICATION_TAX_RETURN_UPLOAD](context, {
        household_id,
        application_id,
        file
    }) {

        const formData = new FormData()
        formData.append('file', file);
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/households/${household_id}/applications/${application_id}/files`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'application');
                    reject(error.response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_HOUSEHOLD_APPLICATIONS](state, data) {
        state.applications = data;
    },
    [SET_HOUSEHOLD_APPLICATION](state, data) {
        state.application = data;
    },
    [SET_HOUSEHOLD_APPLICATION_DOCUMENTS](state, data) {
        state.documents = data;
    },
    [SET_HOUSEHOLD_APPLICATION_HARDSHIP](state, data) {
        state.application.hardship = data;
    },
    [SET_HOUSEHOLD_APPLICATION_MEMBERS](state, data) {
        state.application.members = data;
    },
    [SET_HOUSEHOLD_APPLICATION_MEMBER](state, data) {
        let members = state.application.members;
        for (let i = 0; i < members.length; i++) {
            if (members[i].id === data.id) {
                members.splice(i, 1, data);
                return;
            }
        }
        members.push(data);
    },
    [APPEND_HOUSEHOLD_APPLICATION_MEMBER](state, data) {
        state.application.members.push(data);
    },
    [SET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS](state, data) {
        state.scholarships = data;
    },
    [SET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR](state, data) {
        state.householdApplicationSchoolYear = data;
    },
    [REMOVE_HOUSEHOLD_APPLICATION_DOCUMENT](state, id) {
        let list = state.documents;
        for (let i = 0; i < list.length; i++) {
            if (list[i].document_id === id) {
                list[i].document_submitted = null;
            }
        }
    },
    [REMOVE_HOUSEHOLD_APPLICATION_MEMBER](state, id) {
        let list = state.application.members;
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === id) {
                list.splice(i, 1);
            }
        }
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
