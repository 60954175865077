<template>
  <v-sheet class="pa-5">
    <loading v-if="loading"/>
    <div v-else>
      <v-form :disabled="saving">
        <v-row class="ml-1 mt-n7">
          <v-col
            class="pa-0"
            cols="auto"
          >
            <h2>Make A Donation With {{ householdName }} </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="mt-n4 pl-4 pb-5"
            md="6"
          >
            <v-radio-group
              v-model="donation.tax_year"
              :error="!!errors['donation.tax_year']"
              :error-messages="errors['donation.tax_year'] ? errors['donation.tax_year'] : null"
              class="bottom-border"
              column
              @change="clearPriorDonations()"
            >
              <template v-slot:label>
                <div
                  class="pb-2 font-weight-black"
                  style="font-size: 1.125rem; line-height: 1.5"
                >
                  For Tax Year
                </div>
              </template>
              <v-radio
                v-if="showPriorYearButton"
                :disabled="taxYearFactors.prior_tax_year_is_disabled"
                :label="priorYearLabel"
                color="secondary"
                value="prior"
              />
              <v-radio
                :label="taxYearFactors.current_tax_year.name.toString()"
                color="secondary"
                value="current"
              />
              <v-radio
                v-if="taxYearFactors.next_year_found_in_db"
                :label="taxYearFactors.next_tax_year.name.toString()"
                color="secondary"
                value="next"
              />
            </v-radio-group>

            <filing-status
              v-model="donation.tax_filing_status"
              :selected-tax-year="selectedTaxYear"
              class="bottom-border"
            />

            <prior-donations
              ref="priorDonations"
              :selected-tax-year="selectedTaxYear"
              class="bottom-border"
              email=""
            />

            <div
              class="pb-4 mt-4 font-weight-black theme--light v-label"
              style="font-size: 1.125rem"
            >
              Donation Amount <span class="red--text">*</span>
            </div>

            <v-currency-field
              id="amount"
              v-model.number="donation.amount"
              :error="!!errors['amount']"
              :error-messages="errors['amount'] ? errors['amount'] : null"
              filled
              type="number"
            />

            <donation-amount-explainer
              :explainer="explainer"
              class="mt-n2 pa-4"
              style="border: 1px dashed var(--apsto-teal);"
            />

            <recommendations-page
              v-model="donation.recommendations.original"
              class="bottom-border"
              tax-credit-type="original"
            />

            <recommendations-page
              v-model="donation.recommendations.switcher"
              class="bottom-border"
              tax-credit-type="switcher"
            />
            <div
              v-if="hidePaymentSection"
              class="box-with-background mt-5"
            >
              This is where the payments section is displayed on a working donation page. It's hidden on this
              donation
              page because you linked to this page from the King family donation page which is a fictitious
              family that
              we use as a sample to show you how our custom resources pages work.
            </div>
            <div v-else>
              <!-- Payment-->
              <h2 class="my-4 text-apsto-teal">Payment</h2>
              <div class="mt-n6 text-h6-mulish font-weight-bold">
                Amount: {{ donation.amount | formatCurrency }}
              </div>
              <donation-payment-by-card
                :called-from-donation-page="true"
                :donation="donation"
                :email="email"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import Loading from "../../../components/shared/Loading";

import {
  DELETE_DONOR_PRIOR_DONATIONS,
  GET_APPLICATION_PROMO,
  GET_DONOR_DONATION_TAX_YEAR_FACTORS,
  GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES,
} from "../../../store/actions.type";

import SchoolSelector from "../../../components/shared/SchoolSelector";
import FilingStatus from "../../../components/donations/FilingStatus";
import DonationPaymentByCard from "../../../components/donations/DonationPaymentByCard";
import PriorDonations from "../../../components/donations/PriorDonations";
import RecommendationsPage from "../../../components/donations/RecommendationsPage";
import {SET_DONATION} from "../../../store/mutations.type";
import DonationAmountExplainer from "../../../components/donations/DonationAmountExplainer";

export default {
  name: "DonationPageCreate",
  components: {
    Loading,
    DonationAmountExplainer,
    FilingStatus,
    DonationPaymentByCard,
    PriorDonations,
    RecommendationsPage,
    SchoolSelector,
  },
  data() {
    return {
      loading: true,
      saving: false,
    };
  },
  computed: {
    donation: {
      get() {
        return this.$store.getters.currentDonation;
      },
      set(donation) {
        this.$store.commit(SET_DONATION, donation);
      },
    },
    email() {
      let user = this.$store.getters.currentUser;
      return user.email;
    },
    explainer() {
      return {
        maxOriginal: this.maxOriginal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        maxSwitcher: this.maxSwitcher(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        maxTotal: this.maxTotal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        selectedTaxYear: this.selectedTaxYear,
        subscriptionChargeDay: 0,
        taxFilingStatus: this.donation.tax_filing_status,
        totalPriorDonations: this.totalPriorDonations,
      }
    },
    applicationPromo() {
      return this.$store.getters.currentPromo;
    },
    hidePaymentSection() {
      return this.applicationPromo.id === 4 || this.applicationPromo.id === 5;
    },
    householdName() {
      if (this.applicationPromo.application_promoable_type === "Application") {
        let name = this.applicationPromo.application_promoable.household_name;
        return "The " + name + " Family";
      }
      return this.applicationPromo.application_promoable.name;
    },
    priorYearLabel() {
      return this.taxYearFactors.prior_tax_year_caption ? this.taxYearFactors.prior_tax_year.name.toString() + this.taxYearFactors.prior_tax_year_caption : this.taxYearFactors.prior_tax_year.name.toString();
    },
    selectedTaxYear() {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      return parseInt(this.taxYearFactors && this.taxYearFactors[tax_year_key].name)
    },
    showPriorYearButton() {
      return this.taxYearFactors && !this.taxYearFactors.prior_tax_year_is_disabled && !this.taxYearFactors.next_year_found_in_db;
    },
    taxYearFactors() {
      return this.$store.getters.currentTaxYearFactors;
    },
    totalPriorDonations() {
      let priorDonationsArray = this.$store.getters.currentDonorPriorDonations(this.selectedTaxYear)
      let total = 0;
      for (let i = 0; i < priorDonationsArray.length; i++) {
        let amount = parseFloat(priorDonationsArray[i].amount);
        total = total + amount;
      }
      return parseInt(total);
    },
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
  },
  methods: {
    clearPriorDonations() {
      switch (this.donation.tax_year) {
        case "current":
          this.$store.dispatch(DELETE_DONOR_PRIOR_DONATIONS, this.taxYearFactors.prior_tax_year.name)
            .then(() => {
              if (this.taxYearFactors.next_tax_year) {
                this.$store.dispatch(DELETE_DONOR_PRIOR_DONATIONS, this.taxYearFactors.next_tax_year.name)
              }
            });
          break;
        case "next":
          this.$store.dispatch(DELETE_DONOR_PRIOR_DONATIONS, this.taxYearFactors.current_tax_year.name)
            .then(() => {
              this.$store.dispatch(DELETE_DONOR_PRIOR_DONATIONS, this.taxYearFactors.prior_tax_year.name)
            });
          break;
        case "prior":
          this.$store.dispatch(DELETE_DONOR_PRIOR_DONATIONS, this.taxYearFactors.current_tax_year.name)
            .then(() => {
              if (this.taxYearFactors.next_tax_year) {
                this.$store.dispatch(DELETE_DONOR_PRIOR_DONATIONS, this.taxYearFactors.next_tax_year.name)
              }
            });
          break;
      }
      this.$refs.priorDonations.getDonorPriorDonations();
    },
    maxOriginal(taxFilingStatus) {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      let tax_filing_status_key = "max_original_" + taxFilingStatus;
      let max_original = this.taxYearFactors[tax_year_key][tax_filing_status_key];
      return parseInt(max_original);
    },
    maxSwitcher(taxFilingStatus) {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      let tax_filing_status_key = "max_switcher_" + taxFilingStatus;
      let max_switcher = this.taxYearFactors[tax_year_key][tax_filing_status_key];
      return parseInt(max_switcher);
    },
    maxTotal(taxFilingStatus) {
      return this.maxOriginal(taxFilingStatus) + this.maxSwitcher(taxFilingStatus);
    },
  },
  mounted() {
    this.loading = true;
    let promo_id = this.$route.params.promo_id;
    this.$store.dispatch(GET_APPLICATION_PROMO, promo_id).then(() => {
      this.$store.dispatch(GET_DONOR_DONATION_TAX_YEAR_FACTORS).then(() => {
        //Set donation to a blank version
        this.$nextTick(() => {
          this.$store.commit(SET_DONATION, {
            agree_to_terms: false,
            amount: 0,
            priorDonations: [],
            recommendations: {
              original: {
                comments: null,
                school_id: null,
                students: [],
              },
              switcher: {
                comments: null,
                school_id: null,
                students: [],
              },
            },
            tax_filing_status: "",
            tax_year: this.applicableTaxYearAsString(),
          });
        });
        this.$store.dispatch(GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES, promo_id).then(() => {
          this.loading = false;
        });
      });
    });
  },
};
</script>

<style scoped>
.bottom-border {
  border-bottom: 1px dotted;
}

::v-deep .v-toolbar__content {
  background-color: var(--apsto-purple-very-light);
}

::v-deep .v-label {
  color: black;
}</style>
