<template>
  <div>
    <v-row
      v-for="content in currentPage.contents"
      v-bind:key="content.id"
      no-gutters
    >
      <v-col>
        <component :is="content.component.component" :content="content"/>
      </v-col>
    </v-row>
    <v-row v-if="editMode" no-gutters>
        <v-col>
          <add-component
            :parent-id="currentPage.id"
            parent-type="WebsitePage"
            :parent-component="5"
          />
        </v-col>
      </v-row>
  </div>
</template>

<script>
import Hero from "../components/Basic/Hero";
import Title from "../components/Basic/Title";
import CalloutImages from "../components/Basic/CalloutImages"
import Mission from "../components/Basic/Mission";
import Banner from "../components/Basic/Banner";

import AddComponent from "../menus/AddComponent";

export default {
  name: "Basic",
  components: {
    Hero,
    Title,
    CalloutImages,
    Mission,
    Banner,
    AddComponent
  },
  computed: {
    editMode() {
      return this.$store.getters.currentEditMode;
    },
    currentPage() {
      return this.$store.getters.currentPage(this.$route.params.slug);
    },
  }
};

</script>
