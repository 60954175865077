<template>
    <div style="font-size: 1.125rem;">
        <v-row class="ml-0">
            <v-col
                cols="12"
                class="px-0"
            >
                <div>
                    Consistent with updates to A.R.S. 43-1603 in April 2015, all STO's must include the percentage and
                    dollar
                    amount of scholarships given to students whose household income meets 185% and 342.25% of poverty level
                    in the
                    previous fiscal year for each type of scholarship.
                </div>
                <div class="font-weight-black mt-4">
                    {{ fiscalYearName }} Fiscal Year
                </div>
            </v-col>
        </v-row>
        <v-row v-if="awards.disabled_level_1 > 0 || awards.disabled_level_2 > 0">
            <v-col>
                <h2 style="color: var(--apsto-teal);">
                    Disabled / Displaced (Lexie's Law) Tax Credit
                </h2>
                <v-row>
                    <v-col class="box">
                        <div class="header"> STUDENTS WHOSE HOUSEHOLD INCOME IS UP TO 185% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards:
                                <span class="numeric">
                                    {{ awards.disabled_level_1 * 100 / totalAwards | formatNumberWithCents }}%</span>
                            </li>
                            <li>
                                Award Amount <span class="numeric"> {{ awards.disabled_level_1 | formatCurrency }}</span>
                            </li>
                        </ul>
                    </v-col>
                    <v-col class="box">
                        <div class="header">STUDENTS WHOSE HOUSEHOLD INCOME IS 185% TO 342.25% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards: <span class="numeric"> {{
                                    awards.disabled_level_2 * 100 / totalAwards | formatNumberWithCents
                                }}%</span>
                            </li>
                            <li>
                                Award Amount <span class="numeric"> {{
                                    awards.disabled_level_2 | formatCurrency
                                }}</span>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="awards.low_income_level_1 > 0 || awards.low_income_level_2 > 0">
            <v-col>
                <h2 style="color: var(--apsto-teal);">
                    Low-Income Corporate Tuition Tax Credit Program:
                </h2>
                <v-row>
                    <v-col class="box">
                        <div class="header"> STUDENTS WHOSE HOUSEHOLD INCOME IS UP TO 185% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards:
                                <span class="numeric">
                                    {{
                                        awards.low_income_level_1 * 100 / totalAwards | formatNumberWithCents
                                    }}%
                                </span>
                            </li>
                            <li>
                                Award Amount
                                <span class="numeric"> {{
                                    awards.low_income_level_1 | formatCurrency
                                }}</span>
                            </li>
                        </ul>
                    </v-col>
                    <v-col class="box">
                        <div class="header">STUDENTS WHOSE HOUSEHOLD INCOME IS 185% TO 342.25% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards: <span class="numeric"> {{
                                    awards.low_income_level_2 * 100 / totalAwards | formatNumberWithCents
                                }}%</span>
                            </li>
                            <li>
                                Award Amount <span class="numeric"> {{
                                    awards.low_income_level_2 | formatCurrency
                                }}</span>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="awards.original_level_1 > 0 || awards.original_level_2 > 0">
            <v-col>
                <h2 style="color: var(--apsto-teal);">
                    Original Tuition Tax Credit Program
                </h2>
                <v-row>
                    <v-col class="box">
                        <div class="header"> STUDENTS WHOSE HOUSEHOLD INCOME IS UP TO 185% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards: <span class="numeric"> {{
                                    awards.original_level_1 * 100 / totalAwards | formatNumberWithCents
                                }}%</span>
                            </li>
                            <li>
                                Award Amount <span class="numeric"> {{
                                    awards.original_level_1 | formatCurrency
                                }}</span>
                            </li>
                        </ul>
                    </v-col>
                    <v-col class="box">
                        <div class="header">STUDENTS WHOSE HOUSEHOLD INCOME IS 185% TO 342.25% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards: <span class="numeric"> {{
                                    awards.original_level_2 * 100 / totalAwards | formatNumberWithCents
                                }}%</span>
                            </li>
                            <li>
                                Award Amount <span class="numeric"> {{
                                    awards.original_level_2 | formatCurrency
                                }}</span>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="awards.switcher_level_1 > 0 || awards.switcher_level_2 > 0">
            <v-col>
                <h2 style="color: var(--apsto-teal);">
                    Switcher (Overflow/PLUS) Tuition Tax Credit Program:
                </h2>
                <v-row>
                    <v-col class="box">
                        <div class="header"> STUDENTS WHOSE HOUSEHOLD INCOME IS UP TO 185% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards: <span class="numeric"> {{
                                    awards.switcher_level_1 * 100 / totalAwards | formatNumberWithCents
                                }}%</span>
                            </li>
                            <li>
                                Award Amount <span class="numeric"> {{
                                    awards.switcher_level_1 | formatCurrency
                                }}</span>
                            </li>
                        </ul>
                    </v-col>
                    <v-col class="box">
                        <div class="header">STUDENTS WHOSE HOUSEHOLD INCOME IS 185% TO 342.25% OF POVERTY LEVEL</div>
                        <ul>
                            <li>
                                Percentage of Total Tuition Awards: <span class="numeric"> {{
                                    awards.switcher_level_2 * 100 / totalAwards | formatNumberWithCents
                                }}%</span>
                            </li>
                            <li>
                                Award Amount <span class="numeric"> {{
                                    awards.switcher_level_2 | formatCurrency
                                }}</span>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ApiService from '../../../../api/api.service';

export default {
    name: "StudentAwardsDistribution",
    props: {
        content: {
            type: Object,
        },
    },
    data() {
        return {
            awards: {
                disabled_level_1: 0,
                low_income_level_1: 0,
                original_level_1: 0,
                switcher_level_1: 0,
                disabled_level_2: 0,
                low_income_level_2: 0,
                original_level_2: 0,
                switcher_level_2: 0
            },
            fiscalYearName: '',
        };
    },
    computed: {
        totalAwards() {
            return parseFloat(this.awards.disabled_total) + parseFloat(this.awards.low_income_total) + parseFloat(this.awards.original_total) + parseFloat(this.awards.switcher_total);
        },
    },
    mounted() {
        ApiService.get('/api/website/data/student-awards-distribution')
            .then((response) => {
                this.awards = response.data['awards'];
                this.fiscalYearName = response.data['fiscalYearName'];
            })
            .catch((e) => {
                console.log("STUDENT AWARDS DISTRIBUTION ERROR");
            });
    },
};
</script>

<style scoped>
.box {
    border: 1px solid var(--apsto-border);
    padding: 30px 20px;
    margin: 25px 20px 10px 15px;
}

.header {
    font-size: .95rem;
    margin-bottom: 20px;
}

.numeric {
    color: var(--apsto-purple);
    font-weight: bold;
}</style>
