<template>
    <div>
        <v-card class="pa-5">
            <v-card-title>
                {{ editing ? 'Edit Deposit' : 'Add Deposit' }}
            </v-card-title>
            <v-card-text>
                <v-form :disabled="saving">
                    <admin-deposit-form class="mt-0" />
                </v-form>
            </v-card-text>
            <v-card-actions
                class="mt-n6"
                :class="editing ? 'pr-4' : ''"
            >
                <v-btn
                    class="cancel-button ml-2"
                    :disabled="disabled"
                    @click="cancel"
                >
                    Cancel
                </v-btn>
                <v-btn
                    :disabled="disabled"
                    color="primary"
                    @click="save"
                >
                    {{ this.creating ? 'Save and add refunds' : 'Save' }}
                </v-btn>
                <template v-if="editing">
                    <v-spacer />
                    <v-btn
                        :disabled="disabledDelete"
                        color="error"
                        @click="delRecord"
                    >
                        Delete
                    </v-btn>
                </template>
            </v-card-actions>
        </v-card>
        <confirmation-dialog ref="confirm" />
    </div>
</template>
  
<script>
import AdminDepositForm from "./AdminDepositForm";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import { POST_ADMIN_DEPOSIT, PATCH_ADMIN_DEPOSIT, DELETE_ADMIN_DEPOSIT } from "../../../store/actions.type";
import { SET_ADMIN_DEPOSIT } from "../../../store/mutations.type";

export default {
    name: "AdminDeposit",
    components: {
        AdminDepositForm,
        ConfirmationDialog,
    },
    data() {
        return {
            saving: false,
        }
    },
    computed: {
        deposit: {
            get() {
                return this.$store.getters.currentAdminDeposit;
            },
            set(newValue) {
                this.$store.commit(SET_ADMIN_DEPOSIT, newValue);
            },
        },
        awardRefunds() {
            return this.$store.getters.currentAdminDepositAwardRefunds;
        },
        awardRefundsTotal() {
            return this.awardRefunds.reduce((total, refund) => {
                return total + refund.amount;
            }, 0);
        },
        refundsToDepositRemaining() {
            return this.deposit.amount - this.awardRefundsTotal;
        },
        depositNotFullyApplied() {
            return this.refundsToDepositRemaining > 0;
        },
        disabled() {
            return this.editing && this.depositNotFullyApplied;
        },
        mode() {
            return this.deposit.id ? 'edit' : 'create';
        },
        editing() {
            return this.mode == 'edit';
        },
        creating() {
            return this.mode == 'create';
        },
        disabledDelete() {
            return this.saving || this.deposit.deleted_at;
        }
    },
    methods: {
        cancel() {
            this.$emit('cancelled');
        },
        save() {
            this.saving = true;
            let currentlyEditing = this.editing.valueOf();

            const action = this.creating ? POST_ADMIN_DEPOSIT : PATCH_ADMIN_DEPOSIT;
            this.$store.dispatch(action, this.deposit)
                .then(() => {
                    this.saving = false;
                    if (currentlyEditing) {
                        //Only close on edits, so refunds can be added right away.
                        this.$emit('done');
                    }
                })
                .catch(() => {
                    this.saving = false;
                });
        },
        async delRecord() {
            if (
                await this.$refs.confirm.open(
                    "Confirm",
                    "Are you sure you want to delete this deposit?"
                )
            ) {
                this.destroy();
            }
        },
        destroy() {
            this.saving = true;

            this.$store.dispatch(DELETE_ADMIN_DEPOSIT, this.deposit)
                .then(() => {
                    this.$emit('done');
                    this.$nextTick(() => {
                        this.saving = false;
                    });
                })
                .catch(() => {
                    this.saving = false;
                });
        },
    }
}
</script>
  
  
<style scoped></style>
  