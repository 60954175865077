import {SET_ERROR} from "./store/mutations.type";
import router from "./router";

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.withCredentials = true;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


if (!process.env.MIX_APP_DEBUG === "true") {
    if (!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function () {
        };
    }
}

window.searchArrayByKey = function (key, list, field = 'id', seeking = null) {
    for (var i = 0; i < list.length; i++) {
        if (list[i][field] == key) {
            if (seeking == 'object') {
                return list[i];
            } else if (seeking == 'name') {
                return list[i].name;
            } else {
                return i;
            }

        }
    }
    return false;
}

window.upsert = function (array, item) {
    const i = array.findIndex(_item => _item.id === item.id);
    if (i > -1) array.splice(i, 1);
    array.push(item);
}

window.upshift = function (array, item) {
    const i = array.findIndex(_item => _item.id === item.id);
    if (i > -1) array.splice(i, 1);
    array.unshift(item);
}

window.upreplace = function (array, item) {
    const i = array.findIndex(_item => _item.id === item.id);
    if (i > -1) array.splice(i, 1, item);
}

window.handleError = function (context, error, target) {
    const response = error.response;
    console.log("handle error: ", response.status)
    switch (response.status) {
        case 402:
            router.push('/requires-primary-admin');
            return;
        case 403:
            router.push('/denied-access');
            return;
        case 404:
            router.push('/not-found');
            return;
    }
    context.commit(SET_ERROR, {
        target: target,
        message: response.data.message,
        errors: response.data.errors ?? [],
    });
}