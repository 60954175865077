<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row>
      <v-col>
        <admin-company-header page-title="Edit Company"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-n5">
        <v-card class="pa-5">
          <v-row v-if="company.employee_donations">
            <v-col>
              <v-btn
                color="primary"
                rounded
                @click="getEmployeeDonations"
              >
                Go To Payroll Withholding
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <admin-company-info :company="company"/>
              <admin-administrators
                class="mt-5"
                entity-type="company"
              />
              <v-radio-group
                v-model="company.employee_donations"
                :error="!!errors.employee_donations"
                :error-messages="errors.employee_donations ? errors.employee_donations : null"
                class="bottom-border"
                column
                @change="updateCompanyEmployeeDonationsParticipation()"
              >
                <template v-slot:label>
                  <div
                    class="pb-2 font-weight-black"
                    style="font-size: 1.125rem; line-height: 1.5"
                  >
                    Payroll Withholding Participation
                  </div>
                </template>
                <v-radio
                  :value="1"
                  color="secondary"
                  label="On"
                />
                <v-radio
                  :value="0"
                  color="secondary"
                  label="Off"
                />
              </v-radio-group>

              <div class="text-h6-mulish mt-2">Payroll Withholding Frequency</div>
              <div
                v-if="noticeToDefer"
                class="notification-box"
              >
                {{ noticeToDefer }}
              </div>
              <div class="mt-2">
                <period-selector
                  v-model="company.employee_donations_frequency"
                  :error="!!errors.employee_donations_frequency"
                  :error-messages="errors.employee_donations_frequency ? errors.employee_donations_frequency : null"
                  :frequencies="frequencies"
                  :value="4"
                  @input="updateCompanyEmployeeDonationsFrequency"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminAdministrators from "../main/AdminAdministrators";
import AdminCompanyInfo from "./AdminCompanyInfo";
import ConfirmationDialog from "../../../components/shared/ConfirmationDialog";
import Loading from "../../../components/shared/Loading";
import PeriodSelector from "../../../components/shared/PeriodSelector";

import {
  GET_COMPANY,
  PATCH_COMPANY_EMPLOYEE_DONATIONS_FREQUENCY,
  PATCH_COMPANY_EMPLOYEE_DONATIONS_PARTICIPATION
} from "../../../store/actions.type";
import AdminCompanyHeader from "../main/AdminCompanyHeader.vue";

export default {
  name: "AdminCompanyEdit",
  components: {
    AdminAdministrators,
    AdminCompanyHeader,
    AdminCompanyInfo,
    ConfirmationDialog,
    Loading,
    PeriodSelector
  },
  data() {
    return {
      frequencies: [
        {name: "Monthly", id: 3},
        {name: "Quarterly", id: 4},
      ],
      loading: true,
      noticeToDefer: null,
      saving: false,
      snackbar: false,
      snackbarMessage: "",

    }
  },
  computed: {
    company() {
      return this.$store.getters.currentCompany;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getCompany() {
      this.loading = true;
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_COMPANY, company_id)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log("Company ERROR:", e);
        });
    },
    getEmployeeDonations() {
      this.$router.push(`employee-donations`);
    },
    initialize() {
      this.company = [];
    },
    updateCompanyEmployeeDonationsFrequency() {
      let company = this.company;
      if (company.id) {
        this.$store.dispatch(PATCH_COMPANY_EMPLOYEE_DONATIONS_FREQUENCY, company)
          .then(() => {
          })
          .catch((response) => {
            this.noticeToDefer = response.data.message;
            this.saving = false;
          });
      }
    },
    updateCompanyEmployeeDonationsParticipation() {
      let company = this.company;
      this.$store.dispatch(PATCH_COMPANY_EMPLOYEE_DONATIONS_PARTICIPATION, company)
        .then(() => {
        })
        .catch(() => {
          this.saving = false;
        });
    }
  },
  mounted() {
    this.getCompany();
  },
}
</script>

<style scoped>
a {
  color: var(--apsto-teal) !important;
}

.box-with-background {
  min-height: 225px;
}

.left-column {
  width: 215px;
}

.notification-box {
  background-color: red;
  color: white;
  font-weight: bold;
  margin-top: 10px;
  padding: 20px;
}
</style>
