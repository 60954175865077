<template>
  <v-row no-gutters>
    <v-col class="text-center">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" style="top: 18px" v-bind="attrs" v-on="on">
            <v-icon>mdi-plus</v-icon>
            {{buttonText}}
          </v-btn>
          <v-divider inset color="success" />
        </template>
        <v-list v-if="!components.length">
          <v-list-item>
            <loading />
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item
            v-for="(component, index) in components"
            :key="index"
            @click="addContent(component)"
          >
            <v-list-item-title>{{ component.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { GET_COMPONENTS, POST_CONTENT, GET_CONTENTS } from "../../../store/actions.type";
import Loading from "../../shared/Loading";

export default {
  name: "AddComponent",
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    parentId: {
      type:Number,
      required: true
    },
    parentType: {
      type:String,
      required: true
    },
    parentComponent: {
      type: Number,
      required: true
    },
    parentSortOrder: {
      type:Number,
      default: 1
    },
    buttonText: {
      type:String,
      default: "Add Component"
    }
  },
  computed: {
    components() {
      let parentType = this.parentType;
      let parentComponent = this.parentComponent;
      return this.$store.getters.currentComponents({parentType,parentComponent});
    },
    
  },
  methods: {
    getComponents() {
      this.loading = true;
      this.$store
        .dispatch(GET_COMPONENTS)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    addContent(component) {

      this.loading = true;

      let parent_id = this.parentId;
      let parent_type = this.parentType;
      let website_component_id = component.id;
      let parent_sort_order = this.parentSortOrder;

      let data = {
        parent_id,
        parent_type,
        website_component_id,
        parent_sort_order
      };

      this.$store
        .dispatch(POST_CONTENT, data)
        .then(() => {
          this.$store
            .dispatch(GET_CONTENTS)
            .then(() => {
              this.loading = false;
            })
            .catch((e) => {
              //Todo: Global snackbar error
              this.loading = false;
            });
        })
        .catch((e) => {
          //Todo: Global snackbar error
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getComponents();
  },
};
</script>