<template>
  <v-card>
    <v-card-title>
            <span class="text-h5 pb-4">
                {{ this.editedItem.id ? "Edit Student" : "Add Student" }}
            </span>
    </v-card-title>
    <v-card-text style="
        background-color: var(--apsto-teal-light);
        padding-left: 22px;
        padding-right: 22px;
      ">
      <v-row>
        <v-col
          class="mt-2"
          cols="12"
        >
          <v-card class="pa-4">
            <v-text-field
              id="name"
              v-model.trim="editedItem.name"
              :error="!!errors['name']"
              :error-messages="errors['name'] ? errors['name'] : null"
              label="Student Name"
            />
            <school-selector :key="'recommendationsStudent'" v-model="editedItem.school_id"/>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-4">
      <v-spacer/>
      <v-btn
        class="cancel-button"
        rounded
        @click="$emit('done')"
      > Cancel
      </v-btn>
      <v-btn
        :disabled="disableSubmitButton"
        class="save-by-cancel-button"
        color="primary"
        rounded
        @click="save"
      > Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SchoolSelector from "../../components/shared/SchoolSelector";
import {
  APPEND_DONOR_STUDENT_RECOMMENDATION,
  INCREMENT_RECOMMENDATION_STUDENT_ID,
  UPDATE_DONOR_STUDENT_RECOMMENDATION
} from '../../store/mutations.type';
import {GET_SCHOOL_FOR_RECOMMENDATION} from "../../store/actions.type";

export default {
  name: "RecommendationStudent",
  components: {
    SchoolSelector,
  },
  data: () => ({
    saving: false,
    school_name: "",
  }),
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    taxCreditType: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentRecommendationsCount() {
      return this.$store.getters.currentRecommendationsCount;
    },
    disableSubmitButton() {
      return !this.editedItem.name || !this.editedItem.school_id;
    },
    donorRecommendations() {
      return this.$store.getters.currentDonorRecommendationStudents(this.taxCreditType);
    },
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
  },
  methods: {
    save() {
      this.saving = true;
      let student = this.editedItem;
      this.$store.commit(INCREMENT_RECOMMENDATION_STUDENT_ID);
      student.id = student.id ? student.id : this.currentRecommendationsCount;
      let taxCreditType = this.taxCreditType;
      this.$store.dispatch(GET_SCHOOL_FOR_RECOMMENDATION, this.editedItem.school_id)
        .then((data) => {
          student.school_name = data.name;
          this.$store.commit(
            student.id === this.currentRecommendationsCount
              ? APPEND_DONOR_STUDENT_RECOMMENDATION
              : UPDATE_DONOR_STUDENT_RECOMMENDATION,
            {taxCreditType, student}
          )
          this.saving = false;
          this.$emit("done");
        });
    },
  },
};
</script>

<style scoped>.errors-message {
  color: red;
  padding-left: 5px;
}</style>
