import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_COMPANY_DAILY_DONATION,
    DELETE_COMPANY_DAILY_DONATION_PAYMENT,
    GET_ANNUAL_EMPLOYEE_DONATIONS,
    GET_COMPANY_PAYROLL_BATCHES_BY_YEAR,
    GET_EMPLOYEE_DONATION,
    GET_EMPLOYEE_DONATION_EMPLOYEES,
    GET_EMPLOYEE_DONATIONS,
    GET_EMPLOYEES_FORM_A4C,
    GET_NEXT_COMPANY_PAYROLL_BATCH,
    PATCH_COMPANY_DAILY_DONATION_PAYMENT,
    POST_COMPANY_DAILY_DONATION_CARD_PAYMENT,
    POST_COMPANY_DAILY_DONATION_PAYMENT,
    POST_EMPLOYEE_DONATION,
    POST_EMPLOYEE_DONATIONS_FILE_UPLOAD
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    APPEND_COMPANY_DAILY_DONATION_PAYMENT,
    CLEAR_ERRORS,
    CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE,
    REMOVE_COMPANY_DAILY_DONATION_PAYMENT,
    SET_ANNUAL_EMPLOYEE_DONATIONS,
    SET_COMPANY_PAYROLL_BATCHES_BY_YEAR,
    SET_EMPLOYEE_DONATION,
    SET_EMPLOYEE_DONATION_EMPLOYEES,
    SET_EMPLOYEE_DONATIONS,
    SET_EMPLOYEES_FORM_A4C,
    SET_ERROR,
    SET_NEXT_COMPANY_PAYROLL_BATCH,
    SET_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE,
    SET_PAYROLL_WITHHOLDING_UPLOAD_SELECTIONS,
    SET_UPLOADED_EMPLOYEE_DONATIONS_FILE,
    UPDATE_COMPANY_DAILY_DONATION_PAYMENT,
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        annualEmployeeDonations: [],
        companyPayrollBatch: {},
        companyPayrollBatches: [],
        donorDonationsInBatch: [],
        employeeDonation: {},
        employeeDonations: [],
        employeeDonationEmployees: [],
        employeesFormA4C: [],
        nextCompanyPayrollBatch: {},
        payments: [],
        payrollWithholdingUploadResponse: {},
        withheldPayrollSelections: {},
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {

    currentAnnualEmployeeDonations(state) {
        return state.annualEmployeeDonations;
    },
    currentCompanyPayrollBatch(state) {
        return state.companyPayrollBatch;
    },
    currentCompanyPayrollBatches(state) {
        return state.companyPayrollBatches;
    },
    currentDonorDonationsInBatch(state) {
        return state.donorDonationsInBatch;
    },
    currentEmployeeDonations(state) {
        return state.employeeDonations;
    },
    currentEmployeeDonationEmployees(state) {
        return state.employeeDonationEmployees;
    },
    currentEmployeesFormA4C(state) {
        return state.employeesFormA4C;
    },
    currentNextCompanyPayrollBatch(state) {
        return state.nextCompanyPayrollBatch;
    },
    currentPayments(state) {
        return state.payments;
    },
    currentPayrollWithholdingUploadResponse(state) {
        return state.payrollWithholdingUploadResponse;
    },
    currentWithheldPayrollSelections(state) {
        return state.withheldPayrollSelections;
    },

};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_ANNUAL_EMPLOYEE_DONATIONS](context, company_id) {
        console.log("GET_ANNUAL_EMPLOYEE_DONATIONS");
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/employee-donations/annual`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ANNUAL_EMPLOYEE_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_COMPANY_PAYROLL_BATCHES_BY_YEAR](context, {calendar_year, company_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/employee-donations/by-calendar-year/${calendar_year}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_PAYROLL_BATCHES_BY_YEAR, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_NEXT_COMPANY_PAYROLL_BATCH](context, company_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/employee-donations/latest`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_NEXT_COMPANY_PAYROLL_BATCH, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_EMPLOYEE_DONATIONS](context, {company_id, company_annual_donation_id}) {
        console.log("GET_EMPLOYEE_DONATIONS");
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/employee-donations/annual/${company_annual_donation_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_EMPLOYEE_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_EMPLOYEE_DONATION](context, {company_id, company_payroll_batch_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/employee-donations/${company_payroll_batch_id}/show/`)
                .then(({data}) => {

                    console.log("GET_PAYROLL_BATCH", data);

                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_EMPLOYEE_DONATION, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_EMPLOYEES_FORM_A4C](context, company_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/employee-donations/employees-form-a4c`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_EMPLOYEES_FORM_A4C, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_EMPLOYEE_DONATIONS_FILE_UPLOAD](context, {company_id, data}) {

        console.log("POST_EMPLOYEE_DONATIONS_FILE_UPLOAD", data);

        return new Promise((resolve, reject) => {
            ApiService.post("/api/companies/" + company_id + "/employee-donations/uploads", data)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_UPLOADED_EMPLOYEE_DONATIONS_FILE, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_EMPLOYEE_DONATION](context, form) {
        console.log("POST_EMPLOYEE_DONATION", form);
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/companies/${form.company_id}/employee-donations`, form)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_COMPANY_DAILY_DONATION_PAYMENT](context, {company_id, company_payroll_batch_id, payment}) {
        console.log('POST_COMPANY_DAILY_DONATION_PAYMENT');
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/companies/${company_id}/employee-donations/${company_payroll_batch_id}/payment/`, payment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_COMPANY_DAILY_DONATION_PAYMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_COMPANY_DAILY_DONATION_CARD_PAYMENT](context, {company_id, company_payroll_batch_id, payment}) {
        console.log('POST_COMPANY_DAILY_DONATION_CARD_PAYMENT');
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/companies/${company_id}/employee-donations/${company_payroll_batch_id}/payment/card`, payment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_COMPANY_DAILY_DONATION_PAYMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "admin",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_COMPANY_DAILY_DONATION_PAYMENT](context, {company_id, company_payroll_batch_id, payment}) {
        console.log("PATCH_COMPANY_DAILY_DONATION_PAYMENT");
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/companies/${company_id}/employee-donations/${company_payroll_batch_id}/payment/${payment.id}`, payment)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(UPDATE_COMPANY_DAILY_DONATION_PAYMENT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_COMPANY_DAILY_DONATION](context, {company_id, company_payroll_batch_id}) {
        console.log("DELETE_COMPANY_DAILY_DONATION");
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/companies/${company_id}/employee-donations/${company_payroll_batch_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_COMPANY_DAILY_DONATION_PAYMENT](context, {company_id, company_payroll_batch_id, payment_id}) {
        console.log("DELETE_COMPANY_DAILY_DONATION_PAYMENT");
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/companies/${company_id}/employee-donations/${company_payroll_batch_id}/payment/${payment_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_COMPANY_DAILY_DONATION_PAYMENT, payment_id);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_EMPLOYEE_DONATION_EMPLOYEES](context, {company_id, company_annual_donation_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/employee-donations/annual/${company_annual_donation_id}/employees`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_EMPLOYEE_DONATION_EMPLOYEES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "employeeDonations",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [APPEND_COMPANY_DAILY_DONATION_PAYMENT](state, data) {
        state.payments.push(data);
    },
    [UPDATE_COMPANY_DAILY_DONATION_PAYMENT](state, data) {
        let payments = state.payments;
        for (let i = 0; i < payments.length; i++) {
            if (payments[i].id === data.id) {
                payments.splice(i, 1, data);
                return;
            }
        }
    },
    [REMOVE_COMPANY_DAILY_DONATION_PAYMENT](state, id) {
        let payments = state.payments;
        for (let i = 0; i < payments.length; i++) {
            if (payments[i].id === id) {
                payments.splice(i, 1);
                return;
            }
        }
    },
    [SET_ANNUAL_EMPLOYEE_DONATIONS](state, data) {
        state.annualEmployeeDonations = data;
    },
    [SET_EMPLOYEE_DONATIONS](state, data) {
        state.employeeDonations = data;
    },
    [SET_COMPANY_PAYROLL_BATCHES_BY_YEAR](state, data) {
        state.companyPayrollBatches = data;
    },
    [SET_EMPLOYEE_DONATION](state, data) {
        state.companyPayrollBatch = data.companyPayrollBatch;
        state.donorDonationsInBatch = data.donorDonationsInBatch;
        state.payments = data.payments
    },
    [SET_UPLOADED_EMPLOYEE_DONATIONS_FILE](state, data) {
        state.employeeDonationsFile = data;
    },
    [SET_EMPLOYEE_DONATION_EMPLOYEES](state, data) {
        state.employeeDonationEmployees = data;
    },
    [SET_PAYROLL_WITHHOLDING_UPLOAD_SELECTIONS](state, data) {
        state.withheldPayrollSelections = data;
    },
    [CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE](state) {
        state.payrollWithholdingUploadResponse = {};
    },
    [SET_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE](state, data) {
        state.payrollWithholdingUploadResponse = data;
    },
    [SET_NEXT_COMPANY_PAYROLL_BATCH](state, data) {
        state.nextCompanyPayrollBatch = data;
    },
    [SET_EMPLOYEES_FORM_A4C](state, data) {
        state.employeesFormA4C = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
