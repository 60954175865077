<template>
    <div>
        <div id="dd-container">
            <a href="https://doublethedonation.com/matching-grant-resources/matching-gift-basics/">
                Matching Gift
            </a> and
            <a href="https://doublethedonation.com/matching-grant-resources/volunteer-grant-basics/">
                Volunteer Grant
            </a> information provided by
            <br>
            <a href="https://doublethedonation.com">
                <img
                    alt="Powered by Double the Donation"
                    src="https://doublethedonation.com/api/img/powered-by.png"
                />
            </a>
        </div>
    </div>
</template>

<script>
// import postscribe from "postscribe";

export default {
    name: "MatchingGifts",
    mounted() {
        let matchinggiftsinnerscript = "var DDCONF = { API_KEY: \"CvbnWV4I0xrJfT4g\" };";
        let matchinggiftsoutterscript = document.createElement("script");
        matchinggiftsoutterscript.innerHTML = matchinggiftsinnerscript;
        document.head.appendChild(matchinggiftsoutterscript);
        let matchinggiftsoutterscript2 = document.createElement("script");
        matchinggiftsoutterscript2.src = "https://doublethedonation.com/api/js/ddplugin.js";
        document.head.appendChild(matchinggiftsoutterscript2);

        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         console.log("postscribe");
        //         var matchinggiftsscript = "<script>var DDCONF = { API_KEY: \"CvbnWV4I0xrJfT4g\" };<\/script>" +
        //             "<script src=\"https://doublethedonation.com/api/js/ddplugin.js\"><\/script><div id=\"dd-container\"></div>";

        //         postscribe(
        //             "#matchinggiftsscript",
        //             matchinggiftsscript
        //         );
        //     }, 2000);
        // });
    },
};
</script>

<style scoped lang="scss">
@import "https://doublethedonation.com/api/css/ddplugin.css";
</style>

<!--


    <link href="https://doublethedonation.com/api/css/ddplugin.css" rel="stylesheet">


-->
