import Vue from 'vue';
import Vuex from 'vuex';

import error from './error.module';
import website from './website.module';
import auth from './auth.module';
import admin from "./admin.module";
import adminSchools from "./admin-schools.module";
import applications from './household-applications.module';
import applicationPromos from './application-promos.module';
import brochureRequests from "./brochure-requests.module";
import companies from './companies.module';
import companyAdmins from './company-admins.module';
import companyDropdown from "./company-dropdown.module";
import corporateDonations from './corporate-pledges.module';
import contactUs from "./contact-us.module";
import conversation from "./conversation.module";
import donors from './donors.module';
import employeeDonations from "./employee-donations.module";
import fiscalYearsModule from "./fiscal-years.module";
import households from './households.module';
import householdAdmins from './household-admins.module';
import invites from './invites.module';
import regions from './regions.module';
import schools from './schools.module';
import schoolAdmins from './school-admins.module';
import schoolApplications from './school-applications.module';
import schoolDropdown from './school-dropdown.module';
import schoolGrades from './school-grades.module';
import schoolStudents from './school-students.module';
import schoolYears from './school-years.module';
import studentDocumentDropdown from "./student-document-dropdown.module";
import studentDropdown from "./student-dropdown.module";
import userDropdown from "./user-dropdown.module";
//import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        error,
        website,
        auth,
        admin,
        adminSchools,
        applications,
        applicationPromos,
        brochureRequests,
        companies,
        companyAdmins,
        companyDropdown,
        contactUs,
        conversation,
        corporateDonations,
        donors,
        employeeDonations,
        fiscalYearsModule,
        households,
        householdAdmins,
        invites,
        regions,
        schools,
        schoolAdmins,
        schoolApplications,
        schoolGrades,
        schoolDropdown,
        schoolStudents,
        schoolYears,
        studentDocumentDropdown,
        studentDropdown,
        userDropdown
    },
    plugins: [
        createMutationsSharer({predicate: ['logout', 'setUser']})
    ]
});
