<template>
    <loading v-if="loading" />
    <div
        v-else
        class="outer-border mt-4"
    >
        <v-row>
            <v-col cols="auto">
                <v-card-title class="ml-n4">Changes Made</v-card-title>
            </v-col>
            <v-col>
                <v-checkbox
                    v-model="includeViewedChanges"
                    color="secondary"
                    @change="!includeViewedChanges"
                >
                    <template v-slot:label>
                        <div style="color: var(--apsto-black);"> Include Previously Viewed Changes</div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-row
            v-if="showMarkViewedButton"
            class="mb-5 mt-n5"
        >
            <v-col>
                <v-btn
                    color="primary"
                    @click="markViewed"
                >
                    Mark Changes Viewed
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
            :headers="headers"
            :items="revisions"
            :items-per-page="-1"
            class="elevation-2"
        >
            <template v-slot:no-data>
                <div class="text-apsto-teal text-h7-mulish">
                    There are no unviewed changes on file for this {{ documentName }}.
                </div>
            </template>
            <template #item.first_name="{ item }">
                {{ item.first_name }} {{ item.last_name }}
                <br>
                {{ displayDate(item.created_at) }}
                <br>
                {{ displayTime(item.created_at) }}
            </template>
            <template #item.key="{ item }">
                {{ fieldName(item) }}
                <div v-if="item.member_name">
                    {{ item.member_name }}
                </div>
                <div
                    v-if="item.viewed"
                    class="text-apsto-teal"
                >
                    Previously Viewed
                </div>
            </template>
            <template #item.old_value="{ item }">
                <div v-if="item.key === 'tuition_amount'">
                    {{ oldValue(item) | formatCurrency }}
                </div>
                <div v-else-if="item.key === 'birth_date'">
                    {{ displayDate(oldValue(item)) }}
                </div>
                <div v-else>
                    {{ oldValue(item) }}
                </div>
            </template>
            <template #item.new_value="{ item }">
                <div v-if="item.key === 'tuition_amount'">
                    {{ newValue(item) | formatCurrency }}
                </div>
                <div v-else-if="item.key === 'birth_date'">
                    {{ displayDate(newValue(item)) }}
                </div>
                <div v-else>
                    {{ newValue(item) }}
                </div>
            </template>
        </v-data-table>
        <div
            v-if="showMarkViewedButton"
            class="mt-5"
        >
            <v-btn
                color="primary"
                @click="markViewed"
            >
                Mark changes viewed
            </v-btn>
        </div>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { GET_REVISIONS, GET_SCHOOL_DROPDOWN, GET_SCHOOL_GRADES, MARK_REVISIONS_VIEWED } from "../../store/actions.type";
import Loading from "./Loading";

export default {
    name: "Revisions",
    components: {
        Loading
    },
    data() {
        return {
            loading: false,
            includeViewedChanges: false,
            snackbar: false,
            snackbarMessage: "",
            headers: [
                { text: 'NAME', value: 'first_name', width: '20%' },
                { text: 'ITEM', value: 'key', width: '30%' },
                { text: 'FROM', value: 'old_value' },
                { text: 'TO', value: 'new_value' },
            ],
        }
    },
    props: {
        application: {
            type: Object,
            required: true
        },
        applicationType: {
            type: String,
            required: true
        }
    },
    computed: {
        documentName() {
            return this.applicationType === "school" ? "partner agreement" : "application";
        },
        revisions() {
            return this.$store.getters.currentRevisionsFiltered(this.includeViewedChanges);
        },
        showMarkViewedButton() {
            return this.$store.getters.currentRevisionsFiltered(false).length > 0;
        }
    },
    methods: {
        fieldName(revision) {
            if (this.applicationType === "school") {
                switch (revision.key) {
                    case "agree_to_terms":
                        return "Agree To Terms";
                    case "charge_for_all_students":
                        return "Charge Tuition for All Students";
                    case "comments":
                        return "Comments";
                    case "comports_to_law":
                        return "Qualified School";
                    case "disabled_displaced":
                        return "Disabled / Displaced Tax Credit Scholarship";
                    case "how_scores_published":
                        return "How You Publish Test Scores";
                    case "fingerprints":
                        return "Fingerprints";
                    case "fingerprints_provider":
                        return "Fingerprints Provider";
                    case "low_income_corporate":
                        return "Low Income Corporate Tax Credit Scholarship";
                    case "original":
                        return "Original Tax Credit Scholarship";
                    case "switcher":
                        return "Switcher Tax Credit Scholarship";
                    case "publish_test_scores":
                        return "Publish Test Scores";
                    case "test_name":
                        return "Name of the Tests You Use";
                    case "tuition_amount":
                        return "Tuition Amount";
                    default:
                        return "Notify programmer missing field name";
                }
            }
            switch (revision.key) {
                case "active_military":
                    return "Active Military";
                case "agree_to_income_reporting_instructions":
                    return "Agree to Income Reporting Instructions";
                case "agree_to_terms":
                    return "Agree to Terms";
                // case "esa_contract":
                //     return "Current ESA Contract";
                // case "esa_contract_not":
                //     return "Not Participated in ESA";
                // case "esa_enum":
                //     return "ESA Contract Closed";
                case "birth_date":
                    return "Birth Date";
                case "comments":
                    return "Comments";
                case "disabled":
                    return "Disabled";
                case "email":
                    return "Email";
                case "first_name":
                    return "First Name";
                case "foster":
                    return "Foster";
                case "grade_id":
                    return "Grade";
                case "hardship_bankruptcy":
                    return "Hardship: Bankruptcy";
                case "hardship_comments":
                    return "Hardship Comments";
                case "hardship_employment_change":
                    return "Hardship: Employment Change";
                case "hardship_family_size_change":
                    return "Hardship: Family Size Change";
                case "hardship_general_expenses":
                    return "Hardship: General Expenses";
                case "hardship_loss_of_home":
                    return "Hardship: Loss of Home";
                case "hardship_loss_of_income":
                    return "Hardship: Loss of Income";
                case "hardship_medical_expenses":
                    return "Hardship: Medical Expenses";
                case "hardship_other":
                    return "Hardship: Other";
                case "hardship_tuition_expenses":
                    return "Hardship: Tuition Expenses";
                case "household_income_category_id":
                    return "Household Income Category";
                case "household_members":
                    return "Number of Household Members";
                case "household_qualifies_for_low_income_credit":
                    return "Household Qualifies For Low Income Credit";
                case "last_name":
                    return "Last Name";
                case "qualifies_for_disabled_displaced":
                    return "Qualifies for Disabled / Displaced Credit";
                case "qualifies_for_low_income_corporate":
                    return "Student Qualifies for Low Income Credit";
                case "qualifies_for_original":
                    return "Qualifies for Original Credit";
                case "qualifies_for_switcher":
                    return "Qualifies for Switcher Credit";
                case "transfer_from_charter_or_public_school":
                    return "Transferred From Charter Or Public School";
                case "transfer_from_different_state":
                    return "Transferred From Different State";
                case "transfer_from_home_school":
                    return "Transferred From Home Schooling";
                case "previous_scholarship":
                    return "Previous Scholarship";
                case "previous_scholarship_award_1":
                    return "Disabled / Displaced Tax Credit Scholarship";
                case "previous_scholarship_award_2":
                    return "Low Income Corporate Tax Credit Scholarship";
                case "previous_scholarship_award_3":
                    return "Original Tax Credit Scholarship";
                case "previous_scholarship_award_4":
                    return "Switcher Tax Credit Scholarship";
                case "previous_scholarship_award_1_sto":
                    return "Issued By Another AZ STO";
                case "foster_amount":
                    return "Student Use of Foster Income Amount";
                case "school_id":
                    return "School";
                case "tuition_amount":
                    return "Tuition Amount";
                default:
                    return "Notify programmer missing field name";
            }
        },
        oldValue(revision) {
            if (this.applicationType === "school") {
                switch (revision.key) {
                    case "comments":
                        return revision.old_value ? revision.old_value : "Blank";
                    case "agree_to_terms":
                    case "charge_for_all_students":
                    case "comports_to_law":
                    case "fingerprints":
                    case "publish_test_scores":
                        return revision.old_value === "0" ? "No" : "Yes";
                    case "disabled_displaced":
                    case "low_income_corporate":
                    case "original":
                    case "switcher":
                        return revision.old_value === "1" ? "Selected" : "Not Selected";
                    case "fingerprints_provider":
                    case "how_scores_published":
                    case "test_name":
                        return revision.old_value ? revision.old_value : "Unanswered";
                    case "tuition_amount":
                        return revision.old_value ? revision.old_value : "0000";
                    default:
                        return "Notify programmer missing old value";
                }
            }
            switch (revision.key) {
                case "active_military":
                case "agree_to_income_reporting_instructions":
                case "agree_to_terms":
                case "disabled":
                // case "esa_contract":
                // case "esa_contract_not":
                case "foster":
                case "household_qualifies_for_low_income_credit":
                case "qualifies_for_disabled_displaced":
                case "qualifies_for_low_income_corporate":
                case "qualifies_for_original":
                case "qualifies_for_switcher":
                case "transfer_from_charter_or_public_school":
                case "transfer_from_different_state":
                // case "transfer_from_esa":
                case "transfer_from_home_school":
                case "previous_scholarship":
                    return revision.old_value === "0" ? "No" : "Yes";
                case "hardship_bankruptcy":
                case "hardship_employment_change":
                case "hardship_family_size_change":
                case "hardship_general_expenses":
                case "hardship_loss_of_home":
                case "hardship_loss_of_income":
                case "hardship_medical_expenses":
                case "hardship_other":
                case "hardship_tuition_expenses":
                case "previous_scholarship_award_1":
                case "previous_scholarship_award_2":
                case "previous_scholarship_award_3":
                case "previous_scholarship_award_4":
                    return revision.old_value ? "Selected" : "Not Selected";
                case "birth_date":
                case "comments":
                case "email":
                case "first_name":
                case "foster_amount":
                case "hardship_comments":
                case "household_income_category_id":
                    return this.householdIncomeCategory(revision.old_value);
                case "household_members":
                case "last_name":
                case "previous_scholarship_award_1_sto":
                case "tuition_amount":
                    return revision.old_value ? revision.old_value : "Unanswered";
                case "grade_id":
                    return this.schoolGrade(revision.old_value);
                case "school_id":
                    return this.schoolName(revision.old_value);
            }
        },
        newValue(revision) {
            if (this.applicationType === "school") {
                switch (revision.key) {
                    case "comments":
                        return revision.new_value ? revision.new_value : "Blank";
                    case "agree_to_terms":
                    case "charge_for_all_students":
                    case "comports_to_law":
                    case "fingerprints":
                    case "publish_test_scores":
                        return revision.new_value === "0" ? "No" : "Yes";
                    case "disabled_displaced":
                    case "low_income_corporate":
                    case "original":
                    case "switcher":
                        return revision.new_value === "1" ? "Selected" : "Not Selected";
                    case "fingerprints_provider":
                    case "how_scores_published":
                    case "test_name":
                        return revision.new_value ? revision.new_value : "Unanswered";
                    case "tuition_amount":
                        return revision.new_value ? revision.new_value : "0000";
                    default:
                        return "Notify programmer missing new value";
                }
            }
            switch (revision.key) {
                case "active_military":
                case "agree_to_income_reporting_instructions":
                case "agree_to_terms":
                case "disabled":
                // case "esa_contract":
                // case "esa_contract_not":
                case "foster":
                case "household_qualifies_for_low_income_credit":
                case "qualifies_for_disabled_displaced":
                case "qualifies_for_low_income_corporate":
                case "qualifies_for_original":
                case "qualifies_for_switcher":
                case "transfer_from_charter_or_public_school":
                case "transfer_from_different_state":
                // case "transfer_from_esa":
                case "transfer_from_home_school":
                case "previous_scholarship":
                    return revision.new_value === "0" ? "No" : "Yes";
                case "hardship_bankruptcy":
                case "hardship_employment_change":
                case "hardship_family_size_change":
                case "hardship_general_expenses":
                case "hardship_loss_of_home":
                case "hardship_loss_of_income":
                case "hardship_medical_expenses":
                case "hardship_other":
                case "hardship_tuition_expenses":
                case "previous_scholarship_award_1":
                case "previous_scholarship_award_2":
                case "previous_scholarship_award_3":
                case "previous_scholarship_award_4":
                    return revision.new_value ? "Selected" : "Not Selected";
                case "birth_date":
                case "comments":
                case "email":
                case "first_name":
                case "foster_amount":
                case "hardship_comments":
                case "household_income_category_id":
                    return this.householdIncomeCategory(revision.new_value);
                case "household_members":
                case "last_name":
                case "previous_scholarship_award_1_sto":
                case "tuition_amount":
                    return revision.new_value ? revision.new_value : "Unanswered";
                case "grade_id":
                    return this.schoolGrade(revision.new_value);
                case "school_id":
                    return this.schoolName(revision.new_value);
            }
        },
        markViewed() {
            let application_type = this.applicationType;
            let application_id = application_type === "household" ? this.$route.params.application_id : this.$route.params.school_application_id;
            this.$store.dispatch(MARK_REVISIONS_VIEWED, { application_id, application_type })
                .then(() => {
                    this.snackbarMessage = "Revisions Marked";
                    this.snackbar = true;
                    this.includeViewedChanges = true;
                    this.getRevisions();
                })
                .catch((response) => {
                    this.snackbarMessage = response.message;
                    this.snackbar = true;
                });
        },
        getRevisions() {
            this.loading = true;
            let application_type = this.applicationType;
            let application_id = application_type === "household" ? this.$route.params.application_id : this.$route.params.school_application_id;

            this.$store.dispatch(GET_REVISIONS, { application_id, application_type })
                .then(() => {
                    this.$store.dispatch(GET_SCHOOL_GRADES).then(() => {
                        this.$store.dispatch(GET_SCHOOL_DROPDOWN).then(() => {
                            this.loading = false;
                        })
                    })
                })
                .catch((response) => {
                    console.log(response)
                });
        },
        schoolGrade(gradeId) {
            let grade = this.$store.getters.currentSchoolGradesFiltered(gradeId)[0];
            return grade.name;
        },
        schoolName(schoolId) {
            let school = this.$store.getters.currentSchoolsFiltered(schoolId)[0];
            return school.name;
        },
        householdIncomeCategory(incomeCategoryId) {
            switch (incomeCategoryId) {
                case "1":
                    return "Less Than 185% of poverty level";
                case "2":
                    return "From 185% To 342.25% of poverty level";
                case "3":
                    return "Greater Than 342.25% of poverty level";
                default:
                    return "Unanswered";
            }
        },
    },
    mounted() {
        this.getRevisions();
    }
}
</script>

<style scoped>
.outer-border {
    border: 1px dotted var(--apsto-teal);
    padding: 0 20px 20px;
}

::v-deep tbody td {
    height: unset !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    vertical-align: top;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
