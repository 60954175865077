<template>
  <div class="content">
        <span class="font-weight-bold">There are many ways for a student to qualify for the {{
            item.scholarship_label
          }}:</span>
    <div class="mt-2">
      <ol>
        <li>
          the student is a dependent of a member of the U.S. Armed Forces who is stationed in Arizona under
          military orders, OR
        </li>
        <li>
          the student is enrolling or is currently enrolled in Preschool and is disabled and attends a school that
          provides services for disabilities, OR
        </li>
        <li>
          the student is enrolling or is currently enrolled in private school Kindergarten, OR
        </li>
        <li>
          the student was awarded a low income scholarship or a switcher
          scholarship for the {{ this.application.prior_school_year_name }} school year, OR
        </li>
        <li>
          the student transferred in from an Arizona charter or public school, OR
        </li>
        <li>
          the student moved from another state or country and transferred directly to an Arizona private school,
          OR
        </li>
        <li>
          the student directly exited an ESA program to participate in a tuition tax credit program, OR
        </li>
        <li>
          the student was home-schooled immediately prior to transferring directly to an Arizona Private school
        </li>
      </ol>
    </div>
    <div
      v-if="item.qualifies === 'Yes'"
      class="mt-2"
    >
            <span class="font-weight-bold">
                You've indicated that {{ item.model.first_name }} meets the following prerequisites during this application
                school year:
            </span>
      <div class="mt-2"></div>
      <div v-if="item.model.student.active_military">
        Is a dependent of a member of the U.S. Armed Forces who is stationed in Arizona under
        military orders.
        <br>
      </div>
      <div v-if="isDisabledPreschooler(item)">
        Is disabled and is or will be enrolled in a preschool that offers services for the disabled.
        <br>
      </div>
      <div v-if="item.model.student.grade_id === 2">
        Is in Kindergarten.
        <br>
      </div>
      <div v-if="item.model.student.previous_scholarship_award_2">
        Was previously awarded a low income corporate scholarship
        <br>
      </div>
      <div v-if="item.model.student.previous_scholarship_award_4">
        Was previously awarded a switcher scholarship
        <br>
      </div>
      <div v-if="item.model.student.transfer_from_charter_or_public_school">
        Transferred in from a charter or public school
        <br>
      </div>
      <div v-if="item.model.student.transfer_from_different_state">
        Transferred in from a different state
        <br>
      </div>
      <div v-if="item.model.student.esa_enum === 2">
        Directly exited an ESA program to participate in a tuition tax credit program
        <br>
      </div>
      <div v-if="item.model.student.transfer_from_home_school">
        Was home-schooled immediately prior to transferring directly to an Arizona Private school
        <br>
      </div>
    </div>
    <div
      v-else
      class="mt-2 font-weight-bold"
    >
      <div v-if="item.model.student.esa_enum === 3">
        However {{ item.model.first_name }} is enrolled in the ESA program and cannot qualify for any tax credit
        scholarship while in that program.
      </div>
      <div v-else>
        You've indicated that {{ item.model.first_name }} does not meet any of these prerequisites during this
        application school year.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScholarshipSwitcher",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    application() {
      return this.$store.getters.currentApplication;
    },
  },
  methods: {
    isDisabledPreschooler(item) {
      return item.model.student.grade_id === 1 &&
        item.model.student.disabled &&
        item.model.student.school_has_disabled;
    }
  }
}

</script>

<style scoped>
ol > li {
  font-size: .9rem;
  margin-bottom: 0;
}

.content {
  font-family: "Mulish", sans-serif;
  font-size: .9rem;
  line-height: 1.40rem;
}</style>
