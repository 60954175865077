<template>
  <loading v-if="loading"/>
  <div v-else>

    <v-row v-if="isMobile">
      <v-col>
        <donor-header page-title="Monthly Contributions"/>
      </v-col>
    </v-row>

    <v-row
      v-if="!isMobile"
      align="center"
    >
      <v-col md="6">
        <donor-header page-title="Monthly Contributions"/>
      </v-col>
      <v-spacer/>
      <v-col cols="auto">
        <v-btn
          :disabled="disableSetupDonation"
          :to="{ name: 'donor-donation-subscription-create' }"
          color="primary"
          rounded
        >
          <v-icon left> mdi-plus-circle-outline</v-icon>
          Start Monthly Contributions
        </v-btn>
      </v-col>
    </v-row>

    <v-spacer/>
    <v-data-table
      :class="isMobile ? '' : 'ml-4 mt-5'"
      :disable-sort="isMobile"
      :headers="headers"
      :hide-default-footer="isMobile"
      :items="subscriptionDonations"
      class="elevation-2"
      group-by="tax_year"
      group-desc
    >
      <template #item.amount="{ item }">
        {{ item.amount | formatCurrency }}
      </template>
      <template #item.subscription_charge_day="{ item }">
        {{ item.subscription_charge_day }}
      </template>
      <template #item.subscription_charges_authorized="{ item }">
        {{ item.subscription_charges_authorized > 0 ? item.subscription_charges_authorized : 0 }}
      </template>
      <template #item.apply_subscription_charges_to_prior_tax_year="{ item }">
        <div v-if="item.apply_subscription_charges_to_prior_tax_year">
          Yes, apply donations through April tax filing day to prior tax year.
        </div>
        <div v-else>
          No
        </div>
      </template>
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <v-toolbar
          v-if="isMobile"
          color="secondary"
          flat
        >
          <v-row>
            <v-col>
              Monthly Contributions Status
            </v-col>
            <v-col cols="auto">
              <v-icon
                :disabled="disableSetupDonation"
                color="white"
                rounded
                @click="addItem"
              >
                mdi-plus-circle-outline
              </v-icon>
            </v-col>
          </v-row>
        </v-toolbar>
        <th colspan="7">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          Tax Year: {{ items[0].tax_year }}
        </th>
      </template>
      <template v-slot:top>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <v-card class="pb-5 dialog">
            <v-card-title class="pa-4">
              Confirm
            </v-card-title>
            <v-card-text class="mx-n2">
              <v-row class="mt-2">
                <v-col class="text-h6-mulish">
                  Stop monthly donations?
                </v-col>
              </v-row>
            </v-card-text>
            <div
              v-if="isMobile"
              class="px-4"
            >
              <v-btn
                block
                class="cancel-button"
                rounded
                @click="closeDeleteSubscriptionDonation"
              >
                Cancel
              </v-btn>
              <v-btn
                block
                class="mt-5"
                color="primary"
                @click="deleteSubscriptionDonationConfirm"
              >
                Yes, Stop Monthly Contributions
              </v-btn>
            </div>
            <v-card-actions v-else>
              <v-btn
                class="cancel-button"
                rounded
                @click="closeDeleteSubscriptionDonation"
              >
                Cancel
              </v-btn>
              <v-btn
                class="ml-2"
                color="primary"
                @click="deleteSubscriptionDonationConfirm"
              >
                Yes, Stop Monthly Contributions
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
          v-model="snackbar"
          centered
        >
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <template v-slot:item.actionsSubscriptionDonation="{ item }">
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="secondary"
              v-bind="attrs"
              @click="sendEmail(item)"
              v-on="on"
            >
              mdi-email
            </v-icon>
          </template>
          <span>Email Me A Copy</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :disabled="disableDeleteSubscription(item)"
              class="mr-2"
              color="red"
              v-bind="attrs"
              @click="deleteSubscriptionDonation(item)"
              v-on="on"
            >
              mdi-stop
            </v-icon>
          </template>
          <span>Stop Monthly Contribution</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {
  DELETE_DONOR_SUBSCRIPTION_DONATION,
  EMAIL_DONOR_SUBSCRIPTION_DONATION,
  GET_DONOR_SUBSCRIPTION_DONATIONS
} from "../../../store/actions.type";
import DonorHeader from "../../../components/donors/DonorHeader.vue";

export default {
  name: "DonationSubscriptionList",
  components: {
    DonorHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        name: '',
        status_name: '',
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Tax Year',
          align: 'start',
          value: 'tax_year',
        },
        {text: 'Amount', value: 'amount'},
        {text: 'CHARGE DAY', value: 'subscription_charge_day'},
        {text: 'Charges Authorized', value: 'subscription_charges_authorized'},
        {text: 'Charges Processed', value: 'subscription_charges_processed'},
        {text: 'Charges Remaining', value: 'subscription_charges_remaining'},
        {text: 'Apply To Prior Tax Year', value: 'apply_subscription_charges_to_prior_tax_year'},
        {text: 'Options', value: 'actionsSubscriptionDonation', sortable: false},
      ],
      loading: true,
      sending: false,
      snackbar: false,
      snackbarMessage: "",

    }
  },
  computed: {
    derivedTaxYear() {
      return new Date().getMonth() + 1 === 12 ? "next" : "current";
    },
    disableSetupDonation() {
      const taxYearForFilteredCall = this.derivedTaxYear === "current" ? new Date().getFullYear() : new Date().getFullYear() + 1;
      return !!this.$store.getters.currentActiveSubscriptionDonationsFiltered(taxYearForFilteredCall).length;
    },
    donor() {
      return this.$store.getters.currentDonor;
    },
    subscriptionDonations() {
      return this.$store.getters.currentSubscriptionDonations;
    },
  },
  methods: {
    addItem() {
      this.$router.push({name: 'donor-donation-subscription-create'});
    },
    disableDeleteSubscription(item) {
      return item.subscription_charges_authorized === item.subscription_charges_processed;
    },
    getSubscriptionDonations() {
      this.$store.dispatch(GET_DONOR_SUBSCRIPTION_DONATIONS)
    },
    closeDeleteSubscriptionDonation() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteSubscriptionDonation(item) {
      this.editedIndex = this.subscriptionDonations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteSubscriptionDonationConfirm() {
      this.destroySubscriptionDonation();
      this.subscriptionDonations.splice(this.editedIndex, 1)
      this.closeDeleteSubscriptionDonation()
    },
    destroySubscriptionDonation() {
      this.saving = true;
      let donor_selection_id = this.editedItem.id;
      this.$store.dispatch(DELETE_DONOR_SUBSCRIPTION_DONATION, donor_selection_id)
        .then(() => {
          this.saving = false;
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;

          this.snackbar = true;
        });
    },
    initialize() {
      this.subscriptionDonations = [];
    },
    sendEmail(item) {
      this.editedIndex = this.subscriptionDonations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.sending = true;
      let donor_selection_id = this.editedItem.id;
      this.$store.dispatch(EMAIL_DONOR_SUBSCRIPTION_DONATION, donor_selection_id)
        .then(() => {
          this.sending = false;
          this.snackbarMessage = "Email sent";

          this.snackbar = true;
        })
        .catch((response) => {
          console.log(response);
          this.sending = false;
        });
    },
  },
  mounted() {
    this.getSubscriptionDonations();
    this.loading = false;
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep th:not([role=columnheader]) {
  background-color: var(--apsto-purple-very-light);
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}
</style>
