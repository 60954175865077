<template>
    <v-select
        id="slug"
        v-model="localSiteWallpaper"
        :error="!!errors['slug']"
        :error-messages="errors['slug'] ? errors['slug'] : null"
        :items="siteWallpapersForDropdown"
        :label="this.label"
        :loading="loading"
        item-text="name"
        item-value="slug"
    />
</template>

<script>
export default {
    name: "SiteWallpaperSelector",
    props: {
        label: {
            default: "Wallpaper",
            type: String
        },
        value: {
            default: '',
            type: String,
            required: true,
        },
    },
    computed: {
        siteWallpapersForDropdown() {
            return this.$store.getters.currentSiteWallpaperSlugs;
        },
        loading() {
            return false;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
        localSiteWallpaper: {
            get() {
                return this.value
            },
            set(localSiteWallpaper) {
                this.$emit('input', localSiteWallpaper)
            }
        }
    },
    mounted() {
        this.$emit('input', this.localSiteWallpaper);
    },
};
</script>
