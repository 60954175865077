<template>
  <loading v-if="loading"/>
  <div v-else>
    <admin-header class="ml-4 pt-4" :page-title="'Student: ' + studentPermanentName" />
    <div v-if="sentFromRecommendedStudent" class="ml-4 mt-n4">
      You got here by clicking on {{ sentFromRecommendedStudent }} which is a donor recommendation that has been linked to {{ studentPermanentName }}.
    </div>
    <div v-else class="ml-4 mt-n4">You came here from the Edit School page for {{ school.name }}.</div>
    <v-row center>
      <v-col>
        <v-card class="mt-4 pa-5" style="min-height: 196px;">
          <div class="text-h6-mulish">Awards Made For {{ studentPermanentName }}</div>
          <v-data-table
            :headers="awardsHeaders"
            :items="awards"
            :single-select="true"
            item-key="id"
            class="mt-3"
          >
            <template v-slot:no-data>
            <span class="text-apsto-teal">
              No awards have been issued for this student.
            </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mt-4 pa-5" style="min-height: 196px;">
          <div class="text-h6-mulish">{{ school.name }}</div>
          <v-row class="mt-0" align="center">
            <v-col cols="auto" class="text-h6-mulish">Primary Contact</v-col>
            <v-col>
              <a
                :href="'/impersonate/take/' + school.primary_user_id"
                class="text-apsto-teal font-weight-bold"
              >
                Impersonate
              </a>
            </v-col>
          </v-row>
          <div class="mt-2">
            {{ school.primary_user_name }}, {{ school.primary_user_title }}
            <br>
            Email:
            <a :href="'mailto:' + school.primary_user_email" target="_blank" color="primary">
              {{ school.primary_user_email }}
            </a>
            <br>
            Phone: {{ school.contact_phone }}
          </div>
        </v-card>
        <v-card class="mt-4 pa-5" style="min-height: 196px;">
          <div class="text-h6-mulish">Donor Recommendations Linked To {{ studentPermanentName }}</div>
          <v-data-table
            :headers="recommendedStudentsHeaders"
            :items="recommendedStudents"
            :single-select="true"
            item-key="id"
            class="mt-3"
          >
            <template v-slot:no-data>
              <span class="text-apsto-teal">
                This student has not been recommended by any donors.
              </span>
            </template>
            <template v-slot:item.actionsForRecommendedStudentsTable="{ item }">
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    @click="unlink(item)"
                    v-on="on"
                  >
                    fas fa-unlink
                  </v-icon>
                </template>
                <span>Unlink Donor Recommendation {{ item.name }} From Student {{ studentPermanentName }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../../../../main/AdminHeader";
import ConfirmationDialog from "../../../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../../../components/shared/Loading";
import {GET_ADMIN_LINKED_STUDENT_AWARDS, GET_ADMIN_SCHOOL} from "../../../../../../store/actions.type";

export default {
  name: "AdminSchoolLinkedStudentAwards",
  components: {
    AdminHeader,
    ConfirmationDialog,
    Loading,
  },
  data() {
    return {
      loading: true,
      saving: false,
      awardsHeaders: [
        {text: 'Date', align: 'start', value: 'created_at'},
        {text: 'Amount', align: 'start', value: 'amount'},
      ],
      recommendedStudentsHeaders: [
        {text: 'Student Name ENTERED BY DONOR', align: 'start', value: 'name'},
        {text: 'DONOR', align: 'start', value: 'donor_name'},
        {text: 'Amount', align: 'start', value: 'amount'},
        {text: "Actions", value: "actionsForRecommendedStudentsTable", sortable: false},
      ],
    }
  },
  computed: {
    awards() {
      return [];
    },
    href() {
      return "Link to list of all permanent students in system."
    },
    studentPermanentName() {
      return this.linkedStudent.studentPermanent.name;
    },
    linkedStudent() {
      return this.$store.getters.currentAdminLinkedStudentAwards;
    },
    recommendedStudents() {
      return this.linkedStudent.studentPermanent.donor_selection_students;
    },
    school() {
      return this.$store.getters.currentAdminSchool;
    },
    sentFromRecommendedStudent() {
      return this.linkedStudent.sentFromRecommendedStudent;
    }
  },
  methods: {
    getLinkedStudent() {
      this.loading = true;
      let school_id = this.$route.params.school_id;
      let student_permanent_id = this.$route.params.student_permanent_id;
      let donor_selection_student_id = this.$route.params.donor_selection_student_id;
      this.$store.dispatch(GET_ADMIN_SCHOOL, school_id)
        .then(() => {
          this.$store.dispatch(GET_ADMIN_LINKED_STUDENT_AWARDS, {
            school_id,
            student_permanent_id,
            donor_selection_student_id
          }).then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
        });
    },
    unlink(item) {
      let school_id = this.$route.params.school_id;
      let donor_selection_student_id = item.id;
      this.$router.push(`/dashboard/admin/schools/${school_id}/unlink-student/${donor_selection_student_id}`);
    },
  },
  mounted() {
    this.getLinkedStudent();
  },
}
</script>

<style scoped>

a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
  height: 60px !important;
}

::v-deep table.donation-box tbody td {
  height: unset !important;
}

::v-deep tbody td.student-permanent {
  height: unset !important;
  padding-bottom: 4px;
  padding-top: 4px;
}

::v-deep table.donation-box tbody td.tax-credit-type-name-column {
  padding-right: 20px;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep .theme--light.v-data-table.unlinked-students > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
  cursor: pointer;
}

::v-deep .v-icon {
  font-size: 24px !important;
}

</style>
