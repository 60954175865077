//Views
import CharitableDonationCreate from "../views/donors/donations/CharitableDonationCreate";
import DonationAcknowledgement from "../views/donor-guests/GuestDonationAcknowledgement";
import DonationCreate from "../views/donors/donations/DonationCreate";
import DonationList from "../views/donors/main/DonationList";
import DonationPageCreate from "../views/donors/donations/DonationPageCreate";
import DonationSubscriptionCreate from "../views/donors/donations/DonationSubscriptionCreate";
import DonationSubscriptionList from "../views/donors/main/DonationSubscriptionList";
import DonationWithholdingCreate from "../views/donors/donations/DonationWithholdingCreate";
import DonationWithholdingList from "../views/donors/main/DonationWithholdingList";
import Donor from "../views/donors/main/Donor";
import AnnualDonationReceipts from "../views/donors/annual-receipt/AnnualDonationReceipts";
import DonorEdit from "../views/donors/main/DonorEdit";
import DonationWithholdingFormUpload from "../views/donors/donations/DonationWithholdingFormUpload.vue";
import CharitableDonationPageCreate from "../views/donors/donations/CharitableDonationPageCreate.vue";
import DonorSubscriptionExplainer from "../views/donors/main/DonorSubscriptionExplainer.vue";
import DonorWithholdingExplainer from "../views/donors/main/DonorWithholdingExplainer.vue";

export default [
    {
        path: "/dashboard/donors",
        name: "donor",
        component: Donor,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/annual-donations-receipt",
        name: "donor-annual-donations-receipt",
        component: AnnualDonationReceipts,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/edit",
        name: "donor-edit",
        component: DonorEdit,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donations",
        name: "donor-donations-list",
        component: DonationList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donation-acknowledgement/:email",
        name: "guest-donation-acknowledgement",
        component: DonationAcknowledgement,
        meta: {
            requiresAuth: false,
            hideForAuth: true,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/charitable-donation/create",
        name: "charitable-donation-create",
        component: CharitableDonationCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/charitable-donation-page/:promo_id/create",
        name: "charitable-donation-page-create",
        component: CharitableDonationPageCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donation/create",
        name: "donor-donation-create",
        component: DonationCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donation-page/:promo_id/create",
        name: "donor-donation-page-create",
        component: DonationPageCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donations/subscription",
        name: "donor-donations-subscription-list",
        component: DonationSubscriptionList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donation/subscription/create",
        name: "donor-donation-subscription-create",
        component: DonationSubscriptionCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/subscription-explainer",
        name: "donor-subscription-explainer",
        component: DonorSubscriptionExplainer,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donations/withholding",
        name: "donor-donations-withholding-list",
        component: DonationWithholdingList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donation/withholding/create",
        name: "donor-donation-withholding-create",
        component: DonationWithholdingCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/withholding-explainer",
        name: "donor-withholding-explainer",
        component: DonorWithholdingExplainer,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
    {
        path: "/dashboard/donors/donations/withholding/:donor_selection_id/upload",
        name: "donor-donations-withholding-form-upload",
        component: DonationWithholdingFormUpload,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Donor Portal'
        },
    },
];
