<template>
    <v-img
        v-if="page.header_image"
        :src="page.header_image"
        :key="page.header_image"
        contain
    >
        <v-row
            class="fill-height ma-0 white--text font-roboto-slab font-weight-medium"
            align="center"
            justify="center"
        >
            <v-col
                cols="6"
                class="text-center"
            >
                <v-textarea
                    v-model="localPage.header_text"
                    auto-grow
                    v-if="editMode"
                    dark
                    class="text-h4"
                    @focus="lockFab"
                    @blur="updatePage"
                />
                <div
                    v-resize-text="{ ratio: 1.4 }"
                    v-else
                    class="billboard-body"
                >
                    {{ page.header_text }}
                </div>
            </v-col>
        </v-row>
    </v-img>
    <v-row
        v-else
        class="fill-height ma-0 white--text font-roboto-slab font-weight-medium purple-background"
        align="center"
        justify="center"
    >
        <v-col
            cols="6"
            class="text-center"
        >
            <v-textarea
                v-model="localPage.header_text"
                auto-grow
                v-if="editMode"
                dark
                class="text-h4"
                @focus="lockFab"
                @blur="updatePage"
            />
            <div
                v-resize-text="{ ratio: 1.4, minFontSize: '30px', maxFontSize: '44px' }"
                v-else
                class="billboard-body py-10"
            >
                {{ page.header_text }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { PATCH_PAGE } from '../../../../store/actions.type';
import { SET_FAB_ENABLED } from '../../../../store/mutations.type';
export default {
    name: "Billboard",
    props: {
        page: {
            type: Object,
        },
        forcePreview: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        editMode() {
            if (this.forcePreview) return false;
            return this.$store.getters.currentEditMode;
        },
    },
    data() {
        return {
            loading: false,
            localPage: null,
        };
    },
    created() {
        this.localPage = this.page;
    },
    methods: {
        updatePage(value) {
            this.loading = true;
            let self = this;
            let page = this.localPage;
            this.$store
                .dispatch(PATCH_PAGE, page)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    self.loading = false;
                })
                .catch((e) => {
                    self.loading = false;
                });
            return;
        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        }
    }
};
</script>

<style scoped>.billboard-body {
    font-family: "Roboto Slab", serif;
    text-shadow: 1px 1px 1px #000;
    line-height: 1.1;
}</style>
