<template>
    <div>
        <v-card
            v-if="showProgramDetails"
            class="mb-5"
        >
            <v-card-title>Program Key Points</v-card-title>
            <div class="pb-5 px-5">
                <div class="mb-5">
                    Each year, the Arizona legislature sets statewide fiscal caps for the amount of funds that can be
                    claimed under two corporate tax credit scholarships.
                    <div class="my-5">
                        The two credits are the
                        <a
                            href="https://azleg.gov/ars/43/01183.htm"
                            target="_blank"
                        >
                            <span class="text-apsto-teal">{{
                                    taxCreditInfo.lowIncomeTaxCreditName
                                }} A.R.S. 43-1183</span>
                        </a>
                        for donations to support tuition costs for low income or underserved students and the
                        <a
                            href="https://azleg.gov/ars/43/01184.htm"
                            target="_blank"
                        >
                            <span class="text-apsto-teal">{{
                                    taxCreditInfo.disabilityTaxCreditName
                                }} A.R.S. 43-1184</span>
                        </a>
                        for donations to support tuition costs for students who have been identified as having a
                        disability
                        (physical, learning, or emotional), or students who are or have been in the Arizona foster care
                        system.
                    </div>
                    <div>
                        The fiscal cap limits are published by June each year and go effective in
                        early July. For the current fiscal year, the limits were initially
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.statewideLimitLowIncome / 1000000 | formatCurrency }}M
                        </span>
                        for the
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.lowIncomeTaxCreditName }}
                        </span>
                        and
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.statewideLimitDisability / 1000000 | formatCurrency }}M
                        </span>
                        for the
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.disabilityTaxCreditName }}
                        </span>
                        tax credit and there are now remaining unused funds of
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.statewideLimitLowIncomeRemaining / 1000000 | formatCurrency }}M
                        </span>
                        for the
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.lowIncomeTaxCreditName }}
                        </span>
                        and
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.statewideLimitDisabilityRemaining / 1000000 | formatCurrency }}M
                        </span>
                        for the
                        <span class="text-apsto-purple font-weight-black">
                            {{ taxCreditInfo.disabilityTaxCreditName }}
                        </span>
                        tax credit
                        .
                    </div>
                </div>
                <div>
                    The requests are approved on a first come, first served basis until the fiscal cap is met. Please
                    complete your donation request here and we will notify you whether funds are available to submit it
                    right away or your donation request will need to wait to be submitted until July.
                </div>
                <div class="mt-5">
                    If you'd like to read more about these credits, you can find more information at our
                    <a
                        href="/corporate-program"
                        target="_blank"
                    >
                        <span class="text-apsto-teal">corporate program</span>
                    </a>
                    page.
                </div>
                <v-divider class="my-5"/>
                <div
                    class="text-apsto-teal"
                    style="cursor:pointer; font-size: 1.075rem;"
                    @click="showProgramDetails = !showProgramDetails"
                >
                    {{ programDetailsText }}
                </div>
            </div>
        </v-card>
        <v-card class="mb-5">
            <v-card-title>Please complete the Donation Request Form below</v-card-title>
            <v-card-subtitle v-if="showProgramDetails === false">
                <div
                    class="text-apsto-teal"
                    style="cursor:pointer; font-size: 1.075rem;"
                    @click="showProgramDetails = !showProgramDetails"
                >
                    {{ programDetailsText }}
                </div>
            </v-card-subtitle>
            <v-divider/>
            <div class="pt-5 px-5">
                <table
                    class="box-with-background"
                    style="width:100%;"
                >
                    <tr>
                        <td>Primary Contact Name</td>
                        <td>{{ company.primary_user_name }}</td>
                    </tr>
                    <tr>
                        <td>Primary Contact Email</td>
                        <td>{{ company.primary_user_email }}</td>
                    </tr>
                    <tr>
                        <td>Primary Contact Title</td>
                        <td>{{ company.primary_user_title }}</td>
                    </tr>
                </table>
                <v-text-field
                    id="secondary_contact_name"
                    v-model.trim="corporatePledge.secondary_contact_name"
                    :error="!!errors.secondary_contact_name"
                    :error-messages="errors.secondary_contact_name ? errors.secondary_contact_name : null"
                    :hint="'Please enter secondary contact name if applicable or leave blank if ' + secondaryContactReference + '.'"
                    background-color="white"
                    class="mt-5"
                    filled
                    label="Secondary Contact Name"
                />
                <v-text-field
                    id="secondary_contact_title"
                    v-model.trim="corporatePledge.secondary_contact_title"
                    :error="!!errors.secondary_contact_title"
                    :error-messages="errors.secondary_contact_title ? errors.secondary_contact_title : null"
                    :hint="'Please enter secondary contact title if applicable or leave blank if ' + secondaryContactReference + '.'"
                    background-color="white"
                    filled
                    label="Secondary Contact Title"
                />
                <v-text-field
                    id="secondary_contact_email"
                    v-model.trim="corporatePledge.secondary_contact_email"
                    :error="!!errors.secondary_contact_email"
                    :error-messages="errors.secondary_contact_email ? errors.secondary_contact_email : null"
                    :hint="'Please enter secondary contact email if applicable or leave blank if ' + secondaryContactReference + '.'"
                    background-color="white"
                    filled
                    label="Secondary Contact Email"
                />
                <v-text-field
                    id="federal_ein"
                    v-model.trim="company.federal_ein"
                    :error="!!errors.federal_ein"
                    :error-messages="errors.federal_ein ? errors.federal_ein : null"
                    background-color="white"
                    filled
                    label="Federal EIN"
                    @input="federalEIN"
                />
                <v-text-field
                    id="naics_code"
                    v-model.trim="company.naics_code"
                    :error="!!errors.naics_code"
                    :error-messages="errors.naics_code ? errors.naics_code : null"
                    background-color="white"
                    filled
                    label="NAICS Code (Leave blank unless you are an insurance company and you plan to apply this donation against your premium tax liability)"
                />
                <v-select
                    id="tax_credit_type"
                    v-model="corporatePledge.tax_credit_type"
                    :error="!!errors.tax_credit_type"
                    :error-messages="errors.tax_credit_type ? errors.tax_credit_type : null"
                    :items="taxCreditTypes"
                    autocomplete="on"
                    background-color="white"
                    filled
                    item-key="id"
                    item-text="value"
                    item-value="id"
                    label="Tax Credit Type"
                    persistent-hint
                />
                <v-currency-field
                    id="amount"
                    v-model.trim="corporatePledge.amount"
                    :error="!!errors.amount"
                    :error-messages="errors.amount ? errors.amount : null"
                    autocomplete="amount"
                    background-color="white"
                    filled
                    label="Requested Donation Amount"
                />

                <div v-if="company.organization_type_enum === 2 || company.organization_type_enum === 3">
                    <v-select
                        id="subs_subsidiary"
                        v-model="corporatePledge.subs_subsidiary"
                        :error="!!errors.subs_subsidiary"
                        :error-messages="errors.subs_subsidiary ? errors.subs_subsidiary : null"
                        :items="subsSubsidiaryOptions"
                        background-color="white"
                        filled
                        hint="A Qualified Subchapter S Subsidiary, also known as a QSUB or QSSS, is an S Corporation that's owned by another (Parent) S Corporation."
                        item-key="id"
                        item-text="value"
                        item-value="id"
                        label="Are You a Qualified Subchapter S Subsidiary"
                        name="subs_subsidiary"
                        persistent-hint
                    />
                    <v-text-field
                        v-if="corporatePledge.subs_subsidiary === 1"
                        id="subs_parent_info"
                        v-model.trim="corporatePledge.subs_parent_info"
                        :error="!!errors.subs_parent_info"
                        :error-messages="errors.subs_parent_info ? errors.subs_parent_info : null"
                        background-color="white"
                        filled
                        label="Parent Company Name and Federal EIN"
                    />
                </div>
                <corporate-pledge-recommendations
                    v-model="corporatePledge.recommendations"
                    class="mt-n5 pb-2"
                />
            </div>
        </v-card>
        <corporate-pledge-review-panel
            :company="company"
            :corporate-pledge="corporatePledge"
            called-from="company"
            class="my-5"
        />
    </div>
</template>

<script>
import {GET_TAX_CREDIT_INFO} from "../../../store/actions.type";
import CorporatePledgeRecommendations from "./CorporatePledgeRecommendations";
import CorporatePledgeReviewPanel from "./CorporatePledgeReviewPanel.vue";

export default {
    name: "CorporatePledgeForm",
    components: {
        CorporatePledgeRecommendations,
        CorporatePledgeReviewPanel,
    },
    props: {
        company: {
            type: Object,
            required: true
        },
        corporatePledge: {
            type: Object,
            required: true,
        },
        create: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showProgramDetails: false,
            loading: false,
        }
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("corporatePledges");
        },
        errors() {
            return this.$store.getters.getErrors("corporatePledges");
        },
        programDetailsText() {
            return this.showProgramDetails ? "Hide corporate program facts and current limits" : "Click to see corporate program facts and current limits";
        },
        secondaryContactReference() {
            return this.company.secondary_user_name
                ? "secondary contact is " + this.company.secondary_user_name
                : " you are the only contact for your company";
        },
        subsSubsidiaryOptions() {
            return this.$store.getters.subsSubsidiaryOptions;
        },
        taxCreditInfo() {
            return this.$store.getters.taxCreditInfo;
        },
        taxCreditTypes() {
            return this.$store.getters.taxCreditTypes;
        },
    },
    methods: {
        federalEIN() {
            const x = this.company.federal_ein
                .replace(/\D/g, "")
                .match(/(\d{0,2})(\d{0,7})/);
            this.company.federal_ein = !x[2] ? x[1] : x[1] + (x[2] ? "-" + x[2] : "");
        },
        getTaxCreditInfo() {
            this.loading = true;
            this.$store.dispatch(GET_TAX_CREDIT_INFO)
                .then(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getTaxCreditInfo();
    }
}
</script>



