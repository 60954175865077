<template>
  <div>

    <div
      v-if="promo.active !== 1"
      class="mt-10 text-h5-mulish"
      style="text-align: left;"
    >
      This page has been withdrawn by its author.
    </div>
    <div v-else>
      <v-row
        :style="`background-color:${promo.banner_background_color}`"
        align="center"
        class="your-banner"
        justify="center"
      >
        <v-col
          :style="`color:${promo.banner_text_color}`"
          class="text-center font-weight-bold text-h4 px-10 my-6"
        >
          {{ promo.banner_caption }}
        </v-col>
      </v-row>
      <v-row
        :style="`background-image: url(\'/website/siteWallpapers/${promo.site_wallpaper_id}/display/\');`"
        align="center"
        class="your-content"
        justify="center"
      >
        <v-col
          class="body-background px-3 px-sm-8 px-md-16 pb-10"
        >
          <v-row class="pt-10 px-2">
            <v-col
              class="pr-3 pr-md-10"
              md="6"
              style="text-align: left;"
            >
              <h2 :style="`color:${promo.banner_background_color}`">
                Dear Friends and Family,
              </h2>

              <br/>

              <div v-html="cleanHtml(promo.introductory_text)"/>

              <div v-html="cleanHtml(promo.child_narrative)"/>

              <div v-html="cleanHtml(promo.footer_text)"/>

              <!-- START OF Static -->

              <p>
                You can make this tax credit donation to Arizona Private School
                Tuition Organization (APSTO) anytime between now and April 15,
                {{ year + 1 }}. When you file your {{ year }} taxes you will deduct that amount
                from your Arizona state tax liability. If you owe money when you
                file, you will owe less by the amount you donated. If you are
                getting a refund, the amount of your refund will be increased by
                the amount of your donation.
              </p>
              <p>
                If you make a tax credit donation to APSTO, please let us know.
                All donations are anonymous, and we would love to thank you for
                participating in this program.
              </p>

              <v-btn
                :color="promo.banner_background_color"
                :disabled="disabled"
                :style="`color:${promo.banner_text_color}`"
                :to="{ name: 'online-donation-page', params: { promo_id: this.promo.id } }"
                block
                class="your-promo-link mt-6 font-weight-black"
                rounded
              >
                ARIZONA TAXPAYERS: DONATE NOW <br> & GET AN AZ TAX CREDIT
              </v-btn>

              <v-btn
                :color="promo.secondary_background_color"
                :disabled="disabled"
                :style="`color:${promo.secondary_text_color}`"
                :to="{ name: 'online-charitable-donation-page', params: { promo_id: this.promo.id } }"
                block
                class="your-promo-link mt-5 font-weight-black"
                rounded
              >
                NOT AN ARIZONA TAXPAYER: DONATE <br>NOW & GET A TAX DEDUCTION
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-card
                contain
                max-height="1280"
                rounded
                width="960"
              >
                <v-img :src="`/website/uploads/show/${promo.primary_image}`"/>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="text-align: left;">
              <p>
                This is a dollar-for-dollar tax credit, so whether you pay the
                taxes or make the donation and take the credit, the cost to you is
                exactly the same. You get to choose if you want to pay your taxes
                to the state, or help support a child’s education.
              </p>
              <p>
                If you have questions, please check out the video below. This link
                will also provide some additional support.
              </p>
              <p>Or you can call APSTO at (480) 256-1001</p>
              <!-- END OF Static -->

              <div v-html="cleanHtml(promo.signature)"/>
            </v-col>
          </v-row>
          <v-row
            align="center"
            class="movie-background rounded-4"
          >
            <v-col
              class="text-center"
              cols="12"
            >
              <h3
                :style="`color:${promo.banner_background_color}`"
                class="pt-4"
              >
                CHECK OUT THIS QUICK VIDEO TO SEE HOW IT WORKS
              </h3>
              <v-dialog
                :scrollable="false"
                max-width="830"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :block="isMobile"
                    :color="promo.banner_background_color"
                    :disabled="disabled"
                    :style="`color:${promo.banner_text_color}`"
                    class="your-promo-link my-5"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div
                      :class="isMobile ? '' : 'mx-16'"
                      class="font-weight-black"
                    >
                      WATCH VIDEO NOW
                    </div>
                  </v-btn>
                </template>
                <v-card class="overflow-hidden text-center">
                  <apsto-video/>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="pt-4">
            <v-col md="6">
              <h2 :style="`color:${promo.banner_background_color}`">
                THANK YOU!
              </h2>

              <p
                class="my-5"
                v-html="promo.thank_you"
              />


              <v-btn
                :color="promo.banner_background_color"
                :disabled="disabled"
                :style="`color:${promo.banner_text_color}`"
                :to="{ name: 'online-donation-page', params: { promo_id: this.promo.id } }"
                block
                class="your-promo-link mt-6 font-weight-black"
                rounded
              >
                ARIZONA TAXPAYERS: DONATE NOW <br> & GET AN AZ TAX CREDIT
              </v-btn>

              <v-btn
                :color="promo.secondary_background_color"
                :disabled="disabled"
                :style="`color:${promo.secondary_text_color}`"
                :to="{ name: 'online-charitable-donation-page', params: { promo_id: this.promo.id } }"
                block
                class="your-promo-link mt-5 font-weight-black"

                rounded
              >
                NOT AN ARIZONA TAXPAYER: DONATE <br>NOW & GET A TAX DEDUCTION
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-card
                contain
                max-height="1280"
                rounded
                width="960"
              >
                <v-img :src="`/website/uploads/show/${promo.secondary_image}`"/>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        :style="`background-color:${promo.banner_background_color}`"
        align="center"
        class="your-banner"
        justify="center"
      >
        <v-col
          :style="`color:${promo.banner_text_color}`"
          class="text-center"
          cols="12"
          lg="6"
          md="8"
        >
          *A SCHOOL TUITION ORGANIZATION CANNOT AWARD, RESTRICT OR RESERVE
          SCHOLARSHIPS SOLELY ON THE BASIS OF A DONOR’S RECOMMENDATION. A TAXPAYER
          MAY NOT CLAIM A TAX CREDIT IF THE TAXPAYER AGREES TO SWAP DONATIONS WITH
          ANOTHER TAXPAYER TO BENEFIT EITHER TAXPAYER’S OWN DEPENDENT.
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ApstoVideo from "../../../../components/website/components/Faq/Video";

export default {
  name: "YourPageContent",
  components: {
    ApstoVideo,
  },
  props: {
    promo: Object,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    disabled() {
      return !(this.promo.primary_image && this.promo.id);
    },
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    cleanHtml(string) {
      if (!string) return "";
      return string.replace("<p><br></p>", "");
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 2rem;
}

.text-h4 {
  font-family: "Roboto Slab", serif !important;
}

.your-banner {
  min-height: 115px;
}

.your-content {
  background-size: auto 50%;
  background: repeat center center;
}

.your-promo-link {
  min-height: 55px !important;
}

.body-background {
    max-width: 1300px;
  background: rgb(255, 255, 255, 0.95);
}

.movie-background {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
</style>
