<template>
    <v-row
        v-if="!editMode"
        class="mb-5 mt-7"
    >
        <v-col>
            <v-carousel
                cycle
                show-arrows-on-hover
                hide-delimiters
            >
                <template v-slot:prev="{ on, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        x-large
                        icon
                        text
                    >
                        <v-icon color="primary">mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        x-large
                        icon
                        text
                    >
                        <v-icon color="primary">mdi-chevron-right</v-icon>
                    </v-btn>
                </template>
                <template v-if="content.contents && content.contents.length">
                    <testimonial
                        v-for="(subcontents, index) in content.contents"
                        :key="index"
                        :content="subcontents"
                    />
                </template>
            </v-carousel>
        </v-col>
    </v-row>
    <v-row
        v-else
        class="mb-5"
    >
        <v-col>
            <testimonial
                v-for="(subcontents, index) in content.contents"
                :key="index"
                :content="subcontents"
            />
            <add-component
                :parent-id="content.id"
                parent-type="WebsiteContent"
                :parent-component="content.website_component_id"
                button-text="Add Testimonial"
            />
        </v-col>
    </v-row>
</template>

<script>
import Testimonial from "./Testimonial";
import AddComponent from "../../menus/AddComponent";

export default {
    name: "Testimonials",
    props: {
        content: {
            type: Object,
        },
    },
    components: {
        Testimonial,
        AddComponent,
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
    },
    data() {
        return {
            loading: false,
            currentCarousel: null,
        };
    },
    methods: {
        //addCOntent
    },
};
</script>

<style scoped>
::v-deep .v-window__prev {
    background: rgba(0, 0, 0, 0);
}

::v-deep .v-window__next {
    background: rgba(0, 0, 0, 0);
}</style>
