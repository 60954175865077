<template>
    <div :class="isMobile ? 'mx-6 mt-n5' : 'ml-2 mt-n5'">
        <v-col md="6">
            <div class="main-header">Monthly Donations</div>
            <p class="mt-5">
                Monthly donations are a great way to support the mission of
                Arizona Private School Tuition Organization. You can set up a
                monthly donation by clicking the button below. You will be
                prompted to enter your credit card information and the amount you
                would like to donate each month. You can cancel your monthly
                donation at any time by selecting "Stop Monthly Contributions".
            </p>
            <p class="mt-5">
                Please visit our <a href="/monthly-contributions">Monthly Contributions</a> page for more information.
            </p>
            <v-btn
                :block="!!isMobile"
                :to="{ name: 'donor' }"
                class="cancel-button mt-5 font-weight-bold"
                color="secondary"
                rounded
            >
                Back
            </v-btn>
            <v-btn
                :block="!!isMobile"
                :class="!!isMobile ? 'mt-3' : 'ml-2 mt-5'"
                :to="{ name: 'donor-donations-subscription-list' }"
                class="font-weight-bold"
                color="primary"
                rounded
            >
                Start Monthly Contributions
            </v-btn>
        </v-col>
    </div>
</template>

<script>
import DonorHeader from "../../../components/donors/DonorHeader.vue";

export default {
    name: "DonorSubscriptionExplainer",
    components: {
        DonorHeader,
    },
    data() {
        return {
            showInfo: false,
        };
    },
    computed: {
        donor() {
            return this.$store.getters.currentDonor;
        },
        user() {
            return this.$store.getters.currentUser;
        },
    },
};
</script>

<style scoped>
.main-header {
    color: var(--apsto-purple);
    font-size: 24px;
    font-weight: bold;
    padding-left: 0;
    padding-top: 0;
}

.container-middle {
    min-height: 100px;
    padding-bottom: 10px;
}

.v-card__actions {
    padding-left: 0 !important;
}
</style>
