var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('loading'):_c('div',[(_vm.household)?_c('v-row',[_c('v-col',[_c('household-header',{attrs:{"page-title":_vm.pageTitle}})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"mx-0 mt-n6"},[_c('v-col',{attrs:{"md":"8"}},[(_vm.isStatusIncomplete)?_c('v-card',{staticClass:"mr-5 mt-3"},[_c('v-card-text',{staticClass:"font-mulish",staticStyle:{"color":"var(--apsto-black)","font-size":"1.05rem"}},[_vm._v("\n                    Please note any items that are\n                    "),_c('span',{staticClass:"missing-data"},[_vm._v("highlighted in red")]),_vm._v("\n                    then click the Edit Application button at the bottom to make changes.\n                    "),_c('div',{staticClass:"mt-5"},[_vm._v("\n                        Your application will be automatically submitted for review when all highlighted items are\n                        cleared.\n                    ")])])],1):_vm._e(),_vm._v(" "),(!_vm.isStatusIncomplete)?_c('v-card-actions',{staticClass:"pb-4 mt-2"},[_c('v-btn',{staticClass:"cancel-button ml-n1 mr-2",attrs:{"to":{
                        name: 'household-applications-list',
                        params: { household_id: this.$route.params.household_id, tab: 'applications' },
                    },"rounded":""}},[_vm._v("\n                    Cancel\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.editHouseholdApplication()}}},[_vm._v("\n                    Edit Application\n                ")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ml-n4 mt-0"},[_c('v-card-text',[_c('family-member-income-table')],1),_vm._v(" "),_c('v-card-title',[_vm._v("Financial Hardship")]),_vm._v(" "),_c('v-card-text',[_c('financial-hardship-section',{staticClass:"mt-4"})],1),_vm._v(" "),_c('v-card-title',[_vm._v("Student Questionnaire(s)")]),_vm._v(" "),_c('v-card-text',{staticClass:"mt-n4"},[_c('student-questionnaire',{attrs:{"school-year-name":_vm.schoolYearName,"students":_vm.householdApplicationStudents}})],1),_vm._v(" "),_c('documents-approve-decline'),_vm._v(" "),_c('v-card-title',[_vm._v("\n                    Scholarships\n                    "),_c('span',{staticClass:"ml-2",staticStyle:{"font-size":"1.015rem"}},[_vm._v("\n                        Click green chevron ("),_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-chevron-down")]),_vm._v(") to see scholarship\n                        details\n                    ")],1)]),_vm._v(" "),_c('v-card-text',[_c('scholarships')],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-title',{},[_vm._v("Affidavit")]),_vm._v(" "),_c('v-card-text',{staticStyle:{"font-size":"1.05rem"}},[(_vm.householdApplication.agree_to_terms)?_c('div',{staticClass:"mt-2"},[_vm._v("\n                        "+_vm._s(_vm.householdApplication.responsible_party_name)+" agreed to the terms.\n                    ")]):_c('div',{staticClass:"missing-data mt-2"},[_vm._v("\n                        Terms have not been agreed to.\n                    ")])])],1),_vm._v(" "),_c('v-card-actions',{staticClass:"pb-4 mt-2"},[_c('v-btn',{staticClass:"cancel-button ml-n1 mr-2",attrs:{"to":{
                        name: 'household-applications-list',
                        params: { household_id: this.$route.params.household_id, tab: 'applications' },
                    },"rounded":""}},[_vm._v("\n                    Cancel\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.editHouseholdApplication()}}},[_vm._v("\n                    Edit Application\n                ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }