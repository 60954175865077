<template>
    <div class="mr-5">
        <v-row align="center" class="ml-1 mt-5">
            <v-col md="6">
                <admin-header page-title="Schools"/>
            </v-col>
        </v-row>
        <v-spacer/>
        <v-data-table
            :footer-props="{ itemsPerPageOptions:[10,20,30] }"
            :headers="headers"
            :items="schools"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :page.sync="page"
            :search="search"
            :server-items-length="serverItemsLength"
            :single-select="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-2 mt-3"
            item-key="id"
            @pagination="getSchools"
            @click:row="editSchool"
            @update:sort-by="getSchools"
            @update:sort-desc="getSchools"
        >
            <template v-slot:top>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            v-model="search"
                            background-color="white"
                            class="mx-4"
                            label="Search on anything"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
        <span class="text-apsto-teal">
          There are no schools on file.
        </span>
            </template>
            <template #item.unlinked_donor_selection_students_count={item}>
                <div v-if="item.unlinked_donor_selection_students_count" class="text-apsto-teal">
                    {{ item.unlinked_donor_selection_students_count | formatNumber }}
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import AdminHeader from "../../main/AdminHeader";
import Loading from "../../../../components/shared/Loading";
import {GET_ADMIN_SCHOOLS} from "../../../../store/actions.type";

export default {
    name: "AdminSchoolsList",
    components: {
        AdminHeader,
        Loading
    },
    data() {
        return {
            headers: [
                {text: 'Name', align: 'start', value: 'name'},
                {text: 'Unlinked Recommendations', value: 'unlinked_donor_selection_students_count', align: 'end'},
                {text: 'Status', value: 'status'},
                {text: 'Affiliation', value: 'affiliation_name'},
            ],
            itemsPerPage: 30,
            loading: true,
            page: 1,
            pageCount: 0,
            search: "",
            serverItemsLength: 0,
            sortBy: 'unlinked_donor_selection_students_count',
            sortDesc: true,

        }
    },
    computed: {
        schools() {
            return this.$store.getters.currentAdminSchools['collection'];
        },
    },
    methods: {
        getSchools() {
            this.loading = true;
            let page = this.page;
            let itemsPerPage = this.itemsPerPage;
            let sortBy = this.sortBy;
            let sortDesc = this.sortDesc;
            let search = this.search;
            this.$store.dispatch(GET_ADMIN_SCHOOLS, {page, itemsPerPage, sortBy, sortDesc, search})
                .then((data) => {
                    this.serverItemsLength = data.totalRecords;
                    this.loading = false;
                });
        },
        editSchool(item) {
            this.$router.push(`schools/${item.id}/edit`);
        },
    },
    watch: {
        search() {
            this.page = 1;
            this.getSchools();
        }
    }
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
