<template>
    <v-sheet class="pa-5">
        <loading v-if="loading" />
        <div v-else>
            <div v-if="subscriptionDonationsInForce">
                <v-alert
                    class="mb-4"
                    color="secondary"
                    type="info"
                >
                    <div class="text-center">
                        <span class="font-weight-bold">
                            You already have a monthly donation set up. Please return to the previous page to delete it then
                            set up a new monthly donation if you'd like to change anything about it.
                        </span>
                        <br />
                        <v-btn
                            :to="{ name: 'donor-donations-subscription-list' }"
                            class="mt-4"
                            color="primary"
                            rounded
                        >
                            {{ isMobile ? 'Return' : 'Return to Monthly Donations' }}
                        </v-btn>
                    </div>
                </v-alert>
            </div>
            <v-form
                v-if="!subscriptionDonationsInForce"
                ref="donationForm"
                :disabled="saving"
            >
                <v-row class="ml-1 mt-0">
                    <v-col
                        class="pa-0"
                        cols="auto"
                    >
                        <h2>Start A Monthly Contribution</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        class="mt-n4 pl-4 pb-5"
                        md="6"
                    >
                        <div v-if="displayTaxYearOption">
                            <div
                                class="font-weight-black mt-4"
                                style="font-size: 1.125rem; line-height: 1.5"
                            >
                                Tax Year to Apply
                            </div>
                            <div class="mt-2">
                                Your donations will be applied to the
                                <span class="text-apsto-teal font-weight-black">
                                    {{ selectedTaxYear }}
                                </span>
                                tax year, however, you have the option to apply donations made
                                between 01/01/{{ selectedTaxYear }} - {{ displayDate(taxDay, true) }} to the
                                <span class="text-apsto-teal font-weight-black">
                                    {{ selectedTaxYear - 1 }}
                                </span>
                                tax year instead.
                            </div>
                            <v-checkbox
                                v-model="donation.apply_subscription_charges_to_prior_tax_year"
                                :error="!!errors['apply_subscription_charges_to_prior_tax_year']"
                                :error-messages="errors['apply_subscription_charges_to_prior_tax_year'] ? errors['apply_subscription_charges_to_prior_tax_year'] : null"
                                class="bottom-border"
                                color="secondary"
                            >
                                <template v-slot:label>
                                    <div style="line-height: 1.5">
                                        Apply donations made between 01/01/{{ selectedTaxYear }} -
                                        {{ displayDate(taxDay, true) }} to tax year
                                        <span class="text-apsto-teal font-weight-black">
                                            {{ selectedTaxYear - 1 }}.
                                        </span>
                                    </div>
                                </template>
                            </v-checkbox>
                        </div>

                        <filing-status
                            v-model="donation.tax_filing_status"
                            :selected-tax-year="selectedTaxYear"
                            class="bottom-border"
                        />

                        <prior-donations
                            v-if="this.derivedTaxYear === 'current'"
                            ref="priorDonations"
                            :selected-tax-year="selectedTaxYear"
                            class="bottom-border"
                            email=""
                        />

                        <div
                            class="pb-4 mt-4 font-weight-black theme--light v-label"
                            style="font-size: 1rem"
                        >
                            Select day you would like to make your monthly donation
                        </div>
                        <day-selector
                            v-model="donation.subscription_charge_day"
                            :value="15"
                        />


                        <div
                            class="pb-4 font-weight-black theme--light v-label"
                            style="font-size: 1rem"
                        >
                            Amount to Donate Each Month
                        </div>
                        <div v-if="donation.tax_filing_status">
                            <v-currency-field
                                id="amount"
                                v-model.number="donation.amount"
                                :error="!!errors['amount']"
                                :error-messages="errors['amount'] ? errors['amount'] : null"
                                :hint="'Enter the maximum amount you want us to withhold each month up to ' + formatCurrency(maxTotalPerMonth) + '.'"
                                :rules="[ruleMaxAmount || 'Amount cannot exceed ' + formatCurrency(maxTotalPerMonth) + ' (Tuition Credit Maximum shown above).']"
                                :success="!!ruleMaxAmount"
                                filled
                                label="Enter monthly subscription amount"
                                type="number"
                            />

                            <donation-amount-explainer
                                :derived-tax-year="derivedTaxYear"
                                :explainer="explainer"
                                called-from="donorSubscription"
                                class="mt-n2 pa-4"
                                style="border: 1px dashed var(--apsto-teal);"
                            />
                        </div>
                        <div
                            v-else
                            class="red--text"
                        >
                            Please select Tax Filing Status above before continuing.
                        </div>

                        <recommendations
                            v-model="donation.recommendations.original"
                            class="bottom-border"
                            tax-credit-type="original"
                        />

                        <recommendations
                            v-model="donation.recommendations.switcher"
                            class="bottom-border"
                            tax-credit-type="switcher"
                        />

                        <!--Payment Card To Charge-->
                        <h2 class="my-4 text-apsto-teal">Payment</h2>
                        <div
                            class="mt-0 font-weight-bold"
                            style="font-size: 1.2rem;"
                        >
                            Amount: {{ donation.amount | formatCurrency }}
                        </div>
                        <donation-subscription-payment
                            :donation="donation"
                            :has-open-items="hasOpenItems"
                            :number-of-payments="explainer.numberOfPayments"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </div>
    </v-sheet>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import { GET_DONOR_DONATION_TAX_YEAR_FACTORS, GET_DONOR_SUBSCRIPTION_DONATIONS, } from "../../../store/actions.type";
import SchoolSelector from "../../../components/shared/SchoolSelector";
import FilingStatus from "../../../components/donations/FilingStatus";
import DonationSubscriptionPayment from "../../../components/donationSubscriptions/DonationSubscriptionPayment";
import PriorDonations from "../../../components/donations/PriorDonations";
import Recommendations from "../../../components/donations/Recommendations";
import DaySelector from "../../../components/shared/DaySelector";
import { SET_DONATION } from "../../../store/mutations.type";
import DonationAmountExplainer from "../../../components/donations/DonationAmountExplainer";

export default {
    name: "DonationSubscriptionCreate",
    components: {
        DaySelector,
        DonationAmountExplainer,
        DonationSubscriptionPayment,
        FilingStatus,
        Loading,
        PriorDonations,
        Recommendations,
        SchoolSelector,
    },
    data() {
        return {
            loading: true,
            saving: false,
        };
    },
    computed: {
        derivedTaxYear() {
            return new Date().getMonth() + 1 === 12 ? "next" : "current";
        },
        displayTaxYearOption() {
            if (this.donation.tax_year === "next" && new Date().getMonth() + 1 === 12) {
                return true;
            } else if (this.donation.tax_year === "current" && new Date().getMonth() + 1 <= 4) {
                return true;
            }
            return false;
        },
        donation: {
            get() {
                return this.$store.getters.currentDonation;
            },
            set(donation) {
                this.$store.commit(SET_DONATION, donation);
            },
        },
        subscriptionDonationsInForce() {
            const taxYearForFilteredCall = this.derivedTaxYear === "current" ? new Date().getFullYear() : new Date().getFullYear() + 1;
            return !!this.$store.getters.currentActiveSubscriptionDonationsFiltered(taxYearForFilteredCall).length;
        },
        explainer() {
            return {
                maxOriginal: this.maxOriginal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
                maxSwitcher: this.maxSwitcher(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
                maxTotal: this.maxTotal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
                numberOfPayments: 0,
                selectedTaxYear: this.selectedTaxYear,
                subscriptionChargeDay: this.donation.subscription_charge_day,
                taxFilingStatus: this.donation.tax_filing_status,
                totalPriorDonations: this.totalPriorDonations,
            }
        },
        hasOpenItems() {
            return !this.donation.tax_filing_status ||
                !this.donation.amount ||
                this.donation.amount > this.maxTotalPerMonth
        },
        maxTotalPerMonth() {
            if (this.numberOfPayments === 0) {
                return 0;
            }
            return Math.floor(this.explainer.maxTotal / this.numberOfPayments);
        },
        numberOfPayments() {
            const today = new Date();

            let chargeDay = this.explainer.subscriptionChargeDay;
            let firstChargeDay = chargeDay < today.getDate() ? today.getDate() : chargeDay;
            let month = today.getMonth() + 1;
            let year = today.getFullYear();

            if (this.derivedTaxYear === "next") {
                month = 1;
                year++;
            }

            const startDate = new Date(year + "-" + month + "-" + firstChargeDay);
            const endDate = new Date(this.explainer.selectedTaxYear + "-12-" + chargeDay);

            const monthDifference = moment(new Date(endDate)).diff(new Date(startDate), 'months', true);

            this.explainer.numberOfPayments = Math.ceil(monthDifference + 1);

            return this.explainer.numberOfPayments;
        },
        ruleMaxAmount() {
            return this.donation.amount <= this.maxTotalPerMonth;
        },
        selectedTaxYear() {
            let tax_year_key = this.donation.tax_year + "_tax_year";
            return parseInt(this.taxYearFactors && this.taxYearFactors[tax_year_key].name)
        },
        taxDay() {
            return this.taxYearFactors.tax_day;
        },
        taxYearFactors() {
            return this.$store.getters.currentTaxYearFactors;
        },
        totalPriorDonations() {
            let priorDonationsArray = this.$store.getters.currentDonorPriorDonations(this.selectedTaxYear)
            let total = 0;
            for (let i = 0; i < priorDonationsArray.length; i++) {
                let amount = parseFloat(priorDonationsArray[i].amount);
                total = total + amount;
            }
            return parseInt(total);
        },
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
    },
    methods: {
        maxOriginal(taxFilingStatus) {
            let tax_year_key = this.donation.tax_year + "_tax_year";
            let tax_filing_status_key = "max_original_" + taxFilingStatus;
            let max_original = this.taxYearFactors[tax_year_key][tax_filing_status_key];
            return parseInt(max_original);
        },
        maxSwitcher(taxFilingStatus) {
            let tax_year_key = this.donation.tax_year + "_tax_year";
            let tax_filing_status_key = "max_switcher_" + taxFilingStatus;
            let max_switcher = this.taxYearFactors[tax_year_key][tax_filing_status_key];
            return parseInt(max_switcher);
        },
        maxTotal(taxFilingStatus) {
            return this.maxOriginal(taxFilingStatus) + this.maxSwitcher(taxFilingStatus) - this.totalPriorDonations;
        },
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch(GET_DONOR_SUBSCRIPTION_DONATIONS).then(() => {
            this.$store.dispatch(GET_DONOR_DONATION_TAX_YEAR_FACTORS).then(() => {
                //Set donation to a blank version
                this.$nextTick(() => {
                    this.$store.commit(SET_DONATION, {
                        amount: 0,
                        agree_to_terms: false,
                        apply_subscription_charges_to_prior_tax_year: false,
                        tax_filing_status: "",
                        tax_year: this.derivedTaxYear,
                        priorDonations: [],
                        recommendations: {
                            original: {
                                comments: null,
                                school_id: null,
                                students: [],
                            },
                            switcher: {
                                comments: null,
                                school_id: null,
                                students: [],
                            },
                        },
                    });
                    this.loading = false;
                });
            });
        });
    },
    watch: {
        amount(val) {
            this.$refs.donationForm.validate();
        },
        cardNumber(val) {
            this.$refs.donationForm.validate();
        },
        expiryMonth(val) {
            this.$refs.donationForm.validate();
        },
        expiryYear(val) {
            this.$refs.donationForm.validate();
        },
        cardCode(val) {
            this.$refs.donationForm.validate();
        },
        firstName(val) {
            this.$refs.donationForm.validate();
        },
        lastName(val) {
            this.$refs.donationForm.validate();
        },
        billingAddress1(val) {
            this.$refs.donationForm.validate();
        },
        billingCity(val) {
            this.$refs.donationForm.validate();
        },
        billingState(val) {
            this.$refs.donationForm.validate();
        },
        billingPostcode(val) {
            this.$refs.donationForm.validate();
        },
    },
};
</script>

<style scoped>
.bottom-border {
    border-bottom: 1px dotted;
}

::v-deep .v-toolbar__content {
    background-color: var(--apsto-purple-very-light);
}

::v-deep .v-label {
    color: black;
}
</style>
