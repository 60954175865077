//Views
import SchoolStudentEdit from "../views/schools/school/students/SchoolStudentEdit";
import SchoolPendingStudentAwards from "../views/schools/school/students/awards/SchoolPendingStudentAwards";
import SchoolStudentsInYear from "../views/schools/school/students/SchoolStudentsInYear.vue";
import SchoolStudentYearsTable from "../views/schools/school/students/SchoolStudentYearsTable.vue";
import SchoolStudentsInYearNonRenewed from "../views/schools/school/students/SchoolStudentsInYearNonRenewed.vue";

export default [
    {
        path: "/dashboard/schools/:school_id/students/year/:school_year_id",
        name: "school-year-students",
        component: SchoolStudentsInYear,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/students/year/:school_year_id/non-renewed",
        name: "school-year-students-non-renewed",
        component: SchoolStudentsInYearNonRenewed,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/year/:school_year_id/students/:application_student_school_id",
        name: "school-student-edit",
        component: SchoolStudentEdit,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'School Portal'
        },
        props: true
    },
    {
        path: "/dashboard/schools/:school_id/pending-awards",
        name: "school-pending-awards",
        component: SchoolPendingStudentAwards,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'School Portal'
        },
    },
    {
        path: "/dashboard/schools/:school_id/students/",
        name: "school-student-years-table",
        component: SchoolStudentYearsTable,
        meta: {
            requiresAuth: true,
            requiresSchoolAdmin: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'School Portal'
        },
    },
];
