<template>
  <v-card>
    <v-card-title>
      <span class="text-h5-mulish">
          Upload Document
      </span>
      <v-card-subtitle class="pa-0 pt-2 pb-4 text-apsto-teal">
        Please select year and document then click on the paperclip line.
      </v-card-subtitle>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="mt-2" cols="12">
          <v-form enctype="multipart/form-data">
            <v-card class="pa-4">
              <v-card-title class="pb-2 pl-0 pt-0">
                <v-radio-group
                  v-model="year"
                  :error="!!errors['year']"
                  :error-messages="errors['year'] ? errors['year'] : null"
                  class="bottom-border"
                  column
                >
                  <template v-slot:label>
                    <div class="pb-2 font-weight-black" style="font-size: 1rem">
                      For Calendar Year
                    </div>
                  </template>
                  <v-radio
                    :label="currentYear.toString()"
                    :value="currentYear"
                    color="secondary"
                  />
                  <v-radio
                    :label="nextYear.toString()"
                    :value="nextYear"
                    color="secondary"
                  />
                </v-radio-group>
              </v-card-title>
              <v-card-title class="pb-2 pl-0 pt-0">
                <site-document-selector v-model="slug"/>
              </v-card-title>
              <v-file-input
                v-model="file"
                :rules="rules"
                accept=".jpg,.jpeg,.pdf,.png"
                show-size
                truncate-length="15"
              />
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="py-4">
      <v-spacer/>
      <v-btn class="cancel-button" rounded @click="cancel"> Cancel</v-btn>
      <v-btn
        :disabled="saveDisabled"
        class="save-by-cancel-button"
        color="primary"
        rounded
        @click="save"
      > Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {GET_SITE_DOCUMENTS_BY_SLUGS, POST_SITE_DOCUMENT,} from "../../../store/actions.type";
import SiteDocumentSelector from "../../../components/shared/SiteDocumentSelector";

export default {
  name: "AdminSiteDocumentFileUpload",
  components: {SiteDocumentSelector},
  data: () => ({
    saving: false,
    file: null,
    rules: [
      value => !value || value.size < 6000000 || 'File size should be less than 6 MB!',
    ],
    showExplanation: false,
    slug: null,
    year: null
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    nextYear() {
      return new Date().getFullYear() + 1;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
    saveDisabled() {
      return !this.year || !this.slug || !this.file;
    }
  },
  methods: {
    getSiteDocuments() {
      this.$store.dispatch(GET_SITE_DOCUMENTS_BY_SLUGS);
    },
    cancel() {
      this.$emit("done");
    },

    save() {
      this.saving = true;
      let file = this.file;
      let site_document_slug = this.slug;
      let site_document_year = this.year;
      this.$store
        .dispatch(POST_SITE_DOCUMENT, {
          file,
          site_document_slug,
          site_document_year
        })
        .then(() => {
          location.reload();
        })
    },
  },
  mounted() {
    this.getSiteDocuments();
  }
};
</script>

<style scoped>
.errors-message {
  color: red;
  padding-left: 5px;
}
</style>
