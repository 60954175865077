<template>
    <div>
        <v-row no-gutters>
            <v-col>
                <billboard :page="currentPage" />
            </v-col>
        </v-row>
        <v-container
            :style="currentPage.show_toc ? 'max-width:1400px' : 'max-width:1800px'"
            class="mt-5"
        >
            <v-row>
                <v-col
                    v-if="currentPage.show_toc"
                    cols="12"
                    sm="auto"
                    class="px-10 mr-5"
                >
                    <div class="sticky-top">
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="primary--text text-uppercase font-mulish">
                                        {{ currentGroup.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list>
                            <v-list-item
                                v-for="page in currentGroup.pages"
                                :key="page.id"
                                :to="'/' + page.slug"
                            >
                                <v-list-item-content>
                                    <v-list-item-content
                                        v-text="page.name"
                                        class="text-uppercase font-mulish"
                                    />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                class="success"
                                v-if="editMode"
                                @click="openAddPageEditDialog(currentGroup.id)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold white--text font-mulish">
                                        <v-icon class="white--text">mdi-plus</v-icon>
                                        Add New
                                        Page
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-col>
                <v-col class="px-2 px-sm-5 px-md-10 pt-6">
                    <draggable
                        :list="currentPage.contents"
                        handle=".handle"
                        @end="onEnd"
                        :force-fallback="true"
                    >
                        <v-row
                            v-for="content in currentPage.contents"
                            v-bind:key="content.id"
                            no-gutters
                        >
                            <v-col>
                                <v-card
                                    v-if="editMode"
                                    class="pa-4 my-4"
                                >
                                    <v-row justify="space-between" class="pt-4">
                                        <v-col cols="4">
                                            Component: {{ content.component.name }}
                                        </v-col>
                                        <v-col cols="4" class="py-0">
                                            <v-text-field
                                                v-model="content.class"
                                                label="Class"
                                                outlined
                                                dense
                                                hide-details
                                                @change="updateContent(content)"
                                            />
                                        </v-col>

                                        <v-col cols="2" class="py-0">
                                            <v-text-field
                                                v-model="content.sort_order"
                                                label="Sort Order"
                                                outlined
                                                dense
                                                hide-details
                                                @change="updateContent(content)"
                                            />
                                        </v-col>

                                        <v-col cols="auto" class="py-0">
                                            <v-btn
                                                icon
                                                @click="deleteContent(content.id)"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto" class="py-0">
                                            <v-btn
                                                class="handle"
                                                icon
                                            >
                                                <v-icon>mdi-drag-vertical</v-icon>
                                            </v-btn>
                                        </v-col>

                                        <v-col cols="12">
                                            <component
                                                :is="content.component.component"
                                                :content="content"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-row v-else>
                                    <v-col :class="content.class">
                                        <component
                                            :is="content.component.component"
                                            :content="content"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </draggable>
                    <v-row
                        v-if="editMode"
                        no-gutters
                    >
                        <v-col>
                            <add-component
                                :parent-id="currentPage.id"
                                parent-type="WebsitePage"
                                :parent-component="1"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import draggable from "vuedraggable";

import { PATCH_CONTENT, DELETE_CONTENT, GET_CONTENTS, POST_SORT_ORDER } from "../../../store/actions.type";

import { SET_ADD_PAGE_DIALOG_STATUS, SET_ADD_PAGE_TO_GROUP_ID, } from "../../../store/mutations.type";

import AddComponent from "../menus/AddComponent";
import ApprovedSchools from "../components/Faq/ApprovedSchools";
import AuthForwarder from "../components/Faq/AuthForwarder";
import Billboard from "../components/Faq/Billboard";
import BrochureRequest from "../components/Faq/BrochureRequest";
import CalloutImages from "../components/Basic/CalloutImages"
import ContactUs from "../components/Faq/ContactUs";
import Expandable from "../components/Faq/Expandable";
import Heading from "../components/Faq/Heading";
import Infobox from "../components/Faq/Infobox";
import JFKQuote from "../components/Faq/JFKQuote";
import LoginRegister from "../components/Faq/LoginRegister";
import LowIncomeCorporateTaxCredit from "../components/Faq/LowIncomeCorporateTaxCredit";
import MonthlyContributions from "../components/Faq/MonthlyContributions";
import Paragraph from "../components/Faq/Paragraph";
import ParagraphImage from "../components/Faq/ParagraphImage";
import NumberedParagraph from "../components/Faq/NumberedParagraph";
import SiteDocument from "../components/Faq/SiteDocument";
import Spacer from "../components/Faq/Spacer";
import StudentAwardsDistribution from "../components/Faq/StudentAwardsDistribution";
import TaxFactors from "../components/Faq/TaxFactors";
import Testimonials from "../components/Faq/Testimonials";
import Video from "../components/Faq/Video";
import MatchingGifts from "../components/Faq/MatchingGifts";
import News from "../components/Faq/News";

export default {
    name: "Faq",
    components: {
        AddComponent,
        ApprovedSchools,
        AuthForwarder,
        Billboard,
        BrochureRequest,
        CalloutImages,
        ContactUs,
        draggable,
        Expandable,
        Heading,
        Infobox,
        JFKQuote,
        LoginRegister,
        LowIncomeCorporateTaxCredit,
        MonthlyContributions,
        Paragraph,
        ParagraphImage,
        NumberedParagraph,
        SiteDocument,
        Spacer,
        StudentAwardsDistribution,
        TaxFactors,
        Testimonials,
        Video,
        MatchingGifts,
        News
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
        currentGroup() {
            return this.$store.getters.currentGroup(this.$route.params.slug);
        },
        currentPage() {
            return this.$store.getters.currentPage(this.$route.params.slug);
        },
    },
    methods: {
        openAddPageEditDialog(group_id) {
            this.$store.commit(SET_ADD_PAGE_TO_GROUP_ID, group_id);
            this.$store.commit(SET_ADD_PAGE_DIALOG_STATUS, true);
        },
        updateContent(content) {
            this.loading = true;
            let self = this;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    self.loading = false;
                })
                .catch((e) => {
                    self.loading = false;
                });
            return;
        },
        deleteContent(content_id) {
            this.$store.dispatch(DELETE_CONTENT, content_id).then(() => {
                this.$store.dispatch(GET_CONTENTS);
            });
        },
        onEnd() {
            let new_content_id_order = [];

            this.currentPage.contents.forEach((content) => {
                new_content_id_order.push(content.id);
            });

            this.$store.dispatch(POST_SORT_ORDER, new_content_id_order);

        }
    },
};
</script>

<style scoped>
.sticky-top {
    position: sticky;
    top: 165px;
}

::v-deep .v-list-item--active::before {
    opacity: 0;
}

::v-deep .ql-size-small {
    font-size: 0.75em;
}

::v-deep .ql-size-large {
    font-size: 1.5em;
}

::v-deep .ql-size-huge {
    line-height: 1;
    font-size: 2em;
}

::v-deep .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 50px;
}

::v-deep .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 100px;
}

::v-deep .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 150px;
}

::v-deep h4 {
    padding-bottom: 0.5rem !important;
}

::v-deep strong {
    font-weight: 700 !important;
}

::v-deep a {
    color: var(--apsto-teal) !important;
}

::v-deep .v-list-item--active {
    background-color: var(--apsto-purple);
    color: white !important;
    font-weight: 700;
}

::v-deep ol {
    counter-reset: li;
    /* Initiate a counter */
    margin-left: 0;
    /* Remove the default left margin */
    padding-left: 0;
    /* Remove the default left padding */

    margin-top: 12px;
    margin-bottom: -12px;
}

::v-deep ol>li {
    position: relative;
    /* Create a positioning context */
    margin: 0 0 24px 2em;
    /* Give each list item a left margin to make room for the numbers */
    padding: 0px 5px 4px 12px;
    list-style: none;
    /* Disable the normal item numbering */
}

::v-deep ol>li:before {
    content: counter(li);
    /* Use the counter as content */
    counter-increment: li;
    /* Increment the counter by 1 */
    /* Position and style the number */
    position: absolute;
    top: -5px;
    left: -2em;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 2em;
    /* Some space between the number and the content in browsers that support
       generated content but not positioning it (Camino 2 is one example) */
    margin-right: 12px;
    padding: 4px;
    border-top: 2px solid var(--apsto-purple);
    color: #fff;
    background: var(--apsto-purple);
    font-weight: bold;
    font-family: "Helvetica Neue", Arial, sans-serif;
    text-align: center;
    border-radius: 100%;
}

::v-deep li ol,
::v-deep li ul {
    margin-top: 6px;
}

::v-deep ol ol li:last-child {
    margin-bottom: 0;
}

::v-deep .v-list-item__title {
    font-family: 'Mulish', sans-serif !important;
    font-size: 1.1rem;
    font-weight: 800;
    text-decoration: none;
}

::v-deep .dom-mark-purplecircle {
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 2.1em;
    height: 2.1em;
    padding: 4px;
    margin-right:8px;
    border-top: 2px solid var(--apsto-purple);
    color: #fff;
    background: var(--apsto-purple);
    font-weight: bold;
    font-family: "Helvetica Neue", Arial, sans-serif;
    text-align: center;
    border-radius: 100%;
    font-size: 17px
}
</style>
