<template>
    <v-row
        align="center"
        v-if="enumerator"
    >
        <v-col class="menu-number">
            {{ enumerator }}
        </v-col>
        <router-link
            :to="{ name: to, params: { param: param } }"
            class="menu-title"
            :style="'background-color: ' + titleBackgroundColor"
        >
            {{ title }}
        </router-link>
    </v-row>
</template>

<script>
export default {
    name: "MenuItem",
    props: {
        enumerator: {
            type: String,
            required: false,
        },
        param: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        titleBackgroundColor: {
            type: String,
            required: false,
            default: "#FFFFFF"
        },
        to: {
            type: String,
            required: true,
        },
    }
}
</script>

<style scoped>
.menu-number {
    background-color: var(--apsto-teal);
    color: white;
    font-weight: 700;
    width: 10%;
    padding: 12px 0;
    text-align: center;
}

.menu-title {
    border: 1px solid var(--apsto-teal);
    color: black;
    font-size: 14px;
    font-weight: normal;
    width: 90%;
    padding: 12px 20px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
}

.menu-title:hover {
    background-color: var(--apsto-purple) !important;
    color: white;
}</style>
