<template>
    <loading v-if="loading" />
    <div
        v-else
        class="ml-0"
    >
        <v-row>
            <v-col>
                <div class="text-h6-mulish">
                    Tax Credit Donation Amounts for
                    <span class="text-apsto-teal">
                        {{ selectedTaxYear }}
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-n2">
            <v-col>
                <v-simple-table v-if="!isMobile">
                    <thead>
                        <tr>
                            <th style="text-align: left;">Tuition Tax Credits</th>
                            <th>Original Credit Maximum</th>
                            <th>Switcher Credit Maximum</th>
                            <th>Tuition Credit Maximum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="taxFilingStatus === 'joint'">
                            <td style="text-align: left;">Married Jointly</td>
                            <td>{{ taxFactors['Married Jointly'].originalLimit | formatCurrency }}</td>
                            <td>{{ taxFactors['Married Jointly'].overflowLimit | formatCurrency }}</td>
                            <td>{{ taxFactors['Married Jointly'].combinedLimit | formatCurrency }}</td>
                        </tr>
                        <tr v-else>
                            <td style="text-align: left;">Individual</td>
                            <td>{{ taxFactors['Individual'].originalLimit | formatCurrency }}</td>
                            <td>{{ taxFactors['Individual'].overflowLimit | formatCurrency }}</td>
                            <td>{{ taxFactors['Individual'].combinedLimit | formatCurrency }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-simple-table v-if="isMobile">
                    <thead>
                        <tr>
                            <th style="text-align: left;">Tuition Tax Credits</th>
                            <th v-if="taxFilingStatus === 'joint'">Married Jointly</th>
                            <th v-else>Individual</th>
                        </tr>
                    </thead>
                    <tbody v-if="taxFilingStatus === 'joint'">
                        <tr>
                            <td style="text-align: left;">Original Credit Maximum</td>
                            <td>{{ taxFactors['Married Jointly'].originalLimit | formatCurrency }}</td>
                        </tr>
                        <tr>
                            <td style="text-align: left;">Switcher Credit Maximum</td>
                            <td>{{ taxFactors['Married Jointly'].overflowLimit | formatCurrency }}</td>
                        </tr>
                        <tr>
                            <td style="text-align: left;">Tuition Credit Maximum</td>
                            <td>{{ taxFactors['Married Jointly'].combinedLimit | formatCurrency }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td style="text-align: left;">Original Credit Maximum</td>
                            <td>{{ taxFactors['Individual'].originalLimit | formatCurrency }}</td>
                        </tr>
                        <tr>
                            <td style="text-align: left;">Switcher Credit Maximum</td>
                            <td>{{ taxFactors['Individual'].overflowLimit | formatCurrency }}</td>
                        </tr>
                        <tr>
                            <td style="text-align: left;">Tuition Credit Maximum</td>
                            <td>{{ taxFactors['Individual'].combinedLimit | formatCurrency }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ApiService from "../../api/api.service";
import Loading from "../shared/Loading";

export default {
    name: "TaxFactorsForYear",
    components: {
        Loading,
    },
    props: {
        content: {
            type: Object,
        },
        selectedTaxYear: {
            type: Number,
            required: true,
        },
        taxFilingStatus: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            taxFactors: null,
            loading: true
        };
    },
    methods: {
        getTaxInfo() {
            this.loading = true;
            let selectedTaxYear = this.selectedTaxYear;
            ApiService.get(`/api/website/data/tax-factors/${selectedTaxYear}`)
                .then((response) => {
                    this.taxFactors = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log("TAX FACTORS ERROR");
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.getTaxInfo();
    },
    watch: {
        selectedTaxYear() {
            this.getTaxInfo();
        },
        taxFilingStatus() {
            this.getTaxInfo();
        }
    }
};
</script>

<style scoped>
th,
td {
    padding: 20px;
    text-align: center !important;
}

th {
    background-color: var(--apsto-purple) !important;
    color: white !important;
    font-size: .875rem !important;
}

td {
    border: 1px solid var(--apsto-border);
}
</style>
