<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Edit Salesperson Assignment"/>
        <v-form :disabled="saving">
          <salesperson-assignment-form
            :salesperson="salesperson"
            :salesperson-assignment="salespersonAssignment"
          />
          <v-btn
            :to="{ name: 'admin-salesperson-assignments-list', params: { salesperson_id: salesperson.id } }"
            class="cancel-button"
            rounded
          >
            Cancel
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            rounded
            @click="submit"
          > Save
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../../main/AdminHeader";
import ConfirmationDialog from "../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../components/shared/Loading";
import SalespersonAssignmentForm from "../../../../components/admin/salespeople/salesperson/SalespersonAssignmentForm";
import {GET_SALESPERSON_ASSIGNMENT, PATCH_SALESPERSON_ASSIGNMENT} from "../../../../store/actions.type";

export default {
  name: "AdminSalespersonAssignmentEdit",
  components: {
    AdminHeader,
    ConfirmationDialog,
    Loading,
    SalespersonAssignmentForm
  },
  data() {
    return {
      loading: true,
      saving: false
    }
  },
  computed: {
    salesperson() {
      return this.$store.getters.currentSalesperson;
    },
    salespersonAssignment() {
      let assignment_type = this.$route.params.assignment_type;
      let salespersonAssignment = this.$store.getters.currentSalespersonAssignment;

      salespersonAssignment.backdate = false;
      if (assignment_type === "Corporate") {
        salespersonAssignment.company_id = salespersonAssignment.id;
        salespersonAssignment.user_id = 0;
        salespersonAssignment.type = "Corporate";
      } else {
        salespersonAssignment.company_id = 0;
        salespersonAssignment.user_id = salespersonAssignment.id;
        salespersonAssignment.type = "Individual";
      }
      return salespersonAssignment;
    }
  },
  methods: {
    edit(item) {
      this.$router.push(`assignments/${item.id}`);
    },
    getSalespersonAssignment() {
      this.loading = true;
      let salesperson_id = this.$route.params.salesperson_id;
      let assignment_id = this.$route.params.assignment_id;
      let assignment_type = this.$route.params.assignment_type;
      this.$store.dispatch(GET_SALESPERSON_ASSIGNMENT, {salesperson_id, assignment_id, assignment_type})
        .then(() => {
          this.loading = false;
        });
    },
    submit() {
      this.saving = true;
      let salesperson_id = this.$route.params.salesperson_id;
      let salespersonAssignment = this.salespersonAssignment;
      this.$store.dispatch(PATCH_SALESPERSON_ASSIGNMENT, {salesperson_id, salespersonAssignment})
        .then(() => {
          this.saving = false;
          this.$router.push({
            name: 'admin-salesperson-assignments-list',
            params: {salesperson_id: salesperson_id}
          })
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    }
  },
  mounted() {
    this.getSalespersonAssignment();
    this.loading = false;
  },
}
</script>

<style scoped></style>
