<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row
            class="ml-1 pb-5"
            align="center"
        >
            <v-col md="6">
                <admin-header page-title="Messaging" />
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6">

            </v-col>
        </v-row>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";

export default {
    name: "AdminMessaging",
    components: {
        AdminHeader
    },
    data() {
        return {
            loading: false
        }
    },
}
</script>

<style scoped></style>
