<template>
    <v-row
        align="center"
        justify="center"
        class="my-8"
    >
        <v-col
            class="text-center"
            md="8"
            lg="5"
        >
            <div
                v-resize-text="{ ratio: 1.8 }"
                style="line-height: 1;"
                class="font-weight-black primary--text"
            >
                {{ text }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Title",
    props: {
        content: {
            type: Object,
        },
    },
    computed: {
        text() {
            return this.content.body.text;
        },
    },
};
</script>
