<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center">
      <v-col md="6">
        <admin-header
          :second-line="'For the period from ' + $route.params.date_from + ' through ' + $route.params.date_thru"
          page-title="Fund Transactions Report"
        />
      </v-col>
      <v-spacer/>
      <v-col cols="auto">
        <v-btn
          :href="'/api/admin/funds/download-transactions/' + $route.params.date_from + '/' + $route.params.date_thru"
          color="primary"
          rounded
        >
          <v-icon left> mdi-download</v-icon>
          Download This List
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
      :headers="headers"
      :items="transactions"
      :search="search"
      :single-select="true"
      class="elevation-2 mt-3"
      fixed-header
      group-by="tax_year"
      group-desc
      height="100vh"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th colspan="10">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          Tax Year: {{ items[0].tax_year }}
        </th>
      </template>
      <template v-slot:body.prepend>
        <tr
          v-for="(taxYear, index) in totalsByTaxYear"
          :key="index"
          class="totals"
        >
          <th colspan="4">Totals for Tax Year {{ taxYear.taxYear }}</th>
          <th class="text-right">{{ taxYear.amount | formatCurrencyWithCents }}</th>
          <th></th>
          <th class="text-right">{{ taxYear.feeAmount | formatCurrencyWithCents }}</th>
          <th></th>
          <th class="text-right">{{ taxYear.salespersonAmount | formatCurrencyWithCents }}</th>
          <th class="text-right">{{ taxYear.netAmount | formatCurrencyWithCents }}</th>
        </tr>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There were no transactions during the date span you selected.
                </span>
      </template>
      <template #item.amount="{ item }">
        {{ item.amount | formatNumberWithCents }}
      </template>
      <template #item.donation_effective_date="{ item }">
        {{ displayDate(item.donation_effective_date) }}
      </template>
      <template #item.fee_percentage="{ item }">
        {{ item.fee_percentage | formatNumber }}%
      </template>
      <template #item.fee_amount="{ item }">
        {{ item.fee_amount | formatNumberWithCents }}
      </template>
      <template #item.salesperson_percentage="{ item }">
        <div v-if="item.salesperson_percentage">
          {{ item.salesperson_percentage | formatNumber }}%
        </div>
      </template>
      <template #item.salesperson_amount="{ item }">
        <div v-if="item.salesperson_amount">
          {{ item.salesperson_amount | formatNumberWithCents }}
        </div>
      </template>
      <template #item.net_amount="{ item }">
        {{ item.net_amount | formatNumberWithCents }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {GET_FUND_TRANSACTIONS} from "../../../store/actions.type";

export default {
  name: "AdminFundsActivityReport",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        name: '',
        commission_rate: '',
        created_at: ''
      },
      dialog: false,
      headers: [
        {text: 'Tax Year', align: 'start', value: 'tax_year'},
        {text: 'Date', align: 'start', value: 'donation_effective_date'},
        {text: 'Type', value: 'donation_type'},
        {text: 'Name', value: 'donor_name'},
        {text: 'Program', value: 'tax_credit_name'},
        {text: 'Amount', align: 'end', value: 'amount'},
        {text: 'Fee %', value: 'fee_percentage', align: 'end'},
        {text: 'Fee $', value: 'fee_amount', align: 'end'},
        {text: 'Commission %', value: 'salesperson_percentage', align: 'end'},
        {text: 'Commission $', value: 'salesperson_amount', align: 'end'},
        {text: 'NET', align: 'end', value: 'net_amount'},
      ],
      loading: true,
      search: ''
    }
  },
  computed: {
    totalsByTaxYear() {
      return this.$store.getters.currentFundTransactions['totalsByTaxYear'];
    },
    transactions() {
      return this.$store.getters.currentFundTransactions['transactions'];
    },
  },
  methods: {
    getFundTransactions() {
      let date_from = this.$route.params.date_from;
      let date_thru = this.$route.params.date_thru;
      this.$store.dispatch(GET_FUND_TRANSACTIONS, {date_from, date_thru}).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getFundTransactions();
  }
}
</script>


<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.totals) {
  background-color: transparent;
}

::v-deep tr.totals {
  background-color: var(--apsto-teal) !important;
}

::v-deep tr.totals > th {
  color: white;
  font-size: .90rem !important;
}</style>
