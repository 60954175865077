<template>
    <loading v-if="loading" />
    <v-data-table
        v-else
        :disable-sort="true"
        :headers="headers"
        :hide-default-footer="true"
        :items="fundBalances"
        @click:row="chooseTaxCreditType"
    >
        <template v-slot:top>
            <v-row>
                <v-col style="text-align: right;">
                    <div class="rec-header">RECOMMENDATIONS</div>
                </v-col>
            </v-row>
        </template>
        <template #item.name="{ item, index }">
            <div :class="index + 1 === studentAward.tax_credit_type ? 'highlight' : ''">
                {{ item.name }}
            </div>
        </template>
        <template #item.balance_fund="{ item, index }">
            <div
                v-if="index === 0"
                :class="index + 1 === studentAward.tax_credit_type ? 'highlight' : ''"
            >
                {{ item.balance_fund | formatCurrency }}
            </div>
            <div
                v-else
                :class="index + 1 === studentAward.tax_credit_type ? 'highlight' : ''"
            >
                {{ item.balance_fund | formatNumber }}
            </div>
        </template>
        <template #item.balance_school="{ item, index }">
            <div
                v-if="index === 0"
                :class="index + 1 === studentAward.tax_credit_type ? 'highlight' : ''"
            >
                {{ item.balance_school | formatCurrency }}
            </div>
            <div
                v-else
                :class="index + 1 === studentAward.tax_credit_type ? 'highlight' : ''"
            >
                {{ item.balance_school | formatNumber }}
            </div>
        </template>
        <template #item.balance_student="{ item, index }">
            <div
                v-if="index === 0"
                :class="index + 1 === studentAward.tax_credit_type ? 'highlight' : ''"
            >
                {{ item.balance_student | formatCurrency }}
            </div>
            <div
                v-else
                :class="index + 1 === studentAward.tax_credit_type ? 'highlight' : ''"
            >
                {{ item.balance_student | formatNumber }}
            </div>
        </template>
    </v-data-table>
</template>

<script>
import { GET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES } from "../../../../../store/actions.type";
import Loading from "../../../../../components/shared/Loading.vue";
import Recommendations from "../../../../../components/donations/Recommendations.vue";

export default {
    name: "AdminSchoolStudentAwardBalances",
    components: {
        Recommendations,
        Loading
    },
    data() {
        return {
            headers: [
                { text: "Credit", value: "name" },
                { text: "Funds", value: "balance_fund", align: "right" },
                { text: "School", value: "balance_school", align: "right" },
                { text: "Student", value: "balance_student", align: "right" },
            ],
            loading: false
        }
    },
    computed: {
        fundBalances() {
            return this.$store.getters.currentAdminSchoolStudentAwardBalances;
        },
        studentAward: {
            get() {
                return this.$store.getters.currentApplicationStudentAward;
            },
            set(value) {
                this.$store.commit(SET_SCHOOL_STUDENT_AWARD, value);
            }
        }
    },
    methods: {
        getBalances() {
            this.loading = false;
            let fiscal_year_id = this.studentAward.fiscal_year_id;
            let school_id = this.$route.params.school_id;
            let application_student_school_id = this.$route.params.application_student_school_id ?? 0;
            this.$store.dispatch(GET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES, {
                school_id,
                fiscal_year_id,
                application_student_school_id
            })
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        chooseTaxCreditType(row) {
            console.log(row);
            this.studentAward.tax_credit_type = row.id;
        }
    },
    mounted() {
        bus.$on('regetAwardBalances', () => {
            this.getBalances();
        })
        this.getBalances();
    }
}
</script>

<style scoped>
.highlight {
    color: var(--apsto-teal);
    font-weight: bold;
}

.rec-header {
    color: var(--apsto-teal);
    font-weight: bold;
    padding: 10px 20px 0 0;
}

.v-data-table {
    border: 1px solid var(--apsto-teal-light);
}
</style>
