<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header :page-title="'Create Salesperson Assignment for ' + salesperson.name"/>
        <div
          class="pt-5"
          elevation="2"
        >
          <v-form :disabled="saving">
            <salesperson-assignment-form
              :salesperson="salesperson"
              :salesperson-assignment="salespersonAssignment"
            />
            <v-btn
              :to="{ name: 'admin-salesperson-assignments-list', params: { salesperson_id: salesperson.id } }"
              class="cancel-button"
              rounded
            >
              Cancel
            </v-btn>
            <v-btn
              class="ml-2"
              color="primary"
              rounded
              @click="submit"
            >
              Save
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../../main/AdminHeader";
import Loading from "../../../../components/shared/Loading";
import SalespersonAssignmentForm from "../../../../components/admin/salespeople/salesperson/SalespersonAssignmentForm";
import {GET_SALESPERSON, POST_SALESPERSON_ASSIGNMENT} from "../../../../store/actions.type";

export default {
  name: "AdminSalespersonAssignmentCreate",
  components: {
    AdminHeader,
    Loading,
    SalespersonAssignmentForm
  },
  data() {
    return {
      salespersonAssignment: {
        backdate: false,
        commission_rate: null,
        company_id: 0,
        type: "Corporate",
        user_id: 0
      },
      loading: true,
      saving: false
    };
  },
  computed: {
    salesperson() {
      let salesperson = this.$store.getters.currentSalesperson;
      this.salespersonAssignment.commission_rate = salesperson.commission_rate;
      return salesperson;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getSalesperson() {
      let salesperson_id = this.$route.params.salesperson_id;
      this.$store.dispatch(GET_SALESPERSON, salesperson_id)
    },
    submit() {
      this.saving = true;
      let salespersonAssignment = this.salespersonAssignment;
      let salesperson_id = this.$route.params.salesperson_id;
      salespersonAssignment.salesperson_id = salesperson_id; // Need for form request validation
      this.$store.dispatch(POST_SALESPERSON_ASSIGNMENT, {salesperson_id, salespersonAssignment})
        .then(() => {
          this.saving = false;
          this.$router.push({
            name: 'admin-salesperson-assignments-list',
            params: {salesperson_id: salesperson_id}
          })
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.getSalesperson();
    this.loading = false;
  },

}
</script>

<style scoped></style>
