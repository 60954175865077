/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from "vue-router";
import VueResizeText from 'vue-resize-text';
import Vuetify from "vuetify";
import moment from 'moment-timezone';
import {extendMoment} from 'moment-range';
import VCurrencyField from "v-currency-field";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import Hotjar from 'vue-hotjar';
import {VueReCaptcha} from 'vue-recaptcha-v3'
import VueSimpleMarkdown from 'vue-simple-markdown'

/**
 * Load Vue Configs (Store, Router)
 */
import store from './store';
import router from './router';

/**
 * Load Main Component
 */
import App from "./layouts/App";
import ApiService from './api/api.service';

/**
 * Custom Icons
 */
import PdfIcon from './components/shared/icons/PdfIcon';


/**
 * Register Plugins
 */
window.Vuex = Vuex;
window.VueRouter = VueRouter;
window.Vuetify = Vuetify;

//Moment.js init
window.moment = extendMoment(moment);
moment().format();

Vue.use(VueResizeText);

Vue.use(VCurrencyField, {
    defaultValue: 0,
    decimalLength: 0,
    valueAsInteger: true,
    allowNegative: false
});

Vue.use(VuetifyGoogleAutocomplete, {
    apiKey: 'AIzaSyBIXO6X5bSEMrPMsM7rt_fpodH5EN_4Wb4'
});

Vue.use(Vuetify);

Vue.use(Hotjar, {
    id: process.env.MIX_HOTJAR_ID,
});

Vue.use(VueReCaptcha, {
    siteKey: process.env.MIX_RECAPTCHA_SITE_KEY,
    loaderOptions: {
        badge: 'bottomleft',
    },
})

Vue.component('InfiniteLoading', require('vue-infinite-loading').default);

Vue.use(VueSimpleMarkdown);

ApiService.init();

/**
 * Register Mixins
 */

Vue.mixin({
    data() {
        return {
            //environmentVariableExample: process.env.MIX_ENVIRONMENT_VARIABLE === "true",
            appUrl: process.env.MIX_APP_URL,
        };
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        dialogMaxWidth() {
            return this.isMobile ? null : '600px';
        },
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
    },
    methods: {
        handle_function_call(function_name) {
            this[function_name]()
        },
        checkPasswordStrength(password) {
            if (password.length >= 8) {
                return {
                    strength: 100
                }
            }
            return {
                strength: 0
            };
            // Test for:
            // 1. Uppercase Alphabet
            // 2. Lowercase Alphabet
            // 3. Numeric Digits
            // 4. Special Characters
            // 5. Length

            //Regular Expressions
            let regex = [];
            regex.push("[A-Z]"); //For Uppercase Alphabet
            regex.push("[a-z]"); //For Lowercase Alphabet
            regex.push("[A-Z]"); //For Numeric Digits
            regex.push("[$@$!%*#?&]"); //For Special Characters

            let passed = 0;

            //Validation for each Regular Expression
            for (let i = 0; i < regex.length; i++) {
                if ((new RegExp(regex[i])).test(password)) {
                    passed++;
                }
            }

            //Validation for Length of Password
            if (passed > 2 && password.length >= 8) {
                passed++;
            }

            return {
                strength: passed / 5 * 100,
                tests: [{
                    passed: new RegExp("[a-z]").test(password),
                    message: "At least one lowercase letter"
                },
                    {
                        passed: new RegExp("[A-Z]").test(password),
                        message: "At least one capital letter"
                    },
                    {
                        passed: new RegExp("[0-9]").test(password),
                        message: "At least one number"
                    },
                    {
                        passed: new RegExp("[$@$!%*#?&]").test(password),
                        message: "At least one special character"
                    },
                    {
                        passed: password.length >= 8,
                        message: "Be at least 8 characters"
                    }
                ]
            };
        },
        searchArrayByKey(key, list, field = 'id', seeking = null) {
            for (var i = 0; i < list.length; i++) {
                if (list[i][field] === key) {
                    if (seeking === 'object') {
                        return list[i];
                    } else if (seeking === 'name') {
                        return list[i].name;
                    } else {
                        return i;
                    }

                }
            }
        },
        convertToSlug(text) {
            return text.toLowerCase()
                .replace(/[^\w ]+/g, '')
                .replace(/ +/g, '-');
        },
        formatCurrency(value) {
            return numeral(value).format("$0,0");
        },
        formatCurrencyWithCents(value) {
            return numeral(value).format("$0,0.00");
        },
        formatDateOnly(date) {
            if (!date) return null
            const [year, month, day] = date.split('-');
            return `${month}/${day}/${year.trim()}`
        },
        parseDateOnly(date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        displayDate(value, forceTimezone = null) {
            if (forceTimezone) {
                return moment.utc(value).tz('America/Phoenix').format("MM/DD/YYYY");
            }
            return moment.utc(value).format("MM/DD/YYYY");
        },
        displayMonth(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);
            return date.toLocaleString('en-US', {month: 'long'});
        },
        displayTime(value) {
            return moment(value).format("hh:mm A");
        },
        imageUrl(hash) {
            return process.env.MIX_APP_URL + "/website/uploads/avatar/" + hash;
        },
        formatTitleCase(str) {
            return str.toLowerCase().split(' ').map(function (word) {
                return word.replace(word[0], word[0].toUpperCase());
            }).join(' ');
        },
        applicableTaxYear() {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth();
            const day = today.getDate();
            if (month + 1 < 4) {
                return year - 1;
            }
            if (month + 1 === 4 && day <= 16) {
                return year - 1;
            }
            return year;
        },
        applicableTaxYearAsString() {
            return this.applicableTaxYear() === new Date().getFullYear() ? 'current' : 'prior';
        },
    }
});
/**
 * Register Filters
 */

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

var numeral = require("numeral");

Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatNumberWithCents", function (value) {
    return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatCurrency", function (value) {
    return numeral(value).format("$0,0"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatCurrencyWithCents", function (value) {
    return numeral(value).format("$0,0.00"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatPercent", function (value) {
    return numeral(value).format("0.00%"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatPercentThreeDecimals", function (value) {
    return numeral(value).format("0.000%"); // displaying other groupings/separators is possible, look at the docs
});


/**
 * Event Bus
 */
window.bus = new Vue();

/**
 * Initialize VueJS
 */

const app = new Vue({
    el: '#app',
    store,
    router,
    vuetify: new Vuetify({
        theme: {
            themes: {
                light: {
                    danger: '#FF0000',
                    primary: '#6c3192', // apsto purple
                    primaryDark: '#31164A', // apsto purple dark
                    primaryLight: '#AF78D3', // apsto purple light
                    primaryVeryLight: '#F8F5F9', // apsto purple very light
                    secondary: '#55A9AD', // apsto teal
                    secondaryLight: '#CBE5E6', // apsto teal light
                    secondaryVeryLight: '#EEF6F7', // apsto teal very light
                    iconCyan: '#6CB2EB', // cyan for icons
                },
            },
        },
        icons: {
            values: {
                pdf: {
                    component: PdfIcon
                },
            }
        }
    }),
    render: h => h(App)
});
