<template>
    <v-container class="pa-0">
        <login-by-link-form
            v-if="!emitOnly"
            class="mt-5"
        />

        <div class="mt-5">
            <div class="first-heading">{{ !emitOnly ? 'Or, ' : '' }}Login With Password</div>
            <v-form
                ref="loginForm"
                v-model="isFormValid"
                class="section mt-5"
            >
                <v-text-field
                    ref="email"
                    v-model="email"
                    :error="!!errors && errors.email"
                    :error-messages="errors && errors.email ? errors.email : null"
                    :rules="rules.email"
                    label="Email"
                    name="email"
                    type="text"
                    @focus="clearErrors()"
                    @keyup.enter="$refs.password.focus();"
                />
                <v-text-field
                    ref="password"
                    v-model="password"
                    :append-icon="passwordVisibility ? 'mdi-eye-lock-open' : 'mdi-eye-lock'"
                    :error="!!errors && errors.password"
                    :error-messages="errors && errors.password ? errors.password : null"
                    :rules="rules.password"
                    :type="passwordVisibility ? 'text' : 'password'"
                    label="Password"
                    name="password"
                    @focus="clearErrors()"
                    @click:append="togglePasswordVisibility"
                    @keyup.enter="login()"
                />
                <v-row align="center">
                    <v-btn
                        block
                        text
                        x-small
                        @click="forgotEmailFlag = true"
                    >
                        Forgot Your Password?
                    </v-btn>
                </v-row>
            </v-form>
        </div>
        <v-btn
            :disabled="!isFormValid"
            :loading="loading"
            block
            class="mt-3"
            color="primary"
            rounded
            @click.native="login"
        >
            LOGIN
        </v-btn>
        <!-- Forgotten Password Modal-->
        <v-dialog
            v-model="forgotEmailFlag"
            content-class="top-align-dialog"
            scrollable
            width="500"
        >
            <ForgotPasswordModal
                class="ma-3"
                @close="forgotEmailFlag = false"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import ForgotPasswordModal from "./ForgotPasswordModal";
import {LOGIN, SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE} from "../../store/actions.type";
import {CLEAR_ERRORS, SET_CHECK_DATA_BY_EMAIL_FORM} from "../../store/mutations.type";
import LoginByLinkForm from "./LoginByLinkForm.vue";
import CheckDataByEmailForm from "./CheckDataByEmailForm.vue";

export default {
    name: "LoginForm",
    components: {
        CheckDataByEmailForm,
        LoginByLinkForm,
        ForgotPasswordModal,
    },
    props: {
        emitOnly: {
            type: Boolean,
            default: false,
        },
        hidePriorEmails: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            email: "",
            errorMessage: null,
            password: "",
            passwordVisibility: false,
            remember: false,
            loading: false,
            forgotEmailFlag: false,
            isFormValid: false,
            isSignInByEmailFormValid: false,
            rules: {
                email: [
                    (v) => !!v || "Ex: someone@example.com",
                    (v) =>
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            v
                        ) || "Ex: someone@example.com",
                ],
                password: [(v) => (!!v && v.length >= 8) || "Password is at least 8 characters"],
            },
            displayEmailMessage: false,
            tab: null,
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("login");
        },
        errors() {
            return this.$store.getters.getErrors("login");
        },
    },
    methods: {
        login: function () {
            this.loading = true;
            let email = this.email;
            let password = this.password;
            let remember = this.remember;
            this.$store.dispatch(LOGIN, {email, password, remember})
                .then((data) => {
                    if (this.emitOnly) {
                        console.log("emit success");
                        this.$emit("success");
                        return;
                    }
                    this.loading = false;
                    let currentRoute = this.$router.currentRoute.path;
                    let destinationRoute = "dashboard";

                    switch (currentRoute) {
                        case '/applicant-portal':
                        case '/scholarship-application':
                        case '/renew-application':
                            destinationRoute = "households";
                            break;
                        case '/corporate-donation-request-online-form':
                            destinationRoute = "corporate-donation-request-online-form";
                            break;
                        case '/corporate-portal':
                            destinationRoute = "companies";
                            break;
                        case '/donor-portal':
                            destinationRoute = "donor";
                            break;
                        case '/school-portal':
                        case '/partner-with-us':
                        case '/renew-partnership':
                            destinationRoute = "schools";
                            break;
                    }

                    this.$router.push({
                        name: destinationRoute,
                    })
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        },
        clearErrorsAndMessages() {
            this.displayEmailMessage = null;
            this.errorMessage = null;
            this.$store.commit(CLEAR_ERRORS);
        },

        sendSignInEmail() {
            let email = this.email;
            this.$store.dispatch(SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE, email)
                .then(() => {
                    this.loading = false;
                    this.displayEmailMessage = true;
                })
                .catch((response) => {
                    this.errorMessage = response.data.message;
                    this.loading = false;
                });

        },
        togglePasswordVisibility() {
            this.passwordVisibility = !this.passwordVisibility;
        },
        showCheckDataByEmailForm() {
            this.$store.commit(SET_CHECK_DATA_BY_EMAIL_FORM, true);
        },
    },
};
</script>

<style scoped>
.first-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 1.2rem;
    font-weight: bold;
}

.section {
    background-color: var(--apsto-teal-very-light);
    border: 1px solid var(--apsto-teal);
    padding: 20px;
}

.error-message {
    border: 1px solid var(--apsto-purple);
}
</style>
