<template>
    <v-card>
        <v-row class="mx-2">
            <v-col
                :cols="isMobile ? 12 : ''"
                :class="isMobile ? 'mt-3' : ''"
            >
                <v-checkbox
                    v-model="application.hardship.hardship_bankruptcy"
                    :error="!!errors['hardship.hardship_bankruptcy']"
                    :error-messages="errors['hardship.hardship_bankruptcy'] ? errors['hardship.hardship_bankruptcy'] : null"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> Bankruptcy</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_employment_change"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> Changed Employment</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_family_size_change"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> Family Size Changed</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_general_expenses"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> General Expenses Increased</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_loss_of_home"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> Loss of Home</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_loss_of_income"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> Loss of Income</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_medical_expenses"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> Medical Expenses Increased</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_tuition_expenses"
                    color="secondary"
                    disabled
                >
                    <template v-slot:label>
                        <div> Tuition Expenses Increased</div>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-model="application.hardship.hardship_other"
                    color="secondary"
                    disabled
                    :class="application.hardship.hardship_comments ? '' : 'mb-5'"
                >
                    <template v-slot:label>
                        <div> Other</div>
                    </template>
                </v-checkbox>

                <div
                    v-if="application.hardship.hardship_comments"
                    class="mb-5"
                >
                    <v-card-title style="font-size: 1.105rem; padding-left: 0;">
                        Comments
                    </v-card-title>
                    <div
                        style="font-size: 1.015rem;"
                        class="mt-n2"
                    >
                        {{ application.hardship.hardship_comments }}
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default {
    name: "FinancialHardshipSection",
    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
}
</script>

<style scoped>
::v-deep .v-textarea textarea {
    padding: 10px;
}

::v-deep .v-input--is-disabled {
    color: var(--apsto-teal);
}

::v-deep .v-input__control {
    height: 20px;
}

::v-deep .v-label--is-disabled {
    color: rgba(0, 0, 0, 1);
}
</style>
