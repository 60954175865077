<template>
    <div>
        <v-row align="center">
            <v-col>
                <div
                    :class="isMobile ? 'px-3' : 'px-6'"
                    class="box-with-background py-3"
                >
                    <v-row>
                        <v-col class="text-h6-mulish">
                            Tax Receipt for {{ donation.taxYear }}
                            <v-tooltip
                                bottom
                                color="primary"
                            >
                                <template v-slot:activator="{ on }">
                                    <a
                                        :href="`/api/donors/download-annual-donations-receipt/${donation.taxYear}`"
                                        class="ml-2"
                                        v-on="on"
                                    >
                                        <v-icon color="white">
                                            mdi-download
                                        </v-icon>
                                    </a>
                                </template>
                                <span>Download Tax Receipt</span>
                            </v-tooltip>
                            <v-tooltip
                                bottom
                                color="primary"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="ml-2"
                                        color="white"
                                        @click="sendReceipt(false)"
                                        v-on="on"
                                    >
                                        mdi-email
                                    </v-icon>
                                </template>
                                <span>Email Tax Receipt</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="currentYearMessage"
                        class="mt-n5"
                    >
                        <v-col class="subtitle-1 font-mulish">
                            {{ currentYearMessage }}
                        </v-col>
                    </v-row>

                </div>

                <v-card v-if="isMobile">
                    <v-card-text>
                        <div
                            class="font-mulish text-apsto-purple pb-3"
                            style="border-bottom: 1px dotted var(--apsto-teal); font-size: 16px;"
                        >
                            Click mail icon above for tax receipt.
                        </div>
                        <div class="v-data-table__mobile-row__cell mt-3">
                            Original Tax Credit
                        </div>
                        <div class="v-data-table__mobile-row__header">
                            Claim on AZ Form 323
                        </div>
                        <div class="v-data-table__mobile-row__cell text-apsto-black">
                            {{ donation.donorDonationsOriginalSum | formatCurrencyWithCents }}
                        </div>

                        <div class="v-data-table__mobile-row__cell mt-4">
                            Overflow (Switcher) Tax Credit
                        </div>
                        <div class="v-data-table__mobile-row__header">
                            Claim on AZ Form 348
                        </div>
                        <div class="v-data-table__mobile-row__cell text-apsto-black">
                            {{ donation.donorDonationsSwitcherSum | formatCurrencyWithCents }}
                        </div>

                        <div v-if="donation.donorDonationsCharitableSum">
                            <div class="v-data-table__mobile-row__cell mt-4">
                                Non-credit Charitable Contributions
                            </div>
                            <div class="v-data-table__mobile-row__header">
                                Check with your tax advisor
                            </div>
                            <div class="v-data-table__mobile-row__cell text-apsto-black">
                                {{ donation.donorDonationsCharitableSum | formatCurrencyWithCents }}
                            </div>
                        </div>

                        <div class="v-data-table__mobile-row__cell mt-4">
                            TOTAL
                        </div>
                        <div class="v-data-table__mobile-row__cell text-apsto-black">
                            {{ total | formatCurrencyWithCents }}
                        </div>
                    </v-card-text>
                </v-card>


                <v-card
                    v-if="!isMobile"
                    class="px-2 pt-3"
                >
                    <div
                        v-if="donation.taxYear === new Date().getFullYear() && donation.donorDonations.length > 0"
                        class="h6-text-mulish text-apsto-purple mx-5"
                    >
                        Reminder: Please wait until the year is finished to print your year-end receipt.
                    </div>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td v-if="donation.donorDonations.length > 0">
                                        <table
                                            class="pb-5 pt-2"
                                            style="min-width: 800px;"
                                        >
                                            <tr>
                                                <td>
                                                    Total Original tax credit to claim on AZ Form 323
                                                </td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="totals-amount-box">
                                                    {{ donation.donorDonationsOriginalSum | formatCurrencyWithCents }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Total Overflow (Switcher) tax credit to claim on AZ Form 348
                                                </td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="totals-amount-box">
                                                    {{ donation.donorDonationsSwitcherSum | formatNumberWithCents }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Total Additional Charitable Contributions
                                                </td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="totals-amount-box">
                                                    <div v-if="donation.donorDonationsCharitableSum">
                                                        {{ donation.donorDonationsCharitableSum | formatNumberWithCents }}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    TOTAL
                                                </td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="totals-amount-box">
                                                    {{ total | formatCurrencyWithCents }}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td v-else>
                                        No activity during {{ donation.taxYear }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>

                <annual-donation-receipt-table
                    v-if="donation.donorDonations.length > 0"
                    :class="isMobile ? 'mt-3' : ''"
                    :donation="donation"
                />

            </v-col>
        </v-row>


        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>

import AnnualDonationReceiptTable from "./AnnualDonationReceiptTable.vue";
import { EMAIL_DONOR_ANNUAL_RECEIPT } from "../../../store/actions.type";

export default {
    name: "AnnualDonationReceipt",
    components: { AnnualDonationReceiptTable },
    props: {
        donation: {
            type: Object,
            required: true
        },
        currentYear: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            snackbar: false,
            snackbarMessage: "",

        }
    },
    computed: {
        currentYearMessage() {
            let message = "";
            if (this.currentYear && this.donation.donorDonations.length > 0) {
                message = "Scroll down for " + parseInt(this.donation.taxYear - 1) + " tax receipt";
            }
            return message;
        },
        total() {
            return this.donation.donorDonationsOriginalSum +
                this.donation.donorDonationsSwitcherSum +
                this.donation.donorDonationsCharitableSum;
        },
    },
    methods: {
        sendReceipt() {
            let taxYear = this.donation.taxYear;
            this.sending = true;
            this.$store.dispatch(EMAIL_DONOR_ANNUAL_RECEIPT, taxYear)
                .then((response) => {
                    this.sending = false;
                    this.snackbarMessage = response.message;
                    this.snackbar = true;
                })
                .catch((response) => {
                    console.log(response);
                    this.sending = false;
                });
        }
    }
}
</script>

<style scoped>
td,
th {
    font-size: 1rem;
    padding: 8px 8px 8px 0;
}

::v-deep .theme--light.v-data-table .v-data-table__wrapper table thead tr th {
    font-size: 0.75rem;
}

::v-deep .theme--light.v-data-table .v-data-table__wrapper table.data-rows tr td {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.85rem !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}

.totals-amount-box {
    background-color: var(--apsto-purple-very-light);
    border: 2px solid black;
    border-radius: 0 !important;
    min-width: 100px;
    padding-right: 8px;
    text-align: right;
}
</style>
