<template>
    <v-card>
        <v-card-title class="text-capitalize">{{ mode }} New Page</v-card-title>
        <v-card-text>
            <v-text-field
                label="Page Name"
                v-model="page.name"
            />

            <v-text-field
                label="Override URL"
                v-model="page.override_url"
            />

            <template v-if="!page.override_url">
                <v-select
                    v-model="page.website_layout_id"
                    :disabled="loading"
                    :items="layouts"
                    item-value="id"
                    item-text="name"
                    label="Layout"
                />
                <v-text-field
                    label="Header Text"
                    v-model="page.header_text"
                />

                Current Header:
                <billboard
                    :page="page"
                    :force-preview="true"
                />

                <upload-image @done="imageUploaded" />

                <v-switch
                    v-if="page.website_layout_id == 1"
                    label="Show Left Menu"
                    v-model="page.show_toc"
                />
            </template>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="warning"
                class="mr-4"
                @click="closeDialog"
            >
                <v-icon>mdi-cancel</v-icon>
                Cancel
            </v-btn>
            <v-btn
                color="success"
                class="mr-4"
                @click="save"
            >
                <v-icon>mdi-check</v-icon>
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {
    GET_CONTENTS,
    GET_LAYOUTS,
    POST_PAGE,
} from "../../../store/actions.type";
import { SET_ADD_PAGE_DIALOG_STATUS } from "../../../store/mutations.type";

import Billboard from "../../website/components/Faq/Billboard";

import UploadImage from "../../shared/UploadImage";

export default {
    name: "AddEditPage",
    components: {
        Billboard,
        UploadImage
    },
    data() {
        return {
            loading: false,
            currentImage: undefined,
            previewImage: undefined,
            progress: 0,
            message: "",
            defaultPage: {
                name: "",
                website_layout_id: null,
                header_text: "",
                header_image: null,
            },
            page: {
                name: "",
                website_layout_id: null,
                header_text: "",
                header_image: null,
            },
        };
    },
    props: {
        mode: {
            type: String,
            default: "add",
        },
    },
    computed: {
        layouts() {
            return this.$store.getters.currentLayouts;
        },
        currentAddPageGroupID() {
            return this.$store.getters.currentAddPageGroupID;
        },
    },
    methods: {
        closeDialog() {
            this.$store.commit(SET_ADD_PAGE_DIALOG_STATUS, false);
        },
        getLayouts() {
            this.loading = true;
            this.$store
                .dispatch(GET_LAYOUTS)
                .then(() => {
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        imageUploaded(hash) {
            this.page.header_image = '/website/uploads/full/' + hash;
        },
        save() {
            let page = this.page;
            page.website_group_id = this.currentAddPageGroupID;
            this.$store
                .dispatch(POST_PAGE, page)
                .then(() => {
                    this.page = this.defaultPage;
                    this.$store.dispatch(GET_CONTENTS).then(() => {
                        this.loading = false;
                        this.closeDialog();
                    });
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getLayouts();
        this.page = this.defaultPage;
    },
};
</script>
