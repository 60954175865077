<template>
  <div>
    <div class="text-h6-mulish">Students Recommended on Other Donations by {{ donor.name }} ({{ donor.email }})</div>
    <v-data-table
      :footer-props="{ itemsPerPageOptions:[10,20,30,-1] }"
      :headers="headers"
      :items-per-page="-1"
      :items="otherDonations"
      :single-select="true"
      class="mt-3"
      item-key="id"
    >
      <template v-slot:no-data>
        <span class="text-apsto-teal">
          There are no other donations linked to {{ donor.name }}.
        </span>
      </template>
      <template #item.amount="{item, index}">
        <div v-if="index === 0">
          {{ item.amount | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.amount | formatNumberWithCents }}
        </div>
      </template>
      <template #item.linked_to="{item}">
        <tr v-for="(link,index) in item.linked_to.student_permanent_history" :key="index">
          <td class="student-permanent">
            {{ link.name }} on {{ displayDate(link.updated_at) }}
            <br>
            {{ link.household_name }} Family
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "AdminSchoolStudentsLinksOtherDonations",
  props: {
    donor: {
      required: true,
      type: Object
    },
    otherDonations: {
      required: true,
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {text: 'Name', align: 'start', value: 'name'},
        {text: 'School', value: 'school_name'},
        {text: 'Tax Credit Type', value: 'tax_credit_type_name'},
        {text: 'NET Amount', value: 'amount', align: 'end'},
        {text: 'LINKED TO', value: 'linked_to'},
      ],
    }
  },
}
</script>

<style scoped>

a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
  height: 60px !important;
}

::v-deep table.donation-box tbody td {
  height: unset !important;
}

::v-deep tbody td.student-permanent {
  height: unset !important;
  padding-bottom: 4px;
  padding-top: 4px;
}

::v-deep table.donation-box tbody td.tax-credit-type-name-column {
  padding-right: 20px;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep .theme--light.v-data-table.unlinked-students > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
  cursor: pointer;
}

::v-deep .v-icon {
  font-size: 24px !important;
}

</style>
}
