<template>
  <loading v-if="loading"/>
  <v-card v-else>
    <div class="text-h5-mulish" style="padding: 16px 22px 26px;">
      {{ studentName }}
      <div> {{ awardLine }}</div>
      <div class="ellipsis-title">
        Type: {{ applicationStudentAward.tax_credit_name }}
      </div>
    </div>
    <v-card-text class="background-lower">
      <v-row>
        <v-col class="mt-0">
          <v-data-table
            v-if="showDataTable"
            :headers="headers"
            :items="schoolStudentAwardActions"
            :single-select="true"
            class="elevation-2 mt-2 mr-0"
            item-key="id"
          >
            <template v-slot:no-data>
            <span class="text-apsto-teal">
              No students award actions found
            </span>
            </template>
            <template v-slot:top>
              <div>
                <v-toolbar flat>
                  <v-btn color="primary" dark rounded @click="addItem">
                    Accept / Decline
                  </v-btn>
                  <div v-if="schoolStudentAwardActions && schoolStudentAwardActions.length" class="hint-box">
                    Please click button again if you want to change your {{ hintResponse }}.
                  </div>
                </v-toolbar>
              </div>
            </template>
            <template #item.action="{ item, index }">
              <div v-if="item.action === 1 && !acceptedPartialAmount(item)"
                   :class="index === 0 ? 'accepted-declined' : 'accepted-declined lighter'">
                Accepted on {{ displayDate(item.created_at) }} at {{ displayTime(item.created_at) }}
                by {{ item.user_name }}
                <div>
                  Amount: {{ item.amount | formatCurrencyWithCents }}
                </div>
              </div>
              <div v-if="acceptedPartialAmount(item)"
                   :class="index === 0 ? 'accepted-declined' : 'accepted-declined lighter'"
              >
                Accepted partial amount on {{ displayDate(item.created_at) }} at {{ displayTime(item.created_at) }}
                by {{ item.user_name }}
                <div>
                  Amount Accepted: {{ item.amount | formatCurrencyWithCents }}
                </div>
                <div> Reason: {{ item.award_declination_label }}</div>
                <div v-if="item.comments">Comments: {{ item.comments }}</div>
              </div>
              <div v-if="item.action === 2" :class="index === 0 ? 'accepted-declined' : 'accepted-declined lighter'">
                Declined on {{ displayDate(item.created_at) }} at {{ displayTime(item.created_at) }}
                by {{ item.user_name }}
                <div> Reason: {{ item.award_declination_label }}</div>
                <div v-if="item.comments">Comments: {{ item.comments }}</div>
              </div>
            </template>
          </v-data-table>
          <div v-if="showAddDialog" v-model="addDialog">
            <award-action-add
              :award="applicationStudentAward"
              class="mt-2"
              @done="hideAddDialog"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AwardActionAdd from "./AwardActionAdd";
import Loading from "../../../../../components/shared/Loading";
import SchoolAwardDeclinationSelector from "../../../../../components/shared/SchoolAwardDeclinationSelector";
import SchoolHeader from "../../../../../components/school/SchoolHeader";
import Spacer from "../../../../../components/website/components/Faq/Spacer";
import numeral from "numeral";
import {GET_SCHOOL_STUDENT_AWARD} from "../../../../../store/actions.type";

export default {
  name: "SchoolStudentAwardActionsTable",
  components: {
    Spacer,
    AwardActionAdd,
    SchoolAwardDeclinationSelector,
    SchoolHeader,
    Loading
  },
  data() {
    return {
      addDialog: false,
      defaultItem: {
        amount: 0,
        comments: null,
        reason_enum: null
      },
      loading: true,
      headers: [
        {text: 'Activity', value: 'action'},
      ],
      showAddDialog: false,
      showDataTable: true,
    }
  },
  props: {
    applicationStudentAwardId: {
      type: Number,
      required: true,
    },
    studentName: {
      type: String,
      required: true,
    },
  },
  computed: {
    amountOffered() {
      return this.$store.getters.currentApplicationStudentAward.amount;
    },
    applicationStudentAward() {
      return this.$store.getters.currentApplicationStudentAward;
    },
    awardLine() {
      if (this.showLastAction && this.schoolStudentAwardActions[0].action === 1) {
        return "Offered: " + numeral(this.applicationStudentAward.amount).format("$0,0.00") + " | Accepted: " + numeral(this.schoolStudentAwardActions[0].amount).format("$0,0.00");
      }
      return "Offered: " + numeral(this.applicationStudentAward.amount).format("$0,0.00");
    },
    hintResponse() {
      return this.showLastAction ? "last response" : "response"
    },
    showLastAction() {
      return this.schoolStudentAwardActions && this.schoolStudentAwardActions.length > 1;
    },
    schoolStudentAwardActions() {
      return this.applicationStudentAward.application_student_award_actions;
    },
    error() {
      return this.$store.getters.getMessage("schoolStudent");
    },
    errors() {
      return this.$store.getters.getErrors("schoolStudent");
    },
  },
  methods: {
    acceptedPartialAmount(item) {
      return item.action === 1 && parseFloat(item.amount) > 0 && parseFloat(item.amount) < parseFloat(this.amountOffered);
    },
    addItem() {
      this.temporaryItem = Object.assign({}, this.defaultItem);
      this.addDialog = true;
      this.showAddDialog = true;
      this.showDataTable = false;
    },
    getSchoolStudentAward() {
      this.loading = true;
      let school_id = this.$route.params.school_id
      let application_student_school_id = this.$route.params.application_student_school_id;
      let application_student_award_id = this.applicationStudentAwardId;
      this.$store.dispatch(GET_SCHOOL_STUDENT_AWARD, {
        school_id,
        application_student_school_id,
        application_student_award_id
      })
        .then(() => {
          this.loading = false;
          if (!this.schoolStudentAwardActions.length) {
            this.showDataTable = false;
            this.showAddDialog = true;
          }
        });
    },
    hideAddDialog() {
      this.addDialog = false;
      this.showAddDialog = false;
      this.showDataTable = true;
      this.getSchoolStudentAward();
    },
  },
  mounted() {
    this.getSchoolStudentAward();
  },
}
</script>

<style scoped>
.accepted-declined {
  color: black;
  font-weight: 700;
}

.accepted-declined.lighter {
  color: rgba(0, 0, 0, .60);
  font-weight: 400;
}

.background-lower {
  background-color: var(--apsto-teal-light);
  padding-left: 22px;
  padding-right: 22px;
}

.ellipsis-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hint-box {
  font-weight: 400;
  line-height: 1.1rem;
  margin-left: 10px;
  padding: 0px 10px;
}

::v-deep tbody td {
  cursor: default;
  height: unset !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

::v-deep tbody td:hover {
  background-color: white !important;
}
</style>
