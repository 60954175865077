<template>
    <v-row>
        <v-col class="text-center mt-10">
            <div>
                <div v-if="currentImage">
                    <div>
                        <v-progress-linear
                            v-model="progress"
                            color="#55A9AD"
                            height="35"
                            reactive
                        >
                            <strong>{{ progress }} %</strong>
                        </v-progress-linear>
                    </div>
                </div>
                <v-row v-if="previewImage">
                    <v-col class="py-5">
                        <!--            Newly Uploaded Image:-->
                        <v-img
                            max-height="150"
                            max-width="300"
                            contain
                            class="preview my-3"
                            :src="previewImage"
                            alt=""
                        />
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    justify="start"
                    align="center"
                >
                    <v-col>
                        <v-file-input
                            show-size
                            label="Pick an Image"
                            @change="selectImage"
                            ref="fileupload"
                        />
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    justify="center"
                    align="center"
                >
                    <v-col>
                        <v-btn
                            color="success"
                            dark
                            :disabled="!previewImage"
                            @click="upload"
                        >
                            Use This Image
                            <v-icon
                                right
                                dark
                            >mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-alert
                    v-if="message"
                    border="left"
                    color="blue-grey"
                    dark
                >
                    {{ message }}
                </v-alert>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import ApiService from "../../api/api.service";

export default {
    name: "UploadImage",
    data() {
        return {
            currentImage: undefined,
            previewImage: undefined,
            progress: 0,
            message: "",
            imageInfos: [],
            componentKey: 0,
        };
    },
    methods: {
        selectImage(image) {
            this.currentImage = image;
            if (this.currentImage) {
                this.previewImage = URL.createObjectURL(this.currentImage);
            }
            this.progress = 0;
            this.message = "";
        },
        upload() {
            if (!this.currentImage) {
                this.message = "Please select an Image!";
                return;
            }
            this.progress = 0;
            ApiService.uploadImage(this.currentImage, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            })
                .then((response) => {
                    let hash = response.data;
                    //Clear error message
                    this.message = "";
                    this.currentImage = undefined;
                    this.previewImage = undefined;
                    //Reset File Upload
                    this.$refs.fileupload.reset();
                    this.$emit("done", hash);
                })
                .catch((err) => {
                    this.progress = 0;
                    this.message = "Could not upload the image! " + err;
                    this.currentImage = undefined;
                });
        },
    },
};
</script>

<style scoped>.v-btn:not(.v-btn--round).v-size--default {
    min-width: 100%
}</style>
