<template>
    <div>
        <v-row v-if="!this.$route.query.news">
            <v-col cols="12">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-btn
                    color="primary"
                    @click="$router.push({ query: { news: null } })"
                >
                    Show All News
                </v-btn>
            </v-col>
        </v-row>
        <v-row
            v-if="!editMode"
            class="mt-n10"
        >
            <v-col>

                <template v-if="content.contents && content.contents.length">
                    <news-article
                        v-for="(subcontents, index) in filteredContents"
                        :key="index"
                        :content="subcontents"
                    />
                </template>

            </v-col>
        </v-row>
        <v-row
            v-else
            class="mt-n10"
        >
            <v-col>
                <draggable
                    :force-fallback="true"
                    :list="content.contents"
                    handle=".news-article-handle"
                    @end="onEnd"
                >
                    <v-row
                        v-for="subcontent in content.contents"
                        :key="subcontent.id"
                        no-gutters
                    >
                        <v-col>
                            <v-card class="pa-4 my-4">
                                <v-row
                                    class="pt-4"
                                    justify="space-between"
                                >
                                    <v-col cols="4">
                                        Component: {{ subcontent.component.name }}
                                    </v-col>
                                    <v-col
                                        class="py-0"
                                        cols="4"
                                    >
                                        <v-text-field
                                            v-model="subcontent.class"
                                            dense
                                            hide-details
                                            label="Class"
                                            outlined
                                            @change="updateContent(subcontent)"
                                        />
                                    </v-col>

                                    <v-col
                                        class="py-0"
                                        cols="2"
                                    >
                                        <v-text-field
                                            v-model="subcontent.sort_order"
                                            dense
                                            hide-details
                                            label="Sort Order"
                                            outlined
                                            @change="updateContent(subcontent)"
                                        />
                                    </v-col>
                                    <v-col
                                        class="py-0"
                                        cols="auto"
                                    >
                                        <v-btn
                                            class="news-article-handle"
                                            icon
                                        >
                                            <v-icon>mdi-drag-vertical</v-icon>
                                        </v-btn>
                                    </v-col>

                                    <v-col cols="12">
                                        <news-article :content="subcontent" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </draggable>
                <add-component
                    :parent-component="content.website_component_id"
                    :parent-id="content.id"
                    button-text="Add News Article"
                    parent-type="WebsiteContent"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import NewsArticle from "./NewsArticle";
import AddComponent from "../../menus/AddComponent";

import { PATCH_CONTENT, POST_SORT_ORDER } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from "../../../../store/mutations.type";

export default {
    name: "News",
    components: {
        draggable,
        NewsArticle,
        AddComponent,
    },
    props: {
        content: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
            currentCarousel: null,
            search: "",
        };
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
        filteredContents() {
            if (this.$route.query.news) {
                //Filter content.contents by news which is the id of the news content
                return this.content.contents.filter((content) => {
                    return content.id === parseInt(this.$route.query.news);
                });
            }
            if (this.search.length > 0) {
                return this.content.contents.filter((content) => {
                    return content.body.description.toLowerCase().includes(this.search.toLowerCase()) || (content.body.text && content.body.text.toLowerCase().includes(this.search.toLowerCase()));
                });
            } else {
                return this.content.contents;
            }
        }
    },
    methods: {
        onEnd() {
            let new_content_id_order = [];

            this.content.contents.forEach((content) => {
                new_content_id_order.push(content.id);
            });

            this.$store.dispatch(POST_SORT_ORDER, new_content_id_order);
        },
        updateContent(content) {
            this.loading = true;
            let self = this;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    self.loading = false;
                })
                .catch((e) => {
                    self.loading = false;
                });

        },
    },

};
</script>

<style scoped></style>
