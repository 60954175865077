<template>
  <loading v-if="loading"/>
  <div v-else>
    <admin-header
      page-title="Link Unlinked Student Recommendation: "
      :hrefText="unlinkedStudentName"
      hrefColor="text-apsto-red"
    />
    <v-row>
      <v-col>
        <admin-students-table
          :unlinked-student-name="unlinkedStudentName"
          :link-type="'single'"
          class="mt-n3"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../../../../main/AdminHeader";
import AdminStudentsTable from "./AdminStudentsTable";
import ConfirmationDialog from "../../../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../../../components/shared/Loading";
import {GET_ADMIN_UNLINKED_STUDENT_NAME} from "../../../../../../store/actions.type";

export default {
  name: "AdminSchoolLinkStudent",
  components: {
    AdminHeader,
    AdminStudentsTable,
    ConfirmationDialog,
    Loading,
  },
  data() {
    return {
      loading: true,
      saving: false,
    }
  },
  computed: {
    unlinkedStudentName() {
      return this.$store.getters.currentAdminUnlinkedStudentName;
    },
  },
  methods: {
    getUnlinkedStudentName() {
      let school_id = this.$route.params.school_id;
      let donor_selection_student_id = this.$route.params.donor_selection_student_id;
      this.loading = true;
      this.$store.dispatch(GET_ADMIN_UNLINKED_STUDENT_NAME, {school_id, donor_selection_student_id})
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
        });
    },
  },
  mounted() {
    this.getUnlinkedStudentName();
  },
}
</script>

<style scoped>
a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
  height: 60px !important;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep .theme--light.v-data-table.unlinked-students > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
  cursor: pointer;
}

::v-deep .v-icon {
  font-size: 24px !important;
}</style>
