<template>
    <div>
        <v-row>
            <v-col>
                <company-header page-title="Payroll Withholding" />
            </v-col>
        </v-row>
        <v-row v-if="unpaidEmployeeDonations">
            <v-col
                class="mt-n6"
                md="6"
            >
                <unpaid-employee-donations :called-from-client="true" />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col
                class="mt-n6"
                md="6"
            >
                <employee-donations-withholding-period
                    @done="showFileUpload = true"
                    @failed="showFileUpload = false"
                />
                <employee-donations-file-upload
                    v-if="showFileUpload"
                    class="mt-5"
                />
                <upload-failed
                    :failures="failures"
                    class="mt-5"
                    v-if="failures.length"
                />
            </v-col>
            <v-col
                class="mt-n6"
                md="6"
            >
                <div v-if="processedRowsCount && showResponse && !failures.length">
                    <upload-succeeded
                        :company="company"
                        :file-amount="fileAmount"
                        :missing-employees="missingEmployees"
                        :processedRowsCount="processedRowsCount"
                        :withholding="readRows"
                    />
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import CompanyHeader from "../../../components/company/CompanyHeader";
import EmployeeDonationsFileUpload from "../../../components/admin/employee-donations/EmployeeDonationFileUpload.vue";
import EmployeeDonationsWithholdingPeriod
    from "../../../components/admin/employee-donations/EmployeeDonationWithholdingPeriod.vue";
import UnpaidEmployeeDonations from "../../../components/admin/employee-donations/UnpaidEmployeeDonations.vue";
import UploadFailed from "../../../components/admin/employee-donations/UploadFailed";
import UploadSucceeded from "../../../components/admin/employee-donations/UploadSucceeded";

export default {
    name: "EmployeeDonationsUpload",
    components: {
        CompanyHeader,
        EmployeeDonationsFileUpload,
        EmployeeDonationsWithholdingPeriod,
        UnpaidEmployeeDonations,
        UploadFailed,
        UploadSucceeded,
    },
    data() {
        return {
            file: null,
            rules: [
                value => !value || value.size < 2048000 || 'File size should be less than 2 MB!',
            ],
            showFileUpload: false,
            showResponse: true,
        }
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        },
        companyPayrollBatches() {
            return this.$store.getters.currentCompanyPayrollBatches;
        },
        error() {
            return this.$store.getters.getMessage("companies");
        },
        errors() {
            return this.$store.getters.getErrors("companies");
        },
        failures() {
            return this.uploadResponse && this.uploadResponse.failures ? this.uploadResponse.failures : [];
        },
        fileAmount() {
            return this.uploadResponse ? this.uploadResponse.fileAmount : null;
        },
        missingEmployees() {
            return this.uploadResponse && this.uploadResponse.missingEmployees ? this.uploadResponse.missingEmployees : [];
        },
        processedRowsCount() {
            return this.uploadResponse && this.uploadResponse.processedRowsCount
                ? this.uploadResponse.processedRowsCount
                : null;
        },
        readRows() {
            return this.uploadResponse && this.uploadResponse.readRows ? this.uploadResponse.readRows : [];
        },
        unpaidEmployeeDonations() {
            return this.company.unpaid_employee_daily_donations && this.company.unpaid_employee_daily_donations.length;
        },
        uploadResponse() {
            return this.$store.getters.currentPayrollWithholdingUploadResponse;
        },
    },
    mounted() {
        this.processedRowsCount = 0;
    },
}
</script>

<style scoped></style>
