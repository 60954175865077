<template>
    <v-row class="ml-0 mt-2">
        <div style="font-size: 1.125rem">
            Arizona Private School Tuition Organization is not tied to any single
            school, denomination, or organization. If you don’t find your school
            listed below, please
            <a href="/contact"> contact us. </a>
            We will work with every qualified school in the state of Arizona
        </div>
        <v-col
            cols="12"
            class="px-0"
        >
            <input
                type="text"
                v-model="searchParam"
                placeholder="Search ..."
            />
            <v-simple-table>
                <tr
                    v-for="(approvedSchoolLetter, letter_index) in structuredList"
                    :key="letter_index"
                >
                    <td class="purple-white">{{ letter_index }}</td>
                    <td class="schools">
                        <div
                            v-for="(school, key) in approvedSchoolLetter"
                            :key="key"
                        >
                            <div
                                class="school-item"
                                @click="show(school)"
                            >
                                {{ school.name }}
                            </div>
                        </div>
                    </td>
                </tr>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
import ApiService from "../../../../api/api.service";

export default {
    name: "ApprovedSchools",
    props: {
        content: {
            type: Object,
        },
    },
    data() {
        return {
            approvedSchools: null,
            headers: [
                { text: "NAME", value: "name" },
                { text: "CITY", value: "physical_address.city" },
            ],
            searchParam: "",
        };
    },
    computed: {
        filteredList() {
            let regex = new RegExp(this.searchParam, "i");
            if (this.searchParam.length) {
                return this.approvedSchools.filter((school) =>
                    regex.test(school.name)
                );
            }

            return this.approvedSchools;
        },
        structuredList() {
            if (!this.filteredList || !this.filteredList.length) return [];
            return this.groupSchools(this.filteredList, 'name');
        },
    },
    methods: {
        show(item) {
            this.$router.push(`approved-schools/${item.id}`);
        },
        groupSchools(xs) {
            return xs.reduce(function (rv, x) {
                let newkey = x.name[0];
                (rv[newkey] = rv[newkey] || []).push(x);
                return rv;
            }, {});
        },
    },
    mounted() {
        ApiService.get("/api/website/data/approved-schools")
            .then((response) => {
                this.approvedSchools = response.data;
            })
            .catch((e) => {
                console.log("APPROVED SCHOOLS ERROR:", e);
            });
    },
};
</script>

<style scoped>
th,
td {
    padding: 20px;
    text-align: center;
}

tr>td {
    border: 1px solid var(--apsto-purple-very-light);
}

.purple-white {
    background-color: var(--apsto-purple);
    color: white;
    width: 10px;
}

.school-item {
    padding-bottom: 5px;
}

.school-item:hover {
    color: var(--apsto-teal);
    cursor: pointer;
    font-weight: 600;
}

.schools {
    margin-bottom: -5px;
    border: none;
    text-align: left;
}

input {
    color: white;
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 10px 45px;
    background: var(--apsto-teal) url("/images/icons/svg/search-icon.svg") no-repeat 15px center;
    background-size: 15px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

::placeholder {
    color: white;
}</style>
