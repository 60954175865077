<template>
  <div>
    <ModalMenu @done="done" :list="list" />

  </div>
</template>

<script>
import { DELETE_CHAT, DELETE_MESSAGE } from "../../../store/actions.type";
import { EDIT_MESSAGE } from "../../../store/mutations.type";
import ModalMenu from "./ModalMenu";

export default {
  name: "MessageMenu",
  components: {
    ModalMenu
  },
  props: {
    message: Object,
    isEventChat: Boolean
  },
  data() {
    return {
      edit: [
        {
          id: 'edit',
          text: "Edit Message",
          textIcon: "fas fa-edit",
          method: "edit",
        },
      ],
      delete: [
        {
          id: 'delete',
          text: "Delete Message",
          textIcon: "fas fa-trash-alt",
          method: "delete",
        },
      ],
    };
  },
  computed: {
    roles() {
      return this.$store.getters.currentRoles;
    },
    list() {
      if (!this.isAuthenticated) {
        return [];
      }

      let tempItems = [...this.copyText ]

      if (!this.message.mine) {
        if (this.roles.includes('super-admin')) {
            tempItems = [...tempItems, ...this.delete]
        }
      } else {
        //Edit Own
        tempItems = [...tempItems, ...this.edit, ...this.delete]
      }



      return tempItems;
    },
  },
  methods: {
    done(method) {
      if (method) {
        switch(method) {

          case 'edit':
            this.$store.commit(EDIT_MESSAGE, this.message.id);
            break;
          case 'delete':
            if (this.isEventChat) {
              this.$store.dispatch(DELETE_CHAT, this.message.id);
            } else {
              this.$store.dispatch(DELETE_MESSAGE, this.message.id);
            }
            break;
          case 'copy':
            navigator.clipboard.writeText(this.message.body);
            console.log(this.message);
            this.$store.commit(ADD_SNACKBAR, {
              text: 'Text copied!',
            });
        }
      }
      this.$emit("done");
    },
  },
};
</script>
