<template>
    <v-row class="mt-2 outlined">
        <v-col
            cols="12"
            sm="5"
        >
            <h2>We are always here to help!</h2>
            <div class="mt-2"></div>
            <strong>By Phone:</strong>
            <p>Questions? Call <a href="tel:4802561001">(480) 256-1001</a>.</p>
            <strong>By Mail:</strong>
            <p>If you prefer to mail your application or donation:</p>
            <p>
                <strong>Arizona Private School Tuition Organization</strong><br/>
                6424 East Greenway Parkway, Suite 100<br/>
                Scottsdale, AZ 85254
            </p>
            <v-btn
                class="my-1"
                color="secondary"
                href="tel:480-256-1001"
                rounded
                style="min-width: 200px;"
            >
                <v-icon
                    color="white"
                    small
                >mdi-phone
                </v-icon>
                <span style="color: white;"> (480) 256-1001</span>
            </v-btn>
            <br/>
            <v-btn
                class="my-1"
                color="secondary"
                href="tel:480-256-1002"
                rounded
                style="min-width: 200px;"
            >
                <v-icon
                    color="white"
                    small
                >mdi-fax
                </v-icon>
                <span style="color: white;"> 480-256-1002</span>
            </v-btn>
            <br/>
            <v-btn
                class="my-1"
                color="secondary"
                href="mailto:Info@APSTO.org"
                rounded
                style="min-width: 200px;"
            >
                <v-icon
                    color="white"
                    small
                >mdi-email
                </v-icon>
                <span style="color: white;">info@APSTO.org</span>
            </v-btn>
        </v-col>
        <v-col
            cols="12"
            sm="7"
        >
            <v-card>
                <v-card-title class="justify-center">Contact Us</v-card-title>
                <v-card-text class="mt-10">
                    <v-row v-if="contactForm">
                        <v-col
                            lg="6"
                            md="6"
                        >
                            <v-text-field
                                v-model="contactForm.name"
                                :error="!!errors.name"
                                :error-messages="errors.name ? errors.name : null"
                                background-color="white"
                                filled
                                hint="Please include your first and last name"
                                label="Full Name"
                            />
                        </v-col>
                        <v-col
                            lg="6"
                            md="6"
                        >
                            <v-text-field
                                v-model="contactForm.email"
                                :error="!!errors.email"
                                :error-messages="errors.email ? errors.email : null"
                                background-color="white"
                                filled
                                label="Email Address"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="contactForm.subject"
                                :error="!!errors.subject"
                                :error-messages="errors.subject ? errors.subject : null"
                                background-color="white"
                                filled
                                label="Subject"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="contactForm.message"
                                :error="!!errors.message"
                                :error-messages="errors.message ? errors.message : null"
                                background-color="white"
                                filled
                                label="Your message"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="align-start">
                    <v-btn
                        class="ml-2 mt-n5"
                        color="primary"
                        rounded
                        @click="submit"
                    > Send
                    </v-btn>
                </v-card-actions>
                <v-snackbar
                    v-model="snackbar"
                    centered
                >
                    {{ snackbarMessage }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="white"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {POST_CONTACT_US_FORM} from "../../../../store/actions.type";

export default {
    name: "ContactUs",
    data() {
        return {
            defaultContactForm: {
                contact_type_enum: 1, // general
                email: null,
                message: null,
                name: null,
                subject: null,
            },
            contactForm: null,
            snackbar: false,
            snackbarMessage: "",
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("contact");
        },
        errors() {
            return this.$store.getters.getErrors("contact");
        },
    },
    methods: {
        submit() {
            this.saving = true;
            this.$store.dispatch(POST_CONTACT_US_FORM, this.contactForm)
                .then(() => {
                    this.contactForm = {...this.defaultContactForm};
                    this.saving = false;
                    this.snackbar = true;
                    this.snackbarMessage = "Thank you for contacting us. We'll get back to you within one business day.";
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.contactForm = {...this.defaultContactForm};
    },
};
</script>

<style scoped>
::v-deep .v-card__title {
    color: white;
    background-color: var(--apsto-purple);
}
</style>
