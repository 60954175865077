<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col class="px-5 text-h5-mulish"> Add a School</v-col>
        </v-row>
        <v-row class="mt-n3 mt-sm-0">
            <v-col
                class="px-5"
                md="6"
            >
                <v-form :disabled="saving">
                    <school-form :school="school"/>
                    <v-btn
                        :block="!!isMobile"
                        :to="{ name: 'schools' }"
                        class="cancel-button" rounded
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        :block="!!isMobile"
                        :class="isMobile ? 'mt-5' : 'ml-2'"
                        class="save-by-cancel-button"
                        color="primary"
                        rounded
                        @click="submit"
                    >
                        Save
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import SchoolForm from "../../../../components/school/SchoolForm";

import {POST_SCHOOL} from "../../../../store/actions.type";

export default {
    name: "SchoolCreate",
    components: {
        Loading,
        SchoolForm
    },
    data() {
        return {
            school: {
                address: {
                    street1: "",
                    street2: "",
                    city: "",
                    lat: "",
                    lon: "",
                    postal: "",
                    region_id: 3
                },
                physical_address: {
                    street1: "",
                    street2: "",
                    city: "",
                    lat: "",
                    lon: "",
                    postal: "",
                    region_id: 3
                },

            },
            loading: true,
            possibleFields: [
                "city",
                "cityMailing",
                "contact_phone",
                "fax",
                "name",
                "phone",
                "postal",
                "postalMailing",
                "region_id",
                "region_idMailing",
                "street1",
                "street1Mailing",
                "street2",
                "street2Mailing",
                "title",
                "website",

            ],
            saving: false
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("schools");
        },
        errors() {
            return this.$store.getters.getErrors("schools");
        },
    },
    methods: {
        submit() {
            this.saving = true;
            this.$store.dispatch(POST_SCHOOL, this.school)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: 'schools'})
                })
                .catch((response) => {
                    console.log(response);
                    //Scroll to error
                    console.log("errors: ", this.errors);
                    console.log("possibleFields: ", this.possibleFields);
                    this.possibleFields.every((field) => {
                        if (this.errors[field]) {
                            field = field.split('.').pop();
                            const el = document.getElementById(field);
                            if (el) {
                                el.scrollIntoView({block: "center"});
                                return false;
                            }
                        }
                        return true;
                    });
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.loading = false;
    }
}
</script>

