<template>
    <v-row>
        <v-col class="text-center mt-10">
            <h2>Loading...</h2>
            <v-progress-circular
                :size="50"
                :width="5"
                class="mt-5"
                color="#55A9AD"
                indeterminate
            />
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Loading",
}
</script>
