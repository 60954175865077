//Views
import ApprovedSchool from "../views/website/ApprovedSchool";
import Dashboard from "../views/Dashboard";
import DeniedAccess from "../views/DeniedAccess";
import NotFound from "../views/NotFound";
import OnlineCharitableDonationCreate from "../views/donor-guests/OnlineCharitableDonationCreate";
import OnlineDonationCreate from "../views/donor-guests/OnlineDonationCreate";
import OnlineDonationPageCreate from "../views/donor-guests/OnlineDonationPageCreate";
import RequiresPrimaryAdmin from "../views/RequiresPrimaryAdmin";
import ResetPassword from "../components/shared/ResetPassword";
import SignInFromLink from "../views/users/SignInFromLink";
import Website from "../views/Website";
import OnlineCharitableDonationPageCreate from "../views/donor-guests/OnlineCharitableDonationPageCreate.vue";

export default [
    {
        path: '/',
        name: 'home',
        alias: ['', '/home'],
        component: Website,
        meta: {
            showAnnouncements: true,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            showAnnouncements: true,
            topBarTitle: 'Portal'
        },
    },
    {
        path: '/approved-schools/:school_id',
        name: 'approved-school',
        component: ApprovedSchool,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/online-charitable-donation',
        name: 'online-charitable-donation',
        component: OnlineCharitableDonationCreate,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/online-charitable-donation-page/:promo_id',
        name: 'online-charitable-donation-page',
        component: OnlineCharitableDonationPageCreate,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/online-donation',
        name: 'online-donation',
        component: OnlineDonationCreate,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/online-donation-page/:promo_id',
        name: 'online-donation-page',
        component: OnlineDonationPageCreate,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/reset-password/:token',
        name: 'passwordreset',
        component: ResetPassword,
    },
    {
        path: '/sign-in-from-link',
        name: 'sign-in-from-link',
        component: SignInFromLink,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/denied-access',
        name: 'denied-access',
        component: DeniedAccess,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: NotFound,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/requires-primary-admin',
        name: 'requires-primary-admin',
        component: RequiresPrimaryAdmin,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
        }
    },
    {
        path: '/:slug',
        name: 'homeslug',
        alias: ['', '/home'],
        component: Website,
        meta: {
            showAnnouncements: true,
        }
    },

]
