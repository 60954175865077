var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-4 py-2"},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-radio-group',{attrs:{"error":!!_vm.errors['comports_to_law'],"error-messages":_vm.errors['comports_to_law'] ? _vm.errors['comports_to_law'] : null,"column":""},on:{"change":function($event){return _vm.updateSchoolApplicationRadio(
                        'comports_to_law',
                        _vm.schoolApplication.comports_to_law
                    )}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary darken-4 pb-2"},[_vm._v("\n                        Is this a qualified school, a nongovernmental primary or secondary\n                        school that does not discriminate on the basis of race, color,\n                        disability, familial status or national origin and that satisfies\n                        the requirements prescribed by law for private schools in Arizona?\n                    ")])]},proxy:true}]),model:{value:(_vm.schoolApplication.comports_to_law),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "comports_to_law", $$v)},expression:"schoolApplication.comports_to_law"}},[_vm._v(" "),_c('v-radio',{attrs:{"value":1,"color":"secondary","label":"Yes"}}),_vm._v(" "),_c('v-radio',{attrs:{"value":0,"color":"secondary","label":"No"}})],1),_vm._v(" "),_c('v-radio-group',{attrs:{"error":!!_vm.errors['fingerprints'],"error-messages":_vm.errors['fingerprints'] ? _vm.errors['fingerprints'] : null,"column":""},on:{"change":function($event){return _vm.updateSchoolApplicationRadio(
                        'fingerprints',
                        _vm.schoolApplication.fingerprints
                    )}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary darken-4 pb-2"},[_vm._v("\n                        Does this qualified school require all teaching staff and\n                        personnel that have unsupervised contact with students to be\n                        fingerprinted?\n                    ")])]},proxy:true}]),model:{value:(_vm.schoolApplication.fingerprints),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "fingerprints", $$v)},expression:"schoolApplication.fingerprints"}},[_vm._v(" "),_c('v-radio',{attrs:{"value":1,"color":"secondary","label":"Yes"}}),_vm._v(" "),_c('v-radio',{attrs:{"value":0,"color":"secondary","label":"No"}}),_vm._v(" "),_c('transition',{attrs:{"duration":500,"name":"slide-fade"}},[(_vm.schoolApplication.fingerprints)?_c('div',{staticClass:"mb-n8 mt-2"},[_c('form',[_c('v-text-field',{attrs:{"id":"fingerprints_provider","error":!!_vm.errors['fingerprints_provider'],"error-messages":_vm.errors['fingerprints_provider']
                                    ? _vm.errors['fingerprints_provider']
                                    : null,"background-color":"white","filled":"","label":"Name of the company / organization you use"},on:{"change":function($event){return _vm.updateSchoolApplicationTextField()}},model:{value:(_vm.schoolApplication.fingerprints_provider),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "fingerprints_provider", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"schoolApplication.fingerprints_provider"}})],1)]):_vm._e()])],1),_vm._v(" "),_c('v-radio-group',{attrs:{"error":!!_vm.errors['publish_test_scores'],"error-messages":_vm.errors['publish_test_scores'] ? _vm.errors['publish_test_scores'] : null,"column":""},on:{"change":function($event){return _vm.updateSchoolApplicationRadio(
                        'publish_test_scores',
                        _vm.schoolApplication.publish_test_scores
                    )}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary darken-4 pb-2"},[_vm._v("\n                        Does this qualified school annually administer and make available\n                        to the public the aggregate test scores of its students on a\n                        nationally standardized norm-referenced achievement test?\n                    ")])]},proxy:true}]),model:{value:(_vm.schoolApplication.publish_test_scores),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "publish_test_scores", $$v)},expression:"schoolApplication.publish_test_scores"}},[_vm._v(" "),_c('v-radio',{attrs:{"value":1,"color":"secondary","label":"Yes"}}),_vm._v(" "),_c('v-radio',{attrs:{"value":0,"color":"secondary","label":"No"}}),_vm._v(" "),_c('transition',{attrs:{"duration":500,"name":"slide-fade"}},[(_vm.schoolApplication.publish_test_scores)?_c('div',{staticClass:"mb-n8 mt-2"},[_c('form',[_c('v-text-field',{attrs:{"id":"test_name","error":!!_vm.errors['test_name'],"error-messages":_vm.errors['test_name'] ? _vm.errors['test_name'] : null,"background-color":"white","filled":"","label":"Name of the tests you use"},on:{"change":function($event){return _vm.updateSchoolApplicationTextField()}},model:{value:(_vm.schoolApplication.test_name),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "test_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"schoolApplication.test_name"}}),_vm._v(" "),_c('v-text-field',{staticClass:"mt-n2",attrs:{"id":"how_scores_published","error":!!_vm.errors['how_scores_published'],"error-messages":_vm.errors['how_scores_published']
                                    ? _vm.errors['how_scores_published']
                                    : null,"background-color":"white","filled":"","label":"How are these scores published?"},on:{"change":function($event){return _vm.updateSchoolApplicationTextField()}},model:{value:(_vm.schoolApplication.how_scores_published),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "how_scores_published", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"schoolApplication.how_scores_published"}})],1)]):_vm._e()])],1),_vm._v(" "),_c('v-radio-group',{attrs:{"error":!!_vm.errors['charge_for_all_students'],"error-messages":_vm.errors['charge_for_all_students']
                    ? _vm.errors['charge_for_all_students']
                    : null,"column":""},on:{"change":function($event){return _vm.updateSchoolApplicationRadio(
                        'charge_for_all_students',
                        _vm.schoolApplication.charge_for_all_students
                    )}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary darken-4 pb-2"},[_vm._v("\n                        Does this qualified school charge tuition for all students\n                        (notwithstanding any types of scholarships or discounts it may\n                        offer)?\n                    ")])]},proxy:true}]),model:{value:(_vm.schoolApplication.charge_for_all_students),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "charge_for_all_students", $$v)},expression:"schoolApplication.charge_for_all_students"}},[_vm._v(" "),_c('v-radio',{attrs:{"value":1,"color":"secondary","label":"Yes"}}),_vm._v(" "),_c('v-radio',{attrs:{"value":0,"color":"secondary","label":"No"}})],1),_vm._v(" "),_c('div',{staticClass:"text--primary my-4 pt-2"},[_vm._v("\n                We would like to be eligible to receive scholarships in the following\n                tax credit programs:\n            ")]),_vm._v(" "),_c('v-checkbox',{attrs:{"error":!!_vm.errors['disabled_displaced'],"error-messages":_vm.errors['disabled_displaced'] ? _vm.errors['disabled_displaced'] : null,"color":"secondary"},on:{"change":function($event){return _vm.updateSchoolApplicationCheckbox('disabled_displaced')}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Disabled/Displaced Tax Credit Scholarship")])]},proxy:true}]),model:{value:(_vm.schoolApplication.disabled_displaced),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "disabled_displaced", $$v)},expression:"schoolApplication.disabled_displaced"}}),_vm._v(" "),_c('v-checkbox',{attrs:{"error":!!_vm.errors['low_income_corporate'],"error-messages":_vm.errors['low_income_corporate']
                    ? _vm.errors['low_income_corporate']
                    : null,"color":"secondary"},on:{"change":function($event){return _vm.updateSchoolApplicationCheckbox('low_income_corporate')}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Low-Income Corporate Tax Credit Scholarship")])]},proxy:true}]),model:{value:(_vm.schoolApplication.low_income_corporate),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "low_income_corporate", $$v)},expression:"schoolApplication.low_income_corporate"}}),_vm._v(" "),_c('v-checkbox',{attrs:{"error":!!_vm.errors['original'],"error-messages":_vm.errors['original'] ? _vm.errors['original'] : null,"color":"secondary"},on:{"change":function($event){return _vm.updateSchoolApplicationCheckbox('original')}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Original Tax Credit Scholarship")])]},proxy:true}]),model:{value:(_vm.schoolApplication.original),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "original", $$v)},expression:"schoolApplication.original"}}),_vm._v(" "),_c('v-checkbox',{attrs:{"error":!!_vm.errors['switcher'],"error-messages":_vm.errors['switcher'] ? _vm.errors['switcher'] : null,"color":"secondary"},on:{"change":function($event){return _vm.updateSchoolApplicationCheckbox('switcher')}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Switcher (Overflow/Plus) Tax Credit Scholarship")])]},proxy:true}]),model:{value:(_vm.schoolApplication.switcher),callback:function ($$v) {_vm.$set(_vm.schoolApplication, "switcher", $$v)},expression:"schoolApplication.switcher"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }