/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {CLEAR_ERRORS_ACTION} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_ERROR} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        website: {
            message: null,
            errors: []
        },
        login: {
            message: null,
            errors: []
        },
        register: {
            message: null,
            errors: []
        },
        forgot: {
            message: null,
            errors: []
        },
        reset: {
            message: null,
            errors: []
        },
        admin: {
            message: null,
            errors: []
        },
        adminSchools: {
            message: null,
            errors: []
        },
        application: {
            message: null,
            errors: []
        },
        brochureRequest: {
            message: null,
            errors: []
        },
        companies: {
            message: null,
            errors: []
        },
        companyAdmins: {
            message: null,
            errors: []
        },
        contact: {
            message: null,
            errors: []
        },
        corporatePledges: {
            message: null,
            errors: []
        },
        donors: {
            message: null,
            errors: []
        },
        employeeDonations: {
            message: null,
            errors: []
        },
        fiscalYears: {
            message: null,
            errors: []
        },
        households: {
            message: null,
            errors: []
        },
        promos: {
            message: null,
            errors: []
        },
        householdAdmins: {
            message: null,
            errors: []
        },
        invites: {
            message: null,
            errors: []
        },
        preregistration: {
            message: null,
            errors: []
        },
        regions: {
            message: null,
            errors: []
        },
        schools: {
            message: null,
            errors: []
        },
        schoolAdmins: {
            message: null,
            errors: []
        },
        schoolApplication: {
            message: null,
            errors: []
        },
        schoolDropdown: {
            message: null,
            errors: []
        },
        schoolGrades: {
            message: null,
            errors: []
        },
        schoolStudent: {
            message: null,
            errors: []
        },
        schoolYears: {
            message: null,
            errors: []
        },
        users: {
            message: null,
            errors: []
        },
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    getMessage: (state) => (target) => {
        return state[target].message ? state[target].message : null;
    },
    getErrors: (state) => (target) => {
        return typeof state[target].errors != "undefined" ? state[target].errors : null;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [CLEAR_ERRORS_ACTION](context) {
        context.commit(CLEAR_ERRORS);
    }
}

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [CLEAR_ERRORS](state) {
        Object.assign(state, getDefaultState())
    },
    [SET_ERROR](state, {
        target,
        message,
        errors
    }) {
        state[target].message = message;
        state[target].errors = errors;
    },
};

export default {
    getters,
    mutations,
    state
}
