<template>
  <v-card
    class="overflow-y-hidden overflow-x-hidden"
    :style="mobileOrDesktop"
  >
    <conversations />
  </v-card>
</template>

<script>
import { REGET_CONVERSATIONS } from '../../../store/actions.type';

import Conversations from "./Conversations";

export default {
  name: "ConversationContainer",
  components: {
    Conversations,
  },
  created() {
    this.$store.dispatch(REGET_CONVERSATIONS);
  },
  computed: {
    mobileOrDesktop(){
      if (this.$vuetify.breakpoint.width > 425){
        return 'height: 75vh; width: 400px;';
      }
      else {
        return 'height: 78vh; width: 90vw;'
      }
    },
  },
};
</script>
