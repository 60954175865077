<template>
  <div class="mr-5">
    <v-row
      align="center"
      class="ml-1"
    >
      <v-col md="6">
        <admin-header
          :second-line="'For the period from ' + $route.params.date_from + ' through ' + $route.params.date_thru"
          page-title="Donor Donations Report"
        />
      </v-col>
      <v-spacer/>
      <v-col
        class="pa-0"
        cols="auto"
      >
        <v-btn
          :href="'/api/admin/donor-donations/download-transactions/' + $route.params.date_from + '/' + $route.params.date_thru"
          color="primary"
          rounded
        >
          <v-icon left> mdi-download</v-icon>
          Download This List
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
      :headers="headers"
      :items="adminDonorDonations"
      :items-per-page="-1"
      :loading="loading"
      :search="search"
      :single-select="true"
      class="elevation-2 mt-3"
      fixed-header
      group-by="tax_year"
      group-desc
      height="100vh"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th colspan="10">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          Tax Year: {{ items[0].tax_year }}
        </th>
      </template>
      <template v-slot:body.prepend>
        <tr
          v-for="(taxYear, index) in totalsByTaxYear"
          :key="index"
          class="totals"
        >
          <th colspan="3">Totals for Tax Year {{ taxYear.taxYear }}</th>
          <th class="text-right">{{ taxYear.amount | formatCurrencyWithCents }}</th>
        </tr>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There were no transactions during the date span you selected.
                </span>
      </template>
      <template #item.amount="{ item }">
        {{ item.amount | formatNumberWithCents }}
      </template>
      <template #item.type="{ item }">
        <div v-if="item.type === 1">
          Individual Donation
        </div>
        <div v-else-if="item.type === 2">
          Payroll Donation
        </div>
        <div v-else-if="item.type === 3">
          Recurring Donation
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../../main/AdminHeader";
import Loading from "../../../../components/shared/Loading";
import {GET_ADMIN_DONOR_DONATIONS} from "../../../../store/actions.type";

export default {
  name: "AdminDonorDonationsActivityReport",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      headers: [
        {text: 'Name', value: 'donor_name'},
        {text: 'Date', align: 'start', value: 'created_at'},
        {text: 'Type', align: 'start', value: 'type'},
        {text: 'Amount', align: 'end', value: 'amount'},
      ],
      loading: true,
      search: ''
    }
  },
  computed: {
    totalsByTaxYear() {
      return this.$store.getters.currentAdminDonorDonations['totalsByTaxYear'];
    },
    adminDonorDonations() {
      return this.$store.getters.currentAdminDonorDonations['donorDonations'];
    },
  },
  methods: {
    getDonorDonations() {
      let date_from = this.$route.params.date_from;
      let date_thru = this.$route.params.date_thru;
      this.$store.dispatch(GET_ADMIN_DONOR_DONATIONS, {date_from, date_thru}).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getDonorDonations();
  }
}
</script>


<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.totals) {
  background-color: transparent;
}

::v-deep tr.totals {
  background-color: var(--apsto-teal) !important;
}

::v-deep tr.totals > th {
  color: white;
  font-size: .90rem !important;
}
</style>
