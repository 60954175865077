<template>
    <div>
        <v-row v-if="disableCheckbox">
            <v-col>
                <div class="box-with-background">
                    <div style="font-size: 1.125rem;">Please clear the following items before continuing:</div>
                    <ul>
                        <li v-if="schoolApplication.charge_for_all_students === null ||
                            schoolApplication.comports_to_law === null ||
                            schoolApplication.fingerprints === null ||
                            schoolApplication.publish_test_scores === null">
                            One or more items on questionnaire have not been answered.
                        </li>
                        <!--                        <li v-if="schoolApplication.fingerprints && !schoolApplication.fingerprints_provider">-->
                        <!--                            Specify questionnaire fingerprints provider.-->
                        <!--                        </li>-->
                        <!--                        <li-->
                        <!--                            v-if="schoolApplication.publish_test_scores && (!this.schoolApplication.test_name || !this.schoolApplication.how_scores_published)">-->
                        <!--                            Complete questionnaire test scores information.-->
                        <!--                        </li>-->
                        <li v-if="schoolApplication.disabled_displaced === null &&
                            schoolApplication.low_income_corporate === null &&
                            schoolApplication.original === null &&
                            schoolApplication.switcher === null">
                            Check at least one tax credit program on questionnaire.
                        </li>
                        <li v-if="!schoolApplication.school_levels.filter(grade => grade.tuition_amount).length">
                            Add at least one tuition amount.
                        </li>
                    </ul>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="!disableCheckbox">
            <v-col
                md="6"
                style="font-size: 1.125rem;"
            >
                On behalf of our organization, I hereby confirm that:
                <ul>
                    <li>
                        My responses are answered correctly.
                    </li>
                    <li>
                        APSTO tuition awards are to be used solely for the tuition expense of named students. Tuition
                        tax credit awards are only available to full-time students in qualifying K-12 grades.
                        Registration fees, extracurricular charges, etc. are not applicable for the tuition tax credit
                        awards as stated in
                        <a
                            href="https://www.azleg.gov/ars/43/01089.htm"
                            target="_blank"
                        >ARS-43-1089</a>,
                        <a
                            href="https://www.azleg.gov/ars/43/01089-03.htm"
                            target="_blank"
                        >43-1089.03</a>,
                        <a
                            href="https://www.azleg.gov/viewdocument/?docName=https://www.azleg.gov/ars/43/01601.htm"
                            target="_blank"
                        >43-1601 thru 1605.</a>
                    </li>
                    <li>
                        The Partner School is allowed to accept tuition tax credit awards up to the annual tuition cost
                        for the named student.
                        If the student receives tax credit awards in excess of that year’s annual tuition cost from
                        APSTO or various STOs, then the Partner School must immediately refund tax credit award back to
                        last awarding STO. The Partner School may not ‘roll over’ any excess tax credit awards for the
                        following school year.
                    </li>
                    <li>
                        If the awarded student leaves the Partner School, the school must immediately return the unused
                        portion of tuition award to APSTO. The tuition award belongs to the named student, not the
                        school. The school may not reallocate the funds to another student.
                    </li>
                    <li>
                        The Partner School does not receive direct funding as a public or charter school. This school is
                        not a charter school, is not operated by a charter school, and does not offer programs operated
                        by a charter school.
                    </li>
                    <li>
                        The Partner School agrees that a student may not receive a tuition tax credit award and an
                        Empowerment Scholarship Account (ESA) from the Arizona Department of Education during the same
                        period of time in the academic year. If a student receives both award types for the same
                        identified period, the Partner School agrees to return the tuition tax credit award to APSTO.
                        The Partner School also agrees to notify APSTO of any APSTO recipient that become an ESA
                        recipient throughout the school year.
                    </li>
                    <li>
                        The Partner School agrees to not facilitate or encourage parents to swap donations with other
                        parents.
                    </li>
                    <li>
                        The Partner School agrees that Kindergarten students must be age 5 by January 1st of their
                        kindergarten school year in order to participate in any tax credit programs.
                    </li>
                </ul>
                <v-checkbox
                    v-model="schoolApplication.agree_to_terms"
                    :error="!!errors['agree_to_terms']"
                    :error-messages="errors['agree_to_terms'] ? errors['agree_to_terms'] : null"
                    color="secondary"
                    @change="updateSchoolApplicationCheckbox('agree_to_terms')"
                >
                    <template v-slot:label>
                        <div
                            class="text-apsto-teal font-weight-black"
                            style="font-size: 1.125rem;"
                        >
                            I have read and agree to all the above terms
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {PATCH_SCHOOL_APPLICATION_CHECKBOX} from "../../../store/actions.type";

export default {
    name: "SchoolTerms",
    computed: {
        schoolApplication() {
            return this.$store.getters.currentSchoolApplication;
        },
        disableCheckbox() {

            if (this.schoolApplication.charge_for_all_students === null ||
                this.schoolApplication.comports_to_law === null ||
                this.schoolApplication.fingerprints === null ||
                this.schoolApplication.publish_test_scores === null) {
                return true;
            }

            // if (this.schoolApplication.fingerprints && !this.schoolApplication.fingerprints_provider) {
            //     return true;
            // }
            //
            // if (this.schoolApplication.publish_test_scores &&
            //     (!this.schoolApplication.test_name || !this.schoolApplication.how_scores_published)) {
            //     return true;
            // }

            if (!this.schoolApplication.school_levels) {
                return true;
            }

            if (this.schoolApplication.school_levels.filter(grade => grade.tuition_amount).length === 0) {
                return true;
            }

            return this.schoolApplication.disabled_displaced === null &&
                this.schoolApplication.low_income_corporate === null &&
                this.schoolApplication.original === null &&
                this.schoolApplication.switcher === null;

        },
        error() {
            return this.$store.getters.getMessage("schoolApplication");
        },
        errors() {
            return this.$store.getters.getErrors("schoolApplication");
        },
    },
    methods: {
        updateSchoolApplicationCheckbox(field_name) {
            this.saving = true;
            let school_application_id = this.$route.params.application_id;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(PATCH_SCHOOL_APPLICATION_CHECKBOX,
                {
                    school_id,
                    school_application_id,
                    field_name,
                }
            )
                .then(() => {
                    this.saving = false;
                });
        },
    }
}
</script>

<style scoped>
ul li {
    font-size: 1rem;
    margin-bottom: 20px;
}

ul li:first-of-type {
    margin-top: 20px;
}
</style>
