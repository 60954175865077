<template>
    <v-card>
        <v-card-title>
            <span class="text-h5 pb-4">
                {{ this.editedItem.id ? "Edit Prior Donation" : "Add Prior Donation" }}
            </span>
        </v-card-title>
        <v-card-text style="
        background-color: var(--apsto-teal-light);
        padding-left: 22px;
        padding-right: 22px;
      ">
            <v-row>
                <v-col
                    class="mt-2"
                    cols="12"
                >
                    <v-card class="pa-4">
                        <v-text-field
                            id="amount"
                            v-model.number="editedItem.amount"
                            :error="!!errors['amount']"
                            :error-messages="errors['amount'] ? errors['amount'] : null"
                            label="Donation Amount"
                        />
                        <v-text-field
                            id="sto_name"
                            v-model.trim="editedItem.sto_name"
                            :error="!!errors['sto_name']"
                            :error-messages="errors['sto_name'] ? errors['sto_name'] : null"
                            label="STO Name (Optional)"
                        />
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions class="py-4">
            <v-spacer/>
            <v-btn
                class="cancel-button"
                rounded
                @click="$emit('done')"
            > Cancel
            </v-btn>
            <v-btn
                class="save-by-cancel-button"
                color="primary"
                rounded
                @click="save"
            > Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {
    GET_DONOR_PRIOR_DONATIONS,
    PATCH_DONOR_PRIOR_DONATION,
    POST_DONOR_PRIOR_DONATION,
} from "../../store/actions.type";

export default {
    name: "PriorDonation",
    data: () => ({
        saving: false,
    }),
    props: {
        adminCreatingDonorDonation: {
            default: false,
            required: false,
        },
        editedItem: {
            type: Object,
            required: true,
        },
        email: {
            type: String,
            required: true
        },
        selectedTaxYear: {
            type: Number,
            required: true,
        },
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
    },
    methods: {
        save() {
            this.saving = true;
            let priorDonation = this.editedItem;
            priorDonation.admin_creating_donor_donation = this.adminCreatingDonorDonation;
            priorDonation.id = this.editedItem.id ? this.editedItem.id : null;
            priorDonation.tax_year = this.selectedTaxYear;
            this.$store.dispatch(
                this.editedItem.id
                    ? PATCH_DONOR_PRIOR_DONATION
                    : POST_DONOR_PRIOR_DONATION,
                priorDonation
            )
                .then(() => {
                    let email = this.email;
                    let adminCreatingDonorDonation = this.adminCreatingDonorDonation;
                    this.$store.dispatch(GET_DONOR_PRIOR_DONATIONS, {email, adminCreatingDonorDonation});
                    this.saving = false;
                    this.$emit("done");

                });
        },
    },
};
</script>

<style scoped>
.errors-message {
    color: red;
    padding-left: 5px;
}
</style>
