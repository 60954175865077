<template>
    <v-row
        class="mt-n6"
    >
        <v-col>
            <v-card-title v-if="!isMobile">
                <router-link to="/dashboard/donors">
                    <span class="text-apsto-teal">{{ user.name }} /&nbsp;</span>
                </router-link>
                <div class="text-apsto-purple">
                    {{ pageTitle }}
                </div>
            </v-card-title>
            <div
                v-if="isMobile"
                class="mx-2 my-4 mobile-text"
            >
                <router-link to="/dashboard/donors">
                    <span class="text-apsto-teal">{{ user.name }}</span>
                </router-link>
                <div class="text-apsto-purple">
                    {{ pageTitle }}
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "UserHeader",
    props: {
        pageTitle: {
            required: true,
            type: String
        }
    },
    computed: {
        user() {
            return this.$store.getters.currentUser;
        }
    },
};
</script>

<style scoped>
.mobile-text {
    font-size: 1.25rem;
    letter-spacing: .25px;
    line-height: 32px;
}</style>

