<template>
  <div>
    <component :is="currentLayout.template" />
    
    <v-fab-transition v-if="isAdmin && $vuetify.breakpoint.mdAndUp">
      <v-btn
        :disabled="!fabEnabled"
        bottom
        right
        fab
        fixed
        :color="editMode ? 'green' : 'red'"
        @click="toggleEditMode"
      >
        <v-icon>{{ editMode ? "mdi-check" : "mdi-pencil" }}</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import Basic from "../components/website/layouts/Basic";
import { TOGGLE_EDIT_MODE } from "../store/mutations.type";
import Faq from "../components/website/layouts/Faq";
import NotFound from "./NotFound";

//import SchoolSearch from "../components/website/layouts/SchoolSearch";
//import Stepper from "../components/website/layouts/Stepper";
import WebFooter from "../components/website/components/WebFooter";
import { GET_SITE_DOCUMENTS_BY_SLUGS } from '../store/actions.type';

export default {
  name: "Website",
  components: {
    Basic,
    Faq,
    NotFound
  },
  data() {
    return {
      addPageDialogOpen: false,
    };
  },
  
  computed: {
    isAdmin() {
        if (this.$store.getters.hasRole('site-admin')) return true;
        if (this.$store.getters.hasRole('super-admin')) return true;
        return false;
    },
    editMode() {
      return this.$store.getters.currentEditMode;
    },
    fabEnabled() {
      return this.$store.getters.currentFabEnabled;
    },
    currentLayout() {
      return this.$store.getters.currentLayout(this.$route.params.slug);
    },
  },
  methods: {
    toggleEditMode() {
      this.getSiteDocuments();
      this.$store.commit(TOGGLE_EDIT_MODE);
    },
    getSiteDocuments() {
      this.$store.dispatch(GET_SITE_DOCUMENTS_BY_SLUGS)
      .then(() => {
          this.loading = false;
      }).catch((e)=> {
          console.log("Error getting site document slugs: ");
          console.log(e);
      });
    }
  },
};
</script>
