<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row v-if="isMobile">
            <v-col>
                <company-header page-title="Employees Form A-4C" />
            </v-col>
        </v-row>

        <v-row
            v-if="!isMobile"
            align="center"
        >
            <v-col md="6">
                <company-header page-title="Employees Form A-4C" />
            </v-col>
        </v-row>

        <v-row :class="isMobile ? 'mt-n5' : 'mt-n10'">
            <v-col md="6">
                <v-data-table
                    :class="isMobile ? '' : 'ml-4 mt-5'"
                    :disable-sort="isMobile"
                    :headers="headers"
                    :hide-default-footer="isMobile"
                    :items="employeesFormA4C"
                    :single-select="true"
                    class="elevation-2"
                    item-key="id"
                >
                    <template #item.actions="{ item }">
                        <v-tooltip
                            bottom
                            color="secondary"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <a
                                    :href="`/api/companies/${company.id}/employee-donations/download/${item.id}/form-a4c/pdf`"
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon color="secondary">fas fa-file-pdf</v-icon>
                                </a>
                            </template>
                            <span>Download Form A-4C</span>
                        </v-tooltip>
                    </template>
                    <template #item.created_at="{ item }">
                        <div>
                            {{ displayDate(item.created_at) }}
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <span class="text-apsto-teal">
                            No data added. Click button above.
                        </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import CompanyHeader from "../../../components/company/CompanyHeader";
import { GET_EMPLOYEES_FORM_A4C } from "../../../store/actions.type";

export default {
    name: "EmployeeFormA4CList",
    components: {
        CompanyHeader,
        Loading
    },
    data() {
        return {
            loading: true,
        }
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        },
        employeesFormA4C() {
            return this.$store.getters.currentEmployeesFormA4C;
        },
        headers() {
            return [
                { text: 'Employee', value: 'name' },
                { text: 'Date Signed', value: 'created_at' },
                { text: 'Options', value: 'actions', align: 'center', sortable: false }
            ]
        },
    },
    methods: {
        getEmployeesFormA4C() {
            let company_id = this.$route.params.company_id;
            this.$store.dispatch(GET_EMPLOYEES_FORM_A4C, company_id)
                .then(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getEmployeesFormA4C();
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent !important;
}
</style>
