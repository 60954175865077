<template>
    <v-row v-if="!editMode">
        <v-col>
            <v-expansion-panels accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-black text-uppercase">{{
                        content.title
                    }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-html="content.body.text" />
                        <template v-if="content.contents && content.contents.length">
                            <div
                                v-for="(subcontents, index) in content.contents"
                                v-bind:key="index"
                            >
                                <component
                                    :is="subcontents.component.component"
                                    :content="subcontents"
                                />
                            </div>
                        </template>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12">
            <v-text-field
                label="Enter Heading"
                :loading="loading"
                class="font-weight-black secondary--text header text-uppercase"
                v-model="localContent.title"
                @change="updateContent"
            />
        </v-col>
        <v-col cols="12">
            <wysiwyg-editor
                v-model="localContent.body.text"
                :disabled="loading"
                @update="updateContent"
            />
            <template v-if="content.contents && content.contents.length">
                <div
                    v-for="(subcontents, index) in content.contents"
                    v-bind:key="index"
                >
                    <component
                        :is="subcontents.component.component"
                        :content="subcontents"
                    />
                </div>
            </template>
        </v-col>
    </v-row>
</template>

<script>
import HowToApply from "./HowToApply";
import Infobox from "./Infobox";
import LowIncomeCorporateTaxCredit from "./LowIncomeCorporateTaxCredit";
import SiteDocument from "./SiteDocument";
import TaxFactors from "./TaxFactors";
import Video from "./Video";
import WysiwygEditor from "../WysiwygEditor";
import { PATCH_CONTENT } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from '../../../../store/mutations.type';

export default {
    name: "Expandable",
    props: {
        content: {
            type: Object,
        },
    },
    components: {
        HowToApply,
        Infobox,
        LowIncomeCorporateTaxCredit,
        SiteDocument,
        TaxFactors,
        Video,
        WysiwygEditor,
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
    },
    data() {
        return {
            loading: false,
            localContent: null,
        };
    },
    created() {
        this.localContent = this.content;
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let content = this.localContent;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
            return;
        },
    },
};
</script>

<style scoped>::v-deep img {
    width: 100%;
}

::v-deep .header input {
    font-weight: 900 !important;
    font-family: var(--font-TITLE) !important;
    font-size: 1.5em;
}</style>
