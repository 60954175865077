<template>
    <v-carousel-item v-if="!editMode">
        <v-sheet height="100%">
            <v-row
                class="fill-height px-16"
                justify="center"
                no-gutters
            >
                <v-col>
                    <v-img
                        v-if="content.body.image"
                        class="mb-2"
                        :src="content.body.image"
                        contain
                        height="150"
                        max-width="300"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="ma-0 fill-height"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="primaryTheme"
                                />
                            </v-row>
                        </template>
                    </v-img>
                    <v-row no-gutters>
                        <v-col cols="auto">
                            <v-icon
                                color="secondary"
                                large
                            >mdi-format-quote-open</v-icon>
                        </v-col>
                        <v-col>
                            <div
                                class="mt-5"
                                v-html="content.body.text"
                            />
                            <div>
                                <strong class="primary--text">- {{ content.title }}</strong>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-sheet>
    </v-carousel-item>

    <v-row
        v-else
        justify="center"
        class="mt-10"
        no-gutters
    >
        <v-col>
            <v-img
                v-if="localContent.body.image"
                class="mb-2 mx-auto"
                :src="localContent.body.image"
                contain
                max-height="150"
                max-width="300"
            />
            <v-row justify="center">
                <v-col
                    cols="12"
                    sm="6"
                    md="5"
                    lg="4"
                >
                    <upload-image @done="imageUploaded" />
                </v-col>
            </v-row>

            <wysiwyg-editor
                v-model="localContent.body.text"
                @update="updateContent"
            />

            <v-text-field
                label="name"
                v-model="localContent.title"
                @blur="updateContent"
            />
        </v-col>
    </v-row>
</template>

<script>
import { PATCH_CONTENT } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from "../../../../store/mutations.type";


import WysiwygEditor from "../WysiwygEditor";
import UploadImage from "../../../shared/UploadImage";

export default {
    name: "Testimonial",
    props: {
        content: {
            type: Object,
        },
    },
    components: {
        WysiwygEditor,
        UploadImage,
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
    },
    data() {
        return {
            loading: false,
            localContent: null,
        };
    },
    created() {
        this.localContent = this.content;
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let content = this.localContent;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
            return;
        },
        imageUploaded(hash) {
            this.localContent.body.image = "/website/uploads/logo/" + hash;
            this.updateContent(true);
        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        },
    },
};
</script>
