// Views
import CompanyAdminInvite from "../views/companies/company-admins/CompanyAdminInvite";
import CompanyAdminsList from "../views/companies/company-admins/CompanyAdminsList";

export default [
    {
        path: "/dashboard/companies/:company_id/admins",
        name: "company-admins-list",
        component: CompanyAdminsList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
    {
        path: "/dashboard/companies/:company_id/admins/invite",
        name: "company-admin-invite",
        component: CompanyAdminInvite,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
]

