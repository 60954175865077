<template>
  <v-card>
    <v-card-title>
      Choose a Wallpaper
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(wallpaper, wallpaper_index) in wallpapers"
          :key="wallpaper_index"
          cols="12"
          md="4"
          sm="6"
        >
          <v-card @click="$emit('done', wallpaper.id)">
            <div
              class="pa-2"
              style="background-color: var(--apsto-black); color: white; text-align: center;"
            >
              {{ wallpaper.name }}
            </div>
            <v-img
              :src="`/website/siteWallpapers/${wallpaper.id}/thumb/`"
              aspect-ratio="1"
              contain
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pb-6 pl-6">
      <v-btn
        class="cancel-button"
        rounded
        @click="$emit('cancel')"
      >CANCEL
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {GET_SITE_WALLPAPERS} from '../../store/actions.type';

export default {
  name: "WallpaperSelector",
  computed: {
    wallpapers() {
      return this.$store.getters.currentSiteWallpapers;
    },
  },
  methods: {
    getSiteWallpapers() {
      this.loading = true;
      let called_from = "promos";
      this.$store.dispatch(GET_SITE_WALLPAPERS, called_from);
      this.loading = false;
    },
  },
  mounted() {
    this.getSiteWallpapers();
    this.loading = false;
  },
}
</script>
