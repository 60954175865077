<template>
    <div>
        <div v-if="currentApplication.members.length && currentApplication.household_qualifies_for_low_income_credit">
            Income Verification
            <small
                class="subtitle-1 mt-2"
                style="line-height: 1rem;"
            >
                <v-row class="pt-1">
                    <v-col md="6">
                        Please submit the first two pages of your
                        <span class="font-weight-black">Arizona Form 140</span>.
                        <div class="mt-2">
                            This is your Arizona personal tax return, not your federal tax return. We need the <span
                                style="color:red"
                            >140</span>, not the 1040 which is federal.
                        </div>
                        <div class="mt-2 teal---text">
                            Please cross out everything but the last 4 digits of your social security number.
                        </div>
                    </v-col>
                </v-row>
            </small>
            <!--      </v-stepper-step>-->
            <!--      <v-stepper-content step="7">-->
            <v-card
                class="mb-5 px-4 py-2"
                color="primaryVeryLight"
                min-height="130px"
            >
                <v-card-subtitle
                    class="ml-n4"
                    style="color: black;"
                >
                    Please click the line below to select a file to upload.
                </v-card-subtitle>
                <v-row>
                    <v-col md="4">
                        <v-row>
                            <v-file-input
                                v-model="file"
                                :rules="rules"
                                accept=".jpg,.jpeg,.pdf,.png"
                                show-size
                                truncate-length="15"
                            ></v-file-input>
                            <v-card-actions class="py-4">
                                <v-spacer />
                                <v-btn
                                    :disabled="saveTaxReturnDisabled"
                                    color="primary"
                                    @click="saveTaxReturn"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-row>
                        <v-card-subtitle
                            v-if="currentApplication.files && currentApplication.files.length"
                            class="teal-text"
                        >
                            <span class="teal---text">Last upload: </span>
                            <a
                                :href="'/household-pdfs/' + currentApplication.files[0].id"
                                target="_blank"
                            >
                                {{ currentApplication.files[0].title }}
                            </a>
                        </v-card-subtitle>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </div>
</template>

<script>
import { POST_HOUSEHOLD_APPLICATION_TAX_RETURN_UPLOAD } from "../../../store/actions.type";

export default {
    name: "RequestTaxReturn",
    computed: {
        currentApplication() {
            return this.$store.getters.currentApplication;
        },
        data: () => ({
            file: null,
        }),
        saveTaxReturnDisabled() {
            return !this.file;
        },
        rules() {
            return [
                v => !!v || "File is required",
                v => (v && v.size < 2000000) || "File size should be less than 2MB!"
            ];
        }
    },
    methods: {
        saveTaxReturn() {
            this.saving = true;
            let application_id = this.currentApplication.id;
            let file = this.file;
            this.$store.dispatch(POST_HOUSEHOLD_APPLICATION_TAX_RETURN_UPLOAD,
                {
                    application_id,
                    file
                }
            )
                .then(() => {
                    this.file = null;
                    this.getSchoolApplication();
                    this.saving = false;
                });
        },
    }
}

</script>


<style scoped></style>
