<template>
    <div>
        <div class="first-heading">Login By Link</div>
        <v-form
            ref="loginByLinkForm"
            v-model="isSignInByEmailFormValid"
            class="mt-5 section"
        >
            <v-text-field
                ref="email"
                v-model="email"
                :error="!!errors && errors.email"
                :error-messages="errors && errors.email ? errors.email : null"
                :rules="rules.email"
                label="Email"
                name="email"
                type="text"
                @focus="clearErrorsAndMessages()"
            />
        </v-form>
        <div
            v-if="displayEmailMessage"
            class="section mt-4"
        >
            We've sent you an email to login with. Please check your spam folder if
            you don't see it.
        </div>
        <div
            v-if="errorMessage"
            class="error-message mt-4"
        >
            {{ errorMessage }}
        </div>
        <v-btn
            :disabled="!isSignInByEmailFormValid"
            block
            class="mt-3"
            color="primary"
            rounded
            @click="sendSignInEmail"
        >
            REQUEST A LOGIN LINK
        </v-btn>
    </div>
</template>

<script>
import {SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE} from "../../store/actions.type";
import {CLEAR_ERRORS, SET_CHECK_DATA_BY_EMAIL_FORM} from "../../store/mutations.type";
import CheckDataByEmailForm from "./CheckDataByEmailForm.vue";

export default {
    name: "LoginByLinkForm",
    components: {CheckDataByEmailForm},

    data() {
        return {
            email: "",
            errorMessage: null,
            password: "",
            remember: false,
            loading: false,
            forgotEmailFlag: false,
            isFormValid: false,
            isSignInByEmailFormValid: false,
            rules: {
                email: [
                    (v) => !!v || "Ex: someone@example.com",
                    (v) =>
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            v
                        ) || "Ex: someone@example.com",
                ],
            },
            displayEmailMessage: false,
            tab: null,
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("login");
        },
        errors() {
            return this.$store.getters.getErrors("login");
        },
    },
    methods: {
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        },
        clearErrorsAndMessages() {
            this.displayEmailMessage = null;
            this.errorMessage = null;
            this.$store.commit(CLEAR_ERRORS);
        },

        sendSignInEmail() {
            let email = this.email;
            this.$store.dispatch(SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE, email)
                .then(() => {
                    this.loading = false;
                    this.displayEmailMessage = true;
                })
                .catch((response) => {
                    this.errorMessage = response.data.message;
                    this.loading = false;
                });

        },
        showCheckDataByEmailForm() {
            this.$store.commit(SET_CHECK_DATA_BY_EMAIL_FORM, true);
        },
    },
};
</script>

<style scoped>
.first-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 1.2rem;
    font-weight: bold;
}

.section {
    background-color: var(--apsto-teal-very-light);
    border: 1px solid var(--apsto-teal);
    padding: 20px;
}

.error-message {
    border: 1px solid var(--apsto-purple);
}
</style>
