<template>
    <v-row
        :style="announcementStyle"
        align="center"
        class="secondary font-weight-bold white--text text-center"
        justify="center"
        no-gutters
    >
        <v-col cols="auto">
            <transition
                mode="out-in"
                name="fade"
            >
                <div
                    v-for="announcement in announcements"
                    v-if="currentAnnouncement === announcement.id"
                    v-bind:key="announcement.id"
                >
                    <router-link
                        :to="announcement.actionTo"
                        class="announcement"
                    >
                        <v-row
                            align="center"
                            class="py-2"
                            justify="center"
                            no-gutters
                        >
                            <v-col
                                v-if="$vuetify.breakpoint.smAndUp"
                                cols="auto"
                            >
                                <v-avatar
                                    class="mr-2"
                                    color="white"
                                    size="25"
                                >
                                    <v-icon
                                        :icon="announcement.icon"
                                        color="secondary"
                                        size="35"
                                    >
                                        {{ announcement.icon }}
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col
                                class="text-wrap larger-text"
                                cols="auto"
                            >
                                {{ announcement.text }}
                            </v-col>
                            <v-col
                                class="pl-4 pr-2 larger-text"
                                cols="auto"
                            >
                                {{ announcement.actionText }}
                            </v-col>
                            <v-col cols="auto">
                                <v-icon
                                    :icon="announcement.actionIcon"
                                    color="white"
                                    size="30"
                                >
                                    {{ announcement.actionIcon }}
                                </v-icon>
                            </v-col>
                        </v-row>
                    </router-link>
                </div>
            </Transition>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "AnnouncementBar",
    data() {
        return {
            interval: null,
            currentAnnouncement: 1,
            delay: 5000,
        };
    },
    computed: {
        announcements() {
            return this.$store.getters.currentAnnouncements;
        },
        hasAnnouncements() {
            return this.$store.getters.hasAnnouncements;
        },
        sequence() {
            return this.$store.getters.currentAnnouncementSequence;
        },
        announcementStyle() {
            if (this.hasAnnouncements) {
                if (this.$vuetify.breakpoint.width < 550) {
                    return "height: 94px;position:fixed;width: 100%;z-index: 2;top: 0;";
                } else {
                    return "height: 46px;position:fixed;width: 100%;z-index: 2;top: 0;";
                }
            }

            return '';
        }
    },
    methods: {
        startSequence() {
            this.interval = setInterval(() => {
                if (this.currentAnnouncement === this.sequence[this.sequence.length - 1]) {
                    this.currentAnnouncement = 1;
                } else {
                    this.currentAnnouncement++;
                }
            }, this.delay);
        },
    },
    mounted() {
        this.startSequence();
    },
    beforeDestroy() {
        clearInterval(this.interval); // Stop the interval when the component is destroyed
    },
};
</script>

<style scoped>
.announcement {
    color: white;
    font-weight: bold;

}

.larger-text {
    font-size: 1.125rem !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s ease-in;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}</style>
