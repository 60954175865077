<template>
    <v-container style="max-width: 1800px;">
        <v-row
            align="stretch"
            justify="center"
        >
            <template v-if="calloutImages.length">

                <callout-image
                    :content="calloutImage"
                    v-for="calloutImage in calloutImages"
                    :key="calloutImage.id"
                />

            </template>
        </v-row>
    </v-container>
</template>

<script>
import CalloutImage from "./CalloutImages/CalloutImage";

export default {
    name: "CalloutImages",
    components: {
        CalloutImage
    },
    props: {
        content: {
            type: Object,
        },
    },
    computed: {
        calloutImages() {
            return this.content.contents.sort((a, b) => a.sort_order - b.sort_order);
        },
    },
};
</script>
