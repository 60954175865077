<template>
  <v-sheet class="pa-5">
    <loading v-if="loading"/>
    <div v-else>
      <div v-if="withholdingDonationsInForce">
        <v-alert
          class="mb-4"
          color="secondary"
          type="info"
        >
          <div class="text-center">
                        <span class="font-weight-bold">
                            You already have a monthly donation set up. Please return to the previous page to delete it then
                            set up a new monthly donation if you'd like to change anything about it.
                        </span>
            <br/>
            <v-btn
              :to="{ name: 'donor-donations-withholding-list' }"
              class="mt-4"
              color="primary"
              rounded
            >
              {{ isMobile ? 'Return' : 'Return to Payroll Withholding' }}
            </v-btn>
          </div>
        </v-alert>
      </div>
      <v-form
        v-if="!withholdingDonationsInForce"
        :disabled="saving"
      >
        <v-row class="ml-1 mt-0">
          <v-col
            class="pa-0"
            cols="auto"
          >
            <h2>Start Payroll Withholding</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="mt-4 pl-4"
            md="6"
          >
            <div class="donation-header-strip">
              <div
                class="text-h5-mulish"
                style="color: white;"
              >
                Employer Info
              </div>
            </div>
            <div
              class="py-4 font-weight-black"
              style="font-size: 1.125rem; line-height: 1.5"
            >
              Employer Withholding Code (Enter 6 digits in format: AAAAAA)
            </div>
            <v-text-field
              id="withholding_code"
              v-model.trim="donation.withholding_code"
              :error="!!errors['withholding_code']"
              :error-messages="errors['withholding_code'] ? errors['withholding_code'] : null"
              background-color="lightgray"
              filled
              persistent-placeholder
              placeholder="Ask your employer for their 6-Digit code or contact us if they don't know it."
              @focus="clearErrors()"
              @keyup="lookupCompanyCode"
            />
            <div
              v-if="message1"
              class="mt-n2 mb-6"
            >
              {{ message1 }} <span class="text-apsto-teal">{{ message2 }}</span> {{ message3 }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="mt-n7 pl-4 pb-5"
            md="6"
          >
            <div class="donation-header-strip">
                            <span
                              class="text-h5-mulish"
                              style="color: white;"
                            >
                                Your Info
                            </span>
            </div>

            <div v-if="displayTaxYearOption">
              <div
                class="font-weight-black mt-4"
                style="font-size: 1.125rem; line-height: 1.5"
              >
                Tax Year
              </div>
              <div class="mt-2">
                Your donations will be applied to the
                <span class="text-apsto-teal font-weight-black">
                                    {{ selectedTaxYear }}
                                </span>
                tax year, however, you have the option to apply donations made
                between 01/01/{{ selectedTaxYear }} - {{ displayDate(taxDay, true) }} to the
                <span class="text-apsto-teal font-weight-black">
                                    {{ selectedTaxYear - 1 }}
                                </span>
                tax year instead.
              </div>
              <v-checkbox
                v-model="donation.apply_withholding_to_prior_tax_year"
                :error="!!errors['apply_withholding_to_prior_tax_year']"
                :error-messages="errors['apply_withholding_to_prior_tax_year'] ? errors['apply_withholding_to_prior_tax_year'] : null"
                class="bottom-border"
                color="secondary"
              >
                <template v-slot:label>
                  <div style="line-height: 1.5">
                    Apply donations made between 01/01/{{ selectedTaxYear }} -
                    {{ displayDate(taxDay, true) }} to tax year
                    <span class="text-apsto-teal font-weight-black">
                                            {{ selectedTaxYear - 1 }}.
                                        </span>
                  </div>
                </template>
              </v-checkbox>
            </div>

            <filing-status
              v-model="donation.tax_filing_status"
              :selected-tax-year="selectedTaxYear"
              class="bottom-border"
            />

            <prior-donations
              v-if="this.derivedTaxYear === 'current'"
              ref="priorDonations"
              :selected-tax-year="selectedTaxYear"
              class="bottom-border"
              email=""
            />

            <div class="donation-header-strip">
                            <span
                              class="text-h5-mulish"
                              style="color: white;"
                            >
                                Donation
                            </span>
            </div>

            <div
              class="pb-2 mt-6 font-weight-black theme--light v-label"
              style="font-size: 1.125rem"
            >
              Donation Maximum For <span class="text-apsto-teal">{{ selectedTaxYear }}</span>
            </div>

            <donation-amount-explainer
              :explainer="explainer"
              called-from="donorWithholding"
              class="mt-4 pa-4"
              style="border: 1px dashed var(--apsto-teal);"
            />

            <v-currency-field
              id="withholding_maximum"
              v-model.number="donation.withholding_maximum"
              :disabled="!donation.tax_filing_status"
              :error="!!errors['withholding_maximum']"
              :error-messages="errors['withholding_maximum'] ? errors['withholding_maximum'] : null"
              :hint="'Enter the maximum amount you want us to withhold this calendar year up to ' + formatCurrency(explainer.maxTotal) + '.'"
              :rules="[ruleMaxAmount || 'Amount cannot exceed ' + formatCurrency(explainer.maxTotal) + ' (Tuition Credit Maximum shown above).']"
              :success="!!ruleMaxAmount"
              class="mt-4"
              filled
              placeholder="Enter Donation Maximum"
              type="number"
            />

            <recommendations
              v-model="donation.recommendations.original"
              class="bottom-border mt-n6"
              tax-credit-type="original"
            />

            <recommendations
              v-model="donation.recommendations.switcher"
              tax-credit-type="switcher"
            />

            <v-alert
              v-if="hasOpenItems && !saving"
              dismissible
              style="font-size: 1.125rem;"
              type="info"
            >
              Some items haven't been completed. Please answer them to continue.
              <ul class="mt-4">
                <li v-if="!donation.withholding_code">Employer Withholding Code</li>
                <li v-if="!donation.tax_filing_status">Tax Filing Status</li>
                <li v-if="!donation.withholding_maximum">Donation Amount</li>
              </ul>
            </v-alert>

            <div class="mt-6">
              <v-btn
                :to="{ name: 'donor-donations-withholding-list' }"
                class="cancel-button"
                rounded
              > Cancel
              </v-btn>
              <v-btn
                :disabled="disableSubmit"
                class="ml-2"
                color="primary"
                rounded
                @click="submit"
              >
                Submit
              </v-btn>
            </div>
            <v-snackbar
              v-model="snackbar"
              centered
            >
              {{ snackbarMessage }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {
  GET_DONOR_DONATION_TAX_YEAR_FACTORS,
  LOOKUP_COMPANY_BY_EMPLOYER_WITHHOLDING_CODE,
  POST_DONOR_WITHHOLDING_DONATION_SETUP
} from "../../../store/actions.type";
import SchoolSelector from "../../../components/shared/SchoolSelector";
import FilingStatus from "../../../components/donations/FilingStatus";
import PriorDonations from "../../../components/donations/PriorDonations";
import Recommendations from "../../../components/donations/Recommendations";
import DaySelector from "../../../components/shared/DaySelector";
import {CLEAR_ERRORS, SET_DONATION} from "../../../store/mutations.type";
import DonationAmountExplainer from "../../../components/donations/DonationAmountExplainer";

export default {
  name: "DonationWithholdingCreate",
  components: {
    DaySelector,
    DonationAmountExplainer,
    FilingStatus,
    Loading,
    PriorDonations,
    Recommendations,
    SchoolSelector,
  },
  data() {
    return {
      message1: null,
      message2: null,
      message3: null,
      loading: true,
      saving: false,
      snackbar: false,
      snackbarMessage: "",

    };
  },
  computed: {
    derivedTaxYear() {
      return new Date().getMonth() + 1 === 12 ? "next" : "current";
    },
    disableSubmit() {
      return this.saving || this.hasOpenItems
    },
    displayTaxYearOption() {
      return new Date().getMonth() + 1 === 12 || new Date().getMonth() + 1 <= 4;
    },
    donation: {
      get() {
        return this.$store.getters.currentDonation;
      },
      set(donation) {
        this.$store.commit(SET_DONATION, donation);
      },
    },
    explainer() {
      return {
        maxOriginal: this.maxOriginal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        maxSwitcher: this.maxSwitcher(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        maxTotal: this.maxTotal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        selectedTaxYear: this.selectedTaxYear,
        withholdingChargeDay: this.donation.withholding_charge_day,
        taxFilingStatus: this.donation.tax_filing_status,
        totalPriorDonations: this.totalPriorDonations,
      }
    },
    hasOpenItems() {
      return !this.donation.tax_filing_status ||
        !this.donation.withholding_code ||
        !this.donation.withholding_maximum ||
        this.donation.withholding_maximum > this.explainer.maxTotal
    },
    ruleMaxAmount() {
      return this.donation.withholding_maximum <= this.explainer.maxTotal;
    },
    selectedTaxYear() {
      let tax_year_key = this.derivedTaxYear + "_tax_year";
      return parseInt(this.taxYearFactors && this.taxYearFactors[tax_year_key].name)
    },
    taxDay() {
      return this.taxYearFactors.tax_day;
    },
    taxYearFactors() {
      return this.$store.getters.currentTaxYearFactors;
    },
    totalPriorDonations() {
      let priorDonationsArray = this.$store.getters.currentDonorPriorDonations(this.selectedTaxYear)
      let total = 0;
      for (let i = 0; i < priorDonationsArray.length; i++) {
        let amount = parseFloat(priorDonationsArray[i].amount);
        total = total + amount;
      }
      return parseInt(total);
    },
    withholdingDonationsInForce() {
      const taxYearForFilteredCall = this.derivedTaxYear === "current" ? new Date().getFullYear() : new Date().getFullYear() + 1;
      return !!this.$store.getters.currentActiveWithholdingDonationsFiltered(taxYearForFilteredCall).length;
    },
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
  },
  methods: {
    clearErrors: function () {
      this.$store.commit(CLEAR_ERRORS);
    },
    lookupCompanyCode() {
      let withholding_code = this.donation.withholding_code;
      if (withholding_code.length === 6) {
        this.$store.dispatch(LOOKUP_COMPANY_BY_EMPLOYER_WITHHOLDING_CODE, withholding_code)
          .then((data) => {
            this.message1 = data.message1;
            this.message2 = data.message2;
            this.message3 = data.message3;
            this.donation.company_id = data.company_id;
          })
      } else {
        this.message1 = null;
        this.message2 = null;
        this.message3 = null;
        this.donation.company_id = null;
      }
    },
    maxOriginal(taxFilingStatus) {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      let tax_filing_status_key = "max_original_" + taxFilingStatus;
      let max_original = this.taxYearFactors[tax_year_key][tax_filing_status_key];
      return parseInt(max_original);
    },
    maxSwitcher(taxFilingStatus) {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      let tax_filing_status_key = "max_switcher_" + taxFilingStatus;
      let max_switcher = this.taxYearFactors[tax_year_key][tax_filing_status_key];
      return parseInt(max_switcher);
    },
    maxTotal(taxFilingStatus) {
      return this.maxOriginal(taxFilingStatus) + this.maxSwitcher(taxFilingStatus) - this.totalPriorDonations;
    },
    submit() {
      this.saving = true;
      let donation = this.donation;
      this.$store.dispatch(POST_DONOR_WITHHOLDING_DONATION_SETUP, donation)
        .then(({donorSelection}) => {

          this.signWithholding(donorSelection);
          //this.saving = false;
          //this.$router.push({ name: "donor-donations-withholding-list" });
        })
        .catch((response) => {
          this.saving = false;
          console.log('error response: ', response);
          if (response['status'] !== 422) {
            this.snackbarMessage = response['data'];

            this.snackbar = true;
          }
          window.scrollTo(0, 0);
        });
    },
    signWithholding(donation) {
      let self = this;
      var popupFeatures = "width=600,height=700,resizable=yes,scrollbars=yes,status=no,toolbar=no,menubar=no,location=no";
      var popupWindow = window.open('/sign/donor-selection/' + donation.id, 'WithholdingSigningWindow', popupFeatures);
      //if popupWindow closed, reload page
      var timer = setInterval(function () {
        if (popupWindow.closed) {
          clearInterval(timer);
          self.$router.push({name: 'donor-donations-withholding-list'});
        }
      }, 1000);
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch(GET_DONOR_DONATION_TAX_YEAR_FACTORS).then(() => {
      //Set donation to a blank version
      this.$nextTick(() => {
        this.$store.commit(SET_DONATION, {
          amount: 0,
          agree_to_terms: false,
          tax_filing_status: "",
          tax_year: this.derivedTaxYear,
          priorDonations: [],
          recommendations: {
            original: {
              comments: null,
              school_id: null,
              students: [],
            },
            switcher: {
              comments: null,
              school_id: null,
              students: [],
            },
          },
          withholding_code: null,
          withholding_maximum: null,
        });
        this.loading = false;
      });
    });
  },
};
</script>

<style scoped>
.bottom-border {
  border-bottom: 1px dotted var(--apsto-border);
}

::v-deep .v-toolbar__content {
  background-color: var(--apsto-purple-very-light);
}

::v-deep .v-label {
  color: black;
}
</style>
