<template>
    <v-select
        id="year_id"
        v-model="localYear"
        :error="!!errors['year_id']"
        :error-messages="errors['year_id'] ? errors['year_id'] : null"
        :items="years"
        item-text="name"
        item-value="id"
        :label="label"
    >
    </v-select>
</template>

<script>
export default {
    name: "YearSelector",
    props: {
        label: {
            default: "Please select the year this document is for",
            type: String,
            required: false
        },
        value: {
            default: 1,
            type: Number,
            required: true,
        },
        years: {
            type: Array,
            required: true
        }
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
        localYear: {
            get() {
                return this.value
            },
            set(localYear) {
                this.$emit('input', localYear)
            }
        }
    },
};
</script>
