import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_COMPANY,
    GET_COMPANIES,
    GET_COMPANY,
    GET_USER_COMPANIES,
    GET_USER_COMPANY,
    LOOKUP_COMPANY_BY_EMPLOYER_WITHHOLDING_CODE,
    PATCH_COMPANY,
    POST_COMPANY, POST_COMPANY_WITH_CORPORATE_PLEDGE,
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_COMPANIES,
    SET_COMPANY,
    SET_ERROR,
    SET_USER_COMPANIES,
    SET_USER_COMPANY,
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        companies: [],
        company: {},
        organization_types: [
            {id: 0, value: 'Please Select'},
            {id: 1, value: 'C Corporation'},
            {id: 2, value: 'LLC Filing As A Sub S'},
            {id: 3, value: 'Sub S Corporation'},
            {id: 4, value: 'All Other Types'},
        ],
        userCompanies: [],
        userCompany: {},
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentCompanies(state) {
        return state.companies;
    },
    currentCompany(state) {
        return state.company;
    },
    currentUserCompanies(state) {
        return state.userCompanies;
    },
    currentUserCompany(state) {
        return state.userCompany;
    },
    organizationTypeName: (state) => (typeId) => {
        return state.organization_types.filter(type => type.id === typeId)[0].value;
    },
    organizationTypes(state) {
        return state.organization_types;
    },
    organizationTypesExcept: (state) => (typeId) => {
        return state.organization_types.filter(type => type.id !== typeId);
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_COMPANIES](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/admin/companies")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANIES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_COMPANY](context, company_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/companies/${company_id}/edit`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [LOOKUP_COMPANY_BY_EMPLOYER_WITHHOLDING_CODE](context, withholding_code) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/lookup-by-employer-withholding-code/${withholding_code}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_USER_COMPANIES](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/companies")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_COMPANIES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_USER_COMPANY](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_COMPANY, data);
                    resolve(data);
                })
                .catch((error) => {
                    console.log("GET_USER_COMPANY: ", error.response)
                    handleError(context, error, 'companies');
                    reject(error.response);
                });
        });
    },
    [POST_COMPANY](context, company) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/companies", company)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_COMPANY_WITH_CORPORATE_PLEDGE](context, companyWithCorporatePledge) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/companies/with-corporate-pledge", companyWithCorporatePledge)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companies",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_COMPANY](context, company) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/companies/${company.id}`, company)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companies');
                    reject(error.response);
                });
        });
    },
    [DELETE_COMPANY](context, company) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/companies/${company.id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companies');
                    reject(error.response);
                });
        });
    }

};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_COMPANIES](state, data) {
        state.companies = data;
    },
    [SET_COMPANY](state, data) {
        state.company = data;
    },
    [SET_USER_COMPANIES](state, data) {
        state.userCompanies = data;
    },
    [SET_USER_COMPANY](state, data) {
        state.userCompany = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
