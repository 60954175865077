<template>
    <loading v-if="loading" />
    <v-row v-else>
        <v-col>
            <div
                v-if="isMobile"
                class="mx-2 my-4 text-h6-mulish"
            >
                <router-link :to="{ name: 'admin-company-edit', params: { company_id: company.id } }">
                    <span class="text-apsto-teal">Admin / {{ company.name }}</span>
                </router-link>
                <div class="text-apsto-purple text-h7-mulish">
                    {{ pageTitle }}
                </div>
                <div
                    v-if="secondLine"
                    class="text-apsto-purple text-h7-mulish"
                >
                    {{ secondLine }}
                </div>
            </div>
            <v-card-title
                v-if="!isMobile"
                class="ml-n4 mt-n4"
            >
                <router-link :to="{ name: 'admin-company-edit', params: { company_id: company.id } }">
                    <span class="text-apsto-teal">Admin / {{ company.name }} /&nbsp;</span>
                </router-link>
                <div class="text-apsto-purple">
                    {{ pageTitle }}
                </div>
            </v-card-title>
            <div
                v-if="secondLine"
                class="mt-n4 text-apsto-purple text-h7-mulish"
            >
                {{ secondLine }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import { GET_USER_COMPANY } from "../../../store/actions.type";

export default {
    name: "AdminCompanyHeader",
    components: {
        Loading,
    },
    props: {
        pageTitle: {
            required: true,
            type: String
        },
        secondLine: {
            required: false,
            type: String
        }
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        }
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch(GET_USER_COMPANY, this.$route.params.company_id)
            .then(() => {
                this.loading = false;
            });
    },
};
</script>

<style scoped></style>
