/*
Some example mutation verbs
---------------------------
SET
RESET
APPEND
REMOVE
CLEAR
UPDATE
*/

/*------------------------------------*\
    ERRORS
\*------------------------------------*/
export const SET_ERROR = "setError";
export const CLEAR_ERRORS = "clearErrors";

/*------------------------------------*\
    AUTH
\*------------------------------------*/
export const SET_AUTH = "setUser";
export const RESET_AUTH = "logout";

export const SET_USER = "setUser";
export const SET_IDLE_TIMER = "setIdleTimer";

export const SET_CHECK_DATA_BY_EMAIL_FORM = "setCheckDataByEmailForm";
/*------------------------------------*\
    Website
\*------------------------------------*/
export const APPEND_CONTENT = "appendContent";
export const APPEND_PAGE = "appendPage";
export const REMOVE_CONTENT = "removeContent";
export const REMOVE_PAGE = "removePage";
export const SET_ADD_PAGE_DIALOG_STATUS = "setAddPageDialogStatus";
export const SET_ADD_PAGE_TO_GROUP_ID = "setAddPageToGroupID";
export const SET_COMPONENTS = "setComponents";
export const SET_CONTENT = "setContent";
export const SET_CONTENTS = "setContents";
export const SET_EDIT_MODE = "setEditMode";
export const SET_FAB_ENABLED = "setFabEnabled";
export const SET_LAYOUTS = "setLayouts";
export const SET_PAGE = "setPage";
export const TOGGLE_EDIT_MODE = "toggleEditMode";
export const TOGGLE_FAB_ENABLED = "toggleFabEnabled";
export const SET_ANNOUNCEMENTS = "setAnnouncements";

/*------------------------------------*\
    Admin
\*------------------------------------*/
export const SET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID = "setApplicationSchoolStudentByStudentPermanentId";
export const SET_ADMIN_BANK_REPORT_DATES = "setAdminBankReportDates";
export const SET_ADMIN_CORPORATE_PLEDGES = "setAdminCorporatePledges";
export const SET_ADMIN_CORPORATE_PLEDGES_REPORT_DATES = "setAdminCorporatePledgesReportDates";
export const SET_ADMIN_DONOR_DONATIONS = "setAdminDonorDonations";
export const SET_ADMIN_DONOR_DONATIONS_REPORT_DATES = "setAdminDonorDonationsReportDates";
export const SET_ADMIN_HOUSEHOLD_APPLICATIONS = "setAdminHouseholdApplications";
export const CLEAR_ADMIN_HOUSEHOLD_MEMBERS = "clearAdminHouseholdMembers";
export const SET_ADMIN_HOUSEHOLD_MEMBERS = "setAdminHouseholdMembers";
export const SET_ADMIN_LINKED_STUDENT = "setAdminLinkedStudent";
export const SET_ADMIN_LINKED_STUDENT_AWARDS = "setAdminLinkedStudentAwards";
export const SET_ADMIN_LINKED_STUDENT_NAME = "setAdminLinkedStudentName";
export const SET_ADMIN_DEPOSITS = "setAdminDeposits";
export const SET_ADMIN_DEPOSIT = "setAdminDeposit";
export const SET_ADMIN_DEPOSIT_AWARD_REFUNDS = "setAdminDepositAwardRefunds";
export const CLEAR_ADMIN_DEPOSIT_AWARD_REFUNDS = "clearAdminDepositAwardRefunds";
export const CLEAR_AWARDS_BY_SCHOOL_AND_PERIOD = "clearAwardsBySchoolAndPeriod";
export const SET_ADMIN_SCHOOL = "setAdminSchool";
export const SET_ADMIN_SCHOOLS = "setAdminSchools";
export const SET_ADMIN_SCHOOL_APPLICATIONS = "setAdminSchoolApplications";
export const SET_ADMIN_SCHOOL_STUDENTS = "setAdminSchoolStudents";
export const SET_ADMIN_SCHOOL_STUDENT_AWARD = "setAdminSchoolStudentAward";
export const SET_ADMIN_SCHOOL_STUDENT_AWARDS = "setAdminSchoolStudentAwards";
export const SET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD = "setAdminSchoolStudentAwardsLatestPeriod";
export const SET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES = "setAdminSchoolStudentAwardsBalances";
export const SET_ADMIN_STUDENTS = "setAdminStudents";
export const SET_ADMIN_UNLINKED_STUDENT = "setAdminUnlinkedStudent";
export const SET_ADMIN_UNLINKED_STUDENTS_TO_LINK = "setAdminUnlinkedStudentsToLink";
export const SET_ADMIN_UNLINKED_STUDENT_NAME = "setAdminUnlinkedStudentName";
export const SET_ADMIN_USERS = "setAdminUsers";
export const SET_FUND_SECTIONS = "setFundSections";
export const SET_FUND_SECTION_DONATIONS = "setFundSectionDonations";
export const SET_FUND_SECTION_RECOMMENDATIONS = "setFundSectionRecommendations";
export const SET_FUND_SECTION_STUDENT_AWARDS = "setFundSectionStudentAwards";
export const SET_FUND_TRANSACTIONS = "setFundTransactions";
export const SET_FUND_TRANSACTION_REPORT_DATES = "setFundTransactionReportDates";
export const CLEAR_PENDING_STUDENT_AWARDS = "clearPendingStudentAwards";
export const SET_PENDING_STUDENT_AWARDS = "setPendingStudentAwards";
export const CLEAR_RECENT_STUDENT_AWARDS = "clearRecentStudentAwards";
export const SET_RECENT_STUDENT_AWARDS = "setRecentStudentAwards";
export const SET_REVISIONS = "setRevisions";
export const SET_SALESPEOPLE = "setSalespeople";
export const SET_SALESPERSON = "setSalesperson";
export const SET_SALESPERSON_ASSIGNMENT = "setSalespersonAssignment";
export const SET_SALESPERSON_ASSIGNMENTS = "setSalespersonAssignments";
export const SET_SITE_DOCUMENT = "setSiteDocument";
export const SET_SITE_DOCUMENTS = "setSiteDocuments";
export const SET_SITE_DOCUMENT_SLUGS = "setSiteDocumentSlugs";
export const SET_SITE_WALLPAPER = "setSiteWallpaper";
export const SET_SITE_WALLPAPERS = "setSiteWallpapers";
export const SET_SITE_WALLPAPER_SLUGS = "setSiteWallpaperSlugs";
export const SET_USER_DROPDOWN = "setUserDropdown";
export const SET_USER_DROPDOWN_IS_LOADING = "setUserDropdownIsLoading"
export const SET_USER_LIST = "setUserList";

/*------------------------------------*\
    COMPANIES
\*------------------------------------*/
export const APPEND_COMPANY = "appendCompany";
export const SET_COMPANIES = "setCompanies";
export const SET_COMPANY = "setCompany";
export const SET_COMPANY_DROPDOWN = "setCompanyDropdown";
export const SET_COMPANY_DROPDOWN_IS_LOADING = "setCompanyDropdownIsLoading"
export const SET_USER_COMPANIES = "setUserCompanies";
export const SET_USER_COMPANY = "setUserCompany";

/*-----------------------------------*\
    COMPANY ADMINS
\*-----------------------------------*/
export const SET_COMPANY_ADMIN = "setCompanyAdmin";
export const SET_COMPANY_ADMINS = "setCompanyAdmins";
export const SET_COMPANY_INVITE = "setCompanyInvite";
export const SET_COMPANY_INVITES = "setCompanyInvites";

/*-----------------------------------*\
    CORPORATE PLEDGES
\*-----------------------------------*/
export const APPEND_CORPORATE_PLEDGE_PAYMENT = "appendCorporatePledgePayment";
export const APPEND_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION = "appendCorporatePledgeSchoolRecommendation";
export const INCREMENT_RECOMMENDATION_SCHOOL_ID = "incrementRecommendationSchoolId";
export const REMOVE_CORPORATE_PLEDGE_PAYMENT = "removeCorporatePledgePayment";
export const REMOVE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION = "removeCorporatePledgeSchoolRecommendation";
export const REMOVE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATIONS = "removeCorporatePledgeSchoolRecommendations";
export const SET_CORPORATE_PLEDGE = "setCorporatePledge";
export const SET_CORPORATE_PLEDGE_PAYMENTS = "setCorporatePledgePayments";
export const SET_CORPORATE_PLEDGES = "setCorporatePledges";
export const SET_TAX_CREDIT_INFO = "setTaxCreditInfo";
export const UPDATE_CORPORATE_PLEDGE_PAYMENT = "updateCorporatePledgePayment";
export const UPDATE_CORPORATE_PLEDGE_SCHOOL_RECOMMENDATION = "updateCorporatePledgeSchoolRecommendation";
export const SET_ADOR_APPROVAL_DOCUMENT_UPLOAD = "setAdorApprovalDocumentUpload";
/*------------------------------------*\
    CONTACTS
\*------------------------------------*/
export const SET_BROCHURE_REQUESTS = "setBrochureRequests";
export const SET_CONTACTS = "setContacts";

/*------------------------------------*\
    DONORS
\*------------------------------------*/
export const APPEND_DONOR_PRIOR_DONATION = "appendDonorPriorDonation";
export const APPEND_DONOR_RECOMMENDATION = "appendDonorRecommendation";
export const APPEND_DONOR_STUDENT_RECOMMENDATION = "appendDonorStudentRecommendation";
export const COPY_DONOR_STUDENT_RECOMMENDATIONS = "copyDonorStudentRecommendations";
export const INCREMENT_RECOMMENDATION_STUDENT_ID = "incrementRecommendationStudentId";
export const REMOVE_DONOR_PRIOR_DONATION = "removeDonorPriorDonation";
export const REMOVE_DONOR_STUDENT_RECOMMENDATION = "removeDonorStudentRecommendation"
export const REMOVE_DONOR_STUDENT_RECOMMENDATIONS = "removeDonorStudentRecommendations"
export const RESET_DONOR_SUBSCRIPTION_DONATION = "resetDonorSubscriptionDonation";
export const SET_DONATION = "setDonation";
export const SET_DONOR = "setDonor";
export const SET_DONOR_DONATIONS = "setDonorDonations";
export const SET_DONOR_DONATION_TAX_YEAR_FACTORS = "setDonorDonationTaxYearFactors";
export const SET_DONOR_PRIOR_DONATIONS = "setDonorPriorDonations";
export const SET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES = "setDonorStudentRecommendationsFromPromoPages";
export const SET_DONOR_SUBSCRIPTION_DONATIONS = "setDonorSubscriptionDonations";
export const SET_DONOR_WITHHOLDING_DONATIONS = "setDonorWithholdingDonations";
export const SET_LAST_TWO_YEARS_DONATIONS_HISTORY = "setLastTwoYearsDonationHistory";
export const SET_SELECTED_USER = "setCurrentlySelectedUser";
export const UPDATE_DONOR_STUDENT_RECOMMENDATION = "updateDonorStudentRecommendation";
export const SET_STATE_ISO_FROM_REGION_ID = "setStateIsoFromRegionId";
export const SET_USER_ADDRESS = "setUserAddress";

/*-----------------------------------*\
    PAYROLL WITHHOLDING
\*-----------------------------------*/
export const APPEND_COMPANY_DAILY_DONATION_PAYMENT = "appendCompanyPayrollBatchPayment";
export const REMOVE_COMPANY_DAILY_DONATION_PAYMENT = "removeCompanyPayrollBatchPayment";
export const SET_ANNUAL_EMPLOYEE_DONATIONS = "setAnnualEmployeeDonations";
export const SET_NEXT_COMPANY_PAYROLL_BATCH = "setNextCompanyPayrollBatch";
export const SET_COMPANY_PAYROLL_BATCHES_BY_YEAR = "setCompanyPayrollBatchesByYear";
export const SET_EMPLOYEE_DONATION = "setEmployeeDonation";
export const SET_EMPLOYEE_DONATIONS = "setEmployeeDonations";
export const SET_EMPLOYEE_DONATION_EMPLOYEES = "setEmployeeDonationEmployees";
export const SET_EMPLOYEES_FORM_A4C = "setEmployeesFormA4C";
export const SET_UPLOADED_EMPLOYEE_DONATIONS_FILE = "setUploadedEmployeeDonationsFile";
export const UPDATE_COMPANY_DAILY_DONATION_PAYMENT = "updateCompanyPayrollBatchPayment";
export const SET_DONOR_SELECTION_WITH_FILE = "setDonorWithholdingDocument";
export const SET_PAYROLL_WITHHOLDING_UPLOAD_SELECTIONS = "setPayrollWithholdingUploadSelections";
export const SET_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE = "setPayrollWithholdingUploadResponse";
export const CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE = "clearPayrollWithholdingUploadResponse";

/*------------------------------------*\
    HOUSEHOLDS
\*------------------------------------*/
export const SET_HOUSEHOLD = "setHousehold";
export const SET_HOUSEHOLDS = "setHouseholds";
export const SET_USER_HOUSEHOLD = "setUserHousehold";
export const SET_USER_HOUSEHOLDS = "setUserHouseholds";

/*-----------------------------------*\
    HOUSEHOLD ADMINS
\*-----------------------------------*/
export const SET_HOUSEHOLD_ADMIN = "setHouseholdAdmin";
export const SET_HOUSEHOLD_ADMINS = "setHouseholdAdmins";
export const SET_HOUSEHOLD_INVITE = "setHouseholdInvite";
export const SET_HOUSEHOLD_INVITES = "setHouseholdInvites";

/*------------------------------------*\
    HOUSEHOLD APPLICATIONS
\*------------------------------------*/
export const APPEND_HOUSEHOLD_APPLICATION_MEMBER = "appendHouseholdApplicationMember";
export const CLEAR_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN = "clearHouseholdApplicationStudentDocumentsDropdown";
export const REMOVE_HOUSEHOLD_APPLICATION_DOCUMENT = "removeHouseholdApplicationDocument";
export const REMOVE_HOUSEHOLD_APPLICATION_MEMBER = "removeHouseholdApplicationMember";
export const SET_HOUSEHOLD_APPLICATION = "setHouseholdApplication";
export const SET_HOUSEHOLD_APPLICATIONS = "setHouseholdApplications";
export const SET_HOUSEHOLD_APPLICATION_DOCUMENT = "setHouseholdApplicationDocument";
export const SET_HOUSEHOLD_APPLICATION_DOCUMENTS = "setHouseholdApplicationDocuments";
export const SET_HOUSEHOLD_APPLICATION_HARDSHIP = "setHouseholdApplicationHardship";
export const SET_HOUSEHOLD_APPLICATION_MEMBER = "setHouseholdApplicationMember";
export const SET_HOUSEHOLD_APPLICATION_MEMBERS = "setHouseholdApplicationMembers";
export const SET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS = "setHouseholdApplicationScholarships";
export const SET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR = "setHouseholdApplicationSchoolYear";
export const SET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN = "setHouseholdApplicationStudentsDropdown";
export const SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN = "setHouseholdApplicationStudentDocumentsDropdown";
export const SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENT_DROPDOWN_LOADING = "setHouseholdApplicationStudentDocumentDropdownLoading";

/*------------------------------------*\
    APPLICATION PROMOS
\*------------------------------------*/
export const SET_APPLICATION_PROMO = "setApplicationPromo";
export const SET_APPLICATION_PROMOS = "setApplicationPromos";

/*------------------------------------*\
    INVITES
\*------------------------------------*/
export const SET_INVITE = "setInvite";
export const SET_REGIONS = "setRegions";

/*------------------------------------*\
    SCHOOLS
\*------------------------------------*/
export const SET_APPROVED_SCHOOLS = "setApprovedSchools";
export const SET_SCHOOL = "setSchool";
export const SET_SCHOOLS = "setSchools";
export const SET_SCHOOL_DROPDOWN = "setSchoolDropdown";
export const SET_SCHOOL_DROPDOWN_IS_LOADING = "setSchoolDropdownIsLoading"
export const SET_SCHOOL_GRADES = "setSchoolGrades";
export const SET_SCHOOL_YEARS = "setSchoolYears";
export const SET_USER_SCHOOL = "setUserSchool";
export const SET_USER_SCHOOLS = "setUserSchools";

/*-----------------------------------*\
    SCHOOL ADMINS
\*-----------------------------------*/
export const SET_SCHOOL_ADMIN = "setSchoolAdmin";
export const SET_SCHOOL_ADMINS = "setSchoolAdmins";
export const SET_SCHOOL_INVITE = "setSchoolInvite";
export const SET_SCHOOL_INVITES = "setSchoolInvites";

/*------------------------------------*\
    SCHOOL APPLICATIONS
\*------------------------------------*/
export const SET_SCHOOL_APPLICATION = "setSchoolApplication";
export const SET_SCHOOL_APPLICATIONS = "setSchoolApplications";
export const SET_SCHOOL_APPLICATION_GRADE = "setSchoolApplicationGrade";
export const SET_SCHOOL_APPLICATION_GRADES = "setSchoolApplicationGrades";
export const SET_SCHOOL_APPLICATION_SCHOOL_YEAR = "setSchoolApplicationSchoolYear";

/*------------------------------------*\
    SCHOOL STUDENTS
\*------------------------------------*/

export const SET_FISCAL_YEARS = "setFiscalYears";
export const SET_SCHOOL_PENDING_STUDENT_AWARDS = "setSchoolPendingStudentAwards";
export const SET_SCHOOL_STUDENT = "setSchoolStudent";
export const SET_SCHOOL_STUDENT_YEARS = "setSchoolStudentYears";
export const SET_SCHOOL_STUDENTS = "setSchoolStudents";
export const SET_SCHOOL_STUDENTS_NON_RENEWED = "setSchoolStudentsNonRenewed";
export const SET_SCHOOL_STUDENT_AWARD = "setSchoolStudentAward";
export const SET_SCHOOL_STUDENT_AWARD_REFUND = "setSchoolStudentAwardRefund";
export const SET_SCHOOL_YEAR_NAME = "setSchoolYearName";
export const SET_PENDING_STUDENT_AWARDS_COUNT = "setPendingStudentAwardsCount";
export const SET_UNLOCKED_STUDENT_AWARDS_COUNT = "setUnlockedStudentAwardsCount";
export const SET_STUDENT_PERMANENTS = "setStudentPermanents";
export const SET_APPLICATION_BY_APPLICATION_MEMBER_ID = "setApplicationByApplicationMemberId";
export const SET_AWARDS_BY_SCHOOL_AND_PERIOD = "setAwardsBySchoolAndPeriod";
export const SET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS = "setAdminSchoolStudentAvailableAwards";

/*------------------------------------*\

    CHAT

\*------------------------------------*/

export const SET_CONVERSATION_SOUND = 'setConversationSound';
export const SET_MEETING_SOUND = 'setMeetingSound';

export const SET_CHAT_USER = 'setChatUser';
export const CLEAR_CHAT_USER = 'clearChatUser'

export const RESET_CONVERSATIONS = 'resetConversations';
export const OPEN_CONVERSATION_DIALOG = 'openConversationDialog';
export const TOGGLE_CONVERSATION_DIALOG = 'toggleConversationDialog';
export const CLOSE_CONVERSATION_DIALOG = 'closeConversationDialog';
export const OPEN_MESSAGES_DIALOG = 'openMessagesDialog';
export const TOGGLE_MESSAGES_DIALOG = 'toggleMessagesDialog';
export const CLOSE_MESSAGES_DIALOG = 'closeMessagesDialog';
export const SET_CONVERSATION = 'setConversation';
export const CLEAR_CONVERSATIONS = 'clearConversations';
export const UPSERT_CONVERSATION = 'upsertConversation';
export const APPEND_CONVERSATIONS = 'appendConversations';
export const UPDATE_CONVERSATION = 'updateConversation';
export const REMOVE_CONVERSATION = 'removeConversation';
export const SHOW_CONVERSATIONS = 'showConversations';
export const MARK_CONVERSATION_READ = 'markConversationRead';
export const SORT_CONVERSATIONS = 'sortConversations';

export const SET_MESSAGE = 'setMessage';
export const APPEND_MESSAGES = 'setMessages';
export const APPEND_MESSAGE = 'appendMessage';
export const EDIT_MESSAGE = 'editMessage';
export const CLEAR_EDITING_MESSAGE = 'clearEditingMessage';
export const REMOVE_MESSAGE = 'removeMessage';
export const CLEAR_MESSAGES = 'clearMessages';
export const SHOW_MESSAGES = 'showMessages';
export const UPREPLACE_MESSAGE = 'upReplaceMessage';

export const APPEND_CHATS = 'appendChats';
export const APPEND_CHAT = 'appendChat';
export const UPREPLACE_CHAT = 'upReplaceChat';
export const REMOVE_CHAT = 'removeChat';
export const SET_CHAT = 'setChat';
export const CLEAR_CHATS = 'clearChats';
export const CLEAR_CHAT_BADGE = 'clearChatBadge';
export const SHOW_CHAT_BADGE = 'showChatBadge';
