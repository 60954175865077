<template>
    <div>
        <tax-factors-for-year
            :selected-tax-year="explainer.selectedTaxYear"
            :tax-filing-status="explainer.taxFilingStatus"
            class="mr-0 mt-0"
        />
        <div
            v-if="calledFrom === 'donorSubscription' && remainder > 0"
            class="mt-4"
        >
            The amount you can donate each month
            <span class="text-apsto-teal">
                that you can use as a tax credit
            </span>
            for tax year
            {{ explainer.selectedTaxYear }} is
            <span class="text-apsto-teal">
                {{ contributionAmount | formatCurrency }}.
            </span>
            It's based on making
            {{ numberOfPayments }}
            monthly payments from the
            {{ dateOrdinal(startDay) }}
            day of
            {{ startMonth }}, {{ currentYear }}
            through the
            {{ dateOrdinal(explainer.subscriptionChargeDay) }}
            day of December,
            {{ explainer.selectedTaxYear }} for a total of
            {{ contributionAmount * numberOfPayments | formatCurrency }}.
            <div
                v-if="numberOfPayments > 1"
                class="mt-4"
            >
                Dates your payment card will be charged:
                <div class="mt-4">
                    <table v-for="paymentDate in paymentSchedule">
                        <tr>
                            <td> {{ paymentDate }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div
            v-if="remainder <= 0"
            class="mt-4"
        >
            <div v-if="isAuthenticated">
                You've donated {{ explainer.totalPriorDonations | formatCurrency }} toward tax year
                {{ explainer.selectedTaxYear }}.
            </div>
        </div>
        <!--    <div-->
        <!--      v-if="calledFrom === 'donorSubscription' && remainder > 0"-->
        <!--      class="mt-4 text-apsto-teal"-->
        <!--    >-->
        <!--      How we calculated the amount you can use as a tax credit:-->
        <!--    </div>-->
        <div
            v-if="calledFrom !== 'donorWithholding' && calledFrom !== 'donorSubscription'"
            class="mt-4"
        >
            The amounts shown above are the maximum you can claim on your Arizona tax return however you can donate more
            if you wish.
        </div>
        <div v-if="remainder > 0">
            <div
                v-if="explainer.totalPriorDonations > 0"
                class="mt-3"
            >
                You have already donated
                {{ explainer.totalPriorDonations | formatCurrency }}
                for tax year {{ explainer.selectedTaxYear }}
                which leaves
                <span :class="calledFrom === 'donorSubscription' ? '' : 'text-apsto-teal font-weight-black'">
                    {{ remainder | formatCurrency }}
                    that you can donate and use as a tax credit
                </span>
                for tax year
                {{ explainer.selectedTaxYear }}.
            </div>
        </div>
    </div>
</template>

<script>
import TaxFactorsForYear from "../shared/TaxFactorsForYear.vue";

export default {
    name: "DonationAmountExplainer",
    components: {TaxFactorsForYear},
    props: {
        calledFrom: {
            default: "",
            type: String,
            required: false
        },
        derivedTaxYear: {
            type: String,
            required: false
        },
        explainer: {
            type: Object,
            required: true
        },
    },
    computed: {
        contributionAmount() {
            return Math.floor(this.remainder / this.numberOfPayments);
        },
        currentYear() {
            const today = new Date();

            if (this.calledFrom === 'donorSubscription' && this.derivedTaxYear === "next") {
                return today.getFullYear() + 1;
            }

            return today.getFullYear();
        },
        numberOfPayments() {
            const today = new Date();

            let chargeDay = this.explainer.subscriptionChargeDay;
            let firstChargeDay = chargeDay < today.getDate() ? today.getDate() : chargeDay;
            let month = today.getMonth() + 1;
            let year = today.getFullYear();

            if (this.calledFrom === 'donorSubscription' && this.derivedTaxYear === "next") {
                month = 1;
                year++;
            }

            const startDate = new Date(year + "-" + month + "-" + firstChargeDay);
            const endDate = new Date(this.explainer.selectedTaxYear + "-12-" + chargeDay);

            const monthDifference = moment(new Date(endDate)).diff(new Date(startDate), 'months', true);

            this.explainer.numberOfPayments = Math.ceil(monthDifference + 1);

            return this.explainer.numberOfPayments;
        },
        paymentSchedule() {
            const today = new Date();
            let chargeDay = this.explainer.subscriptionChargeDay;
            let firstChargeDay = chargeDay < today.getDate() ? today.getDate() : chargeDay;
            let month = today.getMonth() + 1;
            let year = today.getFullYear();

            if (this.calledFrom === 'donorSubscription' && this.derivedTaxYear === "next") {
                month = 1;
                year++;
            }

            let paymentsArray = [];
            for (let i = month; i < month + this.numberOfPayments; i++) {

                let derivedMonth = i;
                let derivedYear = year;

                if (derivedMonth > 12) {
                    derivedMonth = derivedMonth - 12;
                    derivedYear++;
                }

                let day = i === month ? firstChargeDay : chargeDay;
                let newDate = derivedMonth.toString().padStart(2, "0") + "/" + day.toString().padStart(2, "0") + "/" + derivedYear;

                if (derivedMonth === 2 && day > 28) {
                    day = 28;
                    newDate = derivedMonth.toString().padStart(2, "0") + "/" + day.toString().padStart(2, "0") + "/" + derivedYear;
                }

                if ((derivedMonth === 4 || derivedMonth === 6 || derivedMonth === 9 || derivedMonth === 11) && day === 31) {
                    day = 30;
                    newDate = derivedMonth.toString().padStart(2, "0") + "/" + day.toString().padStart(2, "0") + "/" + derivedYear;
                }

                paymentsArray.push(newDate);
            }

            return paymentsArray;
        },
        remainder() {
            return this.explainer.maxTotal - this.explainer.totalPriorDonations;
        },
        startDay() {
            return this.explainer.subscriptionChargeDay < new Date().getDate() ? new Date().getDate() : this.explainer.subscriptionChargeDay;
        },
        startMonth() {
            const event = new Date();

            let monthInteger = event.getMonth() + 1; // Months are zero-indexed in JavaScript

            if (this.calledFrom === 'donorSubscription' && this.derivedTaxYear === "next") {
                monthInteger = 1;
            }

            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return monthNames[monthInteger - 1];
        },
    },
    methods: {
        dateOrdinal(dom) {
            if (dom === 31 || dom === 21 || dom === 1) return dom + "st";
            else if (dom === 22 || dom === 2) return dom + "nd";
            else if (dom === 23 || dom === 3) return dom + "rd";
            else return dom + "th";
        }
    }
}
</script>

<style scoped></style>
