<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row>
            <v-col class="mx-2 text-h5-mulish"> Settings - Upload Picture</v-col>
        </v-row>
        <v-row class="mt-4">
            <v-col
                class="mx-2"
                md="6"
            >
                <v-row no-gutters>

                    <v-col
                        class="picture my-5"
                        cols="6"
                    >
                        <v-avatar size="300">
                            <v-img
                                v-if="localUser.profile_hash"
                                :src="imageUrl(localUser.profile_hash)"
                                aspect-ratio="1"
                                class="
                    px-4
                    text-center
                    d-flex
                    align-center
                    font-weight-bold
                    f-size-24
                  "
                                contain
                                rounded
                                @click="profileImageDialog = true"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        align="center"
                                        class="fill-height ma-0"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            :size="70"
                                            :width="7"
                                            indeterminate
                                        />
                                    </v-row>
                                </template>
                            </v-img>
                            <v-icon
                                v-else
                                color="secondary"
                                size="300"
                            >mdi-account-circle
                            </v-icon>
                        </v-avatar>
                        <v-btn
                            v-if="localUser.profile_hash"
                            class="btn-remove"
                            color="red"
                            fab
                            rounded
                            @click="removeProfileImage"
                        >
                            <v-icon color="white">fas fa-times</v-icon>
                        </v-btn>
                        <v-btn
                            class="btn-upload"
                            color="secondary"
                            fab
                            rounded
                            @click="profileImageDialog = true"
                        >
                            <v-icon color="primary">fas fa-camera</v-icon>
                        </v-btn>

                        <v-dialog
                            v-model="profileImageDialog"
                            max-width="500"
                        >
                            <image-cropper
                                :aspect-ratio="'1/1'"
                                :error="uploadImgError"
                                :error-message="uploadImgMsg"
                                title="Change Personal Image"
                                @clear="() => { uploadImgError = false; uploadImgMsg = null }"
                                @done="updateProfileImage"
                            />
                        </v-dialog>
                    </v-col>

                </v-row>

            </v-col>
            <v-snackbar
                v-model="snackbar"
                centered
            >
                {{ snackbarMessage }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-row>
    </div>
</template>

<script>
import Loading from "../../components/shared/Loading.vue";
import ImageCropper from "../../components/shared/ImageCropper";

import { GET_USER_ADDRESS, PATCH_USER } from "../../store/actions.type";

export default {
    name: "UserProfilePicture",
    components: {
        Loading,
        ImageCropper,
    },
    data() {
        return {
            address: {
                street1: "",
                street2: "",
                city: "",
                postal: "",
                state: "",
                region_id: "",
            },
            loading: false,
            localUser: null,
            saving: false,
            snackbar: false,
            snackbarMessage: "",
            profileImageDialog: false,
            uploadImgError: false,
            uploadImgMsg: null,
        };
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        currentUserAddress() {
            return this.$store.getters.currentUserAddress;
        },
        error() {
            return this.$store.getters.getMessage("users");
        },
        errors() {
            return this.$store.getters.getErrors("users");
        },
    },
    created() {
        this.localUser = { ...this.currentUser };
    },
    methods: {
        submit() {
            this.saving = true;
            this.localUser.address = this.address;
            this.$store.dispatch(PATCH_USER, this.localUser)
                .then(() => {
                    this.saving = false;
                    this.snackbar = true;
                    this.snackbarMessage = "Picture Uploaded";
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
        getUserAddress() {
            this.loading = true;
            this.$store.dispatch(GET_USER_ADDRESS).then(() => {
                this.address.street1 = this.currentUserAddress.street1;
                this.address.street2 = this.currentUserAddress.street2;
                this.address.city = this.currentUserAddress.city;
                this.address.postal = this.currentUserAddress.postal;
                this.address.state = this.currentUserAddress.state;
                this.address.region_id = this.currentUserAddress.region_id;
                this.loading = false;
            });
        },
        updateProfileImage(formData) {
            console.log("Updating profile image", formData);
            this.localUser.profile_hash = formData;
            this.submit();
            this.profileImageDialog = false;
        },
        removeProfileImage() {
            this.localUser.profile_hash = null;
            this.submit();
        },
    },
    mounted() {
        this.getUserAddress();
    }
}

</script>

<style lang="scss" scoped>
.picture {
    position: relative;

    .btn-upload {
        position: absolute;
        bottom: 0;
        right: 5px;
    }

    .btn-remove {
        position: absolute;
        bottom: 0;
        left: 5px;
    }
}
</style>
