<template>
  <div class="w-100">
    <v-row no-gutters class="align-baseline">
      <v-textarea
        class="chat-send-max-height"
        :placeholder="placeholder"
        :disabled="disabled"
        rows="1"
        outlined
        full-width
        single-line
        auto-grow
        counter="200"
        maxlength="200"
        v-model="message"
        @click="$emit('click')"
        ref="input"
        v-on="$listeners"
        @input="$emit('input', message)"
        @keydown.enter.exact.prevent="$emit('submit')"
      />
      <div class="mb-5">
        <template v-if="!loading">
          <v-menu
            v-if="$vuetify.breakpoint.mdAndUp"
            v-model="isShowingEmojiDialog"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon @click="toggleEmojiDialog">
                <v-icon>mdi-emoticon</v-icon>
              </v-btn>
            </template>
            <emoji-picker @select="insert" />
          </v-menu>
          <v-btn icon @click="$emit('submit')">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </template>

        <v-btn v-else icon>
          <v-icon>mdi-loading fa-spin</v-icon>
        </v-btn>
      </div>
    </v-row>
  </div>
</template>

<script>
import EmojiPicker from "./EmojiPicker.vue";

export default {
  name: "MessageInput",
  components: {
    EmojiPicker
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Type here..."
    },
    disabled: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowingEmojiDialog: false,
      message: ''
    }
  },
  methods: {
    insert(emoji) {
      this.message += emoji;
      this.$emit('input', this.message)
    },
    toggleEmojiDialog() {
      if (this.preview) return
      this.isShowingEmojiDialog = !this.isShowingEmojiDialog;
    },
  }
}
</script>

<style scoped>
.chat-send-max-height {
  max-height: 350px;
  overflow-y: auto;
}
.v-input {
  padding-bottom: 0px !important;
}
::v-deep .v-input__append-inner {
  margin-top: auto !important;
  padding-bottom: 10px;
}
::v-deep textarea {
  white-space: normal !important;
}

::v-deep .v-input__slot {
  background-color: white !important
}

::v-deep .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
