<template>
    <div>
        <div class="table-header" style="padding-bottom: 20px;">Student Awards {{ period }}</div>
        <v-data-table
            :footer-props="{ itemsPerPageOptions:[10,20,30] }"
            :headers="studentAwardHeaders"
            :items="studentAwards"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :page.sync="page"
            :search="search"
            :server-items-length="serverItemsLength"
            :single-select="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-2 mt-3"
            fixed-header
            height="100vh"
            item-key="id"
            @pagination="getStudentAwards"
            @update:sort-by="getStudentAwards"
            @update:sort-desc="getStudentAwards"
        >
            <template v-slot:top>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            v-model="search"
                            background-color="white"
                            class="mx-4"
                            label="Search on anything"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:body.prepend>
                <tr class="totals">
                    <th colspan="3">Totals</th>
                    <th class="text-right">{{ totalAwardCorporateDisabledDisplaced | formatCurrencyWithCents }}</th>
                    <th class="text-right">{{ totalAwardCorporateLowIncome | formatCurrencyWithCents }}</th>
                    <th class="text-right">{{ totalAwardOriginalTaxCredit | formatCurrencyWithCents }}</th>
                    <th class="text-right">{{ totalAwardSwitcher | formatCurrencyWithCents }}</th>
                    <th class="text-right">{{ totalAwardCharitable | formatCurrencyWithCents }}</th>
                    <th class="text-right">{{ totalAwards | formatCurrencyWithCents }}</th>
                </tr>
            </template>
            <template v-slot:no-data>
            <span class="text-apsto-teal">
              There are no student awards on file for this time period.
            </span>
            </template>
            <template #item.first_name="{item}">
                {{ item.first_name }} {{ item.last_name }}
            </template>
            <template #item.corporate_disabled_displaced="{item, index}">
                <div v-if="index === 0">
                    {{ item.corporate_disabled_displaced | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.corporate_disabled_displaced | formatNumberWithCents }}
                </div>
            </template>
            <template #item.corporate_low_income="{item, index}">
                <div v-if="index === 0">
                    {{ item.corporate_low_income | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.corporate_low_income | formatNumberWithCents }}
                </div>
            </template>
            <template #item.original_tax_credit="{item, index}">
                <div v-if="index === 0">
                    {{ item.original_tax_credit | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.original_tax_credit | formatNumberWithCents }}
                </div>
            </template>
            <template #item.switcher="{item, index}">
                <div v-if="index === 0">
                    {{ item.switcher | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.switcher | formatNumberWithCents }}
                </div>
            </template>
            <template #item.charitable="{item, index}">
                <div v-if="index === 0">
                    {{ item.charitable | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ item.charitable | formatNumberWithCents }}
                </div>
            </template>
            <template #item.total="{item, index}">
                <div v-if="index === 0">
                    {{ total(item) | formatCurrencyWithCents }}
                </div>
                <div v-else>
                    {{ total(item) | formatNumberWithCents }}
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {GET_FUND_SECTION_STUDENT_AWARDS} from "../../../store/actions.type";

export default {
    name: "StudentAwardsTable",
    data() {
        return {
            loading: true,
            search: "",
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,
            sortBy: 'school_name',
            sortDesc: false,
            serverItemsLength: 0,
            studentAwardHeaders: [
                {text: 'School', align: 'start', value: 'school_name'},
                {text: 'Student', align: 'start', value: 'first_name'},
                {text: 'Household', align: 'start', value: 'household_name'},
                {text: 'Displaced', align: 'end', value: 'corporate_disabled_displaced'},
                {text: 'Low Income', align: 'end', value: 'corporate_low_income'},
                {text: 'Original', align: 'end', value: 'original_tax_credit'},
                {text: 'Switcher', align: 'end', value: 'switcher'},
                {text: 'Charitable', align: 'end', value: 'charitable'},
                {text: 'Total', align: 'end', value: 'total'},
            ],
        }
    },
    computed: {
        period() {
            return "Offered During " + this.$store.getters.currentFundSectionDonations['period'];
        },
        studentAwards() {
            return this.$store.getters.currentFundSectionStudentAwards['applicationStudentAwards'];
        },
        totalAwardCorporateDisabledDisplaced() {
            return this.$store.getters.currentFundSectionStudentAwards['totalCorporateDisabledDisplaced'];
        },
        totalAwardCorporateLowIncome() {
            return this.$store.getters.currentFundSectionStudentAwards['totalCorporateLowIncome'];
        },
        totalAwardCharitable() {
            return this.$store.getters.currentFundSectionStudentAwards['totalCharitable'];
        },
        totalAwardOriginalTaxCredit() {
            return this.$store.getters.currentFundSectionStudentAwards['totalOriginalTaxCredit'];
        },
        totalAwardSwitcher() {
            return this.$store.getters.currentFundSectionStudentAwards['totalSwitcher'];
        },
        totalAwards() {
            return this.totalAwardCorporateDisabledDisplaced + this.totalAwardCorporateLowIncome + this.totalAwardCharitable + this.totalAwardOriginalTaxCredit + this.totalAwardSwitcher;
        },
    },
    methods: {
        total(item) {
            return parseFloat(item.corporate_disabled_displaced) +
                parseFloat(item.corporate_low_income) +
                parseFloat(item.charitable) +
                parseFloat(item.original_tax_credit) +
                parseFloat(item.switcher)
        },
        getStudentAwards() {
            this.loading = true;
            let year = this.$route.params.year;
            let month = this.$route.params.month;
            let page = this.page;
            let itemsPerPage = this.itemsPerPage;
            let search = this.search;
            let sortBy = this.sortBy;
            let sortDesc = this.sortDesc;
            this.$store.dispatch(GET_FUND_SECTION_STUDENT_AWARDS, {
                year,
                month,
                page,
                itemsPerPage,
                sortBy,
                sortDesc,
                search
            })
                .then((data) => {
                    this.serverItemsLength = data.totalRecords;
                    this.loading = false;
                })
        }
    },
    watch: {
        search() {
            this.page = 1;
            this.getStudentAwards();
        }
    }
}
</script>

<style scoped>
.table-header {
    font-size: 1.5rem;
    font-weight: bold;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.totals) {
    background-color: transparent;
}

::v-deep tr.totals {
    background-color: var(--apsto-teal) !important;
}

::v-deep tr.totals > th {
    color: white;
    font-size: .90rem !important;
}
</style>
