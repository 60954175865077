<template>
    <v-row>
        <v-col class="py-0">
            <v-list-item>
                <v-list-item-avatar
                    v-if="!message.mine"
                    class="mr-3 pointer mt-4 mb-auto"
                    @click="
                        $router.push({
                            name: 'view-profile',
                            params: { uuid: message.uuid },
                        })
                        "
                >
                    <v-img
                        v-if="message.picture"
                        :alt="`${message.username} avatar`"
                        :src="message.picture"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                            >
                                <v-progress-circular
                                    color="black"
                                    indeterminate
                                />
                            </v-row>
                        </template>
                    </v-img>
                    <v-avatar
                        v-else
                        color="secondary"
                    >
                        <span class="font-weight-bold">{{ message.username[0] }}</span>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="py-2 py-sm-3">
                    <v-row>
                        <v-col
                            :class="message.mine ? 'ml-auto' : ''"
                            class="px-2 py-0"
                            cols="auto"
                        >
                            <v-card
                                v-long-press="500"
                                :class="messageClass"
                                class="pa-2 ma-2"
                                max-width="100%"
                                @click="showDisplayDate = !showDisplayDate"
                                @contextmenu="messageContext"
                                @long-press-start="showMenu"
                            >
                                <div
                                    v-if="!message.mine && (showName || conversation.participant_count > 2)"
                                    class="font-weight-bold dodgerBlue--text"
                                >
                                    {{ message.username }}
                                </div>
                                <edit-message
                                    v-if="isBeingEdited && message.mine"
                                    :conversation="conversation"
                                    :initialMessage="message"
                                />
                                <vue-simple-markdown
                                    v-else
                                    :linkify="this.linkify"
                                    :source="message.body"
                                    :class="message.mine ? 'white--text' : 'black--text'"
                                    class="message-body"
                                />
                                <v-card-actions v-if="!showAll && message.body.length > 550">
                                    <v-btn
                                        class="text-no-wrap black--text px-2 rounded-lg skyBlue mb-1 ml-auto d-block"
                                        depressed
                                        x-small
                                        @click.stop="showAll = !showAll"
                                    >
                                        Read More
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col
                            v-if="showTime"
                            class="px-2 py-0"
                            cols="12"
                        >
                            <div
                                :class="message.mine ? 'text-right' : ''"
                                class="px-2 nevadaGray--text text-caption"
                            >
                                <span v-if="showDisplayDate">{{ message.edited ? '(edited)' : '' }} {{ messageDisplayDate
                                }}</span>
                                <span v-else>{{ message.edited ? '(edited)' : '' }} {{ timestamp }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-list-item-content>
                <v-list-item-avatar
                    v-if="message.mine"
                    class="ml-3 pointer mt-2 mt-sm-4 mb-auto"
                    @click="
                        $router.push({
                            name: 'view-profile',
                            params: { uuid: message.uuid },
                        })
                        "
                >
                    <v-img
                        v-if="message.picture"
                        :alt="`${message.username} avatar`"
                        :src="message.picture"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                            >
                                <v-progress-circular
                                    color="black"
                                    indeterminate
                                />
                            </v-row>
                        </template>
                    </v-img>
                    <v-avatar
                        v-else
                        color="yellow"
                    >
                        <span class="font-weight-bold">{{ message.ui_avatar }}</span>
                    </v-avatar>
                </v-list-item-avatar>
            </v-list-item>
        </v-col>
        <v-dialog
            v-model="messageModal"
            :retain-focus="false"
            scrollable
            width="500"
        >
            <MessageMenu
                :isEventChat="isEventChat"
                :message="message"
                :messageModal.sync="messageModal"
                @done="messageModal = false"
            />
        </v-dialog>
    </v-row>
</template>

<script>
import LongPress from "vue-directive-long-press";

import MessageMenu from "./MessageMenu";
import EditMessage from "./SendMessage";

export default {
    name: "Message",
    components: {
        MessageMenu,
        EditMessage,
    },
    directives: {
        "long-press": LongPress,
    },
    data() {
        return {
            showDisplayDate: true,
            showAll: false,
            messageModal: false,
        };
    },
    props: {
        message: Object,
        showName: Boolean,
        isEventChat: Boolean,
        showTime: Boolean,
        linkify: Boolean,
        conversation: {
            type: Object,
            default: null
        }
    },
    computed: {
        user() {
            return this.$store.getters.currentUser;
        },
        isBeingEdited() {
            return this.editingMessage == this.message.id;
        },
        editingMessage() {
            return this.$store.getters.editingMessage;
        },
        messageDisplayDate() {
            return moment.tz(this.message.created_at, "UTC").local().fromNow();
        },
        timestamp() {
            return moment.tz(this.message.created_at, "UTC").local();
        },
        messageClass() {
            return this.message.mine ? 'secondary' : 'secondaryLight'
        },
    },
    methods: {
        showMenu() {
            if (!this.message.deleted && !this.editingMessage) {
                this.messageModal = true;
            }
        },
        messageContext(e) {
            e.preventDefault()
            this.showMenu()
        }
    },
};
</script>

<style lang="scss" scoped>
.message-body {
    word-break: break-word;

    img {
        max-width: 100% !important;
    }
}

.v-application p {
    margin-bottom: 0px;
}
</style>
