<template>
    <v-row class="ml-1 my-6">
        <v-col md="4">

            <menu-item
                class="menu-item"
                enumerator="1"
                title="Brochure Requests"
                to="admin-brochure-requests-list"
            />

            <menu-item
                class="menu-item"
                enumerator="2"
                title="Companies"
                to="admin-companies-list"
            />

            <menu-item
                class="menu-item"
                enumerator="3"
                title="Companies: Corporate Donation Requests"
                to="admin-corporate-pledges-list"
            />

            <menu-item
                class="menu-item"
                enumerator="4"
                title="Companies: Payroll Withholding"
                to="admin-companies-in-employee-donations-program-list"
            />

            <menu-item
                class="menu-item"
                enumerator="5"
                title="Contact Us Messages"
                to="admin-contacts-list"
            />

            <menu-item
                class="menu-item"
                enumerator="6"
                title="Deposits"
                to="admin-deposits-list"
            />

            <menu-item
                class="menu-item"
                enumerator="7"
                title="Funds Activity Overview For Fiscal Year"
                to="admin-fund-sections"
            />

            <menu-item
                class="menu-item"
                enumerator="8"
                title="Households"
                to="admin-households-list"
            />

            <menu-item
                class="menu-item"
                enumerator="9"
                title="Households: Applications"
                to="admin-household-applications-list"
            />

            <menu-item
                class="menu-item"
                enumerator="10"
                title="Revise Donor Donation"
                to="admin-donor-donations-revise"
            />

        </v-col>
        <v-col md="4">

            <menu-item
                class="menu-item"
                enumerator="11"
                title="Revise Recommendations"
                to="admin-recommendations-revise"
            />

            <menu-item
                class="menu-item"
                enumerator="12"
                title="Salespeople"
                to="admin-salespeople-list"
            />

            <menu-item
                class="menu-item"
                enumerator="13"
                title="Schools"
                to="admin-schools-list"
            />

            <menu-item
                class="menu-item"
                enumerator="14"
                title="Schools: Award Offers"
                to="admin-create-student-award-offers"
            />

            <menu-item
                class="menu-item"
                enumerator="15"
                title="Schools: Partner Agreements"
                to="admin-school-applications-list"
            />

            <menu-item
                class="menu-item"
                enumerator="16"
                title="Site Documents"
                to="admin-site-documents-list"
            />

            <menu-item
                class="menu-item"
                enumerator="17"
                title="Students"
                to="admin-student-permanents-list"
            />

            <menu-item
                class="menu-item"
                enumerator="18"
                title="Wallpapers"
                to="admin-site-wallpapers-list"
            />

            <menu-item
                class="menu-item"
                enumerator="19"
                title="Users"
                to="admin-users-list"
            />

        </v-col>
        <v-col
            class="last-col"
            md="4"
        >

            <menu-item
                class="menu-item"
                enumerator="R1"
                title="Report: Annual Report"
                to="admin-annual-report"
            />

            <menu-item
                class="menu-item"
                enumerator="R2"
                title="Report: Bank Activity By Date Range"
                to="admin-bank-report-dates"
            />

            <menu-item
                class="menu-item"
                enumerator="R3"
                title="Report: Corporate Donation Requests By Year Range"
                to="admin-corporate-pledges-report-dates"
            />

            <menu-item
                class="menu-item"
                enumerator="R4"
                title="Report: Declaration of Donors"
                to="admin-declaration-of-donors"
            />

            <menu-item
                class="menu-item"
                enumerator="R5"
                title="Report: Donor Donations By Date Range"
                to="admin-donor-donations-report-dates"
            />

            <menu-item
                class="menu-item"
                enumerator="R6"
                title="Report: Fund Transactions By Date Range"
                to="admin-funds-report-dates"
            />

            <menu-item
                class="menu-item"
                enumerator="R7"
                title="Report: Pending Student Awards"
                to="admin-student-awards-pending"
            />

            <menu-item
                class="menu-item"
                enumerator="R8"
                title="Report: Recent Student Awards"
                to="admin-student-awards-recent"
            />

            <menu-item
                class="menu-item"
                enumerator="R9"
                title="Report: Student Awards By Date Range"
                to="admin-student-awards-report-dates"
            />

        </v-col>
    </v-row>
</template>

<script>
import MenuItem from "../../../components/shared/MenuItem";

export default {
    name: "AdminMenu",
    components: {
        MenuItem
    },
}
</script>

<style scoped>
.menu-item {
    margin-bottom: 20px;
    margin-right: 20px;
}

.last-col .menu-item {
    margin-right: 0;
}
</style>
