<template>
    <div class="box-with-background">
        {{ company.name }}
        <div class="mt-5">
            <a
                :href="company.website"
                target="_blank"
            >
                <span style="color: white;">{{ company.website }}</span>
            </a>
        </div>
        <div>
            {{ company.phone }}
        </div>
        <div class="mt-5">
            {{ company.address.street1 }}
        </div>
        <div v-if="company.address.street2">{{ company.address.street2 }}</div>
        <div> {{ company.address.city }}, {{ company.address.region }} {{ company.address.postal }}</div>
        <table class="mt-5">
            <tr>
                <td class="left-column">Entity Type</td>
                <td class="pl-5">{{ company.organization_type_name }}</td>
            </tr>
            <tr>
                <td class="left-column">Federal EIN</td>
                <td class="pl-5">{{ company.federal_ein }}</td>
            </tr>
            <tr>
                <td class="left-column">NAICS Code</td>
                <td class="pl-5">{{ company.naics_code }}</td>
            </tr>
            <tr>
                <td class="left-column">Employer Withholding Code</td>
                <td class="pl-5">
                    {{ company.withholding_code.substring(0, 3) }}-{{ company.withholding_code.substring(3) }}
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: "AdminCompanyInfo",
    props: {
        company: {
            type: Object,
            required: true
        }
    },
}
</script>


<style scoped></style>
