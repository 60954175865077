<template>
  <v-row class="mt-2 outlined">
    <v-col>
      <h2>Please Complete the Form Below to Order Brochures</h2>
      <div
        class="mt-2"
        style="font-size: 1.125rem;"
      >
        If you are looking for printable forms or brochures, please follow this link:
        <a href="/forms-brochures">
          Forms & Brochures
        </a>
      </div>
      <v-card class="mt-8">
        <v-card-text v-if="brochureRequest">
          <v-row>
            <v-col
              lg="4"
              md="4"
            >
              <v-text-field
                v-model="brochureRequest.name"
                :error="!!errors.name"
                :error-messages="errors.name ? errors.name : null"
                filled
                label="Full Name"
              />
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <v-text-field
                v-model="brochureRequest.email"
                :error="!!errors.email"
                :error-messages="errors.email ? errors.email : null"
                filled
                label="Email Address"
              />
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <v-text-field
                v-model="brochureRequest.phone"
                v-mask="'(###) ###-####'"
                :error="!!errors.phone"
                :error-messages="errors.phone ? errors.phone : null"
                filled
                label="Phone"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="brochureRequest.street1"
                :error="!!errors.street1"
                :error-messages="errors.street1 ? errors.street1 : null"
                filled
                label="Street1"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="brochureRequest.street2"
                :error="!!errors.street2"
                :error-messages="errors.street2 ? errors.street2 : null"
                filled
                label="Street2"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              lg="4"
              md="4"
            >
              <v-text-field
                v-model="brochureRequest.city"
                :error="!!errors.city"
                :error-messages="errors.city ? errors.city : null"
                filled
                label="City"
              />
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <region-selector
                v-model="brochureRequest.region_id"
                background-color="rgba(0, 0, 0, 0.06)"
              />
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <v-text-field
                v-model="brochureRequest.postal"
                :error="!!errors.postal"
                :error-messages="errors.postal ? errors.postal : null"
                filled
                label="Postal"
              />
            </v-col>
          </v-row>
          <v-divider/>
          <v-row class="mt-5">
            <v-col
              lg="4"
              md="4"
            >
              <div class="mt-4">
                <a
                  href="/siteDocuments/slug/donation-brochure"
                  style="font-size: 1.25rem;"
                  target="_blank"
                >
                  Donor Brochure
                </a>
              </div>
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <v-text-field
                v-model="brochureRequest.quantity_db"
                :error="!!errors.quantity_db"
                :error-messages="errors.quantity_db ? errors.quantity_db : null"
                filled
                label="Quantity"
              />
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <v-textarea
                v-model="brochureRequest.comments_db"
                :error="!!errors.comments_db"
                :error-messages="errors.comments_db ? errors.comments_db : null"
                filled
                label="Comment"
                rows="1"
              />
            </v-col>
          </v-row>
          <v-divider/>
          <v-row class="mt-5">
            <v-col
              lg="4"
              md="4"
            >
              <div class="mt-4">
                <a
                  href="/siteDocuments/slug/corporate-tax-credit-brochure"
                  style="font-size: 1.25rem;"
                  target="_blank"
                >
                  Corporate Tax Credit Brochure
                </a>
              </div>
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <v-text-field
                v-model="brochureRequest.quantity_ctcb"
                :error="!!errors.quantity_ctcb"
                :error-messages="errors.quantity_ctcb ? errors.quantity_ctcb : null"
                filled
                label="Quantity"
              />
            </v-col>
            <v-col
              lg="4"
              md="4"
            >
              <v-textarea
                v-model="brochureRequest.comments_ctcb"
                :error="!!errors.comments_ctcb"
                :error-messages="errors.comments_ctcb ? errors.comments_ctcb : null"
                filled
                label="Comment"
                rows="1"
              />
            </v-col>
          </v-row>
          <v-divider/>
        </v-card-text>
        <v-card-actions class="align-start mt-5">
          <v-btn
            class="mb-5 ml-2 mt-n5"
            color="primary"
            rounded
            @click="submit"
          > Submit
          </v-btn>
        </v-card-actions>
        <v-snackbar
          v-model="snackbar"
          centered
        >
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {POST_BROCHURE_REQUEST_FORM} from "../../../../store/actions.type";
import RegionSelector from "../../../shared/RegionSelector";
import {mask} from 'vue-the-mask';

export default {
  name: "BrochureRequest",
  components: {RegionSelector},
  directives: {mask},
  data() {
    return {
      brochureRequest: null,
      defaultBrochureRequest: {
        comments_ctcb: null,
        comments_db: null,
        email: null,
        name: null,
        phone: null,
        street1: null,
        street2: null,
        city: null,
        postal: null,
        quantity_ctcb: null,
        quantity_db: null,
        region_id: 3,
      },
      snackbar: false,
      snackbarMessage: "",
    };
  },
  computed: {
    error() {
      return this.$store.getters.getMessage("brochureRequest");
    },
    errors() {
      return this.$store.getters.getErrors("brochureRequest");
    },
  },
  methods: {
    submit() {
      this.saving = true;
      this.$store.dispatch(POST_BROCHURE_REQUEST_FORM, this.brochureRequest)
        .then(() => {
          this.brochureRequest = {...this.defaultBrochureRequest};
          this.saving = false;
          this.snackbar = true;
          this.snackbarMessage = "Thank you for your request. We'll get back to you within one business day.";
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.brochureRequest = {...this.defaultBrochureRequest};
  },
};
</script>
