import { render, staticRenderFns } from "./DonorRecommendationsTable.vue?vue&type=template&id=77c9ec62&scoped=true&"
import script from "./DonorRecommendationsTable.vue?vue&type=script&lang=js&"
export * from "./DonorRecommendationsTable.vue?vue&type=script&lang=js&"
import style0 from "./DonorRecommendationsTable.vue?vue&type=style&index=0&id=77c9ec62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c9ec62",
  null
  
)

export default component.exports