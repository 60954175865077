<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row>
      <v-col>
        <admin-header page-title="Edit Household"/>
      </v-col>
    </v-row>
    <v-row
      v-if="household"
      class="mt-n4 mx-0"
    >
      <v-col md="6">
        <v-card class="pa-5">
          <div class="box-with-background">
            {{ household.name }}
            <div v-if="household.address">
              {{ household.address.street1 }}
              <div v-if="household.address.street2">
                {{ household.address.street2 }}
              </div>
              <div>
                {{ household.address.city }},
                {{ household.address.state }}
                {{ household.address.postal }}
              </div>
            </div>
          </div>
          <admin-administrators
            class="mt-5"
            entity-type="household"
          />
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card class="pa-5">
          <div class="text-h6-mulish">Applications</div>
          <v-data-table
            :headers="headers"
            :items="householdApplications"
            :single-select="true"
            class="mt-3"
            item-key="id"
          >
            <template v-slot:no-data>
                            <span class="text-apsto-teal">
                                There are no applications on file for this household.
                            </span>
            </template>
            <template v-slot:item.actionsApplication="{ item }">
              <v-tooltip
                bottom
                color="secondary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="secondary"
                    v-bind="attrs"
                    @click="reviewApplication(item)"
                    v-on="on"
                  >
                    mdi-format-list-bulleted
                  </v-icon>
                </template>
                <span>Review Application</span>
              </v-tooltip>
              <v-icon
                :disabled="disableDeleteLink(item)"
                color="red"
                @click="deleteApplication(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-dialog
            v-model="dialogDelete"
            :fullscreen="!!isMobile"
            :max-width="dialogMaxWidth"
          >
            <v-card>
              <v-card-title class="text-h5-mulish"> Confirm</v-card-title>
              <v-card-text
                class="pa-4 pl-6 black--text"
                style="font-size: 1.1rem"
              >
                Are you sure you want to delete this item?
              </v-card-text>
              <v-card-actions class="pt-3 pb-4">
                <v-spacer/>
                <v-btn
                  class="cancel-button"
                  rounded
                  @click="closeDeleteApplication"
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="body-2 font-weight-bold"
                  color="primary"
                  @click="deleteApplicationConfirm"
                >
                  YES
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminAdministrators from "../../main/AdminAdministrators";
import AdminHeader from "../../main/AdminHeader";
import ConfirmationDialog from "../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../components/shared/Loading";
import {
  DELETE_HOUSEHOLD_APPLICATION_ADMIN,
  GET_ADMIN_HOUSEHOLD,
  PATCH_HOUSEHOLD_ADMIN_VERSION
} from "../../../../store/actions.type";

export default {
  name: "AdminHouseholdShow",
  components: {
    AdminAdministrators,
    AdminHeader,
    ConfirmationDialog,
    Loading,
  },
  data() {
    return {
      dialogDelete: false,
      loading: true,
      saving: false,
      snackbar: false,
      snackbarMessage: "",

      headers: [
        {text: 'Year', value: 'school_year_name'},
        {text: 'Status', value: 'status_name'},
        {text: 'Options', align: 'center', value: 'actionsApplication', sortable: false},
      ],
    }
  },
  computed: {
    household() {
      return this.$store.getters.currentHousehold;
    },
    householdApplications() {
      return this.household.applications;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    closeDeleteApplication() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    disableDeleteLink(item) {
      return item.show_delete_link ? false : true;
    },
    deleteApplication(item) {
      this.editedIndex = this.householdApplications.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteApplicationConfirm() {
      this.destroyApplication();
      this.householdApplications.splice(this.editedIndex, 1);
      this.closeDeleteApplication();
    },
    destroyApplication() {
      this.saving = true;
      let household_id = this.$route.params.household_id;
      let household_application_id = this.editedItem.id;
      this.$store.dispatch(DELETE_HOUSEHOLD_APPLICATION_ADMIN, {household_id, household_application_id})
        .then(() => {
          this.saving = false;
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;

          this.snackbar = true;
        });
    },
    reviewApplication(item) {
      let household_id = this.$route.params.household_id;
      this.$router.push(`/dashboard/admin/households/${household_id}/applications/${item.id}/review`);
    },
    getHousehold() {
      this.loading = true;
      let household_id = this.$route.params.household_id;
      this.$store.dispatch(GET_ADMIN_HOUSEHOLD, household_id)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log("Household ERROR:", e);
        });
    },
    initialize() {
      this.household = [];
    },
    submit() {
      this.saving = true;
      this.$store.dispatch(PATCH_HOUSEHOLD_ADMIN_VERSION)
        .then(() => {
          this.saving = false;
          this.$router.push({name: 'admin-households-list'})
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.getHousehold();
    this.loading = false;
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDeleteApplication();
    },
  },
}
</script>

<style scoped>
a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
  height: 60px !important;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep .v-icon {
  font-size: 24px !important;
}</style>
