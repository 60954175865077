<template>
  <v-card class="dialog">
    <v-card-title class="pa-4">
      <div class="text-h5">
        {{ localCorporatePledge.id ? "Edit School Recommendation" : "Add School Recommendation" }}
      </div>
    </v-card-title>
    <div class="pa-4 text-apsto-black">
      <span class="font-weight-black">Instructions:</span>
      Please select or type the name of a school you would like to recommend and the percentage of your donation to
      allocate to that school. You can specify as many schools as you like as long as they add to 100.
      <div class="mt-2">
        To allocate part of your donation to our general fund, please select or type "<strong>General Fund</strong>"
        from the dropdown. When you do, we'll allocate your funds to schools we work with that need it most.
      </div>
    </div>
    <v-card-text class="mx-n2">
      <v-row>
        <v-col>
          <school-selector
            v-model="localCorporatePledge.id"
            general-fund="prepend"
          />
          <v-text-field
            v-model="localCorporatePledge.allocation_percentage"
            background-color="white"
            filled
            label="Allocation Percentage"
            suffix="%"
            @click="applyDecimal"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <div
      v-if="isMobile"
      class="px-4"
    >
      <v-btn
        block
        class="cancel-button"
        rounded
        @click="$emit('done')"
      > Cancel
      </v-btn>
      <v-btn
        :disabled="disableSubmitButton"
        block
        class="mt-5"
        color="primary"
        @click="save"
      > Save
      </v-btn>
    </div>
    <v-card-actions
      v-else
      class="mt-n9 pb-6"
    >
      <v-btn
        class="cancel-button"
        rounded
        @click="$emit('done')"
      > Cancel
      </v-btn>
      <v-btn
        :disabled="disableSubmitButton"
        color="primary"
        rounded
        @click="save"
      > Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SchoolSelector from "../../../components/shared/SchoolSelector";

export default {
  name: "CorporatePledgeRecommendationSchool",
  components: {
    SchoolSelector,
  },
  data: () => ({
    loading: true,
    saving: false,
  }),
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    localCorporatePledge: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    disableSubmitButton() {
      return !this.localCorporatePledge.id || !this.localCorporatePledge.allocation_percentage || this.localCorporatePledge.allocation_percentage < 1 || this.localCorporatePledge.allocation_percentage > 100;
    },
  },
  methods: {
    applyDecimal() {
      if (!this.localCorporatePledge.allocation_percentage) return;
      this.localCorporatePledge.allocation_percentage = parseFloat(this.localCorporatePledge.allocation_percentage).toFixed(3)
    },
    save() {
      this.applyDecimal();
      this.$emit("save");
    },
  },
};
</script>

<style scoped>
.errors-message {
  color: red;
  padding-left: 5px;
}
</style>
