<template>
  <div>
    <v-radio-group
      v-model="recommendationType"
      column
      @change="clearUnusedFields"
    >
      <template v-slot:label>
        <div
          class="pb-2 font-weight-black"
          style="font-size: 1.125rem; line-height: 1.5"
        >
          Do you have any recommendations for your contributions toward the
          <span
            class="text-apsto-teal"
            style="text-transform: capitalize;"
          >
                        {{ taxCreditType }}
                    </span>
          Tax Credit?
        </div>
      </template>
      <v-radio
        :value="0"
        color="secondary"
        label="No Preference"
      />
      <v-radio
        :value="1"
        color="secondary"
        label="Specific School"
      />
      <v-radio
        :value="2"
        color="secondary"
        label="Specific Student(s)"
      />
      <v-radio
        :value="3"
        color="secondary"
        label="Other"
      />
    </v-radio-group>

    <!--    User chooses specific school-->
    <div
      v-if="recommendationType === 1"
      class="mt-1"
    >
      <school-selector
        v-model="school_id"
        @input="append('school_id')"
      />
    </div>
    <!--    User chooses specific students and their school-->
    <div
      v-else-if="recommendationType === 2"
      class="mt-1"
    >
      <recommendation-page-students-table :tax-credit-type="taxCreditType"/>
    </div>
    <!--    User chooses to leave a comment-->
    <div
      v-else-if="recommendationType === 3"
      class="mt-1"
    >
      <div class="text-subtitle-2">
        Please describe how you would recommend this donation be distributed.
      </div>
      <v-textarea
        v-model="comments"
        :error="!!errors['comments']"
        :error-messages="errors['comments'] ? errors['comments'] : null"
        auto-grow
        background-color="secondaryLight"
        @blur="append('comments')"
      />
      <div class="text-subtitle-2 mb-4">
        Please note that a School Tuition Organization cannot award, restrict or
        reserve scholarships solely on the basis of a donor's recommendation.
      </div>
    </div>
  </div>
</template>

<script>
import SchoolSelector from "../../components/shared/SchoolSelector";
import RecommendationPageStudentsTable from "./RecommendationPageStudentsTable";
import {APPEND_DONOR_RECOMMENDATION} from "../../store/mutations.type";

export default {
  name: "RecommendationsPage",
  components: {
    RecommendationPageStudentsTable,
    SchoolSelector,
  },
  props: {
    taxCreditType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      comments: null,
      school_id: 0,
      recommendationType: 2,
    };
  },
  computed: {
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
  },
  methods: {
    append(field_name) {
      this.saving = true;
      let taxCreditType = this.taxCreditType;
      let value = field_name === "comments" ? this.comments : this.school_id;
      this.$store.commit(APPEND_DONOR_RECOMMENDATION, {taxCreditType, field_name, value})
      this.saving = false;
      this.$emit("done");
    },
    clearUnusedFields() {
      switch (this.recommendationType) {
        case 0:
          this.school_id = 0;
          this.comments = null;
          break;
        case 1:
          this.comments = null;
          break;
        case 2:
          this.school_id = 0;
          this.comments = null;
          break;
        case 3:
          this.school_id = 0;
          break;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-textarea textarea {
  padding: 10px;
}
</style>
