<template>
    <div
        class="text-center"
        fill-height
        style="height: calc(100vh - 58px);"
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col
                lg="6"
                md="8"
                rows="12"
            >
                <div class="text-h4 mt-10">We're sorry, we've upgraded to a new software platform and the link to the custom
                    donation page you're looking for no longer works.
                    <div class="mt-6">
                        Please contact Karen at (480) 256-1001 for the new link.
                    </div>
                </div>

                <v-btn
                    class="mt-9"
                    color="primary"
                    to="/"
                >
                    Go to Home Page
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "NotFound"
};
</script>
