<template>
    <div>
        <v-card
            v-if="!processed"
            class="pa-4"
        >
            <v-card-title class="pa-0">Successful Upload</v-card-title>
            <div class="mt-3">
                The file you just uploaded includes {{ processedRowsCount }} rows totaling {{
                    fileAmount |
                    formatCurrencyWithCents
                }}.
            </div>
            <div class="mt-3">
                Here's a sample of rows from your file to assist you to confirm that this is the information you intended to
                upload for this period.
            </div>
            <div class="font-weight-black mt-2">
                Sample Rows From Your File
            </div>
            <table>
                <tr
                    v-for="(person, index) in withholding.slice(0, 6)"
                    v-if="person['last_name']"
                >
                    <td class="pt-2">{{ person['first_name'] }} {{ person['last_name'] }}</td>
                    <td
                        v-if="index === 0"
                        class="pt-2 pl-4"
                        style="text-align: right"
                    >{{
                        person['amount'] | formatCurrencyWithCents
                    }}
                    </td>
                    <td
                        v-else
                        class="pt-2 pl-4"
                        style="text-align: right"
                    >{{ person['amount'] | formatNumberWithCents }}
                    </td>
                </tr>
            </table>
            <div
                v-if="missingEmployees.length"
                class="mt-3"
            >
                <h3>Missing Employees</h3>
                <div class="pt-4">
                    The following employees have authorized us to withhold donations but are not in the file you
                    uploaded. You can:
                    <ol
                        class="mt-2"
                        style="padding-left: 16px;"
                    >
                        <li>
                            Add them to your file then start your upload again.
                        </li>
                        <li>
                            Terminate their payroll withholding if they're no longer participating in this program.
                        </li>
                        <li>
                            Proceed as normal by clicking the Proceed button below.
                        </li>
                    </ol>

                    <v-data-table
                        :headers="headersMissingEmployees"
                        :items="missingEmployees"
                        class="mt-5"
                    >
                        <template v-slot:top>
                            <v-dialog
                                v-model="dialogDelete"
                                :fullscreen="!!isMobile"
                                :max-width="dialogMaxWidth"
                            >
                                <v-card>
                                    <v-card-title class="text-h5-mulish">
                                        Confirm
                                    </v-card-title>
                                    <v-card-text
                                        class="pa-4 pl-6 black--text"
                                        style="font-size: 1.1rem;"
                                    >
                                        Are you sure you want to terminate payroll withholding for this employee?
                                    </v-card-text>
                                    <v-card-actions class="pt-3 pb-4">
                                        <v-spacer />
                                        <v-btn
                                            class="body-2 font-weight-bold"
                                            color="grey"
                                            text
                                            @click="closeDeleteWithholdingDonation"
                                        >
                                            CANCEL
                                        </v-btn>
                                        <v-btn
                                            class="body-2 font-weight-bold"
                                            color="primary"
                                            @click="deleteWithholdingDonationConfirm"
                                        >
                                            YES
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-snackbar
                                v-model="snackbar"
                                centered
                            >
                                {{ snackbarMessage }}
                                <template v-slot:action="{ attrs }">
                                    <v-btn
                                        text
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                    >
                                        Close
                                    </v-btn>
                                </template>
                            </v-snackbar>
                        </template>
                        <template v-slot:item.actionsWithholdingDonation="{ item }">
                            <v-tooltip
                                bottom
                                color="secondary"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="mr-2"
                                        color="red"
                                        v-bind="attrs"
                                        @click="deleteWithholdingDonation(item)"
                                        v-on="on"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <span>Terminate Payroll Withholding</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </div>
            </div>
            <div class="mt-5">
                <div>
                    <v-btn
                        :block="isMobile"
                        class="cancel-button"
                        rounded
                        @click="hideResults"
                    >
                        Cancel, Wrong Info
                    </v-btn>
                    <v-btn
                        :block="isMobile"
                        :class="isMobile ? 'mt-5' : 'ml-2'"
                        color="primary"
                        rounded
                        @click="storeFileContents"
                    >
                        Looks Good, Proceed
                    </v-btn>
                </div>
            </div>
        </v-card>
        <v-card v-if="processed">
            <v-card-title
                class="pa-5 justify-center"
                style="background-color: var(--apsto-teal-light);"
            >
                <div class="text-h4">
                    Thank you!
                </div>
            </v-card-title>
            <div
                class="mx-2 mt-5 text-h6-mulish"
                style="text-align: center;"
            >
                You successfully created {{ countStored }} payroll withholding donation{{ countStored > 1 ? 's.' : '.' }}
            </div>
            <v-card-actions class="py-6 justify-center">
                <v-btn
                    color="primary"
                    @click="cancel"
                >OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { DELETE_DONOR_WITHHOLDING_DONATION, POST_EMPLOYEE_DONATION } from "../../../store/actions.type";
import { CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE } from "../../../store/mutations.type";

export default {
    name: "UploadSucceeded",
    props: {
        company: {
            type: Object,
            required: true
        },
        fileAmount: {
            type: Number,
            required: true
        },
        missingEmployees: {
            type: Array,
            required: true
        },
        processedRowsCount: {
            type: Number,
            required: true
        },
        withholding: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            countStored: 0,
            dialogDelete: false,
            form: {
                calendar_year: null,
                month: new Date().getMonth() + 1,
            },
            headersMissingEmployees: [
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Options', value: 'actionsWithholdingDonation', align: 'end', sortable: false },
            ],
            months: [
                { id: 1, name: "January" },
                { id: 2, name: "February" },
                { id: 3, name: "March" },
                { id: 4, name: "April" },
                { id: 5, name: "May" },
                { id: 6, name: "June" },
                { id: 7, name: "July" },
                { id: 8, name: "August" },
                { id: 9, name: "September" },
                { id: 10, name: "October" },
                { id: 11, name: "November" },
                { id: 12, name: "December" },
            ],
            processed: false,
            showForm: false,
            snackbar: false,
            snackbarMessage: "",

            years: []
        }
    },
    computed: {
        withheldPayrollSelections() {
            return this.$store.getters.currentWithheldPayrollSelections;
        },
    },
    methods: {
        cancel() {
            this.processedRowsCount = 0;
            this.$router.go(-1)
        },
        closeDeleteWithholdingDonation() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        deleteWithholdingDonation(item) {
            this.editedIndex = this.missingEmployees.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteWithholdingDonationConfirm() {
            this.destroyWithholdingDonation();
            this.missingEmployees.splice(this.editedIndex, 1)
            this.closeDeleteWithholdingDonation()
        },
        destroyWithholdingDonation() {
            this.saving = true;
            let donor_selection_id = this.editedItem.id;
            this.$store.dispatch(DELETE_DONOR_WITHHOLDING_DONATION, donor_selection_id)
                .then(() => {
                    this.saving = false;
                })
                .catch(({ data }) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        hideResults() {
            this.$store.commit(CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE);
        },
        storeFileContents() {
            this.saving = true;

            const form = {
                calendar_year: this.withheldPayrollSelections.calendar_year,
                month: this.withheldPayrollSelections.month,
                company_id: this.company.id,
            }

            this.$store.dispatch(POST_EMPLOYEE_DONATION, form)
                .then((data) => {
                    this.saving = false;
                    this.processed = true;
                    this.countStored = data.countStored;
                })
                .catch((response) => {
                    this.saving = false;
                    this.snackbarMessage = response.data.message_response;
                    this.snackbar = true;
                });
        },
    },
    mounted() {
        this.yearNow = new Date().getFullYear();
        this.years = [
            { id: 2, name: this.yearNow },
            { id: 1, name: this.yearNow - 1 },
        ]
    },
    destroyed() {
        this.$store.commit(CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE);
    },
}
</script>

<style scoped>
li {
    font-size: 1rem !important;
    margin-bottom: 4px !important;
}

.box-with-background {
    border-radius: 4px;
}
</style>
