<template>
    <loading v-if="loading" />
    <div v-else>
        <admin-company-header :page-title="'Payroll Withholding - ' + employeeDonationsYear" />
        <v-row
            align="center"
            class="mt-n6"
        >
            <v-col cols="auto">
                <year-and-quarter-selectors />
            </v-col>
            <v-col>
                <v-btn
                    :href="`/api/companies/${$route.params.company_id}/employee-donations/download/payments-list`"
                    color="primary"
                    rounded
                >
                    <v-icon left> mdi-download</v-icon>
                    Download Payments List
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col style="text-align: right;">
                <v-btn
                    :to="{ name: 'admin-employee-donations-upload', params: { company_id: company.id } }"
                    color="primary"
                    rounded
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    Upload Payroll Withholding
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="companyPayrollBatchList"
                    :single-select="true"
                    class="elevation-2"
                    item-key="id"
                    @click:row="show"
                >
                    <template v-slot:no-data>
                        <span class="text-apsto-teal">
                            No data available. Click Add button above.
                        </span>
                    </template>
                    <template #item.amount="{ item }">
                        {{ item.amount | formatCurrencyWithCents }}
                    </template>
                    <template #item.created_at="{ item }">
                        {{ displayDate(item.created_at) }}
                    </template>
                    <template #item.fully_paid_date="{ item }">
                        <div v-if="item.fully_paid_date">
                            {{ displayDate(item.fully_paid_date) }}
                        </div>
                    </template>
                    <template #item.paid="{ item }">
                        <div v-if="item.paid === item.amount"> Yes</div>
                        <div
                            v-else-if="item.paid > item.amount"
                            class="red--text font-weight-black"
                        > Partially
                        </div>
                        <div
                            v-else
                            class="red--text font-weight-black"
                        >No
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import { GET_EMPLOYEE_DONATIONS } from "../../../store/actions.type";
import AdminCompanyHeader from "../main/AdminCompanyHeader";
import YearAndQuarterSelectors from "../../../components/shared/YearAndQuarterSelectors";

export default {
    name: "AdminCompanyPayrollBatchList",
    components: {
        AdminCompanyHeader,
        Loading,
        YearAndQuarterSelectors
    },
    data() {
        return {
            loading: true,
            search: '',
            selected: [],
        }
    },
    computed: {
        company() {
            return this.$store.getters.currentUserCompany;
        },
        companyPayrollBatchList() {
            return this.employeeDonations['companyPayrollBatch'];
        },
        employeeDonations() {
            return this.$store.getters.currentEmployeeDonations;
        },
        employeeDonationsYear() {
            return this.employeeDonations['year']
        },
        headers() {
            return [
                { text: 'Amount', value: 'amount' },
                { text: 'Year Withheld', value: 'calendar_year' },
                { text: 'Month Withheld', value: 'month' },
                { text: 'Uploaded', value: 'created_at' },
                { text: 'Batch #', value: 'id', align: 'center' },
                { text: 'Paid', value: 'paid' },
                { text: 'Date Fully Paid', value: 'fully_paid_date' }
            ]
        },
    },
    methods: {
        show(item) {
            let company_annual_donation_id = this.$route.params.company_annual_donation_id;
            this.$router.push(
                {
                    name: 'admin-company-payroll-batch-show',
                    params: {
                        company_id: this.company.id,
                        company_annual_donation_id: company_annual_donation_id,
                        company_payroll_batch_id: item.id,
                    }
                }
            );
        },
        getEmployeeDonations() {
            let company_id = this.$route.params.company_id;
            let company_annual_donation_id = this.$route.params.company_annual_donation_id
            this.$store.dispatch(GET_EMPLOYEE_DONATIONS, { company_id, company_annual_donation_id })
                .then(() => {
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.getEmployeeDonations();
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}


::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}
</style>
