import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {GET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN,} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_ERROR, SET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN} from "./mutations.type";
/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        studentDropdown: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentStudents(state) {
        return state.studentDropdown;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN](context, {application_id, household_id}) {
        //Prevent loading students over and over
        if (context.state.studentDropdown.length) return context.state.studentDropdown;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications/${application_id}/dropdown-students`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "studentDropdown",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN](state, data) {
        state.studentDropdown = data;
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
