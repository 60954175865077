<template>
    <v-row v-if="!editMode">
        <v-col
            cols="12"
            v-if="content.title"
        >
            <h2 class="font-weight-bold secondary--text">
                {{ content.title }}
            </h2>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            v-html="content.body.text"
            style="padding-top: 0 !important;"
        />
        <v-col
            cols="12"
            sm="6"
        >
            <v-img
                :src="content.body.image"
                contain
                max-height="285"
                max-width="420"
            ></v-img>
        </v-col>

    </v-row>
    <v-row
        v-else
        class="mt-5"
    >
        <v-col cols="12">
            <v-text-field
                label="Enter Heading"
                :loading="loading"
                class="font-weight-black secondary--text header"
                v-model="localContent.title"
                @focus="lockFab"
                @change="updateContent"
            />
        </v-col>
        <v-col cols="12">
            <wysiwyg-editor
                v-model="localContent.body.text"
                @update="updateContent"
            />
        </v-col>
    </v-row>
</template>

<script>
import WysiwygEditor from "../WysiwygEditor";

import { PATCH_CONTENT } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from '../../../../store/mutations.type';

export default {
    name: "ParagraphImage",
    props: {
        content: {
            type: Object,
        },
    },
    components: {
        WysiwygEditor,
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
    },
    data() {
        return {
            loading: false,
            localContent: null,
        };
    },
    created() {
        this.localContent = this.content;
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let self = this;
            let content = this.localContent;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    self.loading = false;
                })
                .catch((e) => {
                    self.loading = false;
                });
            return;
        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        }
    },
};
</script>

<style scoped>
::v-deep .header input {
    color: #55a9ad !important;
    caret-color: #55a9ad !important;
    font-weight: 700 !important;
    font-family: var(--font-TITLE) !important;
    font-size: 1.5em;
}
</style>
