<template>
  <div class="text-center"
       fill-height
       style="height: calc(100vh - 58px);"
  >
    <v-row align="center" justify="center">
      <v-col rows="12" md="8" lg="6">
        <div class="text-h4 mt-10">
          The action you've requested can only be performed by your group's
          <span class="text-apsto-teal">primary</span>
          administrator.
        </div>

        <v-btn
          color="primary"
          to="/"
          class="mt-9"
        >
          Go to Home Page
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "RequiresPrimaryAdmin"
};
</script>
