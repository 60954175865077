<template>
  <div class="mr-5">
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Corporate Donation Requests By Year Range"/>
        <v-card class="mt-2">
          <v-card-title>Corporate Donation Requests Report</v-card-title>
          <v-card-subtitle>Please enter a calendar year span to view</v-card-subtitle>
          <v-divider/>
          <v-form class="pa-4">
            <v-text-field
              v-model="dates.year_from"
              :error="!!errors.year_from"
              :error-messages="errors.year_from ? errors.year_from : null"
              filled
              label="From Calendar Year"
            />
            <v-text-field
              v-model="dates.year_thru"
              :error="!!errors.year_thru"
              :error-messages="errors.year_thru ? errors.year_thru : null"
              filled
              label="Thru Calendar Year"
            />
            <div class="mt-2">
              <v-btn
                :to="{ name: 'admin-menu' }"
                rounded
              > Cancel
              </v-btn>
              <v-btn
                :disabled="disableSubmit"
                class="ml-1"
                color="primary"
                rounded
                @click="submit"
              >
                Submit
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import AdminHeader from "../main/AdminHeader";
import {POST_ADMIN_CORPORATE_PLEDGES_REPORT_DATES} from "../../../store/actions.type";

export default {
  name: "AdminCorporatePledgesReportDates",
  components: {
    Loading,
    AdminHeader
  },
  data() {
    return {
      dates: {
        year_from: '',
        year_thru: '',
      },
      saving: false
    }
  },
  computed: {
    disableSubmit() {
      return !this.dates.year_from || !this.dates.year_thru;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getDates() {
      this.dates.year_from = new Date().getFullYear();
      this.dates.year_thru = new Date().getFullYear();
    },
    submit() {
      this.saving = true;
      this.$store.dispatch(POST_ADMIN_CORPORATE_PLEDGES_REPORT_DATES, this.dates)
        .then(() => {

          let year_from = this.dates.year_from;
          let year_thru = this.dates.year_thru;

          this.$router.push(`/dashboard/admin/corporate-pledges/activity-report/from/${year_from}/thru/${year_thru}`)
          this.saving = false;
        })
        .catch((response) => {
          console.log(response)
          this.saving = false;
        })
    }
  },
  mounted() {
    this.getDates();
  }
}
</script>

<style scoped></style>
