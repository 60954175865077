<template>
  <div v-else>
    <div class="text-h6-mulish">Name</div>
    <div class="mt-2">
      {{ school.name }}
    </div>
    Web: <a :href="school.website" target="_blank" color="primary">{{ school.website }}</a>
    <br>
    Phone: {{ school.phone }}
    <br>
    Fax: {{ school.fax }}

    <div class="text-h6-mulish mt-4">Address</div>
    <div class="mt-2"> {{ school.physical_address.street1 }}</div>
    <div v-if="school.physical_address.street2">{{ school.physical_address.street2 }}</div>
    <div> {{ school.physical_address.city }}, {{ school.physical_address.region }}
      {{ school.physical_address.postal }}
    </div>

    <div class="text-h6-mulish mt-4">Mailing Address</div>
    <div class="mt-2"> {{ school.address.street1 }}</div>
    <div v-if="school.address.street2">{{ school.address.street2 }}</div>
    <div> {{ school.address.city }}, {{ school.address.region }} {{ school.address.postal }}</div>

    <v-row class="mt-4" align="center">
      <v-col cols="auto" class="text-h6-mulish">Primary Contact</v-col>
      <v-col>
        <a
          :href="'/impersonate/take/' + school.primary_user_id"
          style="color: var(--apsto-teal); font-weight: 700;"
        >
          Impersonate
        </a>
      </v-col>
    </v-row>

    <div class="mt-2">
      {{ school.primary_user_name }}, {{ school.primary_user_title }}
      <br>
      Email:
      <a :href="'mailto:' + school.primary_user_email" target="_blank" color="primary">
        {{ school.primary_user_email }}
      </a>
      <br>
      Phone: {{ school.primary_user_phone }}
    </div>

    <div class="text-h6-mulish mt-4">Status</div>

    <!--Active-->
    <v-checkbox
      v-model="school.active"
      :error="!!errors['active']"
      :error-messages="errors['active'] ? errors['active'] : null"
      color="primary"
      @change="updateSchoolCheckbox('active')"
      class="mt-1"
    >
      <template v-slot:label>
        <div class="font-weight-black"> Active</div>
      </template>
    </v-checkbox>

    <!--Disabled-->
    <v-checkbox
      v-model="school.disabled"
      :error="!!errors['disabled']"
      :error-messages="errors['disabled'] ? errors['disabled'] : null"
      color="primary"
      @change="updateSchoolCheckbox('disabled')"
      class="mt-n4"
    >
      <template v-slot:label>
        <div class="font-weight-black"> Provides services for disabled students</div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import {PATCH_SCHOOL_CHECKBOX} from "../../../../../store/actions.type";

export default {
  name: "AdminSchoolAddress",
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    school() {
      return this.$store.getters.currentAdminSchool;
    },
    error() {
      return this.$store.getters.getMessage("adminSchools");
    },
    errors() {
      return this.$store.getters.getErrors("adminSchools");
    },
  },
  methods: {
    updateSchoolCheckbox(field_name) {
      this.saving = true;
      let school_id = this.$route.params.school_id;
      this.$store.dispatch(PATCH_SCHOOL_CHECKBOX, {school_id, field_name})
        .then(() => {
          this.saving = false;
        });
    },
  },
}
</script>

<style scoped>

</style>