<template>
  <div class="ma-5">
    <div class="text-h5-mulish pt-3">Donation Withholding Form Upload</div>
    <v-row>
      <v-col md="5">
        <v-card>
          <v-card-subtitle class="mt-5">
            Click the line below to select a file to upload.
          </v-card-subtitle>
          <v-card-text>
            <v-file-input
              v-model="file"
              :rules="rules"
              accept=".jpg,.jpeg,.pdf,.png"
              show-size
              truncate-length="15"
            />
          </v-card-text>
          <v-card-actions v-if="!saveWithholdingFormDisabled" class="pb-4 px-4">
            <v-btn
              :disabled="saveWithholdingFormDisabled"
              color="primary"
              @click="saveWithholdingForm"
            >
              Save
            </v-btn>
          </v-card-actions>
          <v-card-subtitle
            v-if="uploadedDocument && uploadedDocument.id"
            class="teal-text pt-4"
          >
            You uploaded:
            <a :href="'/donor-withholdings/' + uploadedDocument.id" target="_blank">
              <span class="text-apsto-teal">{{ uploadedDocument.title }}</span>
            </a>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {GET_DONOR_SELECTION_WITH_FILE, POST_DONOR_WITHHOLDING_FORM_UPLOAD} from "../../../store/actions.type";

export default {
  name: "DonationWithholdingFormUpload",
  data() {
    return {
      file: null,
      rules: [
        value => !value || value.size < 5000000 || 'File size should be less than 5 MB!',
      ],
      saving: false,
    }
  },
  computed: {
    donorSelection() {
      return this.$store.getters.currentDonorSelectionWithFile;
    },    
    saveWithholdingFormDisabled() {
      return !this.file;
    },
    uploadedDocument() {
      return this.donorSelection.file;
    },
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
  },
  methods: {
    getDonorSelection() {
      let donor_selection_id = this.$route.params.donor_selection_id;
      this.$store.dispatch(GET_DONOR_SELECTION_WITH_FILE, donor_selection_id)
        .then(() => {
          this.loading = false;
        });
    },
    saveWithholdingForm() {
      this.saving = true;
      let donor_selection_id = this.$route.params.donor_selection_id;
      let file = this.file;
      this.$store.dispatch(POST_DONOR_WITHHOLDING_FORM_UPLOAD,
        {
          donor_selection_id,
          file
        }
      )
        .then(() => {
          this.file = null;
          this.saving = false;
          this.getDonorSelection();
        });
    },
  },
  mounted() {
    this.getDonorSelection();
  }
}
</script>

<style scoped>

</style>