<template>
  <div class="content">
    <span class="font-weight-bold">To qualify for the {{ item.scholarship_label }}:</span>
    <div class="mt-2">
      <v-row>
        <v-col style="line-height: 1.5;">
                    <span
                      class="text-apsto-teal font-weight-black"
                      style="font-size: 16px;"
                    >(A)</span>
          A student needs to be a member of a household <strong>that reports income</strong> and whose income
          is not greater than 185% of 185% (342.25%) of the federal poverty level for this application school
          year,
          <span class="font-weight-bold">AND</span>
        </v-col>
      </v-row>
      <div class="my-2">
                <span
                  class="text-apsto-teal font-weight-black"
                  style="font-size: 16px;"
                >(B)</span>
        at least one of the following is true:
      </div>
      <ol>
        <li>
          the student is a dependent of a member of the U.S. Armed Forces who is stationed in Arizona under
          military orders, OR
        </li>
        <li>
          the student is enrolling or is currently enrolled in private school Kindergarten or is enrolling or is
          currently enrolled in Preschool and is disabled and attends a school that provides services for
          disabilities,
          OR
        </li>
        <li>
          the student was awarded a scholarship (disabled / displaced, low income, original or switcher) for the
          {{ this.application.prior_school_year_name }} school year, OR
        </li>
        <li>
          the student transferred in from an Arizona charter or public school, OR
        </li>
        <li>
          the student moved from another state or country and transferred directly to an Arizona private school,
        </li>
        <li>
          the student directly exited an ESA program to participate in a tuition tax credit program, OR
        </li>
        <li>
          the student was home-schooled immediately prior to transferring directly to an Arizona Private school
        </li>
      </ol>
    </div>
    <div
      v-if="item.qualifies === 'Yes'"
      class="mt-4"
    >
      <v-divider class="pt-2"/>
      <span class="font-weight-bold">
                The household income you provided for this application school year
                <span style="color: var(--apsto-purple);"> MEETS </span> the income requirement in Part A above:
            </span>
      <family-member-income-table class="my-3"/>
      <span class="font-weight-bold">
                You've also indicated that {{ item.model.first_name }} meets the following Part B
                requirements during this application school year:
            </span>
      <div class="mt-2"></div>
      <div v-if="item.model.student.active_military">
        Is a dependent of a member of the U.S. Armed Forces who is stationed in Arizona under
        military orders.
        <br>
      </div>
      <div v-if="item.model.student.grade_id === 2">
        Is in Kindergarten.
        <br>
      </div>
      <div v-if="isDisabledPreschooler(item)">
        Is in Preschool and is disabled and attends a school that offers services for the disabled.
        <br>
      </div>
      <div v-if="item.model.student.previous_scholarship_award_1">
        Was previously awarded a disabled/displaced scholarship
        <br>
      </div>
      <div v-if="item.model.student.previous_scholarship_award_2">
        Was previously awarded a low income corporate scholarship
        <br>
      </div>
      <div v-if="item.model.student.previous_scholarship_award_3">
        Was previously awarded an original scholarship
        <br>
      </div>
      <div v-if="item.model.student.previous_scholarship_award_4">
        Was previously awarded a switcher scholarship
        <br>
      </div>
      <div v-if="item.model.student.transfer_from_charter_or_public_school">
        Transferred in from a charter or public school
        <br>
      </div>
      <div v-if="item.model.student.transfer_from_different_state">
        Transferred in from a different state
        <br>
      </div>
      <div v-if="item.model.student.esa_enum === 2">
        Directly exited an ESA program to participate in a tuition tax credit program
        <br>
      </div>
      <div v-if="item.model.student.transfer_from_home_school">
        Was home-schooled immediately prior to transferring directly to an Arizona Private school
        <br>
      </div>
    </div>
    <div
      v-else
      class="mt-2"
    >
      <v-divider class="pt-2"/>
      <div v-if="application.household_qualifies_for_low_income_credit === false">
                <span class="font-weight-bold">
                    The household income you provided for this application school year
                    <span class="text-apsto-purple"> DOES NOT MEET </span>
                    the income requirement in Part A above.
                </span>
        <family-member-income-table class="my-3"/>
      </div>
      <div v-else>
                <span class="font-weight-bold">
                    The household income you provided for this application school year
                    <span class="text-apsto-purple"> MEETS </span>
                    the income requirement in Part A above:
                </span>
        <family-member-income-table class="my-3"/>
        <div v-if="item.model.student.qualifies_for_low_income_corporate_part_2">
          <div class="font-weight-bold">
            You've also indicated that {{ item.model.first_name }} meets the following Part B requirements
            for this application school year:
          </div>
          <div class="mt-2"></div>
          <div v-if="item.model.student.active_military">
            Is a dependent of a member of the U.S. Armed Forces who is stationed in Arizona under
            military orders.
            <br>
          </div>
          <div v-if="item.model.student.grade_id === 2">
            Is in Kindergarten.
            <br>
          </div>
          <div v-if="isDisabledPreschooler(item)">
            Is in Preschool and is disabled and attends a school that offers services for the disabled.
            <br>
          </div>
          <div v-if="item.model.student.previous_scholarship_award_1">
            Was previously awarded a disabled/displaced scholarship
            <br>
          </div>
          <div v-if="item.model.student.previous_scholarship_award_2">
            Was previously awarded a low income corporate scholarship
            <br>
          </div>
          <div v-if="item.model.student.previous_scholarship_award_3">
            Was previously awarded an original scholarship
            <br>
          </div>
          <div v-if="item.model.student.previous_scholarship_award_4">
            Was previously awarded a switcher scholarship
            <br>
          </div>
          <div v-if="item.model.student.transfer_from_charter_or_public_school">
            Transferred in from a charter or public school
            <br>
          </div>
          <div v-if="item.model.student.transfer_from_different_state">
            Transferred in from a different state
            <br>
          </div>
          <div v-if="item.model.student.esa_enum === 2">
            Directly exited an ESA program to participate in a tuition tax credit program
            <br>
          </div>
          <div v-if="item.model.student.transfer_from_home_school">
            Was home-schooled immediately prior to transferring directly to an Arizona Private school
            <br>
          </div>
        </div>
        <div v-if="item.model.student.qualifies_for_low_income_corporate_part_2 === false">
          <div v-if="item.model.student.esa_enum === 3">
            However {{ item.model.first_name }} is enrolled in the ESA program and cannot qualify for any tax
            credit
            scholarship while in that program.
          </div>
          <div v-else>
            Unfortunately, {{ item.model.first_name }} did not meet any of the other requirements listed in Part
            B above
            during this application school year.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FamilyMemberIncomeTable from "../FamilyMemberIncomeTable.vue";

export default {
  name: "ScholarshipLowIncome",
  components: {FamilyMemberIncomeTable},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    application() {
      return this.$store.getters.currentApplication;
    },
  },
  methods: {
    isDisabledPreschooler(item) {
      return item.model.student.grade_id === 1 &&
        item.model.student.disabled &&
        item.model.student.school_has_disabled;
    }
  }
}

</script>

<style scoped>
ol > li {
  font-size: .9rem;
  margin-bottom: 0;
}

.content {
  font-family: "Mulish", sans-serif;
  font-size: .9rem;
  line-height: 1.40rem;
}
</style>
