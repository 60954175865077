import { render, staticRenderFns } from "./OnlineDonationCreate.vue?vue&type=template&id=0638f156&scoped=true&"
import script from "./OnlineDonationCreate.vue?vue&type=script&lang=js&"
export * from "./OnlineDonationCreate.vue?vue&type=script&lang=js&"
import style0 from "./OnlineDonationCreate.vue?vue&type=style&index=0&id=0638f156&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0638f156",
  null
  
)

export default component.exports