<template>
  <v-data-table
    :expanded.sync="expanded"
    :headers="headers"
    :items="awardsBySchoolAndPeriod"
    :search="search"
    :single-expand="singleExpand"
    class="elevation-1"
    item-key="application_student_school_id"
    show-expand
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        class="mx-4"
        label="Search"
      ></v-text-field>
    </template>
    <template v-slot:group.header="{ items, isOpen, toggle }">
      <th colspan="2">
        <v-icon @click="toggle">
          {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
        {{ items[0].name }}
      </th>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td
        :colspan="headers.length"
        style="background: var(--apsto-teal-very-light)"
      >
        <student-available-awards
          :key="item.application_student_school_id"
          :application-student-school-id="item.application_student_school_id"
          :fiscal-year-id="fiscalYearId"
          :school-id="schoolId"
          @update-saveable="saveable"
        />
      </td>
    </template>
    <template v-slot:item.amount="{ item }">
      {{ item.amount | formatNumberWithCents }}
    </template>
    <template v-slot:item.remaining_balance="{ item }">
      {{ item.remaining_balance | formatNumberWithCents }}
    </template>
    <template v-slot:item.tax_credit_type="{ item }">
      <div v-if="1">
        Disabled/Displaced Tax Credit Scholarship (Lexie's Law)
      </div>
      <div v-else-if="2">
        Low Income Corporate Tax Credit Scholarship
      </div>
      <div v-else-if="3">
        Original Tax Credit Scholarship
      </div>
      <div v-else-if="4">
        Switcher (Overflow/Plus) Tax Credit Scholarship
      </div>
      <div v-else-if="5">
        Charitable Contribution
      </div>
    </template>
  </v-data-table>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {GET_AWARDS_BY_SCHOOL_AND_PERIOD, GET_FISCAL_YEARS} from "../../../store/actions.type";
import {SET_SCHOOL_STUDENT_AWARD_REFUND} from "../../../store/mutations.type";
import StudentAvailableAwards from "./StudentAvailableAwards.vue";

export default {
  name: "DataTableStudentAvailableAwards",
  components: {
    StudentAvailableAwards,
    Loading,
  },
  props: {
    award: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      headers: [
        {text: "Student Name", value: "name"},
        {text: "Available Balance", value: "remaining_balance", align: "end"},
        {value: 'data-table-expand'}
      ],
      loading: false,
      saveable: false,
      search: '',
    }
  },
  computed: {
    deposit() {
      return this.$store.getters.currentAdminDeposit;
    },
    fiscalYearId() {
      return this.award.fiscal_year_id;
    },
    awardsBySchoolAndPeriod() {
      return this.$store.getters.currentAwardsBySchoolAndPeriod;
    },
    schoolId() {
      return this.deposit.school_id ? this.deposit.school_id : 0;
    },
  },
  methods: {
    getSchoolStudentAwards(fiscal_year_id) {
      if (!this.deposit.school_id) return;

      this.loading = true;
      let school_id = this.deposit.school_id;
      this.$store.dispatch(GET_AWARDS_BY_SCHOOL_AND_PERIOD, {fiscal_year_id, school_id}).then(() => {
        this.loading = false;
      })
        .catch((response) => {
          console.log(response)
        });
    },
    updateSaveable(value) {
      console.log('saveable: ', value);
      this.saveable = value;
    },
  },
}
</script>