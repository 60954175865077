<template>
    <loading v-if="loading" />
    <div v-else>
        <v-card-title class="pl-0 pt-0">
            <v-row
                align="center"
                class="ml-1 mt-4"
            >
                <v-col
                    class="pa-0"
                    cols="auto"
                >
                    <school-header page-title="Partner Agreements" />
                </v-col>
                <v-spacer />
                <v-col
                    class="pa-0"
                    cols="auto"
                >
                    <v-btn
                        :to="{ name: 'school-application-create', params: { school_id: school.id } }"
                        color="primary"
                        rounded
                    >
                        <v-icon left> mdi-plus-circle-outline</v-icon>
                        Create Partner Agreement
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-spacer />
        <v-data-table
            :disable-sort="isMobile"
            :headers="headers"
            :items="schoolApplications"
            class="elevation-2 mt-3"
            hide-default-footer
        >
            <template v-slot:no-data>
                <span class="text-apsto-teal">
                    No data added. Click Add button above.
                </span>
            </template>
            <template v-slot:top>
                <v-dialog
                    v-model="dialogDelete"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <v-card>
                        <v-card-title class="text-h5-mulish">
                            Confirm
                        </v-card-title>
                        <v-card-text
                            class="pa-4 pl-6 black--text"
                            style="font-size: 1.1rem;"
                        >
                            Are you sure you want to delete this item?
                        </v-card-text>
                        <v-card-actions class="pt-3 pb-4">
                            <v-spacer />
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="closeDeleteSchoolApplication"
                            >
                                CANCEL
                            </v-btn>
                            <v-btn
                                class="save-by-cancel-button"
                                color="primary"
                                rounded
                                @click="deleteSchoolApplicationConfirm"
                            >
                                YES
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                    v-model="snackbar"
                    centered
                >
                    {{ snackbarMessage }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </template>
            <template #item.status_name="{ item }">
                <div v-if="item.status === 2">
                    <v-icon
                        class="mr-1"
                        color="secondary"
                    >fa-lock
                    </v-icon>
                    Locked while in review by APSTO
                </div>
                <div v-else>{{ item.status_name }}</div>
            </template>
            <template v-slot:item.actionsApplication="{ item }">

                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :disabled="disableEditLink(item)"
                            class="mr-2"
                            color="iconCyan"
                            v-bind="attrs"
                            @click="editSchoolApplication(item)"
                            v-on="on"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Edit Partner Agreement</span>
                </v-tooltip>

                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-2"
                            color="primary"
                            v-bind="attrs"
                            @click="viewSchoolApplication(item)"
                            v-on="on"
                        >
                            mdi-format-list-bulleted
                        </v-icon>
                    </template>
                    <span>View Partner Agreement</span>
                </v-tooltip>


                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-2"
                            color="secondary"
                            v-bind="attrs"
                            @click="sendEmail(item)"
                            v-on="on"
                        >
                            mdi-email
                        </v-icon>
                    </template>
                    <span>Email Me A Copy</span>
                </v-tooltip>
                <v-icon
                    :disabled="disableDeleteLink(item)"
                    color="red"
                    @click="deleteSchoolApplication(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import SchoolHeader from "../../../../components/school/SchoolHeader";
import Loading from "../../../../components/shared/Loading";
import {
    DELETE_SCHOOL_APPLICATION,
    EMAIL_SCHOOL_APPLICATION,
    GET_SCHOOL_APPLICATIONS
} from "../../../../store/actions.type";

export default {
    name: "SchoolApplicationList",
    components: {
        SchoolHeader,
        Loading
    },
    data() {
        return {
            defaultItem: {
                date_submitted: '',
                name: '',
                status_name: '',
            },
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                    text: 'School Year',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Status', value: 'status_name' },
                { text: 'Date SUBMITTED TO APSTO', value: 'date_submitted' },
                { text: 'Options', align: 'center', value: 'actionsApplication', sortable: false },
            ],
            loading: true,
            sending: false,
            snackbar: false,
            snackbarMessage: "",

        }
    },
    computed: {
        schoolApplications() {
            return this.$store.getters.currentSchoolApplications;
        },
        school() {
            return this.$store.getters.currentUserSchool;
        }
    },
    methods: {
        getSchoolApplications() {
            this.$store.dispatch(GET_SCHOOL_APPLICATIONS, this.$route.params.school_id)
        },
        closeDeleteSchoolApplication() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        disableDeleteLink(item) {
            return !item.can_delete;
        },
        disableEditLink(item) {
            return !item.can_edit;
        },
        deleteSchoolApplication(item) {
            this.editedIndex = this.schoolApplications.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteSchoolApplicationConfirm() {
            this.destroySchoolApplication();
            this.schoolApplications.splice(this.editedIndex, 1)
            this.closeDeleteSchoolApplication()
        },
        destroySchoolApplication() {
            this.saving = true;
            let school_application_id = this.editedItem.id;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(DELETE_SCHOOL_APPLICATION, { school_id, school_application_id })
                .then(() => {
                    this.saving = false;
                })
                .catch(({ response }) => {
                    this.snackbarMessage = response.message_response;
                    this.snackbar = true;
                });
        },
        editSchoolApplication(item) {
            this.$router.push(`partner-agreements/${item.id}/edit`);
        },
        initialize() {
            this.schoolApplications = [];
        },
        sendEmail(item) {
            this.editedIndex = this.schoolApplications.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.sendEmailToRecipient();
        },
        sendEmailToRecipient() {
            this.sending = true;
            let school_application_id = this.editedItem.id;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(EMAIL_SCHOOL_APPLICATION, { school_application_id, school_id })
                .then((response) => {
                    this.sending = false;
                    this.snackbarMessage = response.message;
                    this.snackbar = true;
                })
                .catch((response) => {
                    console.log(response);
                    this.sending = false;
                });
        },
        // snackbar
        save() {
            console.log(this);
            this.snack = true

            this.snackText = 'Data saved'
        },
        close() {
            console.log('Dialog closed')
        },
        viewSchoolApplication(item) {
            this.$router.push(`partner-agreements/${item.id}/show`);
        },
    },
    mounted() {
        this.getSchoolApplications();
        this.loading = false;
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDeleteSchoolApplication()
        },
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}

::v-deep .v-icon {
    font-size: 24px !important;
}
</style>
