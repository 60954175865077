<template>
    <v-container>
        <v-row>
            <v-col
                cols="8"
                md="4"
                sm="6"
                class="mx-auto"
            >
                <v-card
                    class="elevation-12"
                    v-if="!thankyou"
                >
                    <v-card-text>
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-col>
                                <v-btn
                                    large
                                    raised
                                    depressed
                                    block
                                    text
                                >Password Reset</v-btn>
                            </v-col>
                        </v-row>

                        <v-form v-model="isFormValid">
                            <v-text-field
                                ref="email"
                                label="Email"
                                name="email"
                                v-model="email"
                                prepend-icon="mdi-account"
                                type="text"
                                :error="!!errors && !!errors.email"
                                :error-messages="errors && errors.email ? errors.email : null"
                                @focus="clearErrors()"
                                :rules="rules.email"
                                :success="!!email.length"
                            />

                            <v-text-field
                                ref="password"
                                id="password"
                                label="Password"
                                name="password"
                                v-model="password"
                                prepend-icon="mdi-lock"
                                type="password"
                                :error="!!errors && !!errors.password"
                                :error-messages="errors && errors.password ? errors.password : null
                                    "
                                @focus="clearErrors()"
                                @keyup.enter="$refs.password_confirmation.focus()"
                                :rules="[
                                    passwordStrength.strength >= 100 ||
                                    'Please enter 8 or more characters. We recommend at least uppercase, lowercase, number, and special character.',
                                ]"
                                :success="passwordStrength.strength >= 100"
                                persistent-hint
                                :hint="passwordStrength.strength >= 100
                                    ? 'Requirements met.'
                                    : 'Please enter 8 or more characters. We recommend at least uppercase, lowercase, number, and special character.'
                                    "
                            />

                            <v-text-field
                                ref="password_confirmation"
                                id="password_confirmation"
                                label="Confirm Password"
                                name="password_confirmation"
                                v-model="password_confirmation"
                                prepend-icon="mdi-lock"
                                type="password"
                                @focus="clearErrors()"
                                @keyup.enter="signUp()"
                                :rules="[doPasswordsMatch || 'Must match first password.']"
                                :success="!!password.length && !!doPasswordsMatch"
                                :error="!doPasswordsMatch"
                            />
                        </v-form>
                        <v-row v-if="error">
                            <v-spacer />
                            <h4 class="red--text">{{ error }}</h4>
                            <v-spacer />
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            :disabled="!isFormValid"
                            :loading="loading"
                            @click.native="resetPassword"
                            color="gold"
                        >RESET</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card
                    class="elevation-12"
                    v-if="thankyou"
                >
                    <v-card-text>
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-col>
                                <v-btn
                                    large
                                    raised
                                    depressed
                                    block
                                    text
                                >Password Reset</v-btn>
                            </v-col>
                        </v-row>
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-col class="text-center">
                                <h5>Your password is reset!</h5>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            to="/"
                            color="gold"
                        >HOME</v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
        </v-col>
    </v-row>
</v-container></template>

<script>
import { PASSWORD_RESET } from "../../store/actions.type";
import { CLEAR_ERRORS } from "../../store/mutations.type";

export default {
    name: "ResetPassword",
    data() {
        return {
            email: "",
            password: "",
            password_confirmation: "",
            loading: false,
            thankyou: false,
            isFormValid: false,
            rules: {
                email: [
                    (v) => !!v || "E-mail is required",
                    (v) =>
                        this.isEmail(v) || "Must be a valid email address",
                ],
            },
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("reset");
        },
        errors() {
            return this.$store.getters.getErrors("reset");
        },
        token() {
            return this.$route.params.token;
        },
        passwordStrength() {
            return this.checkPasswordStrength(this.password);
        },
        doPasswordsMatch() {
            return this.password === this.password_confirmation;
        },
    },
    methods: {
        isEmail(email) {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
        },
        resetPassword: function () {
            this.loading = true;
            this.$store
                .dispatch(PASSWORD_RESET, {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.token
                })
                .then(() => this.thankyou = true)
                .catch(err => console.log(err))
                .finally(() => this.loading = false)
        },
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        }
    },
    mounted() {
        this.email = this.$route.query.email;
    }
};
</script>

<style></style>
