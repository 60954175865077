<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 pb-2"
    >
      <v-col
        class="pa-0"
        cols="auto"
      >
        <household-header page-title="Custom Resources"/>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-simple-table
      ref="promosTable"
      class="elevation-2 mt-3"
    >
      <tr>
        <th role="columnheader">School Year</th>
        <th role="columnheader">For</th>
        <th role="columnheader">Actions</th>
        <th role="columnheader">Date Last Updated</th>
      </tr>
      <tbody>
      <tr v-if="household.application_count === 0">
        <td class="text-center text-apsto-teal py-4" colspan="4">
          Please add an application to see student pages.
        </td>
      </tr>
      </tbody>
      <tbody
        v-for="(promo, promo_index) in promos"
        :key="promo_index"
      >
      <tr
        v-for="(member, member_index) in promo.members"
        :key="member_index"
      >
        <td class="text-center py-4">
          {{ promo.school_year_name }}
        </td>
        <td class="text-center py-4">
          {{ member.name }}
        </td>
        <td class="text-center py-4">
          <template v-if="member.promo">
            <v-btn
              :to="{
                                    name: 'application-member-promo-edit',
                                    params: {
                                        household_id: $route.params.household_id,
                                        application_id: member.application_id,
                                        application_member_id: member.id,
                                        promo_id: member.promo.id
                                    },
                                }"
              color="secondary"
              outlined
              style="min-width: 240px"
            >
              <v-icon
                color="black"
                left
              >mdi-account-school
              </v-icon>
              <span style="color: black">EDIT STUDENT PAGE</span>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              :to="{
                                    name: 'application-member-promo-create',
                                    params: {
                                        household_id: $route.params.household_id,
                                        application_id: member.application_id,
                                        application_member_id: member.id,
                                    },
                                }"
              color="secondary"
              outlined
              style="min-width: 240px"
            >
              <v-icon
                color="black"
                left
              >mdi-account-school
              </v-icon>
              <span style="color: black">CREATE STUDENT PAGE</span>
            </v-btn>
          </template>
        </td>
        <td class="text-center py-4">
          {{ member.promo ? displayDate(member.promo.updated_at) : "-" }}
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import Loading from "../../../../components/shared/Loading";
import {GET_APPLICATION_PROMOS} from "../../../../store/actions.type";

export default {
  name: "PromosList",
  components: {
    HouseholdHeader,
    Loading,
  },
  data() {
    return {
      headers: [
        {text: "School Year", align: "start", value: "school_year_name"},
        {text: "For", value: "type"},
        {text: "Actions", value: "actionsApplication", sortable: false},
        {text: "Date Last Updated", value: "updated"},
      ],
      memberHeaders: [
        {text: "For", align: "start", value: "name"},

        {text: "Actions", value: "actionsMember", sortable: false},
        {text: "Date Last Updated", value: "updated"},
      ],
      loading: true,
      sending: false,
    };
  },
  computed: {
    promos() {
      return this.$store.getters.currentPromos;
    },
    household() {
      return this.$store.getters.currentUserHousehold;
    },
    promoIds() {
      return Object.fromEntries(this.promos.map((promo) => [promo.id, true]));
    },
  },
  methods: {
    getPromos() {
      this.$store
        .dispatch(GET_APPLICATION_PROMOS, this.$route.params.household_id)
        .then(() => {
          this.$refs.promosTable.expansion = this.promoIds;
        });
    },
  },
  mounted() {
    this.getPromos();
    this.loading = false;
  },
};
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep [role="columnheader"] {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 10px 0;
}
</style>
