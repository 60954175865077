<template>
    <v-row class="ma-0" justify="center">
        <v-col
            cols="12"
            class="px-0 pt-4"
            v-if="videoOpen"
        >
            <iframe
                width="800"
                height="448"
                src="https://www.youtube.com/embed/X1vZ8HKgRvE"
                title="The Private School Tuition Tax Credit Program - Arizona"
                border="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </v-col>
        <v-col cols="auto">
            <v-btn
                color="primary"
                class="mb-5"
                @click="videoOpen = !videoOpen"
            >{{ videoOpen ? "CLOSE VIDEO" : "WATCH VIDEO" }}</v-btn>
        </v-col>

    </v-row>
</template>

<script>

export default {
    name: "Video",
    data() {
        return {
            videoOpen: false,
        };
    }
};
</script>

<style scoped></style>
