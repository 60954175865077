import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import {
    API_URL
} from "../common/config";

const ApiService = {
    init() {
        axios.defaults.withCredentials = true;
        axios.defaults.baseURL = API_URL;
        Vue.use(VueAxios, axios);
        this.setHeader();
    },

    setHeader() {
        Vue.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        };
    },

    resetHeader() {
        Vue.axios.defaults.headers.common = {};
    },

    get(resource, params) {
        return Vue.axios.get(`${resource}`, {
            params: params
        });
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    patch(resource, params) {
        return Vue.axios.patch(`${resource}`, params);
    },

    delete(resource, params) {
        return Vue.axios.delete(`${resource}`, {
            params: params
        });
    },
    uploadImage(image, onUploadProgress) {
        let formData = new FormData();
        formData.append("image", image);
        return Vue.axios.post("/api/website/uploads", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    },
    uploadDocument(document, onUploadProgress) {
        let formData = new FormData();
        formData.append("file", document);
        /**
         * To Ron: You may want to change this to a different subfolder (instead of 'website')
         * If you are going to accept images and files on something other than the website you 
         * may want to change uploadImage to a different subfolder as well.
         * 
        */
        return Vue.axios.post("/api/website/uploads", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }
};

export default ApiService;
