<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row v-if="household">
            <v-col>
                <household-header :page-title="pageTitle" />
            </v-col>
        </v-row>
        <v-row class="mx-0 mt-n6">
            <v-col md="8">
                <v-card
                    v-if="isStatusIncomplete"
                    class="mr-5 mt-3"
                >
                    <v-card-text
                        class="font-mulish"
                        style="color: var(--apsto-black);font-size: 1.05rem;"
                    >
                        Please note any items that are
                        <span class="missing-data">highlighted in red</span>
                        then click the Edit Application button at the bottom to make changes.
                        <div class="mt-5">
                            Your application will be automatically submitted for review when all highlighted items are
                            cleared.
                        </div>
                    </v-card-text>
                </v-card>

                <v-card-actions
                    v-if="!isStatusIncomplete"
                    class="pb-4 mt-2"
                >
                    <v-btn
                        :to="{
                            name: 'household-applications-list',
                            params: { household_id: this.$route.params.household_id, tab: 'applications' },
                        }"
                        class="cancel-button ml-n1 mr-2"
                        rounded
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="primary"
                        rounded
                        @click="editHouseholdApplication()"
                    >
                        Edit Application
                    </v-btn>
                </v-card-actions>

                <div class="ml-n4 mt-0">
                    <v-card-text>
                        <family-member-income-table />
                    </v-card-text>

                    <v-card-title>Financial Hardship</v-card-title>
                    <v-card-text>
                        <financial-hardship-section class="mt-4" />
                    </v-card-text>

                    <v-card-title>Student Questionnaire(s)</v-card-title>
                    <v-card-text class="mt-n4">
                        <student-questionnaire
                            :school-year-name="schoolYearName"
                            :students="householdApplicationStudents"
                        />
                    </v-card-text>

                    <documents-approve-decline />

                    <v-card-title>
                        Scholarships
                        <span
                            class="ml-2"
                            style="font-size: 1.015rem"
                        >
                            Click green chevron (<v-icon color="secondary">mdi-chevron-down</v-icon>) to see scholarship
                            details
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <scholarships />
                    </v-card-text>

                    <v-divider />
                    <v-card-title class="">Affidavit</v-card-title>
                    <v-card-text style="font-size: 1.05rem;">
                        <div
                            v-if="householdApplication.agree_to_terms"
                            class="mt-2"
                        >
                            {{ householdApplication.responsible_party_name }} agreed to the terms.
                        </div>
                        <div
                            v-else
                            class="missing-data mt-2"
                        >
                            Terms have not been agreed to.
                        </div>
                    </v-card-text>
                </div>

                <v-card-actions class="pb-4 mt-2">
                    <v-btn
                        :to="{
                            name: 'household-applications-list',
                            params: { household_id: this.$route.params.household_id, tab: 'applications' },
                        }"
                        class="cancel-button ml-n1 mr-2"
                        rounded
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="primary"
                        rounded
                        @click="editHouseholdApplication()"
                    >
                        Edit Application
                    </v-btn>
                </v-card-actions>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import { GET_HOUSEHOLD_APPLICATION, GET_USER_HOUSEHOLD } from "../../../../store/actions.type";
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import Loading from "../../../../components/shared/Loading";
import StatusSelector from "../../../../components/shared/StatusSelector";
import StudentQuestionnaire from "../../../admin/households/household/applications/StudentQuestionnaire";
import SummaryTable from "../../../../components/household/application/SummaryTable";
import FinancialHardshipSection from "../../../admin/households/household/applications/FinancialHardshipSection";
import Scholarships from "../../../../components/household/application/Scholarships";
import DocumentsApproveDecline from "../../../admin/households/household/applications/DocumentsApproveDecline";
import FamilyMemberIncomeTable from "../../../../components/household/application/FamilyMemberIncomeTable.vue";

export default {
    name: "ApplicationReview",
    components: {
        FamilyMemberIncomeTable,
        DocumentsApproveDecline,
        FinancialHardshipSection,
        HouseholdHeader,
        Scholarships,
        StatusSelector,
        StudentQuestionnaire,
        SummaryTable,
        Loading
    },
    data() {
        return {
            loading: true,
            snackbar: false,
            snackbarMessage: "",
        }
    },
    computed: {
        household() {
            return this.$store.getters.currentUserHousehold;
        },
        householdApplication() {
            return this.$store.getters.currentApplication;
        },
        householdApplicationDocuments() {
            return this.$store.getters.currentApplicationDocuments;
        },
        householdApplicationStudents() {
            return this.$store.getters.currentApplicationMembersFiltered(3);
        },
        isStatusIncomplete() {
            let parentsCount = this.householdApplication.members.filter(member => member.type === 2).length;
            let studentsCount = this.householdApplication.members.filter(member => member.type === 3).length;
            let unsubmittedDocumentsCount = this.householdApplicationDocuments.filter(document => !document.document_submitted).length;

            return parentsCount === 0 || studentsCount === 0 || unsubmittedDocumentsCount > 0 ||
                !this.householdApplication.agree_to_terms || !this.householdApplication.agree_to_income_reporting_instructions;

        },
        pageTitle() {
            return "Review Application for School Year " + this.schoolYearName;
        },
        schoolYearName() {
            return this.householdApplication.school_year_name.toString();
        },
    },
    methods: {
        editHouseholdApplication() {
            let household_id = this.$route.params.household_id;
            let application_id = this.$route.params.application_id;
            this.$router.push(`/dashboard/households/${household_id}/applications/${application_id}/edit`);
        },
        getHouseholdApplication() {
            this.loading = true;
            let household_id = this.$route.params.household_id;
            let application_id = this.$route.params.application_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION, { household_id, application_id })
                .then(() => {
                    this.$store.dispatch(GET_USER_HOUSEHOLD, household_id)
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getHouseholdApplication();
    }
}
</script>

<style scoped>
.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    padding: 20px;
}

.box-with-background.apsto-purple {
    background-color: var(--apsto-purple);
}

.qa-block {
    border-bottom: 1px dotted var(--apsto-teal);
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.qa-block:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.missing-data {
    color: #ff0000;
}
</style>
