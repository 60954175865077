<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row
            align="center"
            class="ml-1 mt-4"
        >
            <v-col
                class="pa-0"
                cols="auto"
            >
                <school-header page-title="Create Partner Agreement" />
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6">
                <v-card class="pa-4">
                    <v-card-title class="text-h6-mulish">
                        {{ currentSchoolApplicationSchoolYear && currentSchoolApplicationSchoolYear.message1 }}
                    </v-card-title>
                    <v-card-subtitle
                        v-if="currentSchoolApplicationSchoolYear && currentSchoolApplicationSchoolYear.message2 !== ''"
                        class="mt-2 text-h6-mulish"
                        style="font-size: 1.15rem; line-height: 1.5"
                    >
                        {{ currentSchoolApplicationSchoolYear && currentSchoolApplicationSchoolYear.message2 }}
                    </v-card-subtitle>
                    <v-form :disabled="saving">
                        <v-card-actions class="mt-6">
                            <v-btn
                                :to="{
                                    name: 'school-applications-list',
                                    params: { school_id: this.$route.params.school_id },
                                }"
                                class="cancel-button"
                                rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                v-if="currentSchoolApplicationSchoolYear.proceed === 1"
                                color="primary"
                                rounded
                                @click="submit"
                            >
                                Create Partner Agreement
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SchoolHeader from "../../../../components/school/SchoolHeader";
import Loading from "../../../../components/shared/Loading";
import { GET_SCHOOL_APPLICATION_SCHOOL_YEAR, POST_SCHOOL_APPLICATION, } from "../../../../store/actions.type";

export default {
    name: "SchoolApplicationCreate",
    components: {
        SchoolHeader,
        Loading,
    },
    data() {
        return {
            schoolApplication: {
                school_id: this.$route.params.school_id,
                school_year_id: 0,
            },
            loading: true,
            saving: false,
        };
    },
    computed: {
        currentSchoolApplication() {
            return this.$store.getters.currentSchoolApplication;
        },
        currentSchoolApplicationSchoolYear() {
            return this.$store.getters.currentSchoolApplicationSchoolYear;
        },
        error() {
            return this.$store.getters.getMessage("schoolApplication");
        },
        errors() {
            return this.$store.getters.getErrors("schoolApplication");
        },
    },
    methods: {
        submit() {
            this.saving = true;
            let application = this.schoolApplication;
            application.school_year_id = this.currentSchoolApplicationSchoolYear.id;
            let school_id = this.$route.params.school_id;
            this.$store
                .dispatch(POST_SCHOOL_APPLICATION, application)
                .then(() => {
                    this.saving = false;
                    let application_id = this.currentSchoolApplication.id;
                    this.$router.replace({
                        name: "school-application-edit",
                        params: {
                            application_id: application_id,
                            school_id: school_id,
                        },
                    });
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
    },
    mounted() {
        let school_id = this.$route.params.school_id;
        this.$store.dispatch(GET_SCHOOL_APPLICATION_SCHOOL_YEAR, school_id).then(() => {
            this.loading = false;
        });
    },
};
</script>

<style scoped></style>
