<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row>
            <v-col
                class="mx-4"
                md="6"
            >
                <v-form :disabled="saving">
                    <v-row
                        justify="center"
                        no-gutters
                    >
                        <v-col>
                            <v-text-field
                                id="first_name"
                                v-model.trim="localUser.first_name"
                                :error="!!errors['first_name']"
                                :error-messages="errors['first_name'] ? errors['first_name'] : null"
                                autocomplete="name"
                                background-color="white"
                                filled
                                label="First Name"
                            />
                            <v-text-field
                                id="last_name"
                                v-model.trim="localUser.last_name"
                                :error="!!errors['last_name']"
                                :error-messages="errors['last_name'] ? errors['last_name'] : null"
                                autocomplete="name"
                                background-color="white"
                                filled
                                label="Last Name"
                            />
                            <v-text-field
                                id="phone"
                                v-model.trim="localUser.phone"
                                :error="!!errors.phone"
                                :error-messages="errors.phone ? errors.phone : null"
                                autocomplete="phone"
                                background-color="white"
                                filled
                                label="Cell Phone"
                                @input="phoneNumber"
                            />
                            <v-text-field
                                id="street1"
                                v-model.trim="address.street1"
                                :error="!!errors['address.street1']"
                                :error-messages="errors['address.street1'] ? errors['address.street1'] : null"
                                autocomplete="address-line1"
                                background-color="white"
                                filled
                                label="Street1"
                            />
                            <v-text-field
                                id="street2"
                                v-model.trim="address.street2"
                                :error="!!errors['address.street2']"
                                :error-messages="errors['address.street2'] ? errors['address.street2'] : null"
                                autocomplete="address-line2"
                                background-color="white"
                                filled
                                label="Street2"
                            />
                            <v-text-field
                                id="postal"
                                v-model.trim="address.postal"
                                :error="!!errors['address.postal']"
                                :error-messages="errors['address.postal'] ? errors['address.postal'] : null"
                                autocomplete="zip"
                                background-color="white"
                                filled
                                label="Postal / Zip"
                            />
                            <v-text-field
                                id="city"
                                v-model.trim="address.city"
                                :error="!!errors['address.city']"
                                :error-messages="errors['address.city'] ? errors['address.city'] : null"
                                autocomplete="address-level2"
                                background-color="white"
                                filled
                                label="City"
                            />
                            <region-selector v-model="address.region_id" />
                            <div>
                                <v-btn
                                    :block="isMobile"
                                    :to="{ name: 'user-profile-menu' }"
                                    class="cancel-button"
                                    rounded
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    :block="isMobile"
                                    :class="isMobile ? 'mt-5' : 'ml-2'"
                                    class="save-by-cancel-button"
                                    color="primary"
                                    rounded
                                    @click="submit"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
                <v-snackbar
                    v-model="snackbar"
                    centered
                >
                    {{ snackbarMessage }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="white"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from "../../components/shared/Loading.vue";
import RegionSelector from "../../components/shared/RegionSelector.vue";
import ImageCropper from "../../components/shared/ImageCropper";

import { GET_USER_ADDRESS, PATCH_USER } from "../../store/actions.type";

export default {
    name: "UserProfileName",
    components: {
        Loading,
        RegionSelector,
        ImageCropper,
    },
    data() {
        return {
            address: {
                street1: "",
                street2: "",
                city: "",
                postal: "",
                state: "",
                region_id: "",
            },
            loading: false,
            localUser: null,
            saving: false,
            snackbar: false,
            snackbarMessage: "",
            profileImageDialog: false,
            uploadImgError: false,
            uploadImgMsg: null,
        };
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        currentUserAddress() {
            return this.$store.getters.currentUserAddress;
        },
        error() {
            return this.$store.getters.getMessage("users");
        },
        errors() {
            return this.$store.getters.getErrors("users");
        },
    },
    created() {
        this.localUser = { ...this.currentUser };
    },
    methods: {
        phoneNumber() {
            let x = this.localUser.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.localUser.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        submit() {
            this.saving = true;
            this.localUser.address = this.address;
            this.$store.dispatch(PATCH_USER, this.localUser)
                .then(() => {
                    this.saving = false;
                    this.snackbar = true;
                    this.snackbarMessage = "Changes processed.";
                })
                .catch((response) => {
                    console.log(response);
                    this.saving = false;
                });
        },
        getUserAddress() {
            this.loading = true;
            this.$store.dispatch(GET_USER_ADDRESS).then(() => {
                this.address.street1 = this.currentUserAddress.street1;
                this.address.street2 = this.currentUserAddress.street2;
                this.address.city = this.currentUserAddress.city;
                this.address.postal = this.currentUserAddress.postal;
                this.address.state = this.currentUserAddress.state;
                this.address.region_id = this.currentUserAddress.region_id;
                this.loading = false;
            });
        },
        updateProfileImage(formData) {
            console.log("Updating profile image", formData);
            this.localUser.profile_hash = formData;
            this.submit();
            this.profileImageDialog = false;
        },
        removeProfileImage() {
            this.localUser.profile_hash = null;
            this.submit();
        },
    },
    mounted() {
        this.getUserAddress();
    }
}

</script>

<style scoped></style>
