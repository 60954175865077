<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Create Salesperson"/>
        <div
          class="pt-5"
          elevation="2"
        >
          <v-form :disabled="saving">
            <salesperson-form :salesperson="salesperson"/>
            <v-btn
              :to="{ name: 'admin-salespeople-list' }"
              class="cancel-button"
              rounded
            >
              Cancel
            </v-btn>
            <v-btn
              class="ml-1"
              color="primary"
              rounded
              @click="submit"
            >
              Save
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import SalespersonForm from "../../../components/admin/salespeople/salesperson/SalespersonForm";
import {POST_SALESPERSON} from "../../../store/actions.type";

export default {
  name: "AdminSalespersonCreate",
  components: {
    AdminHeader,
    Loading,
    SalespersonForm
  },
  data() {
    return {
      salesperson: {
        name: "",
        commission_rate: 30
      },
      loading: true,
      saving: false
    };
  },
  methods: {
    submit() {
      this.saving = true;
      let salesperson = this.salesperson;
      this.$store.dispatch(POST_SALESPERSON, salesperson)
        .then(() => {
          this.saving = false;
          this.$router.push({name: 'admin-salespeople-list'})
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.loading = false;
  },
}
</script>

<style scoped></style>
