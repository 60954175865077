import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_SCHOOL,
    GET_APPROVED_SCHOOLS,
    GET_SCHOOL,
    GET_SCHOOL_FOR_RECOMMENDATION,
    GET_SCHOOL_YEAR_NAME,
    GET_SCHOOLS,
    GET_USER_SCHOOL,
    GET_USER_SCHOOLS,
    PATCH_SCHOOL,
    PATCH_SCHOOL_CHECKBOX,
    POST_SCHOOL
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_APPROVED_SCHOOLS,
    SET_ERROR,
    SET_SCHOOL,
    SET_SCHOOL_YEAR_NAME,
    SET_SCHOOLS,
    SET_USER_SCHOOL,
    SET_USER_SCHOOLS
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        affiliations: [
            {id: 1, value: 'Apostolic'},
            {id: 2, value: 'Baptist'},
            {id: 3, value: 'Catholic'},
            {id: 4, value: 'Christian'},
            {id: 5, value: 'Episcopalian'},
            {id: 6, value: 'Islamic'},
            {id: 7, value: 'Jewish'},
            {id: 8, value: 'Mormon'},
            {id: 9, value: 'Lutheran'},
            {id: 10, value: 'Montessori'},
            {id: 11, value: 'Non-denominational'},
            {id: 12, value: 'Protestant'},
            {id: 13, value: 'Other'},
        ],
        approvedSchools: [],
        schools: [],
        school: {},
        school_types: [
            {id: 1, value: 'Combined'},
            {id: 2, value: 'Kindergarten'},
            {id: 3, value: 'Primary'},
            {id: 4, value: 'Secondary'},
        ],
        schoolYearName: '',
        userSchools: [],
        userSchool: {},
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentAffiliations(state) {
        return state.affiliations;
    },
    currentSchool(state) {
        return state.school;
    },
    currentUserSchools(state) {
        return state.userSchools;
    },
    currentUserSchool(state) {
        return state.userSchool;
    },
    schoolTypes(state) {
        return state.school_types;
    },
    currentSchoolYearName(state) {
        return state.schoolYearName;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_APPROVED_SCHOOLS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/website/data/approved-schools")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPROVED_SCHOOLS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOLS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/admin/schools")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOLS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL](context, school_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/admin/schools/${school_id}/show`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_USER_SCHOOLS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/schools")
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_SCHOOLS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_USER_SCHOOL](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_SCHOOL, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schools');
                    reject(error.response);
                });
        });
    },
    [GET_SCHOOL_FOR_RECOMMENDATION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/${id}/for-recommendation`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SCHOOL](context, school) {
        return new Promise((resolve, reject) => {
            ApiService.post("/api/schools/create", school)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_SCHOOL](context, school) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school.id}`, school)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schools');
                    reject(error.response);
                });
        });
    },
    [DELETE_SCHOOL](context, school) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/schools/${school.id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'schools');
                    reject(error.response);
                });
        });
    },
    [PATCH_SCHOOL_CHECKBOX](context, {school_id, field_name}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/schools/${school_id}/checkbox/${field_name}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolApplication",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SCHOOL_YEAR_NAME](context, school_year_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/schools/school-year-name/${school_year_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_YEAR_NAME, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schools",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_APPROVED_SCHOOLS](state, data) {
        state.approvedSchools = data;
    },
    [SET_SCHOOLS](state, data) {
        state.schools = data;
    },
    [SET_SCHOOL](state, data) {
        state.school = data;
    },
    [SET_USER_SCHOOLS](state, data) {
        state.userSchools = data;
    },
    [SET_USER_SCHOOL](state, data) {
        state.userSchool = data;
    },
    [SET_SCHOOL_YEAR_NAME](state, data) {
        state.schoolYearName = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
