<template>
    <v-select
        :id="id"
        v-model="localRegion"
        :background-color="backgroundColor"
        :error="!!errors['address.region_id']"
        :error-messages="errors['address.region_id']
            ? errors['address.region_id']
            : null"
        :items="regions"
        :loading="loading"
        autocomplete="address-level1"
        filled
        item-text="name"
        item-value="id"
        label="State"
    >
    </v-select>
</template>

<script>
import {GET_REGIONS} from "../../store/actions.type";

export default {
    name: "RegionSelector",
    props: {
        backgroundColor: {
            default: "white",
            type: String,
            required: false
        },
        value: {
            default: 3,
            type: Number,
            required: true,
        },
        id: {
            default: "region_id",
            type: String,
            required: false
        }
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("regions");
        },
        errors() {
            return this.$store.getters.getErrors("regions");
        },
        regions() {
            return this.$store.getters.currentRegions;
        },
        localRegion: {
            get() {
                return this.value
            },
            set(localRegion) {
                this.$emit('input', localRegion)
            }
        }
    },
    methods: {
        getRegions() {
            this.loading = true;
            this.$store.dispatch(GET_REGIONS).then(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.getRegions();
        this.$emit('input', this.localRegion);
    },
};
</script>
