<template>
    <div>
        <v-simple-table
            fixed-header
            min-height="200px"
        >
            <template v-slot:default>
                <thead>
                    <tr v-if="isMobile">
                        <th
                            colspan="4"
                            class="scroll-note"
                        >
                            Note: This table can be scrolled horizontally on small screens by swiping it left and right.
                        </th>
                        <th
                            colspan="3"
                            class="scroll-note"
                        >
                            &nbsp;
                        </th>
                    </tr>
                    <tr>
                        <th
                            class="name header-footer"
                            colspan="2"
                        >
                            Household Members
                        </th>
                        <th
                            class="text-center header-footer"
                            colspan="5"
                        >
                            Income Reported on 1040 for Calendar Year {{ priorCalendarYear }}
                            <v-icon
                                class="ml-2"
                                @click="showIncomeInstructions = !showIncomeInstructions"
                                color="white"
                            >
                                mdi-help-circle
                            </v-icon>
                        </th>
                    </tr>
                    <tr v-if="showIncomeInstructions">
                        <th
                            colspan="8"
                            class="earnings-explainer"
                        >
                            <div style="width: 50%;">
                                Earnings From Work: List gross earnings from work (wages, salaries, tips, commissions). This
                                is
                                not the same as take home pay; it is the amount earned before taxes and deductions. Gross
                                earnings should be listed on your pay stub. If self employed, you may report income after
                                expenses (your own business, freelance work, farm, or rental property).
                                <br><br>
                                Welfare, Child Support, Spousal maintenance: Include TANF, General Assistance, General
                                Relief,
                                etc. NOTE: Food Stamps and FDPIR benefits are not included as income.
                                <br><br>
                                Pensions, Retirement, Social Security: Include Supplemental Security Income (SSI), Veteran’s
                                (VA) benefits, and disability
                                benefits.
                                <br><br>
                                All Other Income: Include Worker’s Compensations, unemployment, strike benefits, net rental
                                income, annuities, net royalties, interest, dividend income, cash withdrawn from savings,
                                income
                                from estates, trust and / or investments, regular contributions from people who do not live
                                in
                                your household, and ANY OTHER INCOME.
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-left py-2">
                            Type
                        </th>
                        <th class="name py-2">
                            Names
                        </th>
                        <th class="earnings py-2">
                            Earnings from Work Before Deductions
                        </th>
                        <th class="earnings py-2">
                            Welfare, Child Support, Spousal Maintenance
                        </th>
                        <th class="earnings py-2">
                            Pensions, Retirement, Social Security
                        </th>
                        <th class="earnings py-2">
                            All Other Income
                        </th>
                        <th class="earnings centered py-2">
                            No Income Checked
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in applicationMembers"
                        :key="item.first_name"
                        @click="editItem(item)"
                    >
                        <td>{{ itemTypeName(item) }}</td>
                        <td class="name">{{ item.first_name }} {{ item.last_name }}</td>
                        <td
                            v-if="index === 0"
                            class="earnings"
                        >{{ item.income_earnings | formatCurrency }}</td>
                        <td
                            v-else
                            class="earnings"
                        >{{ item.income_earnings | formatNumber }}</td>
                        <td
                            v-if="index === 0"
                            class="earnings"
                        >{{ item.income_welfare | formatCurrency }}</td>
                        <td
                            v-else
                            class="earnings"
                        >{{ item.income_welfare | formatNumber }}</td>
                        <td
                            v-if="index === 0"
                            class="earnings"
                        >{{ item.income_pension | formatCurrency }}</td>
                        <td
                            v-else
                            class="earnings"
                        >{{ item.income_pension | formatNumber }}</td>
                        <td
                            v-if="index === 0"
                            class="earnings"
                        >{{ item.income_other | formatCurrency }}</td>
                        <td
                            v-else
                            class="earnings"
                        >{{ item.income_other | formatNumber }}</td>
                        <td class="earnings centered">{{ item.income_none ? "Yes" : "" }}</td>
                    </tr>
                    <tr>
                        <td
                            class="name"
                            colspan="2"
                        >Totals</td>
                        <td class="earnings">{{ itemsTotalIncome.earnings | formatCurrency }}</td>
                        <td class="earnings">{{ itemsTotalIncome.welfare | formatCurrency }}</td>
                        <td class="earnings">{{ itemsTotalIncome.pension | formatCurrency }}</td>
                        <td class="earnings">{{ itemsTotalIncome.other | formatCurrency }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th
                            class="name header-footer"
                            colspan="2"
                        >
                            Total People Living in Your Household: {{ applicationMembers.length }}
                        </th>
                        <th
                            class="text-center header-footer"
                            colspan="6"
                        >
                            Total Annual Income: {{ itemsTotalIncome.total | formatCurrency }}
                        </th>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
        <v-dialog
            v-model="showMemberIncomeDialog"
            :max-width="dialogMaxWidth"
            :fullscreen="!!isMobile"
        >
            <family-member-income
                :edited-item="temporaryItem"
                @done="showMemberIncomeDialog = false"
            />
        </v-dialog>
        <div
            v-if="!isMobile && itemsTotalIncome.total"
            class="font-mulish"
            style="background-color: white; padding: 10px 16px;"
        >
            <div class="font-weight-black">Low Income Corporate Tax Credit Scholarship</div>
            <div class="mt-2">
                For a family of
                {{ applicationMembers.length }},
                household income meets the income requirement for the <strong>low income corporate tax credit
                    scholarship</strong> for this
                application school year if your household reports income and that income does not exceed
                <span class="text-apsto-teal">
                    {{ application.maximum_income_threshold_to_qualify | formatCurrency }}
                </span>.
                Your household income is
                <span class="text-apsto-teal">{{ itemsTotalIncome.total | formatCurrency }}</span>
                which
                {{
                    itemsTotalIncome.total > application.maximum_income_threshold_to_qualify ? 'exceeds' : 'does not exceed'
                }}
                the maximum allowed income requirement for this school year.
                Consequently, your family's earnings
                <span class="text-apsto-teal">{{
                    itemsTotalIncome.total > application.maximum_income_threshold_to_qualify ? 'do not meet' : 'meet'
                }}</span>
                the income requirement for the <strong>low income corporate tax credit scholarship</strong> for this
                application school year.
            </div>
        </div>
    </div>
</template>
<script>
import UserProfileName from "../../../views/users/UserProfileName.vue";
import FamilyMember from "./FamilyMember.vue";
import FamilyMemberIncome from "./FamilyMemberIncome.vue";

export default {
    name: "SummaryTable",
    components: {
        FamilyMemberIncome,
        FamilyMember,
        UserProfileName
    },
    data: () => ({
        memberCount: 1,
        showIncomeInstructions: false,
        showMemberIncomeDialog: false,
        temporaryItem: null,
    }),
    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        applicationMembers() {
            return this.$store.getters.currentApplicationMembers;
        },
        itemsTotalIncome() {
            let earnings = 0, other = 0, pension = 0, welfare = 0;
            this.applicationMembers.forEach(function (obj) {
                earnings += obj.income_earnings === null ? 0 : parseFloat(obj.income_earnings);
                other += obj.income_other === null ? 0 : parseFloat(obj.income_other);
                pension += obj.income_pension === null ? 0 : parseFloat(obj.income_pension);
                welfare += obj.income_welfare === null ? 0 : parseFloat(obj.income_welfare);
            });
            return {
                earnings: earnings,
                other: other,
                pension: pension,
                total: earnings + other + pension + welfare,
                welfare: welfare
            }
        },
        priorCalendarYear() {
            return parseInt(this.application.school_year_start_year.substring(0, 4)) - 1;
        },
    },
    methods: {
        editItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.showMemberIncomeDialog = true;
        },
        itemTypeName(item) {
            switch (item.type) {
                case 1:
                    return "Other"
                case 2:
                    return "Parent"
                case 3:
                    return "Student"
            }
        },
    }
}
</script>


<style scoped>.earnings {
    text-align: right !important;
    width: 15%;
}

.earnings.centered {
    text-align: center !important;
}

.earnings-explainer {
    background-color: var(--apsto-teal-very-light) !important;
    padding: 20px !important;
}

.header-footer {
    background-color: var(--apsto-teal) !important;
    color: white !important;
}

.name {
    border-right: thin solid rgba(0, 0, 0, 0.12);
}

.scroll-note {
    background-color: var(--apsto-purple-very-light) !important;
    border-right: none !important;
    color: var(--apsto-black) !important;
    font-family: "Mulish", sans-serif !important;
    font-size: 14px !important;
    padding: 20px !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}

::v-deep tbody tr {
    cursor: pointer;
}

tbody tr td {
    font-size: 0.75rem !important;
}</style>
