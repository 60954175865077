// Views
import corporatePledgesList from "../views/companies/corporate-pledges/CorporatePledgesList";
import CorporatePledgeCreate from "../views/companies/corporate-pledges/CorporatePledgeCreate";
import CorporatePledgeEdit from "../views/companies/corporate-pledges/CorporatePledgeEdit";

export default [
    {
        path: "/dashboard/companies/:company_id/corporate-pledges",
        name: "corporate-pledges-list",
        component: corporatePledgesList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
    {
        path: "/dashboard/companies/:company_id/corporate-pledges/create",
        name: "corporate-pledge-create",
        component: CorporatePledgeCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Corporate Portal'
        },
    },
    {
        path: "/dashboard/companies/:company_id/corporate-pledges/:corporate_pledge_id/edit",
        name: "corporate-pledge-edit",
        component: CorporatePledgeEdit,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Corporate Portal'
        },
    }
]

