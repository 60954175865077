<template>
    <div>
        <v-card>
            <v-card-title class="pt-7">Payments</v-card-title>
            <v-card-subtitle
                v-if="unpaidBalance"
                class="text-apsto-teal"
            >
                Unpaid Balance: {{ unpaidBalance | formatCurrencyWithCents }}
            </v-card-subtitle>
            <v-data-table
                :headers="headers"
                :items="payments"
                class="elevation-2"
                sort-by="name"
            >
                <template v-slot:item.actions="{ item }">
                    <v-tooltip
                        bottom
                        color="secondary"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="mr-2"
                                v-bind="attrs"
                                @click="editItem(item)"
                                v-on="on"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Edit Payment</span>
                    </v-tooltip>
                    <v-icon
                        color="red"
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>

                <template #item.amount="{ item }">
                    <div>
                        {{ item.amount | formatCurrencyWithCents }}
                    </div>
                </template>
                <template #item.created_at="{ item }">
                    <div>
                        {{ displayDate(item.created_at) }}
                    </div>
                </template>
                <template v-slot:no-data>
                    <span class="text-apsto-teal"> No payments have been recorded. </span>
                </template>
            </v-data-table>
            <v-dialog
                v-model="addEditDialog"
                :fullscreen="!!isMobile"
                :max-width="dialogMaxWidth"
            >
                <payment
                    :edited-item="temporaryItem"
                    :unpaid-balance="unpaidBalance"
                    called-from="CompanyPayrollBatch"
                    @done="addEditDialog = false"
                />
            </v-dialog>
            <v-dialog
                v-model="dialogDelete"
                :fullscreen="!!isMobile"
                :max-width="dialogMaxWidth"
            >
                <v-card>
                    <v-card-title class="text-h5-mulish"> Confirm</v-card-title>
                    <v-card-text
                        class="pa-4 pl-6 black--text"
                        style="font-size: 1.1rem"
                    >
                        Are you sure you want to delete this payment?
                    </v-card-text>
                    <v-card-actions class="pt-3 pb-4">
                        <v-spacer />
                        <v-btn
                            class="cancel-button"
                            rounded
                            @click="dialogDelete = false"
                        >Cancel
                        </v-btn>
                        <v-btn
                            class="save-by-cancel-button"
                            color="primary"
                            rounded
                            @click="deleteItemConfirm"
                        >OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <v-btn
            class="cancel-button mt-5"
            rounded
            @click="cancel"
        >Back
        </v-btn>
        <v-btn
            v-if="unpaidBalance"
            class="ml-2 mt-5"
            color="primary"
            rounded
            @click="createItem"
        >
            <v-icon left> mdi-plus-circle-outline</v-icon>
            Record Payment
        </v-btn>
    </div>
</template>

<script>
import Payment from "../../../components/shared/Payment";
import { DELETE_COMPANY_DAILY_DONATION_PAYMENT } from "../../../store/actions.type";

export default {
    name: "AdminCompanyPayrollBatchPaymentsTable",
    components: {
        Payment
    },
    data() {
        return {
            addEditDialog: false,
            dialogDelete: false,
            loading: true,
            temporaryItem: null,
        }
    },
    computed: {
        amountDue() {
            return parseFloat(this.companyPayrollBatch.amount);
        },
        company() {
            return this.$store.getters.currentUserCompany;
        },
        companyPayrollBatch() {
            return this.$store.getters.currentCompanyPayrollBatch;
        },
        defaultItem() {
            return {
                amount: 0,
                check_date: null,
                transaction_id: null,
            };
        },
        headers() {
            return [
                { text: "Date Deposited", value: "check_date" },
                { text: "Transaction ID", value: "transaction_id" },
                { text: "Amount", value: "amount" },
                { text: "Actions", value: "actions", align: 'right', sortable: false },
            ]
        },
        paid() {
            if (this.paymentsSum === 0) {
                return "No";
            }
            if (this.paymentsSum === this.amountDue) {
                return "Yes";
            }
            return "Partially";
        },
        payments() {
            return this.$store.getters.currentPayments;
        },
        paymentsSum() {
            return this.payments.reduce((total, { amount }) => total + parseFloat(amount), 0);
        },
        unpaidBalance() {
            return parseFloat(this.amountDue) - parseFloat(this.paymentsSum);
        }
    },
    methods: {
        cancel() {
            this.$router.go(-1)
        },
        createItem() {
            this.temporaryItem = Object.assign({}, this.defaultItem);
            this.addEditDialog = true;
        },
        editItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.addEditDialog = true;
        },
        deleteItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            let company_payroll_batch_id = this.$route.params.company_payroll_batch_id;
            let company_id = this.$route.params.company_id;
            let payment_id = this.temporaryItem.id;
            this.$store.dispatch(DELETE_COMPANY_DAILY_DONATION_PAYMENT, { company_id, company_payroll_batch_id, payment_id })
                .then(() => {
                    this.dialogDelete = false;
                });
        },
    },

}
</script>

<style scoped>
.first-column {
    width: 50%;
}

.second-column {
    width: 20%;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}
</style>
