<template>
    <loading v-if="loading" />
    <v-row
        v-else
        class="mt-5"
    >
        <v-col v-if="showLoginForm">
            <h2 style="margin-left: 70px;">Please login to accept the invitation</h2>
            <login-form
                :emit-only="true"
                :hide-prior-emails="true"
            />
        </v-col>
        <v-col v-else>
            <div
                v-if="this.currentInvite.section === 1"
                class="mt-10 text-center"
            >
                <div class="text-h4">
                    This invitation has expired.
                </div>
                <div class="my-2 text-h5-mulish">
                    Please ask the sender to invite you again if you'd still like to join.
                </div>
            </div>
            <div
                v-else-if="this.currentInvite.section === 2"
                class="text-center"
            >
                <div class="text-h4">
                    Welcome!
                </div>
                <div class="my-2 text-h5-mulish">
                    You've been added as an administrator for {{ this.currentInvite.name }}.
                </div>
            </div>
            <div v-else-if="this.currentInvite.section === 3">
                <register-from-invitation-form
                    :email="this.currentInvite.email"
                    :entity-name="this.currentInvite.name"
                />
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Loading from "../../components/shared/Loading";
import RegisterFromInvitationForm from "../../components/shared/RegisterFromInvitationForm";
import LoginForm from "../../components/shared/LoginForm";
import { GET_INVITE } from "../../store/actions.type";

export default {
    name: "InviteAccept",
    components: {
        Loading,
        RegisterFromInvitationForm,
        LoginForm,
    },
    data() {
        return {
            loading: true,
            showLoginForm: false,
        }
    },
    computed: {
        currentInvite() {
            return this.$store.getters.currentInvite;
        },
    },
    methods: {
        async getInvite() {
            console.log("getInvite");
            this.$nextTick(() => {
                this.loading = true;
                if (!this.isAuthenticated) {
                    this.showLoginForm = true;
                    this.loading = false;
                    return
                }
                let token = this.$route.params.token;
                this.$store.dispatch(GET_INVITE, token)
                    .then(() => {
                        this.loading = false;
                    });
            });
        },
    },
    mounted() {
        if (!this.isAuthenticated) {
            this.showLoginForm = true;
            this.loading = false;
        } else {
            this.getInvite();
        }
    },
    watch: {
        isAuthenticated() {
            if (this.isAuthenticated) {
                this.showLoginForm = false;
                this.getInvite();
            } else {
                this.showLoginForm = true;
            }
        },
    },
};
</script>

<style scoped>
.text-large-and-bold {
    font-size: 2.0rem;
    font-weight: bold;
}
</style>
