<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col class="px-5 text-h5-mulish"> Edit School</v-col>
        </v-row>
        <v-row class="mt-n3 mt-sm-0">
            <v-col
                class="px-5"
                md="6"
            >
                <v-form :disabled="saving">
                    <school-form :school="school"/>
                    <v-row v-if="isMobile" class="mt-n6">
                        <v-col>
                            <v-btn
                                :to="{ name: 'schools' }"
                                block
                                class="cancel-button" rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="save-by-cancel-button mt-5"
                                color="primary"
                                @click="submit"
                            >
                                Save
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="error"
                                @click="delRecord"
                            >
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-else class="mt-n6">
                        <v-col>
                            <v-btn
                                :to="{ name: 'schools' }"
                                class="cancel-button" rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="save-by-cancel-button ml-2 save-by-cancel-button"
                                color="primary"
                                rounded
                                @click="submit"
                            >
                                Save
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="auto"
                            style="text-align: right;"
                        >
                            <v-btn
                                color="error"
                                rounded
                                @click="delRecord"
                            >
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                    <confirmation-dialog ref="confirm"/>
                </v-form>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            centered
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import SchoolForm from "../../../../components/school/SchoolForm";
import ConfirmationDialog from "../../../../components/shared/ConfirmationDialog";

import {DELETE_SCHOOL, GET_USER_SCHOOL, PATCH_SCHOOL,} from "../../../../store/actions.type";

export default {
    name: "SchoolEdit",
    components: {
        Loading,
        SchoolForm,
        ConfirmationDialog,
    },
    data() {
        return {
            loading: true,
            saving: false,
            snackbar: false,
            snackbarMessage: "",
            possibleFields: [
                "affiliation_id",
                "city",
                "cityMailing",
                "contact_phone",
                "fax",
                "name",
                "phone",
                "postal",
                "postalMailing",
                "region_id",
                "region_idMailing",
                "street1",
                "street1Mailing",
                "street2",
                "street2Mailing",
                "title",
                "website",

            ],
        };
    },
    computed: {
        school() {
            return this.$store.getters.currentUserSchool;
        },
        error() {
            return this.$store.getters.getMessage("schools");
        },
        errors() {
            return this.$store.getters.getErrors("schools");
        },
    },
    methods: {
        async delRecord() {
            if (
                await this.$refs.confirm.open(
                    "Confirm",
                    "Are you sure you want to delete this school?"
                )
            ) {
                this.destroy();
            }
        },
        destroy() {
            this.saving = true;
            this.$store.dispatch(DELETE_SCHOOL, this.school)
                .then(() => {
                    this.saving = false;
                    this.$router.push({
                        name: "schools",
                    });
                })
                .catch(({data}) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        getSchool() {
            this.loading = true;
            this.$store
                .dispatch(GET_USER_SCHOOL, this.$route.params.school_id)
                .then(() => {
                    this.loading = false;
                });
        },
        submit() {
            this.saving = true;
            this.$store
                .dispatch(PATCH_SCHOOL, this.school)
                .then(() => {
                    this.saving = false;
                    this.$router.push({name: "schools"});
                })
                .catch((response) => {
                    console.log(response);
                    console.log("errors: ", this.errors);
                    this.possibleFields.every((field) => {
                        if (this.errors[field]) {
                            field = field.split('.').pop();
                            const el = document.getElementById(field);
                            if (el) {
                                el.scrollIntoView({block: "center"});
                                return false;
                            }
                        }
                        return true;
                    });
                    this.saving = false;
                });
        },
    },
    mounted() {
        this.getSchool();
    },
};
</script>

<style scoped></style>
