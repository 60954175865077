<template>
  <div>
    <v-row>
      <v-col class="mt-2">
        <v-text-field
          id="transaction_id"
          v-model.trim="paymentInfo.transaction_id"
          :error="!!errors['paymentInfo.transaction_id']"
          :error-messages="errors['paymentInfo.transaction_id'] ? errors['paymentInfo.transaction_id'] : null"
          filled
          label="Check Number"
        />
        <v-text-field
          id="amount"
          v-model.trim="paymentInfo.amount"
          :error="!!errors['paymentInfo.amount']"
          :error-messages="errors['paymentInfo.amount'] ? errors['paymentInfo.amount'] : null"
          filled
          label="Check Amount"
        />
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          max-width="290px"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="checkDateFormatted"
              filled
              label="Date Deposited"
              @blur="paymentInfo.check_date = parseDateOnly(checkDateFormatted)"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="paymentInfo.check_date"
            :max="maxDate"
            :min="minDate"
            no-title
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
          v-if="disableSubmitCheck && !saving"
          dismissible
          style="font-size: 1.125rem;"
          type="info"
        >
          Some items haven't been completed. Please answer them to continue.
          <ul class="mt-4">
            <li v-if="!donation.tax_filing_status">Tax Filing Status</li>
            <li v-if="!paymentInfo.transaction_id">Check Number</li>
            <li v-if="!paymentInfo.amount">Donation Amount</li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      class="py-4"
      style="border-top: 1px dotted #808080;"
    >
      <v-btn
        class="cancel-button"
        rounded
        @click="cancel"
      > Cancel
      </v-btn>
      <v-btn
        :disabled="disableSubmitCheck"
        class="ml-2 save-by-cancel-button"
        color="primary"
        rounded
        @click="confirmPaymentInfo"
      > Submit
      </v-btn>
    </v-row>
  </div>
</template>

<script>

import {POST_DONOR_DONATION_PAYMENT_BY_CHECK} from "../../store/actions.type";

export default {
  name: "DonationPaymentByCheck",
  data: () => ({
    checkDateFormatted: null,
    maxDate: "2099-12-31",
    minDate: "2022-01-01",
    paymentInfo: {
      amount: null,
      check_date: null,
      transaction_id: null
    },
    saving: false,
    snackbar: false,
    snackbarMessage: "",

    tab: null,
    yearNow: null,
    years: []
  }),
  props: {
    donation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disableSubmitCheck() {
      return !this.paymentInfo.amount ||
        !this.paymentInfo.transaction_id ||
        !this.donation.tax_filing_status ||
        this.saving;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    cancel() {
      this.$router.push("/dashboard/admin/users");
    },
    confirmPaymentInfo() {
      let check_date = this.paymentInfo.check_date;
      let amount = this.paymentInfo.amount;
      let transaction_id = this.paymentInfo.transaction_id;
      let confirmMessage = `Save this donation using the amount of $${amount}, check #${transaction_id}, and check date of ${check_date}? Click Cancel to make any changes first or OK to proceed.`;

      console.log(confirmMessage);

      if (confirm(confirmMessage)) {
        this.submitCheck();
      }
    },
    submitCheck() {
      this.saving = true;
      let donation = this.donation;
      donation.amount = this.paymentInfo.amount;
      donation.user_id = this.$route.params.user_id;
      donation.paymentInfo = this.paymentInfo;

      this.$store.dispatch(POST_DONOR_DONATION_PAYMENT_BY_CHECK, donation)
        .then(() => {
          this.saving = false;
          this.$router.push({name: "admin-users-list"});
        })
        .catch((response) => {
          this.saving = false;
          console.log('error response: ', response);
          if (response['status'] !== 422) {
            this.snackbarMessage = response['data'];

            this.snackbar = true;
          }
        });
    },
  },
  mounted() {
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    this.paymentInfo.check_date = `${year}-${month}-${day}`;

  },
  watch: {
    paymentInfo: {
      handler() {
        this.checkDateFormatted = this.formatDateOnly(this.paymentInfo.check_date);
      },
      deep: true
    }
  },
};
</script>

