<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center">
      <v-col>
        <company-header :page-title="pageTitle"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card class="mb-5">
          <v-row align="center">
            <v-col cols="auto">
              <v-card-title>Payroll Withholding</v-card-title>
              <v-card-subtitle class="text-apsto-teal">Withheld: {{ withheld }}</v-card-subtitle>
            </v-col>
          </v-row>
          <v-row class="mt-n5 px-4">
            <v-col>
              <v-btn
                :block="isMobile"
                class="mr-5"
                color="primary"
                rounded
                @click="delRecord"
              >
                <v-icon>mdi-delete</v-icon>
                Delete This Upload
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                :block="isMobile"
                :href="'/api/companies/' + company.id + '/employee-donations/download/batch/' + companyPayrollBatch.id"
                color="primary"
                rounded
              >
                <v-icon>mdi-arrow-down</v-icon>
                Download This List
              </v-btn>
            </v-col>
          </v-row>
          <v-simple-table
            class="mt-3"
            fixed-header
            min-height="200px"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left pr-15 first-column">Name</th>
                <th class="text-right second-column">Amount</th>
                <th class="text-left pl-15">Paid</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(donorDonation, index) in donorDonationsInBatch"
                :key="index"
              >
                <td class="text-left pr-15 first-column">{{ donorDonation.name }}</td>
                <td
                  v-if="index === 0"
                  class="text-right second-column"
                >{{
                  donorDonation.amount | formatCurrencyWithCents
                  }}
                </td>
                <td
                  v-else
                  class="text-right second-column"
                >{{ donorDonation.amount | formatNumberWithCents }}
                </td>
                <td class="text-left pl-15">{{ paid }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-left pr-15 first-column">Total</td>
                <td class="text-right second-column financial-total">
                  {{ companyPayrollBatch.amount | formatCurrencyWithCents }}
                </td>
                <td class="text-left pr-15">&nbsp;</td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <confirmation-dialog ref="confirm"/>
          <v-snackbar
            v-model="snackbar"
            centered
          >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
        <v-card
          v-if="payments.length"
          class="mt-5"
        >
          <v-card-title>Payments</v-card-title>
          <v-simple-table
            class="payments-table"
            fixed-header
            min-height="200px"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left pr-15 first-column">Date Deposited</th>
                <th class="text-right second-column">Amount</th>
                <th class="text-left pl-15">Transaction ID</th>
                <th class="text-left pl-15">Donation Period</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(payment, index) in payments"
                :key="index"
              >
                <td class="text-left pr-15 first-column">{{ displayDate(payment.created_at) }}</td>
                <td
                  v-if="index === 0"
                  class="text-right second-column"
                >
                  {{ payment.amount | formatCurrencyWithCents }}
                </td>
                <td
                  v-else
                  class="text-right second-column"
                >
                  {{ payment.amount | formatNumberWithCents }}
                </td>
                <td class="text-left pl-15">{{ payment.transaction_id }}</td>
                <td class="text-left pl-15">
                  {{ payment.calendar_year + " / " + payment.month.toString().padStart(2, "0") }}
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-left pr-15 first-column">Total</td>
                <td class="text-right second-column financial-total">
                  {{ paymentsSum | formatCurrencyWithCents }}
                </td>
                <td class="text-left pr-15">&nbsp;</td>
                <td class="text-left pr-15">&nbsp;</td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card>
        <v-btn
          :block="isMobile"
          :class="payments.length ? 'cancel-button mt-5' : 'cancel-button mt-2'"
          rounded
          @click="cancel"
        >
          Back
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import CompanyHeader from "../../../../components/company/CompanyHeader";
import {DELETE_COMPANY_DAILY_DONATION, GET_EMPLOYEE_DONATION} from "../../../../store/actions.type";
import ConfirmationDialog from "../../../../components/shared/ConfirmationDialog.vue";

export default {
  name: "CompanyPayrollBatchShow",
  components: {
    ConfirmationDialog,
    CompanyHeader,
    Loading
  },
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarMessage: "",

    }
  },
  computed: {
    batchName() {
      return ", Batch #" + this.$route.params.company_payroll_batch_id;
    },
    company() {
      return this.$store.getters.currentUserCompany;
    },
    companyPayrollBatch() {
      return this.$store.getters.currentCompanyPayrollBatch;
    },
    dailyDonationDate() {
      return this.companyPayrollBatch.created_at;
    },
    donorDonationsInBatch() {
      return this.$store.getters.currentDonorDonationsInBatch;
    },
    pageTitle() {
      return 'Payroll Withholding Uploaded On ' + this.displayDate(this.dailyDonationDate) + this.batchName
    },
    payments() {
      return this.$store.getters.currentPayments;
    },
    paymentsSum() {
      return parseFloat(this.payments.reduce((total, obj) => obj.amount + total, 0));
    },
    paid() {

      if (this.paymentsSum === 0) {
        return "No";
      }

      let paymentObligation = parseFloat(this.companyPayrollBatch.amount);
      if (this.paymentsSum === paymentObligation) {
        return "Yes";
      }

      return "Partially";

    },
    withheld() {
      return this.companyPayrollBatch.calendar_year + " / " + this.companyPayrollBatch.month.toString().padStart(2, "0");
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1)
    },
    async delRecord() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this list?"
        )
      ) {
        this.deleteDailyDonation();
      }
    },
    getDailyDonation() {
      let company_payroll_batch_id = this.$route.params.company_payroll_batch_id;
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_EMPLOYEE_DONATION, {company_id, company_payroll_batch_id})
        .then((data) => {
          this.paid = data.paid;
        })
      this.loading = false;
    },
    deleteDailyDonation() {
      let company_payroll_batch_id = this.$route.params.company_payroll_batch_id;
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(DELETE_COMPANY_DAILY_DONATION, {company_id, company_payroll_batch_id})
        .then(() => {
          this.$router.push("/dashboard/companies/" + company_id + "/employee-donations")
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;
          this.snackbar = true;
        });
    },
  },
  mounted() {
    this.getDailyDonation();
  }
}
</script>

<style scoped>
.first-column {
  width: 50%;
}

.second-column {
  width: 20%;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}
</style>
