<template>
    <v-select
        id="day"
        v-model="localDay"
        :error="!!errors['day']"
        :error-messages="errors['day'] ? errors['day'] : null"
        :items="days"
        :label="this.label"
        filled
        item-text="name"
        item-value="id"
    >
    </v-select>
</template>

<script>
export default {
    name: "DaySelector",
    props: {
        label: {
            default: "Day",
            type: String
        },
        value: {
            default: 1,
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            days: [
                { name: '1st', id: 1 },
                { name: '2nd', id: 2 },
                { name: '3rd', id: 3 },
                { name: '4th', id: 4 },
                { name: '5th', id: 5 },
                { name: '6th', id: 6 },
                { name: '7th', id: 7 },
                { name: '8th', id: 8 },
                { name: '9th', id: 9 },
                { name: '10th', id: 10 },
                { name: '11th', id: 11 },
                { name: '12th', id: 12 },
                { name: '13th', id: 13 },
                { name: '14th', id: 14 },
                { name: '15th', id: 15 },
                { name: '16th', id: 16 },
                { name: '17th', id: 17 },
                { name: '18th', id: 18 },
                { name: '19th', id: 19 },
                { name: '20th', id: 20 },
                { name: '21st', id: 21 },
                { name: '22nd', id: 22 },
                { name: '23rd', id: 23 },
                { name: '24th', id: 24 },
                { name: '25th', id: 25 },
                { name: '26th', id: 26 },
                { name: '27th', id: 27 },
                { name: '28th', id: 28 },
                { name: '29th', id: 29 },
                { name: '30th', id: 30 },
                { name: '31st', id: 31 },
            ]
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
        localDay: {
            get() {
                return this.value
            },
            set(localDay) {
                this.$emit('input', localDay)
            }
        }
    },
    mounted() {
        this.$emit('input', this.localDay);
    },
};
</script>
