<template>
    <v-select
        id="grade"
        v-model="localSchoolGrade"
        :error="!!errors['student.grade_id']"
        :error-messages="errors['student.grade_id'] ? errors['student.grade_id'] : null"
        :items="schoolGrades"
        :label="this.label"
        :loading="loading"
        background-color="white"
        filled
        item-text="name"
        item-value="id"
    >
    </v-select>
</template>

<script>
import { GET_SCHOOL_GRADES } from "../../store/actions.type";

export default {
    name: "SchoolGradeSelector",
    props: {
        label: {
            default: "School Grade",
            type: String
        },
        value: {
            default: 1,
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
        schoolGrades() {
            return this.$store.getters.currentSchoolGrades;
        },
        localSchoolGrade: {
            get() {
                return this.value
            },
            set(localSchoolGrade) {
                this.$emit('input', localSchoolGrade)
            }
        }
    },
    methods: {
        getSchoolGrades() {
            this.loading = true;
            this.$store.dispatch(GET_SCHOOL_GRADES).then(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.getSchoolGrades();
        this.$emit('input', this.localSchoolGrade);
    },
};
</script>
