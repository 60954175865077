<template>
    <loading v-if="loading"/>
    <div
        v-else
        class="mr-5"
    >
        <v-row
            align="center"
            class="ml-1 mt-5"
        >
            <v-col cols="auto">
                <admin-header page-title="Revise Donor Donation"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="adminDonorDonations"
                    :search="search"
                    :single-select="true"
                    class="elevation-2 mt-3"
                    disabled="disabled"
                    item-key="id"
                    @click:row="editItem"
                >
                    <template v-slot:top>
                        <v-row>
                            <v-col md="6">
                                <v-text-field
                                    v-model="search"
                                    background-color="white"
                                    class="mx-4"
                                    label="Search on anything"
                                    outlined
                                    prepend-inner-icon="mdi-magnify"
                                />
                            </v-col>
                        </v-row>
                    </template>
                    <template #item.amount="{ item, index }">
                        <div v-if="!index">{{ item.amount | formatCurrencyWithCents }}</div>
                        <div v-else>{{ item.amount | formatNumberWithCents }}</div>
                    </template>
                    <template #item.type="{ item }">
                        <div v-if="item.type === 1">
                            Individual Donation
                        </div>
                        <div v-else-if="item.type === 2">
                            Payroll Donation
                        </div>
                        <div v-else-if="item.type === 3">
                            Monthly Donation
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader.vue";
import AdminDeposit from "../../../components/admin/deposits/AdminDeposit.vue";
import Loading from "../../../components/shared/Loading.vue";
import {GET_ADMIN_DONOR_DONATIONS_RECENT} from "../../../store/actions.type";

export default {
    name: "AdminDonorDonationsRevise",
    components: {Loading, AdminDeposit, AdminHeader},
    data() {
        return {
            headers: [
                {text: 'Name', value: 'donor_name'},
                {text: 'Email', value: 'donor_email'},
                {text: 'Date', align: 'start', value: 'created_at'},
                {text: 'Type', align: 'start', value: 'type'},
                {text: 'Amount', align: 'end', value: 'amount'},
            ],
            loading: true,
            search: "",
        }
    },
    computed: {
        adminDonorDonations() {
            return this.$store.getters.currentAdminDonorDonations['donorDonations'];
        },
        currentYearStartDate() {
            return new Date(this.$store.getters.currentAdminDonorDonations['currentYearStartDate'].substring(0, 10));
        },
        priorYearIsClosed() {
            return this.$store.getters.currentAdminDonorDonations['priorYearIsClosed'];
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    methods: {
        disabled(item) {
            let createdAt = new Date(item.created_at);
            return this.priorYearIsClosed && createdAt < this.currentYearStartDate;
        },
        editItem(item) {
            if (this.disabled(item)) {
                return;
            }
            this.$router.push({name: 'admin-donor-donation-revise', params: {id: item.id}});
        },
        getDonorDonations() {
            this.loading = true;
            this.$store.dispatch(GET_ADMIN_DONOR_DONATIONS_RECENT).then(() => {
                this.loading = false;
            })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getDonorDonations();
    }
}
</script>


<style scoped>

</style>
