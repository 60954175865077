<template>
    <div>
        <div class="first-heading">Not sure which email(s) you've used with us before?</div>
        <div class="mt-3">
            Enter up to 4 email addresses to see if any of them are in our system. If they are, we'll send you an email
            with a link to log in.
        </div>
        <v-form
            ref="checkDataByEmailForm"
            v-model="isCheckDataByEmailFormValid"
            class="my-3 section"
        >
            <v-text-field
                ref="email1"
                v-model="email1"
                :error="!!errors && errors.email1"
                :error-messages="errors && errors.email1 ? errors.email1 : null"
                :rules="rules.email"
                label="Email"
                name="email"
                type="text"
                @focus="clearErrorsAndMessages()"
            />
            <v-text-field
                ref="email"
                v-model="email2"
                :error="!!errors && errors.email2"
                :error-messages="errors && errors.email2 ? errors.email2 : null"
                :rules="rules.email"
                label="Email"
                name="email"
                type="text"
                @focus="clearErrorsAndMessages()"
            />
            <v-text-field
                ref="email"
                v-model="email3"
                :error="!!errors && errors.email3"
                :error-messages="errors && errors.email3 ? errors.email3 : null"
                :rules="rules.email"
                label="Email"
                name="email"
                type="text"
                @focus="clearErrorsAndMessages()"
            />
            <v-text-field
                ref="email"
                v-model="email4"
                :error="!!errors && errors.email4"
                :error-messages="errors && errors.email4 ? errors.email4 : null"
                :rules="rules.email"
                label="Email"
                name="email"
                type="text"
                @focus="clearErrorsAndMessages()"
            />

        </v-form>
        <div
            v-if="displayEmailMessage"
            class="success-message mt-5"
        >
            <div v-if="successMessage['sent'].length">

                <div :class="successMessage['sent'].length > 1 ? 'font-weight-black' : ''">
                    {{ successMessageEmails }}
                </div>

                <div class="mt-3">
                    <div
                        v-for="email in successMessage['sent']"
                        v-if="successMessage['sent'].length > 1"
                    >
                        {{ email }}
                    </div>
                </div>

            </div>
            <div
                v-if="successMessage['notFound'].length"
                class="mt-5"
            >
                <div :class="successMessage['notFound'].length > 1 ? 'font-weight-black' : ''">
                    {{ successMessageNotFound }}
                </div>
                <div class="mt-3">
                    <div
                        v-for="email in successMessage['notFound']"
                        v-if="successMessage['notFound'].length > 1"
                    >
                        {{ email }}
                    </div>
                </div>
            </div>

        </div>
        <div
            v-if="errorMessage"
            class="error-message mt-4"
        >
            {{ errorMessage }}
        </div>
        <v-btn
            block
            class="mt-"
            color="primary"
            rounded
            @click="checkEmailsForData"
        >
            CHECK EMAILS
        </v-btn>
    </div>
</template>

<script>
import {CHECK_EMAILS_FOR_DATA} from "../../store/actions.type";
import {CLEAR_ERRORS} from "../../store/mutations.type";

export default {
    name: "CheckDataByEmailForm",

    data() {
        return {
            email1: "",
            email2: "",
            email3: "",
            email4: "",
            recaptchaToken: null,
            errorMessage: null,
            password: "",
            remember: false,
            sending: false,
            forgotEmailFlag: false,
            isFormValid: false,
            isCheckDataByEmailFormValid: false,
            rules: {
                email: [
                    (v) => !!v || "Ex: someone@example.com",
                    (v) =>
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            v
                        ) || "Ex: someone@example.com",
                ],
            },
            displayEmailMessage: false,
            displayEmailErrorMessage: false,
            successMessage: null,
            tab: null,
        };
    },
    computed: {
        successMessageEmails() {
            return this.successMessage.sent.length === 1
                ? "We've sent an email to " + this.successMessage.sent[0] + ". Please check that account's inbox or spam folder for an email from us."
                : "We've sent emails to these addresses. Please check those inboxes or spam folders for emails from us.";
        },
        successMessageNotFound() {
            return this.successMessage.notFound.length === 1
                ? this.successMessage.notFound[0] + " is not in our system so we did not email it."
                : "The following addresses are not in our system so we did not email them:";
        },
        error() {
            return this.$store.getters.getMessage("login");
        },
        errors() {
            return this.$store.getters.getErrors("login");
        },
    },
    methods: {
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        },
        clearErrorsAndMessages() {
            this.displayEmailMessage = null;
            this.displayEmailErrorMessage = null;
            this.errorMessage = null;
            this.$store.commit(CLEAR_ERRORS);
        },
        async checkEmailsForData() {
            this.clearErrorsAndMessages();

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');

            if (!token) {
                this.errorMessage = 'Sorry, recaptcha failed. Try again.';
                return;
            }
            let email1 = this.email1;
            let email2 = this.email2;
            let email3 = this.email3;
            let email4 = this.email4;
            let emails = {
                email1,
                email2,
                email3,
                email4,
                'g-recaptcha-response': token
            };
            this.sending = true;

            this.$store.dispatch(CHECK_EMAILS_FOR_DATA, emails)
                .then((response) => {
                    this.successMessage = response.message;
                    this.displayEmailMessage = true;
                    this.sending = false;
                })
                .catch((response) => {
                    this.errorMessage = response.message;
                    this.displayEmailErrorMessage = true;
                    this.sending = false;
                });

        }
    },
};
</script>

<style scoped>
.first-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 1.2rem;
    font-weight: bold;
}

.section {
    background-color: var(--apsto-teal-very-light);
    border: 1px solid var(--apsto-teal);
    padding: 20px;
}

.error-message,
.success-message {
    background-color: var(--apsto-purple-very-light);
    border: 1px solid var(--apsto-purple);
    padding: 20px;
}
</style>
