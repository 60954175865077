<template>
    <v-card class="pa-4">
        <v-card-title class="pa-0">Upload Exceptions</v-card-title>
        <div class="mt-3">
            We found the following exceptions. Please fix them in your file then try your upload again.
            <v-data-table
                :headers="headers"
                :items="failures"
                class="mt-5"
            />
        </div>
    </v-card>
</template>
<script>
import { CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE } from '../../../store/mutations.type';

export default {
    name: "UploadFailed",
    props: {
        failures: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            headers: [
                { text: 'ROW', value: 'row' },
                { text: 'PROBLEM', value: 'message' },
            ],
        }
    },
    destroyed() {
        this.$store.commit(CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE);
    },
}
</script>

<style scoped></style>
