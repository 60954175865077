import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_INVITE,
    GET_INVITE,
    PATCH_INVITE,
    POST_AFTER_REGISTRATION_FROM_INVITE,
    POST_INVITE
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_ERROR, SET_INVITE} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        invite: {},
        invites: []
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentInvite(state) {
        return state.invite;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_INVITE](context, token) {
        console.log("GET_INVITE");
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/invites/accept/${token}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_INVITE, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "invites",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_INVITE](context, {invitable_id, invitable_type, invite}) {
        console.log("POST_INVITE");
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/invites/${invitable_id}/${invitable_type}`, invite)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "invites", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_INVITE](context, invite_id) {
        console.log("DELETE_INVITE");
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/invites/${invite_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "invites", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_INVITE](context, invite_id) {
        console.log("PATCH_INVITE");
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/invites/${invite_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "invites", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_AFTER_REGISTRATION_FROM_INVITE](context, {token, user_id}) {
        console.log("POST_AFTER_REGISTRATION_FROM_INVITE");
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/invites/after-registration-from-invite/${token}/${user_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "invites", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_INVITE](state, data) {
        state.invite = data;
    },
};

export default {
    getters, actions, mutations, state,
};
