<template>
    <loading v-if="loading" />
    <div v-else>
        <v-select
            v-model="deposit.deposit_type_enum"
            :error="!!errors.deposit_type_enum"
            :error-messages="errors.deposit_type_enum ? errors.deposit_type_enum : null"
            :items="depositTypes"
            background-color="white"
            filled
            item-text="value"
            item-value="id"
            label="Deposit Type"
        />
        <v-text-field
            v-model.trim="deposit.date_deposited"
            :error="!!errors.date_deposited"
            :error-messages="errors.date_deposited ? errors.date_deposited : null"
            background-color="white"
            filled
            label="Date Deposited"
            type="date"
        />
        <v-text-field
            v-model.trim="deposit.amount"
            :error="!!errors.amount"
            :error-messages="errors.amount ? errors.amount : null"
            background-color="white"
            filled
            label="Deposit Amount"
        />

        <div v-if="deposit.deposit_type_enum === 1">
            <school-selector
                v-model="deposit.school_id"
                :error="!!errors.school_id"
                :error-messages="errors.school_id ? errors.school_id : null"
                label="Select School"
            />
            <admin-deposit-form-award-refunds
                v-if="editing"
                :key="deposit.id"
            />
        </div>

        <v-select
            v-if="deposit.deposit_type_enum === 2"
            v-model="deposit.deposit_source_enum"
            :error="!!errors.deposit_source_enum"
            :error-messages="errors.deposit_source_enum ? errors.deposit_source_enum : null"
            :items="depositSources"
            background-color="white"
            filled
            item-text="value"
            item-value="id"
            label="Matching Gifts Source"
        />

    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import AdminDepositFormAwardRefunds from "./AdminDepositFormAwardRefunds";
import SchoolSelector from "../../shared/SchoolSelector";
import { SET_ADMIN_DEPOSIT } from "../../../store/mutations.type";

export default {
    name: "AdminDepositForm",
    components: {
        SchoolSelector,
        AdminDepositFormAwardRefunds,
        Loading,
    },

    data() {
        return {
            loading: false,
        }
    },
    computed: {
        deposit: {
            get() {
                return this.$store.getters.currentAdminDeposit;
            },
            set(newValue) {
                this.$store.commit(SET_ADMIN_DEPOSIT, newValue);
            },
        },
        mode() {
            return this.deposit.id ? 'edit' : 'create';
        },
        editing() {
            return this.mode == 'edit';
        },
        creating() {
            return this.mode == 'create';
        },
        depositSources() {
            return this.$store.getters.currentReceiptSources;
        },
        depositTypes() {
            return this.$store.getters.currentReceiptTypes;
        },
        error() {
            return this.$store.getters.getMessage("adminSchools");
        },
        errors() {
            return this.$store.getters.getErrors("adminSchools");
        },
    },
}
</script>

<style scoped></style>
