import UserProfileEmail from "../views/users/UserProfileEmail";
import UserProfileMenu from "../views/users/UserProfileMenu";
import UserProfileName from "../views/users/UserProfileName";
import ChangeUsername from "../views/users/ChangeUsername";
import UserProfilePicture from "../views/users/UserProfilePicture.vue";

export default [
    {
        path: '/user-profile-email',
        name: 'user-profile-email',
        component: UserProfileEmail,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Settings - Change Login Email'
        }
    },
    {
        path: '/user-profile-menu',
        name: 'user-profile-menu',
        component: UserProfileMenu,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Settings Options'
        }
    },
    {
        path: '/user-profile-name',
        name: 'user-profile-name',
        component: UserProfileName,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Settings - Name And Address'
        }
    },
    {
        path: '/user-profile-picture',
        name: 'user-profile-picture',
        component: UserProfilePicture,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Settings - Upload Picture'
        }
    },
    {
        path: '/change-username',
        name: 'change-username',
        component: ChangeUsername,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
            hasDashboardMenu: false,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Settings - Change Login Email'
        }
    },
]
