<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row
            align="center"
            class="px-4 px-md-0"
        >
            <v-col md="8">
                <household-header :page-title="pageTitle"/>
                <div class="box-with-background ml-4">
                    Hi {{ currentUser.first_name }},
                    <div class="mt-4">
                        This application saves your entries as you make them:
                        <ul>
                            <li class="mt-2" style="font-size: 16px; margin-bottom: 0;">You can exit and re-enter it
                                without losing any
                                data.
                            </li>
                            <li class="mt-0" style="font-size: 16px;">You can upload student documents as they become
                                available to
                                you.
                            </li>
                        </ul>
                    </div>
                    <div class="mt-2">More ...</div>
                    <div class="mt-4">
                        Click section headings to jump ahead or back. Each section includes a NEXT button but
                        you don't need to use it.
                    </div>
                    <div class="mt-4">

                        <span style="text-decoration: underline;">
                        We don't look at your application until it's complete.
                        </span>
                        The last section will let you know if anything's missing. When you're done,
                        check the box in the last section at the bottom. That will submit your application to us for
                        review.
                    </div>
                    <div class="mt-4">
                        If you get stuck, click the HELP button at the bottom of the page to message us. We'll get back
                        to you within 24 hours.
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="currentApplication.status_id === 2">
            <v-col md="8">
                <v-card
                    class="pa-5 mx-md-4 my-4 "
                    color="red"
                    elevation="2"
                >
                    <div style="color: white;">
                        This {{ applicationType }} is currently under review by an admin. Please check back later.
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <div class="mx-md-4 mt-5">
            <v-stepper
                v-model="e6"
                class="mt-1"
                non-linear
                style="background-color: var(--apsto-purple-very-light);"
                vertical
            >
                <v-stepper-step
                    id="step-1"
                    :complete="e6 > 1"
                    editable
                    step="1"
                >
                    PARENT(S) / GUARDIAN(S)
                </v-stepper-step>
                <v-stepper-content step="1">
                    <v-row>
                        <v-col md="8">
                            <v-card
                                class="pa-0"
                                color="primaryVeryLight"
                                elevation="0"
                            >
                                <family-member-table
                                    :application="currentApplication"
                                    :disable-sort="isMobile"
                                    :hide-default-footer="isMobile"
                                    :household="household"
                                    :sorting-enabled="false"
                                    :type="2"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-btn
                        :block="isMobile"
                        :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                        class="cancel-button"
                        rounded
                    >
                        <v-icon
                            v-if="!isMobile"
                            left
                        >
                            mdi-arrow-left
                        </v-icon>
                        Back
                    </v-btn>
                    <v-btn
                        :block="isMobile"
                        :class="isMobile ? 'mt-5' : 'ml-2'"
                        class="primary-button-with-border"
                        color="primary"
                        rounded
                        @click="goToStep(2)"
                    >
                        Next
                        <v-icon
                            v-if="!isMobile"
                            right
                        >
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step
                    id="step-2"
                    :complete="e6 > 2"
                    editable
                    step="2"
                >
                    STUDENTS IN AN ARIZONA PRIVATE SCHOOL
                    <small class="subtitle-1 mt-2">
                        <span class="font-mulish">
                            If you have other students in your household who are not enrolled in an Arizona private school,
                            please list them in the Other Household Members section just below.
                        </span>
                    </small>
                </v-stepper-step>
                <v-stepper-content step="2">
                    <v-row>
                        <v-col md="8">
                            <div
                                v-if="isPreregistration"
                                class="box-with-background mb-5"
                            >
                                We've classified this application as a pre-registration because it doesn't include any
                                students who are enrolled in this school year. This pre-registration will be converted
                                to a
                                regular application if you also include one or more students who are enrolled in this
                                school
                                year.
                                <div class="mt-5">
                                    Pre-registrations are not eligible for scholarships in the current school year so we
                                    don't need as much information from you.
                                </div>
                            </div>
                            <v-card
                                class="pa-0"
                                color="primaryVeryLight"
                                elevation="0"
                            >
                                <family-member-table :type="3"/>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-btn
                        :block="!!isMobile"
                        class="mt-3 primary-button-with-border"
                        color="secondary"
                        rounded
                        @click="goToStep(3)"
                    >
                        Next
                    </v-btn>
                </v-stepper-content>

                <div v-if="!isPreregistration">
                    <v-stepper-step
                        id="step-3"
                        :complete="e6 > 3"
                        editable
                        step="3"
                    >
                        OTHER HOUSEHOLD MEMBERS
                        <small class="subtitle-1 mt-2">
                            <span class="font-mulish">
                                Please list all other household members not listed in the parents and students sections
                                above.
                            </span>
                        </small>
                    </v-stepper-step>
                    <v-stepper-content step="3">
                        <v-row>
                            <v-col md="8">
                                <v-card
                                    class="pa-0"
                                    color="primaryVeryLight"
                                    elevation="0"
                                >
                                    <family-member-table :type="1"/>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-btn
                            :block="!!isMobile"
                            class="mt-3 primary-button-with-border"
                            color="secondary"
                            rounded
                            @click="goToStep(4)"
                        >
                            Next
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                        id="step-4"
                        :complete="e6 > 4"
                        editable
                        step="4"
                    >
                        HOUSEHOLD INCOME
                    </v-stepper-step>
                    <v-stepper-content step="4">
                        <v-card
                            color="primaryVeryLight"
                            elevation="0"
                            style="border-left: 1px solid #808080; border-right: 1px solid #808080;"
                        >
                            <income-instructions/>
                            <family-member-income-table :allow-editing="true"/>
                            <!--                            <request-tax-return />-->
                        </v-card>
                        <v-btn
                            :block="!!isMobile"
                            class="primary-button-with-border mt-3"
                            color="secondary"
                            rounded
                            @click="goToStep(5)"
                        >
                            Next
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                        id="step-5"
                        :complete="e6 > 5"
                        editable
                        step="5"
                    >
                        HARDSHIP (OPTIONAL)
                        <small
                            class="subtitle-1 mt-2"
                            style="line-height: 1rem;"
                        >
                            <v-row class="pt-1">
                                <v-col md="8">
                                    <div class="font-mulish">
                                        Please check any hardships you may have experienced during the last year.
                                    </div>
                                </v-col>
                            </v-row>
                        </small>
                    </v-stepper-step>
                    <v-stepper-content step="5">
                        <v-row>
                            <v-col md="8">
                                <v-card
                                    class="pa-0 pb-2"
                                    color="primaryVeryLight"
                                    elevation="0"
                                >
                                    <financial-hardship/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-btn
                            :block="!!isMobile"
                            class="mt-3 primary-button-with-border"
                            color="secondary"
                            rounded
                            @click="goToStep(6)"
                        >
                            Next
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                        id="step-6"
                        :complete="e6 > 6"
                        editable
                        step="6"
                    >
                        DOCUMENTS
                        <small class="subtitle-1 mt-2">
                            <v-row>
                                <v-col md="8">
                                    <div class="font-mulish">
                                        Please upload the documents listed below to complete this application.
                                        <div
                                            v-if="applicationRequiresDocuments"
                                            class="mt-2"
                                        >
                                            Note: You don't need to do them all in one session - you can come back and
                                            upload them as they're available.
                                        </div>
                                        <div
                                            v-if="applicationRequiresDocuments"
                                            class="mt-2 font-weight-bold"
                                            style="color: black; text-transform: uppercase; line-height: 1.25rem;"
                                        >
                                            We cannot approve your application until you have uploaded all
                                            documents listed in this section.
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </small>
                    </v-stepper-step>
                    <v-stepper-content step="6">
                        <v-row>
                            <v-col>
                                <v-card
                                    class="pa-0"
                                    color="primaryVeryLight"
                                    elevation="0"
                                    min-height="50px"
                                >
                                    <documents-table/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-btn
                            :block="!!isMobile"
                            class="mt-3 primary-button-with-border"
                            color="secondary"
                            rounded
                            @click="goToStep(7)"
                        >
                            Next
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                        id="step-7"
                        :complete="e6 > 7"
                        editable
                        step="7"
                    >
                        SCHOLARSHIP ELIGIBILITY
                        <small class="subtitle-1 mt-2">
                            <v-row class="pt-1">
                                <v-col
                                    class="font-mulish"
                                    md="8"
                                >
                                    <div class="mt-2">
                                        This section explains which scholarships students are eligible for based on your
                                        answers on this application. It's informational only - you don't have to do
                                        anything
                                        here.
                                    </div>
                                    <div class="mt-2">
                                        Click the green arrow at the {{ isMobile ? "bottom right" : "right" }} of each
                                        scholarship type if you'd like to see why a student qualifies or does not
                                        qualify
                                        for a scholarship.
                                    </div>
                                </v-col>
                            </v-row>
                        </small>
                    </v-stepper-step>
                    <v-stepper-content step="7">
                        <v-row>
                            <v-col md="8">
                                <v-card
                                    class="pa-0"
                                    color="primaryVeryLight"
                                    elevation="0"
                                    min-height="200px"
                                >
                                    <scholarships/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-btn
                            :block="!!isMobile"
                            class="mt-3 primary-button-with-border"
                            color="secondary"
                            rounded
                            @click="goToStep(8)"
                        >
                            Next
                        </v-btn>
                    </v-stepper-content>
                    <v-stepper-step
                        id="step-8"
                        :complete="e6 > 8"
                        :step="8"
                        editable
                    >
                        AFFIDAVIT
                    </v-stepper-step>
                    <v-stepper-content :step="8">
                        <v-row>
                            <v-col md="8">
                                <v-card
                                    class="pt-1 px-2"
                                    color="white"
                                    elevation="0"
                                >
                                    <terms :application="currentApplication"/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <div
                            v-if="isMobile"
                            class="mx-2"
                        >
                            <v-btn
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                block
                                class="cancel-button mt-5"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                :disabled="!!currentApplication.under_review"
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                block
                                class="mt-5"
                                color="primary"
                            >
                                Finished
                            </v-btn>
                        </div>
                        <div
                            v-if="!isMobile"
                            class="mt-5"
                        >
                            <v-btn
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                class="cancel-button"
                                rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                :disabled="!!currentApplication.status_id === 2"
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                class="ml-2"
                                color="primary"
                                rounded
                            >
                                Finished
                            </v-btn>
                        </div>
                    </v-stepper-content>
                </div>
                <div v-else>
                    <v-stepper-step
                        id="step-3"
                        :complete="e6 > 3"
                        :step="3"
                        editable
                    >
                        ACKNOWLEDGEMENT
                    </v-stepper-step>
                    <v-stepper-content :step="3">
                        <v-row>
                            <v-col md="8">
                                <v-card
                                    class="pt-1 px-2"
                                    color="white"
                                    elevation="0"
                                >
                                    <acknowledgement :application="currentApplication"/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <div
                            v-if="isMobile"
                            class="mx-2"
                        >
                            <v-btn
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                block
                                class="cancel-button mt-5"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                :disabled="!!currentApplication.under_review"
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                block
                                class="mt-5"
                                color="primary"
                            >
                                Finished
                            </v-btn>
                        </div>
                        <div
                            v-if="!isMobile"
                            class="mt-5"
                        >
                            <v-btn
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                class="cancel-button"
                                rounded
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                :disabled="!!currentApplication.status_id === 2"
                                :to="{ name: 'household-applications-list', params: { household_id: $route.params.household_id } }"
                                class="ml-2"
                                color="primary"
                                rounded
                            >
                                Finished
                            </v-btn>
                        </div>
                    </v-stepper-content>
                </div>
            </v-stepper>
        </div>
        <messages-fab :conversation="currentApplication.conversation"/>
    </div>
</template>

<script>

import Acknowledgement from "../../../../components/household/application/Acknowledgement.vue";
import DocumentsTable from "../../../../components/household/application/DocumentsTable";
import FamilyMemberIncomeTable from "../../../../components/household/application/FamilyMemberIncomeTable.vue";
import FamilyMemberTable from "../../../../components/household/application/FamilyMemberTable";
import FinancialHardship from "../../../../components/household/application/FinancialHardship";
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import IncomeInstructions from "../../../../components/household/application/IncomeInstructions.vue";
import Loading from "../../../../components/shared/Loading.vue";
import MessagesFab from "../../../../components/shared/chat/MessagesFab";
import RequestTaxReturn from "../../../../components/household/application/RequestTaxReturn.vue";
import Scholarships from "../../../../components/household/application/Scholarships";
import SummaryTable from "../../../../components/household/application/SummaryTable";
import Terms from "../../../../components/household/application/Terms";
import {GET_HOUSEHOLD_APPLICATION} from "../../../../store/actions.type";

export default {
    name: "ApplicationEdit",
    components: {
        Acknowledgement,
        DocumentsTable,
        FamilyMemberIncomeTable,
        FamilyMemberTable,
        FinancialHardship,
        HouseholdHeader,
        IncomeInstructions,
        Loading,
        MessagesFab,
        RequestTaxReturn,
        Scholarships,
        SummaryTable,
        Terms,
    },
    data() {
        return {
            loading: false,
            e6: 1,
            file: null,
            rules: [
                value => !value || value.size < 5000000 || 'File size should be less than 5 MB!',
            ],
        }
    },
    computed: {
        applicationRequiresDocuments() {
            return this.$store.getters.currentApplicationDocuments.length > 0;
        },
        applicationType() {
            return this.isPreregistration ? 'Pre-Registration' : 'Application';
        },
        currentApplication() {
            return this.$store.getters.currentApplication;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        household() {
            return this.$store.getters.currentUserHousehold;
        },
        isPreregistration() {
            return this.currentApplication.is_preregistration;
        },
        pageTitle() {
            return this.applicationType + " for School Year " + this.currentApplication.school_year_name;
        },
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
    },
    methods: {
        getApplication() {
            this.loading = true;
            let application_id = this.$route.params.application_id;
            let household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_APPLICATION, {application_id, household_id})
                .then(() => {
                    this.loading = false;
                });
        },
        goToStep(step) {
            this.e6 = step;
            setTimeout(() => {
                console.log('scrolling to step-' + step);

                window.scrollTo({
                    behavior: 'smooth',
                    top: document.getElementById('step-' + step).getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top - 150
                });
            }, 800);
        },
    },
    mounted() {
        this.getApplication();
    }
}
</script>

<style scoped>
.primary-button-with-border {
    border: 1px solid var(--apsto-teal);
}

@media screen and (max-width: 947px) {
    .v-stepper__content {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.subtitle-1 {
    line-height: 1.1rem !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light) !important;
}

::v-deep .theme--light.v-stepper .v-stepper__label {
    color: var(--apsto-black) !important;
    font-family: "Mulish", sans-serif !important;
    font-weight: 700 !important;
}
</style>



