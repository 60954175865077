import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_COMPANY_ADMIN,
    GET_COMPANY_ADMIN,
    GET_COMPANY_ADMINS,
    GET_COMPANY_INVITE,
    GET_COMPANY_INVITES,
    PATCH_COMPANY_ADMIN,
    TRANSFER_COMPANY_ADMIN
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_COMPANY_ADMIN,
    SET_COMPANY_ADMINS,
    SET_COMPANY_INVITE,
    SET_COMPANY_INVITES,
    SET_ERROR
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        companyAdmin: {},
        companyAdmins: [],
        companyInvite: {},
        companyInvites: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentCompanyAdmins(state) {
        return state.companyAdmins;
    },
    currentCompanyInvites(state) {
        return state.companyInvites;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_COMPANY_ADMINS](context, company_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/admins`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_ADMINS, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companyAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_COMPANY_ADMIN](context, {company_id, company_admin_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${company_id}/admins/${company_admin_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_ADMIN, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "companyAdmins",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [TRANSFER_COMPANY_ADMIN](context, {company_id, user_id}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/companies/${company_id}/admins/${user_id}/transfer-primary`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_ADMIN, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companyAdmins');
                    reject(error.response);
                });
        });
    },
    [PATCH_COMPANY_ADMIN](context, {company_id, user_id, field_name, field_value}) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/companies/${company_id}/admins/${user_id}/${field_name}/${field_value}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_ADMIN, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companyAdmins');
                    reject(error.response);
                });
        });
    },
    [DELETE_COMPANY_ADMIN](context, {company_id, user_id}) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/companies/${company_id}/admins/${user_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companyAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_COMPANY_INVITES](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companies/${id}/invites`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_INVITES, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companyAdmins');
                    reject(error.response);
                });
        });
    },
    [GET_COMPANY_INVITE](context, {company_id, company_invite_id}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/companys/${company_id}/invites/${company_invite_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPANY_INVITE, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'companyAdmins');
                    reject(error.response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_COMPANY_ADMINS](state, data) {
        state.companyAdmins = data;
    },
    [SET_COMPANY_ADMIN](state, data) {
        state.companyAdmin = data;
    },
    [SET_COMPANY_INVITES](state, data) {
        state.companyInvites = data;
    },
    [SET_COMPANY_INVITE](state, data) {
        state.companyInvite = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
