import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {GET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN,} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    CLEAR_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN,
    SET_ERROR,
    SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENT_DROPDOWN_LOADING,
    SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN
} from "./mutations.type";
/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        loading: false,
        studentDocumentDropdown: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    studentDocumentDropdownLoading(state) {
        return state.loading;
    },
    studentDocumentDropdown(state) {
        return state.studentDocumentDropdown;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN](context, {
        application_id,
        household_id,
        application_member_id
    }) {

        context.commit(SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENT_DROPDOWN_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/applications/${application_id}/members/${application_member_id}/documents-dropdown`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN, data);
                    context.commit(SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENT_DROPDOWN_LOADING, false);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENT_DROPDOWN_LOADING, false);
                    context.commit(SET_ERROR, {
                        target: "studentDocumentDropdown",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENT_DROPDOWN_LOADING](state, data) {
        state.loading = data;
    },
    [SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN](state, data) {
        console.log("SET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN: ",data)
        state.studentDocumentDropdown = data;
    },
    [CLEAR_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN](state) {
        state.studentDocumentDropdown = [];
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
