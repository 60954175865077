<template>
  <v-data-table
    :class="isMobile ? 'pt-5' : 'pt-3'"
    :disable-sort="isMobile"
    :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
    :headers="headers"
    :hide-default-footer="true"
    :items="documents"
    :items-per-page="-1"
    class="elevation-2"
    sort-by="student_name"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn
          :class="isMobile ? 'mb-5' : 'mb-3'"
          color="primary"
          dark
          rounded
          @click="createItem"
        >
          Upload Document
        </v-btn>
        <v-dialog
          v-model="createDialog"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <application-file-upload @done="createDialog = false"/>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <dialog-confirmation
            :action="deleteItemConfirm"
            :button-question="'Yes, Delete It'"
            :message-response="messageResponse"
            :text-question="'Delete Document?'"
            :title="'Confirm'"
            @done="dialogDelete = false"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- Actions -->
    <template v-slot:item.actions="{ item }">
      <v-tooltip
        bottom
        color="secondary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="disableView(item)"
            :href="'/customer-documents/' + item.file_id"
            class="mr-2"
            icon
            target="_blank"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="secondary"> mdi-file</v-icon>
          </v-btn>
        </template>
        <span>View Uploaded Document</span>
      </v-tooltip>

      <v-tooltip
        bottom
        color="secondary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :disabled="disableDelete(item)"
            class="mr-2"
            color="red"
            v-bind="attrs"
            @click="deleteItem(item)"
            v-on="on"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Delete Uploaded Document</span>
      </v-tooltip>
    </template>
    <template #item.document_name="{ item }">
      <div v-if="!item.site_document_id">{{ item.document_name }}</div>
      <div v-else>
        {{ item.document_name }}
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :href="'/siteDocuments/' + item.site_document_id"
              color="secondary"
              icon
              target="_blank"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-file</v-icon>
            </v-btn>
          </template>
          <span>Click Me To Get Document To Fill Out</span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:no-data>
            <span class="text-apsto-teal text-h7-mulish">
                No documents are required based on the answers you provided for your students.
            </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  DELETE_HOUSEHOLD_APPLICATION_DOCUMENT,
  GET_HOUSEHOLD_APPLICATION_DOCUMENTS,
  GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS,
} from "../../../store/actions.type";
import ApplicationFileUpload from "./ApplicationFileUpload";
import DialogConfirmation from "../../shared/DialogConfirmation.vue";

export default {
  name: "DocumentsTable",
  components: {DialogConfirmation, ApplicationFileUpload},
  data: () => ({
    createDialog: false,
    dialogDelete: false,
    messageResponse: null,
    temporaryItem: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Student",
          align: "start",
          sortable: false,
          value: "student_name",
        },
        {text: "Verification", value: "qualification_name"},
        {text: "CustomerDocument to Upload", value: "document_name"},
        {text: "Last Uploaded", value: "document_submitted"},
        {text: "Date Approved", value: "document_approved"},
        {text: "Actions", value: "actions", sortable: false},
      ];
    },
    application() {
      return this.$store.getters.currentApplication;
    },
    defaultItem() {
      return {
        student: {},
      };
    },
    documents() {
      return this.$store.getters.currentApplicationDocuments;
    },
    error() {
      return this.$store.getters.getMessage("application");
    },
    errors() {
      return this.$store.getters.getErrors("application");
    },
  },
  methods: {
    createItem() {
      this.temporaryItem = Object.assign({}, this.defaultItem);
      this.createDialog = true;
    },
    disableView(item) {
      return !item.file_id;
    },
    disableDelete(item) {
      return !!(!item.document_submitted || item.document_approved);
    },
    deleteItem(item) {
      this.temporaryItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.messageResponse = null;
    },
    deleteItemConfirm() {
      let application_id = this.$route.params.application_id;
      let household_id = this.$route.params.household_id;
      let document_id = this.temporaryItem.document_id;
      let id = this.temporaryItem.id;
      this.$store
        .dispatch(DELETE_HOUSEHOLD_APPLICATION_DOCUMENT, {
          household_id,
          application_id,
          document_id,
          id,
        })
        .then(() => {
          this.getDocuments();
          this.updateScholarships();
          this.dialogDelete = false;
        })
        .catch((response) => {
          this.messageResponse = response.data.message_response;
        })
      ;
    },
    getDocuments() {
      this.loading = true;
      const application_id = this.$route.params.application_id;
      const household_id = this.$route.params.household_id;
      this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_DOCUMENTS, {
        household_id,
        application_id,
      }).then(() => {
        this.loading = false;
      })
    },
    updateScholarships() {
      const application_id = this.$route.params.application_id;
      const household_id = this.$route.params.household_id;
      this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS, {
        household_id,
        application_id,
      });
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}
</style>
