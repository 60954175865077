<template>
    <div>
        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
            :headers="headers"
            :items="studentAwards"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :page.sync="page"
            :search="search"
            :server-items-length="serverItemsLength"
            :single-select="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="mt-3"
            item-key="id"
            @pagination="getStudentAwards"
            @click:row="edit"
            @update:sort-by="getStudentAwards"
            @update:sort-desc="getStudentAwards"
        >
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="search"
                            background-color="white"
                            label="Search on anything"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no student awards on file for this school.
                </span>
            </template>
            <template v-slot:item.first_name="{ item }">
                {{ item.last_name }}, {{ item.first_name }}
                <span v-if="item.nick_name">&nbsp;({{ item.nick_name }})</span>
                <span>&nbsp;({{ item.primary_user_name }})</span>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ displayDate(item.created_at) }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { GET_ADMIN_SCHOOL_STUDENT_AWARDS } from "../../../../../store/actions.type";

export default {
    name: "AdminSchoolStudentAwardsTable",
    data() {
        return {
            headers: [
                { text: 'Student', align: 'start', value: 'first_name' },
                { text: 'LAST AWARD', value: 'created_at', align: 'end' },
            ],
            itemsPerPage: 10,
            loading: true,
            page: 1,
            pageCount: 0,
            search: "",
            serverItemsLength: 0,
            sortBy: 'name',
            sortDesc: false,
        }
    },
    computed: {
        studentAwards() {
            return this.$store.getters.currentAdminSchoolStudentAwards['collection'];
        },
    },
    methods: {
        edit(item) {
            let school_id = this.$route.params.school_id;
            this.$router.push({
                name: "school-student-awards-edit",
                params: { school_id: school_id, permanent_id: item.permanent_id }
            });
        },
        getStudentAwards() {
            this.loading = true;
            let page = this.page;
            let itemsPerPage = this.itemsPerPage;
            let sortBy = this.sortBy;
            let sortDesc = this.sortDesc;
            let search = this.search;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(GET_ADMIN_SCHOOL_STUDENT_AWARDS, { page, itemsPerPage, sortBy, sortDesc, search, school_id })
                .then((data) => {
                    this.serverItemsLength = data.totalRecords;
                    this.loading = false;
                });
        }
    },
    watch: {
        search() {
            this.page = 1;
            this.getStudentAwards();
        }
    }
}
</script>

<style scoped>
::v-deep tbody td {
    height: 40px !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.totals) {
    background-color: var(--apsto-teal-very-light);
}

::v-deep .theme--light.v-data-table.v-data-table--fixed-header thead th {
    background: unset;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
}

::v-deep .theme--light.v-data-table .v-row-group__header,
.theme--light.v-data-table .v-row-group__summary {
    background: var(--apsto-teal-very-light);
}
</style>
