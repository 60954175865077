/*
Some example action verbs
---------------------------
GET
REGET
POST
PATCH
DELETE
*/

/*------------------------------------*\
    ERRORS
\*------------------------------------*/
export const CLEAR_ERRORS_ACTION = "clearErrorsAction";

/*------------------------------------*\
    AUTH
\*------------------------------------*/
export const CHECK_AUTH = "checkAuth";
export const CHECK_REGISTERED = "checkRegistered";
export const EMAIL_PASSWORD_RESET = "emailPasswordReset";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const PASSWORD_RESET = "passwordReset";
export const PATCH_USER = "patchUser";
export const REGISTER = "register";
export const TOGGLE_AUTH_DIALOG = "toggleAuthDialog";
export const ADMIN_CHECK_REGISTERED = "adminCheckRegistered";
export const ADMIN_REGISTER_USER = "adminRegisterUser";
export const CONFIRM_CHANGE_USERNAME = "confirmChangeUsername";
export const SEND_CHANGE_USERNAME_EMAIL = "sendChangeUsernameEmail";
export const SIGN_IN_FROM_LINK = "SignInFromLink";
export const SEND_SIGN_IN_EMAIL_FROM_CHARITABLE_DONATION = "sendSignInEmailFromCharitableDonation";
export const SEND_SIGN_IN_EMAIL_FROM_DONATION = "sendSignInEmailFromDonation";
export const SEND_SIGN_IN_EMAIL_FROM_DONATION_PAGE = "sendSignInEmailFromDonationPage";
export const SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE = "sendSignInEmailFromLoginPage";
export const RECORD_ACCEPTANCE_OF_OFFER_TO_REMEMBER_USER = "recordAcceptanceOfOfferToRememberUser";
export const GET_USER_ADDRESS = "getUserAddress";
export const CHECK_EMAILS_FOR_DATA = "checkEmailsForData";

/*------------------------------------*\
    Website
\*------------------------------------*/
export const GET_CONTENTS = "getContents";
export const GET_LAYOUTS = "getLayouts";
export const GET_COMPONENTS = "getComponents";

export const POST_PAGE = "postPage";
export const PATCH_PAGE = "patchPage";
export const DELETE_PAGE = "deletePage";

export const POST_CONTENT = "postContent";
export const PATCH_CONTENT = "updateContent";
export const DELETE_CONTENT = "deleteContent";

export const POST_SORT_ORDER = "postSortOrder";

export const GET_ANNOUNCEMENTS = "getAnnouncements";
/*------------------------------------*\
    Admin
\*------------------------------------*/
export const DELETE_HOUSEHOLD_APPLICATION_ADMIN = "deleteHouseholdApplicationAdmin";
export const DELETE_SALESPERSON = "deleteSalesperson";
export const DELETE_SALESPERSON_ASSIGNMENT = "deleteSalespersonAssignment";
export const DELETE_SCHOOL_APPLICATION_ADMIN = "deleteSchoolApplicationAdmin";
export const DELETE_SITE_DOCUMENT = "deleteSteDocument";
export const DELETE_SITE_WALLPAPER = "deleteSiteWallpaper";
export const DOWNLOAD_FUND_TRANSACTIONS = "downloadFundTransactions";
export const GET_ADMIN_CORPORATE_PLEDGES = "getAdminCorporatePledges";
export const GET_ADMIN_DONOR_DONATIONS = "getAdminDonorDonations";
export const GET_ADMIN_DONOR_DONATIONS_RECENT = "getAdminDonorDonationsRecent";
export const GET_ADMIN_HOUSEHOLD = "getAdminHousehold";
export const GET_ADMIN_HOUSEHOLD_APPLICATIONS = "getAdminHouseholdApplications";
export const GET_ADMIN_HOUSEHOLD_MEMBERS = "getAdminHouseholdMembers";
export const GET_ADMIN_HOUSEHOLDS = "getAdminHouseholds";
export const GET_ADMIN_LINKED_STUDENT = "getAdminLinkedStudent";
export const GET_ADMIN_LINKED_STUDENT_AWARDS = "getAdminLinkedStudentAwards";
export const GET_ADMIN_LINKED_STUDENT_NAME = "getAdminLinkedStudentName";
export const GET_ADMIN_SCHOOL = "getAdminSchool";
export const GET_ADMIN_SCHOOL_APPLICATIONS = "getAdminSchoolApplications";
export const GET_ADMIN_SCHOOL_STUDENT_AWARD = "getAdminSchoolStudentAward";
export const GET_ADMIN_SCHOOL_STUDENT_AWARDS = "getAdminSchoolStudentAwards";
export const GET_ADMIN_SCHOOL_STUDENT_AWARDS_BALANCES = "getAdminSchoolStudentAwardsBalances";
export const GET_ADMIN_SCHOOL_STUDENTS = "getAdminSchoolStudents";
export const GET_ADMIN_SCHOOLS = "getAdminSchools";
export const GET_ADMIN_STUDENTS = "getAdminStudents";
export const GET_ADMIN_UNLINKED_STUDENT = "getAdminUnlinkedStudent";
export const GET_ADMIN_UNLINKED_STUDENT_NAME = "getAdminUnlinkedStudentName";
export const GET_ADMIN_USERS = "getAdminUsers";
export const GET_ADMIN_DEPOSITS = "getAdminDeposits";
export const GET_ADMIN_DEPOSIT = "getAdminDeposit";
export const GET_ADMIN_DEPOSIT_AWARD_REFUNDS = "getAdminDepositAwardRefunds";
export const POST_AWARD_REFUNDS_TO_DEPOSIT = "postAwardRefundsToDeposit";
export const DELETE_ADMIN_DEPOSIT = "deleteAdminDeposit";
export const DELETE_DEPOSIT_AWARD_REFUND = "deleteDepositAwardRefund";
export const PATCH_ADMIN_DEPOSIT = "patchAdminDeposit";
export const POST_ADMIN_DEPOSIT = "postAdminDeposit";
export const GET_APPLICATION_SCHOOL_STUDENT_BY_STUDENT_PERMANENT_ID = "getApplicationSchoolStudentByStudentPermanentId";
export const GET_CORPORATE_PLEDGES_ADMIN = "getCorporatePledgesAdmin";
export const GET_CORPORATE_PLEDGE_ADMIN = "getCorporatePledgeAdmin";
export const GET_FUND_SECTIONS = "getFundSections";
export const GET_FUND_SECTION_DONATIONS = "getFundSectionDonations";
export const GET_FUND_SECTION_RECOMMENDATIONS = "getFundSectionRecommendations";
export const GET_FUND_SECTION_STUDENT_AWARDS = "getFundSectionStudentAwards";
export const GET_FUND_TRANSACTIONS = "getFundTransactions";
export const GET_PENDING_STUDENT_AWARDS = "getPendingStudentAwards";
export const GET_PENDING_STUDENT_AWARDS_COUNT = "getPendingStudentAwardsCount";
export const GET_UNLOCKED_STUDENT_AWARDS_COUNT = "getUnlockedStudentAwardsCount";
export const GET_RECENT_STUDENT_AWARDS = "getRecentStudentAwards";
export const GET_STUDENT_AWARDS_BY_DATE_RANGE = "getStudentAwardsByDateRange";
export const RELEASE_AWARD_OFFERS = "releaseAwardOffers";
export const LOCK_AWARD_OFFERS = "lockAwardOffers";
export const GET_REVISIONS = "getRevisions";
export const GET_SALESPEOPLE = "getSalespeople";
export const GET_SALESPERSON = "getSalesperson";
export const GET_SALESPERSON_ASSIGNMENT = "getSalespersonAssignment";
export const GET_SALESPERSON_ASSIGNMENTS = "getSalespersonAssignments";
export const GET_SELECTED_USER = "getSelectedUser";
export const GET_SITE_DOCUMENT = "getSiteDocument";
export const GET_SITE_DOCUMENTS = "getSiteDocuments";
export const GET_SITE_DOCUMENTS_BY_SLUGS = 'getSiteDocumentsBySlugs';
export const GET_SITE_WALLPAPER = "getSiteWallpaper";
export const GET_SITE_WALLPAPERS = "getSiteWallpapers";
export const GET_SITE_WALLPAPERS_BY_SLUGS = 'getSiteWallpapersBySlugs';
export const GET_USER_DROPDOWN = "getUserDropdown";
export const GET_USER_LIST = "getUserList";
export const LINK_DONOR_STUDENT_RECOMMENDATION_TO_ACTUAL_STUDENT = "linkDonorStudentRecommendationToActualStudent";
export const LINK_MULTIPLE_DONOR_STUDENT_RECOMMENDATIONS_TO_ACTUAL_STUDENT = "linkMultipleDonorStudentRecommendationsToActualStudent";
export const MARK_REVISIONS_VIEWED = "markRevisionsViewed";
export const PATCH_CONTACT_READ_STATUS = "patchContactReadStatus";
export const PATCH_CORPORATE_PLEDGE_ADMIN = "patchCorporatePledgeAdmin";
export const PATCH_HOUSEHOLD_ADMIN_VERSION = "patchHouseholdAdminVersion";
export const PATCH_SALESPERSON = "patchSalesperson";
export const PATCH_SALESPERSON_ASSIGNMENT = "patchSalespersonAssignment";
export const PATCH_SITE_DOCUMENT = "patchSiteDocument";
export const PATCH_SITE_WALLPAPER_STATUS = "patchSiteWallpaperStatus";
export const PATCH_STUDENT_DOCUMENT_ADMIN = "patchStudentDocumentAdmin";
export const POST_ADMIN_BANK_REPORT_DATES = "postAdminBankReportDates";
export const POST_ADMIN_CORPORATE_PLEDGES_REPORT_DATES = "postAdminCorporatePledgesReportDates";
export const POST_ADMIN_DONOR_DONATIONS_REPORT_DATES = "postAdminDonorDonationsReportDates";
export const POST_ADMIN_STUDENT_AWARDS_REPORT_DATES = "postAdminStudentAwardsReportDates";
export const POST_FUND_TRANSACTION_REPORT_DATES = "postFundTransactionReportDates";
export const POST_SALESPERSON = "postSalesperson";
export const POST_SALESPERSON_ASSIGNMENT = "postSalespersonAssignment";
export const POST_SITE_DOCUMENT = "postSiteDocument";
export const POST_SITE_WALLPAPER = "postSiteWallpaper";
export const UNLINK_DONOR_STUDENT_RECOMMENDATION = "unlinkDonorStudentRecommendation";
export const UPDATE_SITE_DOCUMENTS = "updateSiteDocuments";
export const UPLOAD_SITE_WALLPAPER = "uploadSiteWallpaper";

/*------------------------------------*\
    COMPANIES
\*------------------------------------*/
export const GET_COMPANIES = "getCompanies";
export const GET_COMPANIES_IN_EMPLOYEE_DONATIONS_PROGRAM = "getCompaniesInEmployeeDonationsProgram";
export const GET_COMPANY = "getCompany";
export const GET_USER_COMPANIES = "getUserCompanies";
export const GET_USER_COMPANY = "getUserCompany";
export const GET_COMPANY_DROPDOWN = "getCompanyDropdown";
export const DELETE_COMPANY = "deleteCompany";
export const POST_COMPANY = "postCompany";
export const POST_COMPANY_WITH_CORPORATE_PLEDGE = "postCompanyWithCorporatePledge";
export const PATCH_COMPANY = "patchCompany";
export const LOOKUP_COMPANY_BY_EMPLOYER_WITHHOLDING_CODE = "lookupCompanyByEmployerWithholdingCode";

/*-----------------------------------*\
    COMPANY ADMINS
\*-----------------------------------*/

export const DELETE_COMPANY_ADMIN = "deleteCompanyAdmin";
export const GET_COMPANY_ADMIN = "getCompanyAdmin";
export const GET_COMPANY_ADMINS = "getCompanyAdmins";
export const GET_COMPANY_INVITE = "getCompanyInvite";
export const GET_COMPANY_INVITES = "getCompanyInvites";
export const PATCH_COMPANY_ADMIN = "patchCompanyAdmin";
export const TRANSFER_COMPANY_ADMIN = "transferCompanyAdmin";

/*------------------------------------*\
    Brochure Requests
\*------------------------------------*/

export const GET_BROCHURE_REQUESTS = "getBrochureRequests";
export const DELETE_BROCHURE_REQUEST = "deleteBrochureRequest";
export const POST_BROCHURE_REQUEST_FORM = "postBrochureRequestForm";

/*------------------------------------*\
    Contact Us
\*------------------------------------*/

export const GET_CONTACT_US_MESSAGES = "getContactUsMessages";
export const DELETE_CONTACT_US_MESSAGE = "deleteContactUsMessage";
export const POST_CONTACT_US_FORM = "postContactUsForm";
export const POST_CONTACT_USER_FROM = "postContactUserFrom";

/*-----------------------------------*\
    CORPORATE PLEDGES
\*-----------------------------------*/
export const DELETE_CORPORATE_PLEDGE = "deleteCorporatePledge";
export const DELETE_CORPORATE_PLEDGE_PAYMENT = "deleteCorporatePledgePayment";
export const GET_CORPORATE_PLEDGE = "getCorporatePledge";
export const GET_CORPORATE_PLEDGES = "getCorporatePledges";
export const GET_CORPORATE_PLEDGE_PAYMENTS = "getCorporatePledgePayments";
export const GET_TAX_CREDIT_INFO = "getTaxCreditInfo";
export const PATCH_CORPORATE_PLEDGE = "patchCorporatePledge";
export const PATCH_CORPORATE_PLEDGE_PAYMENT = "patchCorporatePledgePayment";
export const POST_CORPORATE_PLEDGE = "postCorporatePledge";
export const POST_CORPORATE_PLEDGE_CARD_PAYMENT = "postCorporatePledgeCardPayment";
export const POST_CORPORATE_PLEDGE_PAYMENT = "postCorporatePledgePayment";
export const POST_ADOR_APPROVAL_DOCUMENT_UPLOAD = "postAdorApprovalDocumentUpload";

/*------------------------------------*\
    DONORS
\*------------------------------------*/
export const DELETE_DONOR_PRIOR_DONATION = "deleteDonorPriorDonation";
export const DELETE_DONOR_PRIOR_DONATIONS = "deleteDonorPriorDonations";
export const DELETE_DONOR_SUBSCRIPTION_DONATION = "deleteDonorSubscriptionDonation";
export const DELETE_DONOR_WITHHOLDING_DONATION = "deleteDonorWithholdingDonation";
export const EMAIL_DONOR_ANNUAL_RECEIPT = "emailDonorAnnualReceipt";
export const EMAIL_DONOR_DONATION = "emailDonorDonation";
export const EMAIL_DONOR_SUBSCRIPTION_DONATION = "emailDonorSubscriptionDonation";
export const EMAIL_DONOR_WITHHOLDING_DONATION = "emailDonorWithholdingDonation";
export const GET_CURRENT_DONOR = "getDonor";
export const GET_DONOR_DONATIONS = "getDonorDonations";
export const GET_DONOR_DONATION_TAX_YEAR_FACTORS = "getDonorDonationTaxYearFactors";
export const GET_DONOR_PRIOR_DONATIONS = "getDonorPriorDonations";
export const GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES = "getDonorStudentRecommendationsFromPromoPages";
export const GET_DONOR_SUBSCRIPTION_DONATIONS = "getDonorSubscriptionDonations";
export const GET_DONOR_WITHHOLDING_DONATIONS = "getDonorWithholdingDonations";
export const GET_LAST_TWO_YEARS_DONATIONS_HISTORY = "getLastTwoYearsDonationHistory";
export const PATCH_CURRENT_DONOR = "patchDonor";
export const PATCH_DONOR_PRIOR_DONATION = "patchDonorPriorDonation";
export const POST_DONOR_DONATION = "postDonorDonation";
export const POST_DONOR_DONATION_PAYMENT_BY_CHECK = "postDonorDonationPaymentByCheck";
export const POST_DONOR_PRIOR_DONATION = "postDonorPriorDonation";
export const POST_DONOR_SUBSCRIPTION_DONATION_SETUP = "postDonorSubscriptionDonationSetup";
export const POST_DONOR_WITHHOLDING_DONATION_SETUP = "postDonorWithholdingDonationSetup";
export const POST_DONOR_WITHHOLDING_FORM_UPLOAD = "postDonorWithholdingFormUpload";
export const GET_DONOR_SELECTION_WITH_FILE = "getDonorWithholdingDocument";
export const GET_STATE_ISO_FROM_REGION_ID = "getStateIsoFromRegionId";

/*-----------------------------------*\
    PAYROLL WITHHOLDING
\*-----------------------------------*/
export const GET_ANNUAL_EMPLOYEE_DONATIONS = "getAnnualEmployeeDonations";
export const GET_EMPLOYEE_DONATIONS = "getEmployeeDonations"; // daily donations
export const GET_NEXT_COMPANY_PAYROLL_BATCH = "getNextCompanyPayrollBatch";
export const GET_COMPANY_PAYROLL_BATCHES_BY_YEAR = "getCompanyPayrollBatchesByYear";
export const GET_EMPLOYEE_DONATION = "getEmployeeDonation";
export const GET_EMPLOYEE_DONATION_EMPLOYEES = "getEmployeeDonationEmployees";
export const POST_EMPLOYEE_DONATIONS_FILE_UPLOAD = "postEmployeeDonationsFileUpload";
export const POST_EMPLOYEE_DONATION = "postEmployeeDonation";
export const GET_EMPLOYEES_FORM_A4C = "getEmployeesFormA4C";
export const PATCH_COMPANY_EMPLOYEE_DONATIONS_FREQUENCY = "patchCompanyEmployeeDonationsFrequency";
export const PATCH_COMPANY_EMPLOYEE_DONATIONS_PARTICIPATION = "patchCompanyEmployeeDonationsParticipation";
export const POST_COMPANY_DAILY_DONATION_CARD_PAYMENT = "postCompanyPayrollBatchCardPayment";
export const POST_COMPANY_DAILY_DONATION_PAYMENT = "postCompanyPayrollBatchPayment";
export const PATCH_COMPANY_DAILY_DONATION_PAYMENT = "patchCompanyPayrollBatchPayment";
export const DELETE_COMPANY_DAILY_DONATION = "deleteCompanyPayrollBatch";
export const DELETE_COMPANY_DAILY_DONATION_PAYMENT = "deleteCompanyPayrollBatchPayment";

/*------------------------------------*\
    HOUSEHOLDS
\*------------------------------------*/
export const GET_HOUSEHOLDS = "getHouseholds";
export const GET_HOUSEHOLD = "getHousehold";
export const GET_USER_HOUSEHOLDS = "getUserHouseholds";
export const GET_USER_HOUSEHOLD = "getUserHousehold";
export const DELETE_HOUSEHOLD = "deleteHousehold";
export const POST_HOUSEHOLD = "postHousehold";
export const PATCH_HOUSEHOLD = "patchHousehold";

/*-----------------------------------*\
    HOUSEHOLD ADMINS
\*-----------------------------------*/
export const GET_HOUSEHOLD_ADMINS = "getHouseholdAdmins";
export const GET_HOUSEHOLD_ADMIN = "getHouseholdAdmin";
export const PATCH_HOUSEHOLD_ADMIN = "patchHouseholdAdmin";
export const DELETE_HOUSEHOLD_ADMIN = "deleteHouseholdAdmin";
export const TRANSFER_HOUSEHOLD_ADMIN = "transferHouseholdAdmin";
export const GET_HOUSEHOLD_INVITES = "getHouseholdInvites";
export const GET_HOUSEHOLD_INVITE = "getHouseholdInvite";

/*------------------------------------*\
    HOUSEHOLD APPLICATIONS
\*------------------------------------*/
export const GET_HOUSEHOLD_APPLICATIONS = "getHouseholdApplications";
export const GET_HOUSEHOLD_APPLICATION = "getHouseholdApplication";
export const GET_HOUSEHOLD_APPLICATION_AS_ADMIN = "reviewHouseholdApplicationAsAdmin";
export const GET_HOUSEHOLD_APPLICATION_DOCUMENTS = "getHouseholdApplicationDocuments";
export const GET_HOUSEHOLD_APPLICATION_MEMBERS = "getHouseholdApplicationMembers";
export const GET_HOUSEHOLD_APPLICATION_SCHOLARSHIPS = "getHouseholdApplicationScholarships";
export const GET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR = "getHouseholdApplicationSchoolYear";
export const GET_HOUSEHOLD_APPLICATION_STUDENT_DOCUMENTS_DROPDOWN = "getHouseholdApplicationStudentDocumentsDropdown";
export const GET_HOUSEHOLD_APPLICATION_STUDENTS_DROPDOWN = "getHouseholdApplicationStudentsDropdown";
export const DELETE_HOUSEHOLD_APPLICATION = "deleteHouseholdApplication";
export const EMAIL_HOUSEHOLD_APPLICATION = "emailHouseholdApplication";
export const POST_HOUSEHOLD_APPLICATION = "postHouseholdApplication";
export const PATCH_HOUSEHOLD_APPLICATION_CHECKBOX = "patchHouseholdApplicationCheckbox";
export const PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_CHECKBOX = "patchHouseholdApplicationHardshipCheckbox";
export const PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_COMMENTS = "patchHouseholdApplicationHardshipComments";
export const GET_HOUSEHOLD_APPLICATION_MEMBER = "getHouseholdApplicationMember";
export const POST_HOUSEHOLD_APPLICATION_MEMBER = "postHouseholdApplicationMember";
export const PATCH_HOUSEHOLD_APPLICATION_MEMBER = "patchHouseholdApplicationMember";
export const PATCH_HOUSEHOLD_APPLICATION_MEMBER_INCOME = "patchHouseholdApplicationMemberIncome";
export const PATCH_HOUSEHOLD_APPLICATION_BROCHURE = "patchHouseholdApplicationBrochure";
export const DELETE_HOUSEHOLD_APPLICATION_DOCUMENT = "deleteHouseholdApplicationDocument";
export const DELETE_HOUSEHOLD_APPLICATION_MEMBER = "deleteHouseholdApplicationMember";
export const POST_HOUSEHOLD_APPLICATION_MEMBER_DOCUMENT_UPLOAD = "postHouseholdApplicationMemberDocumentUpload";
export const POST_HOUSEHOLD_APPLICATION_TAX_RETURN_UPLOAD = "postHouseholdApplicationTaxReturnUpload";

/*------------------------------------*\
    APPLICATION PROMOS
\*------------------------------------*/

export const GET_APPLICATION_PROMOS = "getApplicationPromos";
export const GET_APPLICATION_PROMO = "getApplicationPromo";
export const CHECK_APPLICATION_PROMO = "checkApplicationPromo";
export const POST_APPLICATION_PROMO = "postApplicationPromo";
export const PATCH_APPLICATION_PROMO = "patchApplicationPromo";
export const TOGGLE_APPLICATION_PROMO = "toggleApplicationPromo";
export const GET_FAMILY_PROMO_DISPLAY = "getFamilyPromoDisplay";
export const GET_CHILD_PROMO_DISPLAY = "getChildPromoDisplay";

/*------------------------------------*\
    INVITES
\*------------------------------------*/
export const GET_INVITE = "getInvite";
export const DELETE_INVITE = "deleteInvite";
export const POST_INVITE = "postInvite";
export const PATCH_INVITE = "patchInvite";
export const POST_AFTER_REGISTRATION_FROM_INVITE = "postAfterRegistrationFromInvite";

/*-----------------------------------*\
    REGIONS
\*-----------------------------------*/
export const GET_REGIONS = "getRegions";

/*-----------------------------------*\
    SCHOOLS
\*-----------------------------------*/
export const DELETE_SCHOOL = "deleteSchool";
export const GET_APPROVED_SCHOOLS = "getApprovedSchools";
export const GET_SCHOOL = "getSchool";
export const GET_SCHOOLS = "getSchools";
export const GET_SCHOOL_DROPDOWN = "getSchoolDropdown";
export const GET_SCHOOL_FOR_RECOMMENDATION = "getSchoolForRecommendation";
export const GET_SCHOOL_GRADES = "getSchoolGrades";
export const GET_SCHOOL_YEARS = "getSchoolYears";
export const GET_USER_SCHOOL = "getUserSchool";
export const GET_USER_SCHOOLS = "getUserSchools";
export const PATCH_SCHOOL = "patchSchool";
export const PATCH_SCHOOL_CHECKBOX = "patchSchoolCheckbox";
export const POST_SCHOOL = "postSchool";
export const GET_AWARDS_BY_SCHOOL_AND_PERIOD = "getAwardsBySchoolAndPeriod";
export const GET_ADMIN_SCHOOL_STUDENT_AVAILABLE_AWARDS = "getAdminSchoolStudentAvailableAwards";

/*-----------------------------------*\
    SCHOOL ADMINS
\*-----------------------------------*/
export const GET_SCHOOL_ADMINS = "getSchoolAdmins";
export const GET_SCHOOL_ADMIN = "getSchoolAdmin";
export const PATCH_SCHOOL_ADMIN = "patchSchoolAdmin";
export const DELETE_SCHOOL_ADMIN = "deleteSchoolAdmin";
export const TRANSFER_SCHOOL_ADMIN = "transferSchoolAdmin";
export const GET_SCHOOL_INVITES = "getSchoolInvites";
export const GET_SCHOOL_INVITE = "getSchoolInvite";

/*------------------------------------*\
    SCHOOL APPLICATIONS
\*------------------------------------*/
export const DELETE_SCHOOL_APPLICATION = "deleteSchoolApplication";
export const EMAIL_SCHOOL_APPLICATION = "emailSchoolApplication";
export const GET_SCHOOL_APPLICATION = "getSchoolApplication";
export const GET_SCHOOL_APPLICATIONS = "getSchoolApplications";
export const GET_SCHOOL_APPLICATION_GRADES = "getSchoolApplicationGrades";
export const GET_SCHOOL_APPLICATION_SCHOOL_YEAR = "getSchoolApplicationSchoolYear";
export const PATCH_HOUSEHOLD_APPLICATION_STATUS = "patchHouseholdApplicationStatus";
export const PATCH_SCHOOL_APPLICATION = "patchSchoolApplication";
export const PATCH_SCHOOL_APPLICATION_CHECKBOX = "patchSchoolApplicationCheckbox";
export const PATCH_SCHOOL_APPLICATION_GRADE = "patchSchoolApplicationGrade";
export const PATCH_SCHOOL_APPLICATION_RADIO = "patchSchoolApplicationRadio";
export const PATCH_SCHOOL_APPLICATION_STATUS = "patchSchoolApplicationStatus";
export const PATCH_SCHOOL_APPLICATION_TEXTAREA = "patchSchoolApplicationTextarea";
export const PATCH_SCHOOL_APPLICATION_TEXT_FIELD = "patchSchoolApplicationTextField";
export const POST_SCHOOL_APPLICATION = "postSchoolApplication";
export const POST_SCHOOL_APPLICATION_DOCUMENT_UPLOAD = "postSchoolApplicationDocumentUpload";

/*------------------------------------*\
    SCHOOL STUDENTS
\*------------------------------------*/
export const DELETE_SCHOOL_STUDENT_AWARD = "deleteSchoolStudentAward";
export const GET_SCHOOL_PENDING_STUDENT_AWARDS = "getSchoolPendingStudentAwards";
export const GET_SCHOOL_STUDENT = "getSchoolStudent";
export const GET_SCHOOL_STUDENT_YEARS = "getSchoolStudentYears";
export const GET_SCHOOL_STUDENTS = "getSchoolStudents";
export const GET_SCHOOL_STUDENTS_NON_RENEWED = "getSchoolStudentsNonRenewed";
export const GET_SCHOOL_STUDENT_AWARD = "getSchoolStudentAward";
export const PATCH_SCHOOL_STUDENT_AWARD = "patchSchoolStudentAward";
export const PATCH_STUDENT_AMOUNT_REQUESTED = "patchStudentAmountRequested";
export const PATCH_STUDENT_HIDE = "patchStudentHide";
export const POST_SCHOOL_STUDENT_AWARD = "postSchoolStudentAward";
export const GET_SCHOOL_STUDENT_AWARD_REFUND = "getSchoolStudentAwardRefund";
export const GET_ADMIN_SCHOOL_STUDENT_AWARDS_LATEST_PERIOD = "getAdminSchoolStudentAwardsLatestPeriod";
export const POST_SCHOOL_STUDENT_AWARD_ACTION = "postSchoolStudentAwardAction";
export const POST_SCHOOL_STUDENT_AWARDS_PERIOD = "postSchoolStudentAwardsPeriod";
export const GET_FISCAL_YEARS = "getFiscalYears";
export const GET_SCHOOL_YEAR_NAME = "getSchoolYearName";
export const GET_STUDENT_PERMANENTS = "getStudentPermanents";
export const GET_APPLICATION_BY_APPLICATION_MEMBER_ID = "getApplicationByApplicationMemberId";

/*------------------------------------*\
    CHAT
\*------------------------------------*/

export const REGET_CONVERSATIONS = "regetConversations";
export const GET_CONVERSATIONS = 'getConversations';
export const START_CONVERSATION = 'startConversation';
export const DELETE_CONVERSATION = 'deleteConversation';
export const ACCEPT_CONVERSATION = 'acceptConversation';
export const IGNORE_CONVERSATION = 'ingoreConversation';
export const READ_CONVERSATION = 'readConversation';

export const SHADOWBAN = 'shadowBan';
export const GET_MESSAGES = 'getMessages';
export const GET_CHATS = 'getChats';
export const UPDATE_CHAT = 'updateChat';
export const DELETE_CHAT = 'deleteChat';
export const POST_MESSAGE = 'postMessage';
export const ADD_MESSAGE = 'addMessage';
export const UPDATE_MESSAGE = 'updateMessage';
export const DELETE_MESSAGE = 'deleteMessage';
