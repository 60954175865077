<template>
  <div>
    <div class="text-h5"> Company Information</div>
    <company-form
      :company="company"
      :create="true"
      :with-pledge="true"
      class="mt-6"
    />
    <new-company-corporate-pledge-form
      :company="company"
      :corporate-pledge="corporatePledge"
      :create="true"
    />
    <corporate-pledge-review-panel
      :company="company"
      :corporate-pledge="corporatePledge"
      called-from="newCompany"
      class="my-5"
    />
  </div>
</template>

<script>
import CompanyForm from "../../CompanyForm.vue";
import NewCompanyCorporatePledgeForm from "./NewCompanyCorporatePledgeForm.vue";
import CorporatePledgeReviewPanel from "../CorporatePledgeReviewPanel.vue";

export default {
  name: "NewCompanyWithCorporatePledgeForm",
  components: {
    CorporatePledgeReviewPanel,
    CompanyForm,
    NewCompanyCorporatePledgeForm,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    corporatePledge: {
      type: Object,
      required: true,
    },
  },
};
</script>
