<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-h6-mulish">
          Students Recommended By Donors <span class="font-weight-bold">Not Yet</span> Linked To
          Actual Students
        </div>

        <v-data-table
          v-model="selectedUnlinkedStudents"
          :footer-props="{ itemsPerPageOptions: [-1] }"
          :headers="unlinkedStudentsHeaders"
          :items="unlinkedStudents"
          :search="search"
          :show-select="true"
          class="mt-3"
          item-key="id"
        >
          <template v-slot:top>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  background-color="white"
                  label="Search on anything"
                  outlined
                  prepend-inner-icon="mdi-magnify"
                />
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col>
                <div
                  v-if="!selectedUnlinkedStudents.length"
                  class="mb-6"
                >
                  Select rows below to link multiple instances to a student.
                </div>
                <v-btn
                  v-else
                  class="mb-6"
                  color="primary"
                  @click="linkMultipleUnlinkedStudents"
                >Link Selected Students as One
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <span class="text-apsto-teal">
                There are no unlinked student recommendations on file for this school.
              </span>
          </template>
          <template #item.created_at="{item}">
            {{ displayDate(item.created_at) }}
          </template>
          <template #item.link="{item}">
            <v-btn icon @click="linkUnlinkedStudent(item)">
              <v-icon>
                mdi-link
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h6-mulish">Students With Recommendations</div>
        <v-data-table
          :headers="permanentStudentsHeaders"
          :items="permanentStudents"
          :single-select="true"
          class="mt-3"
          item-key="id"
          @click:row="studentAwardsPage"
        >
          <template v-slot:no-data>
              <span class="text-apsto-teal">
                There are no students with recommendations on file for this school.
              </span>
          </template>
          <template #item.created_at="{item}">
            {{ displayDate(item.created_at) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h6-mulish">Links</div>
        <v-data-table
          :headers="linkedStudentsHeaders"
          :items="linkedStudents"
          :single-select="true"
          class="mt-3"
          item-key="id"
          @click:row="unlinkStudentPage"
        >
          <template v-slot:no-data>
              <span class="text-apsto-teal">
                There are no links to actual students on file for this school.
              </span>
          </template>
          <template #item.created_at="{item}">
            {{ displayDate(item.created_at) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {SET_ADMIN_UNLINKED_STUDENTS_TO_LINK} from "../../../../../store/mutations.type";

export default {
  name: "AdminStudents",
  data() {
    return {
      loading: true,
      search: "",
      selectedUnlinkedStudents: [],
      linkedStudentsHeaders: [
        {text: 'RECOMMENDED Student', align: 'start', value: 'name'},
        {text: 'ACTUAL Student', value: 'permanent_student_name'},
      ],
      permanentStudentsHeaders: [
        {text: 'Name', align: 'start', value: 'name'},
        {text: 'Household Name', align: 'start', value: 'household_name'},
        {text: 'LAST School Year', align: 'start', value: 'school_year_name'},
      ],
      unlinkedStudentsHeaders: [
        {text: 'RECOMMENDED Student', align: 'start', value: 'name'},
        {text: 'DONOR', value: 'donor_name'},
        {text: 'Date', value: 'created_at'},
        {text: 'LINK', value: 'link'}
      ],
    }
  },
  computed: {
    linkedStudents() {
      return this.school.linked_donor_selection_students;
    },
    permanentStudents() {
      return this.school.permanent_students;
    },
    school() {
      return this.$store.getters.currentAdminSchool;
    },
    unlinkedStudents() {
      return this.school.unlinked_donor_selection_students;
    },
  },
  methods: {
    linkUnlinkedStudent(item) {
      this.$router.push(`unlinked-students/${item.id}`);
    },
    linkMultipleUnlinkedStudents() {
      let school_id = this.$route.params.school_id;
      this.$store.commit(SET_ADMIN_UNLINKED_STUDENTS_TO_LINK, this.selectedUnlinkedStudents);
      this.$router.push(`/dashboard/admin/schools/${school_id}/link-students`);
    },
    studentAwardsPage(item) {
      let school_id = this.$route.params.school_id;
      let permanent_id = item.permanent_id;
      this.$router.push(`/dashboard/admin/schools/${school_id}/linked-student-awards/${permanent_id}/dss/0`);
    },
    unlinkStudentPage(item) {
      let school_id = this.$route.params.school_id;
      let donor_selection_student_id = item.id;
      this.$router.push(`/dashboard/admin/schools/${school_id}/unlink-student/${donor_selection_student_id}`);
    },
  }
}
</script>

<style scoped>
::v-deep tbody td {
  height: 40px !important;
}
</style>