<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center">
      <v-col>
        <admin-company-header page-title="Payroll Withholding - By Calendar Year"/>
      </v-col>
      <v-spacer/>
      <v-col cols="auto">
        <v-btn
          :to="{ name: 'admin-employee-donations-upload', params: { company_id: company.id } }"
          color="primary"
          rounded
        >
          <v-icon left> mdi-plus-circle-outline</v-icon>
          Upload Payroll Withholding
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="annualEmployeeDonations"
      :single-select="true"
      class="elevation-2 mt-4"
      item-key="id"
    >
      <template #item.amount="{ item, index }">
        <div v-if="index === 0">
          {{ item.amount | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.amount | formatNumberWithCents }}
        </div>
      </template>
      <template #item.created_at="{ item }">
        {{ displayDate(item.created_at) }}
      </template>
      <template #item.fully_paid="{ item }">
        <div v-if="item.fully_paid"> Yes</div>
        <div
          v-else
          class="red--text font-weight-black"
        >No
        </div>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    No data added. Click Upload button above.
                </span>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              v-bind="attrs"
              @click="edit(item)"
              v-on="on"
            >
              mdi-view-list
            </v-icon>
          </template>
          <span>View Uploads </span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-3"
              color="blue"
              v-bind="attrs"
              @click="showEmployees(item)"
              v-on="on"
            >
              mdi-account-group
            </v-icon>
          </template>
          <span>View Employees</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import {GET_ANNUAL_EMPLOYEE_DONATIONS} from "../../../store/actions.type";
import AdminCompanyHeader from "../main/AdminCompanyHeader";

export default {
  name: "AdminCompanyAnnualDonationsList",
  components: {
    AdminCompanyHeader,
    Loading
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    company() {
      return this.$store.getters.currentUserCompany;
    },
    annualEmployeeDonations() {
      return this.$store.getters.currentAnnualEmployeeDonations;
    },
    headers() {
      return [
        {
          text: 'C Year',
          align: 'start',
          value: 'calendar_year',
        },
        {text: 'Amount', value: 'amount'},
        {text: 'LAST UPLOAD', value: 'created_at'},
        {text: 'FULLY Paid', value: 'fully_paid'},
        {text: 'Options', value: 'actions', align: 'center', sortable: false}
      ]
    },
  },
  methods: {
    edit(item) {
      this.$router.push({
        name: 'admin-company-daily-donations-list',
        params: {company_id: this.company.id, company_annual_donation_id: item.id}
      })
    },
    getAnnualEmployeeDonations() {
      let company_id = this.$route.params.company_id;
      this.$store.dispatch(GET_ANNUAL_EMPLOYEE_DONATIONS, company_id)
        .then((data) => {
          this.loading = false;
          if (data.length === 1) {
            let company_annual_donation_id = data[0].id;
            this.$router.push(
              {
                name: 'admin-company-daily-donations-list',
                params: {
                  company_id: company_id,
                  company_annual_donation_id: company_annual_donation_id
                }
              }
            );
          }
        });
    },
    showEmployees(item) {
      this.$router.push({
        name: 'admin-company-employees-list',
        params: {company_id: this.company.id, company_annual_donation_id: item.id}
      })
    },
  },
  mounted() {
    this.getAnnualEmployeeDonations();
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent !important;
}
</style>
