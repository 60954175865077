<template>
    <v-autocomplete
        id="company_id"
        v-model="localCompany"
        :background-color="backgroundColor"
        :error="!!errors['company_id']"
        :error-messages="errors['company_id'] ? errors['company_id'] : null"
        :items="companyList"
        :label="label"
        :loading="loading"
        filled
        item-text="name"
        item-value="id"
    />
</template>

<script>
import { GET_COMPANY_DROPDOWN } from "../../store/actions.type";
import { SET_COMPANY_DROPDOWN_IS_LOADING } from "../../store/mutations.type";

export default {
    name: "CompanySelector",
    props: {
        backgroundColor: {
            default: "white",
            type: String,
        },
        label: {
            default: "Company Name",
            type: String,
        },
        value: {
            type: Number,
            required: false,
        },
    },
    computed: {
        loading: {
            get() {
                return this.$store.getters.currentCompanyDropdownIsLoading;
            },
            set(value) {
                this.$store.commit(SET_COMPANY_DROPDOWN_IS_LOADING, value);
            },
        },
        companyList() {
            return this.$store.getters.currentCompanyDropdown;
        },
        error() {
            return this.$store.getters.getMessage("companies");
        },
        errors() {
            return this.$store.getters.getErrors("companies");
        },
        localCompany: {
            get() {
                return this.value;
            },
            set(localCompany) {
                this.$emit("input", localCompany);
            },
        },
    },
    methods: {
        getCompanyDropdown() {
            if (!this.loading) {
                this.loading = true;
                this.$store.dispatch(GET_COMPANY_DROPDOWN).then(() => {
                    this.loading = false;
                });
            }
        },
    },
    mounted() {
        this.getCompanyDropdown();
        this.$emit("input", this.localCompany);
    },
};
</script>
