//Views
import AdminSchoolApplicationReview from "../views/admin/schools/school/applications/AdminSchoolApplicationReview";
import AdminSchoolApplicationsList from "../views/admin/schools/other/AdminSchoolApplicationsList";
import AdminSchoolEdit from "../views/admin/schools/school/main/AdminSchoolEdit";
import AdminSchoolLinkStudent
    from "../views/admin/schools/school/students-links/unlinked-students/AdminSchoolLinkStudent";
import AdminSchoolLinkStudents
    from "../views/admin/schools/school/students-links/unlinked-students/AdminSchoolLinkStudents";
import AdminSchoolStudentList from "../views/admin/schools/school/students/AdminSchoolStudentsList";
import AdminSchoolUnlinkStudent
    from "../views/admin/schools/school/students-links/linked-students/AdminSchoolUnlinkStudent";
import AdminSchoolUnlinkedStudent
    from "../views/admin/schools/school/students-links/unlinked-students/AdminSchoolUnlinkedStudent";
import AdminSchoolsList from "../views/admin/schools/other/AdminSchoolsList";
import AdminSchoolLinkedStudentAwards
    from "../views/admin/schools/school/students-links/linked-students/AdminSchoolLinkedStudentAwards";
import AdminSchoolStudentAwardsEdit from "../views/admin/schools/school/awards/AdminSchoolStudentAwardsEdit";
import AdminSchoolsReleaseAwardOffers from "../views/admin/student-awards/AdminSchoolsReleaseAwardOffers.vue";
import AdminStudentAwardsActivityReport from "../views/admin/student-awards/AdminStudentAwardsActivityReport.vue";
import AdminStudentAwardsReportDates from "../views/admin/student-awards/AdminStudentAwardsReportDates.vue";

export default [
    {
        path: "/dashboard/admin/schools/:school_id/applications/:school_application_id/review",
        name: "admin-school-application-review",
        component: AdminSchoolApplicationReview,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/edit",
        name: "admin-school-edit",
        component: AdminSchoolEdit,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/linked-students/:donor_selection_student_id",
        name: "admin-school-linked-students",
        component: AdminSchoolUnlinkedStudent,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/linked-student-awards/:student_permanent_id/dss/:donor_selection_student_id",
        name: "admin-school-linked-student-awards",
        component: AdminSchoolLinkedStudentAwards,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/unlink-student/:donor_selection_student_id",
        name: "admin-school-unlink-student",
        component: AdminSchoolUnlinkStudent,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/unlinked-students/:donor_selection_student_id",
        name: "admin-school-unlinked-student",
        component: AdminSchoolUnlinkedStudent,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/link-student/:donor_selection_student_id",
        name: "admin-school-link-student",
        component: AdminSchoolLinkStudent,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,

        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/link-students",
        name: "admin-school-link-students",
        component: AdminSchoolLinkStudents,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools",
        name: "admin-schools-list",
        component: AdminSchoolsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal - Schools'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/students",
        name: "admin-school-students-list",
        component: AdminSchoolStudentList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/applications",
        name: "admin-school-applications-list",
        component: AdminSchoolApplicationsList,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/schools/:school_id/students/:permanent_id/edit",
        name: "school-student-awards-edit",
        component: AdminSchoolStudentAwardsEdit,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal - School'
        },
    },
    {
        path: "/dashboard/admin/student-awards/report-dates",
        name: "admin-student-awards-report-dates",
        component: AdminStudentAwardsReportDates,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Portal'
        },
    },
    {
        path: "/dashboard/admin/student-awards/activity-report/from/:date_from/thru/:date_thru",
        name: "admin-student-awards-activity-report",
        component: AdminStudentAwardsActivityReport,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            topBarTitle: 'Admin Student Awards Activity Report'
        },
    },
    {
        path: "/dashboard/admin/schools/release-awards",
        name: "admin-school-release-awards",
        component: AdminSchoolsReleaseAwardOffers,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,

        },
    },

];
