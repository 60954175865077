<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row align="center" class="ml-1 py-5">
      <v-col cols="auto" lg="6" md="8">
        <admin-header page-title="Site Documents"/>
      </v-col>
      <v-spacer/>
      <v-btn
        class="mb-2 mr-3"
        color="primaryLight"
        dark
        @click="updateSiteDocuments"
      >
        Prepare List for Upcoming Year
        <v-icon v-if="updating" right small>fas fa-spinner fa-spin</v-icon>
        <v-icon v-if="showCheckmark" right small>fas fa-check</v-icon>
      </v-btn>
      <v-btn
        class="mb-2 mr-3"
        color="primary"
        href="/api/admin/download-site-documents"
        rounded
      >
        <v-icon left style="color: white;"> mdi-download</v-icon>
        <span style="color: white;">Download This List</span>
      </v-btn>
      <v-btn
        class="mb-2 mr-3"
        color="primary"
        dark
        rounded
        @click="createItem"
      >
        Upload Document
      </v-btn>
    </v-row>
    <v-data-table
      :footer-props="{ itemsPerPageOptions:[10,20,30,-1] }"
      :headers="headers"
      :items="siteDocuments"
      :items-per-page="-1"
      :search="search"
      :single-select="true"
      class="elevation-2"
      fixed-header
      height="100vh"
      sort-by="name"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
        <v-dialog v-model="createDialog" :fullscreen="!!isMobile" :max-width="dialogMaxWidth">
          <admin-site-document-file-upload @done="createDialog = false"/>
        </v-dialog>
        <v-dialog v-model="dialogDelete" :fullscreen="!!isMobile" :max-width="dialogMaxWidth">
          <v-card>
            <v-card-title class="text-h5-mulish"> Confirm</v-card-title>
            <v-card-text
              class="pa-4 pl-6 black--text"
              style="font-size: 1.1rem"
            >
              Are you sure you want to delete this item?
            </v-card-text>
            <v-card-actions class="pt-3 pb-4">
              <v-spacer/>
              <v-btn class="cancel-button" rounded @click="dialogDelete = false">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="deleteItemConfirm">YES</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #item.current_year_file_has_been_uploaded="{ item }">
        <div v-if="item.current_year_file_has_been_uploaded">
          Yes
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :href="'/siteDocuments/' + item.current_year_id"
                color="secondary"
                icon
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-file</v-icon>
              </v-btn>
            </template>
            <span>Click Me To See {{ currentYear }} Document On File</span>
          </v-tooltip>
        </div>
      </template>
      <template #item.next_year_file_has_been_uploaded="{ item }">
        <div v-if="item.next_year_file_has_been_uploaded">
          Yes
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :href="'/siteDocuments/' + item.next_year_id"
                color="secondary"
                icon
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-file</v-icon>
              </v-btn>
            </template>
            <span>Click Me To See {{ nextYear }} Document On File</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {DELETE_SITE_DOCUMENT, GET_SITE_DOCUMENTS, UPDATE_SITE_DOCUMENTS,} from "../../../store/actions.type";
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import AdminSiteDocumentFileUpload from "./AdminSiteDocumentFileUpload";

export default {
  name: "AdminSiteDocumentsList",
  components: {
    AdminHeader,
    Loading,
    AdminSiteDocumentFileUpload
  },
  data: () => ({
    createDialog: false,
    dialogDelete: false,
    loading: true,
    temporaryItem: null,
    search: '',
    snackbar: false,
    snackbarMessage: "",
    showCheckmark: false,
    updating: false
  }),
  computed: {
    headers() {
      return [
        {text: "Name", value: "name"},
        {text: this.currentYear, value: "current_year_file_has_been_uploaded"},
        {text: this.nextYear, value: "next_year_file_has_been_uploaded"},
      ];
    },
    currentYear() {
      return new Date().getFullYear();
    },
    nextYear() {
      return new Date().getFullYear() + 1;
    },
    siteDocuments() {
      return this.$store.getters.currentSiteDocuments;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    createItem() {
      this.createDialog = true;
    },
    deleteItem(item) {
      this.temporaryItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let id = this.temporaryItem.id;
      this.$store.dispatch(DELETE_SITE_DOCUMENT, {id})
        .then(() => {
          this.dialogDelete = false;
        });
    },
    getSiteDocuments() {
      this.loading = true;
      this.$store.dispatch(GET_SITE_DOCUMENTS);
      this.loading = false;
    },
    updateSiteDocuments() {
      this.updating = true;
      this.$store.dispatch(UPDATE_SITE_DOCUMENTS).then(() => {
        const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));

        const showSpinner = () => {
          return wait(500).then(() => {
            this.updating = false;
          });
        };

        const showCheckmark = () => {
          this.showCheckmark = true;
          return wait(1500).then(() => {
            this.showCheckmark = false;
          });
        };

        async function showSaveActions() {
          await showSpinner();
          await showCheckmark();
        }

        showSaveActions();
      })
        .catch(() => {
          this.updating = false;
          this.showCheckmark = false;
        });
    }
  },
  mounted() {
    this.getSiteDocuments();
    this.loading = false;
  },
};
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}
</style>
