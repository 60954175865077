<template>
  <loading v-if="loading"/>
  <div v-else>
    <div class="ml-4 pt-4">
      <v-card-title class="pl-0 pt-0">
        <router-link :to="{ name: 'admin-menu' }">
          <span style="color: var(--apsto-teal);">Admin:</span>
        </router-link>
        <div class="ml-1 text-apsto-purple">
          Unlink Linked Student Recommendation:
        </div>
        <div class="text-apsto-teal ml-1">
          <a @click="displayDialog">
            <span class="text-apsto-teal">{{ linkedStudentName }}</span>
            <v-icon color="secondary" class="ml-1">fas fa-unlink</v-icon>
          </a>
        </div>
        <div class="ml-1 text-apsto-purple">
          From Actual Student: {{ studentName }}
        </div>
      </v-card-title>
    </div>

    <v-dialog v-model="createDialog" :max-width="dialogMaxWidth" :fullscreen="!!isMobile">
      <admin-school-unlink-student-confirm
        :student-name="studentName"
        :linked-student-name="linkedStudentName"
        @done="createDialog = false"
      />
    </v-dialog>
    <v-row center>
      <v-col md="6">
        <v-card class="mt-4 pa-5" style="min-height: 196px;">
          <div class="text-h6-mulish">
            Net Donation of {{ netDonationAmount | formatCurrencyWithCents }} on
            {{ linkedStudent.donorDonation.created_at }}
          </div>
          <v-row class="mt-0">
            <v-col>
              <table class="donation-box">
                <tbody>
                <tr>
                  <td class="tax-credit-type-name-column">
                    Original Tax Credit Scholarship:
                  </td>
                  <td>
                    {{ linkedStudent.mainDonationAllocation.original | formatCurrencyWithCents }}
                  </td>
                </tr>
                <tr>
                  <td class="tax-credit-type-name-column">
                    Switcher Tax Credit Scholarship:
                  </td>
                  <td>
                    {{ linkedStudent.mainDonationAllocation.switcher | formatCurrencyWithCents }}
                  </td>
                </tr>
                <tr>
                  <td class="tax-credit-type-name-column">
                    Charitable Contribution:
                  </td>
                  <td>
                    {{ linkedStudent.mainDonationAllocation.charitable | formatCurrencyWithCents }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="mt-2">
                {{ linkedStudent.donor.name }} ({{ linkedStudent.donor.email }}) :
                {{ linkedStudent.donor.address.street1 }}, {{ linkedStudent.donor.address.city }}
                {{ linkedStudent.donor.phone }}
                <br>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card class="mt-4 pa-5" style="min-height: 196px;">
          <div class="text-h6-mulish">{{ school.name }}</div>
          <v-row class="mt-0" align="center">
            <v-col cols="auto" class="text-h6-mulish">Primary Contact</v-col>
            <v-col>
              <a
                :href="'/impersonate/take/' + school.primary_user_id"
                class="text-apsto-teal font-weight-bold"
              >
                Impersonate
              </a>
            </v-col>
          </v-row>
          <div class="mt-2">
            {{ school.primary_user_name }}, {{ school.primary_user_title }}
            <br>
            Email:
            <a :href="'mailto:' + school.primary_user_email" target="_blank" color="primary">
              {{ school.primary_user_email }}
            </a>
            <br>
            Phone: {{ school.contact_phone }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mt-2 pa-5">
          <AdminSchoolStudentsLinksRecommendations :student-recommendations="studentRecommendations"/>
        </v-card>
        <v-card class="mt-7 pa-5">
          <AdminSchoolStudentsLinksOtherDonations
            :other-donations="otherDonations"
            :donor="linkedStudent.donor"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../../../../main/AdminHeader";
import ConfirmationDialog from "../../../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../../../components/shared/Loading";
import {
  GET_ADMIN_LINKED_STUDENT,
  GET_ADMIN_LINKED_STUDENT_NAME,
  GET_ADMIN_SCHOOL
} from "../../../../../../store/actions.type";
import AdminSchoolStudentsLinksRecommendations from "../AdminSchoolStudentsLinksRecommendations";
import AdminSchoolStudentsLinksOtherDonations from "../AdminSchoolStudentsLinksOtherDonations";
import AdminSchoolUnlinkStudentConfirm from "./AdminSchoolUnlinkStudentConfirm";

export default {
  name: "AdminSchoolUnlinkStudent",
  components: {
    AdminHeader,
    AdminSchoolStudentsLinksOtherDonations,
    AdminSchoolStudentsLinksRecommendations,
    AdminSchoolUnlinkStudentConfirm,
    ConfirmationDialog,
    Loading,
  },
  data() {
    return {
      createDialog: false,
      loading: true,
      saving: false,
    }
  },
  computed: {
    href() {
      let school_id = this.$route.params.school_id;
      let donor_selection_student_id = this.$route.params.donor_selection_student_id;
      return "/dashboard/admin/schools/" + school_id + "/link-student/" + donor_selection_student_id;
    },
    netDonationAmount() {
      return this.linkedStudent.mainDonationAllocation.charitable + this.linkedStudent.mainDonationAllocation.original + this.linkedStudent.mainDonationAllocation.switcher
    },
    school() {
      return this.$store.getters.currentAdminSchool;
    },
    otherDonations() {
      return this.linkedStudent.otherDonations;
    },
    studentRecommendations() {
      return this.linkedStudent.students;
    },
    linkedStudent() {
      return this.$store.getters.currentAdminLinkedStudent;
    },
    linkedStudentName() {
      return this.$store.getters.currentAdminLinkedStudentNameAndStudentPermanentName.name;
    },
    studentName() {
      return this.$store.getters.currentAdminLinkedStudentNameAndStudentPermanentName.student_permanent_name;
    },
  },
  methods: {
    displayDialog() {
      this.createDialog = true;
    },
    getLinkedStudent() {
      this.loading = true;
      let school_id = this.$route.params.school_id;
      let donor_selection_student_id = this.$route.params.donor_selection_student_id;
      this.$store.dispatch(GET_ADMIN_SCHOOL, school_id)
        .then(() => {
          this.$store.dispatch(GET_ADMIN_LINKED_STUDENT, {school_id, donor_selection_student_id}).then(() => {
            this.$store.dispatch(GET_ADMIN_LINKED_STUDENT_NAME, {school_id, donor_selection_student_id})
              .then(() => {
                this.loading = false;
              })
          });
        })
        .catch(() => {
        });
    },
  },
  mounted() {
    this.getLinkedStudent();
  },
}
</script>

<style scoped>

a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
  height: 60px !important;
}

::v-deep table.donation-box tbody td {
  height: unset !important;
}

::v-deep tbody td.student-permanent {
  height: unset !important;
  padding-bottom: 4px;
  padding-top: 4px;
}

::v-deep table.donation-box tbody td.tax-credit-type-name-column {
  padding-right: 20px;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep .theme--light.v-data-table.unlinked-students > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
  cursor: pointer;
}

::v-deep .v-icon {
  font-size: 24px !important;
}

</style>
