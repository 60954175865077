<template>
    <div v-if="!editMode" />
    <div v-else>
        <v-text-field
            v-model="localContent.body.path"
            @focus="lockFab"
            @change="updateContent"
            label="Forward Path"
        />
    </div>
</template>

<script>
import { PATCH_CONTENT } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from '../../../../store/mutations.type';

export default {
    name: "AuthForwarder",
    props: {
        content: {
            type: Object,
        },
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
    },
    data() {
        return {
            loading: false,
            localContent: null,
        };
    },
    created() {
        this.localContent = this.content;
    },
    mounted() {
        if (!this.isAuthenticated) return;
        if (this.editMode) return;

        this.$router.push(this.content.body.path);
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let self = this;
            let content = this.localContent;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    self.loading = false;
                })
                .catch((e) => {
                    self.loading = false;
                });
            return;
        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        },
    },
};
</script>
