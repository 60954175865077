<template>
  <div :class="isMobile ? 'mx-6 mt-n5' : 'ml-2 mt-n5'">
    <v-col md="6">
      <div class="main-header">Payroll Withholding</div>
      <p class="mt-5">
        Arizona’s withholding tax reduction law A.R.S. 43-401 allows you to redirect your state withholdings to
        create
        private school tuition scholarships for children. It counts the same as if you’d made your tax credit
        donation
        in one lump sum, but this way, you can donate smaller amounts throughout the year without affecting your
        take
        home pay.
      </p>
      <p class="mt-5">
        Please visit our <a href="/donor-withholdings-program">Withholding Program</a> page for more information.
      </p>
      <div
        class="text-h6-mulish font-weight-bold mt-5"
        style="color: var(--apsto-purple);"
      >Check With Your Employer
      </div>
      <p class="mt-5">
        Please check with your employer to see if this is a benefit they are willing to provide. Employer
        participation
        is optional but, if your employer confirms this is a program they will participate in, you can donate to
        APSTO
        through your
        Arizona state tax withholdings.
      </p>
      <p class="mt-5">
        If your employer has signed up with us, please ask them for their 6-digit employer withholding code then click
        below to get started.
      </p>
      <v-btn
        :block="!!isMobile"
        :to="{ name: 'donor' }"
        class="cancel-button mt-5 font-weight-bold"
        color="secondary"
        rounded
      >
        Back
      </v-btn>
      <v-btn
        :block="!!isMobile"
        :class="!!isMobile ? 'mt-3' : 'ml-2 mt-5'"
        :to="{ name: 'donor-donations-withholding-list' }"
        class="font-weight-bold save-by-cancel-button"
        color="primary"
        rounded
      >
        Next
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import DonorHeader from "../../../components/donors/DonorHeader.vue";

export default {
  name: "DonorWithholdingExplainer",
  components: {
    DonorHeader,
  },
  data() {
    return {
      showInfo: false,
    };
  },
  computed: {
    donor() {
      return this.$store.getters.currentDonor;
    },
    user() {
      return this.$store.getters.currentUser;
    },
  },
};
</script>

<style scoped>
.main-header {
  color: var(--apsto-purple);
  font-size: 24px;
  font-weight: bold;
  padding-left: 0;
  padding-top: 0;
}

.container-middle {
  min-height: 100px;
  padding-bottom: 10px;
}

.v-card__actions {
  padding-left: 0 !important;
}
</style>
