// Views
import HouseholdAdminInvite from "../views/households/household-admins/HouseholdAdminInvite";
import HouseholdAdminsList from "../views/households/household-admins/HouseholdAdminsList";

export default [
    {
        path: "/dashboard/households/:household_id/admins",
        name: "household-admins-list",
        component: HouseholdAdminsList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
    {
        path: "/dashboard/households/:household_id/admins/invite",
        name: "household-admin-invite",
        component: HouseholdAdminInvite,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Applicant Portal'
        },
    },
]

