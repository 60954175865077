import { render, staticRenderFns } from "./SchoolTuitionTable.vue?vue&type=template&id=f84183a8&scoped=true&"
import script from "./SchoolTuitionTable.vue?vue&type=script&lang=js&"
export * from "./SchoolTuitionTable.vue?vue&type=script&lang=js&"
import style0 from "./SchoolTuitionTable.vue?vue&type=style&index=0&id=f84183a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f84183a8",
  null
  
)

export default component.exports