<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col
        cols="auto"
        lg="6"
        md="8"
      >
        <admin-header page-title="Wallpapers"/>
      </v-col>
      <v-spacer/>
      <v-btn
        class="mb-2 mr-3"
        color="primary"
        dark
        @click="createItem"
      >
        Add New Wallpaper
      </v-btn>
      <v-btn
        class="mb-2 mr-3"
        color="primary"
        dark
        @click="uploadItem"
      >
        Upload Wallpaper
      </v-btn>
    </v-row>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
      :headers="headers"
      :items="siteWallpapers"
      :items-per-page="-1"
      :search="search"
      :single-select="true"
      class="elevation-2"
      fixed-header
      height="100vh"
      sort-by="name"
    >
      <template v-slot:top>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
        <v-dialog
          v-model="createDialog"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <admin-site-wallpaper-create @done="createDialog = false"/>
        </v-dialog>
        <v-dialog
          v-model="uploadDialog"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <admin-site-wallpaper-file-upload @done="uploadDialog = false"/>
        </v-dialog>
      </template>
      <template #item.active="{ item }">
        <v-switch
          v-model="item.active"
          @change="saveStatus(item)"
        />
      </template>
      <template #item.file_has_been_uploaded="{ item }">
        <div v-if="item.file_has_been_uploaded">
          <v-tooltip
            bottom
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :href="'/siteWallpapers/' + item.id"
                color="secondary"
                icon
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  :src="'/website/uploads/thumbwall/' + item.hash"
                  contain
                  height="100"
                  width="100"
                />
              </v-btn>
            </template>
            <span>Click To Download This Wallpaper</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          color="red"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDelete"
      :fullscreen="!!isMobile"
      :max-width="dialogMaxWidth"
    >
      <v-card>
        <v-card-title class="text-h5-mulish"> Confirm</v-card-title>
        <v-card-text
          class="pa-4 pl-6 black--text"
          style="font-size: 1.1rem"
        >
          Are you sure you want to delete this item?
        </v-card-text>
        <v-card-actions class="pt-3 pb-4">
          <v-spacer/>
          <v-btn
            class="cancel-button"
            rounded
            @click="dialogDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="deleteItemConfirm"
          >YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      centered
    >
      {{ snackbarMessage }}
      <div
        v-for="(household, index) in snackbarHouseholds"
        v-bind:key="index"
        class="mt-2"
      >
        {{ household }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {DELETE_SITE_WALLPAPER, GET_SITE_WALLPAPERS, PATCH_SITE_WALLPAPER_STATUS,} from "../../../store/actions.type";
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import AdminSiteWallpaperFileUpload from "./AdminSiteWallpaperFileUpload";
import AdminSiteWallpaperCreate from "./AdminSiteWallpaperCreate";

export default {
  name: "AdminSiteWallpapersList",
  components: {
    AdminHeader,
    Loading,
    AdminSiteWallpaperCreate,
    AdminSiteWallpaperFileUpload
  },
  data: () => ({
    cancel: false,
    createDialog: false,
    dialogDelete: false,
    uploadDialog: false,
    loading: true,
    temporaryItem: null,
    search: '',
    snackbar: false,
    snackbarHouseholds: [],
    snackbarMessage: "",
    snackbarTimeout: -1
  }),
  computed: {
    headers() {
      return [
        {text: "NAME", value: "name"},
        {text: "SWATCH", value: "file_has_been_uploaded"},
        {text: "ACTIVE", value: 'active'},
        {text: "ACTIONS", value: "actions", sortable: false},
      ];
    },
    currentYear() {
      return new Date().getFullYear();
    },
    nextYear() {
      return new Date().getFullYear() + 1;
    },
    saveDisabled() {
      return !this.slug || !this.file;
    },
    siteWallpapers() {
      return this.$store.getters.currentSiteWallpapers;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    closeDeleteSiteWallpaper() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    createItem() {
      this.createDialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.siteWallpapers.indexOf(item);
      this.temporaryItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.destroySiteWallpaper();
      this.siteWallpapers.splice(this.editedIndex, 1);
      this.closeDeleteSiteWallpaper();
    },
    destroySiteWallpaper() {
      this.saving = true;
      let id = this.temporaryItem.id;
      this.$store.dispatch(DELETE_SITE_WALLPAPER, id)
        .then(() => {
          this.dialogDelete = false;
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;
          this.snackbarHouseholds = data.households;
          this.snackbar = true;
        });
    },
    getSiteWallpapers() {
      this.loading = true;
      let called_from = "admin";
      this.$store.dispatch(GET_SITE_WALLPAPERS, called_from);
      this.loading = false;
    },
    saveStatus(item) {
      let site_wallpaper_id = item.id;
      this.$store.dispatch(PATCH_SITE_WALLPAPER_STATUS, site_wallpaper_id);
    },
    uploadItem() {
      this.uploadDialog = true;
    },
  },
  mounted() {
    this.getSiteWallpapers();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDeleteSiteWallpaper();
    },
  },
};
</script>

<style scoped>
::v-deep tbody tr {
  cursor: pointer;
  height: 120px;
}
</style>
