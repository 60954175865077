<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-radio-group
            v-model="showPriorDonations"
            column
        >
            <template v-slot:label>
                <div
                    class="pb-2 font-weight-black"
                    style="font-size: 1.125rem; line-height: 1.5"
                >
                    Have you previously donated to another STO for the
                    <span class="text-apsto-teal">{{ selectedTaxYear }}</span>
                    tax year?
                </div>
            </template>
            <v-radio
                :value="true"
                color="secondary"
                label="Yes"
            ></v-radio>
            <v-radio
                :value="false"
                color="secondary"
                label="No"
            ></v-radio>
        </v-radio-group>

        <div v-if="showPriorDonations">
            <div
                class="mb-4"
                style="font-size: 1.125rem"
            >
                Please add any donations you made for the
                <span class="text-apsto-teal">
                    {{ selectedTaxYear }}
                </span>
                tax year
                <span class="text-apsto-teal">
                    other than through APSTO.
                </span>
            </div>
            <prior-donations-table
                :admin-creating-donor-donation="adminCreatingDonorDonation"
                :email="email"
                :selected-tax-year="selectedTaxYear"
            />
        </div>
    </div>
</template>

<script>
import Loading from "../shared/Loading.vue";
import PriorDonationsTable from "./PriorDonationsTable";
import {GET_DONOR_PRIOR_DONATIONS} from "../../store/actions.type";

export default {
    name: "PriorDonations",
    components: {Loading, PriorDonationsTable},
    props: {
        adminCreatingDonorDonation: {
            default: false,
            required: false,
        },
        email: {
            type: String,
            required: true
        },
        selectedTaxYear: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            showPriorDonations: false,
        };
    },
    computed: {
        donorPriorDonations() {
            return this.$store.getters.currentDonorPriorDonations(this.selectedTaxYear);
        },
        taxYearFactors() {
            return this.$store.getters.taxYearFactors;
        },
        error() {
            return this.$store.getters.getMessage("donors");
        },
        errors() {
            return this.$store.getters.getErrors("donors");
        },
    },
    methods: {
        getDonorPriorDonations() {
            this.loading = true;
            let email = this.email ? this.email : 'missing';
            let adminCreatingDonorDonation = this.adminCreatingDonorDonation ? 1 : 0;
            this.$store.dispatch(GET_DONOR_PRIOR_DONATIONS, {email, adminCreatingDonorDonation}).then(() => {
                this.loading = false;
                this.show();
            });
        },
        show() {
            this.showPriorDonations = !!(this.donorPriorDonations && this.donorPriorDonations.length);
        },
    },
    mounted() {
        this.getDonorPriorDonations();
    }
};
</script>

<style scoped></style>
