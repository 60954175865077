import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    DELETE_DONOR_PRIOR_DONATION,
    DELETE_DONOR_PRIOR_DONATIONS,
    DELETE_DONOR_SUBSCRIPTION_DONATION,
    DELETE_DONOR_WITHHOLDING_DONATION,
    EMAIL_DONOR_ANNUAL_RECEIPT,
    EMAIL_DONOR_DONATION,
    EMAIL_DONOR_SUBSCRIPTION_DONATION,
    EMAIL_DONOR_WITHHOLDING_DONATION,
    GET_CURRENT_DONOR,
    GET_DONOR_DONATION_TAX_YEAR_FACTORS,
    GET_DONOR_DONATIONS,
    GET_DONOR_PRIOR_DONATIONS,
    GET_DONOR_SELECTION_WITH_FILE,
    GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES,
    GET_DONOR_SUBSCRIPTION_DONATIONS,
    GET_DONOR_WITHHOLDING_DONATIONS,
    GET_LAST_TWO_YEARS_DONATIONS_HISTORY,
    GET_SELECTED_USER,
    PATCH_CURRENT_DONOR,
    PATCH_DONOR_PRIOR_DONATION,
    POST_DONOR_DONATION,
    POST_DONOR_PRIOR_DONATION,
    POST_DONOR_SUBSCRIPTION_DONATION_SETUP,
    POST_DONOR_WITHHOLDING_DONATION_SETUP,
    POST_DONOR_WITHHOLDING_FORM_UPLOAD
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    APPEND_DONOR_PRIOR_DONATION,
    APPEND_DONOR_RECOMMENDATION,
    APPEND_DONOR_STUDENT_RECOMMENDATION,
    CLEAR_ERRORS,
    COPY_DONOR_STUDENT_RECOMMENDATIONS,
    INCREMENT_RECOMMENDATION_STUDENT_ID,
    REMOVE_DONOR_PRIOR_DONATION,
    REMOVE_DONOR_STUDENT_RECOMMENDATION,
    REMOVE_DONOR_STUDENT_RECOMMENDATIONS,
    SET_DONATION,
    SET_DONOR,
    SET_DONOR_DONATION_TAX_YEAR_FACTORS,
    SET_DONOR_DONATIONS,
    SET_DONOR_PRIOR_DONATIONS,
    SET_DONOR_SELECTION_WITH_FILE,
    SET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES,
    SET_DONOR_SUBSCRIPTION_DONATIONS,
    SET_DONOR_WITHHOLDING_DONATIONS,
    SET_ERROR,
    SET_LAST_TWO_YEARS_DONATIONS_HISTORY,
    SET_SELECTED_USER,
    UPDATE_DONOR_STUDENT_RECOMMENDATION
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        donor: {
            address: {
                city: '',
                postal: '',
                region_id: 3,
                street1: '',
                street2: '',

            }
        },
        donation: {
            agree_to_terms: false,
            amount: 0,
            priorDonations: [],
            recommendations: {
                original: {
                    comments: null,
                    school_id: null,
                    students: [],
                },
                switcher: {
                    comments: null,
                    school_id: null,
                    students: [],
                },
            },
            tax_filing_status: "joint",
            tax_year: "current",
        },
        donorDonations: [],
        donorDonationsHistory: [],
        donorSubscriptionDonations: [],
        donorSelectionWithFile: {},
        donorWithholdingDonations: [],
        donorPriorDonations: [],
        recommendationsCount: 0,
        selectedUser: {},
        taxYearFactors: {},

    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentDonation(state) {
        return state.donation;
    },
    currentDonations(state) {
        return state.donorDonations;
    },
    currentDonor(state) {
        return state.donor;
    },
    currentDonorSelectionWithFile(state) {
        return state.donorSelectionWithFile;
    },
    currentRecommendationsCount(state) {
        return state.recommendationsCount;
    },
    currentSubscriptionDonations(state) {
        return state.donorSubscriptionDonations;
    },
    currentWithholdingDonations(state) {
        return state.donorWithholdingDonations;
    },
    currentActiveSubscriptionDonationsFiltered: (state) => (taxYear) => {
        return state.donorSubscriptionDonations ? state.donorSubscriptionDonations
            .filter(subscription => subscription.tax_year === parseInt(taxYear) && subscription.subscription_charges_authorized > subscription.subscription_charges_processed) : [];
    },
    currentActiveWithholdingDonationsFiltered: (state) => (taxYear) => {
        return state.donorWithholdingDonations ? state.donorWithholdingDonations
            .filter(withholding => withholding.tax_year === parseInt(taxYear) && withholding.withholding_charges_authorized > withholding.withholding_charges_processed) : [];
    },
    currentDonorPriorDonations: (state) => (taxYear) => {
        return state.donorPriorDonations ? state.donorPriorDonations.filter(priorDonation => priorDonation.tax_year === parseInt(taxYear)) : [];
    },
    currentDonorRecommendationStudents: (state) => (taxCreditType) => {
        return state.donation.recommendations[taxCreditType].students;
    },
    currentTaxYearFactors(state) {
        return state.taxYearFactors;
    },
    currentDonorTwoYearDonationsHistory(state) {
        return state.donorDonationsHistory;
    },
    currentSelectedUser(state) {
        return state.selectedUser;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [EMAIL_DONOR_DONATION](context, donation_id) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/donations/${donation_id}/email-pdf`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [EMAIL_DONOR_SUBSCRIPTION_DONATION](context, donor_selection_id) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/subscriptions/${donor_selection_id}/email-pdf`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_CURRENT_DONOR](context) {
        console.log("GET_CURRENT_DONOR");
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_SELECTED_USER](context, user_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/users/${user_id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SELECTED_USER, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_DONOR_DONATION](context, donation) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/donations/`, donation)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_DONOR_DONATIONS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/donations`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_DONOR_SUBSCRIPTION_DONATION_SETUP](context, donation) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/subscriptions`, donation)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_DONOR_SUBSCRIPTION_DONATIONS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/subscriptions`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_SUBSCRIPTION_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_DONOR_PRIOR_DONATIONS](context, {email, adminCreatingDonorDonation}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/donations/prior/email/${email}/admin/${adminCreatingDonorDonation}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_PRIOR_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_DONOR_DONATION_TAX_YEAR_FACTORS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/tax-year`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_DONATION_TAX_YEAR_FACTORS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_CURRENT_DONOR](context, donor) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/donors/${donor.id}`, donor)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_DONOR_PRIOR_DONATION](context, priorDonation) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/donors/donations/prior`, priorDonation)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_DONOR_PRIOR_DONATION](context, priorDonation) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/donations/prior`, priorDonation)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_DONOR_PRIOR_DONATION, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_DONOR_PRIOR_DONATION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/donors/donations/prior/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_DONOR_PRIOR_DONATION, id);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_DONOR_PRIOR_DONATIONS](context, {tax_year_to_delete, email}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/donations/prior/destroy-collection/${tax_year_to_delete}/${email}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_DONOR_SUBSCRIPTION_DONATION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/donors/subscriptions/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_SUBSCRIPTION_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [EMAIL_DONOR_WITHHOLDING_DONATION](context, donor_selection_id) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/withholdings/${donor_selection_id}/email-pdf`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_DONOR_WITHHOLDING_DONATION_SETUP](context, donation) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/withholdings`, donation)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_DONOR_WITHHOLDING_FORM_UPLOAD](context, {
        donor_selection_id,
        file
    }) {
        const formData = new FormData()
        formData.append('file', file);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/donors/withholdings/${donor_selection_id}/upload`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'donors');
                    reject(error.response);
                });
        });
    },
    [GET_DONOR_SELECTION_WITH_FILE](context, donor_selection_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/withholdings/${donor_selection_id}/document`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_SELECTION_WITH_FILE, data);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'donors');
                    reject(error.response);
                });
        });
    },
    [GET_DONOR_WITHHOLDING_DONATIONS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/withholdings`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_WITHHOLDING_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_DONOR_WITHHOLDING_DONATION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/donors/withholdings/${id}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_WITHHOLDING_DONATIONS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_LAST_TWO_YEARS_DONATIONS_HISTORY](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/last-two-years-donations-history`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_LAST_TWO_YEARS_DONATIONS_HISTORY, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [EMAIL_DONOR_ANNUAL_RECEIPT](context, taxYear) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/email-annual-donations-receipt/${taxYear}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES](context, promo_id) {

        console.log("PROMO ID: ", promo_id)

        return new Promise((resolve, reject) => {
            ApiService.get(`/api/donors/recommended-students-from-promo-pages/${promo_id}`)
                .then(({data}) => {
                    console.log("GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES");
                    console.log(data);
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES, data);
                    resolve(data);
                })
                .catch((e) => {
                    console.log(e);
                    let response = e.response;
                    console.log("GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES_RESPONSE: ", response)
                    context.commit(SET_ERROR, {
                        target: "donors",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },

};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_DONOR](state, data) {
        state.donor = data;
    },
    [SET_SELECTED_USER](state, data) {
        state.selectedUser = data;
    },
    [SET_DONOR_DONATIONS](state, data) {
        state.donorDonations = data;
    },
    [SET_DONOR_SUBSCRIPTION_DONATIONS](state, data) {
        state.donorSubscriptionDonations = data;
    },
    [SET_DONOR_WITHHOLDING_DONATIONS](state, data) {
        state.donorWithholdingDonations = data;
    },
    [SET_DONOR_DONATION_TAX_YEAR_FACTORS](state, data) {
        state.taxYearFactors = data;
    },
    [SET_DONOR_PRIOR_DONATIONS](state, data) {
        state.donorPriorDonations = data;
    },
    [APPEND_DONOR_PRIOR_DONATION](state, data) {
        state.donorPriorDonations.push(data);
    },
    [REMOVE_DONOR_PRIOR_DONATION](state, id) {
        let list = state.donorPriorDonations;
        for (var i = 0; i < list.length; i++) {
            if (list[i].id === id) {
                list.splice(i, 1);
            }
        }
    },
    [SET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES](state, recommendations) {
        if (state.donation.recommendations.original && state.donation.recommendations.switcher) {
            state.donation.recommendations.original.students = [...recommendations];
            state.donation.recommendations.switcher.students = [...recommendations];
        } else {
            state.donation.recommendations.charitable.students = [...recommendations];
        }
        state.recommendationsCount = recommendations.length;
    },
    [COPY_DONOR_STUDENT_RECOMMENDATIONS](state, originalRecommendations) {
        state.donation.recommendations.switcher.students = [...originalRecommendations];
    },
    [APPEND_DONOR_STUDENT_RECOMMENDATION](state, {taxCreditType, student}) {
        state.donation.recommendations[taxCreditType].students.push(student);
    },
    [INCREMENT_RECOMMENDATION_STUDENT_ID](state) {
        state.recommendationsCount++;
    },
    [UPDATE_DONOR_STUDENT_RECOMMENDATION](state, {taxCreditType, student}) {
        let list = state.donation.recommendations[taxCreditType].students;
        for (let item = 0; item < list.length; item++) {
            if (list[item].id === student.id) {
                list.splice(item, 1, student);
            }
        }
    },
    [APPEND_DONOR_RECOMMENDATION](state, {taxCreditType, field_name, value}) {
        state.donation.recommendations[taxCreditType][field_name] = value;
    },
    [REMOVE_DONOR_STUDENT_RECOMMENDATIONS](state, taxCreditType) {
        state.donation.recommendations[taxCreditType].students = [];
    },
    [REMOVE_DONOR_STUDENT_RECOMMENDATION](state, {taxCreditType, id}) {
        let list = state.donation.recommendations[taxCreditType].students;
        for (let item = 0; item < list.length; item++) {
            if (list[item].id === id) {
                list.splice(item, 1);
            }
        }
    },
    [SET_DONATION](state, data) {
        state.donation = data;
    },
    [SET_LAST_TWO_YEARS_DONATIONS_HISTORY](state, data) {
        state.donorDonationsHistory = data;
    },
    [SET_DONOR_SELECTION_WITH_FILE](state, data) {
        state.donorSelectionWithFile = data;
    },


};

export default {
    getters,
    actions,
    mutations,
    state,
};
