<template>
  <v-card class="pa-4">
    <v-row>
      <v-col v-if="calledFromClient">
        <div class="text-h7-mulish">
          We haven't received payment for the last payroll you uploaded. Please send payment for that payroll
          before
          uploading a new file.
          <div class="mt-4">
            If you didn't intend to upload that payroll, click Cancel then click on that payroll then delete it.
          </div>
        </div>
        <v-card-actions class="mt-3">
          <v-btn
            :block="isMobile"
            class="cancel-button ml-n2"
            rounded
            @click="cancel"
          >Cancel
          </v-btn>
          <div v-if="isMobile"></div>
          <v-btn
            :block="isMobile"
            class="ml-sm-2"
            color="primary"
            rounded
            @click="contact"
          >
            I already sent payment. Please check again.
          </v-btn>
        </v-card-actions>
        <v-snackbar
          v-model="snackbar"
          centered
        >
          Thank you. We will contact you shortly.
          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
      <v-col v-else>
        This client has unpaid payroll withholdings.
        <div class="mt-4">
          Please record payment for that payroll before uploading a new file or delete that payroll if it's
          incorrect then upload a new file.
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {POST_CONTACT_USER_FROM} from "../../../store/actions.type";

export default {
  name: "UnpaidEmployeeDonations",
  props: {
    calledFromClient: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      company: this.$store.getters.currentUserCompany,
      snackbar: false,
      user: this.$store.getters.currentUser,
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1)
    },
    contact() {
      let contact_type_enum = 3; // Payroll Withholding
      let email = this.user.email;
      let name = this.user.name;
      let message = "I clicked the Contact Me button to contact me about payment for payroll withholding. We already sent it. I'm with " + this.company.name + " and our phone number is " + this.company.phone + ".";
      let subject = "Payroll Withholding Payment"
      this.$store.dispatch(POST_CONTACT_USER_FROM, {contact_type_enum, email, message, name, subject}).then(() => {
        this.snackbar = true;
      });
    },
  }
}

</script>

<style scoped></style>
