import { render, staticRenderFns } from "./SchoolHeader.vue?vue&type=template&id=13c622d3&scoped=true&"
import script from "./SchoolHeader.vue?vue&type=script&lang=js&"
export * from "./SchoolHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c622d3",
  null
  
)

export default component.exports