import ApiService from "../api/api.service";
import JwtService from "../common/jwt.service";
import Vue from "vue";
import router from '../router'
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    CHECK_AUTH,
    CHECK_REGISTERED,
    CONFIRM_CHANGE_USERNAME,
    LOGIN,
    LOGOUT,
    PATCH_USER,
    REGISTER,
    EMAIL_PASSWORD_RESET,
    PASSWORD_RESET,
    SEND_CHANGE_USERNAME_EMAIL,
    SEND_SIGN_IN_EMAIL_FROM_CHARITABLE_DONATION,
    SEND_SIGN_IN_EMAIL_FROM_DONATION,
    SEND_SIGN_IN_EMAIL_FROM_DONATION_PAGE,
    SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE,
    SIGN_IN_FROM_LINK,
    RECORD_ACCEPTANCE_OF_OFFER_TO_REMEMBER_USER,
    GET_USER_ADDRESS, CHECK_EMAILS_FOR_DATA,
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    RESET_AUTH,
    SET_AUTH,
    SET_ERROR,
    SET_IDLE_TIMER, SET_USER_ADDRESS,
    SET_CHECK_DATA_BY_EMAIL_FORM
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        token: null,
        userId: null,
        user: null,
        userAddress: null,
        roles: [],
        idleTime: null,
        idleTimer: null,
        isAuthenticated: !!JwtService.getToken(),
        impersonating: false,
        showCheckDataByEmailForm: false,

    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentUser(state) {
        return state.user;
    },
    currentUserAddress(state) {
        return state.userAddress;
    },
    currentRoles(state) {
        return state.roles;
    },
    hasRole(state) {
        return keyword => {
            return state.roles.includes(keyword);
        }
    },
    isAdmin(state) {
        return state.roles.includes('site-admin') || state.roles.includes('super-admin') || state.roles.includes('site-admin-1');
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    isImpersonating(state) {
        return state.impersonating;
    },
    isShowingCheckDataByEmailForm(state) {
        return state.showCheckDataByEmailForm;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [LOGIN](context, credentials) {
        console.log("LOGIN");
        return new Promise((resolve, reject) => {
            Vue.axios.get('/sanctum/csrf-cookie').then(() => {
                Vue.axios.post("/login", credentials)
                    .then(({data}) => {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_AUTH, {
                                userId: data.user.id,
                                user: data.user,
                                token: data.token,
                                roles: data.roles,
                            }
                        );
                        context.dispatch(CHECK_AUTH);
                        resolve(data);
                    })
                    .catch(({response}) => {
                        context.commit(
                            SET_ERROR, {
                                target: 'login',
                                message: response.data.message,
                                errors: response.data.errors ?? []
                            }
                        );
                        reject(response);
                    });
            })
        });
    },
    [LOGOUT](context) {
        console.log("LOGOUT");
        return new Promise((resolve, reject) => {
            ApiService.get("/logout")
                .then(({
                           data
                       }) => {
                    context.commit(RESET_AUTH);
                    ApiService.resetHeader();
                    localStorage.clear();
                    window.localStorage.clear();
                    window.location.href = '/';
                    resolve(data);
                })
                .catch(({
                            response
                        }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'logout',
                            message: response.data.message,
                            errors: response.data.errors ?? []
                        }
                    );
                    reject(response);
                });
        });
    },
    [REGISTER](context, credentials) {
        console.log("REGISTER");
        return new Promise((resolve, reject) => {
            ApiService.post("/register", credentials)
                .then(({
                           data
                       }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_AUTH, {
                        userId: data.user.id,
                        user: data.user,
                        token: data.api_token,
                        roles: data.roles,
                    });
                    context.dispatch(CHECK_AUTH);
                    router.push('/');
                    resolve(data);
                })
                .catch(({
                            response
                        }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'register',
                            message: response.data.message,
                            errors: response.data.errors ?? []
                        }
                    );
                    reject(response);
                });
        });
    },
    [EMAIL_PASSWORD_RESET](context, email) {
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.post("/forgot-password", {
                    email: email
                })
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'reset',
                            message: response.data.message,
                            errors: response.data.errors ?? []
                        }
                    );
                    reject(response);
                });
        });
    },
    [PASSWORD_RESET](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("/reset-password", credentials)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'reset',
                            message: response.data.message,
                            errors: response.data.errors ?? []
                        }
                    );
                    reject(response);
                });
        });
    },
    [CHECK_AUTH](context) {
        console.log("CHECK_AUTH");
        return new Promise((resolve, reject) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("/validate")
                    .then(({
                               data
                           }) => {
                        context.commit(SET_IDLE_TIMER, data.expiry);
                        context.commit(
                            SET_AUTH, {
                                userId: data.user.id,
                                user: data.user,
                                roles: data.roles,
                                impersonating: data.impersonating
                            }
                        );
                        resolve(data);
                    })
                    .catch(({
                                response
                            }) => {
                        context.commit(RESET_AUTH);
                        reject(response);
                    });
            } else {
                context.commit(RESET_AUTH);
                resolve("ok");
            }
        });
    },
    [PATCH_USER](context, user) {
        console.log("PATCH_USER", user);
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/users/`, user)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.dispatch(CHECK_AUTH);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "users", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [CHECK_REGISTERED](context, email) {
        console.log("CHECK_REGISTERED");
        const formData = new FormData();
        formData.append('email', email);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/users/check-registered`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [SEND_SIGN_IN_EMAIL_FROM_CHARITABLE_DONATION](context, email) {
        console.log("SEND_SIGN_IN_EMAIL_FROM_CHARITABLE_DONATION");
        const formData = new FormData();
        formData.append("email", email);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/users/send-sign-in-email-from-charitable-donation`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "users", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [SEND_SIGN_IN_EMAIL_FROM_DONATION](context, email) {
        console.log("SEND_SIGN_IN_EMAIL_FROM_DONATION");
        const formData = new FormData();
        formData.append("email", email);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/users/send-sign-in-email-from-donation`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "users", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [SEND_SIGN_IN_EMAIL_FROM_DONATION_PAGE](context, email) {
        console.log("SEND_SIGN_IN_EMAIL_FROM_DONATION_PAGE");
        const formData = new FormData();
        formData.append("email", email);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/users/send-sign-in-email-from-donation-page`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "users", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE](context, email) {
        console.log("SEND_SIGN_IN_EMAIL_FROM_LOGIN_PAGE");
        const formData = new FormData();
        formData.append("email", email);

        return new Promise((resolve, reject) => {
            ApiService.post(`/api/users/send-sign-in-email-from-login-page`, formData)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "users", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [RECORD_ACCEPTANCE_OF_OFFER_TO_REMEMBER_USER](context, email) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/donors/record-acceptance-of-offer-to-remember-user/${email}`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch((error) => {
                    handleError(context, error, 'users');
                    reject(error.response);
                });
        });
    },
    [SIGN_IN_FROM_LINK](context, credentials) {
        console.log("SIGN_IN_FROM_LINK");
        return new Promise((resolve, reject) => {
            Vue.axios.post('/sign-in-from-link', null, {
                params: {
                    calledFrom: credentials.calledFrom,
                    email: credentials.email,
                    expires: credentials.expires,
                    user: credentials.user,
                    signature: credentials.signature
                }
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_AUTH, {
                            userId: data.user.id,
                            user: data.user,
                            token: data.token,
                            roles: data.roles,
                        }
                    );
                    context.dispatch(CHECK_AUTH);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'login',
                            message: response.data.message,
                            errors: response.data.errors ?? []
                        }
                    );
                    reject(response);
                });
        });
    },
    [SEND_CHANGE_USERNAME_EMAIL](context, email) {
        console.log("SEND_CHANGE_USERNAME_EMAIL");
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/users/send-change-username-email`, email)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "users", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [CONFIRM_CHANGE_USERNAME](context, credentials) {
        console.log("CONFIRM_CHANGE_USERNAME");
        return new Promise((resolve, reject) => {
            Vue.axios.post('/change-username', null, {
                params: {
                    email: credentials.email,
                    expires: credentials.expires,
                    user: credentials.user,
                    signature: credentials.signature
                }
            })
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_AUTH, {
                            userId: data.user.id,
                            user: data.user,
                            token: data.token,
                            roles: data.roles,
                        }
                    );
                    context.dispatch(CHECK_AUTH);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'users',
                            message: response.data.message,
                            errors: response.data.errors ?? []
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_USER_ADDRESS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/users/address`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_USER_ADDRESS, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "users",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [CHECK_EMAILS_FOR_DATA](context, emails) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/users/check-email-for-data`, emails)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "donors", message: response.data.message, errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },

};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_AUTH](state, data) {
        console.log("SET_AUTH");
        state.isAuthenticated = true;
        state.userId = data.userId;
        state.user = data.user;
        state.impersonating = data.impersonating;
        !!state.token ? state.token = data.token : null;
        state.roles = data.roles;
        state.errors = getDefaultState().errors;
        JwtService.setToken(data.token);
    },
    [RESET_AUTH](state) {
        console.log("RESET_AUTH");
        JwtService.unsetToken();
        Object.assign(state, getDefaultState());
    },
    [SET_IDLE_TIMER](state, time) {
        // console.log("SET_IDLE_TIMER");
        // state.idleTime = time*1000; //Convert to milliseconds
        // clearInterval(state.idleTimer);
        // state.idleTimer = setInterval(() => {
        //     console.log("Running Session Timer");
        //     state.idleTime -= 1000;
        //     if (state.idleTime < 1) {
        //         console.log("SESSION EXPIRED");
        //         clearInterval(state.idleTimer);
        //         JwtService.unsetToken();
        //         Object.assign(state, getDefaultState());
        //         router.push('/');
        //         alert('Your session has expired.');
        //     }
        // }, 1000);
    },
    [SET_USER_ADDRESS](state, data) {
        state.userAddress = data;
    },
    [SET_CHECK_DATA_BY_EMAIL_FORM](state, data) {
        state.showCheckDataByEmailForm = data;
    }
};

export default {
    getters,
    actions,
    mutations,
    state
}
