<template>
    <v-row class="mt-2 outlined mx-0">
        <v-col
            class="pr-0"
            cols="auto"
        ><v-icon
                x-large
                color="primary"
            >mdi-information</v-icon></v-col>
        <v-col cols="auto"><v-divider
                color="primary"
                vertical
            /></v-col>
        <v-col>
            <div
                cols="auto"
                v-if="!editMode"
                v-html="content.body.text"
            />
            <wysiwyg-editor
                v-else
                v-model="localContent.body.text"
                :disabled="loading"
                @update="updateContent"
            />
        </v-col>
    </v-row>
</template>

<script>
import WysiwygEditor from "../WysiwygEditor";

import { PATCH_CONTENT } from "../../../../store/actions.type";
import { SET_FAB_ENABLED } from '../../../../store/mutations.type';

export default {
    name: "Infobox",
    props: {
        content: {
            type: Object,
        },
    },
    components: {
        WysiwygEditor,
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
    },
    data() {
        return {
            loading: false,
            localContent: null,
        };
    },
    created() {
        this.localContent = this.content;
    },
    methods: {
        updateContent() {
            console.log("updateContent");

            this.loading = true;
            let content = this.content;
            content = this.localContent;
            this.$store
                .dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.loading = false;
                    this.$store.commit(SET_FAB_ENABLED, true);
                })
                .catch((e) => {
                    this.loading = false;
                });
            return;
        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        }
    },
};
</script>

<style scoped>::v-deep img {
    width: 100%;
}

.outlined {
    border: 1px solid var(--apsto-purple);
}</style>
