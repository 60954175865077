<template>
    <v-row class="mb-1">
        <v-col v-if="!editMode">
            <h2 class="font-weight-bold secondary--text">
                {{ text }}
            </h2>
        </v-col>
        <v-col v-else>
            <v-text-field
                label="Enter Heading"
                :loading="loading"
                class="font-weight-black secondary--text"
                :value="text"
                @change="updateContent"
            />
        </v-col>
    </v-row>
</template>

<script>
import { PATCH_CONTENT } from '../../../../store/actions.type';

export default {
    name: "Heading",
    props: {
        content: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
        text() {
            return this.content.title;
        },
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let content = this.content;
            content.title = value;
            this.$store.dispatch(PATCH_CONTENT, content).then(() => {
                this.loading = false;
            }).catch((e) => {
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>
::v-deep input {
    color: #55a9ad !important;
    caret-color: #55a9ad !important;
    font-weight: 700 !important;
    font-family: var(--font-TITLE) !important;
    font-size: 1.5em;
}
</style>
