<template>
  <v-card class="pa-4">
    <v-card-title class="pa-0">File Upload</v-card-title>
    <v-row>
      <v-col>
        <div class="mt-3">
          Please
          <a :href="href">
                        <span class="text-apsto-teal">
                            click here
                        </span>
          </a>
          to download a list of employees participating in this program during the dates you entered above. You
          can use this list to upload your payroll withholdings or you can use an earlier list as long as it
          follows this format.
        </div>
        <v-file-input
          v-model="file"
          :error="!!errors.file"
          :error-messages="errors.file ? errors.file : null"
          :rules="rules"
          accept=".csv,.xls,.xlsx"
          background-color="white"
          class="mt-5"
          label="Select your payroll withholdings file"
          outlined
          show-size
          small-chips
          style="border-radius: 0"
          truncate-length="15"
          @focus="fileFocus"
        />

        <v-btn
          :disabled="!showUploadButton"
          color="primary"
          rounded
          style="min-width: 140px;"
          @click="saveFile"
        >
          Upload
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {POST_EMPLOYEE_DONATIONS_FILE_UPLOAD} from "../../../store/actions.type";
import {
  CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE,
  INCREMENT_RECOMMENDATION_STUDENT_ID,
  SET_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE
} from "../../../store/mutations.type";

export default {
  name: "EmployeeDonationsFileUpload",
  data() {
    return {
      loading: true,
      file: null,
      rules: [
        value => !value || value.size < 2048000 || 'File size should be less than 2 MB!',
      ],
      failures: [],
      fileId: null,
      fileName: null,
      missingEmployees: [],
      processedRowsCount: 0,
      readRows: [],
      showFileContent: false,
    }
  },
  computed: {
    company() {
      return this.$store.getters.currentUserCompany;
    },
    href() {
      let year = this.withheldPayrollSelections.calendar_year;
      let quarter = Math.ceil(this.withheldPayrollSelections.month / 3);

      return '/api/companies/' + this.$route.params.company_id + '/employee-donations/download/' + quarter + '/' + year + '/template';
    },
    showUploadButton() {
      return this.file;
    },
    withheldPayrollSelections() {
      return this.$store.getters.currentWithheldPayrollSelections;
    },
    error() {
      return this.$store.getters.getMessage("companies");
    },
    errors() {
      return this.$store.getters.getErrors("companies");
    },
    unpaidEmployeeDonations() {
      return this.company.unpaid_employee_daily_donations && this.company.unpaid_employee_daily_donations.length;
    },
  },
  methods: {
    fileFocus() {
      this.file = null;
      this.$store.commit(CLEAR_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE);
    },
    saveFile() {
      this.saving = true;
      let company_id = this.$route.params.company_id;

      let data = new FormData();
      data.append('calendar_year', this.withheldPayrollSelections.calendar_year);
      data.append('company_id', company_id);
      data.append('file', this.file);
      data.append('month', this.withheldPayrollSelections.month);

      this.$store.dispatch(POST_EMPLOYEE_DONATIONS_FILE_UPLOAD, {company_id, data})
        .then((data) => {
          this.processedRowsCount = data.response.processedRowsCount;
          this.failures = data.response.failures
          if (this.failures.length) {
            this.file = null;
          }
          this.fileAmount = data.response.fileAmount;
          this.fileId = data.response.fileId;
          this.fileName = data.response.fileName;
          this.missingEmployees = data.response.missingEmployees;
          this.readRows = data.response.readRows

          this.saving = false;
          this.$store.commit(SET_PAYROLL_WITHHOLDING_UPLOAD_RESPONSE, data.response);
        });
    },
  },
}

</script>

<style scoped></style>
