<template>
    <v-menu :close-on-click="false" :close-on-content-click="false" v-model="isShowingMessagesDialog" top offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...menu }" v-bind="attrs" :color="hasUnread ? 'success' : 'primary'"
                        fab dark fixed bottom right class="mb-5 mr-5"
                        :class="hasUnread ? 'animate__animated animate__infinite animate__bounce' : ''">
                        <v-icon> mdi-help-circle </v-icon>
                    </v-btn>
                </template>
                <span>Help</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>APSTO Help Messenger</v-card-title>
            <div class="overflow-y-hidden overflow-x-hidden" :style="mobileOrDesktop">
                <messages :conversation="conversation" />
            </div>
        </v-card>

    </v-menu>
</template>

<script>
import Messages from "./Messages";

export default {
    name: "MessagesFab",
    components: {
        Messages,
    },
    props: {
        conversation: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isShowingMessagesDialog: {
            get: function () {
                return this.$store.getters.isShowingMessagesDialog;
            },
            set: function (newValue) {
                console.log('Messages Dialog being set');
                console.log(newValue);
            },
        },
        hasUnread() {
            if (!this.conversation) return false;

            return this.conversation.has_unread;
        },
        mobileOrDesktop() {
            if (this.$vuetify.breakpoint.width > 425) {
                return 'height: 75vh; width: 400px;';
            }
            else {
                return 'height: 78vh; width: 90vw;'
            }
        },
    },
};
</script>
