<template>
  <loading v-if="loading"/>
  <div v-else>

    <v-row>
      <v-col>
        <household-header page-title="New Application"/>
      </v-col>
    </v-row>

    <v-row class="mt-n4">
      <v-col md="6">
        <v-card>
          <v-card-title class="text-h6-mulish font-weight-bold">
            {{ currentApplicationSchoolYear && currentApplicationSchoolYear.message1 }}
            <div
              v-if="currentApplicationSchoolYear && currentApplicationSchoolYear.message2"
              class="mt-3"
            >
              {{ currentApplicationSchoolYear && currentApplicationSchoolYear.message2 }}
            </div>
          </v-card-title>

          <v-card-title
            class="mt-n4 font-weight-bold text-h7-mulish text-apsto-teal"
            style="cursor: pointer;"
            @click="showPreregistrationNote = !showPreregistrationNote"
          >
            Read note about pre-registrations
          </v-card-title>

          <v-card-subtitle v-if="showPreregistrationNote" class="mt-0 text-h7-mulish text-apsto-black">
            {{ currentApplicationSchoolYear && currentApplicationSchoolYear.message3 }}
            <span class="text-apsto-teal font-weight-bold">
              {{ currentApplicationSchoolYear && currentApplicationSchoolYear.message4 }}
            </span>
            {{ currentApplicationSchoolYear && currentApplicationSchoolYear.message5 }}
          </v-card-subtitle>
          <v-form :disabled="saving">
            <div v-if="isMobile" class="mx-2 mt-3 pb-5">
              <v-btn
                :to="{name: 'household-applications-list', params: { household_id: $route.params.household_id }}"
                block class="cancel-button"
                rounded
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="currentApplicationSchoolYear.proceed"
                block
                class="mt-5"
                color="primary"
                @click="submit"
              >
                Continue
              </v-btn>
            </div>
            <v-card-actions v-if="!isMobile" class="pb-4 ml-2">
              <v-btn
                :to="{name: 'household-applications-list', params: { household_id: $route.params.household_id }}"
                class="cancel-button" rounded
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="currentApplicationSchoolYear.proceed"
                color="primary"
                rounded
                @click="submit"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import Loading from "../../../../components/shared/Loading";
import {GET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR, POST_HOUSEHOLD_APPLICATION,} from "../../../../store/actions.type";
import FormRequiresWideScreen from "../../../../components/shared/FormRequiresWideScreen.vue";

export default {
  name: "ApplicationCreate",
  components: {
    FormRequiresWideScreen,
    HouseholdHeader,
    Loading,
  },
  data() {
    return {
      application: {
        household_id: this.$route.params.household_id,
      },
      loading: true,
      saving: false,
      showPreregistrationNote: false,
    };
  },
  computed: {
    currentApplication() {
      return this.$store.getters.currentApplication;
    },
    currentApplicationSchoolYear() {
      return this.$store.getters.currentApplicationSchoolYear;
    },
    error() {
      return this.$store.getters.getMessage("application");
    },
    errors() {
      return this.$store.getters.getErrors("application");
    },
  },
  methods: {
    submit() {
      this.saving = true;
      let application = this.application;
      console.log('new app:', application)
      application.school_year_id = this.currentApplicationSchoolYear.id;
      let household_id = this.$route.params.household_id;
      this.$store.dispatch(POST_HOUSEHOLD_APPLICATION, application)
        .then(() => {
          this.saving = false;
          let application_id = this.currentApplication.id;
          this.$router.replace({
            name: "household-application-edit",
            params: {
              application_id: application_id,
              household_id: household_id,
            },
          });
        })
        .catch((response) => {
          console.log(response);
          this.saving = false;
        });
    },
  },
  mounted() {
    let household_id = this.$route.params.household_id;
    this.$store.dispatch(GET_HOUSEHOLD_APPLICATION_SCHOOL_YEAR, household_id).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.v-card__title {
  line-height: 1.60rem;
}

.v-card__subtitle {
  line-height: 1.5rem !important;
}
</style>
