<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col
                class="ml-3 pa-0"
                cols="auto"
            >
                <school-header page-title="Student Info & Awards"/>
            </v-col>
        </v-row>
        <v-row class="ml-0">
            <v-col md="6">
                <student-show
                    :application-member="applicationMember"
                    :application-student="applicationStudent"
                    :school-student="schoolStudent"
                    :school-year-name="schoolYearName"
                />
            </v-col>
        </v-row>
        <!--    <v-row class="ml-0">-->
        <!--      <v-col md="6">-->
        <!--        <div class="awards-header mt-1">Special Award Requested (as of {{ today }})</div>-->
        <!--        <div class="awards-header hint">-->
        <!--          Enter amount here that you would like to request for this student. Leave blank if zero.-->
        <!--        </div>-->
        <!--        <v-text-field-->
        <!--          v-model.trim="amount_requested"-->
        <!--          :error="!!errors['amount_requested']"-->
        <!--          :error-messages="errors['amount_requested'] ? errors['amount_requested'] : null"-->
        <!--          filled-->
        <!--          @mouseout="saveAmountRequested"-->
        <!--        />-->
        <!--      </v-col>-->
        <!--    </v-row>-->
        <v-row>
            <v-col md="6">
                <v-card :style="isMobile ? '' : 'margin-right: -6px;'" class="ml-3">
                    <v-card-title class="text-h6-mulish">
                        Remove Student From School
                    </v-card-title>
                    <v-select
                        v-model="schoolStudent.hide"
                        :items="studentHideOptions"
                        class="px-4"
                        filled
                        item-text="name"
                        item-value="id"
                        label="Select"
                        @change="saveStudentHide"
                    />
                </v-card>
            </v-col>
        </v-row>
        <v-row class="ma-0">
            <v-col>
                <div class="awards-header mt-4">Awards</div>
                <div
                    v-if="schoolStudentAwards && schoolStudentAwards.length && !schoolHasApprovedApplicationForSchoolYear"
                    class="sub-header mt-4"
                >
                    <div v-if="schoolHasApplicationForSchoolYear">
                        You'll be able to access these awards once the Partner Agreement you filed for this school year
                        has
                        been approved. It's current status is: {{ schoolApplicationStatusName }}.
                    </div>
                    <div v-else>
                        You'll be able to access these awards after you complete a Partner Agreement for this school
                        year
                        and
                        it's been approved.
                    </div>
                </div>
                <v-data-table
                    :disable-sort="isMobile"
                    :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
                    :headers="headers"
                    :items="schoolStudentAwards"
                    :items-per-page="30"
                    :single-select="true"
                    class="elevation-2 mt-4 mr-0"
                    item-key="id"
                    @click:row="editSchoolStudentAward"

                >
                    <template v-slot:no-data>
                        <span class="text-apsto-teal">
                            No students awards found
                        </span>
                    </template>
                    <template #item.application_student_award_actions="{ item }">
                        <div
                            v-for="(action, index) in item.application_student_award_actions"
                            :key="index"
                        >
                            <div v-if="action.action === 1 && !acceptedPartialAmount(action, item)">
                                <div
                                    :style="index >= 1 ? 'padding-top: 8px;' : ''"
                                    class="accepted-declined"
                                >
                                    Accepted on {{ displayDate(action.created_at) }} at
                                    {{ displayTime(action.created_at) }}
                                    by
                                    {{ action.user_name }}
                                </div>
                                <div>
                                    Amount: {{ action.amount | formatCurrencyWithCents }}
                                </div>
                            </div>
                            <div v-if="acceptedPartialAmount(action, item)">
                                <div
                                    :style="index >= 1 ? 'padding-top: 8px;' : ''"
                                    class="accepted-declined"
                                >
                                    Accepted partial amount on {{ displayDate(action.created_at) }} at {{
                                        displayTime(action.created_at)
                                    }}
                                    by {{ action.user_name }}
                                </div>
                                <div>
                                    Amount Accepted: {{ action.amount | formatCurrencyWithCents }}
                                </div>
                                <div> Reason: {{ action.award_declination_label }}</div>
                                <div v-if="action.comments">Comments: {{ action.comments }}</div>
                            </div>
                            <div v-if="action.action === 2">
                                <div
                                    :style="index >= 1 ? 'padding-top: 8px;' : ''"
                                    class="accepted-declined"
                                >
                                    Declined on {{ displayDate(action.created_at) }} at
                                    {{ displayTime(action.created_at) }}
                                    by
                                    {{ action.user_name }}
                                </div>
                                <div> Reason: {{ action.award_declination_label }}</div>
                                <div v-if="action.comments">Comments: {{ action.comments }}</div>
                            </div>
                        </div>
                    </template>
                    <template #item.amount="{ item, index }">
                        <div v-if="index === 0">
                            {{ item.amount | formatCurrencyWithCents }}
                        </div>
                        <div v-else>
                            {{ item.amount | formatNumberWithCents }}
                        </div>
                    </template>
                    <template #item.created_at="{ item }">
                        {{ displayDate(item.created_at) }}
                    </template>
                    <template #item.tax_credit_type="{ item }">
                        <div>{{ item.tax_credit_name }}</div>
                    </template>
                    <template #item.locked="{ item }">
                        <div v-if="item.locked">
                            <v-tooltip
                                bottom
                                color="secondary"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-lock
                                    </v-icon>
                                </template>
                                <span>Awards for this period have been locked and cannot be edited</span>
                            </v-tooltip>
                        </div>
                        <div v-else>
                            <v-icon color="secondary">
                                mdi-lock-open
                            </v-icon>
                        </div>
                    </template>
                </v-data-table>
                <v-dialog
                    v-model="nestedTableDialog"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                    @click:outside="doneAwardActions"
                >
                    <school-student-award-actions-table
                        :application-student-award-id="awardId"
                        :student-name="applicationMember.name"
                        @done="doneAwardActions"
                    />
                </v-dialog>
            </v-col>
        </v-row>
        <v-row class="ml-0">
            <v-col>
                <v-card-actions class="pb-4 ml-n2 mt-n4">

                    <v-btn
                        v-if="sender === 'school-pending-student-awards'"
                        :to="{
                            name: 'school-pending-awards',
                            params: { school_id: this.$route.params.school_id }
                        }"
                        class="cancel-button"
                        rounded
                    >
                        Back to Pending Awards
                    </v-btn>
                    <v-btn
                        v-else
                        :to="{
                            name: 'school-year-students',
                            params: { school_id: this.$route.params.school_id, school_year_id: this.$route.params.school_year_id }
                        }"
                        class="cancel-button"
                        rounded
                    >
                        Back to Students
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import SchoolHeader from "../../../../components/school/SchoolHeader";
import SchoolStudentAwardActionsTable from "./awards/SchoolStudentAwardActionsTable";
import StudentSelector from "../../../../components/shared/StudentSelector";
import StudentShow from "../../../../components/school/StudentShow";
import {GET_SCHOOL_STUDENT, PATCH_STUDENT_HIDE} from "../../../../store/actions.type";

export default {
    name: "SchoolStudentEdit",
    components: {
        Loading,
        SchoolHeader,
        SchoolStudentAwardActionsTable,
        StudentSelector,
        StudentShow,
    },
    data() {
        return {
            awardId: null,
            nestedTableDialog: false,
            loading: true,
            headers: [
                {text: 'School Year', value: 'fiscal_year_name'},
                {text: 'Date', value: 'created_at'},
                {text: 'Amount', align: 'end', value: 'amount',},
                {text: 'Tax Credit Type', value: 'tax_credit_type', width: '30%'},
                {text: 'Activity', value: 'application_student_award_actions'},
                {text: 'Status', value: 'locked'},
            ],
            amount_requested: null,
            saving: false,
            studentHideOptions: [
                {id: 0, name: 'No, Leave Student In School'},
                {id: 1, name: 'Yes, Student Has Graduated'},
                {id: 2, name: 'Yes, Student No Longer Attending'},
            ],
        }
    },
    props: {
        sender: {
            type: String,
            required: false,
        },
    },
    computed: {
        applicationMember() {
            return this.$store.getters.currentSchoolStudent["applicationMember"];
        },
        applicationStudent() {
            return this.$store.getters.currentSchoolStudent["applicationStudent"];
        },
        school() {
            return this.$store.getters.currentUserSchool;
        },
        schoolApplicationStatus() {
            return this.$store.getters.currentSchoolStudent["schoolApplicationStatus"];
        },
        schoolApplicationStatusName() {
            return this.$store.getters.currentSchoolStudent["schoolApplicationStatusName"];
        },
        schoolHasApplicationForSchoolYear() {
            return this.$store.getters.currentSchoolStudent["schoolHasApplicationForSchoolYear"];
        },
        schoolHasApprovedApplicationForSchoolYear() {
            return this.schoolHasApplicationForSchoolYear && this.schoolApplicationStatus === 3
        },
        schoolStudent() {
            return this.$store.getters.currentSchoolStudent["applicationStudentSchool"];
        },
        schoolStudentAwards() {
            return this.$store.getters.currentSchoolStudent["awards"];
        },
        schoolYearName() {
            return this.applicationStudent.school_year_name;
        },
        today() {
            return new Date().toLocaleDateString();
        },
        error() {
            return this.$store.getters.getMessage("schoolStudent");
        },
        errors() {
            return this.$store.getters.getErrors("schoolStudent");
        },
    },
    methods: {
        acceptedPartialAmount(action, item) {
            return action.action === 1 && parseFloat(action.amount) > 0 && parseFloat(action.amount) < parseFloat(item.amount);
        },
        doneAwardActions() {
            this.getSchoolStudent();
            this.nestedTableDialog = false;
        },
        editSchoolStudentAward(item) {
            if (item.locked) {
                return;
            }
            this.awardId = item.id;
            this.nestedTableDialog = this.schoolHasApprovedApplicationForSchoolYear;
        },
        getSchoolStudent() {
            this.loading = true;
            let application_student_school_id = this.$route.params.application_student_school_id;
            let fiscal_year_id = this.$route.params.school_year_id;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(GET_SCHOOL_STUDENT, {school_id, application_student_school_id, fiscal_year_id})
                .then(() => {
                    this.loading = false;
                });
        },
        saveStudentHide() {
            let form = this.schoolStudent;
            let application_student_school_id = this.$route.params.application_student_school_id;
            let school_id = this.$route.params.school_id;
            console.log('form', form);
            this.$store.dispatch(PATCH_STUDENT_HIDE, {school_id, application_student_school_id, form});
        },
        // saveAmountRequested() {
        //   this.saving = true;
        //   let form = this.schoolStudent;
        //   form.amount_requested = this.amount_requested;
        //   let application_student_school_id = this.$route.params.application_student_school_id;
        //   let school_id = this.$route.params.school_id;
        //   this.$store.dispatch(PATCH_STUDENT_AMOUNT_REQUESTED, {school_id, application_student_school_id, form})
        //     .then(() => {
        //       this.saving = false;
        //     });
        // }
    },
    mounted() {
        this.getSchoolStudent();
    },
}
</script>

<style scoped>
.accepted-declined {
    font-weight: 700;
}

.awards-header {
    font-family: 'Mulish', sans-serif;
    font-size: 21px;
}

.awards-header.hint {
    font-size: 15px;
    margin-bottom: 15px;
}

.sub-header {
    background-color: var(--apsto-purple-very-light);
    border: 1px solid var(--apsto-purple);
    font-size: 1.15rem;
    padding: 20px !important;
}

::v-deep tbody td {
    height: unset !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

::v-deep th:not([role=columnheader]) {
    background-color: var(--apsto-purple-very-light);
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
