import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {GET_SCHOOL_GRADES,} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {CLEAR_ERRORS, SET_ERROR, SET_SCHOOL_GRADES} from "./mutations.type";
/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        schoolGrades: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentSchoolGrades(state) {
        return state.schoolGrades;
    },
    currentSchoolGradesFiltered: (state) => (gradeId) => {
        return state.schoolGrades.filter(grade => grade.id === gradeId);
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_SCHOOL_GRADES](context) {
        //Prevent loading school grades over and over
        if (context.state.schoolGrades.length) return context.state.schoolGrades;

        return new Promise((resolve, reject) => {
            ApiService.get(`/api/school-grades/`)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCHOOL_GRADES, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, {
                        target: "schoolGrades",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_SCHOOL_GRADES](state, data) {
        state.schoolGrades = data;
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
