<template>
    <div>
        <v-text-field
            id="name"
            v-model.trim="school.name"
            :error="!!errors.name"
            :error-messages="errors.name ? errors.name : null"
            autocomplete="organization"
            background-color="white"
            filled
            label="School Name"
        />
        <v-text-field
            id="website"
            v-model.trim="school.website"
            :error="!!errors.website"
            :error-messages="errors.website ? errors.website : null"
            autocomplete="website"
            background-color="white"
            filled
            hint="The easiest way to enter your website is to go to your site then copy the address and paste it here."
            label="Website (Optional)"
        />
        <v-text-field
            id="phone"
            v-model.trim="school.phone"
            :error="!!errors.phone"
            :error-messages="errors.phone ? errors.phone : null"
            autocomplete="phone"
            background-color="white"
            filled
            label="School Phone"
            @input="phoneNumber"
        />
        <v-text-field
            id="fax"
            v-model.trim="school.fax"
            :error="!!errors.fax"
            :error-messages="errors.fax ? errors.fax : null"
            autocomplete="phone"
            background-color="white"
            filled
            label="School Fax (Optional)"
            @input="faxNumber"
        />
        <!--        <v-select-->
        <!--            id="organization_type_enum"-->
        <!--            v-model="school.organization_type_enum"-->
        <!--            :error="!!errors.organization_type_enum"-->
        <!--            :error-messages="errors.organization_type_enum ? errors.organization_type_enum : null"-->
        <!--            :items="schoolTypes"-->
        <!--            autocomplete="on"-->
        <!--            background-color="white"-->
        <!--            filled-->
        <!--            item-text="value"-->
        <!--            item-value="id"-->
        <!--            label="School Type"-->
        <!--            persistent-hint-->
        <!--        />-->
        <v-select
            id="affiliate_id"
            v-model="school.affiliation_id"
            :error="!!errors.affiliation_id"
            :error-messages="errors.affiliation_id ? errors.affiliation_id : null"
            :items="affiliations"
            autocomplete="on"
            background-color="white"
            filled
            item-text="value"
            item-value="id"
            label="Religious Affiliations"
            persistent-hint
        />
        <v-text-field
            id="title"
            v-model.trim="school.title"
            :error="!!errors.title"
            :error-messages="errors.title ? errors.title : null"
            autocomplete="organization-title"
            background-color="white"
            filled
            hint="Your title at this school."
            label="School Title"
        />
        <v-text-field
            id="contact_phone"
            v-model.trim="school.contact_phone"
            :error="!!errors.contact_phone"
            :error-messages="errors.contact_phone ? errors.contact_phone : null"
            autocomplete="phone"
            background-color="white"
            filled
            label="Your Contact Phone"
            @input="contactPhoneNumber"
        />
        <v-row class="mt-n5">
            <v-col class="text-h5"> Physical Address</v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="mt-n3 box-with-background">
                    Quick Address Entry
                    <vuetify-google-autocomplete
                        id="map"
                        append-icon="mdi-magnify"
                        background-color="white"
                        class="mt-4"
                        country="us"
                        filled
                        hint="The address you select will fill in the fields below."
                        placeholder="Start typing address"
                        v-on:placechanged="getAddressData"
                    />
                </div>
            </v-col>
        </v-row>
        <v-text-field
            id="street1"
            v-model.trim="school.physical_address.street1"
            :error="!!errors['physical_address.street1']"
            :error-messages="errors['physical_address.street1'] ? errors['physical_address.street1'] : null"
            autocomplete="address-line1"
            background-color="white"
            filled
            label="Street1"
        />
        <v-text-field
            id="street2"
            v-model.trim="school.physical_address.street2"
            :error="!!errors['physical_address.street2']"
            :error-messages="errors['physical_address.street2'] ? errors['physical_address.street2'] : null"
            autocomplete="address-line2"
            background-color="white"
            filled
            label="Street2"
        />
        <v-text-field
            id="postal"
            v-model.trim="school.physical_address.postal"
            :error="!!errors['physical_address.postal']"
            :error-messages="errors['physical_address.postal'] ? errors['physical_address.postal'] : null"
            autocomplete="zip"
            background-color="white"
            filled
            label="Postal / Zip"
        />
        <v-text-field
            id="city"
            v-model.trim="school.physical_address.city"
            :error="!!errors['physical_address.city']"
            :error-messages="errors['physical_address.city'] ? errors['physical_address.city'] : null"
            autocomplete="address-level2"
            background-color="white"
            filled
            label="City"
        />
        <region-selector v-model="school.physical_address.region_id"/>

        <!--        Mailing Address-->
        <v-row class="mt-n5">
            <v-col class="text-h5"> Mailing Address</v-col>
        </v-row>
        <v-row class="mt-n7">
            <v-col>
                <v-checkbox
                    v-model="address_checkbox"
                    color="secondary"
                    @change="populateAddress"
                >
                    <template v-slot:label>
                        <div>
                            Mailing address is the same as above
                        </div>
                    </template>
                </v-checkbox>
                <div v-if="!address_checkbox">
                    <v-text-field
                        id="street1Mailing"
                        v-model.trim="school.address.street1"
                        :error="!!errors['address.street1']"
                        :error-messages="errors['address.street1'] ? errors['address.street1'] : null"
                        autocomplete="address-line1"
                        background-color="white"
                        filled
                        label="Street1"
                    />
                    <v-text-field
                        id="street2Mailing"
                        v-model.trim="school.address.street2"
                        :error="!!errors['address.street2']"
                        :error-messages="errors['address.street2'] ? errors['address.street2'] : null"
                        autocomplete="address-line2"
                        background-color="white"
                        filled
                        label="Street2"
                    />
                    <v-text-field
                        id="postalMailing"
                        v-model.trim="school.address.postal"
                        :error="!!errors['address.postal']"
                        :error-messages="errors['address.postal'] ? errors['address.postal'] : null"
                        autocomplete="zip"
                        background-color="white"
                        filled
                        label="Postal / Zip"
                    />
                    <v-text-field
                        id="cityMailing"
                        v-model.trim="school.address.city"
                        :error="!!errors['address.city']"
                        :error-messages="errors['address.city'] ? errors['address.city'] : null"
                        autocomplete="address-level2"
                        background-color="white"
                        filled
                        label="City"
                    />
                    <region-selector id="region_idMailing" v-model="school.address.region_id"/>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RegionSelector from "../shared/RegionSelector";

export default {
    name: "SchoolForm",
    components: {
        RegionSelector
    },
    data() {
        return {
            address_checkbox: false,
        }
    },
    props: {
        school: {
            type: Object,
            required: true,
        }
    },
    computed: {
        affiliations() {
            return this.$store.getters.currentAffiliations;
        },
        regions() {
            return this.$store.getters.currentRegions;
        },
        // schoolTypes() {
        //     return this.$store.getters.schoolTypes;
        // },
        error() {
            return this.$store.getters.getMessage("schools");
        },
        errors() {
            return this.$store.getters.getErrors("schools");
        },
    },
    methods: {
        populateAddress() {
            if (this.address_checkbox) {
                this.school.address = Object.assign({}, this.school.physical_address);
            }
        },
        contactPhoneNumber() {
            var x = this.school.contact_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.school.contact_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        phoneNumber() {
            var x = this.school.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.school.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        faxNumber() {
            var x = this.school.fax.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.school.fax = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.school.physical_address.street1 = addressData.name;
            this.school.physical_address.city = addressData.locality;
            this.school.physical_address.postal = addressData.postal_code;
            this.school.physical_address.lat = addressData.latitude;
            this.school.physical_address.lon = addressData.longitude;

            let iso_state = addressData.administrative_area_level_1;
            this.school.physical_address.region_id = searchArrayByKey(iso_state, this.regions, 'iso', 'object').id;
        }
    }
}
</script>

<style scoped>
.box-with-background {
    background-color: var(--apsto-teal);
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
}
</style>
