<template>
    <v-row>
        <v-col md="6">
            <v-data-table
                :footer-props="{ 'items-per-page-options': [-1] }"
                :headers="headers"
                :hide-default-footer="true"
                :items="schoolApplication.school_levels"
                class="elevation-2"
                sort-by="grade_id"
            >
                <template #item.grade="{ item }">
                    <div> {{ item.name }}</div>
                </template>
                <template v-slot:item.tuition_amount="props">
                    <!--                    <inline-edit-tuition-->
                    <!--                        v-model.number="props.item.tuition_amount"-->
                    <!--                        class="text-apsto-teal align-end"-->
                    <!--                        label="Tuition Amount"-->
                    <!--                        @input="save(props.item.id, 'tuition_amount', $event)"-->
                    <!--                    />-->
                    <v-text-field
                        v-model="props.item.tuition_amount"
                        clearable
                        reverse
                        @input="save(props.item.id, 'tuition_amount', $event)"
                    />
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>

import { PATCH_SCHOOL_APPLICATION_GRADE } from "../../../store/actions.type";
import InlineEditTuition from "../../shared/InlineEditTuition";

export default {
    name: "SchoolTuitionTable",
    components: {
        InlineEditTuition
    },
    data() {
        return {
            itemsPerPage: 15,
            positive: v => v > 0 || 'Please enter zero or greater.',
            snack: false,
            snackText: '',
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: "LEVEL",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                {
                    text: "TUITION AMOUNT",
                    align: 'end',
                    value: "tuition_amount"
                },
            ]
        },
        schoolApplication() {
            return this.$store.getters.currentSchoolApplication;
        },
        error() {
            return this.$store.getters.getMessage("schoolApplication");
        },
        errors() {
            return this.$store.getters.getErrors("schoolApplication");
        },
    },
    methods: {
        save(grade_id, field_name, value) {
            let school_application_id = this.$route.params.application_id;
            let school_id = this.$route.params.school_id;
            this.$store.dispatch(PATCH_SCHOOL_APPLICATION_GRADE, {
                school_id,
                school_application_id,
                grade_id,
                field_name,
                value
            })
            this.snack = true
            this.snackText = 'Data saved'
        },
        cancel() {
            this.snack = true
            this.snackText = 'Canceled'
        },
        open() {
            this.snack = true
            this.snackText = 'Dialog opened'
        },
        close() {
            console.log('Dialog closed')
        },
    }
};
</script>

<style scoped>
::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .v-small-dialog__activator__content {
    color: var(--apsto-teal);
    font-weight: bold;
}

::v-deep .text-end {
    width: 50%;
}
</style>
