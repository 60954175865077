<template>
    <loading v-if="loading" />
    <div v-else>

        <v-row v-if="isMobile">
            <v-col>
                <household-header page-title="Household Admins" />
            </v-col>
        </v-row>

        <v-row
            v-if="!isMobile"
            align="center"
        >
            <v-col md="6">
                <household-header page-title="Household Admins" />
            </v-col>
            <v-spacer />
            <v-col
                v-if="household.user_is_primary_admin"
                cols="auto"
            >
                <v-btn
                    :to="{ name: 'household-admin-invite', params: { household_id: household.id } }"
                    color="primary"
                    rounded
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    Invite Another Household Admin
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :class="isMobile ? '' : 'ml-4 mt-5'"
            :disable-sort="isMobile"
            :headers="headers"
            :hide-default-footer="isMobile"
            :items="admins"
            class="elevation-2"
        >
            <template v-slot:top>
                <v-toolbar
                    v-if="isMobile"
                    color="secondary"
                    flat
                >
                    <v-row>
                        <v-col>
                            List of Household Admins
                        </v-col>
                        <v-col
                            v-if="household.user_is_primary_admin"
                            cols="auto"
                        >
                            <v-icon
                                color="white"
                                rounded
                                @click="addItem"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-toolbar>

                <v-dialog
                    v-model="dialogDeleteAdmin"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <v-card class="pb-5 dialog">
                        <v-card-title class="pa-4">
                            Confirm
                        </v-card-title>
                        <v-card-text class="mx-n2">
                            <v-row class="mt-2">
                                <v-col class="text-h6-mulish">
                                    Delete admin?
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <div
                            v-if="isMobile"
                            class="px-4"
                        >
                            <v-btn
                                block
                                class="cancel-button"
                                rounded
                                @click="closeDeleteAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="primary"
                                rounded
                                @click="deleteAdminConfirm"
                            >
                                Yes, Delete Admin
                            </v-btn>
                        </div>
                        <v-card-actions v-else>
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="closeDeleteAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="primary"
                                rounded
                                @click="deleteAdminConfirm"
                            >
                                Yes, Delete Admin
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogMakePrimaryAdmin"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >

                    <v-card class="pb-5 dialog">
                        <v-card-title class="pa-4">
                            Confirm
                        </v-card-title>
                        <v-card-text class="mx-n2">
                            <v-row class="mt-2">
                                <v-col class="text-h6-mulish">
                                    Are you sure you want to make this person the primary user for this household?
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <div
                            v-if="isMobile"
                            class="px-4"
                        >
                            <v-btn
                                block
                                class="cancel-button"
                                rounded
                                @click="closeMakePrimaryAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="primary"
                                @click="makePrimaryAdminConfirm"
                            >
                                Yes, Make Them Primary
                            </v-btn>
                        </div>
                        <v-card-actions v-else>
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="closeMakePrimaryAdmin"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="primary"
                                @click="makePrimaryAdminConfirm"
                            >
                                Yes, Make Them The Primary User
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
            </template>
            <template #item.is_primary="{ item }">
                {{ item.is_primary ? 'Yes' : 'No' }}
            </template>
            <v-snackbar
                v-model="snackbar"
                centered
            >
                {{ snackbarMessage }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :disabled="disableMakePrimaryAdminLink(item)"
                            class="mr-2"
                            color="secondary"
                            v-bind="attrs"
                            @click="makePrimaryAdmin(item)"
                            v-on="on"
                        >
                            mdi-account-convert
                        </v-icon>
                    </template>
                    <span>Assign role of primary admin to someone else</span>
                </v-tooltip>
                <v-icon
                    :disabled="disableDeleteAdminLink(item)"
                    color="red"
                    @click="deleteAdmin(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <household-invites-list class="mt-3" />
    </div>
</template>

<script>
import HouseholdHeader from "../../../components/household/HouseholdHeader.vue";
import HouseholdInvitesList from "./HouseholdInvitesList";
import Loading from "../../../components/shared/Loading";

import {
    DELETE_HOUSEHOLD_ADMIN,
    GET_HOUSEHOLD_ADMINS,
    GET_USER_HOUSEHOLD,
    PATCH_HOUSEHOLD_ADMIN,
    TRANSFER_HOUSEHOLD_ADMIN
} from "../../../store/actions.type";

export default {
    name: "HouseholdAdminsList",
    components: {
        HouseholdInvitesList,
        HouseholdHeader,
        Loading
    },
    data() {
        return {
            defaultItemAdmin: {
                name: '',
                email: '',
                is_primary: 0,
            },
            dialog: false,
            dialogDeleteAdmin: false,
            dialogMakePrimaryAdmin: false,
            headers:
                [
                    { text: 'Household Admin Name', align: 'start', value: 'name', },
                    { text: 'Email', value: 'email' },
                    { text: 'Primary Admin', value: 'is_primary' },
                    { text: 'Options', value: 'actions', sortable: false, align: 'end' },
                ],
            loading: true,
            search: '',
            selected: [],
            snackbar: false,
            snackbarMessage: '',
            max125chars: v => v.length <= 125 || 'Input too long!',
        }
    },
    computed: {
        admins() {
            return this.$store.getters.currentHouseholdAdmins;
        },
        household() {
            return this.$store.getters.currentUserHousehold;
        },
    },
    methods: {
        addItem() {
            this.$router.push({ name: 'household-admin-invite', params: { household_id: this.household.id } });
        },
        getAdmins() {
            let household_id = this.$route.params.household_id;
            this.$store.dispatch(GET_HOUSEHOLD_ADMINS, household_id).then(() => {
                this.loading = false;
            });
        },
        getHousehold() {
            // To get updated value for household.user_is_primary_admin to check whether user can see button to invite new admins.
            this.$store.dispatch(GET_USER_HOUSEHOLD, this.$route.params.household_id)
        },
        disableDeleteAdminLink(item) {
            return item.show_delete_link ? false : true;
        },
        disableMakePrimaryAdminLink(item) {
            return item.show_make_primary_link ? false : true;
        },
        closeDeleteAdmin() {
            this.dialogDeleteAdmin = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItemAdmin)
                this.editedIndex = -1
            })
        },
        closeMakePrimaryAdmin() {
            this.dialogMakePrimaryAdmin = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItemAdmin)
                this.editedIndex = -1
            })
        },
        editAdmin(item) {
            this.editedIndex = this.admins.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteAdmin(item) {
            this.editedIndex = this.admins.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDeleteAdmin = true
        },
        deleteAdminConfirm() {
            this.destroyAdmin();
            this.admins.splice(this.editedIndex, 1)
            this.closeDeleteAdmin()
        },
        destroyAdmin() {
            this.saving = true;
            let household_id = this.household.id;
            let user_id = this.editedItem.id;
            console.log(household_id, user_id)
            this.$store.dispatch(DELETE_HOUSEHOLD_ADMIN, { household_id, user_id })
                .then(() => {
                    this.saving = false;
                })
                .catch(({ data }) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        initialize() {
            this.admins = [];
        },
        makePrimaryAdmin(item) {
            this.editedIndex = this.admins.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogMakePrimaryAdmin = true
        },
        makePrimaryAdminConfirm() {
            this.transferPrimaryAdmin();
            this.closeMakePrimaryAdmin()
        },
        transferPrimaryAdmin() {
            this.saving = true;
            let household_id = this.household.id;
            let user_id = this.editedItem.id;
            this.$store.dispatch(TRANSFER_HOUSEHOLD_ADMIN, { household_id, user_id })
                .then(() => {
                    this.getHousehold(household_id);
                    this.getAdmins();
                    this.saving = false;
                })
                .catch(({ data }) => {
                    this.snackbarMessage = data.message_response;
                    this.snackbar = true;
                });
        },
        // snackbar
        save() {
            console.log(this);
            let household_id = this.household.id;
            let user_id = this.editedItem.id;
            this.$store.dispatch(PATCH_HOUSEHOLD_ADMIN, { household_id, user_id })
            this.snackbar = true

            this.snackbarMessage = 'Data saved'
        },
        close() {
            console.log('Dialog closed')
        },
    },
    mounted() {
        this.getAdmins();
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDeleteAdmin(val) {
            val || this.closeDeleteAdmin()
        },
        dialogMakePrimaryAdmin(val) {
            val || this.closeMakePrimaryAdmin()
        },
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}
</style>
