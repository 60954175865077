<template>
  <div class="mr-5">
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Fund Transactions By Date Range"/>
        <v-card class="mt-2">
          <v-card-title>Funds Activity Report</v-card-title>
          <v-card-subtitle>Please enter a date span to view</v-card-subtitle>
          <v-divider/>
          <v-form class="pa-4">
            <v-text-field
              v-model="dates.date_from"
              :error="!!errors.date_from"
              :error-messages="errors.date_from ? errors.date_from : null"
              filled
              label="From Date"
              type="date"
            />
            <v-text-field
              v-model="dates.date_thru"
              :error="!!errors.date_thru"
              :error-messages="errors.date_thru ? errors.date_thru : null"
              filled
              label="Thru Date"
              type="date"
            />
            <div class="mt-2">
              <v-btn
                :to="{ name: 'admin-menu' }"
                class="cancel-button"
                rounded
              > Cancel
              </v-btn>
              <v-btn
                :disabled="disableSubmit"
                class="ml-1"
                color="primary"
                rounded
                @click="submit"
              >
                Submit
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import AdminHeader from "../main/AdminHeader";
import {POST_FUND_TRANSACTION_REPORT_DATES} from "../../../store/actions.type";

export default {
  name: "AdminFunds",
  components: {
    Loading,
    AdminHeader
  },
  data() {
    return {
      dates: {
        date_from: '',
        date_thru: '',
      },
      saving: false
    }
  },
  computed: {
    disableSubmit() {
      return !this.dates.date_from || !this.dates.date_thru;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getDates() {
      const today = new Date();
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      this.dates.date_from = yyyy + "-" + mm + "-01";

      const d = new Date(yyyy, today.getMonth() + 1, 0);
      const last = String(d.getDate()).padStart(2, "0");
      this.dates.date_thru = yyyy + "-" + mm + '-' + last;
    },
    submit() {
      this.saving = true;
      this.$store.dispatch(POST_FUND_TRANSACTION_REPORT_DATES, this.dates)
        .then(() => {
          this.$router.push({
            name: 'admin-funds-activity-report',
            params: {date_from: this.dates.date_from, date_thru: this.dates.date_thru}
          })
          this.saving = false;
        })
        .catch((response) => {
          console.log(response)
          this.saving = false;
        })
    }
  },
  mounted() {
    this.getDates();
  }
}
</script>

<style scoped></style>
