<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row class="ml-1 py-5" align="center">
      <company-header :page-title="'Employees - ' + employeeDonationsYear"/>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="companyEmployees"
      :single-select="true"
      class="elevation-2 mt-4"
      item-key="id"
    >
      <template v-slot:no-data>
        <span class="text-apsto-teal">
          No participants found.
        </span>
      </template>
      <template #item.withholding_maximum="{ item, index }">
        <div v-if="index === 0">
          {{ item.withholding_maximum | formatCurrencyWithCents }}
        </div>
        <div v-else>
          {{ item.withholding_maximum | formatNumberWithCents }}
        </div>
      </template>
      <template #item.withholding_charges_remaining="{ item, index }">
        {{ item.withholding_charges_authorized - item.withholding_charges_processed | formatNumber }}
      </template>
      <template #item.withholding_active="{ item, index }">
        {{ item.withholding_charges_authorized ? 'Yes' : 'No' }}
      </template>
      <template #item.file="{ item, index }">
        {{ item.file ? 'Yes' : 'Need' }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loading from "../../../../components/shared/Loading";
import {GET_EMPLOYEE_DONATION_EMPLOYEES} from "../../../../store/actions.type";
import CompanyHeader from "../../../../components/company/CompanyHeader";

export default {
  name: "CompanyEmployeesList",
  components: {
    CompanyHeader,
    Loading,
  },
  data() {
    return {
      loading: true,
      search: '',
      selected: [],
    }
  },
  computed: {
    companyEmployees() {
      return this.$store.getters.currentEmployeeDonationEmployees['employees'];
    },
    employeeDonationsYear() {
      return this.$store.getters.currentEmployeeDonationEmployees['year'];
    },
    headers() {
      return [
        {text: 'Name', value: 'name'},
        {text: 'A-4C', value: 'file', align: 'start'},
        {text: 'Charges Authorized', value: 'withholding_charges_authorized', align: 'center'},
        {text: 'Processed', value: 'withholding_charges_processed', align: 'center'},
        {text: 'Remaining', value: 'withholding_charges_remaining', align: 'center'},
        {text: 'Active', value: 'withholding_active', align: 'center'},
        {text: 'Max Amount', value: 'withholding_maximum', align: 'end'},
      ]
    },
  },
  methods: {
    getEmployees() {
      let company_id = this.$route.params.company_id;
      let company_annual_donation_id = this.$route.params.company_annual_donation_id
      this.$store.dispatch(GET_EMPLOYEE_DONATION_EMPLOYEES, {company_id, company_annual_donation_id})
        .then(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getEmployees();
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}
</style>
