<template>
  <loading v-if="loading" />
  <div
    v-else-if="unlinkedStudents.length === 0"
    class="pa-5"
  >
    <v-row>
      <v-col>
        <v-card
          class="pa-5"
          outlined
        >
          <v-row align="center">
            <v-col cols="auto">
              <v-icon
                color="text-apsto-red"
                size="48"
              >
                mdi-alert-circle-outline
              </v-icon>
            </v-col>
            <v-col>
              <span class="text-h5-mulish">No unlinked students found.</span>
              <v-btn
                :to="{ name: 'admin-school-edit', params: { schoolId: $route.params.schoolId } }"
              >
                Try again
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div
    v-else
    class="pa-5"
  >
    <v-row align="center">
      <v-col cols="auto">
        <admin-header
          page-title="Link Unlinked Student Recommendation: "
          :hrefText="unlinkedStudentsNames"
          hrefColor="text-apsto-red"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <admin-students-table
          :unlinked-student-name="unlinkedStudentsNames"
          :link-type="'multiple'"
          class="mt-n3"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminHeader from "../../../../main/AdminHeader";
import AdminStudentsTable from "./AdminStudentsTable";
import ConfirmationDialog from "../../../../../../components/shared/ConfirmationDialog";
import Loading from "../../../../../../components/shared/Loading";

export default {
  name: "AdminSchoolLinkStudent",
  components: {
    AdminHeader,
    AdminStudentsTable,
    ConfirmationDialog,
    Loading,
  },
  data() {
    return {
      loading: true,
      saving: false,
    }
  },
  computed: {
    unlinkedStudents() {
      return this.$store.getters.currentAdminUnlinkedStudentsToLink;
    },
    unlinkedStudentsNames() {
      //return a string with each students UNIQUE name separated by commas. Only the unique names are returned.
      return this.unlinkedStudents.map(student => student.name).filter((value, index, self) => self.indexOf(value) === index).join(', ');
    },
  },
  methods: {
    
  },
  mounted() {
    this.loading = false;
  },
}
</script>

<style scoped>
a {
  color: var(--apsto-teal) !important;
}

::v-deep .v-data-table-header {
  background-color: var(--apsto-purple-very-light);
}

::v-deep tbody td {
  height: 60px !important;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
}

::v-deep .theme--light.v-data-table.unlinked-students>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
  cursor: pointer;
}

::v-deep .v-icon {
  font-size: 24px !important;
}</style>
