import ApiService from "../api/api.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_CONTENTS,
    GET_COMPONENTS,
    GET_LAYOUTS,
    POST_PAGE,
    PATCH_PAGE,
    POST_CONTENT,
    PATCH_CONTENT,
    DELETE_CONTENT,
    POST_SORT_ORDER,
    GET_ANNOUNCEMENTS
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_EDIT_MODE,
    SET_ADD_PAGE_DIALOG_STATUS,
    SET_ADD_PAGE_TO_GROUP_ID,
    TOGGLE_EDIT_MODE,
    TOGGLE_FAB_ENABLED,
    SET_FAB_ENABLED,
    SET_CONTENTS,
    SET_COMPONENTS,
    SET_LAYOUTS,
    SET_CONTENT,
    APPEND_CONTENT,
    REMOVE_CONTENT,
    SET_ANNOUNCEMENTS
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        editMode: false,
        fabEnabled: true,
        addPageDialogStatus: false,
        addPageToGroupID: false,
        layouts: [],
        contents: [],
        components: [],
        announcements: [],
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentEditMode(state) {
        return state.editMode;
    },
    currentFabEnabled(state) {
        return state.fabEnabled;
    },
    currentAddPageDialogStatus(state) {
        return state.addPageDialogStatus;
    },
    currentAddPageGroupID(state) {
        return state.addPageToGroupID;
    },
    currentContents(state) {
        return state.contents;
    },
    currentLayouts(state) {
        //Layouts for new pages
        return state.layouts;
    },
    currentComponents: (state) => ({parentType, parentComponent}) => {
        //Components for edit mode
        switch (parentType) {
            case "WebsitePage":
                return state.components.filter(component => component.website_layout_id == parentComponent);
            case "WebsiteContent":
                return state.components.filter(component => component.parent_component_id == parentComponent);
        }
    },
    currentGroup: (state) => (slug) => {
        if (!slug) return state.contents[0];

        let page = null;
        let group = null;
        state.contents.forEach((content) => {

            let pages = structuredClone(content.pages);
            pages = pages.filter(page => !page.override_url);

            page = searchArrayByKey(slug, pages, 'slug', 'object');
            if (page) group = content;
        });

        return group;
    },
    currentPage: (state, getters) => (slug) => {
        if (!slug) return state.contents[0].pages[0];

        let page = null;

        //404
        if (!getters.currentGroup(slug)) return null;

        let pages = structuredClone(getters.currentGroup(slug).pages);

        //Filter out pages that have an override url
        pages = pages.filter(page => !page.override_url);

        page = searchArrayByKey(slug, pages, 'slug', 'object');
        if (page) return page;

        return null;
    },
    currentLayout: (state, getters) => (slug) => {
        if (!slug) return {template:"Basic"};

        let page = null;

        page = getters.currentPage(slug);
        if (page) return page.layout;

        return {template:"NotFound"};
    },
    currentAnnouncements(state) {
        return state.announcements;
    },
    hasAnnouncements(state) {
        return state.announcements.length > 0;
    },
    currentAnnouncementSequence(state) {
        return state.announcements.map(announcement => announcement.id);
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_CONTENTS](context) {
        console.log('GET_CONTENTS');
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/website`)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CONTENTS, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_COMPONENTS](context) {
        console.log('GET_COMPONENTS');
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/website/components`)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_COMPONENTS, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_LAYOUTS](context) {
        console.log('GET_LAYOUTS');

        if (context.state.layouts.length) resolve(context.state.layouts);

        return new Promise((resolve, reject) => {
            ApiService.get(`/api/website/layouts`)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_LAYOUTS, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_PAGE](context, page) {
        console.log('POST_PAGE');
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/website/page`,page)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.dispatch(GET_CONTENTS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_PAGE](context, page) {
        console.log('PATCH_PAGE');
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/website/page/${page.id}`,page)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.dispatch(GET_CONTENTS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_CONTENT](context, data) {
        console.log('POST_CONTENT');
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/website/content`,data)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    //context.commit(APPEND_CONTENT, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_CONTENT](context, content) {
        console.log('PATCH_CONTENT');
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/website/content/${content.id}`,content)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CONTENT, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [DELETE_CONTENT](context, id) {
        console.log('DELETE_CONTENT');
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/website/content/${id}`)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_SORT_ORDER](context, new_sort_order) {
        console.log('POST_SORT_ORDER');
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/website/sort`,new_sort_order)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "website",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_ANNOUNCEMENTS](context) {
        console.log('GET_ANNOUNCEMENTS');
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/website/announcements`)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_ANNOUNCEMENTS, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, {
                        target: "announcements",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    }
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_EDIT_MODE](state, setting) {
        state.editMode = setting;
    },
    [SET_FAB_ENABLED](state, setting) {
        state.fabEnabled = setting;
    },
    [SET_ADD_PAGE_DIALOG_STATUS](state,setting) {
        state.addPageDialogStatus = setting;
    },
    [SET_ADD_PAGE_TO_GROUP_ID](state,group_id) {
        state.addPageToGroupID = group_id;
    },
    [TOGGLE_EDIT_MODE](state) {
        state.editMode = !state.editMode;
    },
    [TOGGLE_FAB_ENABLED](state) {
        state.fabEnabled = !state.fabEnabled;
    },
    [SET_CONTENTS](state, data) {
        state.contents = data;
    },
    [SET_LAYOUTS](state, data) {
        state.layouts = data;
    },
    [SET_COMPONENTS](state, data) {
        state.components = data.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0);
    },
    [APPEND_CONTENT](state, data) {
        console.log(data);
    },
    [SET_CONTENT](state, data) {
        console.log(data);
    },
    [REMOVE_CONTENT](state, data) {
        console.log(data);
    },
    [SET_ANNOUNCEMENTS](state, data) {
        state.announcements = data;
    }
};

export default {
    getters,
    actions,
    mutations,
    state,
};
