//Views
import Company from "../views/companies/company/Company";
import CompanyCreate from "../views/companies/company/CompanyCreate";
import CompanyEdit from "../views/companies/company/CompanyEdit";
import CompanyList from "../views/companies/company/CompanyList";
import CorporatePledgeOnlineForm from "../views/companies/corporate-pledges/CorporatePledgeOnlineForm.vue";
import EmployeeFormA4CList from "../views/companies/employee-donations/EmployeeFormA4CList.vue";

export default [
    {
        // Keep before "dashboard/companies" route
        path: "/dashboard/companies/corporate-donation-request-online-form",
        name: "corporate-donation-request-online-form",
        component: CorporatePledgeOnlineForm,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal'
        },
    },
    {
        path: "/dashboard/companies",
        name: "companies",
        component: CompanyList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal',
        },
    },
    {
        path: "/dashboard/companies/:company_id",
        name: "company",
        component: Company,
        meta: {
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal',
        },
    },
    {
        path: "/dashboard/companies/company/create",
        name: "company-create",
        component: CompanyCreate,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal',
        },
    },
    {
        path: "/dashboard/companies/:company_id/edit",
        name: "company-edit",
        component: CompanyEdit,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal',
        },
    },
    {
        path: "/dashboard/companies/:company_id/get-form-a4c-list",
        name: "company-get-form-a4c-list",
        component: EmployeeFormA4CList,
        meta: {
            requiresAuth: true,
            hideForAuth: false,
            hasDashboardMenu: true,
            backgroundColorGrey: true,
            showAnnouncements: true,
            topBarTitle: 'Corporate Portal',
        },
    },
];
