<template>
    <v-card>
        <v-card-title>
            <span class="text-h5 pb-4">
                {{ this.editedItem.id ? "Edit Payment" : "Record Payment" }}
            </span>
        </v-card-title>
        <v-tabs
            v-model="tab"
            background-color="secondary"
            centered
            dark
            icons-and-text
        >
            <v-tabs-slider />
            <v-tab href="#check">
                Check
                <v-icon>fas fa-money-check-dollar</v-icon>
            </v-tab>
            <v-tab-item value="check">
                <v-card-text style="background-color: var(--apsto-teal-light); padding: 10px 20px 20px;">
                    <v-row>
                        <v-col class="mt-2">
                            <v-card class="pa-4">
                                <v-text-field
                                    id="transaction_id"
                                    v-model.trim="editedItem.transaction_id"
                                    :error="!!errors['transaction_id']"
                                    :error-messages="errors['transaction_id'] ? errors['transaction_id'] : null"
                                    label="Check Number or Payment Type"
                                />
                                <!--Check Date-->
                                <v-text-field
                                    v-model="editedItem.check_date"
                                    :error="!!errors['check_date']"
                                    :error-messages="errors['check_date'] ? errors['check_date'] : null"
                                    :max="maxDate"
                                    :min="minDate"
                                    label="Date Deposited"
                                    type="date"
                                    @blur="editedItem.check_date = parseDateOnly(checkDateFormatted)"
                                />
                                <v-text-field
                                    id="amount"
                                    v-model.trim="editedItem.amount"
                                    :error="!!errors['amount']"
                                    :error-messages="errors['amount'] ? errors['amount'] : null"
                                    label="Payment Amount"
                                    @focus="clearErrors()"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-alert
                    v-if="errorOnSubmit"
                    border="left"
                    color="error"
                    dark
                    elevation="2"
                    icon="mdi-alert"
                    outlined
                    prominent
                    text
                >
                    <v-row>
                        <v-col>
                            <span class="text-h6">
                                Error
                            </span>
                            <v-divider class="mt-2" />
                            <span>
                                There was an error processing your payment. Please refresh the page and try again.
                            </span>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-card-actions class="py-4">
                    <v-spacer />
                    <v-btn
                        class="cancel-button"
                        rounded
                        @click="$emit('done')"
                    > Cancel
                    </v-btn>
                    <v-btn
                        :disabled="disableSubmitCheck"
                        class="save-by-cancel-button"
                        color="primary"
                        rounded
                        @click="submitCheck"
                    > Submit
                    </v-btn>
                </v-card-actions>
            </v-tab-item>
            <v-tab
                v-if="!this.editedItem.id"
                href="#card"
            >
                Payment Card
                <v-icon>fas fa-credit-card</v-icon>
            </v-tab>
            <v-tab-item value="card">
                <div>
                    <v-card-text style="background-color: var(--apsto-teal-light); padding: 10px 20px 20px;">
                        <v-row>
                            <v-col class="mt-2">
                                <v-card
                                    class="pa-4"
                                    style="height: 580px;"
                                >
                                    <payment-form
                                        v-model="paymentInfo"
                                        :offer-to-copy-info="false"
                                        :show-amount="true"
                                    />
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-alert
                        v-if="errorOnSubmit"
                        border="left"
                        color="error"
                        dark
                        elevation="2"
                        icon="mdi-alert"
                        outlined
                        prominent
                        text
                    >
                        <v-row>
                            <v-col>
                                <span class="text-h6">
                                    Error
                                </span>
                                <v-divider class="mt-2" />
                                <span>
                                    There was an error processing your payment. Please refresh the page and try again.
                                </span>
                            </v-col>
                        </v-row>
                    </v-alert>
                    <v-card-actions class="py-4">
                        <v-spacer />
                        <v-btn
                            class="cancel-button"
                            rounded
                            @click="$emit('done')"
                        > Cancel
                        </v-btn>
                        <v-btn
                            :disabled="disableSubmitCard"
                            class="ml-2"
                            color="primary"
                            rounded
                            @click="submitCard"
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>
                    <v-snackbar
                        v-model="snackbar"
                        centered
                    >
                        {{ snackbarMessage }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </div>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import {
    PATCH_COMPANY_DAILY_DONATION_PAYMENT,
    PATCH_CORPORATE_PLEDGE_PAYMENT,
    POST_COMPANY_DAILY_DONATION_CARD_PAYMENT,
    POST_COMPANY_DAILY_DONATION_PAYMENT,
    POST_CORPORATE_PLEDGE_CARD_PAYMENT,
    POST_CORPORATE_PLEDGE_PAYMENT
} from "../../store/actions.type";
import { CLEAR_ERRORS } from "../../store/mutations.type";
import PaymentForm from "./PaymentForm.vue";

export default {
    name: "Payment",
    components: {
        PaymentForm
    },
    data: () => ({
        paymentInfo: {
            amount: 0,
            billingAddress1: null,
            billingCity: null,
            billingPostcode: null,
            billingState: null,
            cardCode: null,
            cardNumber: null,
            expiryMonth: null,
            expiryYear: null,
            firstName: null,
            lastName: null,
        },
        calendar_year: null,
        checkDateFormatted: null,
        errorOnSubmit: false,
        maxDate: "2099-12-31",
        minDate: "2022-01-01",
        saving: false,
        snackbar: false,
        snackbarMessage: "",
        tab: null,
        yearNow: null,
    }),
    props: {
        calledFrom: {
            type: String,
            required: true
        },
        editedItem: {
            type: Object,
            required: true,
        },
        unpaidBalance: {
            type: Number,
            required: true
        }
    },
    computed: {
        disableSubmitCheck() {
            return !this.editedItem.amount ||
                !this.editedItem.check_date ||
                !this.editedItem.transaction_id ||
                this.saving ||
                this.errorOnSubmit;
        },
        disableSubmitCard() {
            return this.saving ||
                !this.paymentInfo.amount ||
                !this.paymentInfo.billingAddress1 ||
                !this.paymentInfo.billingCity ||
                !this.paymentInfo.billingPostcode ||
                !this.paymentInfo.billingState ||
                !this.paymentInfo.cardCode ||
                !this.paymentInfo.cardNumber ||
                !this.paymentInfo.expiryMonth ||
                !this.paymentInfo.expiryYear ||
                !this.paymentInfo.firstName ||
                !this.paymentInfo.lastName ||
                this.paymentInfo.cardNumber.toString().length !== 16 ||
                this.paymentInfo.expiryYear.toString().length !== 2 ||
                this.errorOnSubmit;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        clearErrors: function () {
            this.$store.commit(CLEAR_ERRORS);
        },
        submitCheck() {
            this.saving = true;
            let payment = this.editedItem;
            payment.id = payment.id ? payment.id : 0;
            payment.unpaid_balance = this.unpaidBalance;
            switch (this.calledFrom) {
                case "CompanyPayrollBatch":
                    this.submitCheckForCompanyPayrollBatch(payment);
                    break;
                case "CorporatePledge":
                    this.submitCheckForCorporatePledge(payment);
                    break;
            }
        },
        submitCheckForCompanyPayrollBatch(payment) {
            this.saving = true;
            let company_id = this.$route.params.company_id;
            let company_payroll_batch_id = this.$route.params.company_payroll_batch_id;
            payment.model = "CompanyPayrollBatch";
            payment.model_id = company_payroll_batch_id;
            this.$store.dispatch(
                payment.id === 0
                    ? POST_COMPANY_DAILY_DONATION_PAYMENT
                    : PATCH_COMPANY_DAILY_DONATION_PAYMENT,
                { company_id, company_payroll_batch_id, payment }
            )
                .then(() => {
                    this.saving = false;
                    this.$emit("done");
                })
                .catch((response) => {
                    this.saving = false;
                    if (response['data']['exception']) {
                        console.log("response[data]", response['data']);
                        this.errorOnSubmit = true;
                    }
                    if (!response['data']['errors']) {
                        console.log("response[data]", response['data']);
                        this.snackbarMessage = response['data'];
                        this.snackbar = true;
                    }
                });
        },
        submitCheckForCorporatePledge(payment) {
            this.saving = true;
            let corporate_pledge_id = this.$route.params.corporate_pledge_id;
            payment.model = "CorporatePledge";
            payment.model_id = corporate_pledge_id;
            this.$store.dispatch(
                payment.id === 0
                    ? POST_CORPORATE_PLEDGE_PAYMENT
                    : PATCH_CORPORATE_PLEDGE_PAYMENT,
                { corporate_pledge_id, payment }
            )
                .then(() => {
                    this.saving = false;
                    this.$emit("done");
                })
                .catch((response) => {
                    this.saving = false;
                    if (response['data']['exception']) {
                        console.log("response[data]", response['data']);
                        this.errorOnSubmit = true;
                    }
                    if (!response['data']['errors']) {
                        console.log("response[data]", response['data']);
                        this.snackbarMessage = response['data'];
                        this.snackbar = true;
                    }
                });
        },
        submitCard() {
            this.saving = true;
            let payment = {};
            payment.paymentInfo = {
                amount: this.paymentInfo.amount,
                billingAddress1: this.paymentInfo.billingAddress1,
                billingCity: this.paymentInfo.billingCity,
                billingPostcode: this.paymentInfo.billingPostcode,
                billingState: this.paymentInfo.billingState,
                cardNumber: this.paymentInfo.cardNumber,
                cvv: this.paymentInfo.cardCode,
                email: this.paymentInfo.email,
                expiryMonth: this.paymentInfo.expiryMonth,
                expiryYear: this.paymentInfo.expiryYear,
                firstName: this.paymentInfo.firstName,
                lastName: this.paymentInfo.lastName,
                unpaid_balance: this.unpaidBalance.toString()
            };
            switch (this.calledFrom) {
                case "CompanyPayrollBatch":
                    this.submitCardForCompanyPayrollBatch(payment);
                    break;
                case "CorporatePledge":
                    this.submitCardForCorporatePledge(payment);
                    break;
            }


        },
        submitCardForCompanyPayrollBatch(payment) {
            this.saving = true;
            let company_id = this.$route.params.company_id;
            let company_payroll_batch_id = this.$route.params.company_payroll_batch_id;
            payment.model = "CompanyPayrollBatch";
            payment.model_id = company_payroll_batch_id;
            this.$store.dispatch(
                POST_COMPANY_DAILY_DONATION_CARD_PAYMENT,
                { company_id, company_payroll_batch_id, payment }
            )
                .then(() => {
                    this.saving = false;
                    this.$emit("done");
                })
                .catch((response) => {
                    this.saving = false;
                    if (response['data']['exception']) {
                        console.log("response[data]", response['data']);
                        this.errorOnSubmit = true;
                    }
                    if (!response['data']['errors']) {
                        console.log("response[data]", response['data']);
                        this.snackbarMessage = response['data'];
                        this.snackbar = true;
                    }
                });
        },
        submitCardForCorporatePledge(payment) {
            this.saving = true;
            let corporate_pledge_id = this.$route.params.corporate_pledge_id;
            payment.model = "CorporatePledge";
            payment.model_id = corporate_pledge_id;
            this.$store.dispatch(
                POST_CORPORATE_PLEDGE_CARD_PAYMENT,
                { corporate_pledge_id, payment }
            )
                .then(() => {
                    this.saving = false;
                    this.$emit("done");
                })
                .catch((response) => {
                    this.saving = false;
                    if (response['data']['exception']) {
                        console.log("response[data]", response['data']);
                        this.errorOnSubmit = true;
                    }
                    if (!response['data']['errors']) {
                        console.log("response[data]", response['data']);
                        this.snackbarMessage = response['data'];
                        this.snackbar = true;
                    }
                });
        },
    },
    mounted() {
        this.checkDateFormatted = this.formatDateOnly(this.editedItem.check_date);
    },
    watch: {
        editedItem: {
            handler() {
                this.checkDateFormatted = this.formatDateOnly(this.editedItem.check_date);
            },
            deep: true
        }
    },
};
</script>

<style scoped>
.v-tab {
    border: 1px solid var(--apsto-teal);
    margin-right: 10px;
}
</style>

