<template>
    <v-col
        class="text-center mt-0 mb-14 mb-sm-0  d-flex flex-column"
        sm="6"
        md="4"
        lg="3"
    >
        <v-img :src="content.body.image"></v-img>
        <v-card>
            <div>
                <v-card-title class="primary--text text-h5 font-weight-bold justify-center">{{
                    content.title
                }}
                </v-card-title>
                <v-card-text
                    v-html="content.body.text"
                    class="text-h6-mulish"
                    style="height:150px"
                >
                </v-card-text>
            </div>
            <v-spacer />
            <v-card-actions class="justify-center">
                <v-btn
                    :href="content.body.route"
                    class="button-style white--text"
                    color="secondary"
                    rounded
                    v-if="content.body.route"
                >
                    {{ buttonText() }}
                </v-btn>
                <v-btn
                    :href="content.body.href"
                    class="button-style white--text"
                    color="secondary"
                    rounded
                    target="_blank"
                    v-if="content.body.href"
                >
                    {{ buttonText() }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: "CalloutImage",
    props: {
        content: {
            type: Object,
        },
    },
    methods: {
        buttonText() {
            if (this.content.body.button) return this.content.body.button;

            return "Learn More";

        }
    }
};
</script>

<style scoped>
.button-style {
    bottom: -25px;
    font-size: 1.125rem;
    font-weight: 700;
    min-width: 80% !important;
}
</style>
