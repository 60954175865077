<template>
    <loading v-if="loading" />
    <div v-else>
        <v-row
            justify="center"
            no-gutters
        >
            <v-col
                cols="12"
                lg="6"
                md="8"
                sm="10"
            >
                <household-header page-title="Custom Resources" />

                <v-card class="my-2">
                    <v-card-title class="py-5 px-4 font-weight-black">
                        Instructions to Create a Brochure
                    </v-card-title>
                    <v-card-text style="color: black; font-size: 1rem">
                        You will be creating a brochure to help you share and explain the tax credit program to family and
                        friends so they can recommend student(s) for scholarships. Note, to create brochures for more than
                      one child, enter information in steps 1 to 3 below then download the brochure to your hard drive.
                      Then, enter information for the next student.
                        <div class="pa-4 mt-4 font-weight-black box-with-background">
                            Scroll to the bottom of this page to see your brochure preview.
                        </div>
                        <h2 class="my-4">Steps:</h2>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="expansion-header">
                                    <v-row>
                                        <v-col
                                            class="section-number"
                                            cols="auto"
                                        >
                                            1
                                        </v-col>
                                        <v-col class="section-title">
                                            Choose Image to Display on the First Page of Your Brochure
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-btn
                                                block
                                                class="mt-5"
                                                color="primary"
                                                @click="brochurePictureDialog = true"
                                            >
                                                <v-icon
                                                    class="mr-2"
                                                    color="primaryButton"
                                                >fas fa-camera</v-icon>
                                                Upload brochure image
                                            </v-btn>
                                            <v-dialog
                                                v-model="brochurePictureDialog"
                                                :fullscreen="!!isMobile"
                                                max-width="600"
                                            >>
                                                <image-cropper
                                                    title="Upload Brochure Image"
                                                    @done="(hash) => imageUploaded(hash)"
                                                />
                                            </v-dialog>
                                            <v-img
                                                v-if="application.brochure_image"
                                                :src="`/website/uploads/show/${application.brochure_image}`"
                                                contain
                                                label="Primary Image"
                                                min-height="200"
                                                min-width="200"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        align="center"
                                                        class="fill-height ma-0"
                                                        justify="center"
                                                    >
                                                        <v-icon size="150">mdi-camera</v-icon>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="expansion-header">
                                    <v-row>
                                        <v-col
                                            class="section-number"
                                            cols="auto"
                                        >
                                            2
                                        </v-col>
                                        <v-col class="section-title">
                                            Enter Caption to Show Underneath the Image.
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                                    <v-row
                                        class="mt-2"
                                        no-gutters
                                    >
                                        <v-col>
                                            <v-text-field
                                                v-model="application.brochure_caption"
                                                hide-details
                                                label="Brochure Caption"
                                                outlined
                                                @change="fieldsUpdated"
                                                class="mt-5"
                                                background-color="white"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="expansion-header">
                                    <v-row>
                                        <v-col
                                            class="section-number"
                                            cols="auto"
                                        >
                                            3
                                        </v-col>
                                        <v-col class="section-title">
                                            Add Recommendations
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="background-color: var(--apsto-teal-light)">
                                    <v-row
                                        class="mt-2"
                                        no-gutters
                                    >
                                        <v-col>
                                            <div class="mt-4">
                                                We've filled in your student's information. Please review and make any
                                                changes you'd like. These
                                                appear in the recommendations section on the second page.
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field
                                                        v-model="application.brochure_student1"
                                                        :maxlength="39"
                                                        hide-details
                                                        label="Student(s)"
                                                        outlined
                                                        @change="fieldsUpdated"
                                                        background-color="white"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-2">
                                                <v-col>
                                                    <v-text-field
                                                        v-model="application.brochure_school1"
                                                        :maxlength="35"
                                                        hide-details
                                                        label="School(s)"
                                                        outlined
                                                        @change="fieldsUpdated"
                                                        background-color="white"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <h4>SWITCHER RECOMMENDATION</h4>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field
                                                        v-model="application.brochure_student2"
                                                        :maxlength="39"
                                                        hide-details
                                                        label="Student(s)"
                                                        outlined
                                                        @change="fieldsUpdated"
                                                        background-color="white"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-2">
                                                <v-col>
                                                    <v-text-field
                                                        v-model="application.brochure_school2"
                                                        :maxlength="35"
                                                        hide-details
                                                        label="School(s)"
                                                        outlined
                                                        @change="fieldsUpdated"
                                                        background-color="white"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-btn
                            block
                            class="mt-5 success"
                            color="primary"
                            @click="togglePreview"
                        >
                            <v-icon
                                class="mr-2"
                                color="primaryButton"
                            >{{ brochurePreview ? 'fas fa-eye-slash' : 'fas fa-eye' }}</v-icon>
                            {{  brochurePreview ? 'Hide' : 'Show' }} Brochure Preview
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="brochurePreview">
            <v-col>
                <iframe
                    id="brochurePreviewFrame"
                    :src="previewUrl"
                    style="width: 100%; height: 800px"
                >
                    <p>Your browser does not support iframes.</p>
                    <a
                        :href="previewUrl"
                        target="_blank"
                    >
                        Click here to preview in a new tab.
                    </a>
                </iframe>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { GET_HOUSEHOLD_APPLICATION, PATCH_HOUSEHOLD_APPLICATION_BROCHURE } from "../../../../store/actions.type";

import Loading from "../../../../components/shared/Loading";
import HouseholdHeader from "../../../../components/household/HouseholdHeader";
import UploadImage from "../../../../components/shared/UploadImage";
import ImageCropper from "../../../../components/shared/ImageCropper";

export default {
    name: "BrochurePreview",
    components: {
        Loading,
        HouseholdHeader,
        UploadImage,
        ImageCropper
    },
    data() {
        return {
            loading: true,
            brochurePreview: false,
            brochurePictureDialog: false,
        };
    },
    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        user() {
            return this.$store.getters.currentUser;
        },
        errors() {
            return this.$store.getters.getErrors("promos");
        },
        previewUrl() {
            return `/api/households/${this.$route.params.household_id}/applications/${this.$route.params.application_id}/brochure`;
        },
        students() {
            return this.application.members.filter(member => member.member_type === 'Student');
        },
    },
    methods: {
        fieldsUpdated() {
            this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_BROCHURE, {
                household_id: this.$route.params.household_id,
                application_id: this.$route.params.application_id,
                application: this.application
            }).then(() => {
                this.refreshPreview();
            });
        },
        refreshPreview() {
            if (this.brochurePreview) {
                document.getElementById('brochurePreviewFrame').contentWindow.location.reload();
            }
        },
        imageUploaded(hash) {
            console.log(hash);
            let application = this.application;
            application.brochure_image = hash;

            this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_BROCHURE, {
                household_id: this.$route.params.household_id,
                application_id: this.$route.params.application_id,
                application
            }).then(() => {
                this.brochurePictureDialog = false;
                this.refreshPreview();
            });
        },
        setupRecommendations(value) {
            if (!this.application.brochure_student1) {
                this.application.brochure_student1 = value.name;
            }
            if (!this.application.brochure_school1) {
                this.application.brochure_school1 = value.school_name;
            }
            if (!this.application.brochure_student2) {
                this.application.brochure_student2 = value.name;
            }
            if (!this.application.brochure_school2) {
                this.application.brochure_school2 = value.school_name;
            }
            this.fieldsUpdated();
        },
        togglePreview() {
            this.brochurePreview = !this.brochurePreview;
        }
    },
    mounted() {
        this.$store
            .dispatch(GET_HOUSEHOLD_APPLICATION, {
                household_id: this.$route.params.household_id,
                application_id: this.$route.params.application_id
            })
            .then(() => {
                this.setupRecommendations(this.students[0]);
                this.loading = false;
            });
    },
};
</script>

<style lang="sass" scoped>

.no-alpha {
  .v-color-picker__controls {
    .v-color-picker__preview {
      .v-color-picker__sliders {
        .v-color-picker__alpha {
          display: none;
      }
    }
  }

    .v-color-picker__edit {
      .v-color-picker__input:last-child {
        display: none;
    }
  }
}
}

.expansion-header {
  min-height: unset !important;
  padding: 11px 24px !important;
}

.v-expansion-panel-content {
  padding-bottom: 20px;
}

.caption-text {
  font-size: 1.125rem;
  font-weight: 900;
  padding: 20px 0;
}

.recommendations-list .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.separator-box {
  background-color: var(--apsto-teal);
  color: white;
  font-weight: 900;
  padding: 20px;
}

.section-number {
  background-color: var(--apsto-teal);
  font-weight: 700;
  margin-left: -12px;
  padding: 20px;
  color: white;
}

.section-title {
  padding: 20px;
}

::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  opacity: 0;
}

::v-deep .theme--light.v-icon {
  color: var(--apsto-purple);
}

::v-deep button.success {
  background-color: var(--apsto-teal) !important;
  border-color: var(--apsto-teal) !important;
}

::v-deep .ql-container {
  font-family: Mulish, sans-serif;
}

@import '~cropperjs/dist/cropper.css'

</style>
