<template>
  <v-sheet class="pa-5">
    <loading v-if="loading"/>
    <div v-else>
      <v-form :disabled="saving">
        <v-row class="ml-1 mt-n7">
          <v-col
            class="pa-0"
            cols="auto"
          >
            <h2>Make A Charitable Donation</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="mt-2 pl-4 pb-5"
            md="6"
          >

            <v-currency-field
              id="amount"
              v-model.number="donation.amount"
              :error="!!errors['amount']"
              :error-messages="errors['amount'] ? errors['amount'] : null"
              filled
              label="Donation Amount"
              type="number"
            />

            <recommendations-page
              v-model="donation.recommendations.charitable"
              class="mt-n7 bottom-border"
              tax-credit-type="charitable"
            />

            <div
              v-if="hidePaymentSection"
              class="box-with-background mt-5"
            >
              This is where the payments section is displayed on a working donation page. It's hidden on this
              donation page because you linked to this page from the King family donation page which is a
              fictitious family that we use as a sample to show you how our custom resources pages work.
            </div>
            <div v-else>
              <!-- Payment-->
              <h2 class="my-4 text-apsto-teal">Payment</h2>
              <div class="text-h6-mulish font-weight-black">Amount: {{ donation.amount | formatCurrency }}
              </div>
              <donation-payment-by-card
                :called-from-charitable-donation-page="true"
                :donation="donation"
                :email="email"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import DonationPaymentByCard from "../../../components/donations/DonationPaymentByCard";
import FilingStatus from "../../../components/donations/FilingStatus";
import SchoolSelector from "../../../components/shared/SchoolSelector";

import {
  DELETE_DONOR_PRIOR_DONATIONS,
  GET_APPLICATION_PROMO,
  GET_DONOR_DONATION_TAX_YEAR_FACTORS,
  GET_DONOR_PRIOR_DONATIONS,
  GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES,
} from "../../../store/actions.type";
import {SET_DONATION} from "../../../store/mutations.type";
import RecommendationsPage from "../../../components/donations/RecommendationsPage.vue";


export default {
  name: "CharitableDonationPageCreate",
  components: {
    DonationPaymentByCard,
    FilingStatus,
    Loading,
    RecommendationsPage,
    SchoolSelector,
  },
  data() {
    return {
      loading: true,
      saving: false,
    };
  },
  computed: {
    applicationPromo() {
      return this.$store.getters.currentPromo;
    },
    donation: {
      get() {
        return this.$store.getters.currentDonation;
      },
      set(donation) {
        this.$store.commit(SET_DONATION, donation);
      },
    },
    email() {
      let user = this.$store.getters.currentUser;
      return user.email;
    },
    explainer() {
      return {
        maxOriginal: this.maxOriginal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        maxSwitcher: this.maxSwitcher(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        maxTotal: this.maxTotal(this.donation.tax_filing_status ? this.donation.tax_filing_status : "single"),
        selectedTaxYear: this.selectedTaxYear,
        subscriptionChargeDay: 0,
        taxFilingStatus: this.donation.tax_filing_status,
        totalPriorDonations: this.totalPriorDonations,
      }
    },
    selectedTaxYear() {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      return parseInt(this.taxYearFactors && this.taxYearFactors[tax_year_key].name)
    },
    hidePaymentSection() {
      return this.applicationPromo.id === 4 || this.applicationPromo.id === 5;
    },
    taxYearFactors() {
      return this.$store.getters.currentTaxYearFactors;
    },
    totalPriorDonations() {
      let priorDonationsArray = this.$store.getters.currentDonorPriorDonations(this.selectedTaxYear)
      let total = 0;
      for (let i = 0; i < priorDonationsArray.length; i++) {
        let amount = parseFloat(priorDonationsArray[i].amount);
        total = total + amount;
      }
      return parseInt(total);
    },
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
  },
  methods: {
    maxOriginal(taxFilingStatus) {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      let tax_filing_status_key = "max_original_" + taxFilingStatus;
      let max_original = this.taxYearFactors[tax_year_key][tax_filing_status_key];
      return parseInt(max_original);
    },
    maxSwitcher(taxFilingStatus) {
      let tax_year_key = this.donation.tax_year + "_tax_year";
      let tax_filing_status_key = "max_switcher_" + taxFilingStatus;
      let max_switcher = this.taxYearFactors[tax_year_key][tax_filing_status_key];
      return parseInt(max_switcher);
    },
    maxTotal(taxFilingStatus) {
      return this.maxOriginal(taxFilingStatus) + this.maxSwitcher(taxFilingStatus);
    },
  },
  mounted() {
    this.loading = true;
    let promo_id = this.$route.params.promo_id;
    this.$store.dispatch(GET_APPLICATION_PROMO, promo_id).then(() => {
      this.$store.dispatch(GET_DONOR_DONATION_TAX_YEAR_FACTORS).then(() => {
        //Set donation to a blank version
        this.$nextTick(() => {
          this.$store.commit(SET_DONATION, {
            amount: 0,
            agree_to_terms: true,
            priorDonations: [],
            recommendations: {
              charitable: {
                comments: null,
                school_id: null,
                students: [],
              },
            },
            tax_filing_status: "single",
            tax_year: this.applicableTaxYearAsString(),
          });
          this.$store.dispatch(GET_DONOR_STUDENT_RECOMMENDATIONS_FROM_PROMO_PAGES, promo_id).then(() => {
            this.loading = false;
          });
        });
      });
    });
  },
};
</script>

<style scoped>
.bottom-border {
  border-bottom: 1px dotted;
}

::v-deep .v-toolbar__content {
  background-color: var(--apsto-purple-very-light);
}

::v-deep .v-label {
  color: black;
}
</style>
