<template>
  <v-form v-model="isFormValid" :disabled="saving">
    <v-card class="pa-5">
      <v-card-title>
        Add An Award
      </v-card-title>
      <v-card-subtitle class="mt-0">
        <div class="text-apsto-teal">
          {{ school.name }}
          <div v-if="studentName">
            {{ studentName }}
          </div>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <admin-school-student-award-form class="mt-0"/>
      </v-card-text>
      <v-card-actions class="mt-n6">
        <v-spacer/>
        <v-btn
          class="cancel-button ml-2"
          rounded
          @click="$emit('done')"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="disabled"
          class="save-by-cancel-button"
          color="primary"
          rounded
          @click="save"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  POST_SCHOOL_STUDENT_AWARD
} from "../../../../../store/actions.type";
import AdminSchoolStudentAwardForm from "./AdminSchoolStudentAwardForm";

export default {
  name: "AdminSchoolStudentAwardCreate",
  components: {AdminSchoolStudentAwardForm},
  data() {
    return {
      saving: false,
      isFormValid: false,
    }
  },
  props: {
    studentName: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    disabled() {
      return !this.isFormValid ||
        this.saving ||
        !this.studentAward.amount ||
        !this.studentAward.application_student_school_id ||
        !this.studentAward.fiscal_year_id ||
        !this.studentAward.month ||
        !this.studentAward.tax_credit_type;
    },
    school() {
      return this.$store.getters.currentAdminSchool;
    },
    studentAward() {
      return this.$store.getters.currentApplicationStudentAward;
    }
  },
  methods: {
    save() {
      this.saving = true;
      let form = this.studentAward;
      form.school_id = this.$route.params.school_id;
      this.$store.dispatch(POST_SCHOOL_STUDENT_AWARD, form).then(() => {
        this.saving = false;
        this.$emit("done");
        bus.$emit('regetAwardBalances');
      })
        .catch(() => {
          this.saving = false;
        });
    }
  },
}
</script>

<style scoped></style>
