<template>
  <div style="border-top: 1px dashed var(--apsto-border); border-bottom: 1px dashed var(--apsto-border);">
    <div class="pt-5">Upload ADOR Approval Form</div>
    <v-row>
      <v-col>
        <v-file-input
          v-model="file"
          :error="!!errors.file"
          :error-messages="errors.file ? errors.file : null"
          :rules="rules"
          accept=".pdf"
          background-color="white"
          class="mt-5"
          label="Select file"
          outlined
          show-size
          small-chips
          style="border-radius: 0"
          truncate-length="15"
        />

        <v-btn
          :disabled="!showUploadButton"
          color="primary"
          rounded
          style="min-width: 140px;"
          @click="saveFile"
        >
          Upload
        </v-btn>
        <div
          v-if="corporatePledge.files && corporatePledge.files.length"
          class="teal-text mt-5 pb-5"
        >
          Last file uploaded for this request:
          <a
            :href="'/corporate-pledge-documents/' + corporatePledge.files[0].id"
            target="_blank"
          >
            <span class="text-apsto-teal">{{ corporatePledge.files[0].title }}</span>
          </a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {POST_ADOR_APPROVAL_DOCUMENT_UPLOAD} from "../../../store/actions.type";

export default {
  name: "AdorApprovalFileUpload",
  data() {
    return {
      loading: true,
      file: null,
      rules: [
        value => !value || value.size < 2048000 || 'File size should be less than 2 MB!',
      ],
      fileId: null,
      fileName: null,
      showFileContent: false,
    }
  },
  computed: {
    corporatePledge() {
      return this.$store.getters.currentCorporatePledge;
    },
    showUploadButton() {
      return this.file;
    },
    error() {
      return this.$store.getters.getMessage("corporatePledges");
    },
    errors() {
      return this.$store.getters.getErrors("corporatePledges");
    },
  },
  methods: {
    saveFile() {
      this.saving = true;
      let corporate_pledge_id = this.corporatePledge.id;
      let file = this.file;
      this.$store.dispatch(POST_ADOR_APPROVAL_DOCUMENT_UPLOAD,
        {
          corporate_pledge_id,
          file
        }
      )
        .then(() => {
          this.file = null;
          this.saving = false;
        });
    },
  },
}

</script>

<style scoped></style>
