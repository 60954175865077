<template>
    <div>
        <v-row
            v-if="!isMobile"
            class="my-2"
        >
            <v-col class="text-h6-mulish"> The following donations are included in your tax receipt for {{
                donation.taxYear
            }}:
            </v-col>
        </v-row>
        <v-data-table
            v-if="donation.donorDonations.length > 0"
            :disable-sort="isMobile"
            :headers="headers"
            :hide-default-footer="isMobile"
            :items="donation.donorDonations"
            class="elevation-2"
        >
            <template v-slot:top>
                <v-toolbar
                    v-if="isMobile"
                    color="primary"
                    flat
                >
                    <v-row>
                        <v-col>
                            Contributions Included in {{ donation.taxYear }} Tax Receipt
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template #item.amount="{ item, index }">
                <div v-if="!index">{{ item.amount | formatCurrencyWithCents }}</div>
                <div v-else>{{ item.amount | formatNumberWithCents }}</div>
            </template>
            <template #item.original="{ item, index }">
                <div v-if="!index">{{ item.original | formatCurrencyWithCents }}</div>
                <div v-else>{{ item.original | formatNumberWithCents }}</div>
            </template>
            <template #item.switcher="{ item, index }">
                <div v-if="!index">{{ item.switcher | formatCurrencyWithCents }}</div>
                <div v-else>{{ item.switcher | formatNumberWithCents }}</div>
            </template>
            <template #item.none="{ item, index }">
                <div v-if="!index">{{ item.none | formatCurrencyWithCents }}</div>
                <div v-else>{{ item.none | formatNumberWithCents }}</div>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {
    name: "AnnualDonationReceiptTable",
    props: {
        donation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            headers: [
                { text: 'Date', value: 'created_at' },
                { text: 'Type', value: 'type' },
                { text: 'STO Name', value: 'sto_name' },
                { text: 'Amount', value: 'amount', align: 'end' },
                { text: 'Original', value: 'original', align: 'end' },
                { text: 'Switcher', value: 'switcher', align: 'end' },
                { text: 'Charitable', value: 'none', align: 'end' },
                { text: '', value: '', align: 'end' },
            ],
        }
    },
}
</script>

