<template>
  <loading v-if="loading"/>
  <div v-else>

    <div
      class="text-h6-mulish box-with-background mb-5"
      style="color: white;"
    >
      The following Award Refunds have been applied
    </div>

    <data-table-deposit-refunds/>

    <v-btn
      v-if="depositNotFullyApplied"
      class="my-5"
      color="primaryLight"
      @click="addAwardRefund"
    >
      <v-icon
        left
        small
        style="color: white;"
      >
        mdi-plus
      </v-icon>
      <span style="color: white;">Add Award Refund</span>
    </v-btn>
    <v-btn
      v-else
      class="my-5"
      color="success white--text"
    >
      <v-icon
        color="white"
        left
        small
      >
        mdi-check
      </v-icon>
      <span>Deposit Fully Applied</span>
    </v-btn>

    <v-row
      class="my-3"
      no-gutters
    >
      <v-col>
        <v-sheet
          :color="depositNotFullyApplied ? 'error' : 'success'"
          dark
          rounded
        >


          <v-row
            justify="space-between"
            no-gutters
          >
            <v-col cols="auto">
              <v-card
                color="transparent"
                flat
                outlined
                rounded
              >

                <v-card-title>Total Refunds Applied</v-card-title>
                <v-card-text class="font-weight-bold text-h6">
                  {{ awardRefundsTotal | formatCurrencyWithCents }} of {{ deposit.amount | formatCurrencyWithCents }}
                </v-card-text>
              </v-card>

            </v-col>
            <v-col cols="auto">
              <v-card
                color="transparent"
                flat
                outlined
                rounded
              >
                <v-card-title>Remaining</v-card-title>
                <v-card-text class="font-weight-bold text-right text-h6">
                  {{ refundsToDepositRemaining | formatCurrencyWithCents }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>


        </v-sheet>
      </v-col>
    </v-row>

    <v-card
      v-if="depositNotFullyApplied"
      class="my-3"
      color="error"
      dark
      flat
      rounded
    >
      <v-card-text>
        You must apply refunds to the entire deposit amount to continue.
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="addAwardRefundDialog"
      max-width="500px"
    >
      <admin-deposit-award-refund-form @done="closeAdminDepositAwardRefundDialog"/>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import DataTableDepositRefunds from "./DataTableDepositRefunds";
import SchoolSelector from "../../shared/SchoolSelector";
import AdminDepositAwardRefundForm from "./AdminDepositAwardRefundForm";
import {GET_ADMIN_DEPOSIT_AWARD_REFUNDS} from "../../../store/actions.type";

export default {
  name: "AdminDepositFormAwardRefunds",
  components: {
    Loading,
    DataTableDepositRefunds,
    SchoolSelector,
    AdminDepositAwardRefundForm,
  },
  data() {
    return {
      loading: true,
      addAwardRefundDialog: false,
    }
  },
  computed: {
    deposit() {
      return this.$store.getters.currentAdminDeposit;
    },
    awardRefunds() {
      return this.$store.getters.currentAdminDepositAwardRefunds;
    },
    awardRefundsTotal() {
      return this.awardRefunds.reduce((total, refund) => {
        return parseFloat(total) + parseFloat(refund.amount);
      }, 0);
    },
    refundsToDepositRemaining() {
      return parseFloat(this.deposit.amount) - parseFloat(this.awardRefundsTotal);
    },
    depositNotFullyApplied() {
      return this.refundsToDepositRemaining > 0;
    },
  },
  methods: {
    getAwardRefunds() {
      this.$store.dispatch(GET_ADMIN_DEPOSIT_AWARD_REFUNDS, this.deposit.id)
        .then(() => {
          this.loading = false;
        })
    },
    addAwardRefund() {
      this.addAwardRefundDialog = true;
    },
    closeAdminDepositAwardRefundDialog() {
      this.addAwardRefundDialog = false;
      this.$store.dispatch(GET_ADMIN_DEPOSIT_AWARD_REFUNDS, this.deposit.id)
        .then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAwardRefunds();
  },
}
</script>

<style scoped>
::v-deep .theme--light.v-icon {
  color: var(--apsto-teal);
}
</style>
