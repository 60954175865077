<template>
    <div>
        <div v-if="editMode">
            <v-text-field
                v-model="localContent.body.section"
                label="Section"
                @change="updateContent"
                @focus="lockFab"
            />
        </div>
        <div v-else>

            <div
                v-if="$router.currentRoute.path === '/corporate-donation-request-online-form'"
                class="printable-form-offer"
            >
                Please login or create an account to complete a donation request form online.
                <div class="mt-5">
                    Prefer not to login or register? Use our
                    <a href="/siteDocuments/slug/corporate-donation-request-printable-form">
                        printable form
                    </a>
                    instead or call us at (480) 256-1001.
                </div>
            </div>

            <div
                v-if="$router.currentRoute.path === '/partner-with-us'"
                class="printable-form-offer"
            >
                Please login or create an account if you are an administrator of a school that's on our Approved Schools
                List.
                <div class="mt-5">
                    Note, we will be taking applications for the 2024/2025 school year starting May 16th.
                </div>

            </div>

            <div id="pal" class="major-heading">Portal Account Login</div>

            <v-row class="mt-0">
                <v-col class="mr-5">

                    <login-form
                        class="mt-5"
                        @done="hideCheckDataByEmailForm"
                    />
                    <!--                    <div class="first-heading">-->
                    <!--                        Not sure which email(s) you've used with us before?-->
                    <!--                        <v-btn-->
                    <!--                            class="ml-1"-->
                    <!--                            color="primary"-->
                    <!--                            style="cursor: pointer;"-->
                    <!--                            @click="showCheckDataByEmailForm"-->
                    <!--                        >-->
                    <!--                            Click here to check-->
                    <!--                        </v-btn>-->
                    <!--                    </div>-->

                    <!--                    <check-data-by-email-form-->
                    <!--                        class="mt-5"-->
                    <!--                    />-->
                </v-col>
                <v-col>
                    <register-form
                        :section="section"
                        class="mt-sm-5"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import CheckDataByEmailForm from "../../../shared/CheckDataByEmailForm.vue";
import LoginForm from '../../../../components/shared/LoginForm.vue'
import RegisterForm from '../../../../components/shared/RegisterForm.vue'
import {PATCH_CONTENT} from "../../../../store/actions.type";
import {SET_CHECK_DATA_BY_EMAIL_FORM, SET_FAB_ENABLED} from "../../../../store/mutations.type";

export default {
    name: "LoginRegister",
    components: {
        CheckDataByEmailForm,
        LoginForm,
        RegisterForm
    },
    props: {
        content: {
            type: Object,
        },
    },
    computed: {
        editMode() {
            return this.$store.getters.currentEditMode;
        },
        section() {
            return this.content.body.section.toString();
        },
        isShowingCheckDataByEmailForm() {
            return this.$store.getters.isShowingCheckDataByEmailForm;
        },
    },
    data() {
        return {
            loading: false,
            localContent: null,
        };
    },
    created() {
        this.localContent = this.content;
    },
    mounted() {
        if (!this.isAuthenticated) return;
        if (this.editMode) return;

        this.$router.push({name: this.content.body.section});
    },
    methods: {
        updateContent(value) {
            this.loading = true;
            let self = this;
            let content = this.localContent;
            this.$store.dispatch(PATCH_CONTENT, content)
                .then(() => {
                    this.$store.commit(SET_FAB_ENABLED, true);
                    self.loading = false;
                })
                .catch((e) => {
                    self.loading = false;
                });

        },
        lockFab() {
            this.$store.commit(SET_FAB_ENABLED, false);
        },
        hideCheckDataByEmailForm() {
            this.$store.commit(SET_CHECK_DATA_BY_EMAIL_FORM, false);
        },
        showCheckDataByEmailForm() {
            this.$store.commit(SET_CHECK_DATA_BY_EMAIL_FORM, true);
        },
    },
};

</script>


<style scoped>
.major-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 1.4rem;
    font-weight: bold;
}

.printable-form-offer {
    background-color: var(--apsto-teal-very-light);
    border: 1px solid var(--apsto-teal);
    font-family: 'Roboto Slab', serif;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 30px;
    margin-bottom: 30px;
}
</style>
