<template>
  <v-data-table
    :headers="headers"
    :items="donorRecommendations"
    class="elevation-2"
    sort-by="name"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn
          v-if="taxCreditType === 'switcher' && donorRecommendationsOriginalExist"
          color="primary"
          dark
          rounded
          @click="copyFromOriginal"
        >
          Copy From Original Credit
        </v-btn>
        <v-spacer/>
        <v-btn
          color="primary"
          dark
          rounded
          @click="createItem"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          Add Student
        </v-btn>
        <v-dialog
          v-model="addEditDialog"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <recommendation-student
            :edited-item="temporaryItem"
            :tax-credit-type="taxCreditType"
            @done="addEditDialog = false"
          />
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <v-card>
            <v-card-title class="text-h5"> Confirm</v-card-title>
            <v-card-text
              class="pa-4 pl-6 black--text"
              style="font-size: 1.1rem"
            >
              Are you sure you want to delete this student?
            </v-card-text>
            <v-card-actions class="pt-3 pb-4">
              <v-spacer/>
              <v-btn
                class="cancel-button"
                rounded
                @click="dialogDelete = false"
              >Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- Actions -->
    <template v-slot:item.actions="{ item }">
      <v-tooltip
        bottom
        color="secondary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-2"
            small
            v-bind="attrs"
            @click="editItem(item)"
            v-on="on"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Edit student</span>
      </v-tooltip>
      <v-icon
        color="red"
        small
        @click="deleteItem(item)"
      > mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span class="text-apsto-teal"> No students have been added. Click the Add Student button above to start.</span>
    </template>
    <template #item.school_id="{ item }">
      <div>
        {{ item.school_name }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import RecommendationStudent from "./RecommendationStudent";
import {COPY_DONOR_STUDENT_RECOMMENDATIONS, REMOVE_DONOR_STUDENT_RECOMMENDATION} from "../../store/mutations.type";

export default {
  name: "RecommendationPageStudentsTable",
  components: {RecommendationStudent},
  props: {
    taxCreditType: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    addEditDialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Student Name",
        align: "start",
        value: "name",
      },
      {text: "School They Attend", value: "school_id"},
      {text: "Edit / Delete", value: "actions", sortable: false},
    ],
    temporaryItem: null,
    snackbar: false,
    snackbarMessage: "",
  }),
  computed: {
    defaultItem() {
      return {
        id: 0,
        name: "",
        school_id: 0,
        school_name: "",
      };
    },
    donorRecommendations() {
      return this.$store.getters.currentDonorRecommendationStudents(this.taxCreditType);
    },
    donorRecommendationsOriginalExist() {
      return !!(this.$store.getters.currentDonorRecommendationStudents("original") && this.$store.getters.currentDonorRecommendationStudents("original").length);
    },
    error() {
      return this.$store.getters.getMessage("donors");
    },
    errors() {
      return this.$store.getters.getErrors("donors");
    },
  },
  methods: {
    copyFromOriginal() {
      this.$store.commit(COPY_DONOR_STUDENT_RECOMMENDATIONS, this.$store.getters.currentDonorRecommendationStudents("original"));
    },
    createItem() {
      this.temporaryItem = Object.assign({}, this.defaultItem);
      this.addEditDialog = true;
    },
    editItem(item) {
      this.temporaryItem = Object.assign({}, item);
      this.addEditDialog = true;
    },
    deleteItem(item) {
      this.temporaryItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let id = this.temporaryItem.id;
      let taxCreditType = this.taxCreditType;
      this.$store.commit(REMOVE_DONOR_STUDENT_RECOMMENDATION, {taxCreditType, id})
      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>::v-deep tbody td {
  height: 60px !important;
}

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep .v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}</style>
