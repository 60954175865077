import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=474c0e54&scoped=true&"
import script from "./LoginForm.vue?vue&type=script&lang=js&"
export * from "./LoginForm.vue?vue&type=script&lang=js&"
import style0 from "./LoginForm.vue?vue&type=style&index=0&id=474c0e54&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474c0e54",
  null
  
)

export default component.exports