<template>
    <loading v-if="loading" />
    <v-row
        v-else
        class="mt-n6"
    >
        <v-col>
            <div
                v-if="isMobile"
                class="mx-2 my-4 text-h6-mulish"
            >
                <router-link :to="{ name: 'school', params: { school_id: school.id } }">
                    <span class="text-apsto-teal">{{ school.name }}</span>
                </router-link>
                <div class="text-apsto-purple text-h7-mulish">
                    {{ pageTitle }}
                </div>
            </div>
            <v-card-title v-if="!isMobile">
                <router-link :to="{ name: 'school', params: { school_id: school.id } }">
                    <span class="text-apsto-teal">{{ school.name }} /&nbsp;</span>
                </router-link>
                <div class="text-apsto-purple">
                    {{ pageTitle }}
                </div>
            </v-card-title>
        </v-col>
    </v-row>
</template>

<script>
import Loading from "../shared/Loading";
import { GET_USER_SCHOOL } from "../../store/actions.type";

export default {
    name: "SchoolHeader",
    components: {
        Loading,
    },
    props: {
        pageTitle: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        school() {
            return this.$store.getters.currentUserSchool;
        }
    },
    mounted() {
        this.$store.dispatch(GET_USER_SCHOOL, this.$route.params.school_id)
            .then(() => {
                this.loading = false;
            });
    },
};
</script>

<style scoped></style>

