<template>
    <div>
        <v-card>
            <v-row class="mx-2">
                <v-col
                    :cols="isMobile ? 12 : ''"
                    :class="isMobile ? 'mt-3' : ''"
                >
                    <v-checkbox
                        v-model="application.hardship.hardship_bankruptcy"
                        :error="!!errors['hardship.hardship_bankruptcy']"
                        :error-messages="errors['hardship.hardship_bankruptcy'] ? errors['hardship.hardship_bankruptcy'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_bankruptcy')"
                    >
                        <template v-slot:label>
                            <div> Bankruptcy</div>
                        </template>
                    </v-checkbox>
                    <v-checkbox
                        v-model="application.hardship.hardship_employment_change"
                        :error="!!errors['hardship.hardship_employment_change']"
                        :error-messages="errors['hardship.hardship_employment_change'] ? errors['hardship.hardship_employment_change'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_employment_change')"
                    >
                        <template v-slot:label>
                            <div> Changed Employment</div>
                        </template>
                    </v-checkbox>

                    <v-checkbox
                        v-model="application.hardship.hardship_family_size_change"
                        :error="!!errors['hardship.hardship_family_size_change']"
                        :error-messages="errors['hardship.hardship_family_size_change'] ? errors['hardship.hardship_family_size_change'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_family_size_change')"
                    >
                        <template v-slot:label>
                            <div> Family Size Changed</div>
                        </template>
                    </v-checkbox>

                    <v-checkbox
                        v-model="application.hardship.hardship_general_expenses"
                        :error="!!errors['hardship.hardship_general_expenses']"
                        :error-messages="errors['hardship.hardship_general_expenses'] ? errors['hardship.hardship_general_expenses'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_general_expenses')"
                    >
                        <template v-slot:label>
                            <div> General Expenses Increased</div>
                        </template>
                    </v-checkbox>

                    <v-checkbox
                        v-model="application.hardship.hardship_loss_of_home"
                        :error="!!errors['hardship.hardship_loss_of_home']"
                        :error-messages="errors['hardship.hardship_loss_of_home'] ? errors['hardship.hardship_loss_of_home'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_loss_of_home')"
                    >
                        <template v-slot:label>
                            <div> Loss of Home</div>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col
                    :cols="isMobile ? 12 : ''"
                    :class="isMobile ? 'mt-n6' : ''"
                >
                    <v-checkbox
                        v-model="application.hardship.hardship_loss_of_income"
                        :error="!!errors['hardship.hardship_loss_of_income']"
                        :error-messages="errors['hardship.hardship_loss_of_income'] ? errors['hardship.hardship_loss_of_income'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_loss_of_income')"
                    >
                        <template v-slot:label>
                            <div> Loss of Income</div>
                        </template>
                    </v-checkbox>

                    <v-checkbox
                        v-model="application.hardship.hardship_medical_expenses"
                        :error="!!errors['hardship.hardship_medical_expenses']"
                        :error-messages="errors['hardship.hardship_medical_expenses'] ? errors['hardship.hardship_medical_expenses'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_medical_expenses')"
                    >
                        <template v-slot:label>
                            <div> Medical Expenses Increased</div>
                        </template>
                    </v-checkbox>

                    <v-checkbox
                        v-model="application.hardship.hardship_tuition_expenses"
                        :error="!!errors['hardship.hardship_tuition_expenses']"
                        :error-messages="errors['hardship.hardship_tuition_expenses'] ? errors['hardship.hardship_tuition_expenses'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_tuition_expenses')"
                    >
                        <template v-slot:label>
                            <div> Tuition Expenses Increased</div>
                        </template>
                    </v-checkbox>

                    <v-checkbox
                        v-model="application.hardship.hardship_other"
                        :error="!!errors['hardship.hardship_other']"
                        :error-messages="errors['hardship.hardship_other'] ? errors['hardship.hardship_other'] : null"
                        color="secondary"
                        @change="updateCheckbox('hardship_other')"
                    >
                        <template v-slot:label>
                            <div> Other</div>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row class="mx-2">
                <v-col>
                    <div class="text-subtitle-2 pb-1">
                        Please add any comments you'd like to share.
                    </div>
                    <v-textarea
                        :counter-value="(v) => (v || '').trim().split(' ').length"
                        :error-messages="errors['hardship.hardship_comments'] ? errors['hardship.hardship_comments'] : null"
                        :error="!!errors['hardship.hardship_comments']"
                        :rules="wordsRules"
                        @blur="updateComments"
                        auto-grow
                        background-color="secondaryLight"
                        counter
                        maxlength="500"
                        v-model="application.hardship.hardship_comments"
                    />
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import {
    PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_CHECKBOX,
    PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_COMMENTS
} from "../../../store/actions.type";

export default {
    name: "FinancialHardship",
    data: () => ({
        v: '',
        wordsRules: [v => (v || '').trim().split(' ').length <= 500 || 'Max 500 words'],
    }),
    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        error() {
            return this.$store.getters.getMessage("application");
        },
        errors() {
            return this.$store.getters.getErrors("application");
        },
    },
    methods: {
        updateCheckbox(field_name) {
            this.saving = true;
            let application_id = this.$route.params.application_id;
            let household_id = this.$route.params.household_id;
            this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_CHECKBOX,
                {
                    household_id,
                    application_id,
                    field_name,
                }
            )
                .then(() => {
                    this.saving = false;
                });
        },
        updateComments() {
            this.saving = true;
            let application = this.application;
            let household_id = this.$route.params.household_id;
            this.$store.dispatch(PATCH_HOUSEHOLD_APPLICATION_HARDSHIP_COMMENTS,
                {
                    household_id,
                    application
                }
            )
                .then(() => {
                    this.saving = false;
                });
        }
    }
}
</script>

<style scoped>
::v-deep .v-textarea textarea {
    padding: 10px;
}
</style>
