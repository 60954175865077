<template>
  <div class="content">
    <div class="font-weight-bold">There are two ways for a student to qualify for the {{
        item.scholarship_label
      }}:
    </div>
    <div class="mt-2">
      (1) the student is disabled, OR
      <br>
      (2) the student is or has been placed in the Arizona foster care system at any time
      before graduating high school.
    </div>
    <div
      v-if="item.qualifies === 'Yes'"
      class="mt-2 font-weight-bold"
    >
      <div v-if="item.model.student.disabled && !item.model.student.foster">
        You've indicated that {{ item.model.first_name }} meets the disabled prerequisite during this
        application school year.
      </div>
      <div v-else-if="item.model.student.foster && !item.model.student.disabled">
        You've indicated that {{ item.model.first_name }} meets the Arizona foster care prerequisite during this
        application school year.
      </div>
      <div v-else>
        You've indicated that {{ item.model.first_name }} meets both the disabled prerequisite and the Arizona
        foster care prerequisite during this
        application school year.
      </div>
    </div>
    <div
      v-else
      class="mt-2"
    >
      <div v-if="item.model.student.esa_enum === 3">
        However {{ item.model.first_name }} is enrolled in the ESA program and cannot qualify for any tax credit
        scholarship while in that program.
      </div>
      <div v-else>
        You've indicated that {{ item.model.first_name }} did not meet either prerequisite during this application
        school year.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScholarshipDisabledDisplaced",
  props: {
    item: {
      type: Object,
      required: true,
    },
  }
}

</script>

<style scoped>
.content {
  font-family: "Mulish", sans-serif;
  font-size: .9rem;
  line-height: 1.40rem;
}
</style>
