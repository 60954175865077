<template>
    <v-row>
        <v-col md="3"/>
        <v-col
            class="mt-5"
            md="6"
        >
            <v-card>
                <div class="v-card-title">
                    <div class="text-h5-mulish">
                        Thank you for choosing to partner with APSTO!
                    </div>
                </div>
                <div class="mt-5 mx-10">
                    <v-radio-group v-model="offerToLogin">
                        <template v-slot:label>
                            <span
                                class="text-h6-mulish"
                                style="color: black;"
                            >
                                Would you like to create a donor portal account?
                            </span>
                        </template>
                        <v-radio
                            :value="true"
                            color="secondary"
                            label="Yes"
                        />
                        <v-radio
                            :value="false"
                            color="secondary"
                            label="No"
                        />
                    </v-radio-group>

                    <div class="text-h6-mulish">
                        Donor Portal Benefits
                    </div>
                    <ul>
                        <li class="list-item-title">View Donation History</li>
                        <li class="list-item-title">Faster Donations in the Future</li>
                        <li class="list-item-title">Print Tax Receipts</li>
                        <li class="list-item-title">Schedule Monthly Contributions</li>
                        <li class="list-item-title">Enroll in Withholdings Program</li>
                    </ul>
                </div>

                <v-card-actions class="ma-5">
                    <v-btn
                        class="guest-mimic-logged-in-btn"
                        color="primary"
                        rounded
                        @click="submit"
                    >SUBMIT
                    </v-btn>
                </v-card-actions>

                <div class="v-card-title mt-5 font-mulish">
                    We've sent you an email containing a donation receipt to use when filing your taxes. Please check
                    your
                    spam folder if you don't see it.
                </div>

            </v-card>
        </v-col>
        <v-col md="3"/>
    </v-row>
</template>

<script>
import {RECORD_ACCEPTANCE_OF_OFFER_TO_REMEMBER_USER} from "../../store/actions.type";

export default {
    name: "GuestDonationAcknowledgement",
    data() {
        return {
            offerToLogin: true
        }
    },
    methods: {
        submit() {
            if (this.offerToLogin) {
                let email = this.$route.params.email;
                this.$store.dispatch(RECORD_ACCEPTANCE_OF_OFFER_TO_REMEMBER_USER, email)
                    .then(() => {
                        this.$router.push({name: 'home'})
                    })
            } else {
                this.$router.push({name: 'home'})
            }
        }
    }
}
</script>

<style scoped>
.v-card-title {
    padding: 20px 40px;
    background-color: var(--apsto-teal-light);
    justify-content: center;
}

::v-deep .theme--light.v-label {
    color: rgba(0, 0, 0);
    font-size: 1.05rem;
}

.list-item-title {
    color: rgba(0, 0, 0);
    font-family: "Mulish", sans-serif !important;
    font-size: 1.05rem;
    margin-top: 10px;
}
</style>
