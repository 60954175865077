<template>
    <loading v-if="loading" />
    <div
        v-else
        class="mx-2"
    >
        <v-row>
            <v-col md="6">
                <donor-header page-title="Donations" />
            </v-col>
        </v-row>
        <annual-donation-receipt
            :current-year="true"
            :donation="donationsHistory.currentYearAmounts"
        />

        <annual-donation-receipt
            :donation="donationsHistory.priorYearAmounts"
            class="mt-2"
        />

    </div>
</template>

<script>
import AnnualDonationReceipt from "./AnnualDonationReceipt.vue";
import Loading from "../../../components/shared/Loading";
import { GET_LAST_TWO_YEARS_DONATIONS_HISTORY } from "../../../store/actions.type";
import DonorHeader from "../../../components/donors/DonorHeader.vue";

export default {
    name: "AnnualDonationReceipts",
    components: { DonorHeader, AnnualDonationReceipt, Loading },
    data() {
        return {
            loading: true,
            form: {
                tax_year: "prior"
            }
        };
    },
    computed: {
        taxYearFactors() {
            return this.$store.getters.currentTaxYearFactors;
        },
        donationsHistory() {
            return this.$store.getters.currentDonorTwoYearDonationsHistory;
        },
    },
    methods: {
        getLastTwoYearsDonationsHistory() {
            this.$store.dispatch(GET_LAST_TWO_YEARS_DONATIONS_HISTORY)
                .then(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getLastTwoYearsDonationsHistory();
    }
}
</script>

<style scoped></style>
