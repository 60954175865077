<template>
  <loading v-if="loading"/>
  <div v-else>
    <v-row
      align="center"
      class="ml-1 py-5"
    >
      <v-col md="6">
        <admin-header page-title="Contact Us Messages"/>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="contacts"
      class="elevation-2 mt-3"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialogDelete"
          :fullscreen="!!isMobile"
          :max-width="dialogMaxWidth"
        >
          <v-card>
            <v-card-title class="text-h5-mulish">
              Confirm
            </v-card-title>
            <v-card-text
              class="pa-4 pl-6 black--text"
              style="font-size: 1.1rem;"
            >
              Are you sure you want to delete this item?
            </v-card-text>
            <v-card-actions class="pt-3 pb-4">
              <v-spacer/>
              <v-btn
                class="cancel-button"
                rounded
                @click="closeDeleteContact"
              >
                Cancel
              </v-btn>
              <v-btn
                class="body-2 font-weight-bold"
                color="primary"
                @click="deleteContactConfirm"
              >
                YES
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #item.name="{ item }">
        <div class="py-5">
          Sent: {{ item.created_at }}
          <br>
          From: {{ item.name }}
          <br>
          Email: {{ item.email }}
          <br><br>
          Subject: {{ item.subject }}
          <br>
          Message: {{ item.message }}
        </div>
      </template>
      <template #item.is_read="{ item }">
        <v-switch
          v-model="item.is_read"
          @change="saveStatus(item)"
        />
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    No contact us messages at this time.
                </span>
      </template>
      <template v-slot:item.actionsContacts="{ item }">
        <v-tooltip
          bottom
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="red"
              v-bind="attrs"
              @click="deleteContact(item)"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Message</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {
  DELETE_CONTACT_US_MESSAGE,
  GET_CONTACT_US_MESSAGES,
  PATCH_CONTACT_READ_STATUS
} from "../../../store/actions.type";

export default {
  name: "AdminContactsList",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      defaultItem: {
        name: '',
        created_at: ''
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'MESSAGE', value: 'name'},
        {text: 'READ', value: 'is_read'},
        {text: 'DELETE', value: 'actionsContacts', sortable: false},
      ],
      loading: true,
    }
  },
  computed: {
    contacts() {
      return this.$store.getters.currentContacts;
    },
    error() {
      return this.$store.getters.getMessage("admin");
    },
    errors() {
      return this.$store.getters.getErrors("admin");
    },
  },
  methods: {
    getContacts() {
      this.loading = true;
      this.$store.dispatch(GET_CONTACT_US_MESSAGES)
        .then(() => {
          this.loading = false;
        });
    },
    closeDeleteContact() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteContact(item) {
      this.editedIndex = this.contacts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteContactConfirm() {
      this.destroyContact();
      this.contacts.splice(this.editedIndex, 1)
      this.closeDeleteContact()
    },
    destroyContact() {
      this.saving = true;
      let contact_id = this.editedItem.id;
      this.$store.dispatch(DELETE_CONTACT_US_MESSAGE, contact_id)
        .then(() => {
          this.saving = false;
        })
        .catch(({data}) => {
          this.snackbarMessage = data.message_response;

          this.snackbar = true;
        });
    },
    initialize() {
      this.contacts = [];
    },
    saveStatus(item) {
      console.log("item: ", item);
      let contact_id = item.id;
      this.$store.dispatch(PATCH_CONTACT_READ_STATUS, contact_id);
    },
  },
  mounted() {
    this.getContacts();
  },
}
</script>

<style scoped>
::v-deep tbody td {
  height: 60px !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: var(--apsto-teal-very-light);
}
</style>
