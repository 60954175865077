import { render, staticRenderFns } from "./StudentAvailableAwards.vue?vue&type=template&id=1a673e48&scoped=true&"
import script from "./StudentAvailableAwards.vue?vue&type=script&lang=js&"
export * from "./StudentAvailableAwards.vue?vue&type=script&lang=js&"
import style0 from "./StudentAvailableAwards.vue?vue&type=style&index=0&id=1a673e48&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a673e48",
  null
  
)

export default component.exports