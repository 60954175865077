<template>
    <div>
        <v-data-table
            v-if="applicationMembers.length"
            :disable-sort="true"
            :footer-props="{ itemsPerPageOptions: [10] }"
            :headers="headers"
            :hide-default-footer="true"
            :items="applicationMembers"
            :items-per-page="10"
            class="elevation-2 mb-5"
            sort-by="full_name"
            @click:row="editItem"
        >
            <template v-slot:top>
                <v-toolbar
                    color="secondary"
                    flat
                >
                    Household Members & Income Reported on 1040 for Calendar Year {{ priorCalendarYear }}
                    <v-icon
                        class="ml-2"
                        color="white"
                        @click="showIncomeInstructions = !showIncomeInstructions"
                    >
                        mdi-help-circle
                    </v-icon>

                </v-toolbar>
                <v-row
                    v-if="showIncomeInstructions"
                    class="earnings-explainer"
                >
                    <v-col :cols="isMobile ? 12 : 8">
                        Earnings From Work: List gross earnings from work (wages, salaries, tips, commissions). This is not
                        the same
                        as take home pay; it is the amount earned before taxes and deductions. Gross earnings should be
                        listed on
                        your pay stub. If self employed, you may report income after expenses (your own business, freelance
                        work,
                        farm, or rental property).
                        <br><br>
                        Welfare, Child Support, Spousal maintenance: Include TANF, General Assistance, General
                        Relief,
                        etc. NOTE: Food Stamps and FDPIR benefits are not included as income.
                        <br><br>
                        Pensions, Retirement, Social Security: Include Supplemental Security Income (SSI), Veteran’s
                        (VA) benefits, and disability
                        benefits.
                        <br><br>
                        All Other Income: Include Worker’s Compensations, unemployment, strike benefits, net rental income,
                        annuities, net royalties, interest, dividend income, cash withdrawn from savings, income from
                        estates,
                        trust and / or investments, regular contributions from people who do not live in your household, and
                        ANY
                        OTHER INCOME.
                    </v-col>
                </v-row>
            </template>

            <template
                v-if="!isMobile"
                v-slot:body.append
            >
                <tr>
                    <td
                        class="name"
                        colspan="2"
                    >Totals
                    </td>
                    <td class="earnings">{{ itemsTotalIncome.earnings | formatCurrency }}</td>
                    <td class="earnings">{{ itemsTotalIncome.welfare | formatCurrency }}</td>
                    <td class="earnings">{{ itemsTotalIncome.pension | formatCurrency }}</td>
                    <td class="earnings">{{ itemsTotalIncome.other | formatCurrency }}</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <th
                        class="name header-footer"
                        colspan="2"
                    >
                        Total People Living in Your Household: {{ applicationMembers.length }}
                    </th>
                    <th
                        class="text-center header-footer"
                        colspan="5"
                    >
                        Total Annual Income: {{ itemsTotalIncome.total | formatCurrency }}
                    </th>
                </tr>
            </template>

            <template #item.income_earnings="{ item }">
                <div>{{ item.income_earnings | formatCurrency }}</div>
            </template>
            <template #item.income_welfare="{ item }">
                <div>{{ item.income_welfare | formatCurrency }}</div>
            </template>
            <template #item.income_pension="{ item }">
                <div>{{ item.income_pension | formatCurrency }}</div>
            </template>
            <template #item.income_other="{ item }">
                <div>{{ item.income_other | formatCurrency }}</div>
            </template>
            <template #item.income_none="{ item }">
                <div v-if="item.income_none">Yes</div>
                <div v-else>No</div>
            </template>
        </v-data-table>

        <v-row
            v-if="isMobile"
            class="pt-0"
        >
            <v-col>
                <v-card class="dialog">
                    <v-card-title
                        class="pa-4"
                        style="background-color: var(--apsto-purple);font-size: 1rem;"
                    >
                        Household Members & Income Totals
                    </v-card-title>
                    <v-card-text class="">
                        <v-row class="mt-2">
                            <div class="v-data-table__wrapper">
                                <table>
                                    <tbody>
                                        <tr class="v-data-table__mobile-table-row">

                                            <td class="v-data-table__mobile-row">
                                                <div class="v-data-table__mobile-row__header">Earnings from Work Before
                                                    Deductions
                                                </div>
                                                <div class="v-data-table__mobile-row__cell">
                                                    {{ itemsTotalIncome.earnings | formatCurrency }}
                                                </div>
                                            </td>

                                            <td class="v-data-table__mobile-row">
                                                <div class="v-data-table__mobile-row__header">Welfare, Child Support,
                                                    Spousal Maintenance
                                                </div>
                                                <div class="v-data-table__mobile-row__cell">
                                                    {{ itemsTotalIncome.pension | formatCurrency }}
                                                </div>
                                            </td>

                                            <td class="v-data-table__mobile-row">
                                                <div class="v-data-table__mobile-row__header">Pensions, Retirement, Social
                                                    Security
                                                </div>
                                                <div class="v-data-table__mobile-row__cell">
                                                    {{ itemsTotalIncome.pension | formatCurrency }}
                                                </div>
                                            </td>

                                            <td class="v-data-table__mobile-row">
                                                <div class="v-data-table__mobile-row__header">All Other Income</div>
                                                <div class="v-data-table__mobile-row__cell">
                                                    {{ itemsTotalIncome.other | formatCurrency }}
                                                </div>
                                            </td>

                                            <td class="v-data-table__mobile-row">
                                                <div class="v-data-table__mobile-row__header">Total Income</div>
                                                <div class="v-data-table__mobile-row__cell">{{
                                                    itemsTotalIncome.total |
                                                    formatCurrency
                                                }}
                                                </div>
                                            </td>

                                            <td class="v-data-table__mobile-row">
                                                <div class="v-data-table__mobile-row__header">Total People in Your Household
                                                </div>
                                                <div class="v-data-table__mobile-row__cell">{{ applicationMembers.length }}
                                                </div>
                                            </td>

                                            <!--Needs to remain-->
                                            <td class="v-data-table__mobile-row"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showMemberIncomeDialog"
            :fullscreen="!!isMobile"
            :max-width="dialogMaxWidth"
        >
            <family-member-income
                :edited-item="temporaryItem"
                @done="showMemberIncomeDialog = false"
            />
        </v-dialog>
        <div
            v-if="!isMobile && itemsTotalIncome.total"
            class="font-mulish"
            style="background-color: white; padding: 10px 16px;"
        >
            <div class="font-weight-black">Low Income Corporate Tax Credit Scholarship</div>
            <div class="mt-2">
                For a family of
                {{ applicationMembers.length }},
                household income meets the income requirement for the <strong>low income corporate tax credit
                    scholarship</strong> for this
                application school year if your household reports income and that income does not exceed
                <span class="text-apsto-teal">
                    {{ application.maximum_income_threshold_to_qualify | formatCurrency }}
                </span>.
                Your household income is
                <span class="text-apsto-teal">{{ itemsTotalIncome.total | formatCurrency }}</span>
                which
                {{
                    itemsTotalIncome.total > application.maximum_income_threshold_to_qualify ? 'exceeds' : 'does not exceed'
                }}
                the maximum allowed income requirement for this school year.
                Consequently, your family's earnings
                <span class="text-apsto-teal">{{
                    itemsTotalIncome.total > application.maximum_income_threshold_to_qualify ? 'do not meet' : 'meet'
                }}</span>
                the income requirement for the <strong>low income corporate tax credit scholarship</strong> for this
                application school year.
            </div>
        </div>
    </div>
</template>
<script>
import UserProfileName from "../../../views/users/UserProfileName.vue";
import FamilyMember from "./FamilyMember.vue";
import FamilyMemberIncome from "./FamilyMemberIncome.vue";

export default {
    name: "FamilyMemberIncomeTable",
    components: {
        FamilyMemberIncome,
        FamilyMember,
        UserProfileName
    },
    props: {
        allowEditing: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        memberCount: 1,
        showIncomeInstructions: false,
        showMemberIncomeDialog: false,
        temporaryItem: null,
    }),
    computed: {
        application() {
            return this.$store.getters.currentApplication;
        },
        applicationMembers() {
            return this.$store.getters.currentApplicationMembers;
        },
        headers() {
            return [
                { text: "Type", value: "member_type" },
                { text: "Name", value: "full_name" },
                { text: "Earnings from Work Before Deductions", value: "income_earnings", align: "end" },
                { text: "Welfare, Child Support, Spousal Maintenance", value: "income_welfare", align: "end" },
                { text: "Pensions, Retirement, Social Security", value: "income_pension", align: "end" },
                { text: "All Other Income", value: "income_other", align: "end" },
                { text: "No Income Checked", value: "income_none", align: "center" },
            ];
        },
        itemsTotalIncome() {
            let earnings = 0, other = 0, pension = 0, welfare = 0;
            this.applicationMembers.forEach(function (obj) {
                earnings += obj.income_earnings === null ? 0 : parseFloat(obj.income_earnings);
                other += obj.income_other === null ? 0 : parseFloat(obj.income_other);
                pension += obj.income_pension === null ? 0 : parseFloat(obj.income_pension);
                welfare += obj.income_welfare === null ? 0 : parseFloat(obj.income_welfare);
            });
            return {
                earnings: earnings,
                other: other,
                pension: pension,
                total: earnings + other + pension + welfare,
                welfare: welfare
            }
        },
        priorCalendarYear() {
            return parseInt(this.application.school_year_start_year.substring(0, 4)) - 1;
        },
    },
    methods: {
        editItem(item) {
            this.temporaryItem = Object.assign({}, item);
            this.showMemberIncomeDialog = this.allowEditing;
        },
    }
}
</script>


<style scoped>
.earnings {
    text-align: right !important;
    width: 15%;
}

.earnings.centered {
    text-align: center !important;
}

.earnings-explainer {
    background-color: var(--apsto-teal-very-light) !important;
    font-family: "Mulish", sans-serif !important;
    margin-bottom: 10px !important;
    padding: 20px !important;
}

.header-footer {
    background-color: var(--apsto-teal) !important;
    color: white !important;
}

.v-data-table__mobile-row__cell {
    color: var(--apsto-black) !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep thead th {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}
</style>
