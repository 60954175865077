<template>
    <loading v-if="loading"/>
    <div v-else>

        <v-row v-if="isMobile">
            <v-col>
                <donor-header page-title="Payroll Withholding Status"/>
            </v-col>
        </v-row>

        <v-row
            v-if="!isMobile"
            align="center"
        >
            <v-col md="6">
                <donor-header page-title="Payroll Withholding Status"/>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn
                    :disabled="disableStartWithholding"
                    :to="{ name: 'donor-donation-withholding-create' }"
                    color="primary"
                    rounded
                >
                    <v-icon left> mdi-plus-circle-outline</v-icon>
                    Start Payroll Withholding
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :class="isMobile ? '' : 'ml-4 mt-5'"
            :disable-sort="isMobile"
            :headers="headers"
            :hide-default-footer="isMobile"
            :items="withholdingDonations"
            class="elevation-2"
            group-by="tax_year"
            group-desc
        >
            <template #item.amount="{ item }">
                {{ item.amount | formatCurrency }}
            </template>

            <template v-slot:no-data>
                <span class="text-apsto-teal">
                    To start payroll withholding, click the {{ isMobile ? 'icon' : 'start button' }} above.
                </span>
            </template>

            <template v-slot:group.header="{ items, isOpen, toggle }">
                <v-toolbar
                    v-if="isMobile"
                    color="secondary"
                    flat
                >
                    <v-row>
                        <v-col>
                            Payroll Withholding Status
                        </v-col>
                        <v-col cols="auto">
                            <v-icon
                                :disabled="disableStartWithholding"
                                color="white"
                                rounded
                                @click="addItem"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <th colspan="7">
                    <v-icon @click="toggle">
                        {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    Tax Year: {{ items[0].tax_year }}
                </th>
            </template>
            <template v-slot:top>
                <v-dialog
                    v-model="dialogDelete"
                    :fullscreen="!!isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <v-card class="pb-5 dialog">
                        <v-card-title class="pa-4">
                            Confirm
                        </v-card-title>
                        <v-card-text class="mx-n2">
                            <v-row class="mt-2">
                                <v-col class="text-h6-mulish">
                                    Stop payroll donations through this employer?
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <div
                            v-if="isMobile"
                            class="px-4"
                        >
                            <v-btn
                                block
                                class="cancel-button"
                                rounded
                                @click="closeDeleteWithholdingDonation"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                block
                                class="mt-5"
                                color="primary"
                                rounded
                                @click="deleteWithholdingDonationConfirm"
                            >
                                Yes, Stop Payroll Donations
                            </v-btn>
                        </div>
                        <v-card-actions v-else>
                            <v-btn
                                class="cancel-button"
                                rounded
                                @click="closeDeleteWithholdingDonation"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="primary"
                                rounded
                                @click="deleteWithholdingDonationConfirm"
                            >
                                Yes, Stop Payroll Donations
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                    v-model="snackbar"
                    centered
                >
                    {{ snackbarMessage }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </template>
            <template #item.withholding_charges_authorized="{ item }">
                {{ item.withholding_charges_authorized > 0 ? item.withholding_charges_authorized : 0 }}
            </template>
            <template #item.withholding_maximum="{ item }">
                {{ item.withholding_maximum | formatCurrencyWithCents }}
            </template>
            <template v-slot:item.actionsWithholdingDonation="{ item }">
                <!--        <v-tooltip bottom color="secondary">-->
                <!--          <template v-slot:activator="{ on, attrs }">-->
                <!--            <v-btn-->
                <!--              :disabled="disableView(item)"-->
                <!--              :href="displayFile(item)"-->
                <!--              icon-->
                <!--              target="_blank"-->
                <!--              v-bind="attrs"-->
                <!--              v-on="on"-->
                <!--            >-->
                <!--              <v-icon color="iconCyan"> mdi-file</v-icon>-->
                <!--            </v-btn>-->
                <!--          </template>-->
                <!--          <span>View Uploaded Document</span>-->
                <!--        </v-tooltip>-->
                <!--        <v-tooltip bottom color="secondary">-->
                <!--          <template v-slot:activator="{ on, attrs }">-->
                <!--            <v-btn-->
                <!--              :disabled="disableUpload(item)"-->
                <!--              :href="'/dashboard/donors/donations/withholding/' + item.id + '/upload'"-->
                <!--              icon-->
                <!--              target="_blank"-->
                <!--              v-bind="attrs"-->
                <!--              v-on="on"-->
                <!--            >-->
                <!--              <v-icon color="primary"> mdi-upload</v-icon>-->
                <!--            </v-btn>-->
                <!--          </template>-->
                <!--          <span v-if="!item.file">Upload Document</span>-->
                <!--          <span v-if="item.file">Upload Document Again</span>-->
                <!--        </v-tooltip>-->
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :color="item.signed ? 'success' : 'secondary'"
                            class="mr-2"
                            v-bind="attrs"
                            @click="signWithholding(item)"
                            v-on="on"
                        >
                            mdi-file-sign
                        </v-icon>
                    </template>
                    <span>
                        {{ item.signed ? 'Form A-4C has been Signed' : 'Sign Form A-4C' }}
                    </span>
                </v-tooltip>
                <v-tooltip
                    v-if="item.signed"
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :disabled="disableSendEmail(item)"
                            class="mr-2"
                            color="secondary"
                            v-bind="attrs"
                            @click="sendEmail(item)"
                            v-on="on"
                        >
                            mdi-email
                        </v-icon>
                    </template>
                    <span>Email Me A Copy</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    color="secondary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :disabled="disableDeleteWithholding(item)"
                            class="mr-2"
                            color="red"
                            v-bind="attrs"
                            @click="deleteWithholdingDonation(item)"
                            v-on="on"
                        >
                            mdi-stop
                        </v-icon>
                    </template>
                    <span>Stop Payroll Withholding</span>
                </v-tooltip>
            </template>
            <template #item.apply_withholding_to_prior_tax_year="{ item }">
                <div v-if="item.apply_withholding_to_prior_tax_year">
                    Yes, apply withholding through April tax filing day to prior tax year.
                </div>
                <div v-else>
                    No
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Loading from "../../../components/shared/Loading";
import DonorHeader from "../../../components/donors/DonorHeader.vue";
import {
    DELETE_DONOR_WITHHOLDING_DONATION,
    EMAIL_DONOR_WITHHOLDING_DONATION,
    GET_DONOR_WITHHOLDING_DONATIONS
} from "../../../store/actions.type";
import CompanyHeader from "../../../components/company/CompanyHeader.vue";

export default {
    name: "DonationWithholdingList",
    components: {
        CompanyHeader,
        DonorHeader,
        Loading
    },
    data() {
        return {
            defaultItem: {
                name: '',
                status_name: '',
            },
            dialog: false,
            dialogDelete: false,
            headers: [
                {text: 'Tax Year', align: 'start', value: 'tax_year'},
                {text: 'Company Name', value: 'company_name'},
                {text: 'Charges Authorized', value: 'withholding_charges_authorized'},
                {text: 'Charges Processed', value: 'withholding_charges_processed'},
                {text: 'Charges Remaining', value: 'withholding_charges_remaining'},
                {text: 'Apply To Prior Tax Year', value: 'apply_withholding_to_prior_tax_year'},
                {text: 'Max Amount', value: 'withholding_maximum', align: 'end'},
                {text: 'Options', value: 'actionsWithholdingDonation', align: 'end', sortable: false},
            ],
            loading: true,
            sending: false,
            snackbar: false,
            snackbarMessage: "",

        }
    },
    computed: {
        derivedTaxYear() {
            return new Date().getMonth() + 1 === 12 ? "next" : "current";
        },
        disableStartWithholding() {
            const taxYearForFilteredCall = this.derivedTaxYear === "current" ? new Date().getFullYear() : new Date().getFullYear() + 1;
            return !!this.$store.getters.currentActiveWithholdingDonationsFiltered(taxYearForFilteredCall).length;
        },
        donor() {
            return this.$store.getters.currentDonor;
        },
        withholdingDonations() {
            return this.$store.getters.currentWithholdingDonations;
        },
    },
    methods: {
        addItem() {
            this.$router.push({name: 'donor-donation-withholding-create'});
        },
        disableDeleteWithholding(item) {
            return item.withholding_charges_authorized === item.withholding_charges_processed || item.tax_year < new Date().getFullYear();
        },
        disableSendEmail(item) {
            return !item.can_send_email;
        },
        disableUpload(item) {
            return !item.withholding_charges_authorized;
        },
        disableView(item) {
            return !item.file || !item.withholding_charges_authorized;
        },
        displayFile(item) {
            return item.file && item.file.id ? '/donor-withholdings/' + item.file.id : '';
        },
        getWithholdingDonations() {
            this.$store.dispatch(GET_DONOR_WITHHOLDING_DONATIONS)
        },
        closeDeleteDonation() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDeleteWithholdingDonation() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        deleteWithholdingDonation(item) {
            this.editedIndex = this.withholdingDonations.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteWithholdingDonationConfirm() {
            this.destroyWithholdingDonation();
            this.withholdingDonations.splice(this.editedIndex, 1)
            this.closeDeleteWithholdingDonation()
        },
        destroyWithholdingDonation() {
            this.saving = true;
            let donor_selection_id = this.editedItem.id;
            this.$store.dispatch(DELETE_DONOR_WITHHOLDING_DONATION, donor_selection_id)
                .then(() => {
                    this.saving = false;
                })
                .catch(({data}) => {
                    this.snackbarMessage = data.message_response;

                    this.snackbar = true;
                });
        },
        signWithholding(item) {
            var popupFeatures = "width=600,height=700,resizable=yes,scrollbars=yes,status=no,toolbar=no,menubar=no,location=no";
            var popupWindow = window.open('/sign/donor-selection/' + item.id, 'WithholdingSigningWindow', popupFeatures);
        },
        initialize() {
            this.withholdingDonations = [];
        },
        sendEmail(item) {
            this.editedIndex = this.withholdingDonations.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.sending = true;
            let donor_selection_id = this.editedItem.id;
            this.$store.dispatch(EMAIL_DONOR_WITHHOLDING_DONATION, donor_selection_id)
                .then(() => {
                    this.sending = false;
                    this.snackbarMessage = "Email sent";

                    this.snackbar = true;
                })
                .catch((response) => {
                    console.log(response);
                    this.sending = false;
                });
        },
    },
    mounted() {
        this.getWithholdingDonations();
        this.loading = false;
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep th:not([role=columnheader]) {
    background-color: var(--apsto-purple-very-light);
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
}
</style>
