import ApiService from "../api/api.service";
/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_APPLICATION_PROMO,
    CHECK_APPLICATION_PROMO,
    GET_APPLICATION_PROMOS,
    GET_CHILD_PROMO_DISPLAY,
    GET_FAMILY_PROMO_DISPLAY,
    PATCH_APPLICATION_PROMO,
    POST_APPLICATION_PROMO,
    TOGGLE_APPLICATION_PROMO
} from "./actions.type";
/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_APPLICATION_PROMO,
    SET_APPLICATION_PROMOS,
    SET_ERROR
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        promos: [],
        promo: null,
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentPromos(state) {
        return state.promos;
    },
    currentPromo(state) {
        return state.promo;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {

    [GET_APPLICATION_PROMOS](context, household_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/households/${household_id}/promos`)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_PROMOS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_APPLICATION_PROMO](context, promo_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/promos/${promo_id}`)
                .then(({ data }) => {
                    console.log("GET_APPLICATION_PROMO: ", promo_id, "data: ", data);
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_PROMO, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [CHECK_APPLICATION_PROMO](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/promos/check`, data)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_FAMILY_PROMO_DISPLAY](context, { household_id }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/promos/display/${household_id}`)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_PROMO, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [GET_CHILD_PROMO_DISPLAY](context, { household_id, child_id }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/promos/display/${household_id}/${child_id}`)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_PROMO, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [POST_APPLICATION_PROMO](context, { household_id, application_id, promo }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/api/households/${household_id}/applications/${application_id}/promos`, promo)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_PROMO, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
    [PATCH_APPLICATION_PROMO](context, { household_id, application_id, promo_id, promo }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/api/households/${household_id}/applications/${application_id}/promos/${promo_id}`, promo)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_APPLICATION_PROMO, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },

    [TOGGLE_APPLICATION_PROMO](context, { household_id, promo_id }) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/households/${household_id}/promos/${promo_id}`)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, {
                        target: "promos",
                        message: response.data.message,
                        errors: response.data.errors ?? [],
                    });
                    reject(response);
                });
        });
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_APPLICATION_PROMOS](state, data) {
        state.promos = data;
    },
    [SET_APPLICATION_PROMO](state, data) {
        state.promo = data;
    },
};

export default {
    getters,
    actions,
    mutations,
    state,
};
