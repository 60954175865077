<template>
    <loading v-if="loading" />
    <v-row
        v-else
        class="ml-0 mt-2"
    >
        <h2 style="color: var(--apsto-teal);">
            Tax Credit Donation Maximums
        </h2>
        <v-col
            class="px-0"
            cols="12"
        >
            <div style="font-size: 18px;">
                <div>
                    This chart shows Arizona tax credit maximums for {{ currentTaxYear }}.
                    <template v-if="isStartingTaxYear && shouldShowToggle">
                        The chart will show {{ otherTaxYear }} maximums on {{ displayDate(taxDay) }}.
                    </template>
                    <div v-if="shouldShowToggle">
                        <v-btn
                            class="mt-5"
                            color="secondary"
                            @click="changeYear"
                        >
                            Show {{ otherTaxYear }} Maximums Instead
                        </v-btn>
                    </div>
                </div>
            </div>
            <v-simple-table
                v-if="!isMobile"
                class="mt-5"
            >
                <thead>
                    <tr>
                        <th style="text-align: left;">Tuition Tax Credits</th>
                        <th>Original Credit Maximum</th>
                        <th>Switcher Credit Maximum</th>
                        <th>Tuition Credit Maximum</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(taxFactor, label) in taxFactors['wideScreen']"
                        :key="label"
                    >
                        <td style="text-align: left;">{{ label }}</td>
                        <td>{{ taxFactor.originalLimit | formatCurrency }}</td>
                        <td>{{ taxFactor.overflowLimit | formatCurrency }}</td>
                        <td>{{ taxFactor.combinedLimit | formatCurrency }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-simple-table
                v-if="isMobile"
                class="mt-5"
            >
                <thead>
                    <tr>
                        <th style="text-align: left;">Tuition Tax Credits</th>
                        <th>Married Jointly</th>
                        <th>Individual</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(taxFactor, label) in taxFactors['narrowScreen']"
                        :key="label"
                    >
                        <td style="text-align: left;">{{ label }}</td>
                        <td>{{ taxFactor.married | formatCurrency }}</td>
                        <td>{{ taxFactor.single | formatCurrency }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
import ApiService from '../../../../api/api.service';
import Loading from '../../../shared/Loading';

export default {
    name: "TaxFactors",
    components: {
        Loading,
    },
    props: {
        content: {
            type: Object,
        },
    },
    data() {
        return {
            taxFactors: null,
            loading: true,
            startingTaxYear: null,
            currentTaxYear: null,
        };
    },
    computed: {
        shouldShowToggle() {
            const today = new Date();
            const month = today.getMonth();
            const day = today.getDate();
            //If today is after the tax day but before the end of the year, hide the toggle
            if (month + 1 > 4 || (month + 1 === 4 && day > 16)) {
                return false;
            }
            return true;
        },
        taxDay() {
            return this.taxFactors.taxDay;
        },
        taxYear() {
            return this.applicableTaxYear();
        },
        isStartingTaxYear() {
            return this.currentTaxYear === this.startingTaxYear;

        },
        otherTaxYear() {
            if (this.isStartingTaxYear) {
                return this.currentTaxYear + 1;
            } else {
                return this.currentTaxYear - 1;
            }
        },
    },
    methods: {
        changeYear() {
            if (this.isStartingTaxYear) {
                this.currentTaxYear = this.currentTaxYear + 1;
            } else {
                this.currentTaxYear = this.currentTaxYear - 1;
            }
            this.getChart(this.currentTaxYear);
        },
        getChart(year) {
            this.loading = true;
            ApiService.get('/api/website/data/tax-factors-for-chart/' + year)
                .then((response) => {
                    this.taxFactors = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log("TAX FACTORS ERROR");
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.startingTaxYear = this.applicableTaxYear();
        this.currentTaxYear = this.applicableTaxYear();
        this.getChart(this.currentTaxYear);
    },
};
</script>

<style scoped>
td {
    font-size: 18px !important;
    padding: 20px;
    text-align: center !important;
}

th {
    background-color: var(--apsto-purple) !important;
    color: white !important;
    font-size: .875rem !important;
    padding: 20px;
}

tr>td {
    border: 1px solid var(--apsto-purple-very-light);
}
</style>
